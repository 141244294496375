import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap } from 'rxjs';
import { TaskGroupService } from '@app/core/services/task-group.service';
import { TaskGroupActions } from './task-group.actions';
import { ToastActions } from '../toast/toast.actions';
import { TaskGroupsActions } from '../task-groups/task-groups.actions';

@Injectable()
export class TaskGroupEffects {
  createTaskGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskGroupActions.create),
      switchMap(({ cmd }) =>
        this.taskGroupService.createTaskGroup(cmd).pipe(
          mergeMap((taskGroup) => [
            TaskGroupsActions.load(),
            ToastActions.showInfoMessage({ summary: 'Mallgrupp sparad', detail: '' }),
            TaskGroupActions.createTaskGroupSucceeded({ taskGroup }),
          ]),
          catchError(() =>
            of(
              ToastActions.showErrorMessage({
                summary: 'Något gick fel!',
                detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateTaskGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskGroupActions.update),
      switchMap(({ id, cmd }) =>
        this.taskGroupService.updateTaskGroup(id, cmd).pipe(
          mergeMap((taskGroup) => [
            TaskGroupsActions.load(),
            ToastActions.showInfoMessage({ summary: 'Mallgrupp sparad', detail: '' }),
            TaskGroupActions.updateTaskGroupSucceeded({ taskGroup }),
          ]),
          catchError(() =>
            of(
              ToastActions.showErrorMessage({
                summary: 'Något gick fel!',
                detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  deleteTaskGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskGroupActions.delete),
      switchMap(({ id }) =>
        this.taskGroupService.deleteTaskGroup(id).pipe(
          mergeMap(() => [
            TaskGroupsActions.load(),
            ToastActions.showInfoMessage({ summary: 'Mallgrupp borttagen', detail: '' }),
            TaskGroupActions.deleteTaskGroupSucceeded(),
          ]),
          catchError(() =>
            of(
              ToastActions.showErrorMessage({
                summary: 'Något gick fel!',
                detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private taskGroupService: TaskGroupService) {}
}
