import { Component } from '@angular/core';
import { AuthActions } from '@app/core/state/auth/auth.actions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { FormDialogBaseComponent } from '../form-dialog-base/form-dialog-base.component';

@Component({
  selector: 'no-assigned-team.dialog',
  template: `
    <div>
      <div class="flex-col">
        <p>
          Du har inte blivit tilldelad några team ännu. Kontakta en byråadministratör för att bli tilldelad ett eller
          flera team.
        </p>
      </div>
      <div class="flex w-full justify-end">
        <button type="button" pButton (click)="onLogout()" icon="pi pi-sign-out" label="Logga ut"></button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [ButtonModule],
})
export class NoAssignedTeamDialog extends FormDialogBaseComponent {
  constructor(
    protected override ref: DynamicDialogRef,
    protected override config: DynamicDialogConfig,
    private router: Router,
  ) {
    super(ref, config);
  }

  protected static override getDialogConfig = (): Omit<DynamicDialogConfig, 'data'> => ({
    modal: true,
    closable: false,
    draggable: false,
    autoZIndex: true,
    position: 'center',
    header: 'Inga tilldelade team',
  });

  protected override onAfterClose = () => {
    this.store.dispatch(AuthActions.resetAuthStateAndStorage());
    this.router.navigate(['login']);
  };

  onLogout = () => this.close({});
}
