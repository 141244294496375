import { User } from './user';
import { Client } from './client';
import { ActivityState } from './activitystate';
import { Assignment } from './assignment';
export class Todo {
  public id: number;
  public entryDate: string;
  public deadline: string;
  public text = '';
  public tmpText = '';
  public users: User[];
  public creator: User;
  public client: Client;
  public clientName = '';
  public clientOrgNbr = '';
  public clientCustNbr = '';
  public clientRespName = '';
  public state: ActivityState;
  public statename = '';
  public assignment: Assignment;
  public assignmentname = '';
  public scope: string;
  public usernames = '';
  public selectableStates: ActivityState[] = [
    { description: 'Ej påbörjad', name: 'NST' },
    { description: 'Pågående', name: 'IPG' },
    { description: 'Klar', name: 'DON' }
  ];
  public selectableScopes: string[];
  public type = { description: '', name: '' };
  public selectableTypes = [
    { description: 'Notering', name: 'NOT' },
    { description: 'Besök', name: 'BES' },
    { description: 'E-post', name: 'EPO' },
    { description: 'Telefon', name: 'TEL' },
    { description: 'Övrigt', name: 'OVR' }
  ];

  constructor() {}
}
