import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TeamType } from '@app/core/state/types';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-confirm-delete-team-dialog',
  template: `
    <p-dialog [visible]="visible" header="Ta bort teamet?" [modal]="true" showEffect="fade">
      <p>Är du helt säker på att du vill ta bort teamet "{{ team?.name }}"?</p>
      <p-footer>
        <div>
          <button type="button" class="btn btn-success" (click)="onConfirmClick()">Ja, fortsätt</button>
          <button type="button" class="btn btn-warning" (click)="onCancelClick()">Avbryt</button>
        </div>
      </p-footer>
    </p-dialog>
  `,
  standalone: true,
  imports: [DialogModule],
})
export class ConfirmDeleteTeamDialog {
  @Input() team: TeamType;
  @Input() visible = false;

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() confirm: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  onConfirmClick() {
    this.visibleChange.emit(false);
    this.confirm.emit();
  }

  onCancelClick() {
    this.visibleChange.emit(false);
    this.cancel.emit();
  }
}
