import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TimelineMarkerDirective } from './directives/timeline-marker.directive';
import { ProgressTextDirective } from './directives/progress-text.directive';
import { ProgressTimelineComponent } from './progress-timeline.component';

@NgModule({
  declarations: [ProgressTimelineComponent, TimelineMarkerDirective, ProgressTextDirective],
  imports: [CommonModule],
  exports: [ProgressTimelineComponent, TimelineMarkerDirective, ProgressTextDirective],
})
export class ProgressTimelineModule {}
