import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { SimpleUserType, UserType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { MemoizedSelector, Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { BehaviorSubject, Observable, Subject, map, takeUntil } from 'rxjs';

type FormUserType = number | UserType | SimpleUserType;
type FormSelectorType = UserType | SimpleUserType;

@Component({
  selector: 'app-form-user-selector',
  template: `
    <ng-container *ngIf="items$ | async">
      <ng-container *ngIf="!isMultiSelect">
        <p-dropdown
          appendTo="body"
          dataKey="id"
          styleClass="w-full"
          [disabled]="disabled"
          [options]="items$ | async"
          [scrollHeight]="scrollHeight"
          [title]="title"
          [inputId]="inputId"
          [class]="isInvalid ? 'ng-invalid ng-dirty' : ''"
          [(ngModel)]="selectedItem"
          (onChange)="itemChanged()"
          [autoOptionFocus]="false"
          [autoDisplayFirst]="false">
        </p-dropdown>
      </ng-container>
      <ng-container *ngIf="isMultiSelect">
        <p-multiSelect
          appendTo="body"
          dataKey="id"
          placeholder="- Välj -"
          selectedItemsLabel="{0} användare valda"
          styleClass="w-full"
          [showHeader]="false"
          [maxSelectedLabels]="1"
          [disabled]="disabled"
          [options]="items$ | async"
          [scrollHeight]="scrollHeight"
          [title]="title"
          [inputId]="inputId"
          [class]="isInvalid ? 'ng-invalid ng-dirty' : ''"
          [(ngModel)]="selectedItems"
          (onChange)="itemsChanged()"
          [autoOptionFocus]="false">
        </p-multiSelect>
      </ng-container>
    </ng-container>
  `,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormUserSelectorComponent,
    },
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MultiSelectModule, DropdownModule],
})
export class FormUserSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() isMultiSelect: boolean;
  @Input() isInvalid: boolean;
  @Input() title: string;
  @Input() inputId: string;
  @Input() selector: MemoizedSelector<AppState, FormSelectorType[]>;
  @Input() scrollHeight: string;
  @Input() breakpoint: number;

  public disabled: boolean;
  public itemsBS: BehaviorSubject<FormSelectorType[]>;
  public items$: Observable<SelectItem[]>;
  public selectedItems: FormUserType[];
  public selectedItem: FormUserType;
  public onChange: ControlValueAccessorFn = () => {};
  public onTouched: ControlValueAccessorFn = () => {};
  private onDestroySub: Subject<void>;

  constructor(private store: Store<AppState>) {
    this.onDestroySub = new Subject();
    this.itemsBS = new BehaviorSubject([]);
    this.isMultiSelect = false;
    this.isInvalid = false;
    this.selector = UserSelectors.activeUsersWithoutAllAsSimpleUser;
    this.breakpoint = 16;
    this.title = '';
  }

  ngOnInit(): void {
    this.store
      .select(this.selector)
      .pipe(takeUntil(this.onDestroySub))
      .subscribe((items) => {
        this.itemsBS.next(items);
        if (!this.scrollHeight) {
          this.scrollHeight = getScrollHeightString(items.length, this.breakpoint);
        }
      });
    this.items$ = this.itemsBS
      .asObservable()
      .pipe(map((items) => items.map((item) => ({ label: item.name, value: item }))));
  }

  ngOnDestroy(): void {
    this.onDestroySub.next();
    this.onDestroySub.complete();
  }

  writeValue(items: FormUserType | FormUserType[]): void {
    if (!Array.isArray(items)) {
      this.selectedItem = items;
      return;
    }

    this.selectedItems = items
      .map((item) => {
        if (typeof item === 'number') {
          return this.itemsBS.value.find((i) => i.id === item);
        }

        return item;
      })
      .filter((item) => Boolean(item));
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  itemChanged(): void {
    const selectedIds = typeof this.selectedItem === 'number' ? this.selectedItem : this.selectedItem.id;
    const changedItem = this.itemsBS.value.find((item) => selectedIds === item.id);

    this.onChange(changedItem);
  }

  itemsChanged(): void {
    const selectedIds = this.selectedItems.map((item) => {
      if (typeof item === 'number') {
        return item;
      }

      return item.id;
    });
    const changedItems = this.itemsBS.value.filter((item) => selectedIds.includes(item.id));

    this.onChange(changedItems);
  }
}
