export interface IncludeFinishedLabelProps<T> {
  label: string;
  completeList: T[];
  predicate: (item: T) => boolean;
}

export function getIncludeFinishedLabel<T>({ label, completeList, predicate }: IncludeFinishedLabelProps<T>): string {
  const filteredListCount = completeList?.filter(predicate).length ?? 0;
  return `${label} (${filteredListCount} st)`;
}
