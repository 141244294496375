import { createActionGroup, props } from '@ngrx/store';
import { ToastMessageType, ToastWithoutSeverity } from '../types';

export const ToastActions = createActionGroup({
  source: 'Toast',
  events: {
    'Show Info Message': props<ToastWithoutSeverity>(),
    'Show Warn Message': props<ToastWithoutSeverity>(),
    'Show Error Message': props<ToastWithoutSeverity>(),
    'Show Message': props<ToastMessageType>(),
    'Clears All Toast Messages': props<{ key?: string }>(),
  },
});
