import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BlFrontendBadgeComponent } from '@app/core/wrappers/bl-frontend-badge.component';
import { BadgeProps } from '@bl/components';

@Component({
  selector: 'app-partnerprogram-card',
  template: `
    <div
      class="flex flex-col bg-white p-4 border border-bl-grey-200 rounded-lg shadow-sm h-56 relative"
      [ngStyle]="
        background
          ? {
              'background-image': 'url(' + background + ')',
              'background-repeat': 'no-repeat',
              'background-position': 'top right'
            }
          : {}
      ">
      <div>
        <div class="flex flex-col">
          <p class="text-lg font-semibold text-bl-blue-light-800 truncate mb-0 pr-2" [style]="headerStyle ?? ''">
            {{ header }}
          </p>
          <ng-container *ngIf="badges?.length">
            <div class="flex gap-2 mt-1">
              <bl-frontend-badge
                *ngFor="let badge of badges"
                [text]="badge.text"
                [variant]="badge.variant"
                [icon]="badge.icon"></bl-frontend-badge>
            </div>
          </ng-container>
        </div>
        <p class="py-4 text-sm font-normal text-bl-grey-700" [style]="contentStyle ?? 'min-height: 75px'">
          {{ content }}
        </p>
      </div>
      <div class="absolute bottom-0 mb-4">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, BlFrontendBadgeComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramCardComponent {
  @Input() header: string;
  @Input() content: string;
  @Input() contentStyle: string;
  @Input() headerStyle: string;
  @Input() background: string;
  @Input() badges?: BadgeProps[];
}
