import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { PartnerFirm, PropertiesDto, PropertyType, SaveFirmDto } from '@app/core/state/types/partner.types';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, combineLatest, filter, takeUntil } from 'rxjs';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { MultiSelectDropDownIncludingAllComponent } from '@app/shared/components/multiselect-dropdown/multiselect-dropdown-including-all.component';
import { DialogService } from 'primeng/dynamicdialog';
import { openDialog } from '@app/shared/misc/openDialog';
import { FirmSettingsDialog } from '@app/firm-settings/firm.settings.dialog';
import { PartnerProgramFileUpload } from './partnerprogram-file-upload.component';
import { PartnerProgramProfileCardComponent } from './partnerprogram-profile-card.component';
import { AppState } from '@app/core/state/appState';
import { FirmType } from '@app/core/state/types';
import { PartnerProgramSelectors } from '@app/core/state/partnerprogram/partnerprogram.selectors';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { PartnerProgramActions } from '@app/core/state/partnerprogram/partnerprogram.actions';
import { BlFrontendAlertComponent } from '@app/core/wrappers/bl-frontend-alert.component';

const DESCRIPTION_MAXLENGTH = 1000;
interface PartnerProfileForm {
  description: FormControl<string>;
  email: FormControl<string>;
  phone: FormControl<string>;
  webpage: FormControl<string>;
}

interface PartnerContactForm {
  address: FormControl<string>;
  city: FormControl<string>;
  zipcode: FormControl<string>;
}
interface PartnerProgramProfileForm {
  partnerProfile: FormGroup<PartnerProfileForm>;
  background: FormControl<string>;
  partnerContact: FormGroup<PartnerContactForm>;
  propertyIds: FormControl<number[]>;
}

@Component({
  selector: 'app-partnerprogram-profile',
  template: `
    <div class="max-w-screen-xl">
      <div class="dark mb-6">
        <bl-frontend-alert
          [header]="'Gör byrån synlig för nya kunder!'"
          severity="info"
          [listItems]="[
            {
              text: 'Skapa en byråprofil som blir synlig för företag som söker redovisningsbyråer i Bjorn Lunden-Appen. Fyll i och spara byråprofilen nedan så blir byrån sökbar för företagare.'
            }
          ]"
          [useUnsafeHtml]="true"
          [dismissable]="true"
          *ngIf="!isStored">
        </bl-frontend-alert>
      </div>
      <form [formGroup]="form">
        <div class="grid grid-cols-2 gap-4 mb-6">
          <div formGroupName="partnerProfile">
            <app-partnerprogram-profile-card header="Information till kund">
              <div class="flex flex-col pb-2 pt-4">
                <label class="text-sm font-medium text-bl-blue-light-600" for="webpage">Hemsida</label>
                <input id="webpage" type="url" formControlName="webpage" pInputText placeholder="Hemsida" />
              </div>
              <div class="grid gap-6 grid-cols-2 pb-2 pt-4">
                <div class="flex flex-col">
                  <label class="text-sm font-medium text-bl-blue-light-600" for="email">E-post</label>
                  <input id="email" type="email" formControlName="email" pInputText placeholder="E-post" />
                </div>
                <div class="flex flex-col">
                  <label class="text-sm font-medium text-bl-blue-light-600" for="phone">Telefon</label>
                  <input id="phone" type="tel" formControlName="phone" pInputText placeholder="Telefon" />
                </div>
              </div>
              <div class="flex flex-col pb-2 pt-4 relative">
                <label class="text-sm font-medium text-bl-blue-light-600" for="description">
                  Beskrivning av byrå
                </label>
                <textarea
                  [maxlength]="maxlength"
                  pInputTextarea
                  id="description"
                  formControlName="description"
                  rows="10">
                </textarea>
                <div class="absolute right-0 bottom-0 -mb-4">
                  <span class="text-sm font-normal">
                    {{ form?.value?.partnerProfile?.description?.length }}/{{ maxlength }}
                  </span>
                </div>
              </div>
            </app-partnerprogram-profile-card>
          </div>
          <div>
            <div class="grid grid-rows-2 gap-4">
              <div>
                <app-partnerprogram-profile-card header="Omslagsbild">
                  <app-partnerprogram-file-upload accept=".png, .svg,.jpg,.jpeg" formControlName="background">
                  </app-partnerprogram-file-upload>
                </app-partnerprogram-profile-card>
              </div>
              <div class="flex flex-col" formGroupName="partnerContact">
                <div
                  class="flex flex-col bg-white p-6 border border-bl-grey-200 border-b-0 rounded-t-lg shadow-sm content-between">
                  <div class="flex flex-col pb-2 pt-4 mt-6">
                    <label class="text-base font-medium text-bl-grey-700">Adress</label>
                    <span class="text-base font-normal text-bl-grey-500">{{
                      form?.value?.partnerContact?.address
                    }}</span>
                  </div>
                  <div class="grid gap-6 grid-cols-2 pb-2 pt-4">
                    <div class="flex flex-col pb-2 pt-4">
                      <label class="text-base font-medium text-bl-grey-700">Postnummer</label>
                      <span class="text-base font-normal text-bl-grey-500">{{
                        form?.value?.partnerContact?.zipcode
                      }}</span>
                    </div>
                    <div class="flex flex-col pb-2 pt-4">
                      <label class="text-base font-medium text-bl-grey-700">Ort</label>
                      <span class="text-base font-normal text-bl-grey-500">{{
                        form?.value?.partnerContact?.city
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="border bg-bl-grey-50 border-bl-grey-200 rounded-b-lg shadow-sm flex justify-end py-2 px-6">
                  <div>
                    <span
                      class="text-base font-medium text-bl-grey-500 hover:text-bl-blue-light-500 cursor-pointer"
                      (click)="onEditFirm()">
                      Redigera uppgifter
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="grid grid-cols-2 gap-4">
        <div *ngFor="let group of (properties$ | async)?.groups; let groupIndex = index">
          <app-partnerprogram-profile-card [header]="group.label" [fullHeight]="false">
            <div class="flex w-full flex-wrap justify-between gap-2">
              <div
                class="flex flex-col pb-2 pt-4"
                [ngClass]="{ 'w-full': typeIndex > 1 || groupIndex > 0, 'w-5/12': groupIndex === 0 && typeIndex < 2 }"
                *ngFor="let type of group.types; let typeIndex = index">
                <label class="text-sm font-medium" [for]="type.label">{{ type.label }}</label>
                <ng-container *ngIf="!type.multiValue">
                  <p-dropdown
                    [autoDisplayFirst]="false"
                    optionLabel="label"
                    optionValue="value"
                    [inputId]="type.label"
                    [options]="type.properties"
                    [ngModel]="type.selected"
                    styleClass="w-full"
                    (onChange)="onSinglePropertyChanged($event, type)" />
                </ng-container>
                <ng-container *ngIf="type.multiValue">
                  <app-multiselect-dropdown-including-all
                    optionLabel="label"
                    optionValue="value"
                    [allOption]="type.properties[0]"
                    [options]="type.properties"
                    [selected]="type.multiSelected ?? []"
                    styleClass="w-full"
                    (selectedChange)="onMultiPropertyChanged($event, type)" />
                </ng-container>
              </div>
            </div>
          </app-partnerprogram-profile-card>
        </div>
      </div>
      <div class="my-6 flex justify-end space-x-2">
        <bl-frontend-button [text]="'Avbryt'" (onClick)="onCancel()" variant="secondary" />
        <bl-frontend-button [text]="'Spara'" (onClick)="onSubmit()" />
      </div>
    </div>
  `,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PartnerProgramProfileCardComponent,
    InputTextModule,
    InputTextareaModule,
    DropdownModule,
    ButtonModule,
    PartnerProgramFileUpload,
    MultiSelectDropDownIncludingAllComponent,
    BlFrontendButtonComponent,
    BlFrontendAlertComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramProfileComponent implements OnDestroy {
  @Input() level: number;
  form: FormGroup<PartnerProgramProfileForm>;
  maxlength = DESCRIPTION_MAXLENGTH;
  firm: FirmType;
  partnerFirm: PartnerFirm;
  properties$: Observable<PropertiesDto>;
  regions: Record<string, string>[];
  integrations: Record<string, string>[];
  digitizationLevels: { label: string; value: number }[];
  propertiesSub: BehaviorSubject<PropertiesDto | null>;
  private onDestroySub: Subject<void>;
  public refreshSub: BehaviorSubject<void | null>;

  constructor(
    private store: Store<AppState>,
    private builder: FormBuilder,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.propertiesSub = new BehaviorSubject(null);
    this.refreshSub = new BehaviorSubject(null);
    this.onDestroySub = new Subject();
    this.properties$ = this.propertiesSub.asObservable();
    this.initForm();
    combineLatest([
      this.store.select(FirmSelectors.selectFirm),
      this.store.select(PartnerProgramSelectors.firm),
      this.store.select(PartnerProgramSelectors.properties),
      this.refreshSub.asObservable(),
    ])
      .pipe(
        filter(([firm, partnerFirm, properties]) => Boolean(firm) && Boolean(partnerFirm) && Boolean(properties)),
        takeUntil(this.onDestroySub),
      )
      .subscribe(([firm, partnerFirm, properties]) => {
        this.firm = firm;
        this.partnerFirm = partnerFirm;
        this.propertiesSub.next(this.addSelectedProperties(properties));
        this.refreshForm(firm, partnerFirm);
      });
  }

  ngOnDestroy(): void {
    this.onDestroySub.next();
    this.onDestroySub.complete();
  }

  onEditFirm = () => {
    openDialog(this.dialogService, FirmSettingsDialog, { tabToOpen: 'Byråuppgifter' }).subscribe(() =>
      this.changeDetectorRef.markForCheck(),
    );
  };

  onSinglePropertyChanged(event: DropdownChangeEvent, type: PropertyType) {
    const found = this.propertiesSub.value.groups
      .find((g) => g.types.some((t) => t.value === type.value))
      ?.types.find((t) => t.value === type.value);
    if (!found) {
      return;
    }

    const existingIds = this.form.controls.propertyIds.value;
    const foundIds = found.properties.map((p) => p.value);
    const newIds = existingIds.filter((id) => !foundIds.includes(id)).concat(event.value);
    this.form.controls.propertyIds.patchValue(newIds);
  }

  onMultiPropertyChanged(items: number[], type: PropertyType) {
    const found = this.propertiesSub.value.groups
      .find((g) => g.types.some((t) => t.value === type.value))
      ?.types.find((t) => t.value === type.value);
    if (!found) {
      return;
    }

    const existingIds = this.form.controls.propertyIds.value;
    const foundIds = found.properties.map((p) => p.value);
    const newIds = existingIds.filter((id) => !foundIds.includes(id)).concat(items);
    this.form.controls.propertyIds.patchValue(newIds);
  }

  onCancel() {
    this.refreshSub.next(null);
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const data: SaveFirmDto = {
      address: this.getAddress(),
      zipcode: this.getZipcode(),
      city: this.getCity(),
      webpage: this.getWebpage(),
      email: this.getEmail(),
      background: this.getBackground(),
      phone: this.getPhone(),
      description: this.getDescription(),
      propertyIds: this.getPropertyIds(),
      logo: null,
    };
    this.store.dispatch(PartnerProgramActions.save({ data }));
  }

  // eslint-disable-next-line complexity
  get isStored() {
    return (
      this.getWebpage() ||
      this.getEmail() ||
      this.getPhone() ||
      this.getDescription() ||
      this.getBackground() ||
      this.getPropertyIds()?.length > 0
    );
  }

  private getCity = (): string | null => this.form.value?.partnerContact?.city || this.firm?.city || null;
  private getWebpage = (): string | null => this.form.value?.partnerProfile?.webpage || null;
  private getEmail = (): string | null => this.form.value?.partnerProfile?.email || null;
  private getPhone = (): string | null => this.form.value?.partnerProfile?.phone || null;
  private getDescription = (): string | null => this.form.value?.partnerProfile?.description || null;
  private getBackground = (): string | null => this.form.value?.background || null;
  private getPropertyIds = (): number[] | null => this.form.value?.propertyIds?.filter((id) => Boolean(id)) || null;
  private getAddress = (): string | null => this.form.value?.partnerContact?.address || this.firm?.address || null;
  private getZipcode = (): string | null => this.form.value?.partnerContact?.zipcode || this.firm?.zipcode || null;

  private addSelectedProperties = (properties: PropertiesDto): PropertiesDto => {
    const selected = this.partnerFirm?.propertyIds;
    if (!selected) {
      return properties;
    }

    const groups = properties.groups.map((group) => ({
      ...group,
      types: group.types.map((type) => {
        if (type.multiValue) {
          return {
            ...type,
            multiSelected: type.properties.filter((p) => selected.includes(p.value)).map((p) => p.value),
          };
        }

        return {
          ...type,
          selected: type.properties.find((p) => selected.includes(p.value))?.value || null,
        };
      }),
    }));
    return { ...properties, groups };
  };

  private initForm = () => {
    this.form = this.builder.group<PartnerProgramProfileForm>({
      partnerProfile: new FormGroup<PartnerProfileForm>({
        description: new FormControl<string>(null),
        email: new FormControl<string>(null),
        phone: new FormControl<string>(null),
        webpage: new FormControl<string>(null),
      }),
      partnerContact: new FormGroup<PartnerContactForm>({
        address: new FormControl<string>(null),
        city: new FormControl<string>(null),
        zipcode: new FormControl<string>(null),
      }),
      background: new FormControl<string>(null),
      propertyIds: new FormControl<number[]>([]),
    });
  };

  private refreshForm = (firm: FirmType, partnerFirm: PartnerFirm) => {
    this.form = this.builder.group<PartnerProgramProfileForm>({
      partnerProfile: new FormGroup({
        description: new FormControl<string>(partnerFirm.description),
        email: new FormControl<string>(partnerFirm.email),
        phone: new FormControl<string>(partnerFirm.phone),
        webpage: new FormControl<string>(partnerFirm.webpage),
      }),
      partnerContact: new FormGroup({
        address: new FormControl<string>(firm.address),
        city: new FormControl<string>(firm.city),
        zipcode: new FormControl<string>(firm.zipcode),
      }),
      background: new FormControl<string>(partnerFirm.background),
      propertyIds: new FormControl<number[]>(partnerFirm.propertyIds),
    });
  };
}
