<div>
  <app-tabs #tabs>
    <app-tab tabTitle="Uppdragsmallar">
      <app-firm-task-templates></app-firm-task-templates>
    </app-tab>
    <app-tab tabTitle="Importera" (activeChange)="fileImportTabActive = $event"
      ><app-file-import [tabActive]="fileImportTabActive"></app-file-import
    ></app-tab>
    <app-tab tabTitle="Medarbetare">
      <app-firm-users></app-firm-users>
    </app-tab>
    <app-tab tabTitle="Systemuppgifter">
      <app-firm-system-settings></app-firm-system-settings>
    </app-tab>
    <app-tab tabTitle="Byråuppgifter">
      <app-firm-data [firmData$]="firmData$ | async" (updateFirmEvent)="updateFirmData($event)"> </app-firm-data>
    </app-tab>
    <ng-container *ngIf="firmAssignmentData$ | async as firmSettings">
      <app-tab tabTitle="Uppdragsavtalet">
        <app-firm-assignment-settings
          [firmAssignmentData$]="firmSettings"
          (updateFirmAssignmentData)="updateFirmAssignmentData($event)"
          (resetFirmAssignmentData)="resetFirmAssignmentData()">
        </app-firm-assignment-settings>
      </app-tab>
    </ng-container>
    <app-tab
      tabTitle="Masshantering"
      tabTooltip="Bara byråadministratörer har tillgång till Masshantering"
      [disabled]="!hasRightsToChange()"
      (activeChange)="bulkHandlingTabActive = $event">
      <app-bulk-main [tabActive]="bulkHandlingTabActive"></app-bulk-main>
    </app-tab>

    <app-tab
      tabTitle="Teamhantering"
      tabTooltip="Bara byråadministratörer har tillgång till Teamhantering"
      [disabled]="!hasRightsToChange()"
      (activeChange)="teamTabActive = $event">
      <app-teams *ngIf="teamTabActive"></app-teams>
    </app-tab>

    <app-tab *appAdminPrivilege tabTitle="Byråadministration">
      <app-firm-reset [closeCallback]="resetCallback"></app-firm-reset>
    </app-tab>
  </app-tabs>
</div>
