import { Injectable } from '@angular/core';
import { BlaApplicationLinksBuilder } from '@app/shared/misc/application-link-builders/BlaApplicationLinksBuilder';
import { ApplicationLinksBuilder, ApplicationType } from '@app/shared/misc/application-link-builders/types';
import { YearlyApplicationLinksBuilder } from '@app/shared/misc/application-link-builders/YearlyApplicationLinksBuilder';
import { Task } from 'src/app/core/entity/task';

@Injectable({ providedIn: 'root' })
export class ApplicationLinksService {
  private builders: ApplicationLinksBuilder[] = [];

  constructor() {
    const thisYear = new Date().getUTCFullYear();
    this.builders = [
      new BlaApplicationLinksBuilder('BL Administration'),
      new YearlyApplicationLinksBuilder('BL Skatt Besk', ApplicationType.BLSkatt, new Date(`${thisYear}-06-16`)),
      new YearlyApplicationLinksBuilder('BL Bokslut Besk', ApplicationType.BLBokslut, new Date(`${thisYear}-04-01`)),
    ];
  }

  public getApplicationLinksGroups = (tasks: Task[]) => {
    const groups = Object.values(ApplicationType).map((type) => {
      const builder = this.builders.find((b) => b.canBuild(type));
      const links = builder.build(type, tasks);

      return { type, links };
    });

    return groups;
  };

  public getApplicationLinksGroupsWithTasks = () => {
    const blaTask: Task = { ...new Task(), assignmentAgent: ApplicationType.BL };
    const blsTask: Task = { ...new Task(), assignmentAgent: ApplicationType.BLSkatt };
    const blbTask: Task = { ...new Task(), assignmentAgent: ApplicationType.BLBokslut };
    const tasks: Task[] = [blaTask, blsTask, blbTask];
    const appLinksGroups = this.getApplicationLinksGroups(tasks);
    return appLinksGroups;
  };
}
