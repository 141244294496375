import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Assignment } from '../entity/assignment';
import { Observable } from 'rxjs';

import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AssignmentType } from '../state/types';
import { AppState } from '../state/appState';
import { toAssignmentTypeTransformer } from '../state/transformers/todo.transformers';

@Injectable({
  providedIn: 'root',
})
export class AssignmentService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'assignment';

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
    this.useCache();
  }

  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  getAssignments(): Observable<Assignment[]> {
    if (!this.hasCacheExpired()) {
      return this.getCacheObservable<Assignment[]>();
    }

    const headers = this.options.getAuthorizationHeader();
    return this.http.get<Assignment[]>(this.url, headers).pipe(
      tap((result) => this.updateCache(result)),
      this.catchErrorAndShowMessage(),
    );
  }

  getAssignmentTypes(): Observable<AssignmentType[]> {
    return this.getAssignments().pipe(map((assignments) => assignments.map(toAssignmentTypeTransformer.transform)));
  }
}
