import { createSelector } from '@ngrx/store';
import { toSimpleUserTypeTransformer } from '../transformers/transformers';
import { AppState } from '../appState';
import { AuthState } from './auth.reducers';
import { RoleType } from '../types';

const authState = (state: AppState) => state.auth;

const selectLoadSpinnerState = createSelector(authState, (state: AuthState) => state.authSpinner);
const selectAuthUser = createSelector(authState, (state: AuthState) => state.user);
const selectAuthAsSimpleUser = createSelector(authState, (state: AuthState) =>
  state.user ? toSimpleUserTypeTransformer.transform(state.user) : null,
);
const selectTeams = createSelector(authState, (state: AuthState) => state.user?.teams ?? []);
const selectIsFirmUser = createSelector(
  authState,
  (state: AuthState) => state.user?.role === RoleType.FIRM_USER ?? false,
);

export const AuthSelectors = {
  selectAuthUser,
  selectLoadSpinnerState,
  selectAuthAsSimpleUser,
  selectTeams,
  selectIsFirmUser,
};
