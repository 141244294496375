import { Link } from '@bl/components';

export const getMainInfoAlertContent = (): Link[] => [
  {
    text: `Välj samarbetspaket för klienten i kolumnen <strong>Hantera paket</strong>.
      <a href="https://www.bjornlunden.se/samarbetspaket" target="_blank">Läs mer här</a> om innehållet i de olika paketen.`,
  },
  {
    text: 'Du kan också välja att arkivera eller ta bort klientens företagsdatabas',
  },
  {
    text: `<a href="https://support.bjornlunden.se/guide/val-av-samarbetspaket?category=val-av-nya-samarbetspaket" target="_blank">
      Här hittar du en guide</a> som visar hur du väljer samarbetspaket!`,
  },
];

export const getAdminMessageAboutHiddenDatabases = (): string => `
    <div class="pt-3 font-bold">Det finns dolda klienter i listan (de är ej publicerade i företagslistan i molnet). <br>
    De visas just nu bara för medarbetare som är byråadministratörer. <br>
    Vill du ändra denna inställning <a href="#">klicka här</a>.</div>`;

export const getUserMessageAboutHiddenDatabases =
  (): string => `<div class="pt-3 font-bold">Det finns dolda klienter i listan (de är ej publicerade i företagslistan i molnet). <br>
    Endast byråadministratörer kan se dessa enligt nuvarande inställning.</div>`;
