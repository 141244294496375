import { Injectable } from '@angular/core';
import { TaskService } from '@app/core/services/task.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, mergeMap, switchMap } from 'rxjs';
import { TaskActions } from './task.actions';
import { ToastActions } from '../toast/toast.actions';

@Injectable()
export class TaskEffects {
  loadRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.save),
      switchMap(({ task }) =>
        this.taskService.saveTask(task).pipe(
          mergeMap((tasks) => [
            ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Tjänsten har sparats' }),
            TaskActions.saveSucceeded({ task: tasks[0] }),
          ]),
          catchError((error: unknown) =>
            from([
              ToastActions.showWarnMessage({
                summary: 'Ett fel har uppstått',
                detail: this.getErrorMessage(error),
              }),
              TaskActions.saveFailed({ error }),
            ]),
          ),
        ),
      ),
    ),
  );

  private getErrorMessage = (error: { _body?: string }): string =>
    // eslint-disable-next-line no-underscore-dangle
    error?._body ? JSON.parse(error?._body)?.message ?? '' : '';

  // eslint-disable-next-line no-useless-constructor
  constructor(private actions$: Actions, private taskService: TaskService) {}
}
