import { createReducer, on } from '@ngrx/store';
import { TeamType } from '../types';
import { TeamsActions } from './teams.actions';

export interface TeamsState {
  teams: TeamType[] | null;
  intialized: boolean;
  error: any;
}

const initialTeamsState: TeamsState = {
  teams: null,
  intialized: false,
  error: null,
};

const addTeam = (state: TeamsState, teamToAdd: TeamType): TeamsState => ({
  ...state,
  error: null,
  teams: [...state.teams, teamToAdd],
});

const updateTeam = (state: TeamsState, teamToUpdate: TeamType): TeamsState => {
  const teams = state.teams.map((team) => (team.id === teamToUpdate.id ? teamToUpdate : team));
  return { ...state, error: null, teams };
};

const deleteTeam = (state: TeamsState, teamToDelete: TeamType): TeamsState => {
  const teams = state.teams.filter((team) => team.id !== teamToDelete.id);
  return { ...state, error: null, teams };
};

export const teamsReducer = createReducer(
  initialTeamsState,
  on(TeamsActions.loadAllTeams, (state) => ({ ...state, error: null })),
  on(TeamsActions.loadTeamsSucceeded, (state, { teams }) => ({ ...state, teams, intialized: true, error: null })),
  on(TeamsActions.loadTeamsFailed, (state, { error }) => ({ ...state, intialized: false, error })),

  on(TeamsActions.addTeam, (state) => ({ ...state, error: null })),
  on(TeamsActions.addTeamSucceeded, (state, { team }) => addTeam(state, team)),
  on(TeamsActions.addTeamFailed, (state, { error }) => ({ ...state, error })),

  on(TeamsActions.updateTeam, (state) => ({ ...state, error: null })),
  on(TeamsActions.updateTeamSucceeded, (state, { team }) => updateTeam(state, team)),
  on(TeamsActions.updateTeamFailed, (state, { error }) => ({ ...state, error })),

  on(TeamsActions.deleteTeam, (state) => ({ ...state, error: null })),
  on(TeamsActions.deleteTeamSucceeded, (state, { team }) => deleteTeam(state, team)),
  on(TeamsActions.deleteTeamFailed, (state, { error }) => ({ ...state, error })),
);
