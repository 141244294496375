import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getIcon',
})
export class GetIconPipe implements PipeTransform {
  transform(value: string, showDisabledIcon?: boolean): string {
    switch (value) {
      case 'BLBokslut':
        return '/assets/blb20.png';
      case 'BLSkatt':
        return '/assets/bls20.png';
      case 'BL':
        return showDisabledIcon ? '/assets/bla20_disabled.png' : '/assets/bla20.png';
      default:
        return '';
    }
  }
}
