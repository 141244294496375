const isValidParam = (param: string) => param.trim().startsWith('.');

const parseAccept = (accept: string): string[] => {
  const params = accept.split(',');
  const output = [];

  for (let index = 0; index < params.length; index += 1) {
    const param = params[index];
    if (isValidParam(param)) {
      output.push(param.trim().substring(1).toUpperCase());
    }
  }

  return output;
};

export const getHumanizedFileAcceptString = (accept: string): string | null => {
  if (!accept) {
    return null;
  }

  const params = parseAccept(accept);
  if (!params.length) {
    return null;
  }

  if (params.length === 1) {
    return params[0];
  }

  return `${params.slice(0, -1).join(', ')} eller ${params.slice(-1)}`;
};
