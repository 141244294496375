import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AssignmentService } from '../../services/assignment.service';
import { AssignmentActions } from './assignments.actions';

@Injectable()
export class AssignmentEffects {
  loadAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentActions.loadAllAssignments),
      switchMap(() =>
        this.assignmentService.getAssignmentTypes().pipe(
          map((assignments) => AssignmentActions.loadAssignmentsSucceeded({ assignments })),
          catchError((error: unknown) => of(AssignmentActions.loadAssignmentsFailed({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private assignmentService: AssignmentService) {}
}
