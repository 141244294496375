<div>
  <h1 class="!text-base">Klicka på ikonen nedan för att öppna {{ client.name }} i något av programmen:</h1>

  <div class="flex mt-5" *ngIf="applicationLinksGroups$ | async as groups">
    <div class="flex flex-col space-y-3" *ngFor="let group of groups">
      <ng-container *ngIf="group.type === 'BL'; else defaultApp">
        <div
          *ngFor="let app of group.links"
          class="app-link mr-10 font-semibold"
          [ngClass]="{ 'disabled-app-link': app.disabled }"
        >
          <app-bla-icon-button [title]="'&nbsp;BL Administration'" [client]="client"></app-bla-icon-button>
        </div>
      </ng-container>

      <ng-template #defaultApp>
        <div
          (click)="openAgentApplication(app)"
          *ngFor="let app of group.links"
          class="app-link mr-10 font-semibold"
          [ngClass]="{ 'disabled-app-link': app.disabled }"
        >
          <img [src]="app.type | getIcon" class="mr-2 inline" alt="{{ app.title }}" /><span>{{ app.title }}</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
