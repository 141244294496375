import { createActionGroup, props } from '@ngrx/store';
import { CreditInformationType } from '../types/credit-information.types';

export const CreditInformationActions = createActionGroup({
  source: 'Credit Information',
  events: {
    Load: props<{ corporateIdentity: string }>(),
    'Load Succeeded': props<{ creditInformation: CreditInformationType }>(),
    'Load Failed': props<{ error: unknown }>(),
  },
});
