import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, distinctUntilKeyChanged, mergeMap, of, switchMap } from 'rxjs';
import { PartnerProgramService } from '@app/core/services/partnerprogram.service';
import { PartnerProgramActions } from './partnerprogram.actions';
import { ToastActions } from '../toast/toast.actions';

@Injectable()
export class PartnerProgramEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnerProgramActions.load),
      distinctUntilKeyChanged('startDate'),
      switchMap(({ startDate }) =>
        this.partnerProgramService.getFirm('sv', startDate).pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((data) => [PartnerProgramActions.loadSucceeded({ startDate, data })]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) => of(PartnerProgramActions.loadFailed({ error }))),
        ),
      ),
    ),
  );

  loadBenefits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnerProgramActions.loadBenefits),
      switchMap(() =>
        this.partnerProgramService.getBenefits().pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((benefits) => [PartnerProgramActions.loadBenefitsSucceeded({ benefits })]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) => of(PartnerProgramActions.loadBenefitsFailed({ error }))),
        ),
      ),
    ),
  );

  loadProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnerProgramActions.loadProperties),
      switchMap(() =>
        this.partnerProgramService.getProperties().pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((properties) => [PartnerProgramActions.loadPropertiesSucceeded({ properties })]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) => of(PartnerProgramActions.loadPropertiesFailed({ error }))),
        ),
      ),
    ),
  );

  loadLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnerProgramActions.loadLevels),
      switchMap(() =>
        this.partnerProgramService.getLevels().pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((levels) => [PartnerProgramActions.loadLevelsSucceeded({ levels })]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) => of(PartnerProgramActions.loadLevelsFailed({ error }))),
        ),
      ),
    ),
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PartnerProgramActions.save),
      switchMap(({ data }) =>
        this.partnerProgramService.updateFirm(data).pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((d) => [
            ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Byråprofilen has sparats' }),
            PartnerProgramActions.saveSucceeded({ data: d }),
          ]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) => of(PartnerProgramActions.saveFailed({ error }))),
        ),
      ),
    ),
  );

  // eslint-disable-next-line no-useless-constructor
  constructor(private actions$: Actions, private partnerProgramService: PartnerProgramService) {}
}
