import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NameDescriptionType, RuleType, TaskType, UserType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { BehaviorSubject, filter, Observable, withLatestFrom } from 'rxjs';
import { MemoizedSelector } from '@ngrx/store';
import { RuleActions } from '@app/core/state/rule/rule.actions';
import { RuleSelectors } from '@app/core/state/rule/rule.selectors';
import { CLIENT_RESPONSIBLE, LOGGED_IN_USER, SINGLE_EVENT } from '@app/shared/misc/constants';
import { DeadlineValueComponent } from '@app/shared/components/deadline/deadline-value.component';
import { ValidatorResult } from '@app/core/entity/validation';
import { Period } from '@app/core/entity/period';
import { ValidationStatus } from '@app/core/entity/validationStatus';
import { UserMultiSelectorComponent } from '@app/shared/components/user-multi-selector/user-multi-selector.component';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { ActivityStateMultiSelectorComponent } from '@app/shared/components/activity-states-multi-selector/activity-states-multi-selector.component';
import { ActivityState } from '@app/core/entity/activitystate';
import { CheckboxModule } from 'primeng/checkbox';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { TaskTemplateActions } from '@app/core/state/task-templates/task-templates.actions';
import { askArchiveTaskTemplate } from './askArchiveTaskTemplate';
import { AutoFocusModule } from 'primeng/autofocus';
import { FocusTrapModule } from 'primeng/focustrap';
import {
  DialogData,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';

interface Data extends DialogData {
  template: TaskType;
}

interface Result extends DialogResult {
  template: TaskType;
}

interface DeadlineGroup {
  template: FormControl<string>;
  paramType: FormControl<string>;
  param: FormControl<string>;
  status: FormControl<ValidatorResult>;
}

interface TaskTemplateDetailsForm {
  startDiff: FormControl<number>;
  reminderDiff: FormControl<number>;
  warningDiff: FormControl<number>;
  periodicity: FormControl<NameDescriptionType>;
  deadlineRule: FormGroup<DeadlineGroup>;
  userIds: FormControl<number[]>;
  users: FormControl<UserType[]>;
  preselectedCustomerResponsible: FormControl<boolean>;
  preselectedLoggedInUser: FormControl<boolean>;
  selectableStates: FormControl<ActivityState[]>;
  preferredTemplate: FormControl<boolean>;
  archived: FormControl<boolean>;
}

@Component({
  selector: 'app-firm-task-template-details',
  templateUrl: './firm-task-template-details.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutoFocusModule,
    FocusTrapModule,
    InputNumberModule,
    DropdownModule,
    CheckboxModule,
    DeadlineValueComponent,
    UserMultiSelectorComponent,
    ActivityStateMultiSelectorComponent,
  ],
  providers: [DialogService],
})
export class FirmTaskTemplateDetailsComponent
  extends FormDialogBaseComponent<Data, Result, TaskTemplateDetailsForm>
  implements OnInit, OnDestroy
{
  public rules$: Observable<RuleType[]>;
  public responsibleUserSelector: MemoizedSelector<AppState, UserType[]>;
  public scrollHeight: string;

  private rulesSub: BehaviorSubject<RuleType[]>;

  protected override onInitialized = () => {
    this.rulesSub = new BehaviorSubject([]);
    this.rules$ = this.rulesSub.asObservable();
    this.responsibleUserSelector = UserSelectors.activeUsersWithClientResponsibleAndLoggedInUser;

    this.initForm();
    this.subscribeToPerodicityChanges();
    this.subscribeToDeadlineTemplateChanges();
    this.subscribeToStore();
    this.subscribeToUserIdsChanges();

    const period: Period = { name: this.config.data.template.periodicity.name, description: '' };
    this.store.dispatch(RuleActions.load({ period }));
  };

  protected override onBeforeClose = ({ template }: Result) => {
    if (!template) {
      return;
    }

    this.store.dispatch(TaskTemplateActions.save({ template }));
  };

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<any>, 'data'> {
    return {
      width: '750px',
      draggable: false,
      resizable: false,
    };
  }

  get periodicities() {
    return this.config.data.template.selectablePeriodicities;
  }

  onCancel() {
    this.close({ template: null });
  }

  onSubmit() {
    const template = this.getDialogResult();
    this.close({ template });
  }

  onArchivedClicked() {
    askArchiveTaskTemplate(this.store, this.dialogService, this.getDialogResult(), (template) =>
      this.close({ template }),
    );
  }

  onDeadlineValidated(result: ValidatorResult) {
    this.form.controls.deadlineRule.controls.status.patchValue(result);
  }

  onDeadlineValueChanged(param: string) {
    this.form.controls.deadlineRule.controls.param.patchValue(param);
  }

  setDeadlineRuleTemplate = (rules: RuleType[]) => {
    const rule = rules.find((r) => r.code === this.config.data.template.deadlineRule.template);
    const { code, paramType } = rule || rules[0];

    if (this.form.controls.deadlineRule.controls.template.value !== code) {
      this.form.controls.deadlineRule.controls.template.patchValue(code);
    }

    if (this.form.controls.deadlineRule.controls.paramType.value !== paramType) {
      this.form.controls.deadlineRule.controls.paramType.patchValue(paramType);
    }
  };

  private initForm = () => {
    const {
      startDiff,
      reminderDiff,
      warningDiff,
      periodicity,
      preselectedCustomerResponsible,
      preselectedLoggedInUser,
      selectableStates,
      preferredTemplate,
      archived,
      taskGroupId,
      assignmentName,
      type,
    } = this.config.data.template;
    this.config.header = `${assignmentName} - ${type}`;
    const selectedUsers = this.getSelectedUsers();

    this.form = this.builder.group<TaskTemplateDetailsForm>(
      {
        startDiff: new FormControl({ value: startDiff, disabled: false }, Validators.required),
        reminderDiff: new FormControl({ value: reminderDiff, disabled: false }, Validators.required),
        warningDiff: new FormControl({ value: warningDiff, disabled: false }, Validators.required),
        periodicity: new FormControl({ value: periodicity, disabled: false }),
        deadlineRule: this.getDeadlineForm(),
        userIds: new FormControl({ value: selectedUsers.map((u) => u.id), disabled: false }),
        users: new FormControl({ value: selectedUsers, disabled: false }),
        preselectedCustomerResponsible: new FormControl({ value: preselectedCustomerResponsible, disabled: false }),
        preselectedLoggedInUser: new FormControl({ value: preselectedLoggedInUser, disabled: false }),
        selectableStates: new FormControl({ value: selectableStates, disabled: false }),
        preferredTemplate: new FormControl({ value: preferredTemplate, disabled: taskGroupId > 0 }),
        archived: new FormControl({ value: archived, disabled: false }),
      },
      { validators: this.validateRequiredFields() },
    );
  };

  private getSelectedUsers = (): UserType[] => {
    const { users, preselectedCustomerResponsible, preselectedLoggedInUser } = this.config.data.template;

    const selectedUsers = [...users];
    if (preselectedCustomerResponsible) {
      selectedUsers.push({ id: -1, name: CLIENT_RESPONSIBLE } as UserType);
    }

    if (preselectedLoggedInUser) {
      selectedUsers.push({ id: -2, name: LOGGED_IN_USER } as UserType);
    }

    return selectedUsers;
  };

  private getDeadlineForm = (): FormGroup<DeadlineGroup> => {
    const { periodicity, deadlineRule } = this.config.data.template;
    const disabled = this.isDeadlineRuleDisabled(periodicity.name);

    return new FormGroup<DeadlineGroup>({
      param: new FormControl({ value: deadlineRule.param, disabled }),
      paramType: new FormControl({ value: deadlineRule.paramType, disabled }),
      template: new FormControl({ value: deadlineRule.template, disabled }),
      status: new FormControl({ value: { status: ValidationStatus.Ok }, disabled: true }),
    });
  };

  private subscribeToPerodicityChanges = () => {
    this.form.controls.periodicity.valueChanges
      .pipe(
        filter(() => !this.form.pristine),
        filter((period) => Boolean(period)),
        this.takeUntilDestroyed(),
      )
      .subscribe((period) => {
        if (this.isDeadlineRuleDisabled(period.name)) {
          this.form.controls.deadlineRule.disable();
        }

        if (!this.isDeadlineRuleDisabled(period.name)) {
          this.form.controls.deadlineRule.enable();
        }

        this.store.dispatch(RuleActions.load({ period }));
      });
  };

  private subscribeToDeadlineTemplateChanges = () => {
    this.form.controls.deadlineRule.controls.template.valueChanges
      .pipe(
        filter(() => !this.form.pristine),
        filter(() => Boolean(this.rulesSub?.value?.length)),
        this.takeUntilDestroyed(),
      )
      .subscribe((code) => {
        const rules = this.rulesSub.value;
        const rule = rules.find((r) => r.code === code);
        const paramType = rule?.paramType ?? rules[0].paramType;

        this.form.controls.deadlineRule.controls.param.patchValue(null);
        this.form.controls.deadlineRule.controls.paramType.patchValue(paramType);
      });
  };

  private subscribeToStore = () => {
    this.store
      .select(RuleSelectors.selectRules)
      .pipe(
        filter((rules) => Boolean(rules?.length)),
        this.takeUntilDestroyed(),
      )
      .subscribe((rules) => {
        this.scrollHeight = getScrollHeightString(rules.length);
        this.rulesSub.next(rules);
        this.setDeadlineRuleTemplate(rules);
      });
  };

  private subscribeToUserIdsChanges = () => {
    this.form.controls.userIds.valueChanges
      .pipe(
        filter(() => !this.form.pristine),
        filter(() => Boolean(this.rulesSub?.value?.length)),
        filter((ids) => Boolean(ids)),
        withLatestFrom(this.store.select(this.responsibleUserSelector)),
        this.takeUntilDestroyed(),
      )
      .subscribe(([ids, users]) => {
        const hasPreselectedCustomerResponsible = ids.includes(-1);
        const hasPreselectedLoggedInUser = ids.includes(-2);

        this.form.controls.users.patchValue(
          users.filter((u) => ids.includes(u.id)).map(({ id, name, initials }) => ({ id, name, initials } as UserType)),
        );
        this.form.controls.preselectedCustomerResponsible.patchValue(hasPreselectedCustomerResponsible);
        this.form.controls.preselectedLoggedInUser.patchValue(hasPreselectedLoggedInUser);
      });
  };

  private isDeadlineRuleDisabled = (name: string) => name === SINGLE_EVENT;

  private validateRequiredFields =
    (): ValidatorFn =>
    (form: FormGroup<TaskTemplateDetailsForm>): ValidationErrors | null => {
      const selectableStates = this.getSelectableStatesValidation(form);
      const users = this.getResponsibleUsersValidation(form);
      const deadlineRule = this.getDeadlineRuleValidation(form);

      if (selectableStates || users || deadlineRule) {
        return {
          selectableStates,
          users,
          deadlineRule,
        };
      }

      return null;
    };

  private getSelectableStatesValidation = (form: FormGroup<TaskTemplateDetailsForm>): ValidationErrors | null => {
    const hasSelectableStates = form.controls.selectableStates.value?.length > 0;
    return hasSelectableStates ? null : { message: 'Det måste finnas minst en status' };
  };

  private getResponsibleUsersValidation = (form: FormGroup<TaskTemplateDetailsForm>): ValidationErrors | null => {
    const hasResponsibleUsers = form.controls.users.value?.length > 0;
    return hasResponsibleUsers ? null : { message: 'Det måste finnas minst en utförande handläggare' };
  };

  private getDeadlineRuleValidation = (form: FormGroup<TaskTemplateDetailsForm>): ValidationErrors | null => {
    const { status, title: message } = form.controls.deadlineRule.controls.status.value ?? {
      status: ValidationStatus.Ok,
      text: '',
    };
    return status === ValidationStatus.Ok ? null : { message };
  };

  private getDialogResult = (): TaskType => {
    const {
      periodicity,
      preferredTemplate,
      preselectedCustomerResponsible,
      preselectedLoggedInUser,
      reminderDiff,
      selectableStates,
      startDiff,
      users,
      warningDiff,
      archived,
    } = this.form.value;

    const { param, paramType, template } = this.form.controls.deadlineRule.value;

    const result = {
      ...this.config.data.template,
      deadlineRule: {
        ...this.config.data.template.deadlineRule,
        param,
        paramType,
        template,
      },
      periodicity,
      preferredTemplate,
      preselectedCustomerResponsible,
      preselectedLoggedInUser,
      reminderDiff,
      selectableStates,
      startDiff,
      users,
      warningDiff,
      archived,
    };

    return result;
  };
}
