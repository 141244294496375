<div class="grid grid-cols-5 gap-2">
  <div>
    <p>Kontakt</p>
    <input type="text" class="w-64" pInputText [(ngModel)]="contactModel.label" maxlength="100" />
  </div>
  <div>
    <p>Telefon</p>
    <input type="text" class="w-64" pInputText [(ngModel)]="contactModel.phone" maxlength="50" />
  </div>
  <div>
    <p>E-post</p>
    <input type="text" class="w-64" pInputText [(ngModel)]="contactModel.email" maxlength="50" />
  </div>
  <div>
    <p>Kommentar</p>
    <input type="text" class="w-64" pInputText [(ngModel)]="contactModel.comment" maxlength="50" />
  </div>
  <div class="self-end">
    <button
      type="button"
      class="btn btn-success !mr-3"
      (click)="saveContact()"
      [disabled]="client.archived || client.id === -1 || !isContactFormValid"
    >
      Spara
    </button>
    <button type="button" class="btn btn-warning" (click)="unSelect()">Töm</button>
  </div>
</div>
<br />
<br />
<app-table
  [columns]="columns"
  [data]="client.contacts"
  sortField="label"
  [columnSelector]="false"
  (rowClick)="onRowSelect($event)"
  rowClass="cursor-pointer"
  [paginator]="false"
  [useFixedStyle]="true"
>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="action" let-rowData="rowData">
    <span
      (click)="deleteContact(rowData.id)"
      class="far fa-trash-alt cursor-pointer"
      style="text-align: center"
      title="Ta bort kontakt"
    >
    </span>
  </ng-template>

  <ng-template tableTemplate="emptyMessage">Inga kontakter finns att visa</ng-template>
</app-table>
