import { createReducer, on } from '@ngrx/store';
import { AssignmentType } from '../types';
import { AssignmentActions } from './assignments.actions';

export interface AssignmentsState {
  assignments: AssignmentType[];
  error: any;
}

export const initialAssignmentsState: AssignmentsState = { assignments: [], error: undefined };

export const assignmentsReducer = createReducer(
  initialAssignmentsState,
  on(AssignmentActions.loadAllAssignments, (_state) => initialAssignmentsState),
  on(AssignmentActions.loadAssignmentsSucceeded, (_state, { assignments }) => ({
    ...initialAssignmentsState,
    assignments,
  })),
  on(AssignmentActions.loadAssignmentsFailed, (_state, { error }) => ({ ...initialAssignmentsState, error }))
);
