import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Benefit, BenefitsDto, Level, LevelDto, PartnerFirm } from '@app/core/state/types/partner.types';
import { BlFrontendBadgeComponent } from '@app/core/wrappers/bl-frontend-badge.component';
import { getLongMonthAndYear, getMonthsBetweenDates } from '@app/shared/misc/dates';
import { PartnerProgramFallenOutAlert } from '../partnerprogram-fallenout-alert/partnerprogram-fallenout-alert.component';

@Component({
  selector: 'app-partnerprogram-benefits',
  template: `
    <ng-container *ngIf="data?.benefitTypes?.length && visibleLevels">
      <div class="max-w-screen-xl">
        <app-partnerprogram-fallenout-alert
          [firm]="firm"
          (openTab)="openTab.emit($event)"></app-partnerprogram-fallenout-alert>
        <table
          class="table-fixed w-full border-separate border-spacing-0"
          *ngFor="let type of data.benefitTypes; let tableIndex = index">
          <thead>
            <ng-container *ngIf="tableIndex === 0">
              <!-- Start of fake row for the top part of the selected level marker -->
              <tr>
                <th class="w-2/5">
                  <span class="text-xl font-bold">&nbsp;</span>
                </th>
                <th
                  [ngClass]="{ 'selected-level-top': visibleLevel.value === level }"
                  *ngFor="let visibleLevel of visibleLevels">
                  <span class="text-base font-semibold text-bl-blue-light-600">&nbsp;</span>
                </th>
                <th class="w-1/5">
                  <span>&nbsp;</span>
                </th>
                <th class="w-4">
                  <span class="text-base font-semibold text-bl-blue-light-600">&nbsp;</span>
                </th>
              </tr>
              <!-- End of fake row -->
            </ng-container>
            <ng-container *ngIf="tableIndex !== 1">
              <tr>
                <th class="w-2/5 py-2.5" [ngClass]="{ 'pt-8': tableIndex > 0 }">
                  <span class="text-xl font-bold" *ngIf="tableIndex === 0">Finansiella förmåner</span>
                  <span class="text-xl font-bold" *ngIf="tableIndex === 2">Marknadsföring och tillväxt</span>
                </th>
                <th
                  class="text-center py-2.5 pt-8"
                  [ngClass]="{ 'selected-level': visibleLevel.value === level, 'pt-8': tableIndex > 0 }"
                  *ngFor="let visibleLevel of visibleLevels">
                  <span class="text-base font-semibold text-bl-blue-light-600">{{ visibleLevel.label }}</span>
                </th>
                <th class="w-1/5">
                  <span>&nbsp;</span>
                </th>
                <th class="w-4 py-2.5" [ngClass]="{ 'pt-8': tableIndex > 0 }">
                  <span class="text-base font-semibold text-bl-blue-light-600">&nbsp;</span>
                </th>
              </tr>
            </ng-container>
            <ng-container *ngIf="tableIndex === 1">
              <tr>
                <th class="w-2/5">
                  <span class="text-xl font-bold"></span>
                </th>
                <th
                  class="text-center"
                  [ngClass]="{ 'selected-level': visibleLevel.value === level }"
                  *ngFor="let visibleLevel of visibleLevels">
                  <span class="text-base font-semibold text-bl-blue-light-600">&nbsp;</span>
                </th>
                <th class="w-1/5">
                  <span>&nbsp;</span>
                </th>
                <th class="w-4">
                  <span class="text-base font-semibold text-bl-blue-light-600">&nbsp;</span>
                </th>
              </tr>
            </ng-container>
          </thead>
          <tbody>
            <tr>
              <ng-container *ngIf="tableIndex <= 1">
                <td class="pl-4 py-4">
                  <span class="text-sm font-bold  text-bl-grey-900">{{ type.label }}</span>
                </td>
              </ng-container>
              <ng-container *ngIf="tableIndex > 1">
                <td class="pl-4">
                  <span class="text-sm font-bold  text-bl-grey-900">{{ type.label }}</span>
                </td>
              </ng-container>
              <td
                class="text-center"
                [ngClass]="{ 'selected-level': visibleLevel.value === level, 'py-4': tableIndex <= 1 }"
                *ngFor="let visibleLevel of visibleLevels">
                <span class="text-bl-blue-light-500">&nbsp;</span>
              </td>
              <td [ngClass]="{ 'py-4': tableIndex <= 1 }"><span>&nbsp;</span></td>
              <td><span>&nbsp;</span></td>
            </tr>
            <tr *ngFor="let benefit of type.benefits; let rowIndex = index">
              <td class="pl-4 py-4">
                <span class="text-sm font-medium  text-bl-grey-900">{{ benefit.label }}</span>
              </td>
              <td
                class="text-center py-4"
                [ngClass]="{ 'selected-level': visibleLevel.value === level }"
                *ngFor="let visibleLevel of visibleLevels">
                <span class="text-bl-blue-light-500">
                  <i *ngIf="containsLevel(benefit.levels, visibleLevel.value)" class="far fa-check fa-lg"></i>
                </span>
              </td>
              <td class="py-4">
                <div class="flex justify-center">
                  <ng-container *ngIf="benefit.conditionShortText">
                    <bl-frontend-badge [text]="benefit.conditionShortText" variant="neutral" icon="lock" />
                  </ng-container>
                  <ng-container *ngIf="isComingSoon(benefit)">
                    <bl-frontend-badge
                      [text]="'Startar ' + getMonthAndYearString(benefit)"
                      variant="warning"
                      icon="exclamation-circle" />
                  </ng-container>
                  <ng-container *ngIf="isComingLater(benefit)">
                    <bl-frontend-badge text="Kommer senare" variant="success" icon="calendar-exclamation" />
                  </ng-container>
                </div>
              </td>
              <td><span>&nbsp;</span></td>
            </tr>
            <ng-container *ngIf="tableIndex === data.benefitTypes.length - 1">
              <!-- Fake row for the bottom part of the selected level marker -->
              <tr>
                <td class="pl-4 py-2 fake">
                  <span class="text-sm font-medium  text-bl-grey-900">&nbsp;</span>
                </td>
                <td
                  class="py-2 fake"
                  [ngClass]="{ 'selected-level-bottom': visibleLevel.value === level }"
                  *ngFor="let visibleLevel of visibleLevels">
                  <span class="text-bl-blue-light-500">&nbsp;</span>
                </td>
                <td class="py-2 fake">
                  <span>&nbsp;</span>
                </td>
                <td class="w-4 py-2 fake">
                  <span class="text-bl-blue-light-500">&nbsp;</span>
                </td>
              </tr>
              <!-- End of fake row -->
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
  `,
  styles: [
    `
      tr:first-child td:first-child:not(.fake):not(.selected-level) {
        border-top-left-radius: 0.5rem;
      }
      tr:first-child td:last-child:not(.fake):not(.selected-level) {
        border-top-right-radius: 0.5rem;
      }
      tr.footer td:first-child:not(.fake):not(.selected-level) {
        border-bottom-left-radius: 0.5rem;
      }
      tr.footer td:last-child:not(.fake):not(.selected-level) {
        border-bottom-right-radius: 0.5rem;
      }
      td:not(.fake):not(.selected-level) {
        @apply border-b;
        @apply border-spacing-0;
        @apply bg-white;
        @apply border-bl-grey-200;
      }
      th.selected-level,
      td.selected-level {
        @apply border-l-2;
        @apply border-r-2;
        @apply border-bl-blue-light-500;
      }
      td.selected-level {
        @apply bg-white;
        @apply border-b;
        border-bottom-color: var(--bl-grey-200);
      }
      th.selected-level-top {
        @apply border-l-2;
        @apply border-r-2;
        @apply border-t-2;
        @apply rounded-t-lg;
        @apply border-bl-blue-light-500;
      }
      td.selected-level-bottom {
        @apply border-l-2;
        @apply border-r-2;
        @apply border-b-2;
        @apply rounded-b-lg;
        @apply border-bl-blue-light-500;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, BlFrontendBadgeComponent, PartnerProgramFallenOutAlert],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramBenefitsComponent {
  @Input() firm: PartnerFirm | null;
  @Input() level: number;
  @Input() data: BenefitsDto;
  @Input()
  set levels(levels: LevelDto[]) {
    this.visibleLevels = levels?.filter((level) => level.value > 0);
  }

  @Output() openTab: EventEmitter<number> = new EventEmitter();

  visibleLevels: LevelDto[] = [];

  containsLevel(levels: Level[], level: number) {
    return levels.some((l) => l.value === level);
  }

  isComingSoon(benefit: Benefit) {
    const diff = getMonthsBetweenDates(new Date().toISOString(), benefit.validFrom);
    return diff > 0 && diff <= 4;
  }

  isComingLater(benefit: Benefit) {
    const diff = getMonthsBetweenDates(new Date().toISOString(), benefit.validFrom);
    return diff > 4;
  }

  getMonthAndYearString(benefit: Benefit) {
    return getLongMonthAndYear(benefit.validFrom, false);
  }
}
