import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientType } from '@app/core/state/types';
import {
  DialogData,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

interface Data extends DialogData {
  client: ClientType;
  featureToActivate: string;
}

interface Result extends DialogResult {
  client: ClientType;
}

@Component({
  selector: 'app-missing-cloud-api-key',
  template: `
    <div>
      <p class="text-base font-normal">
        {{ config.data.client?.name }} saknar molndatabasnyckel, vilket krävs för att aktivera
        {{ config.data.featureToActivate }}.
      </p>
      <div class="flex justify-end mt-8">
        <button type="button" (click)="this.close({ client: null })" class="btn">Avbryt</button>
        <button
          *ngIf="!config.data.client.cloudApiKey"
          (click)="this.close({ client: config.data.client })"
          class="btn btn-primary ml-4">
          Ange molndatabasnyckel
        </button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class MissingCloudApiKeyDialog extends FormDialogBaseComponent<Data, Result> {
  protected override onInitialized = () => {
    this.config.header = `Aktivering av ${this.config.data.featureToActivate}`;
  };

  public static override getDialogConfig(): Omit<DynamicDialogConfig<Data>, 'data'> {
    return {
      width: '400px',
      closable: false,
      draggable: false,
      modal: true,
    };
  }
}
