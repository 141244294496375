<div class="flex">
  <div class="col-md-8 border-r border-bl-grey-400">
    <h1 class="!text-base">Molndatabasnyckel</h1>
    <p class="mb-0">Molndatabasnyckel krävs för att vi ska kunna hämta information från molndatabasen.</p>
    <p>Informationen använder vi i ex.vis. fliken Aviseringar m.m. samt Rådgivarverktyget.</p>
    <p>För att ange molndatabasnyckel finns två tillvägagångssätt:</p>
    <ol style="padding-left: 25px">
      <li>Klicka på knappen "Hämta molndatabasnyckel från BLA" här nedanför.</li>
      <li class="mt-3">
        Klicka på redigeraikonen (
        <svg
          aria-hidden="true"
          focusable="false"
          class="w-4 h-4 inline"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path
            fill="currentColor"
            d="M417.8 315.5l20-20c3.8-3.8 10.2-1.1 10.2 4.2V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h292.3c5.3 0 8 6.5 4.2 10.2l-20 20c-1.1 1.1-2.7 1.8-4.2 1.8H48c-8.8 0-16 7.2-16 16v352c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16V319.7c0-1.6.6-3.1 1.8-4.2zm145.9-191.2L251.2 436.8l-99.9 11.1c-13.4 1.5-24.7-9.8-23.2-23.2l11.1-99.9L451.7 12.3c16.4-16.4 43-16.4 59.4 0l52.6 52.6c16.4 16.4 16.4 43 0 59.4zm-93.6 48.4L403.4 106 169.8 339.5l-8.3 75.1 75.1-8.3 233.5-233.6zm71-85.2l-52.6-52.6c-3.8-3.8-10.2-4-14.1 0L426 83.3l66.7 66.7 48.4-48.4c3.9-3.8 3.9-10.2 0-14.1z"
          ></path>
        </svg>
        ) här nedanför och fyll i molndatabasnyckeln manuellt.
        <p class="mt-1">
          Du hittar molndatabasnyckeln enklast i BL Administration under snabbmenyn Information. <br />
          Klicka där på företagsnamnet. Du får då upp teknisk information om företaget och kan kopiera
          Molndatabasnyckeln.
        </p>
      </li>
    </ol>
    <div class="flex">
      <div class="flex items-center"><span class="font-semibold mr-2">Molndatabasnyckel:</span></div>
      <div class="flex items-center flex-grow">
        <span class="p-2 border border-bl-grey-500 bg-bl-grey-100">{{
          client.cloudApiKey || '-- Molndatabaskoppling saknas --'
        }}</span>

        <button
          type="button"
          class="btn btn-primary ml-2"
          (click)="showEditCloudApiKeyField()"
          title="Redigera molndatabasnyckeln"
        >
          <i class="far fa-edit"></i>
        </button>
        <button
          type="button"
          class="btn btn-primary ml-2"
          (click)="deleteCloudKey()"
          title="Ta bort molndatabasnyckeln"
          *ngIf="!client.archived && client?.cloudApiKey"
        >
          <i class="far fa-trash-alt"></i>
        </button>
        <button
          type="button"
          class="btn btn-primary ml-2"
          (click)="connectCloudCompany()"
          title="Hämta molndatabasnyckel från BLA"
          [disabled]="!isPossibleToConnectToCloudCompany"
        >
          <i class="fas fa-cloud-download"></i>
        </button>
      </div>
    </div>

    <ng-container *ngIf="editCloudApiKey">
      <div class="mt-5">
        <input
          type="text"
          [(ngModel)]="editCloudApiKeyValue"
          (keydown.space)="$event.preventDefault()"
          class="mr-2 mt-2 w-2/5"
          pInputText
        />
        <button
          class="btn btn-primary ml-2"
          (click)="updateCloudApiKey()"
          type="submit"
          [disabled]="updatingCloudApiKey || !editCloudApiKeyValue?.trim()"
        >
          Spara ny molndatabasnyckel
        </button>
      </div>
      <div *ngIf="invalidCloudApiKeyError" class="text-bl-red-500">
        Molndatabasnyckeln hittades inte. Kontrollera att du har skrivit in den rätt.
      </div>
    </ng-container>
  </div>
  <div class="col-md-4">
    <div class="pl-8">
      <h1 class="!text-base">Inställningar för Aviseringar</h1>
      <app-permission-selector
        [clientId]="active ? client.id : undefined"
        [disabled]="!(hasAccessToChange$ | async)?.value"
        [disabledTooltip]="missingAccessTooltipText$ | async"
        (change)="permissionUpdated($event)"
      ></app-permission-selector>
    </div>
  </div>
</div>

<div class="mt-12 mb-6 border-t border-bl-grey-400">
  <h1 class="!text-base">Byråstödsnyckel</h1>
  <p class="mb-4">
    Byråstödsnyckeln krävs för att kunna ändra status på aktiviteter direkt från BL Administration, BL Bokslut och BL
    Skatt.
  </p>
  <div class="flex">
    <div class="flex items-center">
      <span class="font-semibold mr-2">Byråstödsnyckel:</span>
    </div>
    <div class="flex">
      <span class="p-2 border border-bl-grey-500 bg-bl-grey-100" *ngIf="client.alertIdentity">{{
        client.alertIdentity
      }}</span>
      <button type="button" class="btn btn-primary ml-2" (click)="copyText()" title="Kopiera Byråstödsnyckeln">
        <i class="far fa-copy"></i>
      </button>
      <button
        type="button"
        class="btn btn-primary ml-2"
        style="width: 280px"
        (click)="displayNewHash = true"
        [disabled]="client.archived || client.id === -1"
      >
        Skapa ny Byråstödsnyckel
      </button>
    </div>
  </div>

  <ng-container *ngIf="active">
    <div class="flex mt-16 mb-6 border-t border-bl-grey-400">
      <app-application-links [client]="client" [active]="active"></app-application-links>
      <app-application-files></app-application-files>
    </div>
  </ng-container>
</div>

<a href="#" id="cloudServiceAgentCaller"></a>

<p-dialog header="Ny Byråstödsnyckel?" [(visible)]="displayNewHash" showEffect="fade" [draggable]="false">
  <p>
    När en ny Byråstödsnyckel skapas så frånkopplas alla program och tjänster som eventuellt använder den befintliga.
  </p>
  <p>
    För att börja använda den nya nyckeln måste berörda BL-program startas via Byråstödet eller ändras manuellt i
    respektive program.
  </p>
  <p class="mt-6">Vill du fortsätta?</p>
  <p-footer>
    <button type="button" class="btn btn-success" (click)="createNewHash(true)">Ja</button>
    <button type="button" class="btn btn-warning" (click)="createNewHash(false)">Nej</button>
  </p-footer>
</p-dialog>

<p-dialog
  header="Bekräfta molndatabasnyckel"
  [(visible)]="displayMismatchCloudApiKeyWarning"
  [style]="{ width: '400px' }"
  [closable]="false"
  [closeOnEscape]="false"
  showEffect="fade"
  [draggable]="false"
>
  <p>Organisationsnumret i molndatabasen stämmer inte överens med klientkortet.</p>
  <p>Molndatabasens org.nr är {{ cloudVerifyResponse?.orgNumber }} och tillhör {{ cloudVerifyResponse?.name }}.</p>
  <p>Vill du fortsätta?</p>
  <p-footer>
    <button type="button" class="btn btn-success" (click)="confirmMismatchWarning()">Ja</button>
    <button type="button" class="btn btn-warning" (click)="dismissMismatchWarning()">Nej</button>
  </p-footer>
</p-dialog>

<p-dialog
  header="Koppling till Rådgivarverktyget"
  [(visible)]="displayAdvisoryDeletionInfoDialog"
  [style]="{ width: '400px' }"
  [closable]="false"
  [closeOnEscape]="false"
  showEffect="fade"
  [draggable]="false"
>
  <p class="mb-3">Ni verkar har en koppling till Rådgivarverktyget och kan därför inte ta bort molndatabasnyckeln.</p>
  <p-footer>
    <button type="button" class="btn btn-warning" (click)="displayAdvisoryDeletionInfoDialog = false">Stäng</button>
  </p-footer>
</p-dialog>

<p-dialog
  header="Bekräfta borttagning av molndatabasnyckel"
  [(visible)]="displayDeletionCloudApiKeyWarning"
  [style]="{ width: '400px' }"
  [closable]="false"
  [closeOnEscape]="false"
  showEffect="fade"
  [draggable]="false"
>
  <p class="mb-3">Är du säker på att du vill ta bort molndatabasnyckeln?</p>
  <p-footer>
    <button type="button" class="btn btn-success" (click)="confirmDeletionCloudApiKeyWarning()">Ja</button>
    <button type="button" class="btn btn-warning" (click)="dismissDeletionCloudApiKeyWarning()">Nej</button>
  </p-footer>
</p-dialog>

<app-connect-cloud-company-dialog
  [client]="client"
  [(visible)]="displayConnectToCloudCompany"
  (updated)="onClientConnectedToCloudCompany($event)"
></app-connect-cloud-company-dialog>
