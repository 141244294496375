import { Injectable, Type, ViewContainerRef } from '@angular/core';
import { AddTaskComponent } from '../change/components/add-task/add-task.component';
import { ChangeCompanyTypeComponent } from '../change/components/change-company-type/change-company-type.component';
import { ChangeResponsibleUserComponent } from '../change/components/change-responsible-user/change-responsible-user.component';
import { ChangeSelectableStateComponent } from '../change/components/change-selectable-state/change-selectable-state.component';
import { ChangeTaskUserComponent } from '../change/components/change-selected-task-user/change-task-user.component';
import { ChangeStartdateComponent } from '../change/components/change-startdate/change-startdate.component';
import { RemoveTaskComponent } from '../change/components/remove-task/remove-task.component';
import {
  BulkActionType,
  BulkAddActionType,
  BulkChangeActionType,
  BulkRemoveActionType,
} from '../interfaces/bulk-change-action-type.enum';
import { IBulkChangeComponent } from '../interfaces/bulk-change.component';

@Injectable()
export class BulkComponentFactory {
  public getComponentInstanceByType(ref: ViewContainerRef, actionType: BulkActionType): IBulkChangeComponent {
    // clear/remove previous component in the view
    ref.clear();

    const instance = this.createInstance(ref, actionType);
    instance.actionType = actionType;

    return instance;
  }

  private createInstance(ref: ViewContainerRef, actionType: BulkActionType) {
    let component: Type<IBulkChangeComponent>;

    if ((BulkChangeActionType as Record<string, BulkChangeActionType>)[actionType]) {
      component = this.getComponentByChangeType(actionType as BulkChangeActionType);
    } else if ((BulkAddActionType as Record<string, BulkAddActionType>)[actionType]) {
      component = this.getComponentByAddType(actionType as BulkAddActionType);
    } else {
      component = this.getComponentByRemoveType(actionType as BulkRemoveActionType);
    }

    return ref.createComponent(component).instance;
  }

  private getComponentByChangeType(bulkChangeActionType: BulkChangeActionType): Type<IBulkChangeComponent> {
    switch ((BulkChangeActionType as Record<string, BulkChangeActionType>)[bulkChangeActionType]) {
      case BulkChangeActionType.CHANGE_RESPONSIBLE_USER:
        return ChangeResponsibleUserComponent;
      case BulkChangeActionType.CHANGE_TASK_USER:
        return ChangeTaskUserComponent;
      case BulkChangeActionType.CHANGE_STARTDATE:
        return ChangeStartdateComponent;
      case BulkChangeActionType.CHANGE_COMPANY_TYPE:
        return ChangeCompanyTypeComponent;
      case BulkChangeActionType.CHANGE_SELECTABLE_STATE:
        return ChangeSelectableStateComponent;
      default:
        throw new Error('Invalid type');
    }
  }

  private getComponentByAddType(bulkAddActionType: BulkAddActionType): Type<IBulkChangeComponent> {
    switch ((BulkAddActionType as Record<string, BulkAddActionType>)[bulkAddActionType]) {
      case BulkAddActionType.ADD_TASK:
        return AddTaskComponent;
      default:
        throw new Error('Invalid type');
    }
  }

  private getComponentByRemoveType(bulkRemoveActionType: BulkRemoveActionType): Type<IBulkChangeComponent> {
    switch ((BulkRemoveActionType as Record<string, BulkRemoveActionType>)[bulkRemoveActionType]) {
      case BulkRemoveActionType.REMOVE_TASK:
        return RemoveTaskComponent;
      default:
        throw new Error('Invalid type');
    }
  }
}
