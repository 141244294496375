import { createSelector } from '@ngrx/store';
import { CLIENT_RESPONSIBLE, LOGGED_IN_USER } from '@app/shared/misc/constants';
import { taskTemplateFeature } from './task-templates.reducers';
import { LoadingStatus, TaskType } from '../types';

const sortOnType = (a: TaskType, b: TaskType) => (a.type < b.type ? -1 : 1);

const getUsersAsString = (task: TaskType) => {
  const userNames = task.users.map((user) => user.name);

  if (task.preselectedCustomerResponsible) {
    userNames.push(CLIENT_RESPONSIBLE);
  }

  if (task.preselectedLoggedInUser) {
    userNames.push(LOGGED_IN_USER);
  }

  return userNames.join(', ');
};

const getStatusesAsString = (task: TaskType) => task.selectableStates.map((state) => state.description).join(', ');

const selectTaskTemplates = (onlyShowActiveTemplates: boolean) =>
  createSelector(taskTemplateFeature.selectTaskTemplatesState, (state) => {
    if (!state?.templates) {
      return [];
    }

    const templates = onlyShowActiveTemplates ? state.templates.filter((t) => !t.archived) : state.templates;
    const sortedTemplates = [...templates].sort(sortOnType).map((t) => ({
      ...t,
      usersAsString: getUsersAsString(t),
      statusesAsString: getStatusesAsString(t),
    }));
    return sortedTemplates;
  });

const selectLoading = createSelector(taskTemplateFeature.selectStatus, (state) => state === LoadingStatus.Loading);

export const TaskTemplateSelectors = {
  selectTaskTemplates,
  selectLoading,
};
