import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Permission, PermissionType, PermissionGroup } from '../entity/permission';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { AppState } from '../state/appState';
import { BLService } from './bl.service';

@Injectable({ providedIn: 'root' })
export class PermissionService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'permission';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  loadPermissions(clientId: number): Observable<Permission[]> {
    return this.http
      .get<boolean>(`${this.url}/${clientId}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  updatePermission(
    clientId: number,
    permissionType: PermissionType,
    permissionGroup: PermissionGroup,
    users: number[],
  ): Observable<Permission[]> {
    const body = { permissionKey: permissionType, permissionGroup, users };

    return this.http
      .put<boolean>(`${this.url}/${clientId}`, body, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }
}
