import { TaskType } from '@app/core/state/types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ClientTasksActions = createActionGroup({
  source: 'ClientTasks',
  events: {
    load: props<{ clientId: number }>(),
    'Load Succeeded': props<{ tasks: TaskType[] }>(),
    'Load Failed': props<{ error: unknown }>(),
    cleanUp: emptyProps(),
  },
});
