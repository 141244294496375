import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAX_CALENDAR_DATE, MIN_CALENDAR_DATE } from 'src/app/shared/misc/dates';
import { ValidationStatus } from './validationStatus';
import { getValidationStrings } from './validationStrings';
import { ValidationType } from './validationType';

export interface ValidateDateFnConfig {
  key: string;
  label?: string;
  canBeEmpty?: boolean;
}

export function isNullOrUndefined(value: unknown): boolean {
  // eslint-disable-next-line no-undefined
  return value === null || value === undefined;
}

export function validateValidatorResultFn(): ValidatorFn {
  // eslint-disable-next-line func-names
  return function (control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const { status, text, title }: ValidatorResult = control.value;
    return status !== ValidationStatus.Ok ? { message: `${title}${text ? `\n${text}` : ''}` } : null;
  };
}

export function validateSelectRequiredFn(message: string): ValidatorFn {
  // eslint-disable-next-line func-names
  return function (control: AbstractControl): ValidationErrors | null {
    return control?.value ? null : { message };
  };
}

export function validateMultiSelectRequiredFn(message: string): ValidatorFn {
  // eslint-disable-next-line func-names
  return function (control: AbstractControl): ValidationErrors | null {
    return control?.value?.length ? null : { message };
  };
}

function getValidationErrors({
  key,
  status,
  title,
  text,
}: {
  key: string;
  status: ValidationStatus;
  title: string;
  text: string;
}): ValidationErrors | null {
  return status !== ValidationStatus.Ok ? { [key]: { message: `${title}${text ? `\n${text}` : ''}` } } : null;
}

export interface ValidatorConfig {
  min: number;
  max: number;
  label?: string;
}

export interface ValidatorResult {
  status: ValidationStatus;
  title?: string;
  text?: string;
}

export interface Validator {
  validate: (value: string, config: ValidatorConfig) => ValidatorResult;
}

const noOpValidator: Validator = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate: (_value: string, _config: ValidatorConfig) => ({ status: ValidationStatus.Ok }),
};

const DATE_REGEX = /^(\d{4})([\/-])(\d{1,2})\2(\d{1,2})$/;

interface ValidateDateConfig {
  minDate?: Date;
  maxDate?: Date;
}

function getDateValidationStatus({
  date,
  minDate,
  maxDate,
}: {
  date: Date;
  minDate: Date;
  maxDate: Date;
}): ValidationStatus {
  if (date < minDate) {
    return ValidationStatus.BeforeMinDate;
  }

  if (date > maxDate) {
    return ValidationStatus.AfterMaxDate;
  }

  return ValidationStatus.Ok;
}

function validateDate(
  dateString: string,
  { minDate = MIN_CALENDAR_DATE, maxDate = MAX_CALENDAR_DATE }: ValidateDateConfig = {},
): ValidationStatus {
  // missing value
  if (!dateString) {
    return ValidationStatus.MissingValue;
  }

  // correct format
  const validFormat = dateString.match(DATE_REGEX);
  if (!validFormat) {
    return ValidationStatus.InvalidDateFormat;
  }

  // valid date, i.e. 2001-01-45 will return NaN
  const validDate = Date.parse(dateString);
  if (Number.isNaN(validDate)) {
    return ValidationStatus.InvalidDate;
  }

  // min and max dates
  const date = new Date(validDate);
  return getDateValidationStatus({ date, minDate, maxDate });
}

function validateNumber(value: number | null | undefined, min: number, max: number): ValidationStatus {
  if (Number.isNaN(value)) {
    return ValidationStatus.InvalidNumber;
  }

  if (isNullOrUndefined(value)) {
    return ValidationStatus.MissingValue;
  }

  if (value < min) {
    return ValidationStatus.LessThanMin;
  }

  if (value > max) {
    return ValidationStatus.GreaterThanMax;
  }

  return ValidationStatus.Ok;
}

const integerValidator: Validator = {
  validate: (value, { min, max, label }: ValidatorConfig) => {
    const absMin = Math.abs(min);
    const absMax = Math.abs(max);

    if (isNullOrUndefined(value)) {
      const { title, text } = getValidationStrings(ValidationStatus.MissingValue, { min: absMin, max: absMax, label });
      return { status: ValidationStatus.MissingValue, title, text };
    }

    const valueAsNumber = Number(value);
    const useNegativeValues = valueAsNumber < 0;

    if (useNegativeValues) {
      const status = validateNumber(valueAsNumber, absMax * -1, absMin * -1);
      const { title, text } = getValidationStrings(status, { min: absMax * -1, max: absMin * -1, label });
      return { status, title, text };
    }

    const status = validateNumber(valueAsNumber, absMin, absMax);
    const { title, text } = getValidationStrings(status, { min: absMin, max: absMax, label });
    return { status, title, text };
  },
};

const unsignedIntegerValidator: Validator = {
  validate: (value: string, { min, max, label }: ValidatorConfig) => {
    const absMin = Math.abs(min);
    const absMax = Math.abs(max);

    if (isNullOrUndefined(value)) {
      const { title, text } = getValidationStrings(ValidationStatus.MissingValue, { min: absMin, max: absMax, label });
      return { status: ValidationStatus.MissingValue, title, text };
    }

    const valueAsNumber = Number(value);

    if (valueAsNumber < 0) {
      const { title, text } = getValidationStrings(ValidationStatus.NegativeValue, { min: absMin, max: absMax, label });
      return { status: ValidationStatus.NegativeValue, title, text };
    }

    const status = validateNumber(valueAsNumber, absMin, absMax);
    const { title, text } = getValidationStrings(status, { min: absMin, max: absMax, label });
    return { status, title, text };
  },
};

const dateValidator: Validator = {
  validate: (value: string, { min, max, label }: ValidatorConfig) => {
    const minDate = min ? new Date(min) : MIN_CALENDAR_DATE;
    const maxDate = max ? new Date(max) : MAX_CALENDAR_DATE;
    const status = validateDate(value, { minDate, maxDate });
    const { title, text } = getValidationStrings(status, { minDate, maxDate, label });

    return { status, title, text };
  },
};

export function getValidator(type: ValidationType): Validator {
  if (type === ValidationType.INTEGER) {
    return integerValidator;
  }

  if (type === ValidationType.UNSIGNED_INTEGER) {
    return unsignedIntegerValidator;
  }

  if (type === ValidationType.DATE) {
    return dateValidator;
  }

  return noOpValidator;
}

export function getDateValidator({
  minDate = MIN_CALENDAR_DATE,
  maxDate = MAX_CALENDAR_DATE,
}: { minDate?: Date; maxDate?: Date } = {}) {
  return { ...dateValidator, min: minDate.getTime(), max: maxDate.getTime() };
}

export function getUnsignedIntegerValidator({ min = 1, max = 350 }: { min?: number; max?: number } = {}) {
  return { ...unsignedIntegerValidator, min, max };
}

export function validateFn(
  { key, label = '', canBeEmpty = false }: ValidateDateFnConfig,
  type: ValidationType,
): ValidatorFn {
  // eslint-disable-next-line func-names
  return function (control: AbstractControl): ValidationErrors | null {
    if (!control.value && canBeEmpty) {
      return null;
    }

    const { validate, min, max } = type === ValidationType.DATE ? getDateValidator() : getUnsignedIntegerValidator();
    const { status, title, text } = validate(control.value, { min, max, label });

    return getValidationErrors({ key, status, title, text });
  };
}

export function validateDateFn({ key, label = '', canBeEmpty = false }: ValidateDateFnConfig): ValidatorFn {
  return validateFn({ key, label, canBeEmpty }, ValidationType.DATE);
}

export function validateUnsignedIntegerFn({ key, label = '', canBeEmpty = false }: ValidateDateFnConfig): ValidatorFn {
  return validateFn({ key, label, canBeEmpty }, ValidationType.UNSIGNED_INTEGER);
}
