import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CreateTeamType, TeamType, UpdateTeamType } from '../types';

export const TeamsActions = createActionGroup({
  source: 'Teams',
  events: {
    'Load All Teams': emptyProps(),
    'Load Teams Succeeded': props<{ teams: TeamType[] }>(),
    'Load Teams Failed': props<{ error: any }>(),

    'Add Team': props<{ team: CreateTeamType }>(),
    'Add Team Succeeded': props<{ team: TeamType }>(),
    'Add Team Failed': props<{ error: any }>(),

    'Update Team': props<{ team: UpdateTeamType }>(),
    'Update Team Succeeded': props<{ team: TeamType }>(),
    'Update Team Failed': props<{ error: any }>(),

    'Delete Team': props<{ team: TeamType }>(),
    'Delete Team Succeeded': props<{ team: TeamType }>(),
    'Delete Team Failed': props<{ error: any }>(),
  },
});
