import { Directive, HostListener } from '@angular/core';
import { ToastActions } from '@app/core/state/toast/toast.actions';
import { AppState } from '@app/core/state/appState';
import { Store } from '@ngrx/store';
import { isValidCorporateIdentity } from '@bl/functions';

@Directive({
  selector: '[pasteValidCorporateIdentity]',
  standalone: true,
})
export class PasteValidCorporateIdentityDirective {
  constructor(private store: Store<AppState>) {}

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedText = event?.clipboardData?.getData('text') ?? '';
    const pastedTextWithNumbersOnly = pastedText.replace(/\D/g, '');
    if (pastedTextWithNumbersOnly.length === 10 && isValidCorporateIdentity(pastedTextWithNumbersOnly)) {
      document.execCommand('insertText', false, pastedTextWithNumbersOnly);
      return;
    }

    const trimmedPastedText = pastedTextWithNumbersOnly.substring(pastedTextWithNumbersOnly.length - 10);
    if (isValidCorporateIdentity(trimmedPastedText)) {
      document.execCommand('insertText', false, trimmedPastedText);
      return;
    }

    this.showError();
  }

  private showError() {
    this.store.dispatch(
      ToastActions.showWarnMessage({
        summary: 'Felaktigt organisationsnummer',
        detail: 'Värdet du försöker klistra in är inte ett korrekt organisationsnummer',
      }),
    );
  }
}
