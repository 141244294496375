import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ClientActions } from '@app/core/state/clients/clients.actions';
import { ClientType } from '@app/core/state/types';
import {
  DialogData,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { PleaseWaitComponent } from '@app/shared/components/please-wait/please-wait.component';
import { ofType } from '@ngrx/effects';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

interface Data extends DialogData {
  client: ClientType;
}

interface Result extends DialogResult {
  client: ClientType;
}

interface ActivateSustainabilityReportingDialogForm {
  agreement: FormControl<boolean>;
}

@Component({
  selector: 'app-activate-sustainability-reporting-dialog',
  template: `
    <div>
      <app-please-wait [hidden]="!activating"></app-please-wait>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <p>Du är nu på väg att aktivera kopplingen mot Wellfish.</p>
        <p>
          Genom att aktivera den så tillåter du att Wellfish får tillgång till dina uppgifter ur molndatabasen och kan
          hjälpa dig att automatisera din Hållbarhetsredovisning.
        </p>
        <p>
          För upprättande av hållbarhetsredovisning i Wellfish betalar du en styckekostnad per företag, se pris via
          länken nedan.
        </p>
        <p>
          <a href="//www.bjornlunden.se/program/hållbarhetsredovisning-för-byrån__8965" target="_blank"
            >För mer information och prisuppgift läs här>></a
          >
        </p>
        <p>Kontaktuppgift till Wellfish är <a href="mailto:info@wellfish.se">info&#64;wellfish.se</a></p>
        <div class="flex justify-center">
          <input formControlName="agreement" type="checkbox" name="agreement" id="agreement" />
          <label for="agreement" class="ml-2">Jag har läst och godkänner ovanstående villkor.</label>
        </div>
        <div class="flex justify-end mt-4">
          <button type="button" (click)="onClose()" class="btn">Avbryt</button>
          <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Aktivera</button>
        </div>
      </form>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PleaseWaitComponent],
})
export class ActivateSustainabilityReportingDialog extends FormDialogBaseComponent<
  Data,
  Result,
  ActivateSustainabilityReportingDialogForm
> {
  activating = false;

  protected override onInitialized = () => {
    this.form = this.builder.group({
      agreement: new FormControl(false, Validators.requiredTrue),
    });
    this.actions$
      .pipe(
        ofType(
          ClientActions.activateSustainabilityReportingSucceeded,
          ClientActions.activateSustainabilityReportingFailed,
        ),
        this.takeUntilDestroyed(),
      )
      .subscribe((action) => {
        this.activating = false;
        if (action.type === ClientActions.activateSustainabilityReportingFailed().type) {
          return;
        }

        this.close({ client: { ...this.config.data.client, sustainabilityReporting: true } });
      });
  };

  public onClose() {
    this.close({ client: this.config.data.client });
  }

  public onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.activating = true;
    this.store.dispatch(ClientActions.activateSustainabilityReporting({ clientId: this.config.data.client.id }));
  }

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<DialogData>, 'data'> {
    return {
      header: 'Aktivering av Hållbarhetsredovisning',
      modal: true,
      resizable: false,
      draggable: false,
      closable: false,
      closeOnEscape: false,
      width: '400px',
    };
  }
}
