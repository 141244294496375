<main class="flex w-full h-full">
  <section class="relative w-1/2">
    <div class="flex mb-2">
      <h4 class="mr-4">{{ firmData.corporateIdentity }}</h4>
      <h4>{{ firmData.name }}</h4>
    </div>
    <form [formGroup]="firmDataForm" (ngSubmit)="updateFirmData()">
      <label class="text-bl-grey" for="firm_adress">Adress</label>
      <input
        type="text"
        pInputText
        formControlName="address"
        name="firm_adress"
        class="w-full"
        placeholder="Ange adress"
      />
      <div class="flex mt-3">
        <div>
          <label class="!block text-bl-grey" for="firm_zipcode">Postnummer</label>
          <input
            pInputText
            id="zipcode"
            formControlName="zipcode"
            type="text"
            name="firm_zipcode"
            class="mr-2"
            placeholder="Ange postnummer"
          />
        </div>
        <div class="ml-2">
          <label class="!block text-bl-grey" for="firm_city">Ort</label>
          <input
            pInputText
            type="text"
            id="city"
            formControlName="city"
            name="firm_city"
            class="w-full"
            placeholder="Ange stad"
          />
        </div>
      </div>
      <div class="mt-3">
        <label class="text-bl-grey" for="firm_email">E-post</label>
        <input
          id="email"
          pInputText
          formControlName="email"
          class="w-full mt-3"
          type="text"
          name="firm_email"
          placeholder="Ange mailadress"
        />
      </div>
      <div class="mt-3">
        <label class="leading-tight text-bl-grey" for="firm_phone">Telefon</label>
        <input
          pInputText
          id="phone"
          formControlName="phone"
          class="w-full"
          type="text"
          name="firm_phone"
          placeholder="Ange telefonnummer"
        />
      </div>
      <div class="mt-3" formGroupName="properties">
        <h5 class="leading-tight text-bl-grey">Övrigt</h5>
        <p-checkbox
          [binary]="true"
          formControlName="include_activities_for_archived_clients"
          label="Inkludera aktiviteter för arkiverade klienter i vyn Alla aktiviteter"
        ></p-checkbox>
        <p-checkbox
          class="mt-3"
          [binary]="true"
          formControlName="show_hidden_databases_for_all_users"
          label="Dolda klienter på samarbetspaketsfliken, ska visas för alla medarbetare"
        ></p-checkbox>
      </div>
      <button type="submit" [disabled]="firmDataForm.pristine" class="btn btn-success mt-3">Spara byråuppgifter</button>
    </form>
  </section>
</main>
