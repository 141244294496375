/* eslint-disable max-classes-per-file */
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, OperatorFunction } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { catchErrorAndShowMessage } from '../misc/errorHandlers';
import { AppState } from '../state/appState';

export class Cache {
  cacheTime = 30 * 60 * 1000; // 30 min cache

  constructor(private data: Array<any> = null, private timestamp: number | null = null) {
    if (timestamp) {
      this.timestamp = this.timestamp + this.cacheTime;
    }
  }

  public getValue(): Array<any> {
    return this.data;
  }

  public hasCacheExpired(): boolean {
    return this.timestamp === null || this.timestamp < Date.now();
  }
}

export class CacheContainer {
  container = new Map<string, BehaviorSubject<Cache>>();

  constructor(keys: string[]) {
    keys.forEach((key) => this.container.set(key, new BehaviorSubject<Cache>(new Cache())));
  }

  getSubject(key: string): BehaviorSubject<Cache> {
    return this.container.get(key);
  }

  getCacheObject(key: string): Cache {
    return this.getSubject(key).getValue();
  }

  update(key: string, cacheObject: Cache) {
    this.getSubject(key).next(cacheObject);
  }

  clear(key: string) {
    this.update(key, new Cache());
  }

  clearAll() {
    this.container.forEach((_, key) => this.update(key, new Cache()));
  }
}

export class BLService {
  private cacheContainer: CacheContainer;
  private DEFAULT_CACHE_KEY = 'default';

  constructor(protected store: Store<AppState>) {}

  public useCache(keys: string[] = [this.DEFAULT_CACHE_KEY]): void {
    this.cacheContainer = new CacheContainer(keys);
  }

  public catchErrorAndShowMessage(): OperatorFunction<any, any> {
    return catchErrorAndShowMessage({ store: this.store });
  }

  protected hasCacheExpired(key: string = this.DEFAULT_CACHE_KEY): boolean {
    return this.cacheContainer.getCacheObject(key).hasCacheExpired();
  }

  protected updateCache(result: any[], key: string = this.DEFAULT_CACHE_KEY) {
    const hasResultValues = Array.isArray(result) && result.length > 0;

    if (!hasResultValues) {
      this.clearCache(key);
      return;
    }

    this.cacheContainer.update(key, new Cache(result, Date.now()));
  }

  protected clearCache(key: string = this.DEFAULT_CACHE_KEY) {
    this.cacheContainer.clear(key);
  }

  protected getCacheObservable<T>(key: string = this.DEFAULT_CACHE_KEY): Observable<T> {
    return this.cacheContainer
      .getSubject(key)
      .asObservable()
      .pipe(
        take(1),
        map((cacheObject: Cache) => <T>(<unknown>cacheObject.getValue())),
      );
  }

  protected getCacheValue<T>(key: string = this.DEFAULT_CACHE_KEY): T {
    return <T>(<unknown>this.cacheContainer.getCacheObject(key).getValue());
  }
}
