// Note: When change to angular/primeng 17 this export can be changed to an inline template instead of a string!

export const toolTipContent = `
<p class="font-semibold m-0">PTL Risknivå i Byråstöd</p>
<p class="font-semibold mt-3 mb-0">Vad är PTL Risknivå?</p>
Enligt Penningtvättslagen (PTL) ska företag kartlägga riskerna för penningtvätt och finansiering av terrorism i den
egna verk-samheten utifrån typ av verksamhet, omfattning och komplexitet.
<p class="font-semibold mt-3 mb-0">PTL Riskbedömning i Byråstöd</p>
I PTL Risknivå håller du koll på aktuell risknivå och skapar enkelt en ny riskbedömning när det är dags genom att
skapa en ny PTL-kontroll i Due PTL`;
