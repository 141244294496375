import { Component, OnDestroy, OnInit } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, BehaviorSubject, throwError, Subscription } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { AuthSelectors } from 'src/app/core/state/auth/auth.selectors';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { RoleType, UserType } from 'src/app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserActions } from 'src/app/core/state/users/users.actions';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';
import { AuthRoleSelectorEvent } from 'src/app/shared/components/auth-role-selector/auth-role-selector.component';
import { TableColumn } from 'src/app/shared/components/table/interfaces/table-column';
import { FilterConfig, filterType } from './firm-users.filter';

@Component({
  selector: 'app-firm-users',
  templateUrl: './firm-users.component.html',
})
export class FirmUsersComponent implements OnInit, OnDestroy {
  public filter$: Observable<FilterConfig>;
  public filteredUsers$: Observable<UserType[]>;
  public columns: TableColumn[];
  public showConfirmDialog: boolean;
  private filterBS: BehaviorSubject<FilterConfig>;
  private currentAuthUser: UserType;
  private userToDeactivate: UserType | null;
  private subscription: Subscription;

  constructor(private store: Store<AppState>) {
    this.columns = [
      { field: 'name', header: 'Namn' },
      { field: 'initials', header: 'Initialer', sortable: false, width: '12%' },
      { field: 'email', header: 'E-post' },
      { field: 'roleLabel', header: 'Roll', width: '16%' },
      { field: 'active', header: 'Synlig', sortable: false, width: '6%' },
      { field: 'action', header: '', sortable: false, width: '5%', visible: false },
    ];
    this.showConfirmDialog = false;
    this.userToDeactivate = null;
    this.subscription = new Subscription();
    this.filterBS = new BehaviorSubject<FilterConfig>({ includeInactive: false, searchString: '' });
    this.filter$ = this.filterBS.asObservable();
  }

  ngOnInit() {
    const allUsers$ = this.store.select(UserSelectors.allUsers);
    this.filteredUsers$ = combineLatest([allUsers$, this.filter$]).pipe(
      map(([allUsers, filter]) => filterType(allUsers, filter)),
    );

    this.subscription.add(this.subscribeToAuthUser());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  hasRightsToChangeInitials(user: UserType) {
    return this.hasRightsToChange() || this.currentAuthUser?.id === user.id;
  }

  hasRightsToChange() {
    return this.currentAuthUser?.role === RoleType.FIRM_ADMIN;
  }

  search(searchString: string) {
    this.filterBS.next({ ...this.filterBS.getValue(), searchString });
  }

  toggleIncludeInactiveUsers() {
    const currentFilter = this.filterBS.getValue();
    const toggledIncludeInactiveUser = !currentFilter.includeInactive;
    this.filterBS.next({ ...currentFilter, includeInactive: toggledIncludeInactiveUser });
  }

  activateUser(user: UserType) {
    this.saveUserType({ ...user, active: true });
  }

  deactivateUser(user: UserType) {
    this.userToDeactivate = { ...user, active: false };
    this.showConfirmDialog = true;
  }

  updateInitials(user: UserType, event: Event) {
    const newInitials = (event.target as HTMLInputElement).value;
    this.saveUserType({ ...user, initials: newInitials });
  }

  updateRole(event: AuthRoleSelectorEvent) {
    const user = { ...event.originalUser, role: event.value };
    this.saveUserType(user, event.cancel);
  }

  onConfirmDialogClosed(active: boolean) {
    if (!active) {
      this.userToDeactivate = null;
      return;
    }

    this.userToDeactivate.active = false;
    this.saveUserType(this.userToDeactivate);
  }

  private saveUserType(user: UserType, cancelFn = () => {}) {
    const missingAdminMessageAction = ToastActions.showErrorMessage({
      summary: 'Byråadministratör saknas',
      detail: 'Minst en Byråadministratör måste finnas!',
    });

    const isActiveAndAdmin = (user: UserType) => user.active && user.role === RoleType.FIRM_ADMIN;
    const isAtLeastOneAdmin = (users: UserType[]) => users.some(isActiveAndAdmin);

    this.store
      .select(UserSelectors.allUsers)
      .pipe(
        map((users: UserType[]) => users.filter((u) => u.id !== user.id)),
        map((users: UserType[]) => users.concat([user])),
        map((users: UserType[]) => {
          if (isAtLeastOneAdmin(users)) {
            return UserActions.saveUser({ user, errorHandler: this.handleSaveError(user.name) });
          }
          cancelFn();
          return missingAdminMessageAction;
        }),
        take(1),
      )
      .subscribe((action: Action) => this.store.dispatch(action));
  }

  private subscribeToAuthUser() {
    return this.store.select(AuthSelectors.selectAuthUser).subscribe((authUser) => {
      this.currentAuthUser = authUser;
      this.columns = this.getVisibleColumns();
    });
  }

  private getVisibleColumns() {
    return this.columns.map((col) => {
      if (col.field !== 'action') {
        return col;
      }
      return { ...col, visible: this.hasRightsToChange() };
    });
  }

  private handleSaveError(fullName: string) {
    return catchError((err: any) => {
      if (err?.ok) {
        return EMPTY;
      }

      if (err?.status === 422) {
        this.store.dispatch(
          ToastActions.showErrorMessage({
            summary: `Inaktivering av medarbetare (${fullName})`,
            detail: err?.error?.message ?? 'Ett fel har uppstått när en medarbetare inaktiverades',
            sticky: true,
          }),
        );
        return EMPTY;
      }

      return throwError(err);
    });
  }
}
