import { MainSpanType } from '../state/types';
import { Activity } from './activity';
import { CompositeBase } from './composite.base';

export class IncomeStatementComposite extends CompositeBase {
  client = '';
  clientId: number;
  corporateIdentity: string;
  customerNumber: string;
  responsible: string;
  fora: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
  };

  konu: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
  };

  eulo: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
  };

  eura: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
  };

  kuut: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
  };

  rest: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
  };

  sral: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
  };

  mainSpan: MainSpanType;

  protected getCompositeColumnTypePropertyNames(): string[] {
    return ['fora', 'konu', 'eulo', 'eura', 'kuut', 'rest', 'sral'];
  }

  protected createDefaultCompositeObjectForProperty() {
    return {
      activity: new Activity(),
      readyDate: 'false',
      task: { comment: '' },
      agentCall: '',
    };
  }

  static from(obj: unknown): IncomeStatementComposite {
    const composite = CompositeBase.fromObject(IncomeStatementComposite, obj);
    composite.populateTaskUsers();
    return composite;
  }
}
