import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoginStatus } from '../core/interceptors/auth.interceptor';
import { AuthActions } from '../core/state/auth/auth.actions';
import { AuthSelectors } from '../core/state/auth/auth.selectors';
import { ToastActions } from '../core/state/toast/toast.actions';
import { AppState } from '@app/core/state/appState';

@Component({
  templateUrl: './login.component.html',
})
export class LoginComponent {
  public loggingIn$: Observable<boolean>;

  get isChrome() {
    return window.navigator.userAgent.indexOf('Chrome') > 0 && window.navigator.userAgent.indexOf('Edge') === -1;
  }

  constructor(private activatedRoute: ActivatedRoute, private store: Store<AppState>) {
    this.loggingIn$ = store.select(AuthSelectors.selectLoadSpinnerState);
    this.clearSessionAndLoggedInUser();
    this.showSessionExpiredMessageIfRouteHasErrorParams();
  }

  public login() {
    this.store.dispatch(AuthActions.initLogin());
  }

  private clearSessionAndLoggedInUser() {
    this.store.dispatch(AuthActions.resetAuthStateAndStorage());
  }

  private showSessionExpiredMessageIfRouteHasErrorParams() {
    const error = this.activatedRoute.snapshot.queryParams.error;
    if (error && error === LoginStatus.UnAuthorized) {
      this.store.dispatch(
        ToastActions.showErrorMessage({
          summary: 'Utgången session',
          detail: 'Inloggningen är för gammal/ogiltig. Logga in igen för att fortsätta.',
        }),
      );
    }

    if (error && error === LoginStatus.UnAuthorizedInactive) {
      this.store.dispatch(
        ToastActions.showErrorMessage({
          summary: 'Inaktiverad användare',
          detail:
            'Din användare har blivit inaktiverad. Kontakta en byråadministratör för att aktivera din användare igen.',
        }),
      );
    }
  }
}
