import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { map, tap } from 'rxjs/operators';
import { ToastActions } from './toast.actions';

@Injectable()
export class ToastEffects {
  showInfoMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToastActions.showInfoMessage),
      map(({ detail, summary, sticky, button }) =>
        ToastActions.showMessage({ severity: 'info', summary, detail, sticky, button }),
      ),
    ),
  );

  showWarnMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToastActions.showWarnMessage),
      map(({ detail, summary, sticky, button }) =>
        ToastActions.showMessage({ severity: 'warn', summary, detail, sticky, button }),
      ),
    ),
  );

  showErrorMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToastActions.showErrorMessage),
      tap(({ error }) => {
        if (error) {
          // eslint-disable-next-line no-console
          console.log({ error });
        }
      }),
      map(({ detail, summary, sticky, button }) =>
        ToastActions.showMessage({ severity: 'error', summary, detail, sticky, button }),
      ),
    ),
  );

  showToastMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToastActions.showMessage),
        tap(({ severity, summary, detail, sticky, key, life, button }) =>
          this.messageService.add({
            severity,
            summary,
            detail,
            sticky,
            data: { button },
            key,
            life,
          }),
        ),
      ),
    { dispatch: false },
  );

  clearToastMessages$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToastActions.clearsAllToastMessages),
        tap(({ key }) => this.messageService.clear(key)),
      ),
    { dispatch: false },
  );

  // eslint-disable-next-line no-useless-constructor
  constructor(private actions$: Actions, private messageService: MessageService) {}
}
