import { Client } from '@app/core/entity/client';
import { isAdmin } from '@app/core/misc/isAdmin';
import { ClientType, FirmType, UserType } from '@app/core/state/types';
import { Observable, filter, map } from 'rxjs';

const hasClientAndFirm = (client: Client | ClientType, firm: FirmType) => Boolean(client && firm);

interface SustainabilityReportingInfo {
  canActivate: boolean;
  tooltip: string;
}

export const getSustainabilityReportingInfo = ({
  user,
  client,
  firm,
}: {
  user: UserType;
  client: ClientType;
  firm: FirmType;
}): SustainabilityReportingInfo => {
  if (!hasClientAndFirm(client, firm)) {
    return { canActivate: false, tooltip: 'Informationen om klient eller byrå saknas' };
  }

  if (!firm.sustainabilityReporting) {
    return {
      canActivate: false,
      tooltip:
        'Byråägaren behöver aktivera Hållbarhets- redovisning för byrån för att aktiveringen på en klient ska öppnas',
    };
  }

  if (!isAdmin(user) && user.id !== client?.responsible?.id) {
    return {
      canActivate: false,
      tooltip:
        'Bara nuvarande klientansvarig eller byråadministratörer kan aktivera eller avaktivera Hållbarhets- redovisningen',
    };
  }

  return { canActivate: true, tooltip: null };
};

export const getActivateOrDeactivateSustainabilityReportingInfo = (
  loggedInUser: Observable<UserType>,
  client: ClientType,
  firm: FirmType,
): Observable<SustainabilityReportingInfo> =>
  loggedInUser.pipe(
    filter((user) => Boolean(user)),
    map((user) => getSustainabilityReportingInfo({ user, client, firm })),
  );
