import { Span } from './span';
import { Activity } from './activity';
import { CompositeBase } from './composite.base';
import { StatusColor } from '../misc/status-color';
export class AccountingComposite extends CompositeBase {
  client: string;
  clientId: number;
  corporateIdentity: string;
  customerNumber: string;
  responsible: string;
  mainSpan: Span = new Span();
  accountingMaterial: { alertLevel: { name: ''; description: '' }; when: string };
  materialStatusColor = 'black';
  dags: { activity: Activity; readyDate: string; task: { comment: '' } };
  bokf: { activity: Activity; readyDate: string; task: { comment: '' } };
  kont: { activity: Activity; readyDate: string; task: { comment: '' } };
  avst: { activity: Activity; readyDate: string; task: { comment: '' } };
  rapp: { activity: Activity; readyDate: string; task: { comment: '' } };

  constructor() {
    super();
  }

  protected getCompositeColumnTypePropertyNames(): string[] {
    return ['dags', 'bokf', 'kont', 'avst', 'rapp'];
  }

  protected createDefaultCompositeObjectForProperty() {
    return {
      activity: new Activity(),
      readyDate: 'false',
      task: { comment: '' },
    };
  }

  static from(obj: any): AccountingComposite {
    const accountingComposite = CompositeBase.fromObject(AccountingComposite, obj);

    if (!accountingComposite.accountingMaterial) {
      accountingComposite.accountingMaterial = {
        alertLevel: { name: '', description: '' },
        when: '',
      };
    }

    if (!accountingComposite.mainSpan) {
      accountingComposite.mainSpan = new Span();
    }

    accountingComposite.updateMaterialStatusColor();
    accountingComposite.populateTaskUsers();

    return accountingComposite;
  }

  public updateMaterialStatusColor() {
    const alertLevelName = this.accountingMaterial.alertLevel.name;
    this.materialStatusColor = StatusColor.getColorByAlertName(alertLevelName);
  }
}
