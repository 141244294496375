import { DeadlineRuleType, NameDescriptionType, RuleType } from '@app/core/state/types';
import { DialogData, DialogForm, FormDialogBaseComponent } from '../form-dialog-base/form-dialog-base.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ValidationStatus } from '@app/core/entity/validationStatus';
import { Component } from '@angular/core';
import {
  ValidatorResult,
  validateDateFn,
  validateMultiSelectRequiredFn,
  validateValidatorResultFn,
} from '@app/core/entity/validation';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from '../calendar/calendar.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormUserSelectorComponent } from '../forms/form-user-select/form-user-select.component';
import { DropdownModule } from 'primeng/dropdown';
import { SINGLE_EVENT } from '@app/shared/misc/constants';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { RuleActions } from '@app/core/state/rule/rule.actions';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActivityStateMultiSelectorComponent } from '../activity-states-multi-selector/activity-states-multi-selector.component';
import { openDialog } from '@app/shared/misc/openDialog';
import { CreateActivityDialog } from './create-activity.dialog';
import { TaskActions } from '@app/core/state/tasks/task.actions';
import { Task } from '@app/core/entity/task';
import { User } from '@app/core/entity/user';
import { ofType } from '@ngrx/effects';
import { DeadlineValueComponent } from '../deadline/deadline-value.component';

export interface TaskDialogData extends DialogData {
  task: Task;
}

export interface TaskDialogResult extends DialogData {
  task: Task;
}

interface TaskDialogSpan extends DialogForm<any> {
  start: FormControl<string>;
  end: FormControl<string>;
}

interface TaskDialogDeadline extends DialogForm<any> {
  template: FormControl<string>;
  paramType: FormControl<string>;
  param: FormControl<string>;
}

interface TaskDialogPeriodicity extends DialogForm<any> {
  name: FormControl<string>;
}

interface TaskDialogFormInternal extends DialogForm<any> {
  deadlineStatus: FormControl<ValidatorResult>;
}

interface TaskDialogForm extends DialogForm<any> {
  span: FormGroup<TaskDialogSpan>;
  startDiff: FormControl<number>;
  reminderDiff: FormControl<number>;
  warningDiff: FormControl<number>;
  users: FormControl<User[]>;
  periodicity: FormGroup<TaskDialogPeriodicity>;
  deadlineRule: FormGroup<TaskDialogDeadline>;
  comment: FormControl<string>;
  selectableStates: FormControl<NameDescriptionType[]>;
  internal: FormGroup<TaskDialogFormInternal>;
}

@Component({
  selector: 'app-task-dialog-ngrx',
  templateUrl: './task.dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarComponent,
    InputNumberModule,
    FormUserSelectorComponent,
    DropdownModule,
    DeadlineValueComponent,
    ActivityStateMultiSelectorComponent,
  ],
})
export class TaskDialog extends FormDialogBaseComponent<TaskDialogData, TaskDialogResult, TaskDialogForm> {
  rulesBS: BehaviorSubject<RuleType[]>;
  rules$: Observable<RuleType[]>;

  onSubmit = () => {
    if (this.form.invalid) {
      this.close({ task: null });
      return;
    }

    const task: Task = {
      ...this.config.data.task,
      span: {
        ...this.config.data.task.span,
        start: this.form.value.span.start,
        end: this.form.value.span.end,
      },
      startDiff: this.form.value.startDiff,
      reminderDiff: this.form.value.reminderDiff,
      warningDiff: this.form.value.warningDiff,
      users: this.form.value.users,
      periodicity: { ...this.config.data.task.periodicity, name: this.form.value.periodicity.name },
      deadlineRule: {
        ...this.config.data.task.deadlineRule,
        param: this.form.value.deadlineRule.param,
        paramType: this.form.value.deadlineRule.paramType,
        template: this.form.value.deadlineRule.template,
      },
      comment: this.form.value.comment,
      selectableStates: this.form.value.selectableStates,
    };

    this.store.dispatch(TaskActions.save({ task }));
  };

  resetEndYear = () => {
    this.form.controls.span.controls.end.patchValue(null);
  };

  showCreateActivityDialog = () => {
    openDialog(this.dialogService, CreateActivityDialog, { taskId: this.config.data.task.id });
  };

  onDeadlineValidated = (result: ValidatorResult) => {
    this.form.controls.internal.controls.deadlineStatus.patchValue(result);
  };

  get showSingleEventUI(): boolean {
    return this.form.value.periodicity.name === SINGLE_EVENT;
  }

  protected override onInitialized = () => {
    setTimeout(
      () =>
        (this.config.header = `${this.config.data.task.client.name}: ${this.config.data.task.assignmentName} / ${this.config.data.task.type}`),
      100
    );
    this.rulesBS = new BehaviorSubject([]);
    this.createForm();

    this.form.controls.periodicity.valueChanges
      .pipe(
        filter(() => !this.form.pristine),
        filter((period) => Boolean(period?.name)),
        this.takeUntilDestroyed()
      )
      .subscribe((period) => {
        this.form.controls.deadlineRule.controls.param.patchValue('');
        this.setRuleValueInputSettings({ paramType: '', param: undefined, template: undefined });
        this.store.dispatch(RuleActions.load({ period: { name: period.name, description: '' } }));
      });

    this.form.controls.deadlineRule.controls.template.valueChanges
      .pipe(
        filter(() => !this.form.pristine),
        filter((ruleCode) => Boolean(ruleCode)),
        this.takeUntilDestroyed()
      )
      .subscribe((ruleCode) => {
        this.form.controls.deadlineRule.controls.param.patchValue('');
        const rule = this.rulesBS.value.find((r) => r.code === ruleCode);
        this.selectRule(rule);
      });

    this.actions$
      .pipe(ofType(TaskActions.saveSucceeded), this.takeUntilDestroyed())
      .subscribe(({ task }) => this.close({ task }));

    this.actions$.pipe(ofType(RuleActions.loadSucceeded), this.takeUntilDestroyed()).subscribe(({ rules }) => {
      this.rulesBS.next(rules);
      const rule = this.proposeSelectedRule(rules);
      this.form.controls.deadlineRule.controls.template.patchValue(rule.code);
      this.selectRule(rule);
    });

    this.rules$ = this.rulesBS.asObservable();
    this.store.dispatch(RuleActions.load({ period: this.config.data.task.periodicity }));
  };

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<TaskDialogData>, 'data'> {
    return {
      width: '600px',
      resizable: false,
      draggable: false,
      modal: true,
      closeOnEscape: true,
      closable: true,
    };
  }

  private createForm(): void {
    const validatorResult: ValidatorResult = { status: ValidationStatus.Ok };
    const { span, startDiff, warningDiff, reminderDiff, users, periodicity, deadlineRule, comment, selectableStates } =
      this.config.data.task;

    this.form = this.builder.group({
      span: new FormGroup({
        end: new FormControl(span.end, [validateDateFn({ key: 'end', canBeEmpty: true })]),
        start: new FormControl(span.start, [validateDateFn({ key: 'start' })]),
      }),
      startDiff: new FormControl(startDiff, Validators.required),
      reminderDiff: new FormControl(reminderDiff, Validators.required),
      warningDiff: new FormControl(warningDiff, Validators.required),
      users: new FormControl(users, [validateMultiSelectRequiredFn('En tjänst måste ha en utförande handläggare')]),
      periodicity: new FormGroup({
        name: new FormControl(periodicity.name),
      }),
      deadlineRule: new FormGroup({
        param: new FormControl(deadlineRule.param),
        paramType: new FormControl(deadlineRule.paramType),
        template: new FormControl(deadlineRule.template),
      }),
      comment: new FormControl(comment),
      selectableStates: new FormControl(selectableStates, [
        validateMultiSelectRequiredFn('En tjänst måste ha en valbar status'),
      ]),
      internal: new FormGroup({
        deadlineStatus: new FormControl(validatorResult, [validateValidatorResultFn()]),
      }),
    });
  }

  private setRuleValueInputSettings(deadlineRule: DeadlineRuleType) {
    const paramType = deadlineRule.paramType;

    if (paramType === '' && !this.form.controls.deadlineRule.controls.param.value) {
      this.form.controls.deadlineRule.controls.param.patchValue('');
    }
  }

  private proposeSelectedRule(rules: RuleType[]) {
    const matchingRule = rules.find((rule) => rule.code === this.form.value.deadlineRule.template);
    return matchingRule || rules[0];
  }

  private selectRule({ paramType }: RuleType) {
    this.form.controls.deadlineRule.controls.paramType.patchValue(paramType);
    this.setRuleValueInputSettings({
      param: this.form.controls.deadlineRule.controls.param.value,
      template: this.form.controls.deadlineRule.controls.template.value,
      paramType: this.form.controls.deadlineRule.controls.paramType.value,
    });
  }
}
