import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Client } from 'src/app/core/entity/client';
import { Todo } from 'src/app/core/entity/todo';
import { NotesService } from 'src/app/core/services/notes.service';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { AppState } from '@app/core/state/appState';
import { TableColumn } from '../../../../table/interfaces/table-column';
import { showTodoDialog } from '@app/shared/misc/showTodoDialog';
import { DialogService } from 'primeng/dynamicdialog';
import { filter } from 'rxjs';
import { toClientTypeTransformer, toSimpleClientTypeTransformer } from '@app/core/state/transformers/transformers';
import { ActivityState } from '@app/core/entity/activitystate';

@Component({
  selector: 'app-client-todos',
  templateUrl: './client.todos.component.html',
})
export class ClientTodosComponent implements OnInit {
  @Output() updateEmitter = new EventEmitter<boolean>();
  _client: Client;
  _displayDeleteDialog = false;
  clientTodos: Todo[] = [];
  _model: Todo = new Todo();
  pendingDeletion: Todo = new Todo();

  public columns: TableColumn[] = [
    { field: 'type.description', header: 'Typ', width: '90px' },
    { field: 'assignment.name', header: 'Uppdrag', width: '110px' },
    { field: 'entryDate', header: 'Noteringsdatum', width: '150px' },
    { field: 'text', header: 'Noteringstext' },
    { field: 'creator.initials', header: 'Skapad av', width: '120px' },
    { field: 'usernames', header: 'Utf handl', width: '120px' },
    { field: 'deadline', header: 'Deadline', width: '105px' },
    { field: 'status', header: 'Status', width: '125px', sortable: false },
    { field: 'action', header: '', width: '40px', sortable: false },
  ];

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private notesService: NotesService,
    private store: Store<AppState>,
    private dialogService: DialogService,
  ) {}

  @Input()
  set client(client: Client) {
    this._client = client;
    if (this._client.id > 0) {
      this.loadClientTodos();
    } else {
      this.clientTodos = [];
    }
  }

  get displayDeleteDialog() {
    return this._displayDeleteDialog;
  }

  ngOnInit() {}

  private loadClientTodos() {
    this.notesService.getByClientId(this._client.id).subscribe((todos) => {
      this.clientTodos = this.preprocessTodos(todos);
    });
  }

  private preprocessTodos(todos: Todo[]) {
    return todos
      .filter((t) => Boolean(t))
      .map((todo) => ({
        ...todo,
        usernames: (todo.users || []).map((user) => user.initials).join(', '),
        assignmentname: todo.assignment?.name || '', // not sure what this is for
        statename: todo.state?.name || '', // not sure what this is for
      }));
  }

  changeStateAndSave(newState: ActivityState, todo: Todo) {
    if (todo.state.name === newState.name) {
      return;
    }

    const state = this.getStateFromEvent(newState.name, todo);
    this.notesService.addTodo({ ...todo, state }).subscribe(() => {
      this.store.dispatch(ToastActions.showInfoMessage({ summary: 'Sparad', detail: 'Status ändrad' }));
      this.loadClientTodos();
    });
  }

  private getStateFromEvent(event: string, todo: Todo) {
    for (let i = 0; i < todo.selectableStates.length; i += 1) {
      if (todo.selectableStates[i].name === event) {
        return todo.selectableStates[i];
      }
    }
    return todo.selectableStates[0];
  }

  tryDeleteTodo(toDelete: Todo) {
    this._displayDeleteDialog = true;
    this.pendingDeletion = toDelete;
  }

  afterDeleteDialog(del: boolean) {
    if (del) {
      this.notesService.deleteTodo(this.pendingDeletion.id).subscribe(() => {
        this.store.dispatch(
          ToastActions.showInfoMessage({
            summary: 'Borttaget',
            detail: 'Noteringen / Att Göra-punkten har tagits bort',
          }),
        );
        this.loadClientTodos();
        this.pendingDeletion = new Todo();
      });
    } else {
      this.pendingDeletion = new Todo();
    }
    this._displayDeleteDialog = false;
  }

  showExistingTodo(model: Todo) {
    showTodoDialog({ notesService: this.notesService, dialogService: this.dialogService, id: model.id })
      .pipe(filter((result) => Boolean(result?.todo)))
      .subscribe(() => {
        this.loadClientTodos();
      });
  }

  showNewTodo() {
    showTodoDialog({
      notesService: this.notesService,
      dialogService: this.dialogService,
      id: -1,
      client: toSimpleClientTypeTransformer.transform(toClientTypeTransformer.transform(this._client)),
    })
      .pipe(filter((result) => Boolean(result?.todo)))
      .subscribe(() => {
        this.loadClientTodos();
      });
  }

  isDisabledSelector(todo: Todo) {
    return todo.users === undefined || todo.users.length === 0;
  }
}
