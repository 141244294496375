<app-please-wait [hidden]="!loading"></app-please-wait>
<form [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col" pFocusTrap>
  <div class="mb-5 flex justify-between">
    <div class="flex items-end" [ngClass]="{ 'w-3/4': !lastTaskGroupId, 'w-full': lastTaskGroupId > 0 }">
      <p class="!w-1/5 !mb-0" [ngClass]="{ 'w-1/6': lastTaskGroupId > 0 }">
        Ange startdatum, dvs. från vilken period tjänsten ska utföras
      </p>
      <div>
        <!-- <p>Föreslaget startdatum</p> -->
        <app-calendar
          formControlName="startDate"
          [style]="{ 'max-width': '160px' }"
          [invalid]="form.controls.startDate.invalid"
          [title]="
            form.controls.startDate.errors?.startDate?.message ||
            'ex. Om tjänsten ska utföras från oktober månad ange 2021-10-01'
          ">
        </app-calendar>
      </div>
      <div class="flex items-end" [ngClass]="{ 'ml-2': !lastTaskGroupId }">
        <app-task-groups-selector (onTaskGroupChanged)="onTaskGroupChanged($event)" [disabled]="loading">
        </app-task-groups-selector>
      </div>
    </div>
    <div class="flex">
      <div *ngIf="!lastTaskGroupId" class="flex items-end">
        <a class="btn btn-primary ml-4" (click)="loadProposals()" title="Hämta förslag från uppdragsmallarna">
          Importera från Uppdragsmallar
        </a>
      </div>
    </div>
  </div>
  <p-table
    [columns]="columns"
    [value]="taskTemplates$ | async"
    [selection]="selectedTaskTemplates"
    (selectionChange)="onTaskTemplatesSelected($event)"
    scrollHeight="310px"
    dataKey="id"
    sortField="assignmentName"
    #table>
    <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="table.filterGlobal($any($event.target).value, 'contains')"
            placeholder="Sök" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length + 1">
          <div class="my-3">{{ tableMessage }}</div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 3.4em">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th class="font-normal truncate" *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-task>
      <tr>
        <ng-container *ngIf="isExistingTemplate(task)">
          <td>
            <p-checkbox formControlName="selected" [binary]="true"></p-checkbox>
          </td>
          <td class="truncate text-bl-grey-300">
            {{ task.assignmentName }}
          </td>
          <td class="truncate text-bl-grey-300">
            {{ task.type }}
          </td>
          <td class="truncate text-bl-grey-300">
            {{ task.usersAsString }}
          </td>
        </ng-container>
        <ng-container *ngIf="!isExistingTemplate(task)">
          <td>
            <p-tableCheckbox [value]="task"></p-tableCheckbox>
          </td>
          <td class="truncate">
            {{ task.assignmentName }}
          </td>
          <td class="truncate">
            {{ task.type }}
          </td>
          <td class="truncate">
            {{ task.usersAsString }}
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="relative">Antal valda tjänster att lägga till: {{ form.value.taskTemplates?.length }}</div>
    </ng-template>
  </p-table>
  <div class="flex justify-end mt-5">
    <div>
      <button type="submit" class="btn btn-success mr-2" [disabled]="form.pristine || form.invalid">Spara</button>
      <button type="button" class="btn btn-warning" (click)="close({ taskTemplates: null })">Stäng</button>
    </div>
  </div>
</form>
