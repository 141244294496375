<div class="flex flex-col rounded-xl shadow-lg">
  <div class="w-full bg-bl-blue-800 rounded-t-xl py-10">
    <img class="h-24 mx-auto" src="assets/due-compliance.png" alt="Due Compliance" />
  </div>
  <div class="p-4">
    <h4 class="font-semibold inline-flex my-4">
      Due PTL
      <bl-frontend-badge text="Ny" variant="success" class="ml-3"></bl-frontend-badge>
    </h4>
    <p class="text-bl-grey-500">
      I samarbete med Due Compliance erbjuder vi dig som är redovisningskonsult eller revisor ett heltäckande verktyg
      som hjälper dig att efterleva penningtvättslagens krav på kundkännedom (KYC) och antipenningtvätt (AML). Du får en
      enkel, korrekt och komplett hantering med allt samlat på ett ställe.
    </p>
  </div>
  <div class="flex justify-between items-center bg-bl-grey-50 p-8" *ngIf="state$ | async as state">
    <ng-container
      *ngTemplateOutlet="
        state.activeIntegration ? activeIntegrationTemplate : activateIntegrationTemplate;
        context: { $implicit: state }
      ">
    </ng-container>
  </div>
</div>

<ng-template #activeIntegrationTemplate>
  <a
    href="https://www.bjornlunden.se/redovisningsbyr%c3%a5/program/due-compliance-kyc"
    target="_blank"
    rel="noopener"
    class="mb-0 text-lg font-semibold text-bl-grey-500"
    >Läs mer</a
  >
  <div>
    <bl-frontend-button
      text="Inställningar"
      variant="secondary"
      (onClick)="showSettingsIntegrationDialog()"></bl-frontend-button>
    <bl-frontend-button
      class="pl-2"
      text="Avaktivera"
      variant="primary-negative"
      (onClick)="showDeactivateIntegrationDialog()"></bl-frontend-button>
  </div>
</ng-template>

<ng-template #activateIntegrationTemplate let-state>
  <a
    href="https://www.bjornlunden.se/redovisningsbyr%c3%a5/program/due-compliance-kyc"
    target="_blank"
    rel="noopener"
    class="mb-0 text-lg font-semibold text-bl-grey-500"
    >Läs mer</a
  >

  <bl-frontend-button
    [pTooltip]="state.isAdminUser ? null : 'Byråadministratör behöver aktivera Due PTL för byrån.'"
    [disabled]="!state.isAdminUser"
    text="Aktivera integration"
    variant="primary"
    (onClick)="showActivateIntegrationDialog()"></bl-frontend-button>
</ng-template>
