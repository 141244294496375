import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { validateDateFn } from '@app/core/entity/validation';
import { ClientTasksActions } from '@app/core/state/clientTasks/clientTasks.actions';
import { ClientTaskSelectors } from '@app/core/state/clientTasks/clientTasks.selectors';
import { TaskTemplateActions } from '@app/core/state/task-templates/task-templates.actions';
import { TaskTemplateSelectors } from '@app/core/state/task-templates/task-templates.selectors';
import { ClientType, TaskGroupType, TaskType } from '@app/core/state/types';
import { CalendarComponent } from '@app/shared/components/calendar/calendar.component';
import {
  DialogData,
  DialogForm,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { PleaseWaitComponent } from '@app/shared/components/please-wait/please-wait.component';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { TaskGroupsSelectorComponent } from '@app/shared/components/task-groups-selector/task-groups-selector.component';
import { isFirstDateAfterSecondDate, isFirstDateEqualToSecondDate } from '@app/shared/misc/dates';
import { TodayDate } from '@app/shared/misc/today.date';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FocusTrapModule } from 'primeng/focustrap';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { BehaviorSubject, Observable, combineLatest, filter, map } from 'rxjs';

interface Data extends DialogData {
  client: ClientType;
}

interface Result extends DialogResult {
  taskTemplates: TaskType[];
}

interface Form extends DialogForm<unknown> {
  startDate: FormControl<string>;
  taskTemplates: FormControl<TaskType[]>;
  selected: FormControl<boolean>;
}

@Component({
  selector: 'app-add-assignments-dialog',
  templateUrl: './add-assigments.dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarComponent,
    TaskGroupsSelectorComponent,
    TableModule,
    InputTextModule,
    CheckboxModule,
    PleaseWaitComponent,
    FocusTrapModule,
  ],
})
export class AddAssignementsDialog extends FormDialogBaseComponent<Data, Result, Form> {
  lastTaskGroupId: number;
  taskTemplates$: Observable<TaskType[]>;
  selectedTaskTemplates: TaskType[];
  tableMessage = 'Mallar laddas...';
  loading = false;
  columns: TableColumn[] = [
    { field: 'assignmentName', header: 'Uppdrag' },
    { field: 'type', header: 'Tjänst' },
    { field: 'usersAsString', header: 'Utförande handläggare' },
  ];

  private setPreferredTemplate = false;
  private taskTemplatesSub: BehaviorSubject<TaskType[]>;
  private clientTasksSub: BehaviorSubject<TaskType[]>;

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const taskTemplates = this.form.controls.taskTemplates.value?.map((t) => ({
      ...t,
      span: { ...t.span, start: this.form.controls.startDate.value },
    }));
    this.close({ taskTemplates });
  }

  onTaskGroupChanged(taskGroup: TaskGroupType) {
    this.setPreferredTemplate = false;
    this.lastTaskGroupId = taskGroup.id;
    this.tableMessage = 'Mallar laddas...';
    this.form.controls.taskTemplates.markAsDirty();
    this.store.dispatch(TaskTemplateActions.load({ taskGroupId: this.lastTaskGroupId }));
  }

  loadProposals() {
    this.setPreferredTemplate = true;
    this.form.controls.taskTemplates.markAsDirty();
    this.store.dispatch(TaskTemplateActions.load({ taskGroupId: this.lastTaskGroupId }));
  }

  isExistingTemplate = (taskTemplate: TaskType): boolean => {
    if (this.loading) {
      return true;
    }

    const existing = this.clientTasksSub.value.find(
      (ct) => ct.assignmentId === taskTemplate.assignmentId && ct.code === taskTemplate.code,
    );

    if (!existing) {
      return false;
    }

    if (!existing.span.end) {
      return true;
    }

    return this.isEndDateEqualOrAfterFormDate(existing.span.end);
  };

  onTaskTemplatesSelected(taskTemplates: TaskType[], markAsDirty = true) {
    if (markAsDirty) {
      this.form.controls.taskTemplates.markAsDirty();
    }

    const filteredTaskTemplates = taskTemplates.filter((t) => !this.isExistingTemplate(t));
    this.form.patchValue({ taskTemplates: filteredTaskTemplates });
  }

  protected override onInitialized = () => {
    const { name } = this.config.data.client;
    this.config.header = `Lägg till tjänst för ${name}`;
    this.form = this.builder.group({
      startDate: new FormControl(new TodayDate().getTodayAsString(), [
        validateDateFn({ key: 'startDate', canBeEmpty: true }),
      ]),
      taskTemplates: new FormControl([], Validators.required),
      selected: new FormControl({ value: false, disabled: true }),
    });
    this.initSubscriptions();
    this.store.dispatch(ClientTasksActions.load({ clientId: this.config.data.client.id }));
    this.store.dispatch(TaskTemplateActions.load({ taskGroupId: 0 }));
  };

  protected override onAfterClose = () => {
    this.store.dispatch(ClientTasksActions.cleanUp());
  };

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<Data>, 'data'> {
    return {
      closeOnEscape: false,
      closable: false,
      resizable: false,
      draggable: false,
      modal: true,
      width: '1080px',
      height: '640px',
    };
  }

  private initSubscriptions = () => {
    this.taskTemplatesSub = new BehaviorSubject([]);
    this.clientTasksSub = new BehaviorSubject([]);
    this.taskTemplates$ = this.taskTemplatesSub.asObservable();
    combineLatest([
      this.store.select(ClientTaskSelectors.loading),
      this.store.select(TaskTemplateSelectors.selectLoading),
    ])
      .pipe(
        map(([clientTaskLoading, taskTemplatesLoading]) => clientTaskLoading || taskTemplatesLoading),
        this.takeUntilDestroyed(),
      )
      .subscribe((loading) => {
        this.loading = loading;
      });

    combineLatest([
      this.store.select(ClientTaskSelectors.tasks),
      this.store.select(TaskTemplateSelectors.selectTaskTemplates(true)),
    ])
      .pipe(
        filter(([clientTasks, taskTemplates]) => Boolean(clientTasks) && Boolean(taskTemplates)),
        this.takeUntilDestroyed(),
      )
      .subscribe(([clientTasks, taskTemplates]) => {
        this.clientTasksSub.next(clientTasks);
        const updatedTaskTemplates = taskTemplates.map((t) => {
          if (this.lastTaskGroupId > 0) {
            return t;
          }

          const preferredTemplate = this.setPreferredTemplate ? t.preferredTemplate : false;
          return { ...t, preferredTemplate };
        });
        this.tableMessage = 'Inga mallar hittades';
        this.selectedTaskTemplates = updatedTaskTemplates.filter(
          (t) => t.preferredTemplate && !this.isExistingTemplate(t),
        );
        this.onTaskTemplatesSelected(this.selectedTaskTemplates, false);
        this.taskTemplatesSub.next(updatedTaskTemplates);
      });
  };

  private isEndDateEqualOrAfterFormDate = (date: string): boolean =>
    isFirstDateAfterSecondDate(date, this.form.value.startDate) ||
    isFirstDateEqualToSecondDate(date, this.form.value.startDate);
}
