import { Injectable } from '@angular/core';
import { FileVaultService } from '@app/core/services/filevault.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from 'file-saver';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ToastActions } from '../toast/toast.actions';
import { FileVaultActions } from './filevault.actions';

@Injectable()
export class FileVaultEffects {
  loadAllFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileVaultActions.loadAllFileNames),
      switchMap(() =>
        this.fileVaultService.getAllFileNames().pipe(
          map((files) => FileVaultActions.loadAllFileNamesSucceeded({ files })),
          catchError((error: unknown) => of(FileVaultActions.loadAllFileNamesFailed({ error }))),
        ),
      ),
    ),
  );

  download$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileVaultActions.downloadFile),
      mergeMap(({ file }) =>
        this.fileVaultService.download(file).pipe(
          map((blob) => FileVaultActions.downloadFileSucceeded({ file, blob })),
          catchError((error: unknown) => of(FileVaultActions.downloadFileFailed({ file, error }))),
        ),
      ),
    ),
  );

  downloadSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FileVaultActions.downloadFileSucceeded),
        tap(({ blob, file }) => saveAs(new Blob([blob]), file)),
      ),
    { dispatch: false },
  );

  downloadFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileVaultActions.downloadFileFailed),
      map(({ file }) =>
        ToastActions.showWarnMessage({
          summary: 'Nedladdningen misslyckades',
          detail: `Kunde inte ladda ner filen: ${file}`,
        }),
      ),
    ),
  );

  constructor(private actions$: Actions, private fileVaultService: FileVaultService) {}
}
