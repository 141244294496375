import { partnerProgramFeature } from './partnerprogram.reducer';

const { selectFirm, selectBenefits, selectProperties, selectLevels } = partnerProgramFeature;

export const PartnerProgramSelectors = {
  firm: selectFirm,
  benefits: selectBenefits,
  properties: selectProperties,
  levels: selectLevels,
};
