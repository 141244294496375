import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { UserSettingsStorage } from 'src/app/core/storage/user.settings.storage';
import {
  CompanyNotification,
  Notification,
  UpdateReport,
  UpdateReportWithCompanyName,
} from '../../core/entity/advisory.notification';
import { AppState } from '@app/core/state/appState';
import { Store } from '@ngrx/store';
import { ClientActions } from 'src/app/core/state/clients/clients.actions';
import { withTeamIds } from '@app/core/misc/rxjs-operators';
import { toClientTypeTransformer } from '@app/core/state/transformers/transformers';
import { HttpAuthorizationHeader } from '../../core/misc/httpauthorizationheader';
import { ClientService } from '../../core/services/clients.service';
import { FeatureService } from './feature.service';
import { Client } from '../../core/entity/client';
import { AdvisoryToolFilters } from '../filters/filters';

export interface AdvisoryToolFilter {
  advice: boolean;
  warning: boolean;
  cleared: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AdvisoryToolService {
  options = new HttpAuthorizationHeader('application/json');
  url = `${environment.serverUrl}informationlogistics/notifications/`;
  private filterBS: BehaviorSubject<AdvisoryToolFilter>;
  private uss: UserSettingsStorage;
  private loggedInUser: string;
  private forceTriggerBS: BehaviorSubject<any>;
  private showNotificationCountBS: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private clientService: ClientService,
    private featureService: FeatureService,
    private store: Store<AppState>,
  ) {
    this.filterBS = new BehaviorSubject({
      advice: localStorage.getItem('showAdvices')
        ? JSON.parse(localStorage.getItem('showAdvices'))
        : (localStorage.setItem('showAdvices', 'true'), true),
      warning: true,
      cleared: localStorage.getItem('showCleared')
        ? JSON.parse(localStorage.getItem('showCleared'))
        : localStorage.setItem('showCleared', 'false'),
    });
    this.uss = new UserSettingsStorage();
    this.loggedInUser = this.uss.loadSetting(UserSettingsStorage.LOGGED_IN_USER_NAME, '').replace(' ', '+');
    this.forceTriggerBS = new BehaviorSubject(null);
    this.showNotificationCountBS = new BehaviorSubject(
      localStorage.getItem('showNotificationsCount')
        ? // use JSON.parse to convert string to boolean value
          JSON.parse(localStorage.getItem('showNotificationsCount'))
        : (localStorage.setItem('showNotificationsCount', 'false'), false),
    );
  }

  public get filter$(): Observable<AdvisoryToolFilter> {
    return this.filterBS.asObservable();
  }

  public get showNotificationCount$(): Observable<boolean> {
    return this.showNotificationCountBS.asObservable();
  }

  public get notificationsCount$() {
    // count of notifications
    return this.notificationsWithFilter$.pipe(map((arrayOfNotifications) => arrayOfNotifications.length));
  }

  public get lastUpdatedClients$() {
    return this.clientService
      .getClientsWithAdvisoryTool()
      .pipe(
        mergeMap((clients) =>
          combineLatest(clients.map(this.getNotificationWithCompanyName)).pipe(
            tap((array) => array.sort((a, b) => (a.last_update < b.last_update ? 1 : -1))),
          ),
        ),
      );
  }

  public get notificationsWithFilter$(): Observable<CompanyNotification[]> {
    return withTeamIds(this.store).pipe(
      switchMap(() =>
        combineLatest([this.refreshNotifications$, this.filter$]).pipe(
          // this.filter$ is an object that holds boolean values that represent the filter toggle in the UI
          map((props) => this.notificationsWithFilterMapper(props)),
        ),
      ),
    );
  }

  onShowNotificationsToggle(val: boolean): void {
    this.showNotificationCountBS.next(val);
    localStorage.setItem('showNotificationsCount', JSON.stringify(val));
  }

  onAdviceToggle(val: boolean): void {
    const currentStreamValue = this.filterBS.value;
    this.filterBS.next({
      ...currentStreamValue,
      advice: val,
    });
    localStorage.setItem('showAdvices', JSON.stringify(val));
  }

  onClearedToggle(val: boolean): void {
    const currentStreamValue = this.filterBS.value;
    this.filterBS.next({
      ...currentStreamValue,
      cleared: val,
    });
    localStorage.setItem('showCleared', JSON.stringify(val));
  }

  toggleCleared(url: string): any {
    // make a GET to toggle the state, and then force a reupdate of the notifications for the table
    this.http.get<any>(`${url}/?user=${this.loggedInUser}`).subscribe(() => this.reTriggerNotifications());
  }

  filterWarning(companyNotification: CompanyNotification, features: string[], filterObj: AdvisoryToolFilter): boolean {
    if (filterObj.warning) {
      return features.includes(companyNotification.notification.feature_number.toString());
    }
    return !features.includes(companyNotification.notification.feature_number.toString());
  }

  filterAdvice(companyNotification: CompanyNotification, features: string[], filterObj: AdvisoryToolFilter): boolean {
    if (filterObj.advice) {
      return features.includes(companyNotification.notification.feature_number.toString());
    }
    return !features.includes(companyNotification.notification.feature_number.toString());
  }

  filterCleared(companyNotification: CompanyNotification, filterObj: AdvisoryToolFilter): boolean {
    // när cleared är true => returnera alla notifications som har ett värde på cleared_at
    if (filterObj.cleared) {
      return true;
    }
    return companyNotification.notification.cleared_at === null;
  }

  distinctFilter(array: CompanyNotification[]): CompanyNotification[] {
    const data: Record<string, any> = {};
    array.forEach((feature) => {
      // build a key of feature number and corporate identity
      const key = feature.notification.feature_number + feature.corporate_identity;
      // check if the key already exists
      if (data[key]) {
        // if it exists, check if it has a newer notification date
        if (data[key].notification.notification_date < feature.notification.notification_date) {
          data[key] = feature;
        }
      } else {
        data[key] = feature;
      }
    });
    // use Object.values to remove the key from the object and only return the value
    return Object.values(data);
  }

  activateAdvisoryTool(client: Client): Observable<any> {
    return this.clientService.upsertClientType(toClientTypeTransformer.transform(client)).pipe(
      switchMap((result) =>
        this.triggerSoftrobotUpdate(result.cloudApiKey).pipe(map((data) => ({ data, client: result }))),
      ),
      tap(() => this.store.dispatch(ClientActions.loadAllClients())),
    );
  }

  triggerSoftrobotUpdate(publicKey: string) {
    const url = `${environment.serverUrl}informationlogistics/report/update`;
    return (
      this.http
        .put(`${url}/${publicKey}`, '', this.options.getAuthorizationHeaderWithResponseType('text/plain'))
        // call this method to force a retrigger of the notifications
        .pipe(tap(() => this.reTriggerNotifications()))
    );
  }

  reTriggerNotifications(): void {
    this.forceTriggerBS.next(null);
  }

  updateClientInfoOnLoadedNotifications(updatedClient: null | Client, notifications: CompanyNotification[]) {
    if (!updatedClient) {
      return notifications;
    }

    return notifications.reduce((acc, notification) => {
      if (notification.client_id === updatedClient.id) {
        return [
          ...acc,
          {
            ...notification,
            client_name: updatedClient.name,
            corporate_identity: updatedClient.corporateIdentity,
            customer_number: updatedClient.customerNumber,
          },
        ];
      }
      return [...acc, notification];
    }, []);
  }

  private get forceTrigger$() {
    return this.forceTriggerBS.asObservable();
  }

  // ? Could we combine forceTrigger$(as an action stream) and below code(as a data stream) in a combineLatest?
  // ? So whenever we want to rerender the notifications, we just trigger the actionstream
  private get allClientsNotifications$(): Observable<CompanyNotification[]> {
    return this.clientService.getClientsWithAdvisoryTool().pipe(
      mergeMap((clients: Client[]) => {
        if (!clients.length) {
          return of([]);
        }

        return combineLatest(clients.map(this.getNotificationsFromClient)).pipe(map((resp) => resp.flat()));
      }),
    );
  }

  // every time a new value is emitted to the forceTrigger, we fetch the notifications again
  // https://stackoverflow.com/questions/46674188/refreshing-list-using-combinelatest-angular2-rxjs
  private get refreshNotifications$() {
    return this.forceTrigger$.pipe(switchMap(() => this.allClientsNotifications$));
  }

  private notificationsWithFilterMapper = ([notifications, filterObj]: [CompanyNotification[], AdvisoryToolFilter]) => {
    // the featureService holds the feature properties, such as which features that should be typed as warnings and advices
    const warningFeatures = this.featureService.getFeaturesOfTypeWarning();
    const adviceFeatures = this.featureService.getFeatureOfTypeAdvice();

    const cleared = notifications.filter((n) => this.filterCleared(n, filterObj));
    const warned = notifications.filter((n) => this.filterWarning(n, warningFeatures, filterObj));
    const adviced = notifications.filter((n) => this.filterAdvice(n, adviceFeatures, filterObj));

    const result = this.getResult({ cleared, warned, adviced, filterObj });

    const arrayOfDistinctFeatures: CompanyNotification[] = [];
    const arrayOfIndistinctFeatures: CompanyNotification[] = [];

    result.forEach((feature) => {
      if (
        feature.notification.feature_number === 6 ||
        feature.notification.feature_number === 12 ||
        feature.notification.feature_number === 33 ||
        feature.notification.feature_number === 104
      ) {
        arrayOfIndistinctFeatures.push(feature);
      } else {
        arrayOfDistinctFeatures.push(feature);
      }
    });

    const sortedDistinctArray = this.distinctFilter(arrayOfDistinctFeatures);

    return arrayOfIndistinctFeatures.concat(sortedDistinctArray);
  };

  private getResult = ({
    adviced,
    cleared,
    filterObj,
    warned,
  }: {
    cleared: CompanyNotification[];
    warned: CompanyNotification[];
    adviced: CompanyNotification[];
    filterObj: AdvisoryToolFilter;
  }): CompanyNotification[] => {
    if (this.isAdviceAndCleared(filterObj)) {
      return [...adviced, ...warned];
    }

    if (this.isAdviceAndNotCleared(filterObj)) {
      const advicesWithoutCleared = [adviced, cleared].reduce((a, b) => a.filter((c) => b.includes(c)));
      const warningsWithoutCleared = [warned, cleared].reduce((a, b) => a.filter((c) => b.includes(c)));
      return [...advicesWithoutCleared, ...warningsWithoutCleared];
    }

    if (this.isNotAdviceAndNotCleared(filterObj)) {
      return [warned, cleared].reduce((a, b) => a.filter((c) => b.includes(c)));
    }

    if (this.isNotAdviceAndCleared(filterObj)) {
      return warned;
    }

    return [];
  };

  private isAdviceAndCleared = (filterObj: AdvisoryToolFilter) => filterObj.advice && filterObj.cleared;
  private isAdviceAndNotCleared = (filterObj: AdvisoryToolFilter) => filterObj.advice && !filterObj.cleared;
  private isNotAdviceAndNotCleared = (filterObj: AdvisoryToolFilter) => !filterObj.advice && !filterObj.cleared;
  private isNotAdviceAndCleared = (filterObj: AdvisoryToolFilter) => !filterObj.advice && filterObj.cleared;

  private getNotificationsFromClient = (client: Client): Observable<CompanyNotification[]> =>
    this.http.get(`${this.url}${client.cloudApiKey}`, this.options.getAuthorizationHeaderWithEmptyBody()).pipe(
      map((notifications: Notification[]) => {
        if (!Array.isArray(notifications)) {
          return [];
        }

        return notifications
          .filter((n) => AdvisoryToolFilters.includeNotification(client.type, n.feature_number))
          .reduce((acc, value) => {
            const { name, corporateIdentity, customerNumber, id } = client;
            const { title, type, description } = this.featureService.getCorrectFeatureInfo(value.feature_number);
            const { notification_date: date } = value;
            const companyNotification: CompanyNotification = {
              client_id: id,
              client_name: name,
              corporate_identity: corporateIdentity,
              customer_number: customerNumber,
              notification: value,
              title,
              type,
              description,
              date: date.slice(0, 10),
            };
            return [...acc, companyNotification];
          }, []);
      }),
    );

  private getNotificationWithCompanyName = (client: Client): Observable<UpdateReportWithCompanyName> => {
    const url = `${environment.serverUrl}informationlogistics/report/update/time`;
    return this.http
      .get<UpdateReport>(`${url}/${client.cloudApiKey}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(
        map((data) => ({
          ...data,
          company_name: client.name,
        })),
      );
  };
}
