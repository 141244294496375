export enum BulkType {
  Add = 'Add',
  Change = 'Change',
  Remove = 'Remove',
}

export enum BulkChangeActionType {
  CHANGE_RESPONSIBLE_USER = 'Klientansvarig',
  CHANGE_TASK_USER = 'Utförande handläggare',
  CHANGE_STARTDATE = 'Startdatum',
  CHANGE_COMPANY_TYPE = 'Företagstyp',
  CHANGE_SELECTABLE_STATE = 'Valbara statusar',
}

export enum BulkAddActionType {
  ADD_TASK = 'Tjänst',
}

export enum BulkRemoveActionType {
  REMOVE_TASK = 'Tjänster (utan sparade aktiviteter)',
}

export const BulkChangeActionTypeList: {
  key: string;
  label: string;
}[] = Object.entries(BulkChangeActionType).map(([key, label]) => ({ key, label }));

export const BulkAddActionTypeList: {
  key: string;
  label: string;
}[] = Object.entries(BulkAddActionType).map(([key, label]) => ({ key, label }));

export const BulkRemoveActionTypeList: {
  key: string;
  label: string;
}[] = Object.entries(BulkRemoveActionType).map(([key, label]) => ({ key, label }));

export type BulkActionType = BulkChangeActionType | BulkAddActionType | BulkRemoveActionType;
