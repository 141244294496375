import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TaskGroupsSelectors } from '@app/core/state/task-groups/task-groups.selectors';
import { TaskGroupType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { Store } from '@ngrx/store';
import { DropdownModule } from 'primeng/dropdown';
import { BehaviorSubject, Observable, Subject, filter, takeUntil } from 'rxjs';
import { TableFilterItemComponent } from '../table/components/table-filter-item.component';
import { openDialog } from '@app/shared/misc/openDialog';
import { DialogService } from 'primeng/dynamicdialog';
import { TaskGroupDialog } from './task-group.dialog';
import { Actions, ofType } from '@ngrx/effects';
import { TaskGroupActions } from '@app/core/state/task-group/task-group.actions';
import { FormsModule } from '@angular/forms';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';

@Component({
  selector: 'app-task-groups-selector',
  template: `
    <ng-container *ngIf="visible">
      <app-table-filter-item label="Mallgrupper">
        <p-dropdown
          dataKey="id"
          optionLabel="name"
          [disabled]="disabled"
          [ngModel]="selectedTaskGroup"
          [options]="taskGroups$ | async"
          [scrollHeight]="scrollHeight"
          (onChange)="onChange($event)"
          [autoOptionFocus]="false"
          [autoDisplayFirst]="false">
        </p-dropdown>
        <button
          class="btn btn-primary ml-2"
          [disabled]="isBtnDisabled"
          [title]="isBtnDisabled ? 'Den här mallgruppen går inte att redigera' : 'Redigera mallgrupp'"
          (click)="onEditTaskGroup()">
          <i class="fas fa-pencil"></i>
        </button>
        <button
          class="btn btn-primary ml-2"
          [disabled]="isBtnDisabled"
          [title]="isBtnDisabled ? 'Den här mallgruppen går inte att ta bort' : 'Ta bort mallgrupp'"
          (click)="onDeleteTaskGroup()">
          <i class="fas fa-trash-alt"></i>
        </button>
        <button class="btn btn-primary ml-2" [disabled]="disabled" (click)="onNewTaskGroup()">Ny mallgrupp</button>
      </app-table-filter-item>
    </ng-container>
    <ng-container *ngIf="!visible">
      <button class="btn btn-primary" [disabled]="disabled" (click)="onNewTaskGroup()">Ny mallgrupp</button>
    </ng-container>
  `,
  standalone: true,
  imports: [CommonModule, FormsModule, DropdownModule, TableFilterItemComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class TaskGroupsSelectorComponent implements OnDestroy {
  @Input() disabled = true;
  @Output() onTaskGroupChanged: EventEmitter<TaskGroupType>;

  visible: boolean;
  isBtnDisabled: boolean;
  taskGroupsBS: BehaviorSubject<TaskGroupType[]>;
  taskGroups$: Observable<TaskGroupType[]>;
  selectedTaskGroup: TaskGroupType;
  scrollHeight: string;
  private onDestroySub: Subject<void>;

  constructor(private store: Store<AppState>, private dialogService: DialogService, private actions$: Actions) {
    this.onTaskGroupChanged = new EventEmitter();
    this.onDestroySub = new Subject();
    this.taskGroupsBS = new BehaviorSubject([]);
    this.taskGroups$ = this.taskGroupsBS.asObservable();
    this.isBtnDisabled = this.disabled;

    this.store
      .select(TaskGroupsSelectors.selectTaskGroups)
      .pipe(takeUntil(this.onDestroySub))
      .subscribe((groups) => {
        const [selectedTaskGroup] = groups;
        this.selectedTaskGroup = selectedTaskGroup;
        this.taskGroupsBS.next(groups);
        this.visible = groups.length > 1;
        this.scrollHeight = getScrollHeightString(groups.length);
      });

    this.actions$
      .pipe(ofType(TaskGroupActions.deleteTaskGroupSucceeded), takeUntil(this.onDestroySub))
      .subscribe(() => this.onChange({ originalEvent: null, value: this.taskGroupsBS.value[0] }));
  }

  ngOnDestroy(): void {
    this.onDestroySub.next();
    this.onDestroySub.complete();
  }

  onChange(event: { originalEvent: Event; value: TaskGroupType }) {
    this.selectedTaskGroup = event.value;
    this.isBtnDisabled = event.value.id === 0 || this.disabled;
    this.onTaskGroupChanged.emit(event.value);
  }

  onEditTaskGroup() {
    this.openTaskGroupDialog(this.selectedTaskGroup);
  }

  onNewTaskGroup() {
    this.openTaskGroupDialog({ id: 0, name: '', tasks: [] });
  }

  onDeleteTaskGroup() {
    this.store.dispatch(TaskGroupActions.delete({ id: this.selectedTaskGroup.id }));
  }

  private openTaskGroupDialog = (taskGroup: TaskGroupType) => {
    openDialog(this.dialogService, TaskGroupDialog, { taskGroup })
      .pipe(filter((result) => Boolean(result?.taskGroup)))
      .subscribe(({ taskGroup }) => {
        this.onChange({ originalEvent: undefined, value: taskGroup });
      });
  };
}
