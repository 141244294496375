import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import {
  DialogForm,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

interface Result extends DialogResult {
  amlId: string;
}

interface Form extends DialogForm<unknown> {
  amlId: FormControl<string>;
}

@Component({
  template: `
    <form [formGroup]="form" (ngSubmit)="onSubmit()" pFocusTrap>
      <p class="mb-0">Skriv in PTL ID</p>
      <p class="text-sm text-bl-grey-500">Du hittar det hos Due Compliance</p>
      <div>
        <input pInputText type="text" formControlName="amlId" class="w-full" />
      </div>
      <div class="flex flex-row w-full justify-end mt-8">
        <bl-frontend-button
          type="button"
          variant="secondary"
          (click)="close({ amlId: null })"
          text="Avbryt"></bl-frontend-button>
        <bl-frontend-button
          type="submit"
          variant="primary"
          class="ml-2"
          [disabled]="form.invalid"
          text="Spara"></bl-frontend-button>
      </div>
    </form>
  `,
  standalone: true,
  imports: [InputTextModule, ReactiveFormsModule, BlFrontendButtonComponent],
})
export class InputExistingAmlIdDialogComponent extends FormDialogBaseComponent<null, Result, Form> {
  protected override onInitialized = () => {
    this.form = this.builder.group({
      amlId: new FormControl('', [Validators.required]),
    });
  };

  protected static override getDialogConfig(): Omit<DynamicDialogConfig, 'data'> {
    return {
      header: 'Koppla befintlig PTL-kontroll',
      width: '350px',
      height: 'auto',
      modal: true,
    };
  }

  onSubmit() {
    const { amlId } = this.form.value;
    this.close({ amlId });
  }
}
