import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Client } from 'src/app/core/entity/client';
import { ClientService } from 'src/app/core/services/clients.service';
import { CloudCompanyService } from 'src/app/core/services/cloud-company.service';
import { TabsComponent } from 'src/app/shared/components/tabs/tabs.component';
import { BaseCloudImportExportComponent } from './components/base-cloud-import-export.component';

@Component({
  selector: 'app-compare-cloud-companies-dialog',
  templateUrl: './compare-cloud-companies-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareCloudCompaniesDialogComponent implements OnChanges {
  @ViewChild(TabsComponent, { static: true })
  private tabsComponent: TabsComponent;

  @ViewChildren(BaseCloudImportExportComponent)
  private baseCloudImportExportComponents: QueryList<BaseCloudImportExportComponent>;

  @Input() visible = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updated: EventEmitter<void> = new EventEmitter<void>();

  clients: Client[];
  loadingData = true;
  showWorkingSpinner = false;

  get hideAddButton() {
    const component = this.getSelectedBaseComponent() || { hideAddButton: false };
    return component.hideAddButton;
  }

  constructor(
    private clientService: ClientService,
    private cloudCompanyService: CloudCompanyService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.visible && changes.visible.currentValue) {
      this.initLoadingOfAllClients();
      this.initLoadingOfCloudCompanies();
    }
  }

  closeDialog() {
    this.visibleChange.emit(false);
  }

  onTabChange() {
    if (this.visible) {
      this.loadDataForCurrentTab();
    }
  }

  onAddButton() {
    this.getSelectedBaseComponent().execute();
  }

  private loadDataForCurrentTab() {
    const baseComponent = this.getSelectedBaseComponent();
    if (!baseComponent) return;

    baseComponent.init(this.clients).loadList();
  }

  private getSelectedBaseComponent(): BaseCloudImportExportComponent {
    if (!this.tabsComponent || !this.baseCloudImportExportComponents) {
      return undefined;
    }

    const currentTabIndex = this.tabsComponent.currentTabIndex;
    return this.baseCloudImportExportComponents.toArray()[currentTabIndex];
  }

  private initLoadingOfAllClients() {
    this.clientService.getAllClients().subscribe((clients: Client[]) => (this.clients = clients));
  }

  private initLoadingOfCloudCompanies() {
    this.cloudCompanyService.getCloudCompanies().subscribe(
      () => {
        this.loadingData = false;
        this.changeDetectorRef.markForCheck();
        setTimeout(() => this.loadDataForCurrentTab(), 0);
      },
      () => {
        this.loadingData = false;
        this.changeDetectorRef.markForCheck();
      },
    );
  }
}
