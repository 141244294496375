import { Injectable } from '@angular/core';
import { TableColumn } from '../interfaces/table-column';

export interface TableStorageWidthsItem {
  field: string;
  width: string;
}

export interface TableStorageItem {
  visibleColumns?: string[];
  sortOrder?: string[];
  widths?: TableStorageWidthsItem[];
}

@Injectable()
export class TableService {
  public storeSelectedColumnsState(storageKey: string, selectedColumns: TableColumn[]) {
    if (!storageKey) {
      return;
    }

    const visibleColumnFields = selectedColumns.map((col) => col.field);
    this.storeTableState(storageKey, { visibleColumns: visibleColumnFields });
  }

  public storeSortedColumnsState(storageKey: string, columns: TableColumn[]) {
    if (!storageKey) {
      return;
    }

    const fieldsInOrder = columns.map((col) => col.field);
    this.storeTableState(storageKey, { sortOrder: fieldsInOrder });
  }

  public storeWidthsState(storageKey: string, columns: TableColumn[], element: HTMLElement) {
    if (!storageKey) {
      return;
    }

    const resizedCols = this.getResizedColumns(element, columns);
    const storedWidthsWithoutUpdatedCols = this.getStoredWidths(storageKey).filter(
      (item) => !resizedCols.some((r) => r.field === item.field)
    );

    this.storeTableState(storageKey, { widths: storedWidthsWithoutUpdatedCols.concat(resizedCols) });
  }

  public updateColumnsWithStoredState(storageKey: string, cols: TableColumn[]): TableColumn[] {
    if (!storageKey) {
      return cols;
    }

    return this.updateWidthBasedOnStoredValues(
      storageKey,
      this.sortColumnsBasedOnStoredSortOrder(storageKey, this.updateVisibilityBasedOnStoredState(storageKey, cols))
    );
  }

  private updateVisibilityBasedOnStoredState(storageKey: string, columns: TableColumn[]): TableColumn[] {
    const persistedColumnFields: string[] = this.getStoredVisibleColumns(storageKey);

    if (persistedColumnFields.length <= 0) {
      return columns;
    }

    return columns.map((c) => ({ ...c, visible: persistedColumnFields.includes(c.field) || c.selectable === false }));
  }

  private sortColumnsBasedOnStoredSortOrder(storageKey: string, cols: TableColumn[]): TableColumn[] {
    const sortedCols: TableColumn[] = [];

    this.getStoredSortOrder(storageKey).forEach((item: string) => {
      const index = cols.findIndex((c) => c.field === item);
      if (index > -1) {
        sortedCols.push(cols.splice(index, 1)[0]);
      }
    });

    // add the rest of cols if they are missing in the stored sorted list
    return sortedCols.concat(cols);
  }

  private updateWidthBasedOnStoredValues(storageKey: string, columns: TableColumn[]): TableColumn[] {
    this.getStoredWidths(storageKey).forEach((storedItem) => {
      const col = columns.find((c) => c.field === storedItem.field);
      if (col) {
        col.width = storedItem.width;
      }
    });

    return columns;
  }

  private getResizedColumns(element: HTMLElement, columns: TableColumn[]) {
    const resizedCols = [];
    const tableHeaderElements = element.parentElement.children;

    for (let i = 0; i < tableHeaderElements.length - 1; i++) {
      const currentTableHeaderElement = tableHeaderElements[i] as HTMLElement;
      const nextTableHeaderElement = tableHeaderElements[i + 1] as HTMLElement;

      // store only changed column plus next column (which is also changed) and leave the rest
      if (element === currentTableHeaderElement) {
        const currentColumn = columns[i];
        resizedCols.push({ field: currentColumn.field, width: this.getElementWidth(currentTableHeaderElement) });
        // next column
        const nextColumn = columns[i + 1];
        resizedCols.push({ field: nextColumn.field, width: this.getElementWidth(nextTableHeaderElement) });
      }
    }
    return resizedCols;
  }

  private getElementWidth(element: HTMLElement): string {
    if (!element.style.width) {
      return 'auto';
    }

    return `${element.scrollWidth}px`;
  }

  private storeTableState(storageKey: string, data: TableStorageItem) {
    let storedTableInfo = this.getStoredTableInfo(storageKey);
    storedTableInfo = { ...storedTableInfo, ...data };
    localStorage.setItem(storageKey, JSON.stringify(storedTableInfo));
  }

  private getStoredTableInfo(storageKey: string): TableStorageItem {
    return JSON.parse(localStorage.getItem(storageKey) || '{}');
  }

  private getStoredSortOrder(storageKey: string) {
    return this.getStoredTableInfo(storageKey).sortOrder || [];
  }

  private getStoredWidths(storageKey: string): TableStorageWidthsItem[] {
    return this.getStoredTableInfo(storageKey).widths || [];
  }

  private getStoredVisibleColumns(storageKey: string) {
    return this.getStoredTableInfo(storageKey).visibleColumns || [];
  }
}
