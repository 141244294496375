import { createReducer, on } from '@ngrx/store';
import { TeamType, TeamTypes } from '../types';
import { TeamActions } from './team.actions';

export enum TeamDialogType {
  None = 'None',
  Add = 'Add',
  Edit = 'Edit',
}

export interface TeamState {
  dialog: TeamDialogType;
  team: TeamType;
  teamType: TeamTypes;
}

const initialTeamState: TeamState = {
  dialog: TeamDialogType.None,
  team: undefined,
  teamType: undefined,
};

export const teamReducer = createReducer(
  initialTeamState,
  on(TeamActions.showAddDialog, (state, { teamType }) => ({
    ...state,
    team: undefined,
    teamType,
    dialog: TeamDialogType.Add,
  })),

  on(TeamActions.showEditDialog, (state, { team }) => ({
    ...state,
    team,
    teamType: team.typeId,
    dialog: TeamDialogType.Edit,
  })),

  on(TeamActions.hideDialog, () => ({ ...initialTeamState }))
);
