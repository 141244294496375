import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PartnerFirm } from '@app/core/state/types/partner.types';
import { BlFrontendTooltipComponent } from '@app/core/wrappers/bl-frontend-tooltip.component';
import { getIsoDateWithTime, getIsoDateWithoutTime } from '@app/shared/misc/dates';

@Component({
  selector: 'app-partnerprogram-current-benefits',
  template: `
    <div class="ml-4 flex flex-col content-center" [ngClass]="{ 'opacity-60': hasFallenOut }">
      <p class="text-xl font-bold text-bl-blue-light-800">Nuvarande förmåner</p>
      <div class="bg-bl-blue-light-100 rounded-lg px-6 py-3 max-w-xs" [ngClass]="{ 'bg-bl-grey-100': hasFallenOut }">
        <p>
          Återbäring: <span class="ml-2 text-2xl font-semibold">{{ firm?.level.refund * 100 }}%</span>
        </p>
        <p>
          <a [href]="discountLink" target="_blank">Rabatt på engångsköp:</a>
          <bl-frontend-tooltip
            [heading]="'Engångsköp'"
            [content]="'Rabatten gäller vid köp av böcker och kurser.'"
            placement="right">
            <span class="ml-2 text-2xl font-semibold cursor-pointer">{{ firm?.level.discount * 100 }}%</span>
          </bl-frontend-tooltip>
        </p>
      </div>
      <p class="mb-0 mt-3 text-base font-medium">Senast uppdaterad: {{ lastUpdated }}</p>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, BlFrontendTooltipComponent],
})
export class PartnerProgramCurrentBenefitsComponent {
  @Input() hasFallenOut: boolean;
  @Input() firm: PartnerFirm | null = null;
  private discountLinks: Map<number, string> = new Map();

  constructor() {
    this.discountLinks.set(0, 'https://www.bjornlunden.se/redovisningsbyr%c3%a5/partnerprogram');
    this.discountLinks.set(
      1,
      'https://www.bjornlunden.se/redovisningsbyr%c3%a5/partnerprogram/niv%c3%a5-1-j%c3%a4rn__9218',
    );
    this.discountLinks.set(2, 'https://www.bjornlunden.se/redovisningsbyr%c3%a5/partnerprogram/niv%c3%a5-2-krom__9274');
    this.discountLinks.set(
      3,
      'https://www.bjornlunden.se/redovisningsbyr%c3%a5/partnerprogram/niv%c3%a5-3-titanium__9275',
    );
    this.discountLinks.set(
      4,
      'https://www.bjornlunden.se/redovisningsbyr%c3%a5/partnerprogram/niv%c3%a5-4-platinum__9276',
    );
  }

  get lastUpdated() {
    if (this.hasFallenOut && this.firm.units <= 20) {
      return getIsoDateWithoutTime(this.firm?.lastUpdated);
    }

    if (this.firm.monthsInProgram <= 1) {
      return getIsoDateWithTime(this.firm?.level.lastUpdated);
    }

    return getIsoDateWithoutTime(this.firm?.level.lastUpdated);
  }

  get discountLink() {
    return (
      this.discountLinks.get(this.firm?.level.value) ||
      'https://www.bjornlunden.se/redovisningsbyr%c3%a5/partnerprogram'
    );
  }
}
