import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, Observable, mergeMap, filter, map, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { openDialog } from '@app/shared/misc/openDialog';
import { AmlEntity, AmlStatus } from '@core/state/types/aml.types';
import { Client } from '@core/entity/client';
import { AmlService } from '@core/services/aml.service';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuItemProps } from '@bl/components';
import { AppState } from '@app/core/state/appState';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { InputExistingAmlIdDialogComponent } from './input-existing-aml-id.dialog.component';
import { CreateManualAmlDialogComponent } from './create-manual-aml.dialog.component';
import { toolTipContent } from './aml-tooltip-content';
import { TooltipOptions } from 'primeng/api';

@Component({
  selector: 'app-client-aml',
  templateUrl: './client-aml.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientAmlComponent {
  @Input() client: Client;
  @Input()
  set active(value: boolean) {
    if (value) {
      this.loadAmlDataForClient(this.client);
    }
  }

  inProgressAmlData$: Observable<AmlEntity[] | null>;
  doneAmlData$: Observable<AmlEntity[] | null>;
  hasActiveConnection$: Observable<boolean>;
  createAmlButtonText$: Observable<string>;
  tooltipContent: string = toolTipContent;
  tooltipOptions: TooltipOptions = { escape: false, tooltipStyleClass: 'ptl-tooltip' };

  private amlDataSubject = new BehaviorSubject<AmlEntity[] | null>(null);

  constructor(private amlService: AmlService, private dialogService: DialogService, private store: Store<AppState>) {
    this.inProgressAmlData$ = this.getInProgressObservable();
    this.doneAmlData$ = this.getDoneObservable();
    this.hasActiveConnection$ = this.store.select(FirmSelectors.selectHasActiveAmlConnection);
    this.createAmlButtonText$ = this.hasActiveConnection$.pipe(
      map((hasActiveConnection) => (hasActiveConnection ? 'Skapa PTL-kontroll' : 'Lägg till Risknivå')),
    );
  }

  createAml() {
    const createAmlWithDueAndOpenLink$ = this.amlService
      .createAmlForClient(this.client.id)
      .pipe(tap((response: AmlEntity) => this.openLinkInNewTab(response.link)));

    this.hasActiveConnection$
      .pipe(
        take(1),
        mergeMap((hasActiveConnection) => {
          if (!hasActiveConnection) {
            return this.showCreateManualAmlDialog();
          }
          return createAmlWithDueAndOpenLink$;
        }),
      )
      .subscribe((response: AmlEntity) => this.appendAmlData(response));
  }

  showAttachAmlDialog() {
    openDialog(this.dialogService, InputExistingAmlIdDialogComponent, null).subscribe((res) =>
      this.attachExistingAml(res.amlId),
    );
  }

  showCreateManualAmlDialog() {
    return openDialog(this.dialogService, CreateManualAmlDialogComponent, null).pipe(
      filter((response) => !!response),
      mergeMap((response) => this.amlService.createManualAmlForClient(this.client.id, response)),
    );
  }

  inProgressMenuItemsInContextOf(aml: AmlEntity): MenuItemProps[] {
    return [
      {
        text: 'Återuppta PTL-kontroll',
        onAction: () => this.openLinkInNewTab(aml.link),
      },
    ];
  }

  doneMenuItemsInContextOf(amlEntity: AmlEntity): MenuItemProps[] {
    if (!amlEntity.amlId) {
      return [{ text: 'Ta bort Riskbedömning', onAction: () => this.deleteAmlEntity(amlEntity) }];
    }

    const openReportMenuItem: MenuItemProps = {
      text: 'Hämta PTL-rapport',
      onAction: () => this.openLinkInNewTab(amlEntity.documentLink),
    };

    return [
      {
        text: 'Öppna PTL-kontroll',
        onAction: () => this.openLinkInNewTab(amlEntity.link),
      },
      ...(amlEntity.documentLink ? [openReportMenuItem] : []),
    ];
  }

  private attachExistingAml(amlId: string) {
    if (!amlId) {
      return;
    }
    this.amlService.attachExistingAmlForClient(this.client.id, amlId).subscribe((res) => this.appendAmlData(res));
  }

  private deleteAmlEntity(amlEntity: AmlEntity) {
    this.amlService.deleteAmlEntity(amlEntity.id).subscribe(() => {
      const currentAmlData = this.amlDataSubject.getValue();
      this.amlDataSubject.next(currentAmlData.filter((amlData) => amlData.id !== amlEntity.id));
    });
  }

  private loadAmlDataForClient(client: Client) {
    this.amlDataSubject.next(null);

    this.amlService.loadAmlDataForClient(client.id).subscribe((res) => {
      this.amlDataSubject.next(res ?? []);
    });
  }

  private appendAmlData(amlData: AmlEntity) {
    const currentAmlData = this.amlDataSubject.getValue();
    this.amlDataSubject.next([amlData, ...currentAmlData]);
  }

  private getInProgressObservable(): Observable<AmlEntity[]> {
    const doneStatus = [AmlStatus.DONE, AmlStatus.DECLINED];
    return this.amlDataSubject
      .asObservable()
      .pipe(map((res) => res?.filter((amlData) => !doneStatus.includes(amlData.status))));
  }

  private getDoneObservable(): Observable<AmlEntity[]> {
    const doneStatus = [AmlStatus.DONE, AmlStatus.DECLINED];
    return this.amlDataSubject
      .asObservable()
      .pipe(map((res) => res?.filter((amlData) => doneStatus.includes(amlData.status))));
  }

  private openLinkInNewTab(link: string | null) {
    if (!link) {
      return;
    }
    window.open(link, '_blank');
  }
}
