import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../entity/contact';
import { Observable } from 'rxjs';
import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../state/appState';

@Injectable()
export class ContactService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'contact';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  saveContact(contact: Contact): Observable<Contact> {
    const body = JSON.stringify(contact);
    return this.http
      .put<Contact>(this.url, body, this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }

  deleteContact(id: number): Observable<Contact[]> {
    return this.http
      .delete<Contact[]>(this.url + '/' + id, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }
}
