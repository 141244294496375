<p class="mb-6">
  Nedan visas de företag som finns publicerade i er lista för Företag i molnet och som inte finns i Byråstöd (baserat på
  organisationsnummer)
</p>
<p-table
  [columns]="columns"
  [value]="cloudCompanies$ | async"
  [rows]="50"
  [rowsPerPageOptions]="[25, 50, 100, 250, 1000]"
  [paginator]="true"
  [(selection)]="selectedCloudCompanies"
>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length + 1">
        <div class="my-3">{{ listMessage }}</div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3.4em">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th class="font-normal truncate" *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td>
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td *ngFor="let col of columns">
        {{ rowData[col.field] }}
      </td>
    </tr>
  </ng-template>
</p-table>
