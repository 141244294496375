import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { TaskType } from '@core/state/types';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'task-selector',
  template: `
    <p-dropdown
      [options]="tasks"
      [disabled]="disabled"
      [ngModel]="selectedTaskCode"
      (ngModelChange)="emitSelectedTask($event)"
      optionValue="code"
      optionLabel="type"
      styleClass="w-full"
      appendTo="body"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false">
    </p-dropdown>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskSelectorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FormsModule, DropdownModule],
})
export class TaskSelectorComponent implements ControlValueAccessor {
  @Input() tasks: TaskType[];
  @Input() disabled = false;
  @Input() selectedTaskCode: string;
  @Output() selectedTaskEmitter = new EventEmitter<TaskType>();

  emitSelectedTask(taskCode: string) {
    if (!this.tasks) {
      return;
    }

    const selectedTask = this.tasks.find((task) => task.code === taskCode) ?? this.tasks[0];
    this.selectedTaskEmitter.emit(selectedTask);
    this.onChange(selectedTask);
  }

  onChange: ControlValueAccessorFn = () => {};
  onTouch: ControlValueAccessorFn = () => {};

  writeValue(task: string | TaskType): void {
    if (typeof task === 'string') {
      this.selectedTaskCode = task;
      return;
    }
    this.selectedTaskCode = task?.code;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
