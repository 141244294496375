<div class="mt-5" *ngIf="downloadStatus$ | async as downloadStatus">
  <h1 class="!text-base !inline" [ngClass]="{'pl-5': padding, 'font-semibold': mode === 'system'}">Nedladdningsbara installationsfiler:</h1>
  <ng-container *ngIf="loading$ | async as loading">
    <ng-container *ngIf="loading">
      <i class="ml-2 fa fas fa-sync fa-spin"></i>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="hasAllIncService">
    <div [ngClass]="{'border-l': border,'border-bl-grey-400': border, 'pl-5': padding}">
      <div *ngIf="groups$ | async as groups">
        <div *ngFor="let group of groups" class="grid grid-cols-2 gap-1">
          <div *ngFor="let link of group.links">
            <ng-container *ngIf="link.download">
              <div class="mt-2">
                <ng-container *ngIf="downloadStatus[link.download]">
                  <div class="cursor-wait">
                    <img [src]="link.type | getIcon" class="mr-2 inline" alt="{{ link.title }}" />
                    <a>Laddar ner {{ link.title }} ...</a>
                    <i class="ml-2 fa fas fa-sync fa-spin"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="!downloadStatus[link.download]">
                  <div class="cursor-pointer">
                    <img [src]="link.type | getIcon" class="mr-2 inline" alt="{{ link.title }}" />
                    <a (click)="download(link.download)">{{ link.title }}</a>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <a href="https://www.bjornlunden.se/program/programversioner__4132" target="_blank"
          >Här kan du hämta äldre versioner av BL Skatt och BL Bokslut»</a
        >
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!hasAllIncService">
    <div
      class="opacity-50"
      pTooltip="För att ladda ner BL program direkt från Byråstöd krävs det att användaren är inloggad med helhetslösning eller BL Byrå"
    >
      <div [ngClass]="{'border-l': border,'border-bl-grey-400': border, 'pl-5': padding}">
        <div *ngIf="groups$ | async as groups">
          <ul *ngFor="let group of groups">
            <li *ngFor="let link of group.links">
              <div class="mt-2">
                <img [src]="link.type | getIcon" class="mr-2 inline" alt="{{ link.title }}" />
                <a>{{ link.title }}</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
</div>
