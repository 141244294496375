import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BLService } from './bl.service';
import { Store } from '@ngrx/store';
import { AppState } from '../state/appState';

@Injectable()
export class DataSafeService extends BLService {
  private url = 'https://apireg.blinfo.se/data-safe/deposit/nbr/10';

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  storeInvite(blappInvite: string): Observable<string> {
    return this.http.post<string>(this.url, blappInvite).pipe(this.catchErrorAndShowMessage());
  }
}
