import { TaskGroupTaskType, TaskGroupType } from '@app/core/state/types';
import {
  DialogData,
  DialogForm,
  DialogResult,
  FormDialogBaseComponent,
} from '../form-dialog-base/form-dialog-base.component';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { TableColumn } from '../table/interfaces/table-column';
import { BehaviorSubject, Observable, filter, map, scan } from 'rxjs';
import { TaskType } from '@app/core/state/types';
import { TaskService } from '@app/core/services/task.service';
import { PleaseWaitComponent } from '../please-wait/please-wait.component';
import { TaskGroupActions } from '@app/core/state/task-group/task-group.actions';
import { TaskGroupsActions } from '@app/core/state/task-groups/task-groups.actions';
import { Action } from '@ngrx/store';

interface Data extends DialogData {
  taskGroup: TaskGroupType;
}

interface Result extends DialogResult {
  taskGroup: TaskGroupType;
}

interface Form extends DialogForm<any> {
  id: FormControl<number>;
  name: FormControl<string>;
  tasks: FormControl<TaskGroupTaskType[]>;
}

@Component({
  selector: 'app-task-group-dialog',
  template: `
    <app-please-wait [hidden]="(tasks$ | async)?.length > 1"></app-please-wait>
    <div *ngIf="(tasks$ | async)?.length > 1">
      <form [formGroup]="form" class="flex flex-col" (ngSubmit)="onSubmit()">
        <div class="flex flex-col pb-2">
          <label for="name">Namn</label>
          <input
            id="name"
            type="text"
            formControlName="name"
            placeholder="Namnge din mallgrupp här (exvis Årskunder enskild firma)" />
        </div>
        <div class="flex flex-col pb-2">
          <label>Välj de tjänster som ska ingå i mallgruppen</label>
          <p-table
            [columns]="columns"
            [value]="tasks$ | async"
            [selection]="selectedTasks"
            (selectionChange)="onSelectionChange($event)"
            [scrollable]="true"
            [sortField]="columns[0].field"
            scrollHeight="346px"
            dataKey="id"
            #table>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td [attr.colspan]="columns.length + 1">
                  <div class="my-3">Inga träffar</div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
              <colgroup>
                <col *ngFor="let col of columns" />
              </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th style="width: 3.4em">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th
                  class="font-normal truncate"
                  *ngFor="let col of columns"
                  [pSortableColumn]="col.field"
                  [style]="getColumnWidth(col)">
                  {{ col.header }}
                  <p-sortIcon
                    [field]="col.field"
                    ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-task let-columns="columns">
              <tr>
                <td>
                  <p-tableCheckbox [value]="task"></p-tableCheckbox>
                </td>
                <td [style]="getColumnWidth(columns[0])" class="truncate">
                  {{ task.assignmentName }}
                </td>
                <td [style]="getColumnWidth(columns[1])" class="truncate">
                  {{ task.type }}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <div class="relative">Antal valda tjänster: {{ form.controls.tasks.value?.length }}</div>
            </ng-template>
          </p-table>
        </div>
        <div class="flex justify-end mt-4">
          <button type="submit" class="btn btn-success mr-2" [disabled]="form.invalid">Spara</button>
          <button type="button" class="btn btn-warning" (click)="close({ taskGroup: null })">Stäng</button>
        </div>
      </form>
      <div></div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TableModule, PleaseWaitComponent],
})
export class TaskGroupDialog extends FormDialogBaseComponent<Data, Result, Form> {
  tasksBS: BehaviorSubject<TaskType[]>;
  tasks$: Observable<TaskType[]>;
  selectedTasks: TaskType[];
  columns: TableColumn[] = [
    { field: 'assignmentName', header: 'Uppdrag' },
    { field: 'type', header: 'Tjänst' },
  ];

  constructor(
    protected override ref: DynamicDialogRef,
    protected override config: DynamicDialogConfig<Data>,
    private taskService: TaskService,
  ) {
    super(ref, config);
    this.tasksBS = new BehaviorSubject([]);
  }

  protected override onInitialized = () => {
    const { id, name, tasks } = this.config.data.taskGroup;

    this.form = this.builder.group({
      id: new FormControl(id),
      name: new FormControl(name, [Validators.required, Validators.maxLength(50)]),
      tasks: new FormControl(tasks),
    });

    this.tasks$ = this.tasksBS.asObservable();
    this.taskService
      .getTaskTemplateTypes()
      .pipe(
        map((tasks) => tasks.filter((t) => !t?.archived)),
        map((tasks) => {
          tasks.sort(this.sortTasks);
          return tasks;
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe((tasks) => {
        this.selectedTasks = tasks.filter((t) => this.form.controls.tasks.value.find((tg) => tg.code === t.code));
        this.tasksBS.next(tasks);
      });
    this.config.header = this.isExistingTaskGroup ? 'Redigera mallgrupp' : 'Ny mallgrupp';

    const initialState: { loaded: boolean; taskGroup?: TaskGroupType } = { loaded: false, taskGroup: undefined };
    this.actions$
      .pipe(
        filter(
          (action) =>
            action.type === TaskGroupsActions.loadSucceeded.type ||
            action.type === TaskGroupActions.createTaskGroupSucceeded.type ||
            action.type === TaskGroupActions.updateTaskGroupSucceeded.type,
        ),
        scan((acc, curr: Action & { taskGroup: TaskGroupType }) => {
          if (curr.type === TaskGroupsActions.loadSucceeded.type) {
            acc.loaded = true;
            return acc;
          }

          acc.taskGroup = curr.taskGroup;
          return acc;
        }, initialState),
        filter(({ loaded, taskGroup }) => loaded && Boolean(taskGroup)),
        this.takeUntilDestroyed(),
      )
      .subscribe(({ taskGroup }) => this.close({ taskGroup }));
  };

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<any>, 'data'> {
    return {
      width: '600px',
      modal: true,
      draggable: false,
      resizable: false,
      closeOnEscape: true,
    };
  }

  get isExistingTaskGroup(): boolean {
    return Boolean(this.config.data?.taskGroup?.id);
  }

  getColumnWidth(column: TableColumn) {
    if (!column) {
      return {};
    }

    if (!column.width || column.width === 'auto') {
      return {};
    }

    return { maxWidth: column.width };
  }

  onSelectionChange(selectedTasks: TaskType[]) {
    const tasks = selectedTasks.map(({ assignmentName, code, id, type }) => ({
      assignmentName,
      code,
      id,
      type,
    }));

    this.form.controls.tasks.patchValue(tasks);
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const { name, tasks: taskGroupTasks, id } = this.form.value;
    const tasks = taskGroupTasks.map((t) => t.id);

    const action = this.isExistingTaskGroup
      ? TaskGroupActions.update({ id, cmd: { name, tasks } })
      : TaskGroupActions.create({ cmd: { name, tasks } });
    this.store.dispatch(action);
  }

  private sortTasks = (a: TaskType, b: TaskType) => {
    if (!a && !b) {
      return 0;
    }

    if (!a && b) {
      return -1;
    }

    if (a && !b) {
      return 1;
    }

    const aString = a.assignmentName + '-' + a.type;
    const bString = b.assignmentName + '-' + b.type;

    return aString.localeCompare(bString, 'sv-SE');
  };
}
