import { createSelector } from '@ngrx/store';
import { ClientTypeType } from '../types';
import { AppState } from '../appState';

const clientTypes = (state: AppState) => state.clientTypes;

const all = createSelector(clientTypes, ({ error, types }) => {
  if (error) {
    return [{ name: 'ERROR', description: 'Något gick fel när typer av företag laddades' }] as ClientTypeType[];
  }

  return types;
});

export const ClientTypesSelectors = {
  all,
};
