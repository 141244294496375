import { createSelector } from '@ngrx/store';
import { AppState } from '../appState';

const firmState = (state: AppState) => state.firm;

const selectFirm = createSelector(firmState, (state) => state.firm);
const selectHasActiveAmlConnection = createSelector(firmState, (state) => !!state.amlApiEmail);
const selectAmlApiEmail = createSelector(firmState, (state) => state.amlApiEmail);
const selectFirmSettings = createSelector(firmState, (state) => state.settings);

export const FirmSelectors = {
  selectFirm,
  selectHasActiveAmlConnection,
  selectAmlApiEmail,
  selectFirmSettings,
};
