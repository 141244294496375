import { Period } from '@app/core/entity/period';
import { createActionGroup, props } from '@ngrx/store';
import { RuleType } from '../types';

export const RuleActions = createActionGroup({
  source: 'Rule',
  events: {
    Load: props<{ period: Period }>(),
    'Load Succeeded': props<{ rules: RuleType[] }>(),
    'Load Failed': props<{ error: any }>(),
  },
});
