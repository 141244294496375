import { AlertLevel, AlertLevelNames } from './alert.level';
import { State } from './state';
import { Task } from './task';
import { User } from './user';
import { Span } from './span';
import { StatusColor } from '../misc/status-color';
import { NameDescriptionType } from '../state/types';

export interface StateChangeEvent {
  changedBy: string;
  eventDate: string;
  id: number;
  state: { description: string; name: string };
}

export class Activity {
  id: number;
  taskId: number;
  alertLevel: AlertLevel = new AlertLevel();
  alert_level: AlertLevel = new AlertLevel();
  state: State = new State('', '');
  activeSpan: any = { end: '', spanLabel: '', spanType: '', start: '' };
  span: Span = { start: '', end: '', spanType: '', spanLabel: '' };
  start_span: string;
  end_span: string;
  stateColor: string;
  deadline: string;
  comment: string;
  task: Task;
  users: User[];
  statusChangeDate: string;
  stateChangeEvents: StateChangeEvent[];
  latestStateChangeEvent?: StateChangeEvent; // virtual
  unsavedId?: number;
  materialUpdate?: {
    alertLevel?: NameDescriptionType;
    when?: string;
  } = {};

  // Creates a new Activity object from json object
  static from(obj: any) {
    const activity: Activity = Object.assign(new Activity(), obj);
    activity.latestStateChangeEvent = activity.getLastestStateChangeEvent();
    activity.updateStateColor();
    return activity;
  }

  public isPersisted(): boolean {
    return !!this.id;
  }

  public isCompleted() {
    return this.alertLevel.name === AlertLevelNames.COMPLETED;
  }

  public hasAlertLevel() {
    return !!this.alertLevel.name;
  }

  public getLastestStateChangeEvent() {
    return (this.stateChangeEvents || []).reduce((acc, event) => {
      if (acc && acc.eventDate > event.eventDate) {
        return acc;
      }
      return event;
    }, undefined);
  }

  public updateStateColor() {
    this.stateColor = this.getStatusColor(this.alertLevel.name);
  }

  private getStatusColor(alertLevel: string) {
    return StatusColor.getColorByAlertName(alertLevel);
  }
}
