import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ClientListFilter, ClientType } from '../types';

export const ClientActions = createActionGroup({
  source: 'Client',
  events: {
    'Load All Clients': emptyProps(),
    'Load All Clients Succeeded': props<{ clients: ClientType[] }>(),
    'Load All Clients Failed': props<{ error: unknown }>(),
    'Update Clud Api Key': props<{ id: number; cloudApiKey: string }>(),
    'Load Client List': props<{ filter?: ClientListFilter }>(),
    'Load Clients List Succeeded': props<{ clients: ClientType[] }>(),
    'Load Clients List Failed': props<{ error: unknown }>(),
    'Reactivate Client': props<{ client: ClientType; predicate: (client: ClientType) => boolean }>(),
    'Reactivate Client Succeeded': props<{ client: ClientType }>(),
    'Reactivate Client Failed': props<{ error: any }>(),
    'Delete Client': props<{ client: ClientType; predicate: (client: ClientType) => boolean }>(),
    'Delete Client Succeeded': props<{ client: ClientType }>(),
    'Delete Client Failed': props<{ error: any }>(),
    'Archive Client': props<{ client: ClientType; predicate: (client: ClientType) => boolean }>(),
    'Archive Client Succeeded': props<{ client: ClientType }>(),
    'Archive Client Failed': props<{ error: any }>(),
    'Archive Deletable Client': props<{ client: ClientType; predicate: (client: ClientType) => boolean }>(),
    'Archive Deletable Client Succeeded': props<{ client: ClientType }>(),
    'Archive Deletable Client Failed': props<{ error: any }>(),
    'Activate Advisory Report': props<{ client: ClientType; predicate: (client: ClientType) => boolean }>(),
    'Activate Advisory Report Succeeded': props<{ client: ClientType }>(),
    'Activate Advisory Report Failed': props<{ error: any }>(),
    'Deactivate Advisory Report': props<{ client: ClientType; predicate: (client: ClientType) => boolean }>(),
    'Deactivate Advisory Report Succeeded': props<{ client: ClientType }>(),
    'Deactivate Advisory Report Failed': props<{ error: any }>(),
    'Activate Sustainability Reporting': props<{ clientId: number }>(),
    'Activate Sustainability Reporting Succeeded': emptyProps(),
    'Activate Sustainability Reporting Failed': emptyProps(),
    'Deactivate Sustainability Reporting': props<{ clientId: number }>(),
    'Deactivate Sustainability Reporting Succeeded': emptyProps(),
    'Deactivate Sustainability Reporting Failed': emptyProps(),
  },
});
