import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { RoleAsArray } from 'src/app/core/entity/user';
import { RoleType, UserType } from 'src/app/core/state/types';

interface RoleOption {
  id: string;
  label: string;
}

export interface AuthRoleSelectorEvent {
  value: RoleType;
  originalUser: UserType;
  cancel: () => void;
}

@Component({
  selector: 'app-auth-role-selector',
  template: `
    <p-dropdown
      [options]="roleOptions"
      optionValue="id"
      [ngModel]="selectedRole"
      (ngModelChange)="onChangeRole($event)"
      appendTo="body"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false">
    </p-dropdown>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthRoleSelectorsComponent {
  @Input()
  set user(value: UserType) {
    this.originalRole = value?.role;
    this.selectedRole = value?.role;
    this.originalUser = value;
  }

  @Output() roleChange: EventEmitter<AuthRoleSelectorEvent | null>;

  public roleOptions: RoleOption[];
  public selectedRole: RoleType;
  private originalRole: RoleType;
  private originalUser: UserType;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.roleChange = new EventEmitter();
    this.roleOptions = RoleAsArray;
  }

  onChangeRole(newRole: RoleType) {
    if (newRole === this.selectedRole) {
      return;
    }
    const cancel = () => {
      // Forced delay to run as next microtask after Prime dropdown has rendered the first selected value
      setTimeout(() => {
        this.selectedRole = this.originalRole;
        this.changeDetectorRef.markForCheck();
      }, 0);
    };
    this.roleChange.emit({ cancel, value: newRole, originalUser: this.originalUser });
  }
}
