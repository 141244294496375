import { Task } from 'src/app/core/entity/task';

export enum ApplicationType {
  BL = 'BL',
  BLSkatt = 'BLSkatt',
  BLBokslut = 'BLBokslut',
}

export interface ApplicationLink {
  type: ApplicationType;
  title: string;
  task: Task;
  disabled: boolean;
  year: number;
  download?: string;
}

export interface ApplicationLinksGroup {
  type: ApplicationType;
  links: ApplicationLink[];
}

export interface ApplicationLinksBuilder {
  canBuild(type: ApplicationType): boolean;
  build(type: ApplicationType, tasks: Task[]): ApplicationLink[];
}
