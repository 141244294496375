import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FirmSettings } from '@core/entity/firm.settings';
import { Role, User } from '@core/entity/user';
import { ActivityService } from '@core/services/activity.service';
import { FirmService } from '@core/services/firm.service';
import { PeriodService } from '@core/services/period.service';
import { UserService } from '@core/services/user.service';
import { FirmActions } from '@core/state/firm/firm.actions';
import { FirmSelectors } from '@core/state/firm/firm.selectors';
import { ToastActions } from '@core/state/toast/toast.actions';
import { FirmType } from '@core/state/types';
import { UserSettingsStorage } from '@core/storage/user.settings.storage';
import { TabsComponent } from '@shared/components/tabs/tabs.component';
import { DialogData, FormDialogBaseComponent } from '../shared/components/form-dialog-base/form-dialog-base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

interface Data extends DialogData {
  tabToOpen?: string;
}

@Component({
  selector: 'app-firm-settings-dialog',
  templateUrl: './firm.settings.dialog.html',
  styleUrls: ['./firm.settings.dialog.css'],
  providers: [PeriodService, ActivityService],
  encapsulation: ViewEncapsulation.None,
})
export class FirmSettingsDialog extends FormDialogBaseComponent<Data> implements OnInit, OnDestroy {
  @ViewChild('tabs', { static: true }) tabs: TabsComponent;

  public currentUser: User;

  public bulkHandlingTabActive = false;
  public teamTabActive = false;
  public fileImportTabActive = false;

  firmData$: Observable<FirmType>;
  firmAssignmentData$: Observable<FirmSettings> = this.firmService.firmAssignmentData$;

  constructor(
    private firmService: FirmService,
    private userService: UserService,
    ref: DynamicDialogRef,
    config: DynamicDialogConfig<Data>,
  ) {
    super(ref, config);
    this.currentUser = new User();
    this.firmData$ = this.store.select(FirmSelectors.selectFirm);
  }

  hasRightsToChange() {
    return this.currentUser.role === Role.FIRM_ADMIN;
  }

  resetAndReload() {
    this.userService
      .getCurrentUser()
      .pipe(this.takeUntilDestroyed())
      .subscribe((result) => {
        this.currentUser = result;
      });
  }

  updateFirmData(firmData: FirmType): void {
    this.store.dispatch(FirmActions.saveFirm({ firm: firmData }));
  }

  updateFirmAssignmentData(firmAssignmentData: FirmSettings): void {
    this.firmService.updateFirmSettings(firmAssignmentData).subscribe(
      (res) => {
        this.store.dispatch(
          ToastActions.showInfoMessage({ summary: 'Uppgifterna till uppdragsavtalet har sparats', detail: '' }),
        );
        this.firmService.setFirmAssignmentData(res);
      },
      (err: unknown) => {
        console.log('Error', err);
        this.store.dispatch(
          ToastActions.showErrorMessage({
            summary: 'Något gick fel!',
            detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
          }),
        );
      },
    );
  }

  resetFirmAssignmentData() {
    this.firmService.resetFirmSettings().subscribe(
      (x) => {
        this.firmService.setFirmAssignmentData(x);
        this.store.dispatch(ToastActions.showInfoMessage({ summary: 'Texterna är nu återställda.', detail: '' }));
      },
      (err: unknown) => {
        console.log('Error', err);
        this.store.dispatch(
          ToastActions.showErrorMessage({
            summary: 'Något gick fel!',
            detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
          }),
        );
      },
    );
  }

  resetCallback = () => {
    this.close({});
  };

  clearStorageAndLogout() {
    new UserSettingsStorage().clearSettings(true);
    window.location.href = environment.loginUrl;
  }

  protected override onInitialized = () => {
    this.resetAndReload();
    this.firmService.loadFirmAssignmentData();
    if (!this.config.data.tabToOpen) {
      this.tabs.selectFirst();
    }

    this.tabs.selectTabByTitle(this.config.data.tabToOpen);
  };

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<Data>, 'data'> {
    return {
      closeOnEscape: true,
      header: 'Byråinställningar',
      width: '1500px',
      contentStyle: { 'min-height': '650px', 'max-height': '75vh', 'padding-bottom': 0 },
      draggable: false,
      modal: true,
    };
  }
}
