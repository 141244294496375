import { createFeature, createReducer, on } from '@ngrx/store';
import { LoadingStatus, TaskType } from '../types';
import { TaskTemplateActions } from './task-templates.actions';

export interface TaskTemplatesState {
  templates: TaskType[];
  status: LoadingStatus;
  error: unknown;
}

export const initialTaskTemplatesState: TaskTemplatesState = {
  templates: [],
  status: LoadingStatus.NotStarted,
  error: null,
};

const updateTaskTemplate = (state: TaskTemplatesState, template: TaskType): TaskTemplatesState => ({
  ...state,
  templates: state.templates.map((t) => {
    if (t.id !== template.id) {
      return t;
    }

    return template;
  }),
  status: LoadingStatus.NotStarted,
  error: null,
});

export const taskTemplateFeature = createFeature({
  name: 'taskTemplates',
  reducer: createReducer(
    initialTaskTemplatesState,
    on(TaskTemplateActions.load, (state) => ({ ...state, status: LoadingStatus.Loading })),
    on(TaskTemplateActions.loadSucceeded, (state, { templates }) => ({
      ...state,
      status: LoadingStatus.NotStarted,
      error: null,
      templates,
    })),
    on(TaskTemplateActions.loadFailed, (state, { error }) => ({
      ...state,
      status: LoadingStatus.Failed,
      error,
      templates: [],
    })),
    on(TaskTemplateActions.saveSucceeded, (state, { template }) => updateTaskTemplate(state, template)),
    on(TaskTemplateActions.activateSucceeded, (state, { template }) => updateTaskTemplate(state, template)),
    on(TaskTemplateActions.archiveSucceeded, (state, { template }) => updateTaskTemplate(state, template)),
  ),
});
