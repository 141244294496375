import { Task } from 'src/app/core/entity/task';
import { ApplicationLink, ApplicationLinksBuilder, ApplicationType } from './types';

export abstract class BaseApplicationLinksBuilder implements ApplicationLinksBuilder {
  constructor(protected title: string) {}

  abstract canBuild(type: ApplicationType): boolean;
  abstract build(type: ApplicationType, tasks: Task[]): ApplicationLink[];

  protected cantBuildGuard(type: ApplicationType): void {
    if (!this.canBuild(type)) {
      throw new Error(`This builder can't build links of type:${type}.`);
    }
  }

  protected createLink(config: { type: ApplicationType; title: string; year: number; tasks: Task[] }): ApplicationLink {
    const { tasks, title, type, year } = config;
    const task = tasks.find((t) => t.assignmentAgent === type);
    const disabled = !task;
    return {
      type,
      title,
      task,
      disabled,
      year,
    };
  }
}
