<div class="flex items-center mb-14 pb-5 border-b border-bl-grey-200" [ngClass]="{ 'opacity-60': hasFallenOut }">
  <div *ngIf="chartData?.length > 8" (click)="previousDate()">
    <i class="far fa-chevron-left icon-2xl"></i>
  </div>
  <div class="flex-1 text-center">
    <p class="mb-0 text-lg font-bold">Återbäring</p>
    <!-- <div class="text-2xl font-semibold">{{ selectedYear$ | async }}</div> -->
  </div>
  <div *ngIf="chartData?.length > 8" (click)="nextDate()">
    <i class="far fa-chevron-right icon-2xl"></i>
  </div>
</div>
<div class="grid grid-cols-8" *ngIf="chartData?.length">
  <ng-container *ngFor="let data of chartData; let idx = index">
    <!-- grupp -->
    <div class="chart-group w-24" *ngIf="calculateBarHeightForPeriod(data) as barData">
      <bl-frontend-tooltip [heading]="barData.longDate" [content]="barData.tooltip">
        <!-- staplar -->
        <div class="grid grid-flow-col gap-1 items-end h-32">
          <div
            class="bar-value rounded relative"
            [ngStyle]="{ height: barData.refundPercent + '%' }"
            [ngClass]="{
              'current-month': barData.currentMonth,
              'bg-bl-grey-300': hasFallenOut,
              'bg-bl-orange-500': !hasFallenOut
            }"></div>
        </div>
        <div class="mt-5 text-center chart-label">{{ barData.shortDate }}</div>
      </bl-frontend-tooltip>
    </div>
  </ng-container>
</div>
