import { Injectable } from '@angular/core';
import { WizardDefaultValueService } from '@app/shared/components/cloud-db-export-wizard/wizard-default-value.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ExportWizardActions } from './export-wizard.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { toClientTransformer } from '../transformers/transformers';

@Injectable()
export class ExportWizardEffects {
  getDefaultValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExportWizardActions.getDefaultValues),
      switchMap(({ client }) =>
        this.wizardDefaultValueService.gatherDefaultValuesForWizardDialog(toClientTransformer.transform(client)).pipe(
          map((data) => ExportWizardActions.getDefaultValuesSucceeded({ data })),
          catchError((error: unknown) => of(ExportWizardActions.getDefaultValuesFailed({ error }))),
        ),
      ),
    ),
  );

  // eslint-disable-next-line no-useless-constructor
  constructor(private actions$: Actions, private wizardDefaultValueService: WizardDefaultValueService) {}
}
