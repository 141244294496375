export class FirmSettings {
  other_condition_text: string;
  other_terms_text: string;
  payment_condition_text4: string;
  payment_condition_text3: string;
  srf_text: string;
  srf_or_far: string;
  payment_condition_text2: string;
  payment_condition_text1: string;
  payment_text1: string;
  payment_text4: string;
  payment_text2: string;
  payment_text3: string;
  attachment_text2: string;
  attachment_text1: string;
  attachment_text3: string;
  material_delivery4: string;
  far_text: string;
  material_delivery3: string;
  material_delivery2: string;
  material_delivery1: string;
  notice_period: string;
  show_attachment_text1: string;
  show_attachment_text2: string;
  show_attachment_text3: string;
  show_payment_text1: string;
  show_payment_text2: string;
  show_payment_text3: string;
  show_payment_text4: string;
  show_payment_condition_text1: string;
  show_payment_condition_text2: string;
  show_payment_condition_text3: string;
  show_payment_condition_text4: string;
  use_old_bl_agent: string;
  maxLength: number;
  include_activities_for_archived_clients: string;
  show_hidden_databases_for_all_users: string;
}
