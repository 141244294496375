import { Component, Input } from '@angular/core';
import { Client } from 'src/app/core/entity/client';
import { Contact } from 'src/app/core/entity/contact';
import { ClientService } from 'src/app/core/services/clients.service';
import { ContactService } from 'src/app/core/services/contact.service';
import { TableColumn } from '../../../../table/interfaces/table-column';

@Component({
  selector: 'app-client-contacts',
  templateUrl: './client.contacts.component.html',
  providers: [ContactService],
})
export class ClientContactsComponent {
  _client: Client;
  _contactModel: Contact = new Contact();
  _selectedContact: Contact;
  public columns: TableColumn[] = [
    { field: 'label', header: 'Kontakt' },
    { field: 'phone', header: 'Telefon' },
    { field: 'email', header: 'E-post' },
    { field: 'comment', header: 'Kommentar' },
    { field: 'action', header: '', sortable: false, width: '40px' },
  ];

  constructor(private contactService: ContactService, private clientService: ClientService) {}

  @Input()
  set client(client: Client) {
    if (client) {
      this._client = client;
    }
    this._contactModel = new Contact();
  }

  get client() {
    return this._client;
  }

  get contactModel() {
    return this._contactModel;
  }

  get selectedContact() {
    return this._selectedContact;
  }

  get isContactFormValid() {
    return (
      this._contactModel.label?.trim() ||
      this._contactModel.email?.trim() ||
      this._contactModel.phone?.trim() ||
      this._contactModel.comment?.trim()
    );
  }

  saveContact() {
    this._contactModel.clientId = this._client.id;
    this.contactService.saveContact(this._contactModel).subscribe(() => this.loadExistingContacts());
    this.unSelect();
  }

  unSelect() {
    this._selectedContact = undefined;
    this._contactModel = new Contact();
  }

  onRowSelect(event: { data: any }) {
    this._contactModel = JSON.parse(JSON.stringify(event.data));
  }

  deleteContact(id: number) {
    this.contactService.deleteContact(id).subscribe(() => this.loadExistingContacts());
  }

  private loadExistingContacts() {
    this.clientService.getClientById(this._client.id).subscribe((client) => (this._client = client));
  }
}
