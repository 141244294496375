import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { AmlRisk } from '@app/core/state/types/aml.types';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { CalendarComponent } from '@app/shared/components/calendar/calendar.component';
import {
  DialogForm,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { TodayDate } from '@app/shared/misc/today.date';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

interface Result extends DialogResult {
  risk: AmlRisk;
  date: string;
}

interface Form extends DialogForm<unknown> {
  risk: FormControl<AmlRisk>;
  date: FormControl<string>;
}

@Component({
  template: `
    <form [formGroup]="form" (ngSubmit)="onSubmit()" pFocusTrap>
      <label for="risk" class="mb-0">Riskbedömning</label>
      <p-dropdown
        id="risk"
        formControlName="risk"
        [options]="amlRiskOptions"
        optionLabel="label"
        optionValue="value"
        placeholder="Välj risknivå"
        [style]="{ 'min-width': '100%' }"></p-dropdown>

      <label for="date" class="mt-6">Datum</label>
      <app-calendar
        id="date"
        formControlName="date"
        [style]="{ 'max-width': '100%', display: 'flex' }"
        appendTo="body"></app-calendar>

      <div class="flex flex-row w-full justify-end mt-8">
        <bl-frontend-button type="button" variant="secondary" (click)="close(null)" text="Avbryt"></bl-frontend-button>
        <bl-frontend-button
          type="submit"
          variant="primary"
          class="ml-2"
          [disabled]="form.invalid"
          text="Spara"></bl-frontend-button>
      </div>
    </form>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DropdownModule, ReactiveFormsModule, CalendarComponent, BlFrontendButtonComponent],
})
export class CreateManualAmlDialogComponent extends FormDialogBaseComponent<null, Result, Form> {
  amlRiskOptions = [
    { label: 'Låg', value: AmlRisk.LOW },
    { label: 'Normal', value: AmlRisk.NORMAL },
    { label: 'Hög', value: AmlRisk.HIGH },
  ];

  protected override onInitialized = () => {
    this.form = this.builder.group({
      risk: new FormControl(null, [Validators.required]),
      date: new FormControl(TodayDate.getIsoFormattedDate(new Date())),
    });
  };

  protected static override getDialogConfig(): Omit<DynamicDialogConfig, 'data'> {
    return {
      header: 'Lägg till riskbedömning',
      width: '350px',
      height: 'auto',
      modal: true,
    };
  }

  onSubmit() {
    const { risk, date } = this.form.value;
    this.close({ risk, date });
  }
}
