<div
  style="min-height: 600px"
  (drop)="drop($event)"
  class="mb-10px"
  (dragover)="dragOver($event)"
  (dragenter)="dragEnter($event)"
  (dragleave)="dragLeave($event)"
  title="Dra och släpp filer/mappar här för att spara dessa i molnet, samt högerklicka på filer/mappar för kontextmeny"
>
  <app-please-wait [hidden]="hidePleaseWait"></app-please-wait>
  <button type="button" class="btn btn-sm btn-primary" [disabled]="!_client" (click)="loadFiles()">
    <i [class]="refreshIcon"></i>
  </button>
  Sökväg: {{ currentVisualPath }}
  <button type="button" [disabled]="currentVisualPath === '/'" class="btn btn-sm btn-primary" (click)="upDir()">
    <i class="fa fa-arrow-up"></i>
  </button>
  <button
    type="button"
    class="btn btn-sm btn-primary float-right"
    [disabled]="sendingFiles"
    (click)="onUploadButtonClicked()"
  >
    Välj fil(er) att ladda upp
  </button>
  <input
    type="file"
    #fileUpload
    id="fileUpload"
    name="fileUpload"
    multiple="multiple"
    accept="*/*"
    style="display: none"
    (change)="onUploadFile($event)"
  />

  <p-table
    [columns]="columns"
    [value]="files"
    [contextMenu]="cm"
    [(contextMenuSelection)]="selectedRow"
    [(selection)]="selectedRow"
    selectionMode="single"
    (onRowUnselect)="onRowUnselect($event)"
    [scrollable]="true"
    scrollHeight="flex"
    [style]="{ 'max-height': '550px' }"
    [tableStyle]="{'table-layout':'fixed'}"
  >
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">
          <div class="my-3">
            {{
              loading
                ? 'Laddar listan, vänligen vänta...'
                : 'Dra-och-släpp filer/mappar här för att
            spara i molnet'
            }}
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="font-normal truncate" [pSortableColumn]="columns[0].field">
          {{ columns[0].header }}
          <p-sortIcon
            [field]="columns[0].field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </th>
        <th class="font-normal truncate" style="flex: 0 0 40px; width: 40px"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData" (dblclick)="onRowDblClick(rowData)">
        <td [pContextMenuRow]="rowData">
          <i
            [class]="rowData.isFolder ? 'fa fa-folder' : 'far fa-file'"
            [style.color]="rowData.isFolder ? '#ffcc00' : ''"
          ></i
          ><span class="ml-2"> {{ rowData.name }}</span>
        </td>
        <td style="flex: 0 0 40px; width: 40px">
          <span (click)="deleteFile(rowData)" class="far fa-trash-alt" style="text-align: center; cursor: pointer">
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <table class="mt-3">
    <tr>
      <td><input type="text" class="form-control input-sm" [(ngModel)]="directory" placeholder="ny mapp" /></td>
      <td style="padding-left: 10px">
        <button type="button" class="btn btn-sm btn-success" (click)="createDir()" [disabled]="!directory">
          Skapa
        </button>
      </td>
      <td style="padding-left: 10px"></td>
    </tr>
  </table>

  <p-contextMenu #cm appendTo="body" [model]="contextMenuItems"></p-contextMenu>

  <p-dialog header="Ta bort fil/mapp?" [(visible)]="displayDeleteFile" showEffect="fade" [draggable]="false">
    <p>{{ pendingDeletionDocument.name }} kommer tas bort permanent.</p>
    <p>Vill du fortsätta?</p>
    <p-footer>
      <button type="button" class="btn btn-success" (click)="afterDeleteDialog(true)">Ja</button>
      <button type="button" class="btn btn-warning" (click)="afterDeleteDialog(false)">Nej</button>
    </p-footer>
  </p-dialog>

  <p-dialog
    header="Byt namn på fil/mapp?"
    [(visible)]="displayRenameDialog"
    showEffect="fade"
    [style]="{ width: '400px' }"
    [draggable]="false"
  >
    <input type="text" class="form-control input-sm" [(ngModel)]="pendingRenameDocument.name" />
    <p-footer>
      <button type="button" class="btn btn-success" (click)="afterRenameDialog(true)">Spara</button>
      <button type="button" class="btn btn-warning" (click)="afterRenameDialog(false)">Avbryt</button>
    </p-footer>
  </p-dialog>
</div>
