import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MeatBallMenuProps } from '@bl/components';
import { BlFrontendBaseComponent } from './bl-frontend-base.component';

@Component({
  selector: 'bl-frontend-meatballmenu',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class BlFrontendMeatBallMenuComponent extends BlFrontendBaseComponent<MeatBallMenuProps> {
  @Input() items: MeatBallMenuProps['items'] = [];
  @Input() disabled: MeatBallMenuProps['disabled'] = false;

  protected override getComponentName(): string {
    return 'MeatBallMenu';
  }

  protected override getProps(): MeatBallMenuProps {
    return {
      items: this.items,
      brand: 'firmsupport',
      disabled: this.disabled,
    };
  }
}
