import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RuleCode } from '@app/core/entity/ruleCode';
import { deadlineTypes } from 'src/app/core/entity/deadlineTypes';
import { getValidator, ValidatorResult } from 'src/app/core/entity/validation';
import { ValidationStatus } from 'src/app/core/entity/validationStatus';
import { ValidationType } from 'src/app/core/entity/validationType';
import { InputNumberModule } from 'primeng/inputnumber';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { getMinMaxFromPattern } from '../../misc/getMinMaxFromPattern';
import { CalendarComponent } from '../calendar/calendar.component';

@Component({
  selector: 'app-deadline-value',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <app-calendar
      *ngIf="calendarVisible"
      [(selectedDate)]="value"
      [disabled]="disabled"
      appendTo="body"
      styleClass="w-full"
      [style]="style"
      [defaultDate]="defaultDate"
      [title]="invalidTitle"
      [invalid]="invalid"
      [inputId]="inputId"></app-calendar>
    <p-inputNumber
      *ngIf="inputVisible"
      [disabled]="disabled"
      [(ngModel)]="value"
      [class]="invalid ? 'w-full ng-invalid ng-dirty' : 'w-full'"
      [style]="style"
      [styleClass]="invalid ? 'w-full ng-invalid ng-dirty' : 'w-full'"
      [title]="invalidTitle"
      locale="sv-SE"
      [inputId]="inputId"></p-inputNumber>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DeadlineValueComponent,
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, CalendarComponent, InputNumberModule],
})
export class DeadlineValueComponent implements ControlValueAccessor {
  public calendarVisible = true;
  public inputVisible = true;
  public disabled = true;
  public invalid = false;
  public invalidTitle = '';
  public onChange: ControlValueAccessorFn = () => {};
  public onTouched: ControlValueAccessorFn = () => {};
  private validationType = ValidationType.NONE;
  private _value?: string | null;
  private _type?: string;
  private _code?: string;
  private label = '';

  @Input() defaultDate: string = '';
  @Input() style: any;
  @Input() inputId: string;

  @Input()
  set code(newCode: string) {
    this._code = newCode;
    this.validationType = deadlineTypes[newCode as RuleCode] ?? ValidationType.NONE;
    this.calendarVisible = this.validationType === ValidationType.DATE;
    this.inputVisible = this.validationType !== ValidationType.DATE;
    this.disabled = this.validationType === ValidationType.NONE;
    this.validate();
  }

  @Input()
  set type(newType: string) {
    this._type = newType;
    this.label = `Regelvärde: ${newType ? '(' + newType + ')' : ''}`;
    this.validate();
  }

  @Input()
  get value(): string | null {
    return this._value;
  }
  set value(newValue: string | null) {
    this._value = newValue || null;
    this.valueChange.emit(newValue);
    this.onChange(newValue);
    this.validate();
  }

  @Output() valueChange: EventEmitter<string | null>;
  @Output() valueValidated: EventEmitter<ValidatorResult>;

  constructor() {
    this.valueChange = new EventEmitter<string | null>();
    this.valueValidated = new EventEmitter<ValidatorResult>();
  }

  writeValue(value: string | null): void {
    this.value = value;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  setDisabledState?(_isDisabled: boolean): void {}

  private validate() {
    const validationType = deadlineTypes[this._code as RuleCode] ?? ValidationType.NONE;
    if (validationType === ValidationType.NONE || !this._type || this._value === undefined) {
      this.invalid = false;
      this.invalidTitle = '';
      this.valueValidated.emit({ status: ValidationStatus.Ok });
      return;
    }

    const { min, max } = getMinMaxFromPattern(this._type);
    const { validate } = getValidator(this.validationType);
    const validationResult = validate(this._value, { min, max, label: this.label });
    this.invalid = validationResult.status !== ValidationStatus.Ok;
    this.invalidTitle = this.invalid ? validationResult.title : '';
    this.valueValidated.emit(validationResult);
  }
}
