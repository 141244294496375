import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TaskService } from '@app/core/services/task.service';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { TaskType } from '@app/core/state/types';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-team-task-list',
  templateUrl: './team-task-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BystSharedModule],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TeamTaskListComponent), multi: true }],
})
export class TeamTaskListComponent implements ControlValueAccessor {
  tasks$: Observable<TaskType[]>;
  columns: TableColumn[] = [
    { field: 'assignmentName', header: 'Uppdrag' },
    { field: 'type', header: 'Tjänst' },
  ];

  selectedTasks: TaskType[];
  showOnlySelectedRows = false;

  onChange: ControlValueAccessorFn = () => {};
  onTouched: ControlValueAccessorFn = () => {};

  private showOnlySelectedRowsBS = new BehaviorSubject<boolean>(false);

  get filterFields() {
    return this.columns.map((column) => column.field);
  }

  constructor(taskService: TaskService, private changeDetectorRef: ChangeDetectorRef) {
    this.tasks$ = taskService.getActiveTaskTypeTemplates().pipe(
      switchMap((tasks) =>
        this.showOnlySelectedRowsBS.pipe(
          map((showOnlySelectedRows) => {
            if (!showOnlySelectedRows) return tasks;
            const filteredTaskIds = this.selectedTasks.map((task) => task.id);
            return tasks.filter((task) => filteredTaskIds.includes(task.id));
          }),
        ),
      ),
    );
  }

  writeValue(obj: any): void {
    this.selectedTasks = obj;
    this.changeDetectorRef.markForCheck();
    // reset checkbox and filter
    this.showOnlySelectedRowsBS.next(false);
    this.showOnlySelectedRows = false;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  refilterTasks(event?: boolean) {
    const showOnlySelectedRows = typeof event === 'boolean' ? event : this.showOnlySelectedRowsBS.value;
    this.showOnlySelectedRowsBS.next(showOnlySelectedRows);
  }
}
