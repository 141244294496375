import { Component, Input } from '@angular/core';
import { ActivityStateType } from '@app/core/state/types';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-form-activity-states-selector',
  template: `
    <p-dropdown
      [autoDisplayFirst]="false"
      [options]="selectableStates"
      optionLabel="description"
      [ngModel]="selectedState"
      (ngModelChange)="onDropDownChange($event)"
      [disabled]="disabled"
      appendTo="body"
      [scrollHeight]="scrollHeight"
      [inputId]="inputId"
      [title]="invalid ? errors?.message ?? '' : ''"
      [class]="invalid ? 'ng-invalid ng-dirty' : ''"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false">
    </p-dropdown>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormActivityStateSelectorComponent,
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, DropdownModule],
})
export class FormActivityStateSelectorComponent implements ControlValueAccessor {
  @Input() errors?: ValidationErrors;
  @Input() inputId: string;
  @Input()
  get selectableStates(): ActivityStateType[] {
    return this.internalSelectableStates;
  }

  set selectableStates(selectableStates: ActivityStateType[]) {
    this.internalSelectableStates = selectableStates ?? [];
    this.scrollHeight = getScrollHeightString(this.selectableStates.length);
  }

  public scrollHeight: string;
  public disabled: boolean;
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
  public onChange: any = () => {};
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
  public onTouched: any = () => {};
  public selectedState: ActivityStateType;

  get invalid() {
    return Boolean(this.errors);
  }

  private internalSelectableStates: ActivityStateType[];

  writeValue(selectedState?: ActivityStateType): void {
    this.selectedState = this.selectableStates.find((s) => s.name === selectedState?.name);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDropDownChange(value: ActivityStateType) {
    const newState = this.selectableStates.find((state) => state === value);

    if (newState) {
      this.selectedState = newState;
      this.onChange(newState);
    }
  }
}
