import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';
import { FirmSettings } from 'src/app/core/entity/firm.settings';

@Component({
  selector: 'app-firm-assignment-settings',
  templateUrl: './firm-assignment-settings.component.html',
  styleUrls: ['./firm-assignment-settings.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirmAssignmentSettingsComponent implements OnInit, OnDestroy {
  assignmentSettingsForm: UntypedFormGroup;
  firmAssignmentData: FirmSettings;
  resetDialog: boolean;
  maxLength = 500;
  charsLeftConditions: string;
  charsLeftTerms: string;
  @Input() set firmAssignmentData$(firmAssignmentData$: any) {
    const { ...firmAssignmentData } = firmAssignmentData$;
    this.firmAssignmentData = firmAssignmentData;
    this.assignmentSettingsForm.patchValue(firmAssignmentData);
  }

  @Output() updateFirmAssignmentData = new EventEmitter();
  @Output() resetFirmAssignmentData = new EventEmitter();

  private onDestroyBS: Subject<boolean>;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.onDestroyBS = new Subject();
    this.setAssignmentSettingsForm();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.onDestroyBS.next(true);
  }

  setAssignmentSettingsForm(): void {
    this.assignmentSettingsForm = this.formBuilder.group({
      other_condition_text: [''],
      other_terms_text: [''],
      payment_condition_text4: [''],
      payment_condition_text3: [''],
      srf_text: [''],
      srf_or_far: [''],
      payment_condition_text2: [''],
      payment_condition_text1: [''],
      payment_text1: [''],
      payment_text4: [''],
      payment_text2: [''],
      payment_text3: [''],
      attachment_text2: [''],
      attachment_text1: [''],
      attachment_text3: [''],
      material_delivery4: [''],
      far_text: [''],
      material_delivery3: [''],
      material_delivery2: [''],
      material_delivery1: [''],
      notice_period: [''],
      show_payment_condition_text1: [''],
      show_payment_condition_text2: [''],
      show_payment_condition_text3: [''],
      show_payment_condition_text4: [''],
      show_payment_text1: [''],
      show_payment_text2: [''],
      show_payment_text3: [''],
      show_payment_text4: [''],
      show_attachment_text1: [''],
      show_attachment_text2: [''],
      show_attachment_text3: [''],
      show_attachment_text4: [''],
    });

    this.assignmentSettingsForm.valueChanges
      .pipe(distinctUntilKeyChanged('other_condition_text'), takeUntil(this.onDestroyBS.asObservable()))
      .subscribe((form) => {
        this.charsLeftConditions = String(Math.abs(this.maxLength - form.other_condition_text.length));
      });

    this.assignmentSettingsForm.valueChanges
      .pipe(distinctUntilKeyChanged('other_terms_text'), takeUntil(this.onDestroyBS.asObservable()))
      .subscribe((form) => {
        this.charsLeftTerms = String(Math.abs(this.maxLength - form.other_terms_text.length));
      });
  }

  updateAssignmentSettingsData() {
    this.updateFirmAssignmentData.emit(this.assignmentSettingsForm.value);
  }

  resetAssignmentSettings() {
    this.resetFirmAssignmentData.emit();
    this.resetDialog = false;
  }

  onSrfOrFarToggle(eventTarget: EventTarget) {
    const event = eventTarget as HTMLInputElement;
    if (event.checked) {
      this.firmAssignmentData.srf_or_far = event.id;
    } else {
      this.firmAssignmentData.srf_or_far = '';
    }
    // assign the value of the chosen checkbox to the formcontrol 'srf_or_far';
    this.assignmentSettingsForm.get('srf_or_far').setValue(this.firmAssignmentData.srf_or_far);
  }

  onCheckboxChange(eventTarget: EventTarget) {
    const event = eventTarget as HTMLInputElement;
    // take the ID of the input element (which must match the name of the form control) and
    // update the corresponding form control with the new value
    this.assignmentSettingsForm.get(`${event.id}`).setValue(`${event.checked}`);
  }
}
