import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Document } from '../entity/document';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { BLService } from './bl.service';

import { environment } from 'src/environments/environment';
import { escapeHtmlChars, getClientDocumentsRoot } from '../misc/url';
import { Store } from '@ngrx/store';
import { AppState } from '../state/appState';
import { fileNameNormalizer } from '@app/shared/misc/fileNameNormalizer';

@Injectable()
export class DocumentService extends BLService {
  private options = new HttpAuthorizationHeader('application/text; charset=utf-8');
  private RESOURCE_ENDPOINT = 'document';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  public getFilesAndFolders(clientId: number, path: string): Observable<Document[]> {
    return this.http
      .get<Document[]>(
        getClientDocumentsRoot(this.url, clientId) + escapeHtmlChars(path),
        this.options.getAuthorizationHeaderWithEmptyBody(),
      )
      .pipe(this.catchErrorAndShowMessage());
  }

  public getFile(clientId: number, path: string): Observable<any> {
    const options = this.options.getAuthorizationHeaderWithResponseType('arraybuffer');
    options.headers.delete('Content-Type');
    return this.http
      .get<any>(getClientDocumentsRoot(this.url, clientId) + escapeHtmlChars(path), options)
      .pipe(this.catchErrorAndShowMessage());
  }

  public extractResponse(res: any) {
    return { result: res.status };
  }

  createDirectory(clientId: number, path: string, dir: string): Observable<any> {
    return this.http
      .post<any>(
        getClientDocumentsRoot(this.url, clientId) + escapeHtmlChars(path) + '?folderName=' + fileNameNormalizer(dir),
        '',
        this.options.getAuthorizationHeader(),
      )
      .pipe(this.catchErrorAndShowMessage());
  }

  saveFile(clientId: number, path: string, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('uploadFile', file, fileNameNormalizer(file.name));
    const postRequest = new HttpRequest(
      'POST',
      getClientDocumentsRoot(this.url, clientId) + escapeHtmlChars(path),
      formData,
      this.options.getAuthorizationHeaderWithoutContentType(),
    );
    return this.http.request(postRequest).pipe(catchError((err: unknown) => this.handleFileSaveError(err)));
  }

  deleteFileOrFolder(clientId: number, path: string): Observable<any> {
    return this.http
      .delete<any>(
        getClientDocumentsRoot(this.url, clientId) + escapeHtmlChars(path),
        this.options.getAuthorizationHeader(),
      )
      .pipe(this.catchErrorAndShowMessage());
  }

  renameFileOrFolder(clientId: number, path: string, newName: string): Observable<any> {
    return this.http
      .put<any>(
        getClientDocumentsRoot(this.url, clientId) +
          escapeHtmlChars(path) +
          '?operation=rename&newName=' +
          fileNameNormalizer(newName),
        '',
        this.options.getAuthorizationHeader(),
      )
      .pipe(this.catchErrorAndShowMessage());
  }

  public handleFileSaveError(error: any) {
    let errMsg = '';
    if (error._body) {
      try {
        errMsg = JSON.parse(error._body).message;
      } catch (exception) {
        errMsg = 'Server error';
      }
    } else {
      errMsg = 'Server error';
    }
    console.error(error._body ? JSON.stringify(error._body) : error);
    return observableThrowError(errMsg);
  }
}
