import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogForm } from '../form-dialog-base/form-dialog-base.component';
import { DialogData, DialogResult } from '../form-dialog-base/form-dialog-base.component';
import { Component } from '@angular/core';
import { FormDialogBaseComponent } from '../form-dialog-base/form-dialog-base.component';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from '../calendar/calendar.component';
import { TodayDate } from '@app/shared/misc/today.date';
import { validateDateFn } from '@app/core/entity/validation';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActivityActions } from '@app/core/state/activities/activities.actions';

export interface CreateActivityDialogData extends DialogData {
  taskId: number;
}

export interface CreateActivityDialogResult extends DialogResult {
  save: boolean;
}

interface CreateActivityDialogForm extends DialogForm<any> {
  taskId: FormControl<number>;
  date: FormControl<string>;
  comment: FormControl<string>;
}

@Component({
  selector: 'app-create-activity-dialog',
  template: `
    <div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <table>
          <tr>
            <td class="text-thick">Deadline</td>
          </tr>
          <tr>
            <td>
              <app-calendar
                formControlName="date"
                defaultDate=""
                appendTo="body"
                styleClass="w-full"
                [invalid]="form.controls.date.invalid"
                [title]="form.controls.date.invalid ? form.controls.date.errors.date.message : ''"
              ></app-calendar>
            </td>
          </tr>
          <tr>
            <td class="text-thick">Kommentar</td>
          </tr>
          <tr>
            <td>
              <textarea
                formControlName="comment"
                class="form-control"
                style="resize: none"
                rows="3"
                cols="50"
                maxlength="400"
              ></textarea>
            </td>
          </tr>
        </table>
        <div class="flex justify-end mt-4">
          <button type="submit" class="btn btn-success mr-2" [disabled]="form.invalid">Spara</button>
          <button type="button" class="btn btn-warning" (click)="close({ save: false })">Stäng</button>
        </div>
      </form>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CalendarComponent],
})
export class CreateActivityDialog extends FormDialogBaseComponent<
  CreateActivityDialogData,
  CreateActivityDialogResult,
  CreateActivityDialogForm
> {
  protected override onInitialized = () => {
    this.form = this.builder.group<CreateActivityDialogForm>({
      taskId: new FormControl(this.config.data.taskId),
      date: new FormControl(new TodayDate().getTodayAsString(), [validateDateFn({ key: 'date' })]),
      comment: new FormControl(''),
    });
  };

  protected override onBeforeClose = (result: CreateActivityDialogResult) => {
    if (!result.save) {
      return;
    }

    this.store.dispatch(
      ActivityActions.saveSingleActivity({
        taskId: this.form.controls.taskId.value,
        date: this.form.controls.date.value,
        comment: this.form.controls.comment.value,
      })
    );
  };

  onSubmit() {
    if (this.form.invalid) {
      this.close({ save: false });
      return;
    }

    this.close({ save: true });
  }

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<CreateActivityDialogData>, 'data'> {
    return {
      header: 'Skapa aktivitet',
      draggable: false,
      modal: true,
    };
  }
}
