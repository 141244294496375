import { createAction, props } from '@ngrx/store';
import { TodoListFilter, TodoListParams } from '../types';
import { TodoType } from '../types/todo.types';

const load = createAction('[Todos] Load All Todos', props<{ filter: TodoListFilter; params: TodoListParams }>());
const todoDelete = createAction(
  '[Todos] Delete Todo',
  props<{ todo: TodoType; predicate: (todo: TodoType) => boolean }>(),
);
const todoDeleteSucceeded = createAction('[Todos] Delete Todo Succeeded', props<{ todo: TodoType }>());
const todoDeleteFailed = createAction('[Todos] Delete Todo Failed', props<{ error: unknown }>());

const updateState = createAction(
  '[Todos] Update Todo State',
  props<{
    state: string;
    todo: TodoType;
    predicate: (todo: TodoType) => boolean;
  }>(),
);

export const TodosActions = {
  load,
  todoDelete,
  todoDeleteSucceeded,
  todoDeleteFailed,
  updateState,
};
