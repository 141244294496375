<ng-container *ngIf="componentState$ | async as componentState">
  <div class="flex mb-10">
    <div class="flex flex-col pr-10">
      <ng-content select="[actionTitle]"></ng-content>
      <p-dropdown
        [options]="changeActionTypes"
        [disabled]="componentState?.loading"
        [(ngModel)]="selectedActionType"
        optionLabel="label"
        optionValue="key"
        (onChange)="onActionTypeSelected()"
        [placeholder]="actionSelectPlaceholder"
        [style]="{ 'min-width': '100%' }"
        [autoOptionFocus]="false"
        [autoDisplayFirst]="false"
      ></p-dropdown>
    </div>

    <div class="flex">
      <!-- <div class="bulk-item-container"> -->
      <div class="flex items-end">
        <ng-template bulkItemHost></ng-template>
        <button
          *ngIf="selectedActionType"
          [disabled]="!componentState?.valid || componentState?.loading"
          class="btn btn-primary ml-5"
          (click)="loadTableClicked()"
        >
          Visa urval<i class="ml-2 fa fas fa-sync fa-spin" *ngIf="componentState?.loading"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="tableColumns !== undefined">
  <p-table
    [columns]="tableColumns"
    [value]="tableData"
    [(selection)]="tableSelectedRows"
    [scrollable]="true"
    scrollHeight="370px"
    [rowTrackBy]="rowTrackBy()"
    [dataKey]="dataIdentityKey"
    [tableStyle]="{'table-layout':'fixed'}"
  >
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns?.length">Filtret gav inga träffar.</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 3rem; flex: 0 0 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th *ngFor="let col of columns">
          <div class="flex justify-between items-center">
            {{ col.header }}
            <p-columnFilter
              #textColumnFilter
              *ngIf="col.filter.type === 'text'"
              class="p-columnFilter"
              [field]="col.field"
              display="menu"
              [hideOnClear]="true"
              [showOperator]="false"
              operator="or"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <input
                  type="text"
                  pInputText
                  [ngModel]="value"
                  (input)="filter($any($event.target).value)"
                  (keydown.enter)="textColumnFilter.applyFilter()"
                />
              </ng-template>
            </p-columnFilter>

            <p-columnFilter
              *ngIf="col.filter.type === 'dropdown'"
              [field]="col.field"
              display="menu"
              [matchModeOptions]="dropdownMatchModeOptions"
              [matchMode]="'custom-dropdown-equals'"
              [showMatchModes]="false"
              [hideOnClear]="true"
              [showOperator]="false"
              operator="or"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="col.filter.data | async"
                  [optionLabel]="col.filter.dataLabel"
                  [optionValue]="col.filter.dataValue"
                  (onChange)="filter($event.value)"
                  placeholder="Alla"
                ></p-dropdown>
              </ng-template>
            </p-columnFilter>

            <p-columnFilter
              *ngIf="col.filter.type === 'multidropdown' || col.filter.type === 'multidropdownwithmultirules'"
              [field]="col.field"
              display="menu"
              [matchModeOptions]="multiSelectDropdownMatchModeOptions"
              [matchMode]="'custom-multiselect-dropdown-equals'"
              [showAddButton]="col.filter.type === 'multidropdownwithmultirules'"
              [showMatchModes]="false"
              [hideOnClear]="true"
              [showOperator]="false"
              operator="or"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <app-multiselect-dropdown
                  [selected]="value"
                  [breakpoint]="12"
                  [options]="col.filter.data | async"
                  [optionLabel]="col.filter.dataLabel"
                  [optionValue]="col.filter.dataValue"
                  [selectedItemsLabel]="col.filter.dataSelectedItemsLabel"
                  [maxSelectedLabels]="1"
                  (selectedChange)="filter($event)"
                  placeholder="Alla"
                ></app-multiselect-dropdown>
              </ng-template>
            </p-columnFilter>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td style="width: 3rem; flex: 0 0 3rem; text-overflow: unset">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns">
          {{ getCellData(rowData, col) }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <button class="btn btn-success mt-3" [disabled]="!tableSelectedRows.length" (click)="executeChange()">
    Utför ändringarna ({{ tableSelectedRows.length }} st)
  </button>
</ng-container>
