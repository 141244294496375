import { Injectable } from '@angular/core';

@Injectable()
export class UserSettingsStorage {
  public static ACTIVITIES_START_DATE = 'act_start_date';
  public static ACTIVITIES_END_DATE = 'act_end_date';
  public static ACTIVITIES_USER_ID = 'act_user';
  public static ACTIVITIES_SHOW_FINISHED = 'act_show_fin';
  public static ACTIVITIES_SEARCH_UNCOMPLETED = 'act_search_uncompleted';
  public static ACTIVITIES_SELECTED_LENGTH = 'act_sel_len';
  public static ACTIVITIES_NUMBER_OF_ROWS = 'act_num_rows';
  public static ACTIVITIES_CLIENT_RESPONSIBLE_ID = 'act_resp_user';

  public static ACCOUNTING_CLIENT_RESPONSIBLE_ID = 'acc_resp_user';
  public static ACCOUNTING_USER_ID = 'acc_user';
  public static ACCOUNTING_CRITERIA = 'acc_crit';
  public static ACCOUNTING_FILTER_CRITERIA = 'acc_filt';
  public static ACCOUNTING_MONTH = 'acc_month';
  public static ACCOUNTING_QUARTER = 'acc_quarter';
  public static ACCOUNTING_YEAR = 'acc_year';
  public static ACCOUNTING_NUMBER_OF_ROWS = 'acc_num_rows';
  public static ACCOUNTING_INCLUDE_ALL_DONE_CLIENTS = 'acc_inc_done_clients';

  public static INCOME_STATEMENT_CLIENT_RESPONSIBLE_ID = 'inc_resp_user';
  public static INCOME_STATEMENT_USER_ID = 'inc_user';
  public static INCOME_STATEMENT_YEAR = 'inc_resp_year';
  public static INCOME_STATEMENT_CLIENT_RESPONSIBLE_LIST = 'inc_curr_cli_resp_list';
  public static INCOME_STATEMENT_NUMBER_OF_ROWS = 'inc_curr_num_rows';
  public static INCOME_STATEMENT_INCLUDE_ALL_DONE_CLIENTS = 'inc_inc_done_clients';

  public static CLOSING_CLIENT_RESPONSIBLE_ID = 'clo_resp_user';
  public static CLOSING_USER_ID = 'clo_user';
  public static CLOSING_YEAR = 'clo_resp_year';
  public static CLOSING_MONTHS = 'clo_months';
  public static CLOSING_CLIENT_RESPONSIBLE_LIST = 'clo_curr_cli_resp_list';
  public static CLOSING_NUMBER_OF_ROWS = 'clo_curr_num_rows';
  public static CLOSING_INCLUDE_ALL_DONE_CLIENTS = 'clo_inc_done_clients';
  public static CLOSING_TYPES = 'clo_types';

  public static SALARIES_CLIENT_RESPONSIBLE_ID = 'sal_resp_user';
  public static SALARIES_USER_ID = 'sal_user';
  public static SALARIES_MONTH = 'sal_month';
  public static SALARIES_YEAR = 'sal_year';
  public static SALARIES_NUMBER_OF_ROWS = 'sal_curr_num_rows';
  public static SALARIES_INCLUDE_ALL_DONE_CLIENTS = 'sal_inc_done_clients';

  public static INCOME_TAX_CLIENT_RESPONSIBLE_ID = 'inc_tax_resp_user';
  public static INCOME_TAX_USER_ID = 'inc_tax_user';
  public static INCOME_TAX_PERIOD = 'inc_tax_period'; // TODO: remove after next release
  public static INCOME_TAX_PERIODS = 'inc_tax_periods';
  public static INCOME_TAX_YEAR = 'inc_tax_year';
  public static INCOME_TAX_NUMBER_OF_ROWS = 'inc_tax_curr_num_rows';
  public static INCOME_TAX_INCLUDE_ALL_DONE_CLIENTS = 'inc_tax_inc_done_clients';

  public static TAX_CLIENT_RESPONSIBLE_ID = 'tax_resp_user';
  public static TAX_USER_ID = 'tax_user';
  public static TAX_MONTH = 'tax_month';
  public static TAX_YEAR = 'tax_year';
  public static TAX_NUMBER_OF_ROWS = 'tax_curr_num_rows';
  public static TAX_INCLUDE_ALL_DONE_CLIENTS = 'tax_inc_done_clients';

  public static CLIENT_SHOW_ARCHIVED = 'cli_arc';
  public static CLIENT_NUMBER_OF_ROWS = 'tax_curr_num_rows';
  public static CLIENT_RESPONSIBLE_ID = 'cli_resp_user';

  public static COLLABORATION_PACKAGES_NUMBER_OF_ROWS = 'colpac_curr_num_rows';
  public static COLLABORATION_PACKAGES_STATUS_FILTER = 'colpac_status_filter';
  public static COLLABORATION_PACKAGES_RESPONSIBLE_ID = 'colpac_resp_user';
  public static COLLABORATION_PACKAGES_ARCHIVED_FILTER = 'colpac_archived_filter';

  public static TOD_NUMBER_OF_ROWS = 'tod_curr_num_rows';
  public static TOD_USER_ID = 'tod_user';
  public static TOD_START_DATE = 'tod_start_date';
  public static TOD_END_DATE = 'tod_end_date';
  public static TOD_SHOW_FINISHED = 'tod_show_fin';
  public static TOD_INCLUDE_UNCOMPLETED = 'tod_show_uncomp';

  // public static START_WITH_LOGOUT = 'start_logout';

  public static SHOW_ADDED_TASKS_DIALOG = 'show_added_tasks_dialog';
  public static SHOW_TASKS_WITH_END_DATE = 'show_tasks_with_end_date';

  public static LOGGED_IN_FIRM_NAME = 'logged_in_firm_name';
  public static LOGGED_IN_USER_ID = 'logged_in_user_id';
  public static LOGGED_IN_USER_NAME = 'logged_in_user_name';
  public static LOGGED_IN_SERVICE = 'logged_in_service';

  public static NOTIFICATION_RESPONSIBLE_USER = 'notification_responsible_user';
  public static NOTIFICATION_WARNING_DIALOG_RESULT = 'notification_warning_dialog_result';
  public static NOTIFICATION_START_DATE = 'notification_from_date';
  public static NOTIFICATION_END_DATE = 'notification_to_date';
  public static NOTIFICATION_NUMBER_OF_ROWS = 'notification_number_of_rows';
  public static NOTIFICATION_ONLY_SHOW_ROWS_WITH_CONTENT = 'notification_only_show_rows_with_content';

  public static FEATURE_TOGGLES = 'feature_toggles';

  public static TEAM_SELECTOR_PREFIX = 'team_selector_';

  private persistentStorage: string[] = [
    UserSettingsStorage.ACTIVITIES_USER_ID,
    UserSettingsStorage.ACTIVITIES_SEARCH_UNCOMPLETED,
    UserSettingsStorage.ACTIVITIES_SHOW_FINISHED,
    UserSettingsStorage.ACTIVITIES_NUMBER_OF_ROWS,
    UserSettingsStorage.ACTIVITIES_CLIENT_RESPONSIBLE_ID,
    UserSettingsStorage.ACCOUNTING_CLIENT_RESPONSIBLE_ID,
    UserSettingsStorage.ACCOUNTING_USER_ID,
    UserSettingsStorage.ACCOUNTING_CRITERIA,
    UserSettingsStorage.ACCOUNTING_MONTH,
    UserSettingsStorage.ACCOUNTING_YEAR,
    UserSettingsStorage.ACCOUNTING_FILTER_CRITERIA,
    UserSettingsStorage.ACCOUNTING_NUMBER_OF_ROWS,
    UserSettingsStorage.ACCOUNTING_INCLUDE_ALL_DONE_CLIENTS,
    UserSettingsStorage.SALARIES_CLIENT_RESPONSIBLE_ID,
    UserSettingsStorage.SALARIES_MONTH,
    UserSettingsStorage.SALARIES_YEAR,
    UserSettingsStorage.SALARIES_USER_ID,
    UserSettingsStorage.SALARIES_NUMBER_OF_ROWS,
    UserSettingsStorage.SALARIES_INCLUDE_ALL_DONE_CLIENTS,
    UserSettingsStorage.INCOME_TAX_CLIENT_RESPONSIBLE_ID,
    UserSettingsStorage.INCOME_TAX_USER_ID,
    UserSettingsStorage.INCOME_TAX_PERIOD, // TODO: remove after next release
    UserSettingsStorage.INCOME_TAX_PERIODS,
    UserSettingsStorage.INCOME_TAX_YEAR,
    UserSettingsStorage.INCOME_TAX_NUMBER_OF_ROWS,
    UserSettingsStorage.INCOME_TAX_INCLUDE_ALL_DONE_CLIENTS,
    UserSettingsStorage.TAX_CLIENT_RESPONSIBLE_ID,
    UserSettingsStorage.TAX_USER_ID,
    UserSettingsStorage.TAX_MONTH,
    UserSettingsStorage.TAX_YEAR,
    UserSettingsStorage.TAX_NUMBER_OF_ROWS,
    UserSettingsStorage.TAX_INCLUDE_ALL_DONE_CLIENTS,
    UserSettingsStorage.INCOME_STATEMENT_CLIENT_RESPONSIBLE_ID,
    UserSettingsStorage.INCOME_STATEMENT_USER_ID,
    UserSettingsStorage.INCOME_STATEMENT_INCLUDE_ALL_DONE_CLIENTS,
    UserSettingsStorage.INCOME_STATEMENT_NUMBER_OF_ROWS,
    UserSettingsStorage.INCOME_STATEMENT_YEAR,
    UserSettingsStorage.LOGGED_IN_FIRM_NAME,
    UserSettingsStorage.LOGGED_IN_USER_ID,
    UserSettingsStorage.LOGGED_IN_USER_NAME,
    UserSettingsStorage.CLOSING_YEAR,
    UserSettingsStorage.CLOSING_CLIENT_RESPONSIBLE_ID,
    UserSettingsStorage.CLOSING_USER_ID,
    UserSettingsStorage.CLOSING_INCLUDE_ALL_DONE_CLIENTS,
    UserSettingsStorage.CLOSING_NUMBER_OF_ROWS,
    UserSettingsStorage.TOD_NUMBER_OF_ROWS,
    UserSettingsStorage.TOD_USER_ID,
    UserSettingsStorage.TOD_INCLUDE_UNCOMPLETED,
    UserSettingsStorage.TOD_SHOW_FINISHED,
    UserSettingsStorage.NOTIFICATION_RESPONSIBLE_USER,
    UserSettingsStorage.NOTIFICATION_WARNING_DIALOG_RESULT,
    UserSettingsStorage.NOTIFICATION_NUMBER_OF_ROWS,
    UserSettingsStorage.CLIENT_SHOW_ARCHIVED,
    UserSettingsStorage.CLIENT_RESPONSIBLE_ID,
    UserSettingsStorage.SHOW_ADDED_TASKS_DIALOG,
    UserSettingsStorage.SHOW_TASKS_WITH_END_DATE,
    UserSettingsStorage.CLOSING_MONTHS,
    UserSettingsStorage.FEATURE_TOGGLES,
    UserSettingsStorage.NOTIFICATION_ONLY_SHOW_ROWS_WITH_CONTENT,
    UserSettingsStorage.CLOSING_TYPES,
    UserSettingsStorage.COLLABORATION_PACKAGES_NUMBER_OF_ROWS,
    UserSettingsStorage.COLLABORATION_PACKAGES_STATUS_FILTER,
    UserSettingsStorage.COLLABORATION_PACKAGES_RESPONSIBLE_ID,
    UserSettingsStorage.COLLABORATION_PACKAGES_ARCHIVED_FILTER,
  ];

  public saveSetting(key: string, value: string) {
    if (this.isPersistent(key)) {
      localStorage.setItem(key, value);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

  public loadSetting(key: string, defaultValue: string | undefined = undefined) {
    if (this.isPersistent(key)) {
      return localStorage.getItem(key) || defaultValue;
    }
    return sessionStorage.getItem(key) || defaultValue;
  }

  public loadSettingAsNumber(key: string, defaultValue: number) {
    const settingValue = this.loadSetting(key);
    return settingValue ? parseInt(settingValue, 10) : defaultValue;
  }

  public loadSettingAsBoolean(key: string, defaultValue: boolean) {
    const settingValue = this.loadSetting(key, defaultValue + '');
    return settingValue === 'true';
  }

  public removeSetting(key: string) {
    if (this.isPersistent(key)) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }

  public clearSettings(clearSessionStorage = false) {
    localStorage.clear();
    if (clearSessionStorage) {
      sessionStorage.clear();
    }
  }

  public clearSessionStorage() {
    sessionStorage.clear();
  }

  public keyExists(key: string): boolean {
    if (this.isPersistent(key)) {
      return localStorage.getItem(key) !== null;
    }
    return sessionStorage.getItem(key) !== null;
  }

  public featureToggled(feature: string): boolean {
    const togglesAsString = this.loadSetting(UserSettingsStorage.FEATURE_TOGGLES, '[]');
    const toggles = JSON.parse(togglesAsString);

    return Array.isArray(toggles) && (toggles.includes('all') || toggles.includes(feature));
  }

  private isPersistent(key: string) {
    if (key.includes(UserSettingsStorage.TEAM_SELECTOR_PREFIX)) {
      return true;
    }

    return this.persistentStorage.includes(key);
  }
}
