export const errors: Record<string, string> = {
  MISSING_AUTHORIZATION: 'Rättigheter saknas för att uppdatera klienten',
  MISSING_CLIENT_NAME: 'Företagsnamn saknas',
  CLIENT_NAME_TO_LONG:
    'Företagsnamnet innehåller fler än 40 tecken, molndatabas kommer inte kunna {0}. Förkorta namnet och försök igen.',
  MISSING_CORPORATE_IDENTITY: 'Organisationsnummer saknas',
  CLIENT_CLOUD_API_KEY_EXISTS: 'Klienten har redan en molndatabasnyckel',
  MISSING_CLOUD_API_KEY: 'Klienten saknar molndatabasnyckel',
  COUNTRY_TO_LONG:
    'Land innehåller fler än 20 tecken, molndatabas kommer inte kunna {0}. Förkorta land och försök igen.',
  ADDRESS_TO_LONG:
    'Adress innehåller fler än 40 tecken, molndatabas kommer inte kunna {0}. Förkorta adressen och försök igen.',
  PRIVELEGIE_INCORRECT: 'Endast huvudanvändaren kan utföra den här åtgärden.',
};
