import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DialogData,
  DialogForm,
  DialogResult,
  FormDialogBaseComponent,
} from '../../form-dialog-base/form-dialog-base.component';
import { ClientType } from '@app/core/state/types';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { FocusTrapModule } from 'primeng/focustrap';
import { CLIENT_TYPE_SOLE_PROPRIETORSHIP } from '@app/shared/misc/constants';
import { AutoFocusModule } from 'primeng/autofocus';

interface Data extends DialogData {
  client: Partial<ClientType>;
}

interface Result extends DialogResult {
  client: Partial<ClientType>;
}

interface Form extends DialogForm<unknown> {
  nameChecked: FormControl<boolean>;
  addressChecked: FormControl<boolean>;
  phoneChecked: FormControl<boolean>;
  emailChecked: FormControl<boolean>;
  webpageChecked: FormControl<boolean>;
  residenceChecked: FormControl<boolean>;
  businessAreaChecked: FormControl<boolean>;
  registrationDateChecked: FormControl<boolean>;
  accountantChecked: FormControl<boolean>;
  descriptionChecked: FormControl<boolean>;
  signatoryChecked: FormControl<boolean>;
}

@Component({
  selector: 'app-credit-information-update-dialog',
  template: `
    <form [formGroup]="form" (ngSubmit)="onSubmit()" pFocusTrap>
      <p class="font-semibold">
        Hämtning av grunduppgifter för: <em>{{ config.data.client.corporateIdentity }}</em>
      </p>
      <p>Valda uppgifter kommer att hämtas och skriva över befintliga uppgifter på klienten</p>
      <p class="font-semibold mt-5">Registrerade uppgifter</p>
      <div class="mb-2 flex items-start">
        <p-checkbox
          name="nameChecked"
          [binary]="true"
          formControlName="nameChecked"
          pAutoFocus
          [autofocus]="true"></p-checkbox>
        <label class="ml-2" for="nameChecked"><strong>Namn: </strong>{{ config.data.client.name }}</label>
      </div>
      <div class="mb-2 flex items-start">
        <p-checkbox name="addressChecked" [binary]="true" formControlName="addressChecked"></p-checkbox>
        <label class="ml-2" for="addressChecked"
          ><strong>Adress: </strong>{{ config.data.client.address }}, {{ config.data.client.zipCode }}
          {{ config.data.client.city }}</label
        >
      </div>
      <div class="mb-2 flex items-start">
        <p-checkbox name="phoneChecked" [binary]="true" formControlName="phoneChecked"></p-checkbox>
        <label class="ml-2" for="phoneChecked"><strong>Telefon: </strong>{{ config.data.client.phone }}</label>
      </div>
      <div class="mb-2 flex items-start">
        <p-checkbox name="emailChecked" [binary]="true" formControlName="emailChecked"></p-checkbox>
        <label class="ml-2" for="emailChecked"><strong>Email: </strong>{{ config.data.client.email }}</label>
      </div>
      <div class="mb-2 flex items-start">
        <p-checkbox name="webpageChecked" [binary]="true" formControlName="webpageChecked"></p-checkbox>
        <label class="ml-2" for="webpageChecked"><strong>Hemsida: </strong>{{ config.data.client.webpage }}</label>
      </div>
      <div class="mb-2 flex items-start">
        <p-checkbox name="residenceChecked" [binary]="true" formControlName="residenceChecked"></p-checkbox>
        <label class="ml-2" for="residenceChecked"><strong>Säte: </strong>{{ config.data.client.residence }}</label>
      </div>
      <div class="mb-2 flex items-start">
        <p-checkbox name="businessAreaChecked" [binary]="true" formControlName="businessAreaChecked"></p-checkbox>
        <label class="ml-2" for="businessAreaChecked"
          ><strong>Bransch: </strong>{{ config.data.client.businessArea }}</label
        >
      </div>
      <div class="mb-2 flex items-start">
        <p-checkbox
          name="registrationDateChecked"
          [binary]="true"
          formControlName="registrationDateChecked"></p-checkbox>
        <label class="ml-2" for="registrationDateChecked"
          ><strong>Registreringsdatum: </strong>{{ config.data.client.registrationDate }}</label
        >
      </div>
      <div class="mb-2 flex items-start">
        <p-checkbox name="accountantChecked" [binary]="true" formControlName="accountantChecked"></p-checkbox>
        <label class="ml-2" for="accountantChecked"
          ><strong>Revisor: </strong>{{ config.data.client.accountant }}</label
        >
      </div>
      <div class="mb-2 flex items-start">
        <p-checkbox name="descriptionChecked" [binary]="true" formControlName="descriptionChecked"></p-checkbox>
        <label class="ml-2" for="descriptionChecked"
          ><strong>Beskrivning av verksamheten: </strong>{{ config.data.client.description }}</label
        >
      </div>
      <div class="mb-2 flex items-start" *ngIf="isSoleProprietorship">
        <p-checkbox name="signatoryChecked" [binary]="true" formControlName="signatoryChecked"></p-checkbox>
        <label class="ml-2" for="signatoryChecked"
          ><strong>Firmatecknare: </strong>{{ config.data.client.signatory }}</label
        >
      </div>
      <div class="flex flex-row w-full justify-end mb-4">
        <button type="submit" class="btn btn-success mr-2">Uppdatera</button>
        <button type="button" class="btn btn-warning" (click)="close({ client: null })">Avbryt</button>
      </div>
    </form>
  `,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CheckboxModule, FocusTrapModule, AutoFocusModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditInformationUpdateDialog extends FormDialogBaseComponent<Data, Result, Form> {
  get isSoleProprietorship(): boolean {
    return this.config.data.client.type.name === CLIENT_TYPE_SOLE_PROPRIETORSHIP;
  }

  onSubmit() {
    const name = this.getCheckedValue('nameChecked', 'name');
    const address = this.getCheckedValue('addressChecked', 'address');
    const zipCode = this.getCheckedValue('addressChecked', 'zipCode');
    const city = this.getCheckedValue('addressChecked', 'city');
    const phone = this.getCheckedValue('phoneChecked', 'phone');
    const email = this.getCheckedValue('emailChecked', 'email');
    const webpage = this.getCheckedValue('webpageChecked', 'webpage');
    const residence = this.getCheckedValue('residenceChecked', 'residence');
    const businessArea = this.getCheckedValue('businessAreaChecked', 'businessArea');
    const registrationDate = this.getCheckedValue('registrationDateChecked', 'registrationDate');
    const accountant = this.getCheckedValue('accountantChecked', 'accountant');
    const description = this.getCheckedValue('descriptionChecked', 'description');
    const signatory = this.getCheckedValue('signatoryChecked', 'signatory');

    const client: Partial<ClientType> = {
      name,
      address,
      zipCode,
      city,
      phone,
      email,
      webpage,
      residence,
      businessArea,
      registrationDate,
      accountant,
      description,
      signatory,
    };

    this.close({ client: this.cleanUpUnusedProps(client) });
  }

  protected override onInitialized = () => {
    const {
      name,
      address,
      phone,
      email,
      webpage,
      residence,
      businessArea,
      registrationDate,
      accountant,
      description,
      signatory,
    } = this.config.data.client;
    this.form = this.builder.group({
      nameChecked: new FormControl(Boolean(name)),
      addressChecked: new FormControl(Boolean(address)),
      phoneChecked: new FormControl(Boolean(phone)),
      emailChecked: new FormControl(Boolean(email)),
      webpageChecked: new FormControl(Boolean(webpage)),
      residenceChecked: new FormControl(Boolean(residence)),
      businessAreaChecked: new FormControl(Boolean(businessArea)),
      registrationDateChecked: new FormControl(Boolean(registrationDate)),
      accountantChecked: new FormControl(Boolean(accountant)),
      descriptionChecked: new FormControl(Boolean(description)),
      signatoryChecked: new FormControl(this.isSoleProprietorship ? Boolean(signatory) : false),
    });
  };

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<Data>, 'data'> {
    return {
      header: 'Uppdatera klientinformation',
      width: '500px',
      draggable: true,
      closable: true,
      modal: true,
      resizable: false,
    };
  }

  private getCheckedValue = (checkedKey: string, clientKey: string) =>
    this.form.value[checkedKey] ? this.config.data.client[clientKey] : null;

  private cleanUpUnusedProps(client: Partial<ClientType>): Partial<ClientType> {
    return Object.entries(client).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;
      return acc;
    }, {} as Partial<ClientType>);
  }
}
