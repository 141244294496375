import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppState } from '@app/core/state/appState';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { FirmActions } from '@app/core/state/firm/firm.actions';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { ActivateSustainabilityReporting, FirmType } from '@app/core/state/types';
import { AdminPrivilegeDirective } from '@app/shared/components/admin-privilege/admin-privilege.directive';
import { FeatureToggleDirective } from '@app/shared/components/feature-toggle/feature-toggle.directive';
import { NotAdminPrivilegeDirective } from '@app/shared/components/not-admin-privilege/not-admin-privilege.directive';
import { PleaseWaitComponent } from '@app/shared/components/please-wait/please-wait.component';
import { Actions, concatLatestFrom, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

interface SustainabilityReportingForm {
  name: FormControl<string>;
  email: FormControl<string>;
  corporateIdentity: FormControl<string>;
}

@Component({
  selector: 'app-sustainability-reporting',
  template: `
    <app-please-wait [hidden]="!submitting"></app-please-wait>
    <section class="flex flex-col">
      <div
        class="flex-grow flex flex-col overflow-hidden border-0 m-0 p-0"
        [ngClass]="!isActivated ? 'w-full justify-center items-center' : ''">
        <div *ngIf="!isActivated" class="w-1/2 grid grid-cols-2 gap-6">
          <div class="logo flex flex-col items-center rounded-lg">
            <img src="assets/wellfish.png" />
          </div>
          <div *ngIf="!submitted" class="card max-w-sm flex flex-col">
            <div *appAdminPrivilege>
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <h2 class="text-lg font-semibold text-center">Registrera byrån på Wellfish</h2>
                <div class="flex flex-col pb-2 pt-4">
                  <label for="name">Byrånamn</label>
                  <input
                    id="name"
                    class="form-control"
                    type="text"
                    formControlName="name"
                    pInputText
                    placeholder="Byrånamn i wellfish" />
                </div>
                <div class="flex flex-col pb-2 pt-4">
                  <label for="email">E-postadress</label>
                  <input
                    id="email"
                    class="form-control"
                    type="email"
                    formControlName="email"
                    pInputText
                    placeholder="E-postadress" />
                </div>
                <div class="pb-2 pt-4">
                  <button class="btn btn-wellfish" type="submit" [disabled]="form.invalid || submitting">
                    Registrera Wellfish-konto
                  </button>
                </div>
              </form>
            </div>
            <div *appNotAdminPrivilege>
              <h1 class="text-lg font-semibold">Aktivering saknas!</h1>
              <p class="text-base font-normal">
                Hållbarhetsredovisningen är ännu inte aktiverad. Kontakta byråägaren för att aktivera den.
              </p>
            </div>
          </div>
          <div *ngIf="submitted" class="card max-w-sm flex flex-col">
            <h1 class="text-lg font-semibold">Ert konto har skapats</h1>
            <p class="text-base font-normal">
              Stort tack för din registrering på integrationen mellan Byråstöd och Wellfish! Inom kort kommer du att få
              ett mejl från Wellfish med dina inloggningsuppgifter till den angivna mejladressen.
            </p>
            <button class="btn btn-wellfish" (click)="onContinueToWellfish()">Fortsätt mot Wellfish</button>
          </div>
          <div class="card flex flex-col">
            <h1 class="text-lg font-semibold">Varmt välkommen till Wellfish</h1>
            <h2 class="text-lg font-semibold mt-0">- Ditt stöd för hållbarhet!</h2>
            <p class="text-base font-normal">
              Vi erbjuder er det stöd ni behöver för att agera på dagens mest betydelsefulla fråga. Genom anpassad
              vägledning, policys och automatisering, hjälper vi er att effektivt integrera hållbarhet i er verksamhet.
              Vi är stolta över att drivas av en passion: att göra företag konkurrenskraftigt hållbara. Tiden att agera
              är nu. Vi står redo att hjälpa er genom den gröna omställningen.
            </p>
            <p class="text-base font-normal">
              Wellfish erbjuder dubbel väsentlighetsanalys och automatiserad rapportering. Vi hjälper er kartlägga data,
              skapa policys, analysera och jämföra med branschstandard. Kommunicera er hållbarhetsposition och följ upp
              era mål i realtid.
            </p>
          </div>
        </div>
        <iframe
          *ngIf="isActivated"
          [src]="iframeUrl"
          width="100%"
          height="100%"
          class="flex-grow border-0 m-0 p-0"
          sandbox="allow-same-origin allow-scripts allow-forms"></iframe>
      </div>
    </section>
  `,
  styles: [
    `
      :host section {
        background: var(--colors-bg-color, #eef6f9);
        min-height: 90vh;
      }
      .logo {
        background: #1c2633;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
      }
      .card {
        @apply rounded-lg p-7;
        background: var(--black-white-white, #fff);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
      }
      .btn-wellfish {
        background-color: var(--bl-blue-light-600);
        border-color: var(--bl-blue-light-500);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        @apply text-lg font-medium text-white rounded-full w-full;
      }
      .btn-wellfish:hover {
        background-color: #286090;
        border-color: #204d74;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FeatureToggleDirective,
    PleaseWaitComponent,
    AdminPrivilegeDirective,
    NotAdminPrivilegeDirective,
  ],
})
export class SustainabilityReportingComponent implements OnInit, OnDestroy {
  form: FormGroup<SustainabilityReportingForm>;
  submitting: boolean;
  submitted: boolean;
  firmSub: BehaviorSubject<FirmType>;
  iframeUrl: SafeResourceUrl;
  private onDestroySub: Subject<void>;

  constructor(
    private store: Store<AppState>,
    private builder: FormBuilder,
    private actions$: Actions,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {
    this.onDestroySub = new Subject();
    this.submitting = false;
    this.submitted = false;
    this.firmSub = new BehaviorSubject(null);
    this.form = this.builder.group({
      corporateIdentity: new FormControl(''),
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.sustainabiltyUrl);
  }

  ngOnInit(): void {
    this.store
      .select(FirmSelectors.selectFirm)
      .pipe(
        concatLatestFrom(() => this.store.select(AuthSelectors.selectAuthUser)),
        filter(([firm, user]) => Boolean(firm) && Boolean(user)),
        takeUntil(this.onDestroySub),
      )
      .subscribe(([firm, user]) => {
        this.form.controls.name.patchValue(firm.name?.trim());
        this.form.controls.email.patchValue(user.email?.trim());
        this.firmSub.next(firm);
      });

    this.actions$
      .pipe(
        ofType(FirmActions.activateSustainabilityReportingSucceeded, FirmActions.activateSustainabilityReportingFailed),
      )
      .pipe(takeUntil(this.onDestroySub))
      .subscribe((action) => {
        this.form.enable();
        this.submitted = action.type === FirmActions.activateSustainabilityReportingSucceeded().type;
        this.submitting = false;
        this.ref.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.onDestroySub.next();
    this.onDestroySub.complete();
  }

  get isActivated(): boolean {
    return Boolean(this.firmSub?.value?.sustainabilityReporting);
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const data: ActivateSustainabilityReporting = {
      email: this.form.value.email,
      name: this.form.value.name,
      corporateIdentity: this.firmSub?.value?.corporateIdentity,
    };

    this.submitting = true;
    this.form.disable();
    this.store.dispatch(FirmActions.activateSustainabilityReporting({ firmId: this.firmSub?.value?.id, data }));
  }

  onContinueToWellfish() {
    window.location.assign('/sustainability');
  }
}
