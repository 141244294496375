import { Component, Input } from '@angular/core';
import { AllInclusiveService } from '@app/core/services/all-inclusive.service';
import { ApplicationLinksService } from '@app/core/services/application-links.service';
import { FileVaultActions } from '@app/core/state/filevault/filevault.actions';
import { FileVaultSelectors } from '@app/core/state/filevault/filevault.selectors';
import { AppState } from '@app/core/state/appState';
import { ApplicationLinksGroup } from '@app/shared/misc/application-link-builders/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-application-files',
  templateUrl: './application-files.component.html',
})
export class ApplicationFilesComponent {
  @Input() mode: 'client' | 'system' = 'client';
  public loading$: Observable<boolean>;
  public downloadStatus$: Observable<Record<string, boolean>>;
  public groups$: Observable<ApplicationLinksGroup[]>;

  public get hasAllIncService() {
    return this.allIncService.isLoggedInWithAnyAllInclusiveService;
  }

  public get border() {
    return this.mode === 'client';
  }

  public get padding() {
    return this.mode === 'client';
  }

  constructor(
    private store: Store<AppState>,
    private allIncService: AllInclusiveService,
    private applicationLinksService: ApplicationLinksService,
  ) {
    const groups = this.applicationLinksService.getApplicationLinksGroupsWithTasks();
    this.loading$ = this.store.select(FileVaultSelectors.isLoading);
    this.groups$ = this.store.select(FileVaultSelectors.getApplicationLinksWithDownloads(groups));
    this.downloadStatus$ = this.store.select(FileVaultSelectors.downloadStatus);
  }

  public download(file: string) {
    this.store.dispatch(FileVaultActions.downloadFile({ file }));
  }
}
