<p-dialog
  [header]="task?.client?.name"
  [visible]="visible"
  (visibleChange)="dismissDialog()"
  showEffect="fade"
  [style]="{ width: '1100px' }"
  [draggable]="false"
>
  <p class="text-lg">{{ task?.assignmentName }}</p>

  <p class="mb-1 font-size-12">Period för vilka aktiviteter ska visas</p>
  <div class="flex mb-2" *ngIf="filter$ | async as filter">
    <app-calendar
      [selectedDate]="filter.fromDate"
      (selectedDateChange)="onFilterFromDateChanged($event)"
      styleClass="mr-3"
      [style]="{ 'max-width': '150px' }"
      appendTo="body"
    ></app-calendar>
    <app-calendar
      [selectedDate]="filter.toDate"
      (selectedDateChange)="onFilterToDateChanged($event)"
      styleClass="mr-3"
      [style]="{ 'max-width': '150px' }"
      appendTo="body"
    ></app-calendar>
    <p-checkbox
      [binary]="true"
      [ngModel]="filter.includeFinished"
      (ngModelChange)="onFilterIncludeFinishedChanged($event)"
      label="Visa även klara"
    ></p-checkbox>
  </div>

  <app-table
    [data]="activityCompositeList$ | async"
    [columns]="columns"
    [columnSelector]="false"
    [paginator]="false"
    scrollHeight="300px"
    [useFixedStyle]="true"
  >
    <ng-template tableTemplate="bodyCell" tableBodyCellField="statusIcon" let-activityComposite="rowData">
      <app-status-icon
        [status]="activityComposite.activity.alertLevel.name"
        [color]="activityComposite.activity.stateColor"
        [title]="activityComposite.activity.alertLevel.description"
        emptyBlack
      ></app-status-icon>
    </ng-template>
    <ng-template
      tableTemplate="bodyCell"
      tableBodyCellField="task.type-activity.span.spanLabel"
      let-activityComposite="rowData"
    >
      <a (click)="showTask(activityComposite)" class="text-black cursor-pointer">
        {{ activityComposite.task.type }} {{ activityComposite.activity.span.spanLabel }}
      </a>
    </ng-template>
    <ng-template tableTemplate="bodyCell" tableBodyCellField="activity.comment" let-activityComposite="rowData">
      <div class="text-center">
        <app-activity-comment-icon
          [title]="activityComposite.activity.comment"
          (click)="showTask(activityComposite)"
          componentStyle="cursor: pointer; color: orange; padding-right: 5px;"
        ></app-activity-comment-icon>
      </div>
    </ng-template>
    <ng-template tableTemplate="bodyCell" tableBodyCellField="deleteAction" let-activityComposite="rowData">
      <div class="flex justify-center" *ngIf="activityComposite.activity.isPersisted()">
        <span (click)="showDeleteActivityWarning(activityComposite)" class="far fa-trash-alt text-black cursor-pointer">
        </span>
      </div>
    </ng-template>
    <ng-template tableTemplate="bodyCell" tableBodyCellField="programAction" let-activityComposite="rowData">
      <div class="text-center">
        <ng-container *ngIf="activityComposite.task.assignmentAgent === 'BL'">
          <app-bla-icon-button [activityComposite]="activityComposite"></app-bla-icon-button>
        </ng-container>
        <ng-container *ngIf="activityComposite.task.assignmentAgent !== 'BL'">
          <img
            [src]="activityComposite.task.assignmentAgent | getIcon"
            alt="Starta programmet"
            (click)="callAgent(activityComposite)"
            class="cursor-pointer inline"
          />
        </ng-container>
      </div>
    </ng-template>

    <ng-template tableTemplate="emptyMessage">
      <div class="my-3" *ngIf="loading$ | async as loading">
        {{ loading.value ? 'Listan uppdateras...' : 'Inga aktiviteter finns att visa' }}
      </div>
    </ng-template>
  </app-table>

  <a href="#" id="agentCaller"></a>

  <p-footer>
    <button type="button" class="btn btn-warning" (click)="dismissDialog()">Stäng</button>
  </p-footer>
</p-dialog>

<p-dialog
  header="Ta bort aktivitet?"
  [visible]="displayDeleteActivityWarningDialog"
  (visibleChange)="dismissDeleteActivityDialog()"
  showEffect="fade"
  [draggable]="false"
>
  <p>Är du säker på att du vill ta bort aktiviteten?</p>

  <p-footer *ngIf="removingActivity$ | async as removingActivity">
    <p-progressSpinner
      *ngIf="removingActivity.value"
      [style]="{ width: '23px', height: '23px' }"
      strokeWidth="6"
    ></p-progressSpinner>

    <button
      type="button"
      class="btn btn-success"
      (click)="confirmDeleteActivityDialog()"
      [disabled]="removingActivity.value"
    >
      Ja
    </button>
    <button
      type="button"
      class="btn btn-warning"
      (click)="dismissDeleteActivityDialog()"
      [disabled]="removingActivity.value"
    >
      Nej
    </button>
  </p-footer>
</p-dialog>
