<app-table
  #table
  storageKey="salaryListTable"
  [columns]="columns"
  [data]="list$ | async"
  [rowsPerPage]="defaultNumberOfRows"
  sortField="client"
  exportFilename="loneadministration"
  (pageChange)="paginatorTriggered($event)"
>
  <app-table-filter>
    <app-team-multi-selector localStorageKey="salary" (OnTeamsInit)="refreshList()"></app-team-multi-selector>
    <app-table-filter-item label="Klientansvarig">
      <app-user-selector [(userId)]="selectedClientResponsibleUserId" styleClass="min-w-48"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item label="Utförande handläggare">
      <app-user-selector [(userId)]="selectedTaskUserId" styleClass="min-w-48"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item label="Utbetalnings och rapporteringsmånad">
      <div class="flex">
        <app-month-selector [(month)]="selectedMonth" styleClass="min-w-32"></app-month-selector>
        <app-year-selector [(year)]="selectedYear" styleClass="min-w-32 ml-1"></app-year-selector>
      </div>
    </app-table-filter-item>
    <app-table-filter-item>
      <app-refresh-button [loading]="loading$ | async" (onClick)="refreshList()"></app-refresh-button>
    </app-table-filter-item>
    <app-table-filter-item>
      <p-checkbox
        [binary]="true"
        [(ngModel)]="includeAllDoneClients"
        (ngModelChange)="filterList()"
        [label]="includeFinishedLabel$ | async"
      ></p-checkbox>
    </app-table-filter-item>
  </app-table-filter>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="client" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">{{ client.client }}</a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="corporateIdentity" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">
      {{ client.corporateIdentity }}
    </a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="customerNumber" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">
      {{ client.customerNumber }}
    </a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="paymentMaterial.when" let-data="rowData">
    <div
      class="cursor-pointer text-center truncate"
      (click)="onShowActivity(data.lonb)"
      [style.color]="data.materialStatusColor"
    >
      <app-status-icon
        [status]="data.paymentMaterial.alertLevel.name"
        [color]="data.materialStatusColor"
        [title]="data.paymentMaterial.alertLevel.description"
      ></app-status-icon>
      {{ data.paymentMaterial.when }}
    </div>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="lonb.activity.alertLevel.description"
    let-activity="rowData.lonb"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="lonu.activity.alertLevel.description"
    let-activity="rowData.lonu"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="lone.activity.alertLevel.description"
    let-activity="rowData.lone"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="form.activity.alertLevel.description"
    let-activity="rowData.form"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>  
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="stat.activity.alertLevel.description"
    let-activity="rowData.stat"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="ovri.activity.alertLevel.description"
    let-activity="rowData.ovri"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="program" let-data="rowData">
    <div class="text-center">
      <app-bla-icon-button [composite]="data"></app-bla-icon-button>
    </div>
  </ng-template>

  <ng-template tableTemplate="emptyMessage">{{ statusText$ | async }}</ng-template>

  <ng-template tableTemplate="summary" let-data>
    <div class="relative">
      Antal aktuella klienter: {{ data.length }}
      <div class="absolute export-button-wrapper">
        <p-button
          icon="pi pi-download"
          class="export-button"
          size="small"
          label="Exportera lista"
          (click)="table.exportCSV()"
          title="Ladda ner listan för import till Excel"
        ></p-button>
      </div>
    </div>
  </ng-template>

  <ng-template #defaultCompositeColumn let-activity>
    <div
      class="text-center cursor-pointer truncate"
      (click)="onShowActivity(activity)"
      [style.color]="activity.activity.stateColor"
    >
      <app-activity-comment-icon [title]="activity.activity.comment"></app-activity-comment-icon>
      <app-task-comment-icon [title]="activity.task.comment"></app-task-comment-icon>
      <app-status-icon
        [status]="activity.activity.alertLevel.name"
        [color]="activity.activity.stateColor"
        [title]="activity.activity.alertLevel.description"
      ></app-status-icon>
      {{ activity.activity.state.description }}
    </div>
  </ng-template>
</app-table>
