import { createReducer, on } from '@ngrx/store';
import { LoadingStatus } from '../types';
import { FileVaultActions } from './filevault.actions';

export interface FileVaultState {
  files: string[];
  status: LoadingStatus;
  downloadStatus: Record<string, boolean>;
  error: unknown;
}

export const initialFileVaultState: FileVaultState = {
  files: [],
  error: null,
  status: LoadingStatus.NotStarted,
  downloadStatus: {},
};

export const fileVaultReducer = createReducer(
  initialFileVaultState,
  on(FileVaultActions.loadAllFileNames, (state) => ({ ...state, error: null, status: LoadingStatus.Loading })),
  on(FileVaultActions.downloadFile, (state, { file }) => ({
    ...state,
    error: null,
    downloadStatus: { ...state.downloadStatus, [file]: true },
  })),
  on(FileVaultActions.loadAllFileNamesSucceeded, (state, { files }) => ({
    ...state,
    files,
    error: null,
    status: LoadingStatus.NotStarted,
  })),
  on(FileVaultActions.downloadFileSucceeded, (state, { file }) => ({
    ...state,
    downloadStatus: { ...state.downloadStatus, [file]: false },
  })),
  on(FileVaultActions.loadAllFileNamesFailed, (state, { error }) => ({
    ...state,
    files: [],
    error,
    status: LoadingStatus.Failed,
  })),
  on(FileVaultActions.downloadFileFailed, (state, { file }) => ({
    ...state,
    downloadStatus: { ...state.downloadStatus, [file]: false },
  })),
);
