import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TaskService } from '@app/core/services/task.service';
import { AssignmentSelectors } from '@app/core/state/assignments/assignments.selectors';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { ActivityStateType, AssignmentType, TaskType, UserType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { StateFacade } from '@app/firm-settings/bulk-handling/facades/state.facade';
import { BulkChangeComponentData } from '@app/firm-settings/bulk-handling/interfaces/bulk-change.component';
import { TableColumnWithFilter } from '@app/shared/components/table/interfaces/table-column';
import { CLIENT_RESPONSIBLE } from '@app/shared/misc/constants';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { filter, finalize, map, take, tap } from 'rxjs/operators';
import { BaseChangeComponent } from '../base-change.component';
import { requiredIfVisibleValidator, requiredStatesValidator } from './selectable-state-form-validators';

@Component({
  template: `<div [formGroup]="formGroup" class="flex">
    <div class="pr-10">
      <p>Vill du utgå från befintlig mall?</p>
      <p-dropdown
        [options]="[
          { value: true, text: 'Ja' },
          { value: false, text: 'Nej' }
        ]"
        formControlName="useExistingTemplate"
        optionValue="value"
        optionLabel="text"
        [autoOptionFocus]="false"
        [autoDisplayFirst]="false"></p-dropdown>
    </div>
    <div *ngIf="showTemplateSelectors$ | async" class="flex">
      <div class="pr-10">
        <p>Vilket uppdrag?</p>
        <app-assignment-selector
          formControlName="forAssignment"
          [assignments]="availableAssignments$ | async"></app-assignment-selector>
      </div>
      <div class="pr-10">
        <p>Vilken tjänst?</p>
        <task-selector formControlName="forTask" [tasks]="availableTasks$ | async"></task-selector>
      </div>
    </div>
    <div>
      <p>Vilka valbara statusar vill du ändra till?</p>
      <app-activity-states-multi-selector formControlName="toState"></app-activity-states-multi-selector>
    </div>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeSelectableStateComponent extends BaseChangeComponent {
  availableAssignments$: Observable<AssignmentType[]>;
  availableTasks$: Observable<TaskType[]>;
  availableUsers$: Observable<UserType[]>;
  showTemplateSelectors$: Observable<boolean>;

  private get useExisitingTemplateControl(): FormControl<boolean> {
    return this.formGroup.get('useExistingTemplate') as FormControl<boolean>;
  }
  private get forAssignmentControl(): UntypedFormControl {
    return this.formGroup.get('forAssignment') as UntypedFormControl;
  }
  private get forTaskControl(): UntypedFormControl {
    return this.formGroup.get('forTask') as UntypedFormControl;
  }

  constructor(stateFacade: StateFacade, private store: Store<AppState>, private taskService: TaskService) {
    super(stateFacade);

    this.showTemplateSelectors$ = this.useExisitingTemplateControl.valueChanges;
    this.availableAssignments$ = this.store.select(AssignmentSelectors.assignmentsWithChoose);
    this.availableTasks$ = this.getAvailableTasks();
    this.availableUsers$ = this.store.select(UserSelectors.activeUsersWithoutAll);

    this.subscriptions.add(this.clearHiddenFieldsOnHide());
    this.subscriptions.add(this.onTaskChangeUpdateStatusField());
  }

  protected createForm(): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        useExistingTemplate: new FormControl<boolean>(false),
        forAssignment: new UntypedFormControl(null),
        forTask: new UntypedFormControl({ value: null, disabled: true }),
        toState: new UntypedFormControl(null, requiredStatesValidator()),
      },
      { validators: [requiredIfVisibleValidator()] },
    );
  }

  protected load(): Observable<BulkChangeComponentData> {
    this.formGroup.disable({ emitEvent: false });

    const dataToChange = { toState: this.formGroup.value.toState.map((status: ActivityStateType) => status.name) };
    const allActiveClientIds$ = this.store.select(ClientSelectors.allActiveClients).pipe(
      map((clients) => clients.map((client) => client.id)),
      take(1),
    );

    return forkJoin([this.taskService.getAllTaskTypes(), allActiveClientIds$]).pipe(
      map(([tasks, clientIds]) => tasks.filter((task) => clientIds.includes(task.client.id))),
      map((tasks) => this.sortResultList(tasks)),
      map((tasks) => ({ tableData: tasks, dataToChange })),
      finalize(() => {
        this.formGroup.enable({ emitEvent: false });
      }),
    );
  }

  protected getTableColumns(): TableColumnWithFilter[] {
    return [
      { field: 'client.name', header: 'Klient', filter: { type: 'text' } },
      { field: 'client.corporateIdentity', header: 'Org.nr', filter: { type: 'text' } },
      {
        field: 'client.responsible',
        header: CLIENT_RESPONSIBLE,
        fieldValue: (responsible) => responsible?.name,
        filter: {
          type: 'multidropdown',
          data: this.availableUsers$,
          dataLabel: 'name',
          dataValue: 'id',
        },
      },
      { field: 'assignmentName', header: 'Uppdrag', filter: { type: 'text' } },
      { field: 'type', header: 'Tjänst', filter: { type: 'text' } },
      {
        field: 'users',
        header: 'Handläggare',
        filter: {
          type: 'multidropdownwithmultirules',
          data: this.availableUsers$,
          dataLabel: 'name',
          dataValue: 'id',
        },
        fieldValue: (users) => users?.map((user: UserType) => user.initials).join(', '),
      },
      { field: 'periodicity.description', header: 'Periodicitet', filter: { type: 'text' } },
    ];
  }

  protected getDataIdentityKey(): string {
    return 'id';
  }

  private clearHiddenFieldsOnHide(): Subscription {
    return this.useExisitingTemplateControl.valueChanges.pipe(filter((value) => value === false)).subscribe(() => {
      this.formGroup.patchValue({ forAssignment: null, forTask: null });
      this.forTaskControl.disable();
    });
  }

  private getAvailableTasks(): Observable<TaskType[]> {
    return this.forAssignmentControl.valueChanges.pipe(
      map((assignment: AssignmentType) => [...(assignment?.rootTemplates ?? [])]),
      map((tasks) => tasks?.sort((t1, t2) => (t1.type < t2.type ? -1 : 1))),
      tap((tasks) => this.toggleTaskControlStatus(tasks)),
    );
  }

  private toggleTaskControlStatus(tasks: TaskType[]) {
    if (tasks?.length > 0) {
      this.forTaskControl.enable();
      return;
    }
    this.forTaskControl.disable();
  }

  private onTaskChangeUpdateStatusField() {
    return this.forTaskControl.valueChanges.subscribe((task) => {
      if (!!task) {
        this.formGroup.patchValue({ toState: task.selectableStates });
      }
    });
  }

  private sortResultList(tasks: TaskType[]): TaskType[] {
    return tasks.sort((t1, t2) => {
      if (t1.client.name != t2.client.name) {
        return t1.client.name < t2.client.name ? -1 : 1;
      }
      if (t1.assignmentName != t2.assignmentName) {
        return t1.assignmentName < t2.assignmentName ? -1 : 1;
      }
      return t1.type < t2.type ? -1 : 1;
    });
  }
}
