import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BadgeProps } from '@bl/components';
import { BenefitsDto, Level, PartnerFirm, Resource, ResourceType } from '@app/core/state/types/partner.types';
import { PartnerProgramCardComponent } from './partnerprogram-card.component';
import { BlFrontendAlertComponent } from '@app/core/wrappers/bl-frontend-alert.component';
import { getLongMonthAndYear, getMonthsBetweenDates } from '@app/shared/misc/dates';
import { PartnerProgramFallenOutAlert } from '../partnerprogram-fallenout-alert/partnerprogram-fallenout-alert.component';

@Component({
  selector: 'app-partnerprogram-resources',
  template: `
    <ng-container *ngIf="types?.length">
      <div class="max-w-screen-xl">
        <app-partnerprogram-fallenout-alert
          [firm]="firm"
          (openTab)="openTab.emit($event)"></app-partnerprogram-fallenout-alert>
        <div [ngClass]="{ dark: level === 1 }">
          <bl-frontend-alert
            *ngIf="!firm?.keyUserCertifications"
            [severity]="level === 1 ? 'info' : 'warning'"
            [header]="'Key User-certifiering låser upp förmånerna på nivå Krom'"
            [listItems]="[{ text: 'Byrån behöver ha minst 1 certifierad Key User.' }]"
            [link]="{
              text: 'Gå till certifieringen',
              url: 'https://www.bjornlunden.se/redovisningsbyr%c3%a5/partnerprogram/key-user-certifiering__8986',
              blank: true
            }"
            [useUnsafeHtml]="true"
            [dismissable]="true">
          </bl-frontend-alert>
        </div>
        <div *ngFor="let type of types; let typeIndex = index">
          <p class="text-2xl font-bold text-bl-blue-light-800 mt-8">{{ type.label }}</p>
          <div
            class="grid grid-cols-1 gap-4"
            [ngClass]="{ 'md:grid-cols-2': typeIndex > 0, 'md:grid-cols-3': typeIndex === 0 }">
            <div *ngFor="let resource of type.resources">
              <ng-container *ngIf="containsLevel(resource.levels, level)">
                <app-partnerprogram-card
                  [header]="resource.contentHeader"
                  [content]="resource.contentText"
                  [badges]="getBadges(resource)">
                  <ng-container *ngIf="resource.contentLink && resource.contentLinkLabel">
                    <i *ngIf="resource.contentLink.includes('tel:')" class="fal fa-phone text-bl-blue-light-600"></i>
                    <i *ngIf="resource.contentLink.includes('mailto:')" class="fal fa-at text-bl-blue-light-600"></i>
                    <i *ngIf="resource.contentLink.includes('//')" class="fal fa-globe text-bl-blue-light-600"></i>
                    <a class="pl-2 text-bl-blue-light-600" [href]="resource.contentLink" target="_blank">{{
                      resource.contentLinkLabel
                    }}</a>
                  </ng-container>
                </app-partnerprogram-card>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="nextLevelResources.length">
          <p class="text-xl font-bold text-bl-blue-light-800 mt-8">Förmåner som tillkommer på högre nivåer</p>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div *ngFor="let resource of nextLevelResources">
              <ng-container *ngIf="!containsLevel(resource.levels, level)">
                <app-partnerprogram-card
                  [header]="resource.contentHeader"
                  [content]="resource.contentText"
                  [badges]="getBadges(resource)">
                  <ng-container *ngIf="resource.contentLink && resource.contentLinkLabel">
                    <i *ngIf="resource.contentLink.includes('tel:')" class="fal fa-phone text-bl-blue-light-600"></i>
                    <i *ngIf="resource.contentLink.includes('mailto:')" class="fal fa-at text-bl-blue-light-600"></i>
                    <i *ngIf="resource.contentLink.includes('//')" class="fal fa-globe text-bl-blue-light-600"></i>
                    <a class="pl-2 text-bl-blue-light-600" [href]="resource.contentLink" target="_blank">{{
                      resource.contentLinkLabel
                    }}</a>
                  </ng-container>
                </app-partnerprogram-card>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  standalone: true,
  imports: [CommonModule, PartnerProgramCardComponent, BlFrontendAlertComponent, PartnerProgramFallenOutAlert],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramResourcesComponent {
  @Input() firm: PartnerFirm | null;
  @Input() level: number;
  @Input()
  set data(data: BenefitsDto) {
    if (!data) {
      return;
    }
    this.types = data.resourceTypes;
    this.nextLevelResources = data.resourceTypes.reduce(
      (acc, type) => acc.concat(type.resources.filter((r) => !this.containsLevel(r.levels, this.level))),
      [],
    );
  }

  @Output() openTab: EventEmitter<number> = new EventEmitter();

  types: ResourceType[] = [];
  nextLevelResources: Resource[] = [];

  containsLevel(levels: Level[], level: number) {
    return levels.some((l) => l.value === level);
  }

  getBadges(resource: Resource) {
    const badges: BadgeProps[] = [];
    if (!resource.levels) {
      return badges;
    }

    badges.push(...this.getNextLevelBadges(resource));
    badges.push(...this.getConditionBadges(resource));
    badges.push(...this.getValidFromBadges(resource));

    return badges;
  }

  private getNextLevelBadges(resource: Resource): BadgeProps[] {
    const badges: BadgeProps[] = [];
    const found = resource.levels.find((l) => l.value === this.level);
    if (found) {
      return badges;
    }

    badges.push({ text: `Låses upp på nivån ${resource.levels[0].label}`, variant: 'neutral', icon: 'lock' });
    return badges;
  }

  private getConditionBadges(resource: Resource): BadgeProps[] {
    const badges: BadgeProps[] = [];
    if (!resource.conditionText) {
      return badges;
    }

    badges.push({ text: resource.conditionText, variant: 'neutral', icon: 'lock' });
    return badges;
  }

  private getValidFromBadges(resource: Resource): BadgeProps[] {
    const badges: BadgeProps[] = [];
    if (resource.validFrom <= new Date().toISOString()) {
      return badges;
    }

    const diff = Math.abs(getMonthsBetweenDates(new Date().toISOString(), resource.validFrom));

    if (diff <= 4) {
      badges.push({
        text: `Startar ${getLongMonthAndYear(resource.validFrom, false)}`,
        variant: 'warning',
        icon: 'exclamation-circle',
      });
      return badges;
    }

    badges.push({ text: 'Kommer senare', variant: 'success', icon: 'calendar-exclamation' });
    return badges;
  }
}
