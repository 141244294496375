import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateConvert',
  standalone: true,
})
export class DateConvertPipe implements PipeTransform {
  transform(value: string): string {
    const searchString = '+00:00';
    if (!value) {
      return '';
    }

    if (!value.includes(searchString)) {
      const timeStampWithTimeZone = value.concat(searchString);
      return moment(timeStampWithTimeZone).format('YYYY-MM-DD HH:mm:ss');
    }

    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  }
}
