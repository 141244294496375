import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TeamsSelectors } from '@app/core/state/teams/teams.selectors';
import { SimpleUserType, TeamType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { BlFrontendBadgeComponent } from '@app/core/wrappers/bl-frontend-badge.component';
import { AdminAvatarComponent } from '@app/shared/components/admin-avatar/admin-avatar.component';
import { UserAvatarComponent } from '@app/shared/components/user-avatar/user-avatar.component';
import { ROOT_TEAM_NAME } from '@app/shared/misc/constants';
import { Store } from '@ngrx/store';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-team-root-team',
  template: `
    <p class="font-semibold text-lg mt-8 mb-1">Teaminställningar</p>
    <p class="mb-7 text-bl-grey-600">Översikt över vilka som kan redigera team och se alla klienter.</p>
    <div class="border border-bl-grey-200 rounded-lg mt-2 flex flex-col shadow-sm ">
      <div class="p-3 pt-5 border-bl-blue-200 flex items-baseline">
        <p class="text-base text-bl-grey-700 font-normal">Byråadministratörer</p>
        <i
          class="fal fa-question-circle fa-lg text-bl-blue-light-500 px-2 cursor-help"
          pTooltip="Administratörer har rätt att se och redigera alla team.
          
          De kan inte läggas till i ett team, men har behörighet att se alla team."></i>
      </div>
      <div class="mx-3 mb-2 border-t border-bl-grey-200"></div>
      <div class="p-3">
        <app-admin-avatar [users]="admins$ | async"></app-admin-avatar>
      </div>
    </div>
    <div
      class="border border-bl-grey-200 rounded-lg mt-8 flex flex-col shadow-sm"
      [ngClass]="{ 'cursor-pointer  hover:shadow-lg': enabled, 'cursor-not-allowed': !enabled }"
      (click)="enabled ? teamClick.emit(rootTeam) : undefined">
      <div class="p-3 pt-5 border-bl-blue-200 flex justify-between">
        <div class="flex items-baseline">
          <p class="text-base text-bl-grey-700 font-normal">{{ name }}</p>
          <i
            *ngIf="enabled"
            class="fal fa-question-circle fa-lg text-bl-blue-light-500 px-2 cursor-help"
            pTooltip="Medarbetare i Huvudteamet har rättighet att se alla klienter.

            Öppna huvudteamskortet och gå till fliken Redigera medarbetare."></i>
          <i
            *ngIf="!enabled"
            class="fal fa-question-circle fa-lg text-bl-blue-light-500 px-2 cursor-help"
            pTooltip="Medarbetare i Huvudteamet har rättighet att se alla klienter.
            
            Skapa ett eller flera team för att kunna redigera huvudteamet."></i>
        </div>
        <div *ngIf="enabled">
          <i class="far fa-arrows-v fa-lg px-2 origin-center transform rotate-45 text-bl-grey-500"></i>
        </div>
      </div>
      <div class="mx-3 mb-2 border-t border-bl-grey-200"></div>
      <div class="p-3">
        <p>Medarbetare</p>
        <app-user-avatar [users]="users$ | async" [maxVisible]="15"></app-user-avatar>
      </div>
      <div class="p-3 pt-1 mb-5">
        <p>Klienter</p>
        <bl-frontend-badge [text]="rootTeam.clients.count?.toString()" variant="info"></bl-frontend-badge>
      </div>
    </div>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TooltipModule, AdminAvatarComponent, UserAvatarComponent, BlFrontendBadgeComponent],
})
export class TeamRootTeamComponent implements OnInit, OnDestroy {
  @Output() teamClick: EventEmitter<TeamType>;

  rootTeam: TeamType;
  enabled = false;
  admins$: Observable<SimpleUserType[]>;
  users$: Observable<SimpleUserType[]>;

  private onDestroySub: Subject<boolean>;

  constructor(private store: Store<AppState>) {
    this.teamClick = new EventEmitter<TeamType>();
    this.onDestroySub = new Subject();
  }

  ngOnInit(): void {
    this.admins$ = this.store.select(UserSelectors.activeAdminsAsSimpleUser);
    this.users$ = this.store.select(TeamsSelectors.selectRootUsersAsSimpleUser);
    this.store
      .select(TeamsSelectors.selectRootTeam)
      .pipe(takeUntil(this.onDestroySub))
      .subscribe((rootTeam) => {
        this.rootTeam = rootTeam;
      });
    this.store
      .select(TeamsSelectors.selectTeams)
      .pipe(takeUntil(this.onDestroySub))
      .subscribe((teams) => {
        this.enabled = teams?.length > 1;
      });
  }

  ngOnDestroy(): void {
    this.onDestroySub.next(true);
    this.onDestroySub.complete();
  }

  get name(): string {
    return ROOT_TEAM_NAME;
  }
}
