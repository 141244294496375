import { Injectable } from '@angular/core';
import { TaskService } from '@app/core/services/task.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, map, mergeMap, of, switchMap } from 'rxjs';
import { ToastActions } from '../toast/toast.actions';
import { TaskTemplateActions } from './task-templates.actions';

@Injectable()
export class TaskTemplateEffects {
  loadTaskTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskTemplateActions.load),
      switchMap(({ taskGroupId }) =>
        this.taskService.getTaskTemplateTypes(taskGroupId).pipe(
          map((templates) => TaskTemplateActions.loadSucceeded({ templates })),
          catchError((error: unknown) => of(TaskTemplateActions.loadFailed({ error }))),
        ),
      ),
    ),
  );

  saveTaskTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskTemplateActions.save),
      switchMap(({ template }) =>
        this.taskService.saveTaskTemplate(template).pipe(
          mergeMap((updatedTemplate) => [
            TaskTemplateActions.saveSucceeded({ template: updatedTemplate }),
            ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Uppdragsmallen är sparad' }),
          ]),
          catchError((error: unknown) =>
            from([
              TaskTemplateActions.saveFailed({ error }),
              ToastActions.showErrorMessage({ summary: 'Oväntat fel', detail: 'Kunde inte spara uppdragsmallen' }),
            ]),
          ),
        ),
      ),
    ),
  );

  activateTaskTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskTemplateActions.activate),
      switchMap(({ template }) =>
        this.taskService.saveTaskTemplate(template).pipe(
          mergeMap((updatedTemplate) => [
            TaskTemplateActions.activateSucceeded({ template: updatedTemplate }),
            ToastActions.showInfoMessage({ summary: 'Återaktiverad', detail: 'Mallen är återaktiverad.' }),
          ]),
          catchError((error: unknown) =>
            from([
              TaskTemplateActions.activateFailed({ error }),
              ToastActions.showErrorMessage({
                summary: 'Oväntat fel',
                detail: 'Kunde inte återaktivera uppdragsmallen',
              }),
            ]),
          ),
        ),
      ),
    ),
  );

  archivedTaskTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskTemplateActions.archive),
      switchMap(({ template }) =>
        this.taskService.saveTaskTemplate(template).pipe(
          mergeMap((updatedTemplate) => [
            TaskTemplateActions.archiveSucceeded({ template: updatedTemplate }),
            ToastActions.showInfoMessage({ summary: 'Inaktiverad', detail: 'Mallen är inaktiverad.' }),
          ]),
          catchError((error: unknown) =>
            from([
              TaskTemplateActions.archiveFailed({ error }),
              ToastActions.showErrorMessage({ summary: 'Oväntat fel', detail: 'Kunde inte inaktivera uppdragsmallen' }),
            ]),
          ),
        ),
      ),
    ),
  );

  // eslint-disable-next-line no-useless-constructor
  constructor(private actions$: Actions, private taskService: TaskService) {}
}
