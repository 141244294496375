<p-dialog [visible]="visible" (visibleChange)="closeDialog()" [style]="{ width: '1200px' }" [draggable]="false">
  <p-header>Jämför mot Företag i molnet</p-header>

  <app-tabs (tabChange)="onTabChange()">
    <app-tab tabTitle="Importera företag till Byråstöd">
      <div style="overflow: auto; height: 350px">
        <ng-container *ngIf="!loadingData; else loadingTpl">
          <app-import-missing-companies-to-byst
            (updated)="showWorkingSpinner = false; updated.emit()"
            (updating)="showWorkingSpinner = true"
          ></app-import-missing-companies-to-byst>
        </ng-container>
      </div>
    </app-tab>
    <app-tab tabTitle="Exportera företag till molnet">
      <div style="overflow: auto; height: 350px">
        <ng-container *ngIf="!loadingData; else loadingTpl">
          <app-export-companies-to-cloud (updated)="updated.emit()"></app-export-companies-to-cloud>
        </ng-container>
      </div>
    </app-tab>
    <app-tab tabTitle="Koppla ihop befintliga klienter och molnföretag">
      <div style="overflow: auto; height: 350px">
        <ng-container *ngIf="!loadingData; else loadingTpl">
          <app-sync-companies-between-byst-cloud
            (updating)="showWorkingSpinner = true"
            (updated)="showWorkingSpinner = false; updated.emit()"
          ></app-sync-companies-between-byst-cloud>
        </ng-container>
      </div>
    </app-tab>
  </app-tabs>

  <p-footer>
    <div class="inline align-bottom mr-2" *ngIf="showWorkingSpinner">
      <p-progressSpinner [style]="{ width: '23px', height: '23px' }" strokeWidth="6"></p-progressSpinner>
    </div>

    <button
      type="button"
      class="btn btn-success"
      *ngIf="!hideAddButton"
      (click)="onAddButton()"
      [disabled]="loadingData || showWorkingSpinner"
    >
      Lägg till
    </button>
    <button type="button" class="btn btn-warning" (click)="closeDialog()">Stäng</button>
  </p-footer>
</p-dialog>

<ng-template #loadingTpl>
  <div class="text-center mt-16">
    <img src="assets/ajax-loader.gif" alt="Arbetar, var god vänta..." class="mb-4 inline" /><br />
    Hämtar information om era molndatabaser. <br />
    Det kan ta några minuter så vänligen vänta...
  </div>
</ng-template>
