import { createFeature, createReducer, on } from '@ngrx/store';
import { CreditInformationType } from '../types/credit-information.types';
import { CreditInformationActions } from './credit-information.actions';

export interface CreditInformationState {
  creditInformation: CreditInformationType;
  error: unknown;
}

export const initialCreditInformationState: CreditInformationState = {
  creditInformation: null,
  error: null,
};

export const creditInformationFeature = createFeature({
  name: 'creditInformation',
  reducer: createReducer(
    initialCreditInformationState,
    on(CreditInformationActions.load, () => initialCreditInformationState),
    on(CreditInformationActions.loadSucceeded, (state, { creditInformation }) => ({
      ...state,
      error: null,
      creditInformation,
    })),
    on(CreditInformationActions.loadFailed, (state, { error }) => ({ ...state, error, creditInformation: null })),
  ),
});
