import { ClientType } from '@app/core/state/types';
import { DialogData, DialogResult, FormDialogBaseComponent } from '../../form-dialog-base/form-dialog-base.component';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TodayDate } from '@app/shared/misc/today.date';
import { CalendarComponent } from '../../calendar/calendar.component';
import { InputTextModule } from 'primeng/inputtext';
import { ToastActions } from '@app/core/state/toast/toast.actions';
import { validateDateFn } from '@app/core/entity/validation';
import { areMonthsBetweenFinancialYearsOk, isFirstDateAfterSecondDate } from '@app/shared/misc/dates';
import { constructUrlToAdvisoryReport } from '../advisoryReport';
import { environment } from 'src/environments/environment';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';

interface Data extends DialogData {
  client: ClientType;
}

interface GenerateAdvisoryReportForm {
  startDate: FormControl<string>;
  endDate: FormControl<string>;
  radius: FormControl<number | null>;
  sni: FormControl<string | null>;
}

@Component({
  selector: 'app-generate-advisory-report-dialog',
  template: `
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div>
        <p>Mellan vilket datumintervall vill du generera rapporten? Förvalt är senast upplagda räkenskapsår.</p>
        <app-calendar
          appendTo="body"
          formControlName="startDate"
          [invalid]="form.controls.startDate.invalid"
          [title]="form.controls.startDate.errors?.startDate?.message ?? ''">
        </app-calendar>
        <app-calendar
          appendTo="body"
          formControlName="endDate"
          [invalid]="form.controls.endDate.invalid"
          [title]="form.controls.endDate.errors?.endDate?.message ?? ''"></app-calendar>
        <div class="mt-2">
          <p>
            Inom vilken radie vill du att jämförelsesiffror mot andra företag ska hämtas från om möjligt? Anges i km
          </p>
          <input type="number" pInputText formControlName="radius" maxlength="5" />
        </div>
        <div class="mt-2">
          <p>
            Vilken SNI-kod vill du ska användas för branschjämförelse? Om ingen anges hämtas SNI-kod m.h.a ditt orgnr.
          </p>
          <input
            type="text"
            pattern="[0-9]+"
            pInputText
            formControlName="sni"
            maxlength="5"
            [ngClass]="{ 'ng-invalid': form.controls.sni.invalid }"
            [title]="form.controls.sni.errors?.sni?.message ?? ''" />
          <a
            class="inline-block text-xl mt-1"
            style="color: var(--bl-blue-light-800) !important"
            href="http://www.sni2007.scb.se/"
            target="_blank">
            Klicka här för att söka SNI-kod
          </a>
        </div>
      </div>
      <div class="flex justify-end mt-4 space-x-2">
        <bl-frontend-button
          type="submit"
          [disabled]="form.invalid"
          [title]="form.errors?.dates?.message || ''"
          [text]="'Skapa rapport'" />
        <bl-frontend-button type="button" [text]="'Avbryt'" variant="secondary" (click)="close({})" />
      </div>
    </form>
  `,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarComponent,
    InputTextModule,
    BlFrontendButtonComponent,
  ],
})
export class GenerateAdvisoryReportDialog extends FormDialogBaseComponent<
  Data,
  DialogResult,
  GenerateAdvisoryReportForm
> {
  onSubmit() {
    const { corporateIdentity: cin, cloudApiKey: pKey } = this.config.data.client;
    const { startDate, endDate, radius, sni } = this.form.value;

    if (pKey === null || cin === null) {
      this.store.dispatch(
        ToastActions.showErrorMessage({
          summary: 'Uppgifter saknas på klientkortet',
          detail: 'Klienten saknar org.nr eller molndatabasnyckel',
        }),
      );
      return;
    }

    const urlToAdvisoryReport = constructUrlToAdvisoryReport({
      documentUrl: environment.documentUrl,
      jwt: sessionStorage.getItem('jwt'),
      cloudApiKey: pKey,
      corporateIdentity: cin,
      startDate,
      endDate,
      radius,
      sni,
    });

    window.open(urlToAdvisoryReport.replace(/\s/g, ''), '_blank');
    this.store.dispatch(
      ToastActions.showInfoMessage({
        summary: 'Rapporten genereras.',
        detail: 'Din rapport håller nu på att genereras i en ny flik i din webbläsare',
      }),
    );

    setTimeout(() => {
      this.close({});
    }, 1000);
  }

  protected override onInitialized = () => {
    this.initForm();
  };

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<Data>, 'data'> {
    return {
      header: 'Rådgivarrapport',
      width: '400px',
      draggable: false,
      modal: true,
      closable: true,
    };
  }

  private initForm = () => {
    this.form = this.builder.group<GenerateAdvisoryReportForm>(
      {
        startDate: new FormControl(this.getStartDate(), [validateDateFn({ key: 'startDate' })]),
        endDate: new FormControl(this.getEndDate(), [validateDateFn({ key: 'endDate' })]),
        radius: new FormControl(null),
        sni: new FormControl(null, [this.validateSni()]),
      },
      { validators: this.validateRequiredFields() },
    );
  };

  private getStartDate = () => {
    const { client } = this.config.data;
    return client.financialYears.length > 0
      ? client.financialYears[client.financialYears.length - 1].span.start
      : new TodayDate().getFirstDayOfCurrentYearAsString();
  };

  private getEndDate = () => {
    const { client } = this.config.data;
    return client.financialYears.length > 0
      ? client.financialYears[client.financialYears.length - 1].span.end
      : new TodayDate().getLastDayOfCurrentYearAsString();
  };

  private validateRequiredFields =
    (): ValidatorFn =>
    (form: FormGroup<GenerateAdvisoryReportForm>): ValidationErrors | null => {
      const { startDate, endDate } = form.value;

      const diff = areMonthsBetweenFinancialYearsOk(startDate, endDate);
      let message = diff
        ? null
        : 'Felaktigt datumintervall\nIntervallet mellan start och slutdatum överskrider 18 månader';

      const isStartAfterEnd = isFirstDateAfterSecondDate(startDate, endDate);
      if (isStartAfterEnd) {
        message = `${message ? `${message}\n` : ''}Startdatum efter slutdatum\nStartdatum måste vara före slutdatum`;
      }

      return message ? { dates: { message } } : null;
    };

  private validateSni =
    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const isValidSni = /^\d{1,5}$/g.test(control.value.trim());
      return isValidSni ? null : { sni: { message: 'Felaktigt SNI-kod\nSNI-kod ska vara 1-5 siffror' } };
    };
}
