import { Task } from '@app/core/entity/task';
import { createActionGroup, props } from '@ngrx/store';

export const TaskActions = createActionGroup({
  source: 'Task',
  events: {
    Save: props<{ task: Task }>(),
    'Save Succeeded': props<{ task: Task }>(),
    'Save Failed': props<{ error: any }>(),
  },
});
