import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SecurityContext,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { PleaseWaitComponent } from '@app/shared/components/please-wait/please-wait.component';
import { getHumanizedFileAcceptString } from '@app/shared/misc/getHumanizedFileAcceptString';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'app-partnerprogram-file-upload',
  template: `
    <div
      class="rounded-lg bg-bl-grey-50 border border-dashed border-bl-grey-200 mt-4 px-12 py-5 min-h-32"
      [ngClass]="isDraggingOver ? 'border-2 border-bl-orange-600' : ''"
      (drop)="drop($event)"
      (dragover)="dragOver($event)"
      (dragleave)="dragLeave($event)">
      <p-progressBar mode="indeterminate" [style]="{ height: '6px' }" [ngClass]="{ hidden: !progress }"></p-progressBar>
      <div class="flex flex-col justify-center items-center">
        <img *ngIf="image" [src]="image" class="mb-4 h-20" />
        <i *ngIf="!image" class="fas fa-image text-5xl text-bl-grey-300"></i>
        <p>
          <span class="text-bl-blue-light-500 hover:underline cursor-pointer" (click)="onUploadClick()">
            Ladda upp en omslagsbild eller dra och släpp.
          </span>
        </p>
        <p class="text-bl-grey-400">{{ humanizedAccept }}</p>
      </div>
      <input
        #fileUpload
        type="file"
        (change)="onFileChange($event)"
        [accept]="accept"
        class="hidden"
        [disabled]="disabled" />
    </div>
  `,
  standalone: true,
  imports: [CommonModule, PleaseWaitComponent, ProgressBarModule],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PartnerProgramFileUpload), multi: true }],
})
export class PartnerProgramFileUpload implements ControlValueAccessor {
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;
  @Input() image: string;
  @Input() accept: string;
  @Input() disabled = false;
  @Output() OnUploaded: EventEmitter<string | ArrayBuffer>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTouched: any = () => {};
  progress: number;
  isDraggingOver = false;

  constructor(private ref: ChangeDetectorRef, private sanitizer: DomSanitizer) {
    this.OnUploaded = new EventEmitter();
  }

  get humanizedAccept(): string {
    return getHumanizedFileAcceptString(this.accept);
  }

  writeValue(obj: unknown): void {
    if (!obj) {
      this.image = null;
      return;
    }

    if (typeof obj !== 'string') {
      return;
    }

    this.image = obj;
  }

  registerOnChange(fn: unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: unknown): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onUploadClick() {
    this.fileUpload?.nativeElement?.click();
  }

  onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target?.files.length) {
      return;
    }

    const file = target.files[0];
    this.getFileAsDataUrl(file);
  }

  drop(event: DragEvent) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    this.isDraggingOver = false;

    const file = this.getFile(event);
    if (!file) {
      return;
    }

    this.getFileAsDataUrl(file);
  }

  dragOver(event: DragEvent) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    this.isDraggingOver = true;
  }

  dragLeave(event: DragEvent) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    this.isDraggingOver = false;
  }

  private getFile = (event: DragEvent): File | null => {
    if (event?.dataTransfer?.items?.length >= 1 && event.dataTransfer.items[0].kind === 'file') {
      return event.dataTransfer.items[0].getAsFile();
    }

    if (event?.dataTransfer?.files?.length >= 1 && event.dataTransfer.files[0].type === 'file') {
      return event.dataTransfer.files[0];
    }

    return null;
  };

  private getFileAsDataUrl = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      if (typeof reader.result !== 'string') {
        return;
      }

      const sanitizedDataUri = this.sanitizer.sanitize(SecurityContext.URL, reader.result);
      this.image = sanitizedDataUri;
      this.onChange(sanitizedDataUri);
      this.OnUploaded.emit(sanitizedDataUri);
      this.progress = null;
      this.ref.markForCheck(); // make sure Angulars change detection picks this up
    };

    reader.onprogress = (event) => {
      if (!event.lengthComputable) {
        return;
      }
      this.progress = event.loaded;
      this.ref.markForCheck(); // make sure Angulars change detection picks this up
    };

    reader.onerror = () => {
      this.progress = null;
      this.ref.markForCheck(); // make sure Angulars change detection picks this up
    };
  };
}
