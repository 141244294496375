import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivityState } from '../entity/activitystate';
import { Activity } from '../entity/activity';
import { EMPTY, Observable } from 'rxjs';

import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { hashCode } from '../../shared/misc/hashcode';
import { TaskService } from './task.service';
import { Task } from '../entity/task';
import { ActivityComposite } from '../entity/activity.composite';
import { Store } from '@ngrx/store';
import { ActivityStateType } from '../state/types';
import { AppState } from '../state/appState';
import { ToastActions } from '../state/toast/toast.actions';
import { toActivtyStateTypeTransformer } from '../state/transformers/transformers';
import { withFirstTeamIds } from '../misc/rxjs-operators';

interface GetActivitesByUser {
  fromDate: string;
  toDate: string;
  taskUserId: number;
  clientUserId: number;
  includeArchivedClients: boolean;
}

@Injectable({ providedIn: 'root' })
export class ActivityService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'activity';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, private taskService: TaskService, store: Store<AppState>) {
    super(store);
  }

  getActivityStates(): Observable<ActivityState[]> {
    const headers = this.options.getAuthorizationHeaderWithEmptyBody().headers;
    return this.http
      .get<ActivityState[]>(this.url + '/states', {
        headers: headers,
      })
      .pipe(this.catchErrorAndShowMessage());
  }

  getActivityStatesTypes(): Observable<ActivityStateType[]> {
    return this.getActivityStates().pipe(map((states) => states.map(toActivtyStateTypeTransformer.transform)));
  }

  getActivites(
    fromDate: string,
    toDate: string,
    taskId: number,
    includeStageChanges: boolean = false,
  ): Observable<Activity[]> {
    const urlExtension = '/' + fromDate + '/' + toDate + (taskId ? '/' + taskId : '');
    const queryParam = '?includeStateChanges=' + includeStageChanges;
    return this.http
      .get<Activity[]>(`${this.url}${urlExtension}${queryParam}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(
        map((activites: any[]) => activites.map((activity) => Activity.from(activity))),
        this.catchErrorAndShowMessage(),
      );
  }

  getActivity(date: string, taskId: number): Observable<Activity> {
    const urlExtension = '/' + date + '/' + taskId;
    return this.http
      .get<Activity>(this.url + urlExtension, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getEarliestUnfinishedDeadline(userId: number): Observable<any> {
    const urlExtension = '/earliestUnfinishedDeadline/' + userId;
    return this.http
      .get<any>(this.url + urlExtension, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getActivitesByUser({
    fromDate,
    toDate,
    taskUserId,
    clientUserId,
    includeArchivedClients,
  }: GetActivitesByUser): Observable<Activity[]> {
    const queryParams = `includeArchivedClients=${Number(includeArchivedClients)}`;
    const url = `${this.url}/byUser3/${fromDate}/${toDate}/${taskUserId}/${clientUserId}?${queryParams}`;
    return withFirstTeamIds(this.store).pipe(
      switchMap(({ ids }) =>
        this.http.get<Activity[]>(url, this.options.getAuthorizationHeaderWithEmptyBody(ids)).pipe(
          map((response) => {
            if (response && response.length > 0) {
              return response.map((activity) => {
                return activity.id ? activity : { ...activity, unsavedId: hashCode(JSON.stringify(activity)) };
              });
            }
            return response;
          }),
          this.catchErrorAndShowMessage(),
        ),
      ),
    );
  }

  getActivityComposite({ span, taskId }: Activity) {
    return this.getActivity(span.end, taskId).pipe(
      tap((activity: Activity | null) => {
        if (!activity) {
          throw new Error('Activity not found');
        }
      }),
      switchMap((activity: Activity) =>
        this.taskService.getTask(activity.taskId).pipe(map((task: Task) => [activity, task])),
      ),
      map(([activity, task]: [Activity, Task]) => {
        activity.task = task;
        return new ActivityComposite(task, activity);
      }),
      catchError(() => {
        this.store.dispatch(
          ToastActions.showWarnMessage({
            summary: 'Saknas',
            detail: `Aktiviteten kan inte hittas eller ett fel uppstod (taskId: ${taskId} med slutdatum ${span.end})`,
          }),
        );
        return EMPTY;
      }),
    );
  }

  saveActivity(activity: Activity): Observable<Activity> {
    const body = JSON.stringify(activity);
    const alternativeUrl = activity.statusChangeDate ? `/${activity.statusChangeDate}` : '';
    return this.http
      .put<Activity>(this.url + alternativeUrl, body, this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }

  saveSingleActivity(date: string, taskId: number, comment: string): Observable<Activity> {
    return this.http
      .put<Activity>(
        `${this.url}/single/${date}/${taskId}?comment=${comment}`,
        null,
        this.options.getAuthorizationHeader(),
      )
      .pipe(this.catchErrorAndShowMessage());
  }

  deleteActivity(activity: Activity) {
    return this.http.delete(`${this.url}/${activity.id}`, this.options.getAuthorizationHeader()).pipe(
      tap(() =>
        this.store.dispatch(
          ToastActions.showInfoMessage({ summary: 'Borttagen', detail: 'Aktiviteten har tagits bort' }),
        ),
      ),
      this.catchErrorAndShowMessage(),
    );
  }
}
