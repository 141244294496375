import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-firm-system-settings',
  templateUrl: './firm-system-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirmSystemSettingsComponent {
  public termsOfAgreementUrl: string;

  constructor() {
    this.termsOfAgreementUrl = environment.termsOfAggrementUrl;
  }

  clearStorageAndLogout() {
    new UserSettingsStorage().clearSettings(true);
    window.location.href = environment.loginUrl;
  }
}
