import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivitySelectors } from '@app/core/state/activities/activities.selectors';
import { toActivtyStateTransformer } from '@app/core/state/transformers/transformers';
import { ActivityStateGroupType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { Store } from '@ngrx/store';
import { MultiSelectModule } from 'primeng/multiselect';
import { Subscription } from 'rxjs';
import { DividerModule } from 'primeng/divider';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { ActivityState } from '../../../core/entity/activitystate';

@Component({
  selector: 'app-activity-states-multi-selector',
  template: `
    <p-multiSelect
      placeholder="- Välj -"
      optionLabel="description"
      optionValue="name"
      optionGroupLabel="description"
      [group]="true"
      [options]="activityStateGroups"
      (onChange)="onValueChange($event.value)"
      [showHeader]="false"
      styleClass="w-full"
      [maxSelectedLabels]="1"
      selectedItemsLabel="{0} status valda"
      [ngModel]="selectedActivityStates"
      [disabled]="disabled"
      appendTo="body"
      [scrollHeight]="scrollHeight"
      [inputId]="inputId"
      [title]="title"
      [class]="invalid ? 'ng-invalid ng-dirty' : ''"
      [autoOptionFocus]="false">
      <ng-template let-group pTemplate="group">
        <p-divider align="center">
          <span class="text-bl-grey-400 text-sm">{{ group.description }}</span>
        </p-divider>
      </ng-template>
    </p-multiSelect>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActivityStateMultiSelectorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, MultiSelectModule, DividerModule],
})
export class ActivityStateMultiSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public _selectedStatuses: ActivityState[];
  public activityStateGroups: ActivityStateGroupType[];
  public selectedActivityStates: string[] = [];
  public scrollHeight: string;
  private subscription: Subscription;
  private onChange: ControlValueAccessorFn = () => {};
  private onTouched: ControlValueAccessorFn = () => {};

  @Input() disabled = false;
  @Input() inputId: string;
  @Input() title: string;
  @Input() invalid: boolean;

  @Input()
  set selectedStatuses(selectedStatuses: ActivityState[]) {
    this._selectedStatuses = selectedStatuses;
    this.initSelectedStatuses();
  }

  @Output() selectedActivitiesEmitter = new EventEmitter<ActivityState[]>();

  constructor(private store: Store<AppState>) {
    this.activityStateGroups = [];
  }

  public ngOnInit(): void {
    this.subscription = this.store.select(ActivitySelectors.activityStates).subscribe((activityStatesGroups) => {
      this.activityStateGroups = activityStatesGroups;
      this.scrollHeight = getScrollHeightString(activityStatesGroups.length, 2);
    });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private initSelectedStatuses() {
    this.selectedActivityStates = [];
    if (this._selectedStatuses) {
      for (let i = 0; i < this._selectedStatuses.length; i++) {
        this.selectedActivityStates[i] = this._selectedStatuses[i].name;
      }
    }
  }

  onValueChange(values: string[]) {
    const statesToEmit: ActivityState[] = [];
    for (let i = 0; i < values.length; i++) {
      for (const group of this.activityStateGroups) {
        for (let j = 0; j < group.items.length; j++) {
          if (values[i] === group.items[j].name) {
            statesToEmit.push(toActivtyStateTransformer.transform(group.items[j]));
          }
        }
      }
    }
    this.selectedActivitiesEmitter.emit(statesToEmit);
    this.onChange(statesToEmit);
    this.onTouched();
  }

  writeValue(selectedStatuses: ActivityState[]): void {
    this.selectedStatuses = selectedStatuses;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
