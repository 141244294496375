import { Component, Input, Output, EventEmitter } from '@angular/core';
import { toActivtyStateTransformer, toActivtyStateTypeTransformer } from '@app/core/state/transformers/transformers';
import { ActivityStateType } from '@app/core/state/types';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ActivityState } from '../../../core/entity/activitystate';

@Component({
  selector: 'app-activity-states-selector',
  template: `
    <p-dropdown
      [autoDisplayFirst]="false"
      [options]="states"
      optionLabel="description"
      optionValue="name"
      [styleClass]="styleClass"
      [ngModel]="selectedState"
      (ngModelChange)="onChange($event)"
      [disabled]="disabled"
      appendTo="body"
      [scrollHeight]="scrollHeight"
      [autoOptionFocus]="false">
    </p-dropdown>
  `,
  standalone: true,
  imports: [CommonModule, FormsModule, DropdownModule],
})
export class ActivityStateSelectorComponent {
  public states: ActivityStateType[];
  public selectedState: string;
  public scrollHeight: string;

  @Input() disabled = false;

  @Input()
  set state(selectedState: ActivityState) {
    if (!selectedState) {
      return;
    }

    this.selectedState = selectedState.name;
  }

  @Input() styleClass = 'w-full';

  @Input()
  set selectableStates(states: ActivityState[]) {
    if (!states) {
      return;
    }

    this.states = states.map(toActivtyStateTypeTransformer.transform);
    this.scrollHeight = getScrollHeightString(this.states.length);
  }

  @Output() stateChange = new EventEmitter<ActivityState>();

  constructor() {
    this.states = [];
  }

  onChange(value: string) {
    for (let j = 0; j < this.states.length; j += 1) {
      if (value === this.states[j].name) {
        this.stateChange.emit(toActivtyStateTransformer.transform(this.states[j]));
        break;
      }
    }
  }
}
