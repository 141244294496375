import { HttpHeaders } from '@angular/common/http';

export class HttpAuthorizationHeader {
  headers: HttpHeaders;
  contentType: string;

  constructor(contentType: string) {
    this.contentType = contentType;
  }

  public getAuthorizationHeaderWithEmptyBody(selectedTeams?: number[]) {
    this.headers = new HttpHeaders()
      .append('Content-Type', this.contentType)
      .append('jwt', sessionStorage.getItem('jwt') || '');
    this.addTeamsHeader(selectedTeams);

    return { headers: this.headers, body: '' };
  }

  public getAuthorizationHeader() {
    const headers = this.getAuthorizationHeaderWithEmptyBody();
    return { headers: headers.headers };
  }

  public getAuthorizationHeaderWithResponseType(responseType: any) {
    const headers = this.getAuthorizationHeaderWithEmptyBody();
    return { responseType, headers: headers.headers };
  }

  public getAuthorizationHeaderWithoutContentType(selectedTeams?: number[]) {
    this.headers = new HttpHeaders().append('jwt', sessionStorage.getItem('jwt'));
    this.addTeamsHeader(selectedTeams);
    return { headers: this.headers };
  }

  private addTeamsHeader(selectedTeams?: number[]) {
    if (!selectedTeams?.length) {
      return;
    }

    this.headers = this.headers.append('X-TEAMS', selectedTeams.join(','));
  }
}
