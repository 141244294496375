import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BulkChangeEvent } from '../interfaces/bulk-change.component';

type State = {
  loading: boolean;
  componentValid: boolean;
  componentValue: BulkChangeEvent | null;
};

const initState: State = {
  loading: false,
  componentValid: false,
  componentValue: null,
};

@Injectable()
export class StateFacade {
  private stateBS: BehaviorSubject<State> = new BehaviorSubject<State>(initState);
  public state$: Observable<State> = this.stateBS.asObservable();
  public stateLoading$: Observable<boolean> = this.getLoadingStateObservable();
  public stateComponentValid$: Observable<boolean> = this.getValidStateObservable();
  public stateComponentValue$: Observable<BulkChangeEvent | null> = this.getComponentValueStateObservable();

  update(partialState: Partial<State>): void {
    this.stateBS.next({ ...this.stateBS.getValue(), ...partialState });
  }

  reset() {
    this.stateBS.next(initState);
  }

  private getLoadingStateObservable() {
    return this.state$.pipe(
      map((state: State) => state.loading),
      distinctUntilChanged()
    );
  }

  private getValidStateObservable() {
    return this.state$.pipe(
      map((state: State) => state.componentValid),
      distinctUntilChanged()
    );
  }

  private getComponentValueStateObservable() {
    return this.state$.pipe(
      map((state: State) => state.componentValue),
      distinctUntilChanged()
    );
  }
}
