import { Period } from './period';
import { DeadlineRule } from './deadlinerule';
import { ActivityState } from './activitystate';
import { Activity } from './activity';
import { User } from './user';
import { Client } from './client';

export class Task {
  public agent: string;
  public agentCall = '';
  public archived: boolean;
  public assignmentAgent = '';
  public assignmentAgreement: boolean;
  public assignmentId: number;
  public assignmentName = '';
  public client: Client = new Client('');
  public client_id: number;
  public client_name: string;
  public code: string;
  public comment = '';
  public deadlineRule: DeadlineRule = new DeadlineRule();
  public deleteable = false;
  public firmId = 1;
  public feedMode = 'BOTH';
  public id: number;
  public parameterizedActivities: Activity[] = [];
  public periodicity: Period;
  public preferredTemplate = false;
  public preselectedCustomerResponsible = false;
  public preselectedLoggedInUser = false;
  public reminderDiff: number;
  public selectablePeriodicities: Period[];
  public selectableStates: ActivityState[] = [];
  public selectable_states: ActivityState[] = [];
  public span: { start: string; end: string };
  public startDiff: number;
  public statusesAsString = '';
  public type: string;
  public users: User[];
  public usersAsString = '';
  public warningDiff: number;
  public taskGroupId: number | null;

  constructor() {
    this.span = { start: '', end: '' };
  }
}
