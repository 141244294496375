import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-firm-task-template-ask-archive',
  template: `
    <div>
      <p>
        Den kommer då inte bli valbar som ny tjänst på dina klienter. Tidigare upplagda fortsätter att fungera.
        <br />Vill du fortsätta?
      </p>
      <div class="flex w-full pt-6 justify-end">
        <button type="button" class="btn btn-success mr-2" (click)="archiveTaskTemplate(true)">Ja</button>
        <button type="button" class="btn btn-warning" (click)="archiveTaskTemplate(false)">Nej</button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [],
})
export class FirmTaskTemplateAskArchiveDialog {
  constructor(private ref: DynamicDialogRef) {}

  archiveTaskTemplate = (ok: boolean) => {
    this.ref.close(ok);
  };
}
