import { createSelector } from '@ngrx/store';
import { AppState } from '../appState';

const teamState = (state: AppState) => state.team;

const selectTeam = createSelector(teamState, (state) => state.team);
const selectDialogType = createSelector(teamState, (state) => state.dialog);
const selectTeamType = createSelector(teamState, (state) => state.teamType);

export const TeamSelectors = {
  selectTeam,
  selectDialogType,
  selectTeamType,
};
