<app-table
  #table
  storageKey="closingListTable"
  [columns]="columns"
  [data]="list$ | async"
  [rowsPerPage]="defaultNumberOfRows"
  sortField="client"
  exportFilename="bokslut"
  (pageChange)="paginatorTriggered($event)">
  <app-table-filter>
    <app-team-multi-selector localStorageKey="closing" (OnTeamsInit)="refreshList()"></app-team-multi-selector>
    <app-table-filter-item label="Klientansvarig">
      <app-user-selector [(userId)]="selectedClientResponsibleUserId" styleClass="min-w-48"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item label="Utförande handläggare">
      <app-user-selector [(userId)]="selectedTaskUserId" styleClass="min-w-48"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item label="Företagstyp">
      <app-multiselect-dropdown-including-all
        [allValue]="allValue"
        [options]="clientTypes$ | async"
        [(selected)]="selectedTypes"
        optionLabel="description"
        optionValue="name"
        selectedItemLabel="typer"></app-multiselect-dropdown-including-all>
    </app-table-filter-item>
    <app-table-filter-item label="Bokslutsmånad">
      <div class="flex">
        <app-multiselect-dropdown-including-all
          [allValue]="0"
          [options]="months"
          [(selected)]="selectedMonths"
          optionLabel="label"
          optionValue="value"
          selectedItemLabel="månader"></app-multiselect-dropdown-including-all>
        <app-year-selector [(year)]="selectedYear" styleClass="min-w-32 ml-1"></app-year-selector>
      </div>
    </app-table-filter-item>
    <app-table-filter-item>
      <app-refresh-button [loading]="loading$ | async" (onClick)="refreshList()"></app-refresh-button>
    </app-table-filter-item>
    <app-table-filter-item>
      <p-checkbox
        [binary]="true"
        [(ngModel)]="includeAllDoneClients"
        (ngModelChange)="filterList()"
        [label]="includeFinishedLabel$ | async"></p-checkbox>
    </app-table-filter-item>
  </app-table-filter>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="client" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">{{ client.client }}</a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="corporateIdentity" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">
      {{ client.corporateIdentity }}
    </a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="customerNumber" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">
      {{ client.customerNumber }}
    </a>
  </ng-template>

  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="best.activity.state.description"
    let-activity="rowData.best">
    <ng-container
      *ngTemplateOutlet="
        defaultCompositeColumn;
        context: { $implicit: activity, title: activity?.activity?.alertLevel?.description }
      "></ng-container>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="forb.activity.state.description"
    let-activity="rowData.forb">
    <ng-container
      *ngTemplateOutlet="
        defaultCompositeColumn;
        context: { $implicit: activity, title: activity?.activity?.alertLevel?.description }
      "></ng-container>
  </ng-template>

  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="boks.activity.state.description"
    let-activity="rowData.boks">
    <ng-container
      *ngTemplateOutlet="
        defaultCompositeColumn;
        context: { $implicit: activity, title: activity?.activity?.alertLevel?.description }
      "></ng-container>
  </ng-template>

  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="uppr.activity.state.description"
    let-activity="rowData.uppr">
    <ng-container
      *ngTemplateOutlet="
        defaultCompositeColumn;
        context: { $implicit: activity, title: activity?.activity?.alertLevel?.description }
      "></ng-container>
  </ng-template>

  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="arsr.activity.state.description"
    let-activity="rowData.arsr">
    <ng-container
      *ngTemplateOutlet="
        defaultCompositeColumn;
        context: { $implicit: activity, title: activity?.activity?.alertLevel?.description }
      "></ng-container>
  </ng-template>

  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="arss.activity.state.description"
    let-activity="rowData.arss">
    <ng-container
      *ngTemplateOutlet="
        defaultCompositeColumn;
        context: { $implicit: activity, title: activity?.activity?.alertLevel?.description }
      "></ng-container>
  </ng-template>

  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="uppa.activity.state.description"
    let-activity="rowData.uppa">
    <ng-container
      *ngTemplateOutlet="
        defaultCompositeColumn;
        context: { $implicit: activity, title: activity?.activity?.alertLevel?.description }
      "></ng-container>
  </ng-template>

  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="skic.activity.state.description"
    let-activity="rowData.skic">
    <ng-container
      *ngTemplateOutlet="
        defaultCompositeColumn;
        context: { $implicit: activity, title: activity?.activity?.alertLevel?.description }
      "></ng-container>
  </ng-template>

  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="bfrb.activity.state.description"
    let-activity="rowData.bfrb">
    <ng-container
      *ngTemplateOutlet="
        defaultCompositeColumn;
        context: { $implicit: activity, title: activity?.activity?.alertLevel?.description }
      "></ng-container>
  </ng-template>

  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="bokr.activity.state.description"
    let-activity="rowData.bokr">
    <ng-container
      *ngTemplateOutlet="
        defaultCompositeColumn;
        context: { $implicit: activity, title: activity?.activity?.alertLevel?.description }
      "></ng-container>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="program" let-data="rowData">
    <div class="text-center">
      <a [href]="getAgentUrl(data)"><img src="../../assets/blb20.png" title="Starta programmet" /></a>
    </div>
  </ng-template>

  <ng-template tableTemplate="emptyMessage">{{ statusText$ | async }}</ng-template>

  <ng-template tableTemplate="summary" let-data>
    <div class="relative">
      Antal aktuella klienter: {{ data.length }}
      <div class="absolute export-button-wrapper">
        <p-button
          icon="pi pi-download"
          class="export-button"
          size="small"
          label="Exportera lista"
          (click)="table.exportCSV()"
          title="Ladda ner listan för import till Excel"></p-button>
      </div>
    </div>
  </ng-template>

  <ng-template
    #defaultCompositeColumn
    let-activity
    let-title="title"
    let-showSpan="showSpan"
    let-emptyBlack="emptyBlack"
    let-isInlineBlock="isInlineBlock">
    <div
      class="text-center cursor-pointer truncate"
      (click)="onShowActivity(activity)"
      [style.color]="activity?.activity?.stateColor"
      [ngClass]="{ inline: isInlineBlock }">
      <app-activity-comment-icon [title]="activity?.activity?.comment"></app-activity-comment-icon>
      <app-task-comment-icon [title]="activity?.task?.comment"></app-task-comment-icon>
      <app-status-icon
        [status]="activity?.activity?.alertLevel?.name"
        [color]="activity?.activity?.stateColor"
        [title]="title"
        [emptyBlack]="!!emptyBlack"></app-status-icon>
      <span *ngIf="isUndefined(showSpan) || !!showSpan">{{ activity?.activity?.state?.description }}</span>
    </div>
  </ng-template>
</app-table>
