import { createReducer, on } from '@ngrx/store';
import { CollaborationPackageActions } from './collaboration-packages.actions';
import { CollaborationPackageItemState } from '../types/collaboration-package.types';

const initialState: CollaborationPackageItemState = {
  email: null,
  client: null,
  meta: null,
  error: null,
};

export const collaborationPackageItemReducer = createReducer(
  initialState,
  on(CollaborationPackageActions.clearState, () => ({
    ...initialState,
  })),
  on(CollaborationPackageActions.loadEmailForClientSucceeded, (state, { email }) => ({
    ...state,
    email,
    error: null,
  })),
  on(CollaborationPackageActions.loadEmailForClientFailed, (state, { error }) => ({
    ...state,
    email: null,
    error,
  })),
  on(CollaborationPackageActions.loadClientSucceeded, (state, { client }) => ({
    ...state,
    client,
  })),
  on(CollaborationPackageActions.setMeta, (state, { meta }) => ({
    ...state,
    meta,
  })),
);
