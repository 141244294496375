import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { Store } from '@ngrx/store';
import { Observable, filter, map } from 'rxjs';
import { AppState } from '@app/core/state/appState';
import { LevelDto } from '@app/core/state/types/partner.types';

@Component({
  selector: 'app-partnerprogram-cert-card',
  template: `
    <div
      class="relative p-6 rounded-lg drop-shadow-lg bg-contain bg-no-repeat partner-level-card"
      [ngClass]="{
        'partner-level-one': level?.value === 1 || hasFallenOut,
        'partner-level-two': level?.value === 2,
        'partner-level-three': level?.value === 3,
        'partner-level-four': level?.value === 4
      }"
      [style]="imageUrl">
      <div class="absolute bottom-0 left-0 mb-1 ml-8">
        <p class="text-xl font-bold mb-0">Byråpartner • {{ level?.label }}</p>
        <p class="text-base font-normal">
          {{ firmName$ | async }}
        </p>
      </div>
    </div>
  `,
  styles: [``],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramCertCardComponent {
  @Input() hasFallenOut: boolean;
  @Input() level: LevelDto | null = null;
  firmName$: Observable<string>;

  constructor(store: Store<AppState>) {
    this.firmName$ = store.select(FirmSelectors.selectFirm).pipe(
      filter((f) => Boolean(f)),
      map((firm) => firm.name),
    );
  }

  get imageUrl(): string {
    return `background-image: url(assets/partner-card-${this.hasFallenOut ? '1' : this.level?.value}.png);`;
  }
}
