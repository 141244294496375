import { Component, OnDestroy, OnInit } from '@angular/core';
import { Activity } from '@app/core/entity/activity';
import { ActivityService } from '@app/core/services/activity.service';
import { ClientService } from '@app/core/services/clients.service';
import { ListActions } from '@app/core/state/list/list.actions';
import { ListSelectors } from '@app/core/state/list/list.selectors';
import { updateActivityCallback } from '@app/core/state/shared/shared';
import { CompositeBaseType, CompositeListFilter, CompositeListParams, CompositeType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';
import { INCLUDE_FINISHED_CLIENTS_LABEL } from '@app/shared/misc/constants';
import { MemoizedSelector, Store } from '@ngrx/store';
import { first, map, MonoTypeOperatorFunction, Observable, Subject, takeUntil } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { showClientDialog } from '@app/shared/misc/showClientDialog';
import { showActivityDetailsDialog } from '@app/shared/misc/showActivityDetailsDialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CompositeActions } from '@app/core/state/composite/composite.actions';
import { toActivityTransformer, toActivityTypeTransformer } from '@app/core/state/transformers/transformers';
import { CompositeSelectors } from '@app/core/state/composite/composite.selectors';

@Component({
  selector: 'app-list-component',
  template: ``,
})
export abstract class CompositeListComponent<
  Type extends CompositeBaseType,
  Filter extends CompositeListFilter,
  Params extends CompositeListParams,
> implements OnInit, OnDestroy
{
  protected statusText$: Observable<string>;
  protected list$: Observable<Type[]>;
  protected includeFinishedLabel$: Observable<string>;
  protected loading$: Observable<boolean>;
  protected includeFinishedLabel: string = INCLUDE_FINISHED_CLIENTS_LABEL;
  protected uss: UserSettingsStorage;
  protected defaultNumberOfRows = 50;
  private onDestroySub: Subject<boolean>;
  private selector: MemoizedSelector<AppState, { composites: Type[]; count: number }>;

  constructor(
    protected store: Store<AppState>,
    protected clientService: ClientService,
    protected activityService: ActivityService,
    protected domSanitizer: DomSanitizer,
    private dialogService: DialogService,
  ) {
    this.uss = new UserSettingsStorage();
    this.onDestroySub = new Subject();
    this.selector = this.getSelector();
  }

  abstract loadUserSettings(): void;

  abstract saveUserSettings(): void;

  abstract getFilterValue(): Filter;

  abstract getParamsValue(): Params;

  abstract getPaginatorTriggeredKey(): string;

  abstract getCompositeType(): CompositeType;

  ngOnInit(): void {
    this.loadUserSettings();
    this.statusText$ = this.store.select(ListSelectors.getStatusText).pipe(takeUntil(this.onDestroySub));
    this.loading$ = this.store.select(ListSelectors.isLoading).pipe(takeUntil(this.onDestroySub));
    this.list$ = this.store.select(this.selector).pipe(
      map(({ composites }) => composites),
      takeUntil(this.onDestroySub),
    );
    this.includeFinishedLabel$ = this.store.select(this.selector).pipe(
      map(({ count }) => `${INCLUDE_FINISHED_CLIENTS_LABEL} (${count} st)`),
      takeUntil(this.onDestroySub),
    );
  }

  ngOnDestroy(): void {
    this.saveUserSettings();
    this.onDestroySub.next(true);
    this.onDestroySub.complete();
  }

  protected onShowExistingClient = (id: number) => {
    showClientDialog({ clientService: this.clientService, dialogService: this.dialogService, id, store: this.store })
      .pipe(first())
      .subscribe((result) => {
        if (result?.client?.id && result?.client?.id !== -1) {
          this.refreshList();
        }
      });
  };

  protected onShowActivity = (composite: { activity: Activity }) => {
    if (!composite?.activity?.taskId) {
      return;
    }

    showActivityDetailsDialog(this.activityService, this.dialogService, composite?.activity).subscribe((result) => {
      if (result?.activity?.state?.name) {
        this.store.dispatch(
          CompositeActions.updateActivity({
            compositeType: this.getCompositeType(),
            activity: toActivityTypeTransformer.transform(toActivityTransformer.transform(result.activity)),
            callback: updateActivityCallback(),
          }),
        );
      }
    });
  };

  protected refreshList = () =>
    this.store.dispatch(
      CompositeActions.loadComposites({
        compositeType: this.getCompositeType(),
        filter: this.getFilterValue(),
        params: this.getParamsValue(),
      }),
    );

  protected filterList = () => this.store.dispatch(ListActions.filterList({ filter: this.getFilterValue() }));

  protected paginatorTriggered = ({ rows }: { rows: number }) => {
    this.uss.saveSetting(this.getPaginatorTriggeredKey(), String(rows));
  };

  protected getSelector(): MemoizedSelector<AppState, { composites: Type[]; count: number }> {
    return CompositeSelectors.allFilteredComposites<Type, Filter, Params>();
  }

  protected takeUntilDestroyed = <T>(): MonoTypeOperatorFunction<T> => takeUntil(this.onDestroySub);
}
