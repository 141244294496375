// eslint-disable-next-line no-shadow
export enum MessageIds {
  'USER_WITHOUT_TEAM_LOGGED_IN' = 'USER_WITHOUT_TEAM_LOGGED_IN',
  'USER_RECEIVED_TODO' = 'USER_RECEIVED_TODO',
  'USER_TODO_UPDATED_BY_OTHER_USER' = 'USER_TODO_UPDATED_BY_OTHER_USER',
  'NEW_CLIENT_ONBOARDED' = 'NEW_CLIENT_ONBOARDED',
}

export interface NewClientOnboardedMessage {
  clientId: number;
  apiKey: string;
  name: string;
  active: boolean;
  publicKey: string;
  providerKey: string;
  providerName: string;
}
