import { map, tap, switchMap, shareReplay, withLatestFrom, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Todo } from '../entity/todo';
import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { TodayDate } from '../../shared/misc/today.date';
import { UserSettingsStorage } from '../storage/user.settings.storage';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { TodoListParams } from '../state/types';
import { AppState } from '../state/appState';
import { AuthSelectors } from '../state/auth/auth.selectors';
import { takeFirstTruthy, withFirstTeamIds } from '../misc/rxjs-operators';
import { toTodoTransformer, toTodoTypeTransformer } from '../state/transformers/todo.transformers';
import { TodoType } from '../state/types/todo.types';

@Injectable({
  providedIn: 'root',
})
export class NotesService extends BLService {
  public allTodos: Todo[];
  public untouchedTodos: Todo[] = [];
  public fromDate: string = new TodayDate().getDefaultSearchFromDate();
  public toDate: string = new TodayDate().getDefaultSearchToDate();
  public unfinishedTodos$: Observable<number>;
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'note';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;
  private uss = new UserSettingsStorage();
  private currentUsersTodosCountBS = new BehaviorSubject(0);

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
    this.unfinishedTodos$ = this.getCurrentUserId().pipe(
      map((userId) => ({ fromDate: this.fromDate, toDate: this.toDate, executorId: userId })),
      switchMap((params) =>
        this.getByDatesAndUserIdType(params).pipe(
          catchError(() => of([])), //continue with empty list if request fails, so the stream does not break
          // save all the todos to untouchedTodos, to be able to compare states later
          tap((y) => (this.untouchedTodos = y.map(toTodoTransformer.transform))),
          // filter only the todo's thats not done
          map((todo) => todo.filter((item) => item.state.name !== 'DON')),
        ),
      ),
      // return the length of the array containing the filtered todo's
      map((todos) => todos.length),
      // take the number of the array including the unfinished todos and store
      // that value in the BehaviorSubject
      tap((unfinishedTodos) => this.currentUsersTodosCountBS.next(unfinishedTodos)),
      // return the observable that's connected to the BS.
      switchMap(() => this.todos$),
      // share the result if we have multiple subscribers
      shareReplay(),
    );
  }

  private addTodoToStream(todo: Todo) {
    const streamValue = this.currentUsersTodosCountBS.value;
    const todoExists = this.allTodos.some((obj) => obj.id === todo.id);
    const daysDiff = Math.abs(moment().diff(todo.deadline, 'days'));

    if (todoExists) {
      const objBeforeEdit = this.untouchedTodos.find((t) => t.id === todo.id);
      const objAfterEdit = todo;
      const equals = JSON.stringify(objBeforeEdit) === JSON.stringify(todo);
      const stateBeforeEdit = objBeforeEdit ? objBeforeEdit.state.name : '';
      const stateAfterEdit = objAfterEdit.state.name;
      // check if the two objects are equal, otherwise we shouldn't affect the stream
      if (!equals) {
        if (stateBeforeEdit !== stateAfterEdit) {
          if (stateAfterEdit !== 'DON' && stateBeforeEdit === 'DON' && (isNaN(daysDiff) || daysDiff < 14)) {
            this.currentUsersTodosCountBS.next(streamValue + 1);
          } else if (stateAfterEdit === 'DON' && stateBeforeEdit !== 'DON') {
            this.currentUsersTodosCountBS.next(streamValue - 1);
          } else {
            return;
          }
        }
      }
    } else {
      const loggedInUser = Number(this.uss.loadSetting('logged_in_user_id', ''));
      // if it's a new todo which not has a state of done and logged in user is set as user on the todo
      if (isNaN(daysDiff) || daysDiff < 14) {
        if (todo.users.length > 0 && todo.state.name !== 'DON' && todo.users.some((user) => user.id === loggedInUser)) {
          this.currentUsersTodosCountBS.next(streamValue + 1);
        }
      }
    }
  }

  getById(id: number): Observable<TodoType> {
    return this.http
      .get<TodoType>(`${this.url}/${id}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getByClientId(id: number): Observable<Todo[]> {
    return this.http
      .get<Todo[]>(`${this.url}/client/${id}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getByDatesAndUserId(start: string, end: string, id: number): Observable<Todo[]> {
    return this.http
      .get<Todo[]>(`${this.url}/${start}/${end}/${id}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(
        this.catchErrorAndShowMessage(),
        tap((todoList) => {
          this.allTodos = todoList;
        }),
      );
  }

  getByDatesAndUserIdType({ fromDate, toDate, executorId }: TodoListParams): Observable<TodoType[]> {
    return withFirstTeamIds(this.store).pipe(
      switchMap(({ ids }) =>
        this.http
          .get<TodoType[]>(
            `${this.url}/${fromDate}/${toDate}/${executorId}`,
            this.options.getAuthorizationHeaderWithEmptyBody(ids),
          )
          .pipe(
            this.catchErrorAndShowMessage(),
            tap((todoList) => {
              this.allTodos = todoList;
            }),
            map((items) => items.map(toTodoTypeTransformer.transform)),
          ),
      ),
    );
  }

  addTodo(todo: Todo): Observable<Todo> {
    const body = todo;
    return this.http.put<Todo>(this.url, body, this.options.getAuthorizationHeader()).pipe(
      this.catchErrorAndShowMessage(),
      tap((newTodo) => this.addTodoToStream(newTodo)),
    );
  }

  addTodoType(todo: TodoType): Observable<TodoType> {
    const body = todo;
    return this.http.put<TodoType>(this.url, body, this.options.getAuthorizationHeader()).pipe(
      this.catchErrorAndShowMessage(),
      tap((newTodo) => this.addTodoToStream(newTodo)),
    );
  }

  deleteTodo(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}/${id}`, this.options.getAuthorizationHeaderWithEmptyBody()).pipe(
      this.catchErrorAndShowMessage(),
      tap(() => this.currentUsersTodosCountBS.next(this.currentUsersTodosCountBS.value - 1)),
    );
  }

  deleteTodoType(todo: TodoType): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}/${todo.id}`, this.options.getAuthorizationHeaderWithEmptyBody()).pipe(
      this.catchErrorAndShowMessage(),
      withLatestFrom(this.getCurrentUserId()),
      map(([success, userId]: [boolean, number]) => {
        const wasTodoAssignedToCurrentUser = todo.users.some((user) => user.id === userId);
        if (success && wasTodoAssignedToCurrentUser) {
          this.currentUsersTodosCountBS.next(this.currentUsersTodosCountBS.value - 1);
        }
        return success;
      }),
    );
  }

  public increaseTodosCount() {
    this.currentUsersTodosCountBS.next(this.currentUsersTodosCountBS.value + 1);
  }

  private get todos$() {
    return this.currentUsersTodosCountBS.asObservable();
  }

  private getCurrentUserId(): Observable<number> {
    return this.store.select(AuthSelectors.selectAuthAsSimpleUser).pipe(
      takeFirstTruthy(),
      map((user) => user.id),
    );
  }
}
