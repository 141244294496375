import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TaskService } from '@app/core/services/task.service';
import { UserType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { CLIENT_RESPONSIBLE } from '@app/shared/misc/constants';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Task } from 'src/app/core/entity/task';
import { TableColumnWithFilter } from 'src/app/shared/components/table/interfaces/table-column';
import { StateFacade } from '../../../facades/state.facade';
import { BulkChangeComponentData } from '../../../interfaces/bulk-change.component';
import { BaseChangeComponent } from '../base-change.component';

@Component({
  template: `<div></div>`,
})
export class RemoveTaskComponent extends BaseChangeComponent {
  availableUsers$: Observable<UserType[]>;
  filterAvailableUsers$: Observable<UserType[]>;

  constructor(stateFacade: StateFacade, private taskService: TaskService, private store: Store<AppState>) {
    super(stateFacade);
    stateFacade.update({ componentValid: true });
    this.availableUsers$ = this.store.select(UserSelectors.activeUsersWithNoResponsible);
    this.filterAvailableUsers$ = this.store.select(UserSelectors.activeUsersWithMissingResponsible);
  }

  protected load(): Observable<BulkChangeComponentData> {
    this.formGroup.disable({ emitEvent: false });

    return this.taskService.getAllDeletableTasks().pipe(
      map(this.sortTasks),
      map(this.toBulkChangeComponentData),
      finalize(() => {
        this.formGroup.enable({ emitEvent: false });
      }),
    );
  }

  protected createForm() {
    return new FormGroup({});
  }

  protected getDataIdentityKey(): string {
    return 'id';
  }

  protected getTableColumns(): TableColumnWithFilter[] {
    return [
      { field: 'client.name', header: 'Klient', filter: { type: 'text' } },
      { field: 'client.corporateIdentity', header: 'Org.nr', filter: { type: 'text' } },
      {
        field: 'client.responsible',
        header: CLIENT_RESPONSIBLE,
        fieldValue: (responsible) => responsible?.name,
        filter: {
          type: 'multidropdown',
          data: this.availableUsers$,
          dataLabel: 'name',
          dataValue: 'id',
        },
      },
      { field: 'assignmentName', header: 'Uppdrag', filter: { type: 'text' } },
      { field: 'type', header: 'Tjänst', filter: { type: 'text' } },
      {
        field: 'users',
        header: 'Handläggare',
        filter: {
          type: 'multidropdownwithmultirules',
          data: this.availableUsers$,
          dataLabel: 'name',
          dataValue: 'id',
        },
        fieldValue: (users) => users?.map((user: UserType) => user.initials).join(', '),
      },
      { field: 'span.start', header: 'Startdatum', filter: { type: 'text' } },
      { field: 'periodicity.description', header: 'Periodicitet', filter: { type: 'text' } },
      {
        field: 'client.currentFinancialYear',
        header: 'Räkenskapsår',
        filter: { type: 'text' },
        fieldValue: (financialYear) =>
          financialYear ? `${financialYear.span?.start} - ${financialYear.span?.end}` : '',
      },
    ];
  }

  protected override onValidateFields(): boolean {
    return true;
  }

  private sortTasks(tasks: Task[]): Task[] {
    return tasks.sort((t1, t2) => {
      if (t1.client.name !== t2.client.name) {
        return t1.client.name < t2.client.name ? -1 : 1;
      }

      if (t1.assignmentName !== t2.assignmentName) {
        return t1.assignmentName < t2.assignmentName ? -1 : 1;
      }

      return t1.type < t2.type ? -1 : 1;
    });
  }

  private toBulkChangeComponentData(tableData: Task[]): BulkChangeComponentData {
    return { tableData, dataToChange: {} };
  }
}
