import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { isInDoneGroup, isInStartGroup } from '@app/core/state/activities/activities.selectors';
import { ActivityStateType } from '@app/core/state/types';

export const requiredIfVisibleValidator = () => {
  return (group: FormGroup): ValidationErrors => {
    const useExistingTemplate = group.get('useExistingTemplate').value;
    const isAssignmentSelected = !!group.get('forAssignment').value?.id;
    const isTaskSelected = !!group.get('forTask').value?.id;

    if (useExistingTemplate && !isAssignmentSelected) {
      return { forAssignment: 'required' };
    }
    if (useExistingTemplate && !isTaskSelected) {
      return { forTask: 'required' };
    }
    return null;
  };
};

export const requiredStatesValidator = () => {
  const leastOneStartValue = (array: ActivityStateType[]) => array.some((item) => isInStartGroup(item));
  const leastOneDoneValue = (array: ActivityStateType[]) => array.some((item) => isInDoneGroup(item));

  return (formControl: AbstractControl): ValidationErrors => {
    const value = formControl?.value;
    const validArray = Array.isArray(value) && value.length > 0;
    const validValuesInArray = validArray && leastOneStartValue(value) && leastOneDoneValue(value);

    if (validValuesInArray) {
      return null;
    }
    return { required: true };
  };
};
