import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BulkService } from 'src/app/core/services/bulk.service';
import { BystSharedModule } from 'src/app/shared/byst-shared.module';
import { BulkChangeComponent } from './change/bulk-change.component';
import { AddTaskComponent } from './change/components/add-task/add-task.component';
import { ChangeCompanyTypeComponent } from './change/components/change-company-type/change-company-type.component';
import { ChangeResponsibleUserComponent } from './change/components/change-responsible-user/change-responsible-user.component';
import { ChangeSelectableStateComponent } from './change/components/change-selectable-state/change-selectable-state.component';
import { ChangeTaskUserComponent } from './change/components/change-selected-task-user/change-task-user.component';
import { ChangeStartdateComponent } from './change/components/change-startdate/change-startdate.component';
import { RemoveTaskComponent } from './change/components/remove-task/remove-task.component';
import { BulkItemDirective } from './directive/bulk-item.directive';
import { StateFacade } from './facades/state.facade';
import { BulkMainComponent } from './main/bulk-main.component';

@NgModule({
  declarations: [
    BulkMainComponent,
    BulkChangeComponent,
    BulkItemDirective,
    ChangeResponsibleUserComponent,
    ChangeTaskUserComponent,
    ChangeStartdateComponent,
    ChangeCompanyTypeComponent,
    ChangeSelectableStateComponent,
    AddTaskComponent,
    RemoveTaskComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BystSharedModule],
  exports: [BulkMainComponent],
  providers: [StateFacade, BulkService],
})
export class BulkHandlingModule {}
