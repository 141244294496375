export enum PermissionType {
  VisibleInNotification = 'VISIBLE_IN_NOTIFICATION',
  ShowTaxAccountBalance = 'SHOW_TAX_ACCOUNT_BALANCE',
}

export enum PermissionGroup {
  None = 'NONE',
  All = 'ALL',
  OnlyResponsibleUserAndFirmAdmin = 'ONLY_RESPONSIBLE_USER_AND_FIRM_ADMIN',
  OnlyFirmAdmin = 'ONLY_FIRM_ADMIN',
  OnlySelectedUsers = 'ONLY_SELECTED_USERS',
}

export interface Permission {
  id: number;
  clientId: number;
  permissionKey: PermissionType;
  permissionGroup: PermissionGroup;
  userId: number;
}
