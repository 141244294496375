import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { AppState } from '../state/appState';
import { BLService } from './bl.service';

@Injectable({ providedIn: 'root' })
export class FileVaultService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private static RESOURCE_ENDPOINT = 'filevault';
  private static url = environment.serverUrl + FileVaultService.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  public static get baseUrl() {
    return this.url;
  }

  public getAllFileNames(): Observable<string[]> {
    return this.http.get<string[]>(FileVaultService.url, this.options.getAuthorizationHeaderWithEmptyBody()).pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 405)) {
          return of([]);
        }

        return throwError(() => error);
      }),
      this.catchErrorAndShowMessage(),
    );
  }

  public download(fileName: string): Observable<any> {
    const options = this.options.getAuthorizationHeaderWithResponseType('arraybuffer');
    options.headers.delete('Content-Type');

    return this.http.get<any>(`${FileVaultService.url}/download/${fileName}`, options);
  }
}
