import { Pipe } from '@angular/core';
import { AmlStatus } from '@app/core/state/types/aml.types';

@Pipe({
  name: 'amlStatusDescription',
  standalone: true,
})
export class AmlStatusDescriptionPipe {
  // eslint-disable-next-line complexity
  transform(value: AmlStatus | null): string {
    switch (value) {
      case AmlStatus.IN_PROGRESS:
        return 'Pågående';
      case AmlStatus.WAITING_COLLEAGUE:
        return 'Väntar / Förfrågan kollega';
      case AmlStatus.DONE_COLLEAGUE:
        return 'Klar / Förfrågan kollega';
      case AmlStatus.WAITING_COLLEAGUE_APPROVAL:
        return 'Väntar / Godkännande kollega';
      case AmlStatus.WAITING_CLIENT:
        return 'Väntar / Inhämta info från klient';
      case AmlStatus.DONE_CLIENT:
        return 'Klart / Inhämta info från klient';
      case AmlStatus.DECLINED:
        return 'Ej accepterad';
      case AmlStatus.DONE:
        return 'Accepterad';
      default:
        return 'Okänd status';
    }
  }
}
