import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateConvertPipe } from '@app/advisory-tool/pipes/date-convert.pipe';
import { UpdateReportWithCompanyName } from '@app/core/entity/advisory.notification';
import { CheckboxModule } from 'primeng/checkbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-advisory-last-updated',
  template: `
    <main class="flex flex-col">
      <section>
        <p class="!text-base !text-bl-blue-600 !m-0 !mb-4">Senast uppdaterade företag:</p>
        <p-scrollPanel id="last-updated" *ngIf="data" [style]="{ width: '100%', height: '107px' }">
          <div *ngFor="let update of data" class="pt-1">
            <div class="flex justify-between">
              <span class="text-xs truncate">
                {{ update.company_name }}
              </span>
              <div class="flex">
                <span *ngIf="update.update_state === 'completed'" class="text-xs text-bl-blue-400 mr-1">
                  {{ update.last_update | dateConvert }}
                </span>
                <span *ngIf="update.update_state === 'failed'" class="text-xs text-bl-blue-400">
                  Misslyckades, försök igen
                </span>
                <div>
                  <p-checkbox
                    name="group"
                    [value]="update"
                    [(ngModel)]="selectedUpdates"
                    [inputId]="update.company_key">
                  </p-checkbox>
                </div>
              </div>
            </div>
          </div>
        </p-scrollPanel>
      </section>
      <section class="flex justify-between items-center mr-5 mt-2">
        <span class="text-xs">Markerade företag för uppdatering: {{ selectedUpdates.length }}</span>
        <button
          [disabled]="selectedUpdates.length < 1"
          pTooltip="Klicka för att hämta nya notiser för markerade företag"
          tooltipPosition="bottom"
          class="btn btn-primary"
          (click)="triggerCompanyUpdate()">
          <i class="fa fas fa-sync"></i>
        </button>
      </section>
    </main>
  `,
  styleUrls: ['./advisory-last-updated.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, ScrollPanelModule, CheckboxModule, TooltipModule, DateConvertPipe],
})
export class AdvisoryLastUpdatedComponent {
  @Input() data: UpdateReportWithCompanyName[];
  @Output() triggerUpdate = new EventEmitter<UpdateReportWithCompanyName[]>();

  selectedUpdates: UpdateReportWithCompanyName[] = [];

  triggerCompanyUpdate(): void {
    this.triggerUpdate.emit(this.selectedUpdates);
    this.selectedUpdates = [];
  }
}
