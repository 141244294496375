import { Component } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivityComposite } from 'src/app/core/entity/activity.composite';
import { ActivityStateType, ActivityType, UserType, StateChangeEventType } from 'src/app/core/state/types';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  toActivityTypeTransformer,
  toActivtyStateTypeTransformer,
  toStateChangeEventTypeTransformer,
  toUserTypeTransformer,
} from '@app/core/state/transformers/transformers';
import { CommonModule } from '@angular/common';
import { TodayDate } from '@app/shared/misc/today.date';
import { openDialog } from '@app/shared/misc/openDialog';
import { filter, first } from 'rxjs';
import { Task } from '@app/core/entity/task';
import { ActivityService } from '@app/core/services/activity.service';
import { validateDateFn, validateMultiSelectRequiredFn, validateSelectRequiredFn } from '@app/core/entity/validation';
import { TaskDialog } from '../task-dialog/task.dialog';
import { BystTableModule } from '../table/table.module';
import { FormUserSelectorComponent } from '../forms/form-user-select/form-user-select.component';
import { CalendarComponent } from '../calendar/calendar.component';
import { FormActivityStateSelectorComponent } from '../forms/form-activity-states-selector/form-activity-states-selector.component';
import {
  DialogData,
  DialogForm,
  DialogResult,
  FormDialogBaseComponent,
} from '../form-dialog-base/form-dialog-base.component';
import { TableColumn } from '../table/interfaces/table-column';

interface Data extends DialogData {
  activityComposite: ActivityComposite;
}

interface Result extends DialogResult {
  activity: ActivityType;
}

interface Form extends DialogForm<any> {
  clientName: FormControl<string>;
  selectableStates: FormControl<ActivityStateType[]>;
  state: FormControl<ActivityStateType>;
  statusChangeDate: FormControl<string>;
  users: FormControl<UserType[]>;
  deadline: FormControl<string>;
  taskComment: FormControl<string>;
  taskType: FormControl<string>;
  spanLabel: FormControl<string>;
  comment: FormControl<string>;
  stateChangeEvents: FormControl<StateChangeEventType[]>;
}

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity.details.dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormActivityStateSelectorComponent,
    CalendarComponent,
    FormUserSelectorComponent,
    BystTableModule,
  ],
  providers: [DialogService],
})
export class ActivityDetailsDialog extends FormDialogBaseComponent<Data, Result, Form> {
  public columns: TableColumn[] = [
    { field: 'eventDate', header: 'Ändringsdatum', sortable: false },
    { field: 'state.description', header: 'Status', sortable: false },
    { field: 'changedBy', header: 'Ändrat av', sortable: false },
  ];

  constructor(
    protected override ref: DynamicDialogRef,
    protected override config: DynamicDialogConfig<Data>,
    private activityService: ActivityService,
  ) {
    super(ref, config);
  }

  protected override onInitialized = () => {
    const {
      activityComposite: { task, activity },
    } = this.config.data;

    this.form = this.builder.group<Form>({
      clientName: new FormControl(task.client.name),
      state: new FormControl(ActivityDetailsDialog.getState(task, activity.state.name), [
        validateSelectRequiredFn('En aktivitet måste ha en status'),
      ]),
      selectableStates: new FormControl(ActivityDetailsDialog.getSelectableStates(task)),
      statusChangeDate: new FormControl(new TodayDate().getTodayAsString(), [
        validateDateFn({ key: 'statusChangeDate' }),
      ]),
      users: new FormControl(ActivityDetailsDialog.getUsers(this.config.data.activityComposite), [
        validateMultiSelectRequiredFn('En tjänst måste ha en utförande handläggare'),
      ]),
      deadline: new FormControl(activity.deadline, [validateDateFn({ key: 'deadline' })]),
      taskComment: new FormControl(task.comment),
      taskType: new FormControl(task.type),
      spanLabel: new FormControl(activity.span.spanLabel),
      comment: new FormControl(activity.comment),
      stateChangeEvents: new FormControl(activity.stateChangeEvents.map(toStateChangeEventTypeTransformer.transform)),
    });
  };

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<unknown>, 'data'> {
    return {
      width: '750px',
      contentStyle: { overflow: 'auto' },
      closeOnEscape: false,
      closable: false,
      resizable: false,
      draggable: false,
      modal: true,
      showHeader: false,
    };
  }

  openTaskDialog() {
    openDialog(this.dialogService, TaskDialog, { task: this.config.data.activityComposite.task })
      .pipe(filter((result) => Boolean(result?.task)))
      .subscribe(({ task }) => this.onCloseTaskDialog(task));
  }

  onSubmit() {
    const { activity } = this.config.data.activityComposite;
    const updatedActivity = {
      ...toActivityTypeTransformer.transform(activity),
      state: this.form.controls.state.value,
      statusChangeDate: this.form.controls.statusChangeDate.value,
      deadline: this.form.controls.deadline.value,
      comment: this.form.controls.comment.value,
      users: this.form.controls.users.value,
    };

    this.close({ activity: updatedActivity });
  }

  private static getUsers(activityCompontents: ActivityComposite): UserType[] {
    const activityUsers = activityCompontents.activity.users.map(toUserTypeTransformer.transform);
    const exclusiveTaskUsers = activityCompontents.task.users
      .filter((user) => !activityUsers.find((u) => u.id === user.id))
      .map(toUserTypeTransformer.transform);

    return [...activityUsers, ...exclusiveTaskUsers];
  }

  private static getSelectableStates = (task: Task) =>
    task.selectableStates.filter((state) => Boolean(state)).map(toActivtyStateTypeTransformer.transform);

  private onCloseTaskDialog(task: Task) {
    this.config.data.activityComposite.task = task;
    this.form.controls.state.patchValue(ActivityDetailsDialog.getState(task, this.form.controls.state.value.name));
    this.form.controls.selectableStates.patchValue(ActivityDetailsDialog.getSelectableStates(task));
    this.form.controls.taskComment.patchValue(task.comment);
    this.form.controls.users.patchValue(ActivityDetailsDialog.getUsers(this.config.data.activityComposite));
    this.activityService
      .getActivity(this.config.data.activityComposite.activity.span.end, task.id)
      .pipe(
        first(),
        filter((activity) => Boolean(activity)),
      )
      .subscribe(({ deadline }) => {
        this.form.controls.deadline.patchValue(deadline);
      });
  }

  private static getState = (task: Task, stateName: string): ActivityStateType | null => {
    const selectableStates = ActivityDetailsDialog.getSelectableStates(task);
    return selectableStates.find((s) => s.name === stateName) ?? null;
  };
}
