export class TodayDate {
  public MONTHS = [
    'januari',
    'februari',
    'mars',
    'april',
    'maj',
    'juni',
    'juli',
    'augusti',
    'september',
    'oktober',
    'november',
    'december',
  ];

  public SHORT_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
  public QUARTERS = ['jan-mar', 'apr-jun', 'jul-sep', 'okt-dec'];
  public YEARS = ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'];

  // --Static functions --

  public static getIsoFormattedDate(date: Date) {
    if (!date) return null;
    const preZeroNumber = (number: number) => `0${number}`.substr(-2);
    return `${date.getFullYear()}-${preZeroNumber(date.getMonth() + 1)}-${preZeroNumber(date.getDate())}`;
  }

  // -- Instance functions --

  getCurrentMonthAsString() {
    const local = new Date();
    return this.MONTHS[local.getMonth()];
  }

  getRelevantMonthAsString(daysAgo: number): string {
    const local = new Date(new Date().getTime() - daysAgo * 24 * 60 * 60 * 1000).getMonth();
    return this.MONTHS[local];
  }

  getRelevantMonthAsNumber(daysAgo: number): number {
    return new Date(new Date().getTime() - daysAgo * 24 * 60 * 60 * 1000).getMonth();
  }

  getCurrentQuarterAsString() {
    const monthIndex = new Date().getMonth();
    if (monthIndex < 3) {
      return this.QUARTERS[0];
    }
    if (monthIndex < 6) {
      return this.QUARTERS[1];
    }
    if (monthIndex < 9) {
      return this.QUARTERS[2];
    }
    return this.QUARTERS[3];
  }

  getCurrentYearAsString() {
    return `${new Date().getFullYear()}`;
  }

  getLastYearAsString() {
    const year = new Date();
    year.setFullYear(new Date().getFullYear() - 1);
    return `${year.getFullYear()}`;
  }

  getRelevantYearAsString(monthsAgo: number) {
    const year = new Date();
    year.setMonth(new Date().getMonth() - monthsAgo);
    return `${year.getFullYear()}`;
  }

  getIndexOfMonth(month: string) {
    for (let i = 0; i < this.MONTHS.length; i += 1) {
      if (this.MONTHS[i] === month) {
        return i + 1;
      }
    }
    return 0;
  }

  getIndexOfQuarter(quarter: string) {
    for (let i = 0; i < this.QUARTERS.length; i += 1) {
      if (this.QUARTERS[i] === quarter) {
        return i + 1;
      }
    }
    return 0;
  }

  getTodayAsString() {
    const local = new Date();
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }

  getFirstDayOfCurrentYearAsString() {
    const local = new Date();
    local.setMonth(0);
    local.setDate(1);
    return local.toJSON().slice(0, 10);
  }

  getLastDayOfCurrentYearAsString() {
    const local = new Date();
    local.setMonth(11);
    local.setDate(31);
    return local.toJSON().slice(0, 10);
  }

  getFirstDayOfNextFinancialYearAsString(date: string) {
    const local = new Date(date);
    local.setDate(local.getDate() + 1);
    return local.toJSON().slice(0, 10);
  }

  getLastDayOfNextFinancialYearAsString(date: string) {
    const local = new Date(date);
    local.setFullYear(local.getFullYear() + 1);
    if (date.endsWith('02-29')) {
      // skottår...
      local.setDate(local.getDate() - 1);
    }
    if (this.isLeapYear(local.getFullYear()) && date.endsWith('02-28')) {
      local.setDate(local.getDate() + 1);
    }
    return local.toJSON().slice(0, 10);
  }

  private isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  getDefaultSearchFromDate() {
    const local = new Date();
    local.setMilliseconds(local.getMilliseconds() - 432000000);
    return local.toJSON().slice(0, 10);
  }

  getDefaultSearchToDate() {
    const local = new Date();
    local.setMilliseconds(local.getMilliseconds() + 1296000000);
    return local.toJSON().slice(0, 10);
  }

  public isValidDateString(dateString: string) {
    const regEx = /^(\d{4})([\/-])(\d{1,2})\2(\d{1,2})$/;
    return dateString.match(regEx);
  }

  public subtractDays(days: number, date: Date = new Date()) {
    const dateToTransform = new Date(date.getTime());
    dateToTransform.setDate(dateToTransform.getDate() - days);
    return dateToTransform;
  }

  public getIsoFormattedDate(date: Date): string {
    return TodayDate.getIsoFormattedDate(date);
  }
}
