export class AlertLevel {
  name = '';
  description = '';
}

export enum AlertLevelNames {
  COMPLETED = 'COMPLETED',
  WARNING = 'WARNING',
  REMINDER = 'REMINDER',
  OVERDUE = 'OVERDUE',
  STARTED = 'STARTED',
  INACTIVE = 'INACTIVE',
}
