import { Injectable } from '@angular/core';
import { NotesService } from '@app/core/services/notes.service';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { BaseEffects } from '../core/base.effects';
import { ListActions } from '../list/list.actions';
import { ListSelectors } from '../list/list.selectors';
import { NavigationPages } from '../navigation/navigation.selectors';
import { ToastActions } from '../toast/toast.actions';
import { TodoActions } from '../todo/todo.actions';
import { TodoListFilter, TodoListParams } from '../types';
import { AppState } from '../appState';
import { TodosActions } from './todos.actions';
import { TodoType } from '../types/todo.types';

@Injectable()
export class TodosEffects extends BaseEffects {
  loadTodosItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActions.load),
      this.whenCurrentUrl(NavigationPages.todo),
      map(({ filter, params }) => ListActions.load({ filter, params })),
    ),
  );

  loadTodosItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActions.load),
      this.whenCurrentUrl(NavigationPages.todo),
      switchMap(({ params }) =>
        this.notesService.getByDatesAndUserIdType(params).pipe(
          first(),
          map((items) => items.map(this.mapUsersToString)),
          map((items) => ListActions.loadSucceeded({ items })),
          this.takeUntilNavigationStart(),
          catchError((error: unknown) => of(ListActions.loadFailed({ error }))),
        ),
      ),
    ),
  );

  // Trigger reload of todos when a todo is added in seperate dialog
  singleTodoSavedSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodoActions.saveTodoSucceeded),
      this.whenCurrentUrl(NavigationPages.todo),
      concatLatestFrom(() => this.store.select(ListSelectors.getFilters<TodoListFilter, TodoListParams>())),
      map(([, { filter, params }]) => TodosActions.load({ filter, params })),
    ),
  );

  deleteTodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActions.todoDelete),
      this.whenCurrentUrl(NavigationPages.todo),
      switchMap(({ todo, predicate }) =>
        this.notesService.deleteTodoType(todo).pipe(
          mergeMap(() => [
            TodosActions.todoDeleteSucceeded({ todo }),
            ListActions.deleteItemInListSucceeded({ item: todo, predicate }),
          ]),
          catchError((error: unknown) => of(ListActions.deleteItemInListFailed({ error }))),
        ),
      ),
    ),
  );

  deleteStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActions.todoDelete),
      map(({ todo }) => ListActions.deleteItemInList({ item: todo })),
    ),
  );

  deleteTodoSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActions.todoDeleteSucceeded),
      map(() =>
        ToastActions.showInfoMessage({
          summary: 'Borttaget',
          detail: 'Noteringen / Att Göra-punkten har tagits bort',
        }),
      ),
    ),
  );

  updateStateOnTodo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActions.updateState),
      this.whenCurrentUrl(NavigationPages.todo),
      switchMap(({ state, todo, predicate }) => {
        const newState = todo.selectableStates.find((s) => s.name === state) || todo.selectableStates[0];
        const updatedTodo = { ...todo, state: newState };

        return this.notesService.addTodoType(updatedTodo).pipe(
          mergeMap((item) => [
            ToastActions.showInfoMessage({ summary: 'Sparad', detail: 'Status ändrad' }),
            ListActions.updateItemInListSucceeded({ item: this.mapUsersToString(item), predicate }),
          ]),
          catchError((error: unknown) => of(ListActions.updateItemInListFailed({ error }))),
        );
      }),
    ),
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActions.updateState),
      map(({ todo }) => ListActions.updateItemInList({ item: todo })),
    ),
  );

  constructor(actions$: Actions, store: Store<AppState>, private notesService: NotesService) {
    super(actions$, store);
  }

  private mapUsersToString(todo: TodoType) {
    return {
      ...todo,
      usernames: todo.users.map((user) => user.name).join(', '),
    };
  }
}
