import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { Client } from 'src/app/core/entity/client';
import { FinancialYear } from 'src/app/core/entity/financialyear';
import { getDateValidator } from 'src/app/core/entity/validation';
import { ValidationStatus } from 'src/app/core/entity/validationStatus';
import { FinancialYearService } from 'src/app/core/services/financialyear.service';
import { areMonthsBetweenFinancialYearsOk, isFirstDateAfterSecondDate } from 'src/app/shared/misc/dates';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { AppState } from '@app/core/state/appState';
import { TodayDate } from 'src/app/shared/misc/today.date';
import { TableColumn } from '../../../../table/interfaces/table-column';
import { TableHeader } from '../../../../table/interfaces/table-header';
import { CLIENT_TYPE_LIMITED_COMPANY } from '@app/shared/misc/constants';
import { DialogService } from 'primeng/dynamicdialog';
import { EditFinancialYearDialogComponent } from './edit-financialyear-dialog.component';
import { openDialogWithConfig } from '@app/shared/misc/openDialog';
import { toFinancialYearsTransformer } from '@app/core/state/transformers/transformers';

@Component({
  selector: 'app-client-financialyears',
  templateUrl: './client.financialyears.component.html',
  providers: [FinancialYearService, DialogService],
})
export class ClientFinancialYearsComponent implements OnInit {
  @Output() update = new EventEmitter<FinancialYear[]>();

  private _client: Client;
  public financialYearModel: FinancialYear = new FinancialYear();
  public clientFinancialYears: FinancialYear[];
  public selectedFinancialYear: FinancialYear;
  public showAddFinancialYear = false;
  public columns: TableColumn[] = [
    { field: 'span.start', header: 'Startdatum' },
    { field: 'span.end', header: 'Slutdatum' },
    { field: 'largeCompany', header: 'Stort företag' },
    { field: 'euCommerce', header: 'EU-handel' },
    { field: 'electronicVat', header: 'Elektronisk moms' },
    { field: 'electronicCompilation', header: 'Elektronisk komp' },
    { field: 'firmTaxRespite', header: 'Skattrespit' },
    { field: 'electronicTax', header: 'Elektronisk inkomstdeklaration' },
    { field: 'action', header: '', width: '40px', cellStyle: 'padding-right:0', sortable: false },
  ];

  public headerConfig: TableHeader[] = [
    {
      columns: [
        { field: '', header: 'Räkenskapsår', colspan: 2, class: 'text-center white-space-normal' },
        { field: '', header: 'Arbgiv.- / moms.dekl', class: 'text-center white-space-normal' },
        { field: '', header: 'Momsdeklaration', colspan: 2, class: 'text-center white-space-normal' },
        { field: '', header: 'Periodisk samman- ställning', class: 'text-center white-space-normal' },
        { field: '', header: 'Inkomstdeklaration', colspan: 2, class: 'text-center white-space-normal' },
        { field: '', header: '', rowspan: 2, width: '30px' },
      ],
    },
    {
      columns: [
        { field: '', header: 'F.o.m', class: 'text-center' },
        { field: '', header: 'T.o.m', class: 'text-center' },
        { field: '', header: 'Inlämning som stort företag', class: 'text-center white-space-normal' },
        { field: '', header: 'Haft EU-handel', class: 'text-center white-space-normal' },
        { field: '', header: 'Lämnar elektroniskt', class: 'text-center white-space-normal' },
        { field: '', header: 'Lämnar elektroniskt', class: 'text-center white-space-normal' },
        { field: '', header: 'Byråanstånd', class: 'text-center white-space-normal' },
        { field: '', header: 'Lämnas elektroniskt', class: 'text-center white-space-normal' },
      ],
    },
  ];

  @Input()
  set client(client: Client) {
    this.clientFinancialYears = [];
    this.reset();
    if (client) {
      this._client = client;
      if (client.id !== -1) {
        this.initComponent();
      }
    }
  }

  get client() {
    return this._client;
  }

  private _defaultFinancialYear: FinancialYear;

  @Input()
  set defaultFinancialYear(value: FinancialYear) {
    this._defaultFinancialYear = value;
    this.setDateDefaults();
  }

  constructor(
    private financialYearService: FinancialYearService,
    private store: Store<AppState>,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.initComponent();
  }

  private initComponent() {
    if (this._client && this._client.id !== -1) {
      this.loadFinancialYears().subscribe();
    }
  }

  private loadFinancialYears(): Observable<FinancialYear[]> {
    this.financialYearModel = new FinancialYear();
    return this.financialYearService
      .getFinancialYearsForClientId(this._client.id)
      .pipe(tap((fys) => this.applyFinancialYears(fys)));
  }

  private applyFinancialYears(fys: FinancialYear[]) {
    this.clientFinancialYears = fys;
    this.setDateDefaults();
  }

  saveFinancialYear() {
    this.financialYearModel.clientId = this._client.id;

    if (!this.validateDate(this.financialYearModel.span.start, 'Startdatum')) {
      return;
    }

    if (!this.validateDate(this.financialYearModel.span.end, 'Slutdatum')) {
      return;
    }

    const isStartAfterEnd = isFirstDateAfterSecondDate(
      this.financialYearModel.span.start,
      this.financialYearModel.span.end,
    );
    if (isStartAfterEnd) {
      this.store.dispatch(
        ToastActions.showErrorMessage({
          summary: 'Startdatum efter slutdatum',
          detail: 'Startdatum måste vara före slutdatum',
        }),
      );
      return;
    }

    const diff = areMonthsBetweenFinancialYearsOk(this.financialYearModel.span.start, this.financialYearModel.span.end);
    if (!diff) {
      this.store.dispatch(
        ToastActions.showErrorMessage({
          summary: 'Felaktigt datumintervall',
          detail: 'Intervallet mellan start och slutdatum överskrider 18 månader',
        }),
      );
    }
    this.financialYearService
      .saveFinancialYear(this.financialYearModel)
      .pipe(switchMap(() => this.loadFinancialYears()))
      .subscribe(() => {
        this.showAddFinancialYear = false;
        this.store.dispatch(ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Räkenskapsåret är sparat' }));
        this.update.emit(this.clientFinancialYears);
      });
  }

  deleteFinancialYear(id: number) {
    this.financialYearService
      .deleteFinancialYearWithId(id)
      .pipe(switchMap(() => this.loadFinancialYears()))
      .subscribe(() => {
        this.store.dispatch(ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Räkenskapsåret är borttaget' }));
        this.update.emit(this.clientFinancialYears);
      });
  }

  onRowSelect(event: { data: any }) {
    this.financialYearModel = JSON.parse(JSON.stringify(event.data));

    openDialogWithConfig(this.dialogService, EditFinancialYearDialogComponent, {
      data: event.data,
      header: `${event.data.span.start} - ${event.data.span.end}`,
      width: '650px',
    })
      .pipe(filter((res) => !!res))
      .subscribe((res) => {
        this.financialYearModel = toFinancialYearsTransformer.transform(res);
        this.saveFinancialYear();
      });
  }

  reset() {
    this.selectedFinancialYear = undefined;
    this.financialYearModel = new FinancialYear();
    this.setDateDefaults();
  }

  setDateDefaults() {
    if (this.clientFinancialYears.length > 0) {
      const fy = this.clientFinancialYears[0];
      this.financialYearModel.span = {
        start: new TodayDate().getFirstDayOfNextFinancialYearAsString(fy.span.end),
        end: new TodayDate().getLastDayOfNextFinancialYearAsString(fy.span.end),
      };
      this.financialYearModel.largeCompany = fy.largeCompany;
      this.financialYearModel.euCommerce = fy.euCommerce;
      this.financialYearModel.electronicVat = fy.electronicVat;
      this.financialYearModel.electronicCompilation = fy.electronicCompilation;
      this.financialYearModel.electronicTax = fy.electronicTax;
      this.financialYearModel.firmVatRespite = fy.firmVatRespite;
      this.financialYearModel.firmTaxRespite = fy.firmTaxRespite;
    } else {
      if (this._defaultFinancialYear) {
        this.financialYearModel.span = this._defaultFinancialYear.span;
      } else {
        this.financialYearModel.span = {
          start: new TodayDate().getFirstDayOfCurrentYearAsString(),
          end: new TodayDate().getLastDayOfCurrentYearAsString(),
        };
      }
    }
  }

  checkIfTaxRespiteIsPossible(checked: any) {
    if (
      checked &&
      (this._client.type.name === CLIENT_TYPE_LIMITED_COMPANY ||
        this._client.type.name === 'PARTNERSHIP' ||
        this._client.type.name === 'NON_PROFIT_ASSOCIATION')
    ) {
      this.store.dispatch(
        ToastActions.showWarnMessage({
          summary: 'Ej tillåtet',
          detail: 'Juridiska personer får inte söka byråanstånd',
        }),
      );
      setTimeout(() => {
        this.financialYearModel.firmTaxRespite = false;
      }, 500);
    }
  }

  private validateDate(value: string, label: string): boolean {
    const { validate, min, max } = getDateValidator();
    const { status, title, text } = validate(value, { min, max, label });

    if (status !== ValidationStatus.Ok) {
      this.store.dispatch(ToastActions.showWarnMessage({ summary: title, detail: text }));
      return false;
    }

    return true;
  }

  toggleAddFinancialYear() {
    this.showAddFinancialYear = !this.showAddFinancialYear;
  }
}
