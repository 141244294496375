import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { ColumnFilter } from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BulkService } from 'src/app/core/services/bulk.service';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { AppState } from '@app/core/state/appState';
import { TableColumn } from 'src/app/shared/components/table/interfaces/table-column';
import { BulkItemDirective } from '../directive/bulk-item.directive';
import { StateFacade } from '../facades/state.facade';
import { BulkComponentFactory } from '../factories/bulk-component.factory';
import { TableFilterFactory } from '../factories/table-filter.factory';
import {
  BulkActionType,
  BulkAddActionTypeList,
  BulkChangeActionTypeList,
  BulkRemoveActionTypeList,
  BulkType,
} from '../interfaces/bulk-change-action-type.enum';
import {
  BulkChangeEvent,
  ExecuteChangeServerResponse,
  IBulkChangeComponent,
} from '../interfaces/bulk-change.component';

@Component({
  selector: 'app-bulk-change',
  templateUrl: './bulk-change.component.html',
  styles: [
    `
      .bulk-item-container,
      .bulk-item-container > ng-component > div {
        display: inline;
      }

      .p-columnFilter {
        padding: 0;
        border: none;
        background-color: transparent;
      }
    `,
  ],
  providers: [BulkComponentFactory, TableFilterFactory],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkChangeComponent implements OnInit, OnDestroy {
  @ViewChild(BulkItemDirective) bulkItemHost!: BulkItemDirective;
  @ViewChildren(ColumnFilter) columnFilters: ColumnFilter[];

  // Stänger filter-popupen (om den är öppen) om man scrollar på sidan (och inte i tabellen).
  // Filter-popupen lägger sig på "body" nivån och följer med sidan upp/ner om man scrollar.
  @HostListener('document:scroll')
  onDocumentScroll() {
    if (this.columnFilters?.length > 0) {
      this.columnFilters.forEach((columnFilter) => {
        if (columnFilter.overlayVisible) {
          columnFilter.hide();
        }
      });
    }
  }

  @Input()
  set active(isActive: boolean) {
    if (!isActive) {
      this.ngOnDestroy();
    }
  }

  @Input() actionSelectPlaceholder: string = 'Välj vad du vill ändra';

  @Input()
  set type(value: BulkType) {
    this.currentBulkType = value;
    if (value === BulkType.Add) {
      this.changeActionTypes = BulkAddActionTypeList;
      return;
    }

    if (value === BulkType.Remove) {
      this.changeActionTypes = BulkRemoveActionTypeList;
      return;
    }

    this.changeActionTypes = BulkChangeActionTypeList;
  }

  public changeActionTypes: { label: string; key: string }[];
  public selectedActionType: BulkActionType;
  public tableColumns: TableColumn[] | undefined;
  public tableData: any[] | undefined;
  public tableSelectedRows: any[] = [];
  public dataToChange: any;
  public dataIdentityKey: string;
  public componentState$: Observable<{ loading: boolean; valid: boolean }>;
  public dropdownMatchModeOptions: SelectItem[];
  public multiSelectDropdownMatchModeOptions: SelectItem[];

  private currentBulkType: BulkType;
  private componentChangeSubscription!: Subscription;
  private currentSelectedComponent: IBulkChangeComponent;

  constructor(
    private bulkComponentFactory: BulkComponentFactory,
    private stateFacade: StateFacade,
    private bulkService: BulkService,
    private changeDetectorRef: ChangeDetectorRef,
    private tableFilterFactory: TableFilterFactory,
    private store: Store<AppState>,
  ) {
    this.componentState$ = this.stateFacade.state$.pipe(
      map((state) => ({ loading: state.loading, valid: state.componentValid })),
    );
  }

  ngOnInit(): void {
    this.setupCustomFilterForDropdown();
    this.setupCustomFilterForMultiSelectDropdown();
  }

  ngOnDestroy(): void {
    this.unsubscribeComponentChange();
    this.clearTable();
    this.selectedActionType = undefined;
    this.stateFacade.reset();
    this.bulkItemHost?.viewContainerRef.clear();
  }

  public onActionTypeSelected() {
    this.loadComponent(this.selectedActionType);
  }

  public loadTableClicked() {
    this.currentSelectedComponent.loadData();
  }

  public executeChange() {
    const fieldsValidated = this.currentSelectedComponent.validateFields();
    if (!fieldsValidated) {
      return;
    }

    const postData = {
      actionType: this.selectedActionType,
      changes: this.dataToChange,
      rows: this.tableSelectedRows.map((row) => row[this.dataIdentityKey]),
    };

    this.bulkService
      .change(postData)
      .pipe(
        map((response: ExecuteChangeServerResponse) =>
          this.currentSelectedComponent.afterExecuteChange(this.tableData, response),
        ),
      )
      .subscribe(({ hasError, data }) => {
        this.tableSelectedRows = [];
        this.tableData = data;

        this.changeDetectorRef.markForCheck();

        if (!hasError) {
          this.store.dispatch(ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Ändringarna har utförts' }));
          this.currentSelectedComponent.loadData();
          return;
        }

        const detail = this.getDetailedErrorMessage(this.currentBulkType);
        this.store.dispatch(ToastActions.showErrorMessage({ summary: 'Ett fel har uppstått', detail }));
      });
  }

  public getCellData(rowData: any, col: TableColumn) {
    const fieldValue = col.field
      .split('.')
      .reduce((prevObject, currentKey) => prevObject && prevObject[currentKey], rowData);
    if (col.fieldValue) {
      return col.fieldValue(fieldValue, rowData);
    }
    return fieldValue;
  }

  public rowTrackBy() {
    const me = this;
    return (_index: number, rowData: Record<string, any>) => {
      return rowData[me.dataIdentityKey];
    };
  }

  private loadComponent(selectedActionType: BulkActionType) {
    const viewContainerRef = this.bulkItemHost.viewContainerRef;

    // reset state
    this.clearTable();
    this.unsubscribeComponentChange();
    this.stateFacade.reset();

    this.currentSelectedComponent = this.bulkComponentFactory.getComponentInstanceByType(
      viewContainerRef,
      selectedActionType,
    );

    this.componentChangeSubscription = this.stateFacade.stateComponentValue$.subscribe((value: BulkChangeEvent) => {
      if (value === null) {
        this.clearTable();
        return;
      }

      this.tableColumns = value.tableColumns;
      this.tableData = value.tableData;
      this.dataToChange = value.dataToChange;
      this.dataIdentityKey = value.dataIdentityKey;
    });
  }

  private clearTable() {
    this.tableSelectedRows = [];
    this.tableData = undefined;
    this.tableColumns = undefined;
    this.dataToChange = undefined;
    this.dataIdentityKey = undefined;
  }

  private unsubscribeComponentChange() {
    if (this.componentChangeSubscription) {
      this.componentChangeSubscription.unsubscribe();
    }
  }

  private setupCustomFilterForDropdown() {
    const customFilterName = 'custom-dropdown-equals';

    this.tableFilterFactory.registerFilter(
      customFilterName,
      this.tableFilterFactory.createCustomDropdownFilterFunction(),
    );

    this.dropdownMatchModeOptions = [
      {
        label: 'Custom dropdown filter',
        value: customFilterName,
      },
    ];
  }

  private setupCustomFilterForMultiSelectDropdown() {
    const customFilterEqualsName = 'custom-multiselect-dropdown-equals';

    this.tableFilterFactory.registerFilter(
      customFilterEqualsName,
      this.tableFilterFactory.createCustomMultiSelectFilterFunction(),
    );

    this.multiSelectDropdownMatchModeOptions = [
      {
        label: 'Custom multiselect dropdown filter Equals',
        value: customFilterEqualsName,
      },
    ];
  }

  private getDetailedErrorMessage(type: BulkType) {
    if (type === BulkType.Add) {
      return 'En eller flera rader kunde inte läggas till';
    }

    if (type === BulkType.Remove) {
      return 'En eller flera rader kunde inte tas bort';
    }

    return 'En eller flera rader kunde inte ändras';
  }
}
