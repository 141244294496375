import { createActionGroup, props } from '@ngrx/store';
import { TaskType } from '../types';

export const TaskTemplateActions = createActionGroup({
  source: 'Task Template',
  events: {
    Load: props<{ taskGroupId?: number }>(),
    'Load Succeeded': props<{ templates: TaskType[] }>(),
    'Load Failed': props<{ error: any }>(),

    Save: props<{ template: TaskType }>(),
    'Save Succeeded': props<{ template: TaskType }>(),
    'Save Failed': props<{ error: any }>(),

    Archive: props<{ template: TaskType }>(),
    'Archive Succeeded': props<{ template: TaskType }>(),
    'Archive Failed': props<{ error: any }>(),

    Activate: props<{ template: TaskType }>(),
    'Activate Succeeded': props<{ template: TaskType }>(),
    'Activate Failed': props<{ error: any }>(),
  },
});
