import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CompositeListComponent } from '@app/shared/components/composite-list/composite-list.component';
import {
  AccountingCompositeType,
  AccountingListFilter,
  AccountingListParams,
  CompositeType,
} from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';
import { TodayDate } from '@app/shared/misc/today.date';
import { getRelevantYearAsString } from '@app/shared/misc/dates';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { ListSelectors } from '@app/core/state/list/list.selectors';
import { isAllCompositeColumnTypeObjectsDone } from '@app/core/state/shared/shared';

@Component({
  selector: 'app-accounting-list',
  templateUrl: './accounting.list.component.html',
  styles: [
    `
      .ml-1-important {
        margin-left: 0.25rem !important;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AccountingListComponent
  extends CompositeListComponent<AccountingCompositeType, AccountingListFilter, AccountingListParams>
  implements OnInit, OnDestroy
{
  filter: { periodicy: string; includeAllDoneClients: boolean };
  urlFilter: {
    searchCriteria: number;
    selectedMonth: string;
    selectedYear: string;
    selectedClientResponsibleUserId: number;
    selectedTaskUserId: number;
  };

  columns: TableColumn[] = [
    { field: 'client', header: 'Klient' },
    { field: 'corporateIdentity', header: 'Orgnr', width: '7%' },
    { field: 'customerNumber', header: 'Kundnr', width: '4%' },
    { field: 'responsible', header: 'Klientansv', width: '5%' },
    { field: 'mainSpan.spanType', header: 'Intervall', width: '6%' },
    { field: 'mainSpan.spanLabel', header: 'Period', width: '6%' },
    { field: 'accountingMaterial.when', header: 'Material (Bokföring)', width: '10%' },
    {
      field: 'dags.activity.alertLevel.description',
      header: 'Dagskassa',
      width: '9%',
      visible: false,
      fieldValue: (_fieldValue, rowData) => rowData?.dags?.activity?.state?.description,
      additionalFilterFields: ['dags.activity.state.description'],
    },
    {
      field: 'bokf.activity.alertLevel.description',
      header: 'Bokföring',
      width: '12%',
      fieldValue: (_fieldValue, rowData) => rowData?.bokf?.activity?.state?.description,
      additionalFilterFields: ['bokf.activity.state.description'],
    },
    {
      field: 'kont.activity.alertLevel.description',
      header: 'Kont/Stans',
      width: '9%',
      visible: false,
      fieldValue: (_fieldValue, rowData) => rowData?.kont?.activity?.state?.description,
      additionalFilterFields: ['kont.activity.state.description'],
    },
    {
      field: 'avst.activity.alertLevel.description',
      header: 'Avstämning',
      width: '12%',
      fieldValue: (_fieldValue, rowData) => rowData?.avst?.activity?.state?.description,
      additionalFilterFields: ['avst.activity.state.description'],
    },
    {
      field: 'rapp.activity.alertLevel.description',
      header: 'Rapport till klient',
      width: '12%',
      fieldValue: (_fieldValue, rowData) => rowData?.rapp?.activity?.state?.description,
      additionalFilterFields: ['rapp.activity.state.description'],
    },
    { field: 'taskUsers', header: 'UH', width: '5%', visible: false },
    { field: 'program', header: 'Program', width: '70px', sortable: false, exportable: false },
  ];

  accountingSelector: MemoizedSelector<AppState, { composites: AccountingCompositeType[]; count: number }>;

  loadUserSettings(): void {
    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.ACCOUNTING_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );

    this.filter = {
      periodicy: this.uss.loadSetting(UserSettingsStorage.ACCOUNTING_FILTER_CRITERIA, '- Alla -'),
      includeAllDoneClients: this.uss.loadSettingAsBoolean(
        UserSettingsStorage.ACCOUNTING_INCLUDE_ALL_DONE_CLIENTS,
        true,
      ),
    };

    this.urlFilter = {
      searchCriteria: this.uss.loadSettingAsNumber(UserSettingsStorage.ACCOUNTING_CRITERIA, 2),
      selectedMonth: this.uss.loadSetting(
        UserSettingsStorage.ACCOUNTING_MONTH,
        new TodayDate().getRelevantMonthAsString(45),
      ),
      selectedYear: this.uss.loadSetting(UserSettingsStorage.ACCOUNTING_YEAR, getRelevantYearAsString(45)),
      selectedClientResponsibleUserId: this.uss.loadSettingAsNumber(
        UserSettingsStorage.ACCOUNTING_CLIENT_RESPONSIBLE_ID,
        0,
      ),
      selectedTaskUserId: this.uss.loadSettingAsNumber(UserSettingsStorage.ACCOUNTING_USER_ID, 0),
    };
  }

  saveUserSettings(): void {
    this.uss.saveSetting(UserSettingsStorage.ACCOUNTING_FILTER_CRITERIA, this.filter.periodicy);
    this.uss.saveSetting(
      UserSettingsStorage.ACCOUNTING_INCLUDE_ALL_DONE_CLIENTS,
      `${this.filter.includeAllDoneClients}`,
    );

    this.uss.saveSetting(UserSettingsStorage.ACCOUNTING_CRITERIA, `${this.urlFilter.searchCriteria}`);
    this.uss.saveSetting(UserSettingsStorage.ACCOUNTING_MONTH, this.urlFilter.selectedMonth);
    this.uss.saveSetting(UserSettingsStorage.ACCOUNTING_YEAR, this.urlFilter.selectedYear);
    this.uss.saveSetting(
      UserSettingsStorage.ACCOUNTING_CLIENT_RESPONSIBLE_ID,
      `${this.urlFilter.selectedClientResponsibleUserId}`,
    );
    this.uss.saveSetting(UserSettingsStorage.ACCOUNTING_USER_ID, `${this.urlFilter.selectedTaskUserId}`);
  }

  getFilterValue() {
    return { includeAllDoneClients: this.filter.includeAllDoneClients, periodicy: this.filter.periodicy };
  }

  getParamsValue() {
    return {
      executorId: this.urlFilter.selectedTaskUserId,
      responsibleId: this.urlFilter.selectedClientResponsibleUserId,
      searchCriteria: this.urlFilter.searchCriteria,
      year: this.urlFilter.selectedYear,
      periodIndex: new TodayDate().getIndexOfMonth(this.urlFilter.selectedMonth),
    };
  }

  getPaginatorTriggeredKey() {
    return UserSettingsStorage.ACCOUNTING_NUMBER_OF_ROWS;
  }

  getCompositeType() {
    return CompositeType.accounting;
  }

  protected override getSelector() {
    if (this.accountingSelector) {
      return this.accountingSelector;
    }

    this.accountingSelector = createSelector(
      ListSelectors.getItems<AccountingCompositeType, AccountingListFilter, AccountingListParams>(),
      ({ items, filter: { includeAllDoneClients, periodicy } }) => {
        const composites = items.filter((composite) => {
          const isAllChoosen = periodicy === '- Alla -';
          const isWithinPeriod = isAllChoosen || composite.mainSpan.spanType === periodicy;
          const isDone = includeAllDoneClients || !isAllCompositeColumnTypeObjectsDone(composite);
          return isDone && isWithinPeriod;
        });

        const count = items.filter((composite) => {
          const isAllChoosen = periodicy === '- Alla -';
          const isWithinPeriod = isAllChoosen || composite.mainSpan.spanType === periodicy;
          return isAllCompositeColumnTypeObjectsDone(composite) && isWithinPeriod;
        }).length;
        return { composites, count };
      },
    );

    return this.accountingSelector;
  }
}
