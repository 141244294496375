import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { FirmType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { FirmActions } from '@app/core/state/firm/firm.actions';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { Router } from '@angular/router';
import { openDialog } from '@app/shared/misc/openDialog';
import { AdminPrivilegeDirective } from '@app/shared/components/admin-privilege/admin-privilege.directive';
import { FirmResetDialog } from './firm-reset.dialog';

@Component({
  selector: 'app-firm-reset',
  template: `
    <div *appAdminPrivilege class="w-full flex flex-col items-center">
      <h2 class="w-1/2">Funktioner för byråledaren:</h2>
      <div class="flex flex-col border px-4 py-4 border-bl-red-500 w-1/2">
        <div class="flex justify-between items-center py-4">
          <div>
            <strong>Radera tjänster</strong>
            <p>Tar bort alla tjänster och aktiviteter på byrån.</p>
            <p>Denna åtgärd går inte att ångra.</p>
            <p>Efter raderingen kommer du att loggas ut.</p>
          </div>
          <p-button label="Radera tjänster" [outlined]="true" severity="danger" (onClick)="onResetTasks()"> </p-button>
        </div>
        <div class="flex justify-between items-center py-4">
          <div>
            <strong>Radera byrån</strong>
            <p>Tar bort allting på byrån, inklusive klienter.</p>
            <p>Denna åtgärd går inte att ångra.</p>
            <p>Efter raderingen kommer du att loggas ut.</p>
          </div>
          <p-button label="Radera byrån" [outlined]="true" severity="danger" (onClick)="onResetAll()"> </p-button>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  providers: [DialogService],
  imports: [CommonModule, ButtonModule, AdminPrivilegeDirective],
})
export class FirmResetComponent implements OnDestroy {
  @Input() closeCallback: () => void;
  currentFirmSub: BehaviorSubject<FirmType>;

  private onDestroySub: Subject<void>;

  constructor(private store: Store<AppState>, private dialogService: DialogService, private router: Router) {
    this.onDestroySub = new Subject();
    this.currentFirmSub = new BehaviorSubject({} as FirmType);
    this.store.select(FirmSelectors.selectFirm).pipe(takeUntil(this.onDestroySub)).subscribe(this.currentFirmSub);
  }

  ngOnDestroy(): void {
    this.onDestroySub.next();
    this.onDestroySub.complete();
  }

  onResetTasks = () => {
    openDialog(this.dialogService, FirmResetDialog, {
      body: 'Denna åtgärd kan inte göras ogjord. Det här kommer att permanent radera samtliga uppdragsmallar, tjänster, aktiviteter samt deras historik och du kommer att loggas ut.',
      header: 'Innan du raderar tjänsterna måste du läsa nedanstående!',
      buttonText: 'Jag förstår konsekvenserna, radera alla tjänster',
      confirmText: 'radera alla tjänster',
      actionToDispatch: FirmActions.resetFirmTasks({ firm: this.currentFirmSub.value }),
      actionOnSuccess: FirmActions.resetFirmSucceeded,
      actionOnFail: FirmActions.resetFirmFailed,
    })
      .pipe(filter((data) => Boolean(data)))
      .subscribe(() => {
        this.closeCallback();
        this.router.navigate(['login']);
      });
  };

  onResetAll = () => {
    openDialog(this.dialogService, FirmResetDialog, {
      body: 'Denna åtgärd kan inte göras ogjord. Det här kommer att permanent radera samtliga uppdragsmallar, tjänster, aktiviteter, klienter, att göra poster, byrån och du kommer att loggas ut',
      header: 'Innan du raderar byrån måste du läsa nedanstående!',
      buttonText: 'Jag förstår konsekvenserna, radera byrån',
      confirmText: 'radera byrån',
      actionToDispatch: FirmActions.resetFirmAll({ firm: this.currentFirmSub.value }),
      actionOnSuccess: FirmActions.resetFirmSucceeded,
      actionOnFail: FirmActions.resetFirmFailed,
    })
      .pipe(filter((data) => Boolean(data)))
      .subscribe(() => {
        this.closeCallback();
        this.router.navigate(['login']);
      });
  };
}
