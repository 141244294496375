<section>
  <h4>Uppgifter som föreslås till uppdragsavtalet</h4>
  <span>På uppdragsavtal tillämpar byrån de allmänna villkoren från:</span>
  <form [formGroup]="assignmentSettingsForm" (ngSubmit)="updateAssignmentSettingsData()">
    <div class="flex flex-row items-center mb-4">
      <div class="relative flex items-center w-1/2 mr-6">
        <input
          (change)="onSrfOrFarToggle($event.target)"
          class="firm_checkbox"
          type="checkbox"
          [checked]="firmAssignmentData.srf_or_far === 'srf'"
          id="srf" />
        <input pInputText class="w-full pl-12-important" type="text" formControlName="srf_text" />
      </div>
      <div class="relative flex items-center w-1/2">
        <input
          (change)="onSrfOrFarToggle($event.target)"
          class="firm_checkbox"
          type="checkbox"
          [checked]="firmAssignmentData.srf_or_far === 'far'"
          id="far" /><input pInputText class="w-full pl-12-important" type="text" formControlName="far_text" />
      </div>
    </div>
    <div class="w-1/2 pr-3">
      <div class="flex flex-col mb-4">
        <label class="leading-tight text-bl-grey" for="notice">Uppsägningstid</label>
        <input pInputText class="w-full pl-2" type="text" id="notice" formControlName="notice_period" />
      </div>
    </div>
    <div class="flex flex-row w-full mb-4">
      <div class="flex flex-col w-1/2 mr-6">
        <label class="leading-tight text-bl-grey" for="records-delivery">Materialleverans</label>
        <div class="mb-2">
          <input pInputText class="w-full pl-2 mb-2" type="text" formControlName="material_delivery1" />
        </div>
        <div class="mb-2">
          <input pInputText class="w-full pl-2 mb-2" type="text" formControlName="material_delivery2" />
        </div>
        <div class="mb-2">
          <input pInputText class="w-full pl-2 mb-2" type="text" formControlName="material_delivery3" />
        </div>
        <input pInputText class="w-full pl-2" type="text" formControlName="material_delivery4" />
      </div>
      <div class="flex flex-col w-1/2">
        <label class="leading-tight text-bl-grey">Betalningsvillkor</label>
        <div class="relative flex items-center mb-2">
          <input
            class="firm_checkbox"
            type="checkbox"
            id="show_payment_condition_text1"
            [checked]="firmAssignmentData.show_payment_condition_text1 === 'true'"
            (change)="onCheckboxChange($event.target)" /><input
            pInputText
            class="w-full pl-12-important"
            type="text"
            formControlName="payment_condition_text1" />
        </div>
        <div class="relative flex items-center mb-2">
          <input
            class="firm_checkbox"
            type="checkbox"
            name="terms"
            id="show_payment_condition_text2"
            [checked]="firmAssignmentData.show_payment_condition_text2 === 'true'"
            (change)="onCheckboxChange($event.target)" /><input
            pInputText
            class="w-full pl-12-important"
            type="text"
            formControlName="payment_condition_text2" />
        </div>
        <div class="relative flex items-center mb-2">
          <input
            class="firm_checkbox"
            type="checkbox"
            name="terms"
            id="show_payment_condition_text3"
            [checked]="firmAssignmentData.show_payment_condition_text3 === 'true'"
            (change)="onCheckboxChange($event.target)" /><input
            pInputText
            class="w-full pl-12-important"
            type="text"
            formControlName="payment_condition_text3" />
        </div>
        <div class="relative flex items-center">
          <input
            class="firm_checkbox"
            type="checkbox"
            name="terms"
            id="show_payment_condition_text4"
            [checked]="firmAssignmentData.show_payment_condition_text4 === 'true'"
            (change)="onCheckboxChange($event.target)" /><input
            pInputText
            class="w-full pl-12-important"
            type="text"
            formControlName="payment_condition_text4" />
        </div>
      </div>
    </div>
    <div class="flex flex-row w-full mb-4">
      <div class="flex flex-col w-1/2 mr-4">
        <label class="leading-tight text-bl-grey" for="pricing">Ersättning</label>
        <div class="relative flex items-center mb-2">
          <input
            class="firm_checkbox"
            type="checkbox"
            id="show_payment_text1"
            [checked]="firmAssignmentData.show_payment_text1 === 'true'"
            (change)="onCheckboxChange($event.target)" /><input
            pInputText
            class="w-full pl-12-important"
            type="text"
            formControlName="payment_text1" />
        </div>
        <div class="relative flex items-center mb-2">
          <input
            class="firm_checkbox"
            type="checkbox"
            id="show_payment_text2"
            [checked]="firmAssignmentData.show_payment_text2 === 'true'"
            (change)="onCheckboxChange($event.target)" /><input
            pInputText
            class="w-full pl-12-important"
            type="text"
            formControlName="payment_text2" />
        </div>
        <div class="relative flex items-center mb-2">
          <input
            class="firm_checkbox"
            type="checkbox"
            id="show_payment_text3"
            [checked]="firmAssignmentData.show_payment_text3 === 'true'"
            (change)="onCheckboxChange($event.target)" /><input
            pInputText
            class="w-full pl-12-important"
            type="text"
            formControlName="payment_text3" />
        </div>
        <div class="relative flex items-center mb-2">
          <input
            class="firm_checkbox"
            type="checkbox"
            id="show_payment_text4"
            [checked]="firmAssignmentData.show_payment_text4 === 'true'"
            (change)="onCheckboxChange($event.target)" /><input
            pInputText
            class="w-full pl-12-important"
            type="text"
            formControlName="payment_text4" />
        </div>
      </div>
      <div class="flex flex-col w-1/2">
        <label class="leading-tight text-bl-grey">Bilagor</label>
        <div class="relative flex items-center mb-2">
          <input
            class="firm_checkbox"
            type="checkbox"
            id="show_attachment_text1"
            [checked]="firmAssignmentData.show_attachment_text1 === 'true'"
            (change)="onCheckboxChange($event.target)" /><input
            pInputText
            class="w-full pl-12-important"
            type="text"
            formControlName="attachment_text1" />
        </div>
        <div class="relative flex items-center mb-2">
          <input
            class="firm_checkbox"
            type="checkbox"
            id="show_attachment_text2"
            [checked]="firmAssignmentData.show_attachment_text2 === 'true'"
            (change)="onCheckboxChange($event.target)" /><input
            pInputText
            class="w-full pl-12-important"
            type="text"
            formControlName="attachment_text2" />
        </div>
        <div class="relative flex items-center mb-2">
          <input
            class="firm_checkbox"
            type="checkbox"
            id="show_attachment_text3"
            [checked]="firmAssignmentData.show_attachment_text3 === 'true'"
            (change)="onCheckboxChange($event.target)" /><input
            pInputText
            class="w-full pl-12-important"
            type="text"
            formControlName="attachment_text3" />
        </div>
      </div>
    </div>
    <div class="mb-4 grid gap-4 grid-flow-col grid-cols-2">
      <div class="flex flex-col mt-2">
        <label class="leading-tight text-bl-grey" for="other_condition_text"
          >Övriga uppgifter ({{ charsLeftConditions }} tecken kvar)</label
        >
        <textarea
          [maxlength]="maxLength"
          pInputTextarea
          id="other_condition_text"
          formControlName="other_condition_text"
          class="w-full h-48"></textarea>
      </div>
      <div class="flex flex-col mt-2">
        <label class="leading-tight text-bl-grey" for="other_terms_text"
          >Övriga villkor ({{ charsLeftTerms }} tecken kvar)</label
        >
        <textarea
          [maxlength]="maxLength"
          pInputTextarea
          id="other_terms_text"
          formControlName="other_terms_text"
          class="w-full h-48"></textarea>
      </div>
    </div>
    <div class="flex flex-row w-full justify-start mb-4">
      <button type="submit" class="btn btn-success mr-2">Spara</button>
      <button type="button" (click)="resetDialog = !resetDialog" class="btn btn-warning">Återställ texter</button>
    </div>
  </form>
</section>

<p-dialog header="Bekräfta återställning" [(visible)]="resetDialog" [draggable]="false">
  <p>
    Dina textändringar och kryssval till uppdragsavtalet kommer att tas bort och återställas till BL Byråstöds
    grundförslag.
  </p>
  <p-footer>
    <button type="button" (click)="resetAssignmentSettings()" class="btn btn-success">Okej, återställ</button>
    <button type="button" (click)="resetDialog = !resetDialog" class="btn btn-warning">Avbryt</button>
  </p-footer>
</p-dialog>
