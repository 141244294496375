import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TaskTemplateActions } from '@app/core/state/task-templates/task-templates.actions';
import { TaskTemplateSelectors } from '@app/core/state/task-templates/task-templates.selectors';
import { TaskGroupType, TaskType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { BystTableModule } from '@app/shared/components/table/table.module';
import { ONLY_SHOW_ACTIVE_TASKS_TEMPLATES_LABEL } from '@app/shared/misc/constants';
import { Store } from '@ngrx/store';
import { BehaviorSubject, mergeMap, Observable } from 'rxjs';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FirmTaskTemplateDetailsComponent } from './firm-task-template-details.component';
import { askArchiveTaskTemplate } from './askArchiveTaskTemplate';
import { TaskGroupsSelectorComponent } from '@app/shared/components/task-groups-selector/task-groups-selector.component';
import { PleaseWaitComponent } from '@app/shared/components/please-wait/please-wait.component';
import { openDialog } from '@app/shared/misc/openDialog';

@Component({
  selector: 'app-firm-task-templates',
  template: `
    <app-please-wait [hidden]="!(loading$ | async)"></app-please-wait>
    <div style="margin-bottom: 20px">
      <p>
        Klicka på den tjänst du vill ändra. Gör dina ändringar och spara. Klicka på soptunnan längst till höger för att
        inaktivera en tjänst. Den blir då inte valbar vid uppläggning av nya tjänster.
      </p>
    </div>
    <app-table
      #table
      [columns]="columns"
      [data]="taskTemplates$ | async"
      [checkboxField]="true"
      [checkboxLabel]="checkboxLabel"
      [columnSelector]="false"
      [paginator]="false"
      sortField="assignmentName"
      (cellClick)="onCellClick($event)"
      (checkboxChange)="onCheckboxChange($event)"
      rowClass="cursor-pointer"
      scrollHeight="460px"
      [useFixedStyle]="true">
      <app-table-filter>
        <app-task-groups-selector
          (onTaskGroupChanged)="onTaskGroupChanged($event)"
          [disabled]="loading$ | async"></app-task-groups-selector>
      </app-table-filter>
      <ng-template tableTemplate="bodyCell" tableBodyCellField="default" let-content>
        <span class="cursor-pointer hover:underline">
          {{ content }}
        </span>
      </ng-template>
      <ng-template tableTemplate="bodyCell" tableBodyCellField="import" let-task="rowData">
        <span
          [style.display]="task.preferredTemplate ? 'block' : 'none'"
          class="fa fa-check"
          style="text-align: center; color: green">
        </span>
      </ng-template>
      <ng-template tableTemplate="bodyCell" tableBodyCellField="archived" let-task="rowData">
        <div style="text-align: center">
          <span (click)="askArchiveTaskTemplate(task)" [class]="task.archived ? 'fa fa-recycle' : 'far fa-trash-alt'">
          </span>
        </div>
      </ng-template>
    </app-table>
  `,
  standalone: true,
  providers: [DialogService],
  imports: [CommonModule, BystTableModule, DynamicDialogModule, TaskGroupsSelectorComponent, PleaseWaitComponent],
})
export class FirmTaskTemplatesComponent implements OnInit {
  public importColumn: TableColumn = { field: 'import', header: 'Importera', width: '70px', sortable: false };
  public archivedColumn: TableColumn = { field: 'archived', header: '', width: '40px', sortable: false };
  public _columns: TableColumn[] = [
    { field: 'assignmentName', header: 'Uppdrag' },
    { field: 'type', header: 'Tjänst' },
    { field: 'startDiff', header: 'Visa', width: '70px' },
    { field: 'reminderDiff', header: 'Påminn', width: '70px' },
    { field: 'warningDiff', header: 'Varna', width: '70px' },
    { field: 'periodicity.description', header: 'Periodicitet', width: '100px' },
    { field: 'deadlineRule.description', header: 'Regel för deadline' },
    { field: 'deadlineRule.param', header: 'Regelvärde', width: '100px' },
    { field: 'usersAsString', header: 'Utförande handläggare' },
    { field: 'statusesAsString', header: 'Status' },
    this.importColumn,
    this.archivedColumn,
  ];
  public checkboxLabel: string = ONLY_SHOW_ACTIVE_TASKS_TEMPLATES_LABEL;
  public taskTemplates$: Observable<TaskType[]>;
  public loading$: Observable<boolean>;
  public lastTaskGroupId: number;
  private checkedSub$: BehaviorSubject<boolean>;

  constructor(private store: Store<AppState>, private dialogService: DialogService) {
    this.checkedSub$ = new BehaviorSubject(true);
    this.loading$ = this.store.select(TaskTemplateSelectors.selectLoading);
  }

  get columns(): TableColumn[] {
    return this.lastTaskGroupId > 0 ? this._columns.filter((c) => c.field !== this.importColumn.field) : this._columns;
  }

  ngOnInit(): void {
    this.store.dispatch(TaskTemplateActions.load({ taskGroupId: 0 }));
    this.taskTemplates$ = this.checkedSub$
      .asObservable()
      .pipe(mergeMap((value) => this.store.select(TaskTemplateSelectors.selectTaskTemplates(value))));
  }

  onCellClick({ column, data }: { column: TableColumn; data: TaskType }) {
    if (column.field === this.archivedColumn.field) {
      return;
    }

    openDialog(this.dialogService, FirmTaskTemplateDetailsComponent, { template: data });
  }

  onCheckboxChange(checked: boolean) {
    this.checkedSub$.next(checked);
  }

  askArchiveTaskTemplate(updatedTemplate: TaskType) {
    askArchiveTaskTemplate(this.store, this.dialogService, updatedTemplate);
  }

  onTaskGroupChanged(taskGroup: TaskGroupType) {
    this.lastTaskGroupId = taskGroup.id;
    this.store.dispatch(TaskTemplateActions.load({ taskGroupId: taskGroup.id }));
  }
}
