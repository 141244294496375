import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SimpleUserType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { UserAvatarComponent } from '@app/shared/components/user-avatar/user-avatar.component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-team-warnings',
  template: `
    <ng-container *ngIf="usersWithoutTeam$ | async as usersWithoutTeam">
      <div class="mt-8 alert alert-danger" *ngIf="usersWithoutTeam?.length > 0">
        <p class="pb-3 font-bold">Dessa medarbetare finns inte med i något team!</p>
        <app-user-avatar [users]="usersWithoutTeam" [maxVisible]="0"></app-user-avatar>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UserAvatarComponent],
})
export class TeamWarningsComponent {
  usersWithoutTeam$: Observable<SimpleUserType[]>;

  constructor(private store: Store<AppState>) {
    this.usersWithoutTeam$ = this.store.select(UserSelectors.activeUsersWithoutTeamsAsSimpleUser);
  }
}
