import { SimpleChanges } from '@angular/core';

export function getUpdatedProps<T extends Record<string, unknown>>(changes: SimpleChanges): T {
  return Object.keys(changes).reduce((acc, propName) => {
    const change = changes[propName];
    if (change.currentValue !== change.previousValue) {
      acc[propName as keyof T] = change.currentValue as T[keyof T];
    }
    return acc;
  }, {} as T);
}

export function toggleClass(el: HTMLElement, className: string, condition: boolean): void {
  if (condition) {
    el.classList.add(className);
    return;
  }
  el.classList.remove(className);
}
