import { createActionGroup, props } from '@ngrx/store';

export const ListActions = createActionGroup({
  source: 'List',
  events: {
    Load: props<{ filter?: any; params?: any }>(),
    'Load Succeeded': props<{ items: any[] }>(),
    'Load Failed': props<{ error: any }>(),
    'Filter List': props<{ filter: any }>(),

    'Add Item To List': props<{ item: any }>(),
    'Add Item To List Succeeded': props<{ item: any; predicate: (item: any) => boolean }>(),
    'Add Item To List Failed': props<{ error: any }>(),

    'Update Item In List': props<{ item: any }>(),
    'Update Item In List Succeeded': props<{
      item: any;
      predicate?: (item: any) => boolean;
      callback?: (newItem: any, items: any[]) => any[];
    }>(),
    'Update Item In List Failed': props<{ error: any }>(),

    'Delete Item In List': props<{ item: any }>(),
    'Delete Item In List Succeeded': props<{ item: any; predicate: (item: any) => boolean }>(),
    'Delete Item In List Failed': props<{ error: any }>(),

    'Teams Selected': props<{ selectedTeams: number[] }>(),
  },
});
