import { createReducer, on } from '@ngrx/store';
import { CoreActions } from '../core/core.actions';
import { BaseListState, LoadingStatus } from '../types';
import { ListActions } from './list.actions';

export interface ListState extends BaseListState<any, any, any> {}

export const initialListsState: ListState = {
  status: LoadingStatus.NotStarted,
  items: [],
  error: undefined,
  filter: {},
  params: {},
  selectedTeams: undefined,
};

export const listsReducer = createReducer(
  initialListsState,
  on(ListActions.load, (state, { filter, params }) => ({
    ...state,
    status: LoadingStatus.Loading,
    error: undefined,
    filter,
    params,
  })),
  on(ListActions.loadSucceeded, (state, { items }) => ({
    ...state,
    status: LoadingStatus.NotStarted,
    items,
    error: undefined,
  })),
  on(ListActions.loadFailed, (state, { error }) => ({ ...state, status: LoadingStatus.Failed, error, items: [] })),
  on(ListActions.filterList, (state, { filter }) => ({ ...state, filter })),
  on(ListActions.addItemToList, (state) => ({ ...state, status: LoadingStatus.Loading, error: undefined })),
  on(ListActions.addItemToListSucceeded, (state, { item }) => ({
    ...state,
    status: LoadingStatus.NotStarted,
    error: undefined,
    items: [...state.items, item],
  })),
  on(ListActions.addItemToListFailed, (state, { error }) => ({ ...state, status: LoadingStatus.Failed, error })),
  on(ListActions.updateItemInList, (state) => ({ ...state, status: LoadingStatus.Loading, error: undefined })),
  on(ListActions.updateItemInListSucceeded, (state, { item: newItem, predicate, callback }) => {
    let items = state.items;
    if (predicate) {
      items = state.items.map((item) => {
        if (predicate(item)) {
          return newItem;
        }

        return item;
      });
    }

    if (callback) {
      items = callback(newItem, items);
    }

    return {
      ...state,
      status: LoadingStatus.NotStarted,
      error: undefined,
      items,
    };
  }),
  on(ListActions.updateItemInListFailed, (state, { error }) => ({ ...state, status: LoadingStatus.Failed, error })),
  on(ListActions.deleteItemInList, (state) => ({ ...state, status: LoadingStatus.Loading, error: undefined })),
  on(ListActions.deleteItemInListSucceeded, (state, { predicate }) => ({
    ...state,
    status: LoadingStatus.NotStarted,
    error: undefined,
    items: predicate ? state.items.filter(predicate) : state.items,
  })),
  on(ListActions.deleteItemInListFailed, (state, { error }) => ({ ...state, status: LoadingStatus.Failed, error })),
  on(ListActions.teamsSelected, (state, { selectedTeams }) => ({ ...state, selectedTeams })),
  on(CoreActions.navigationStart, () => initialListsState),
);
