import { createFeature, createReducer, on } from '@ngrx/store';
import { TaskGroupType } from '../types';
import { TaskGroupsActions } from './task-groups.actions';

export interface TaskGroupsState {
  taskGroups: TaskGroupType[];
  error: any;
}

export const initialTaskGroupsState: TaskGroupsState = {
  taskGroups: [],
  error: undefined,
};

export const taskGroupsFeature = createFeature({
  name: 'taskGroups',
  reducer: createReducer(
    initialTaskGroupsState,
    on(TaskGroupsActions.loadSucceeded, (state, { taskGroups }) => ({ ...state, error: undefined, taskGroups })),
    on(TaskGroupsActions.loadFailed, (state, { error }) => ({ ...state, error, taskGroups: [] }))
  ),
});
