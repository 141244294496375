import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { MemoizedSelector, Store } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SimpleUserType, UserType } from 'src/app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { User } from '../../../core/entity/user';

@Component({
  selector: 'app-user-multi-selector',
  template: `<p-multiSelect
    [showHeader]="false"
    [options]="usersItems$ | async"
    (onChange)="emitSelectedUser()"
    [maxSelectedLabels]="1"
    selectedItemsLabel="{0} användare valda"
    [(ngModel)]="_selectedUsers"
    [disabled]="disabled"
    appendTo="body"
    styleClass="w-full"
    [scrollHeight]="scrollHeight"
    [placeholder]="placeholder || '- Välj -'"
    [class]="class"
    [title]="title"
    [inputId]="inputId"
    [class]="invalid ? 'ng-invalid ng-dirty' : ''"
    [emptyMessage]="emptyMessage"
    [autoOptionFocus]="false">
  </p-multiSelect>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UserMultiSelectorComponent,
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, MultiSelectModule],
})
export class UserMultiSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output() selectedUserEmitter = new EventEmitter<User[]>();
  @Input() scrollHeight: string;
  @Input() breakpoint: number = 16;
  @Input() selector: MemoizedSelector<AppState, UserType[] | SimpleUserType[]> = UserSelectors.activeUsersWithoutAll;
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() class: string;
  @Input() title: string;
  @Input() inputId: string;
  @Input() invalid: boolean;
  @Input() emptyMessage: string;
  @Input()
  set selectedUsers(users: User[]) {
    if (!users) {
      return;
    }

    this._selectedUsers = users.map((user) => user.id);
  }

  public usersBS: BehaviorSubject<UserType[]>;
  public usersItems$: Observable<SelectItem[]>;
  public _selectedUsers: number[];
  private subscription: Subscription;

  constructor(private store: Store<AppState>) {
    this.subscription = new Subscription();
    this.usersBS = new BehaviorSubject([]);
  }

  onChange: ControlValueAccessorFn = () => {};
  onTouched: ControlValueAccessorFn = () => {};

  writeValue(selectedUsers: number[]): void {
    this._selectedUsers = selectedUsers;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    this.subscription.add(this.store.select(this.selector).subscribe(this.usersBS));
    this.usersItems$ = this.usersBS.asObservable().pipe(
      map((users) => users.map(({ id, name }) => ({ label: name, value: id }))),
      tap((users) => {
        if (!this.scrollHeight) {
          this.scrollHeight = getScrollHeightString(users.length, this.breakpoint);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  emitSelectedUser() {
    const usersToEmit = this.usersBS.value
      .filter((user) => this._selectedUsers.includes(user.id))
      .map((user) => User.from({ id: user.id, name: user.name }));

    this.onChange(this._selectedUsers);
    this.onTouched();
    this.selectedUserEmitter.emit(usersToEmit);
  }
}
