import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OperatorFunction } from 'rxjs';
import { UserType } from '../types';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'Load All Users': emptyProps(),
    'Load All Users Succeeded': props<{ users: UserType[] }>(),
    'Load All Users Failed': props<{ error: any }>(),

    'Save user': props<{ user: UserType; errorHandler?: OperatorFunction<unknown, unknown> }>(),
    'Save User Succeeded': props<{ user: UserType }>(),
    'Save User Failed': props<{ error: any }>(),
  },
});
