<app-table
  #table
  storageKey="activitiesListTable"
  [columns]="columns"
  [data]="currentActivityComposits"
  [rowsPerPage]="defaultNumberOfRows"
  sortField="activity.deadline"
  exportFilename="alla_aktiviteter"
>
  <app-table-filter>
    <app-team-multi-selector localStorageKey="activities" (OnTeamsInit)="initEverything()"></app-team-multi-selector>
    <app-table-filter-item label="Period för vilka aktiviteter ska visas">
      <app-calendar
        [(selectedDate)]="fromDate"
        [defaultDate]="defaultIfEmptyFromDate"
        [style]="{ 'max-width': '150px' }"
      ></app-calendar>
      <app-calendar
        [(selectedDate)]="toDate"
        [defaultDate]="defaultIfEmptyToDate"
        styleClass="ml-2"
        [style]="{ 'max-width': '150px' }"
      ></app-calendar>
    </app-table-filter-item>
    <app-table-filter-item label="Klientansvarig">
      <app-user-selector [(userId)]="selectedClientResponsibleUserId" styleClass="min-w-48"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item label="Utförande handläggare">
      <app-user-selector [(userId)]="selectedTaskUserId" styleClass="min-w-48"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item>
      <app-refresh-button [loading]="isUpdatingList" (onClick)="refreshList()"></app-refresh-button>
    </app-table-filter-item>
    <app-table-filter-item>
      <p-checkbox
        [binary]="true"
        [(ngModel)]="includeFinished"
        (ngModelChange)="refilterActivities()"
        [label]="includeFinishedLabel"
      ></p-checkbox>
    </app-table-filter-item>
  </app-table-filter>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="activity.alertLevel.description" let-alertLevel="rowData">
    <div class="text-center">
      <app-status-icon
        [status]="alertLevel.activity.alertLevel.name"
        [color]="alertLevel.statusColor"
        [title]="alertLevel.activity.alertLevel.description"
        emptyBlack
      ></app-status-icon>
    </div>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="task.assignment_name" let-content let-composite="rowData">
    <a (click)="showTask(composite)" class="cursor-pointer text-black">{{ content }}</a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="task.type" let-content let-composite="rowData">
    <a (click)="showTask(composite)" class="cursor-pointer text-black"
      >{{ composite.task.type }} {{ composite.activity.span.spanLabel }}</a
    >
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="task.customer_number" let-content let-composite="rowData">
    <a (click)="showExistingClient(composite.task.client.id)" class="cursor-pointer text-black">{{ content }}</a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="task.client.name" let-content let-composite="rowData">
    <a (click)="showExistingClient(composite.task.client.id)" class="cursor-pointer text-black">{{ content }}</a>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="status" let-composite="rowData">
    <app-activity-states-selector
      [selectableStates]="composite.task.selectableStates"
      [state]="composite.activity.state"
      (stateChange)="changeActivityState($event?.name, composite)"
      styleClass="table-inline-dropdown w-full"
    ></app-activity-states-selector>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="comments" let-composite="rowData">
    <app-activity-comment-icon
      [title]="composite.activity.comment"
      (click)="showTask(composite)"
      componentStyle="cursor: pointer; color: orange; float: right; padding-right: 5px;"
    ></app-activity-comment-icon>
    <app-task-comment-icon
      [title]="composite.task.comment"
      (click)="showTask(composite)"
      componentStyle="cursor: pointer; color: blue; float: right; padding-right: 5px"
    ></app-task-comment-icon>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="program" let-composite="rowData">
    <div class="text-center">
      <ng-container *ngIf="composite.task.agent === 'BL'">
        <app-bla-icon-button [activityComposite]="composite"></app-bla-icon-button>
      </ng-container>
      <ng-container *ngIf="composite.task.agent !== 'BL'">
        <label (click)="callAgent(composite)" style="cursor: pointer">
          <img [src]="composite.task.agent | getIcon" class="inline" alt="Starta programmet" />
        </label>
      </ng-container>
    </div>
  </ng-template>

  <ng-template tableTemplate="emptyMessage">{{ listMessage }}</ng-template>
  <ng-template tableTemplate="summary" let-data>
    <div class="relative">
      Antal aktiviteter {{ data.length }}
      <div class="absolute export-button-wrapper">
        <p-button
          icon="pi pi-download"
          class="export-button"
          size="small"
          label="Exportera lista"
          (click)="table.exportCSV()"
          title="Ladda ner aktivitetslistan för import till Excel"
        ></p-button>
      </div>
    </div>
  </ng-template>
</app-table>
<a href="#" id="agentCaller"></a>

<p-confirmDialog #cd header="Inkludera aktiviteter för arkiverade klienter?">
  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-success" (click)="cd.accept()">Ja</button>
    <button type="button" class="btn btn-warning" (click)="cd.reject()">Nej</button>
  </ng-template>
</p-confirmDialog>
