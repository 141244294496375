export enum AmlStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_COLLEAGUE = 'WAITING_COLLEAGUE',
  DONE_COLLEAGUE = 'DONE_COLLEAGUE',
  WAITING_COLLEAGUE_APPROVAL = 'WAITING_COLLEAGUE_APPROVAL',
  WAITING_CLIENT = 'WAITING_CLIENT',
  DONE_CLIENT = 'DONE_CLIENT',
  DONE = 'DONE',
  DECLINED = 'DECLINED',
  UNKNOWN = 'UNKNOWN',
}

export enum AmlRisk {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
}

export interface AmlEntity {
  id: number;
  amlId: string;
  clientId: number;
  status: AmlStatus;
  risk: AmlRisk;
  link: string | null;
  documentLink: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface CreateAmlEntry {
  risk: AmlRisk;
  date: string;
}
