import { createReducer, on } from '@ngrx/store';
import { FirmType } from '../types';
import { FirmActions } from './firm.actions';
import { FirmSettings } from '@app/core/entity/firm.settings';

export interface FirmState {
  firm: FirmType;
  amlApiEmail: string;
  settings: FirmSettings;
  error: unknown;
}

export const initialFirmState: FirmState = {
  firm: null,
  amlApiEmail: null,
  settings: null,
  error: null,
};

export const firmReducer = createReducer(
  initialFirmState,
  on(FirmActions.loadFirmSucceeded, (state, { firm }) => ({ ...state, firm, error: null })),
  on(FirmActions.loadFirmFailed, (state, { error }) => ({ ...state, error })),
  on(FirmActions.saveFirmSucceeded, (state, { firm }) => ({ ...state, firm, error: null })),
  on(FirmActions.saveFirmFailed, (state, { error }) => ({ ...state, error })),

  on(FirmActions.verifyAmlConnectionSucceeded, (state, { email }) => ({ ...state, amlApiEmail: email })),
  on(FirmActions.verifyAmlConnectionFailed, (state) => ({ ...state, amlApiEmail: null })),

  on(FirmActions.loadFirmSettingsSucceeded, (state, { settings }) => ({ ...state, settings, error: null })),
  on(FirmActions.loadFirmSettingsFailed, (state, { error }) => ({ ...state, settings: null, error })),
  on(FirmActions.saveFirmSettingsSucceeded, (state, { settings }) => ({ ...state, settings, error: null })),
  on(FirmActions.saveFirmSettingsFailed, (state, { error }) => ({ ...state, error })),
  on(FirmActions.resetFirmSettingsSucceeded, (state, { settings }) => ({ ...state, settings, error: null })),
  on(FirmActions.resetFirmSettingsFailed, (state, { error }) => ({ ...state, error })),
);
