export type ChainHandler<T, R> = {
  setNext(next: ChainHandler<T, R>): ChainHandler<T, R>;
  handle(context: T): R | null;
};

// Use this to create a single handler that will be executed in order until it handles the context
export const createChainHandler = <ContextType, ReturnType>(
  handlePredicate: ChainHandler<ContextType, ReturnType>['handle'],
): ChainHandler<ContextType, ReturnType> => ({
  setNext(nextHandler: ChainHandler<ContextType, ReturnType>) {
    this.next = nextHandler;
    return nextHandler;
  },
  handle(context: ContextType): ReturnType | null {
    const handled = handlePredicate(context);
    if (handled) {
      return handled;
    }
    return this.next ? this.next.handle(context) : null;
  },
});

// Use this to create a chain of handlers that will be executed in order until one of them handles the context
export const chainHandler = <ContextType, ReturnType>(handlers: ChainHandler<ContextType, ReturnType>[]) => {
  if (!Array.isArray(handlers) || handlers.length === 0) {
    throw new Error('At least one handler must be provided');
  }
  if (handlers.length > 1) {
    handlers.reduce((acc, handler) => acc.setNext(handler), handlers[0]);
  }
  return {
    handle(context: ContextType): ReturnType {
      return handlers[0].handle(context);
    },
  };
};
