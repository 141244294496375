import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Role, User } from '../entity/user';
import { isAdmin } from '../misc/isAdmin';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class UserAuthFacade {
  private isAdminOrResponsibleForClientBS = new BehaviorSubject<boolean>(false);
  public isAdminOrResponsibleForClient$: Observable<boolean>;

  constructor(private userService: UserService) {
    this.isAdminOrResponsibleForClient$ = this.isAdminOrResponsibleForClientBS.asObservable();
  }

  public updateAccessRightBasedOnResponsibleUserId(clientResponsibleUserId: number): void {
    this.userService
      .getCurrentUser()
      .pipe(
        map(
          (currentUser: User) =>
            this.isAdmin(currentUser.role) || this.isResponsibleForClient(currentUser.id, clientResponsibleUserId)
        )
      )
      .subscribe((hasAccess) => this.isAdminOrResponsibleForClientBS.next(hasAccess));
  }

  private isAdmin(role: Role): boolean {
    return isAdmin({ role } as User);
  }

  private isResponsibleForClient(currentUserId: number, clientResponsibleUserId: number) {
    return (
      clientResponsibleUserId === null || clientResponsibleUserId === 0 || currentUserId === clientResponsibleUserId
    );
  }
}
