import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { CreateTaskGroupCommand, TaskGroupType, UpdateTaskGroupCommand } from '../state/types';
import { AppState } from '../state/appState';

@Injectable({ providedIn: 'root' })
export class TaskGroupService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'taskgroup';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  getTaskGroups(): Observable<TaskGroupType[]> {
    return this.http
      .get<TaskGroupType[]>(this.url, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  createTaskGroup(createTaskGroup: CreateTaskGroupCommand): Observable<TaskGroupType> {
    return this.http
      .post<TaskGroupType>(this.url, createTaskGroup, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  updateTaskGroup(id: number, updateTaskGroup: UpdateTaskGroupCommand): Observable<TaskGroupType> {
    const url = `${this.url}/${id}`;
    return this.http
      .patch<TaskGroupType>(url, updateTaskGroup, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  deleteTaskGroup(id: number): Observable<void> {
    const url = `${this.url}/${id}`;
    return this.http
      .delete<void>(url, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }
}
