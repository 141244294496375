import { SimpleUserType } from '../state/types';
import { Activity } from './activity';
import { Task } from './task';

export abstract class CompositeBase {
  public taskUsers: string = '';
  protected abstract getCompositeColumnTypePropertyNames(): string[];
  protected abstract createDefaultCompositeObjectForProperty(): any;

  // --- Public functions

  // Returns an array of all the composite objects in the class
  public getCompositeColumnTypeObjects() {
    return this.getCompositeColumnTypePropertyNames().reduce(
      (acc, propName) => [...acc, (this as Record<string, any>)[propName]],
      []
    );
  }

  public isAllCompositeColumnTypeObjectsDone() {
    return this.getCompositeColumnTypeObjects().every((x: { activity: Activity; task: Task }) => {
      return x?.activity?.isCompleted() || !x?.activity?.hasAlertLevel();
    });
  }

  // Updates the activity among the composite objects if it's found
  public updateActivityIfExists(
    newActivity: Activity,
    callbackOnMatch = (_compositeObject: CompositeBase, _compositeTypeObject: any) => {}
  ) {
    const columnTypeObject = this.getCompositeColumnTypeObjects().find((columnTypeObject) =>
      this.isSameActivity(columnTypeObject.activity, newActivity)
    );
    if (columnTypeObject) {
      columnTypeObject.activity = Activity.from(newActivity);
      columnTypeObject.activity.updateStateColor();
      callbackOnMatch.apply(this, [this, columnTypeObject]);
    }
  }

  public getAgentUrl(includeJwt = false) {
    const paths = this.getCompositeColumnTypePropertyNames()
      .map((propName) => (this as Record<string, any>)[propName].agentCall)
      .filter((agentCallPath) => !!agentCallPath);

    let path = paths.length > 0 ? paths[0] : '';

    if (path && includeJwt) {
      path += `&jwt=${sessionStorage.getItem('jwt') || ''}`;
    }

    return path;
  }

  // --- Protected and extendable functions
  protected populateTaskUsers() {
    const tasks = this.getCompositeColumnTypeObjects();
    const initials = tasks.reduce((acc, task) => {
      const initials = task?.activity?.allUsers?.map((u: SimpleUserType) => u.initials);
      if (!initials) {
        return acc;
      }
      return acc.concat(initials);
    }, []);

    this.taskUsers = [...new Set(initials)].join(', ');
  }

  protected populateEmptyColumn(propName: string): void {
    // if the propName (ie. "best", "forb"...) not exists, add a default value for the missing property
    if (!(this as Record<string, any>)[propName]) {
      (this as Record<string, any>)[propName] = this.createDefaultCompositeObjectForProperty();
    }
  }

  // Converts activities that is not an Activity class instance
  protected convertActivityToActivityInstance(propName: string): void {
    const activity = (this as Record<string, any>)[propName].activity;
    if (!activity || !(activity instanceof Activity)) {
      (this as Record<string, any>)[propName].activity = Activity.from(activity);
    }
  }

  protected static fromObject<T extends CompositeBase>(Class: { new (): T }, data: any): T {
    const compositeObject = Object.assign(new Class(), data);
    const compositeColumnPropertyNames = compositeObject.getCompositeColumnTypePropertyNames();

    compositeColumnPropertyNames.forEach((propName: string) => {
      compositeObject.populateEmptyColumn(propName);
      compositeObject.convertActivityToActivityInstance(propName);
      compositeObject[propName].activity.updateStateColor();
    });

    return compositeObject;
  }

  // --- Private functions

  private isSameActivity(oldActivity: Activity, newActivity: Activity) {
    return (
      oldActivity.id == newActivity.id ||
      (oldActivity.taskId == newActivity.taskId &&
        oldActivity.span.start == newActivity.span.start &&
        oldActivity.span.end == newActivity.span.end)
    );
  }
}
