/* eslint-disable no-underscore-dangle */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { switchMap, take, map, filter } from 'rxjs/operators';
import { AssignmentAgreement } from 'src/app/core/entity/assignment.agreement';
import { Client } from 'src/app/core/entity/client';
import { Firm } from 'src/app/core/entity/firm';
import { FirmSettings } from 'src/app/core/entity/firm.settings';
import { Task } from 'src/app/core/entity/task';
import { User } from 'src/app/core/entity/user';
import { ActivityService } from 'src/app/core/services/activity.service';
import { FirmService } from 'src/app/core/services/firm.service';
import { TaskService } from 'src/app/core/services/task.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserSettingsStorage } from 'src/app/core/storage/user.settings.storage';
import { TableColumn } from 'src/app/shared/components/table/interfaces/table-column';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { CLIENT_RESPONSIBLE, INCLUDE_TASKS_WITH_END_DATE_LABEL, LOGGED_IN_USER } from '@app/shared/misc/constants';
import { openDialog } from '@app/shared/misc/openDialog';
import { TaskDialog } from '@app/shared/components/task-dialog/task.dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { toClientTypeTransformer, toTaskTransformer } from '@app/core/state/transformers/transformers';
import { UserType } from '@app/core/state/types';
import { AddAssignementsDialog } from '../../add-assigments-dialog/add-assigments.dialog.component';

@Component({
  selector: 'app-client-assignments',
  templateUrl: './client.assignments.component.html',
  providers: [ActivityService, DialogService],
})
export class ClientAssignmentsComponent implements OnInit {
  @Output() updateEmitter = new EventEmitter<boolean>();
  public clientTasks: Task[] = [];
  public selectorDummy: Task;
  public selectedActivityListTask: Task;
  public pendingDeletionTaskId: number = null;
  public displayDeleteDialog = false;
  public displayTaskAddedDialog = false;
  public dontShowTaskAddedDialogAgain = false;
  public displayActivitiesDialog = false;
  public singleActivityDate: string;
  public columns: TableColumn[] = [
    { field: 'assignmentName', header: 'Uppdrag', sortable: true, width: 'auto' },
    { field: 'type', header: 'Tjänst', sortable: true, width: 'auto' },
    { field: 'span.start', header: 'Startdatum', sortable: true, width: '120px' },
    { field: 'span.end', header: 'Slutdatum', sortable: true, width: '120px' },
    { field: 'periodicity.description', header: 'Periodicitet', sortable: true, width: '120px' },
    { field: 'deadlineRule.description', header: 'Regel', sortable: true, width: '120px' },
    { field: 'usersAsString', header: 'Utförande handläggare', sortable: true, width: '132px' },
    { field: 'assignmentAgreement', header: 'Uppdrags- avtal', sortable: false, width: '90px' },
    { field: 'activitiesAction', header: 'Aktivi- teter', sortable: false, width: '57px' },
    { field: 'deleteAction', header: 'Ta bort', sortable: false, width: '50px' },
  ];

  public includeTasksWithEndDateLabel: string;

  private _client: Client;
  private uss = new UserSettingsStorage();
  private responsibleUser: User;
  private assignmentObject = new AssignmentAgreement();
  private firmData: Firm;
  private firmSettings: FirmSettings;
  private endDateStartLable = INCLUDE_TASKS_WITH_END_DATE_LABEL;
  includeTasksWithEndDate: boolean;

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private taskService: TaskService,
    private userService: UserService,
    private firmService: FirmService,
    private store: Store<AppState>,
    private dialogService: DialogService,
  ) {}

  @Input()
  set client(client: Client) {
    this._client = client;
    if (this._client.id !== -1 && this._client.userId) {
      this.userService.getUser(this._client.userId).subscribe((user) => {
        this.responsibleUser = user;
      });
    } else {
      this.clientTasks = [];
      this.responsibleUser = null;
    }
    this.loadClientTasks();
  }

  get client() {
    return this._client;
  }

  ngOnInit() {
    this.firmService.loadFirmAssignmentData();

    this.dontShowTaskAddedDialogAgain =
      this.uss.loadSetting(UserSettingsStorage.SHOW_ADDED_TASKS_DIALOG, 'false') === 'true';
    this.includeTasksWithEndDate = this.uss.loadSettingAsBoolean(UserSettingsStorage.SHOW_TASKS_WITH_END_DATE, true);
  }

  public getColumnSize(column: TableColumn) {
    if (!column.width || column.width === 'auto') {
      return { flex: '1 1 0px' };
    }

    return { flex: `0 0 ${column.width}`, width: column.width };
  }

  ngOnDestroy() {
    this.uss.saveSetting(UserSettingsStorage.SHOW_TASKS_WITH_END_DATE, `${this.includeTasksWithEndDate}`);
  }

  loadClientTasks() {
    this.clientTasks = [];

    if (!this._client || this._client.id === -1) {
      return;
    }

    this.taskService.getTasksByClientId(this._client.id).subscribe((tasks) => {
      const counter = tasks.reduce((acc, curr) => (curr.span.end ? acc + 1 : acc), 0);
      const filteredTasks = this.includeTasksWithEndDate ? tasks : tasks.filter((t) => !t.span.end);
      this.includeTasksWithEndDateLabel = this.endDateStartLable.concat(' (', counter.toString(), ')');
      this.loadedClientCallback(filteredTasks);
    });
  }

  private loadedClientCallback(tasks: Task[]) {
    this.clientTasks = tasks.map((task) => ({ ...task, usersAsString: this.getUsersAsString(task) }));
  }

  showTask(event: Task) {
    openDialog(this.dialogService, TaskDialog, { task: JSON.parse(JSON.stringify(event)) })
      .pipe(filter((result) => Boolean(result?.task)))
      .subscribe((result) => {
        this.onCloseTaskDialog(result.task);
      });
  }

  displayAssignmentAdder() {
    openDialog(this.dialogService, AddAssignementsDialog, {
      client: toClientTypeTransformer.transform(this.client),
    })
      .pipe(filter((result) => Boolean(result.taskTemplates?.length)))
      .subscribe((result) => {
        const tasks = result.taskTemplates.map((t) => toTaskTransformer.transform(t));
        this.addTasksFromTemplate(tasks);
      });
  }

  addTasksFromTemplate(tasksToAdd: Task[]) {
    if (!tasksToAdd.length) {
      return;
    }

    this.userService
      .getCurrentUser()
      .pipe(
        map((user: User) => {
          const normalizeTaskWithClient = this.normalizeTask(this._client);
          return this.normalizeTasks(tasksToAdd, user, normalizeTaskWithClient);
        }),
        switchMap((tasks) => this.taskService.saveTasks(tasks)),
        switchMap(() => this.taskService.getTasksByClientId(this._client.id)),
      )
      .subscribe({
        next: (tasks) => {
          this.loadedClientCallback(tasks);
          this.updateEmitter.emit(true);
          this.displayTaskAddedDialog = tasksToAdd.length > 0 && !this.dontShowTaskAddedDialogAgain;
        },
        error: () =>
          this.store.dispatch(
            ToastActions.showWarnMessage({
              summary: 'Ett fel har uppstått',
              detail: 'Kontrollera felet och försök igen.',
            }),
          ),
      });
  }

  private normalizeTasks = (tasksToAdd: Task[], user: User, normalizeTaskWithClient: (task: Task) => Task) =>
    tasksToAdd
      .map((task) => {
        if (this.taskHasPreselectedCustomerResponsible(task, this.responsibleUser)) {
          task.users.push(this.createSimpleUser(this.responsibleUser));
        }

        if (this.taskHasPreselectedLoggedInUser(task, user)) {
          task.users.push(this.createSimpleUser(user));
        }

        return task;
      })
      .map(normalizeTaskWithClient);

  private includesUser = (users: User[], user: User) => users.some((u) => u.id === user.id);

  private taskHasPreselectedCustomerResponsible = (task: Task, user: User) =>
    task.preselectedCustomerResponsible && this.responsibleUser && !this.includesUser(task.users, user);

  private taskHasPreselectedLoggedInUser = (task: Task, user: User) =>
    task.preselectedLoggedInUser && !this.includesUser(task.users, user);

  private normalizeTask(client: Client) {
    return (task: Task) => {
      const normalized: Task = {
        ...task,
        client,
        id: null,
        preselectedCustomerResponsible: null,
        preselectedLoggedInUser: null,
        feedMode: 'BOTH',
      };
      return normalized;
    };
  }

  private createSimpleUser(user: User) {
    const newUser = User.nothingButId(user.id);
    newUser.name = user.name;
    newUser.username = user.username;
    return newUser;
  }

  tryDeleteTask(task: Task) {
    if (task.deleteable) {
      this.deleteTask(task.id);
    } else {
      this.pendingDeletionTaskId = task.id;
      this.displayDeleteDialog = true;
    }
  }

  afterDeleteDialog(deleteTask: boolean) {
    this.displayDeleteDialog = false;
    if (deleteTask && this.pendingDeletionTaskId) {
      this.deleteTask(this.pendingDeletionTaskId);
    }
    this.pendingDeletionTaskId = null;
  }

  deleteTask(taskId: number) {
    this.taskService
      .deleteTask(taskId)
      .pipe(switchMap(() => this.taskService.getTasksByClientId(this._client.id)))
      .subscribe((tasks) => {
        this.loadedClientCallback(tasks);
        this.updateEmitter.emit(true);
      });
  }

  private getUsersAsString(task: Task) {
    const usersAsString = task.users.map((user) => user.name);

    if (task.preselectedCustomerResponsible) {
      usersAsString.push(CLIENT_RESPONSIBLE);
    }

    if (task.preselectedLoggedInUser) {
      usersAsString.push(LOGGED_IN_USER);
    }

    const resultingUsersAsString = usersAsString.join(', ');
    if (resultingUsersAsString.length > 20) {
      return `${resultingUsersAsString.substring(0, 20)}...`;
    }

    return resultingUsersAsString;
  }

  closeTaskAddedDialog() {
    this.displayTaskAddedDialog = false;
    this.uss.saveSetting(
      UserSettingsStorage.SHOW_ADDED_TASKS_DIALOG,
      this.dontShowTaskAddedDialogAgain ? 'true' : 'false',
    );
  }

  onCheckboxChange(taskData: Task) {
    this.clientTasks = this.clientTasks.map((task) => {
      if (task.id !== taskData.id) {
        return task;
      }

      return { ...task, assignmentAgreement: !taskData.assignmentAgreement };
    });
  }

  onCheckboxEndDateChange() {
    this.includeTasksWithEndDate = !this.includeTasksWithEndDate;
    this.loadClientTasks();
  }

  saveTaskData() {
    // update the task with true/false on assignment agreement property
    this.clientTasks.forEach((task) => this.taskService.saveTask(task).subscribe());
    // get the data from the database
    this.firmService.assignmentAgreementFirmData$.pipe(take(1)).subscribe(([firmData, firmSettings]) => {
      this.firmData = firmData;
      this.firmSettings = firmSettings;

      // populate the json object
      this.assignmentObject = {
        firm: {
          id: this.firmData.id,
          name: this.firmData.name,
          corporateIdentity: this.firmData.corporateIdentity,
          address: this.firmData.address,
          zipcode: this.firmData.zipcode,
          city: this.firmData.city,
          phone: this.firmData.phone,
          email: this.firmData.email,
          users: this.getResponsibleUser() as unknown as UserType[],
        },
        client: this.getClient(),
        tasks: this.getTaskList(),
        applying_conditions: this.getConditions(this.firmSettings),
        material_delivery: this.getMaterialDelivery(this.firmSettings),
        payment: this.getPaymentOptions(this.firmSettings),
        payment_condition: this.getPaymentCondition(this.firmSettings),
        attachments: this.getAttachments(this.firmSettings),
        signatures: this.getSignatures(),
        notice_period: this.firmSettings.notice_period,
        other_condition_text: this.firmSettings.other_condition_text,
        other_terms_text: this.firmSettings.other_terms_text,
      };
      // send the object to the backend, and get a datakey as response
      this.getKeyFromBackend(this.assignmentObject);
    });
  }

  getClient() {
    const clientContact = this._client.signatory ? this._client.signatory : this._client.name;
    const { name, corporateIdentity, address, zipCode, city, phone, email } = this._client;
    return {
      name,
      corporateIdentity,
      address,
      zipCode,
      city,
      phone,
      email,
      clientContact,
    };
  }

  getTaskList() {
    // filtering out the tasks that has assignmentAgreement checked
    return this.clientTasks.filter((item) => item.assignmentAgreement);
  }

  getConditions(data: FirmSettings) {
    switch (data.srf_or_far) {
      case 'srf':
        return data.srf_text;
      case 'far':
        return data.far_text;
      default:
        return '';
    }
  }

  getMaterialDelivery(data: FirmSettings) {
    return {
      material_delivery1: data.material_delivery1,
      material_delivery2: data.material_delivery2,
      material_delivery3: data.material_delivery3,
      material_delivery4: data.material_delivery4,
    };
  }

  getPaymentOptions(data: FirmSettings) {
    return {
      payment_text1: data.payment_text1,
      checked1: data.show_payment_text1,
      payment_text2: data.payment_text2,
      checked2: data.show_payment_text2,
      payment_text3: data.payment_text3,
      checked3: data.show_payment_text3,
      payment_text4: data.payment_text4,
      checked4: data.show_payment_text4,
    };
  }

  getPaymentCondition(data: FirmSettings) {
    return {
      payment_condition_text1: data.payment_condition_text1,
      checked1: data.show_payment_condition_text1,
      payment_condition_text2: data.payment_condition_text2,
      checked2: data.show_payment_condition_text2,
      payment_condition_text3: data.payment_condition_text3,
      checked3: data.show_payment_condition_text3,
      payment_condition_text4: data.payment_condition_text4,
      checked4: data.show_payment_condition_text4,
    };
  }

  getAttachments(data: FirmSettings) {
    return {
      attachment_text1: data.attachment_text1,
      checked1: data.show_attachment_text1,
      attachment_text2: data.attachment_text2,
      checked2: data.show_attachment_text2,
      attachment_text3: data.attachment_text3,
      checked3: data.show_attachment_text3,
    };
  }

  getSignatures() {
    return {
      city: this.firmData.city,
      date: new Date().toLocaleDateString(),
      client_contact: this._client.signatory,
      firm_contact: this.firmData.users,
    };
  }

  getKeyFromBackend(data: AssignmentAgreement) {
    const jwt = sessionStorage.getItem('jwt');
    this.firmService
      .getKeyFromBackend(data)
      .pipe(take(1))
      .subscribe((dataKey) => {
        const url = `${environment.documentUrl}/assignment-agreement/?dataKey=${dataKey}&jwt=${jwt}`;
        window.open(url, '_blank');
      });
  }

  // Check or uncheck all boxes depending on their current state before pushing to taskArray
  checkOrUncheckAll() {
    const checkedStatus = !this.clientTasks.find((task) => task.assignmentAgreement === true);
    this.clientTasks = this.clientTasks.map((task) => ({ ...task, assignmentAgreement: checkedStatus }));
  }

  getResponsibleUser() {
    // copy the original array
    const newUserArray = this.firmData.users.slice(0);

    // if there's a responsibleUser, set that as the selectedUser, if not
    // take the logged in user from localStorage.
    let selectedUser;

    if (this.responsibleUser) {
      selectedUser = newUserArray.find((x) => x.id === this.responsibleUser.id);
    } else {
      selectedUser = newUserArray.find((x) => x.id === Number(localStorage.getItem('logged_in_user_id')));
    }
    return { users: newUserArray, responsibleUser: selectedUser };
  }

  onCloseTaskDialog(task: Task) {
    if (!task) {
      return;
    }

    this.selectorDummy = null;
    this.loadClientTasks();
    this.updateEmitter.emit(true);
  }

  showTaskActivities(task: Task) {
    this.displayActivitiesDialog = true;
    this.selectedActivityListTask = task;
  }

  onDisplayActivitiesDialogChange(visible: boolean) {
    this.displayActivitiesDialog = visible;
    this.selectedActivityListTask = null;
    this.loadClientTasks();
  }
}
