<div class="grid gap-8 grid-cols-4 mb-5">
  <div
    class="cursor-pointer border-2 border-dashed border-bl-grey-200 rounded-lg transition-all hover:shadow-lg hover:border-bl-blue-light-600 flex flex-col justify-center items-center min-h-220px"
    (click)="newTeamClick.emit()"
  >
    <div class="text-4xl mb-4 text-bl-blue-light-300">
      <i class="fad fa-users fa-lg"></i>
    </div>
    <p class="text-xl text-bl-blue-light-600">Lägg till Team</p>
    <div class="text-4xl mt-4 text-bl-blue-light-300">
      <i class="fal fa-plus-circle fa-lg"></i>
    </div>
  </div>
  <div
    *ngFor="let team of teams$ | async"
    class="cursor-pointer border border-bl-grey-200 rounded-lg transition-shadow hover:shadow-lg"
    (click)="teamClick.emit(team)"
  >
    <div class="p-3 pt-5 border-b border-bl-grey-200 flex justify-between">
      <p title="{{ team.name }}" class="text-base text-bl-grey-700 font-normal truncate">{{ team.name }}</p>
      <i class="far fa-arrows-v fa-lg px-2 origin-center transform rotate-45 text-bl-grey-500"></i>
    </div>
    <div class="p-3">
      <p>Medarbetare</p>
      <app-user-avatar [users]="getTeamUsers(team) | async"></app-user-avatar>
    </div>
    <div class="p-3 pt-1 mb-5" *ngIf="!isRootTeam(team)">
      <ng-container
        [ngTemplateOutlet]="isClientTeam(team) ? clientBased : taskBased"
        [ngTemplateOutletContext]="{ $implicit: team }"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #clientBased let-team>
  <p>Klienter</p>
  <bl-frontend-badge [text]="team.clients.count" variant="info"></bl-frontend-badge>
</ng-template>
<ng-template #taskBased let-team>
  <p>Tjänster</p>
  <bl-frontend-badge [text]="team.tasks.count" variant="info"></bl-frontend-badge>
</ng-template>
