import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PartnerFirm, LevelDto } from '@app/core/state/types/partner.types';
import { BlFrontendTooltipComponent } from '@app/core/wrappers/bl-frontend-tooltip.component';
import {
  getIsoDateWithTime,
  getIsoDateWithoutTime,
  getLastDateOfPreviousMonth,
  getLongMonthAndYear,
  getPreviousMonth,
} from '@app/shared/misc/dates';

interface Dot {
  selected: boolean;
  last: boolean;
}

@Component({
  selector: 'app-partnerprogram-progressbar-level',
  template: `
    <div class="flex items-center gap-5">
      <ng-container *ngIf="isLastLevelDot">
        <bl-frontend-tooltip [heading]="tooltipHeading" [content]="tooltipContent">
          <div
            class="flex flex-row rounded-full w-10 h-10 cursor-pointer last-level-dot"
            [ngClass]="'level-circle-' + levelFrom"></div>
        </bl-frontend-tooltip>
      </ng-container>
      <ng-container *ngIf="!isLastLevelDot">
        <div class="flex flex-row rounded-full w-10 h-10" [ngClass]="'level-circle-' + levelFrom"></div>
      </ng-container>
      <ng-container *ngIf="showFallenOutTooltip">
        <bl-frontend-tooltip [heading]="'Partnernivå'" [content]="tooltipFallenOutContent">
          <span class="text-2xl font-semibold selected-level-label cursor-pointer">
            {{ label }}
          </span>
        </bl-frontend-tooltip>
      </ng-container>
      <ng-container *ngIf="showLevelTooltip && !showFallenOutTooltip">
        <bl-frontend-tooltip [heading]="'Partnernivå'" [content]="tooltipLevelContent">
          <span class="text-2xl font-semibold selected-level-label cursor-pointer">
            {{ label }}
          </span>
        </bl-frontend-tooltip>
      </ng-container>
      <ng-container *ngIf="!showLevelTooltip && !showFallenOutTooltip">
        <span
          class="text-2xl font-semibold"
          [ngClass]="{
            'selected-level-label': levelFrom === currentLevel.value,
            'level-label': levelFrom !== currentLevel.value
          }">
          {{ label }}
        </span>
      </ng-container>
      <ng-container *ngIf="!lastLevel">
        <div *ngFor="let dot of levelDots">
          <ng-container *ngIf="dot.last">
            <bl-frontend-tooltip [heading]="tooltipHeading" [content]="tooltipContent">
              <div
                class="rounded-full w-4 h-4 cursor-pointer"
                [ngClass]="{ 'selected-dot': dot.selected, dot: !dot.selected, 'selected-last-dot': dot.last }"></div>
            </bl-frontend-tooltip>
          </ng-container>
          <ng-container *ngIf="!dot.last">
            <div
              class="rounded-full w-4 h-4"
              [ngClass]="{ 'selected-dot': dot.selected, dot: !dot.selected, 'selected-last-dot': dot.last }"></div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .level-circle-1 {
        background: rgba(87, 87, 87, 1);
      }
      .level-circle-2 {
        background: rgba(224, 224, 224, 1);
      }
      .level-circle-3 {
        background: rgba(147, 147, 147, 1);
      }
      .level-circle-4 {
        background: rgba(229, 232, 191, 1);
      }
      .level-label {
        @apply text-2xl font-semibold;
      }
      .selected-level-label {
        @apply text-4xl text-bl-blue-light-800;
      }
      .dot {
        @apply bg-bl-grey-300;
      }
      .selected-dot {
        @apply bg-bl-blue-light-500;
      }
      .selected-last-dot {
        @apply bg-bl-blue-light-500;
        box-shadow: 0px 0px 5px 0px rgba(0, 193, 255, 1);
      }
      .last-level-dot {
        box-shadow: 0px 0px 5px 0px rgba(0, 193, 255, 1);
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, BlFrontendTooltipComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramProgressbarLevelComponent {
  @Input() levelFrom = 0;
  @Input() levels: LevelDto[];
  @Input() lastLevel = false;
  @Input() hasFallenOut: boolean;

  get showFallenOutTooltip() {
    return this.hasFallenOut && this.levelFrom === 1;
  }

  get tooltipHeading(): string {
    return `Samarbetspaket: ${this.currentUnits}`;
  }

  get tooltipContent(): string {
    return `Antal BL Pro- och BL 
    Essential-paket
    senast uppdaterat: 
    ${getIsoDateWithTime(this.firm.lastUpdated)}`;
  }

  get tooltipLevelContent(): string {
    return `Partnernivå ${getLongMonthAndYear(getPreviousMonth(), false)}:
    ${this.currentLevel.label}

    Nivå för ${getLongMonthAndYear(new Date().toISOString(), false)} 
    bestäms av antalet 
    samarbetspaket per 
    ${getIsoDateWithoutTime(getLastDateOfPreviousMonth())}`;
  }

  get showLevelTooltip(): boolean {
    return this.levelFrom === this.currentLevel.value && this.monthsInProgram > 1;
  }

  get tooltipFallenOutContent(): string {
    return `${this.firm?.remainingUnitsToNextLevel} st BL Pro- eller BL 
    Essential-paket fattas 
    för att återgå till nivå Järn.

    Senast uppdaterat: 
    ${getIsoDateWithTime(this.firm?.lastUpdated)}`;
  }

  get firm(): PartnerFirm {
    return this.internalFirm;
  }

  @Input()
  set firm(data: PartnerFirm) {
    this.internalFirm = data;
    this.currentUnits = data?.units ?? 0;
    this.currentLevel = data?.level ?? { value: 0, label: '', discount: 0, units: 0, refund: 0 };
    const level = this.levels?.find((l) => l.value === this.levelFrom);
    this.label = level?.label ?? '';
    this.monthsInProgram = data?.monthsInProgram;
    this.setActualLevel();

    if (this.lastLevel) {
      return;
    }

    this.fillDots();
    this.setLastDot();
  }

  get isLastLevelDot(): boolean {
    return this.lastLevel && (this.levelFrom === this.currentLevel.value || this.levelFrom === this.actualLevel?.value);
  }

  currentUnits: number;
  currentLevel: LevelDto;
  label: string;
  dots: number[] = Array(9).fill(0);
  levelDots: Dot[] = Array(9).fill({ selected: false, last: false });
  actualLevel?: LevelDto;
  monthsInProgram = 0;

  private internalFirm: PartnerFirm | null = null;

  private setActualLevel = () => {
    const levelsFound = this.levels.filter((l) => l.units <= this.currentUnits);
    this.actualLevel = levelsFound[levelsFound.length - 1] ?? { value: 0, label: '', discount: 0, units: 0, refund: 0 };
  };

  private getDiffPercentage = () => {
    const level = this.levels?.find((l) => l.value === this.levelFrom);
    const levelUnits = level?.units ?? 0;
    const nextLevel = this.levels?.find((l) => l.value > this.levelFrom);
    const nextLevelUnits = nextLevel?.units ?? levelUnits;
    const unitsBetweenLevels = nextLevelUnits - levelUnits;
    const progress = Math.abs(levelUnits - this.currentUnits);
    const diff = progress / unitsBetweenLevels;

    return diff;
  };

  private fillDots = () => {
    const diff = this.getDiffPercentage();

    this.levelDots = this.levelDots.map((_, i) => {
      if (this.levelFrom < this.actualLevel?.value) {
        return { selected: true, last: false };
      }

      if (this.levelFrom > this.actualLevel?.value) {
        return { selected: false, last: false };
      }

      const selected = diff === 0 ? false : ((i + 1) * 10) / 100 < diff;
      return { selected, last: false };
    });
  };

  private setLastDot = () => {
    if (this.levelFrom !== this.actualLevel?.value) {
      return;
    }

    const numberOfSelected = this.levelDots.filter((dot) => dot.selected).length;
    this.levelDots[numberOfSelected] = { ...this.levelDots[numberOfSelected], last: true };
  };
}
