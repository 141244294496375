import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { FirmType } from '@app/core/state/types';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

@Component({
  selector: 'app-firm-data',
  templateUrl: './firm.data.component.html',
  styleUrls: ['./firm.data.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirmDataComponent {
  firmDataForm: UntypedFormGroup;
  firmData: FirmType;
  resetDialog: boolean;

  @Input() set firmData$(firmData$: FirmType) {
    this.firmData = firmData$;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const include_activities_for_archived_clients =
      firmData$?.properties?.include_activities_for_archived_clients === 'true';
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const show_hidden_databases_for_all_users = firmData$?.properties?.show_hidden_databases_for_all_users === 'true';
    const patchValue = {
      ...firmData$,
      properties: { include_activities_for_archived_clients, show_hidden_databases_for_all_users },
    };
    this.firmDataForm.patchValue(patchValue);
  }

  @Output() updateFirmEvent = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder, private store: Store) {
    this.store
      .select(AuthSelectors.selectIsFirmUser)
      .pipe(first())
      .subscribe((isFirmUser) => {
        this.setFirmDataForm();
        if (isFirmUser) {
          // Todo: Kolla med Marie vilken vi ska disabla
          this.firmDataForm.get('properties').disable();
          // this.firmDataForm.get('properties').get('show_hidden_databases_for_all_users').disable();
        }
      });
  }

  setFirmDataForm(): void {
    this.firmDataForm = this.formBuilder.group({
      id: [],
      address: [''],
      zipcode: [''],
      city: [''],
      email: [''],
      phone: [''],
      properties: this.formBuilder.group({
        include_activities_for_archived_clients: [false],
        show_hidden_databases_for_all_users: [false],
      }),
    });
  }

  updateFirmData(): void {
    const newFirmData = {
      ...this.firmDataForm.value,
      name: this.firmData.name,
      corporateIdentity: this.firmData.corporateIdentity,
      properties: {
        include_activities_for_archived_clients: String(
          this.firmDataForm.get('properties').get('include_activities_for_archived_clients').value,
        ),
        show_hidden_databases_for_all_users: String(
          this.firmDataForm.get('properties').get('show_hidden_databases_for_all_users').value,
        ),
      },
    };
    this.updateFirmEvent.emit(newFirmData);
  }
}
