import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PartnerFirm } from '@app/core/state/types/partner.types';
import { BlFrontendAlertComponent } from '@app/core/wrappers/bl-frontend-alert.component';
import { Link } from '@bl/components';

@Component({
  selector: 'app-partnerprogram-fallenout-alert',
  template: `
    <div class="w-full mb-6" *ngIf="visible">
      <bl-frontend-alert
        severity="warning"
        [header]="'Byrån har färre än 20 kopplade samarbetspaket'"
        [listItems]="fallenOutItems"
        [link]="{
          text: 'Till villkoren',
          url: 'https://www.bjornlunden.se/redovisningsbyr%c3%a5/partnerprogram/policy__8985',
          blank: true
        }"
        [useUnsafeHtml]="true"
        [dismissable]="false">
      </bl-frontend-alert>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, BlFrontendAlertComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramFallenOutAlert {
  @Input() firm: PartnerFirm | null;
  @Output() openTab: EventEmitter<number> = new EventEmitter();

  get visible() {
    return this.firm?.level?.value === 0 && this.firm?.monthsInProgram > 1 && this.firm.units < 20;
  }

  get fallenOutItems(): Link[] {
    return [
      {
        text: `För att återgå till partnernivå <strong>Järn</strong> under kommande månad behöver byrån koppla ytterligare <strong>${this.firm?.remainingUnitsToNextLevel} st BL Pro- eller BL Essential-paket</strong>`,
      },
      {
        text: 'Byrån har inte rätt till någon återbäring eller rabatt i Partnerprogrammet under innevarande månad',
      },
      {
        text: `Byrån behåller övriga förmåner på partnernivån, och dessa hittar du under fliken Resurser.`,
      },
    ];
  }
}
