import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { AssignmentType } from '@app/core/state/types';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-assignment-selector',
  template: `
    <p-dropdown
      [options]="assignments"
      [disabled]="disabled"
      [ngModel]="selectedAssignmentId"
      (ngModelChange)="emitSelectedAssignment($event)"
      optionValue="id"
      optionLabel="name"
      styleClass="w-full"
      appendTo="body">
    </p-dropdown>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssignmentSelectorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FormsModule, DropdownModule],
})
export class AssignmentSelectorComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() selectedAssignmentId: number;
  @Input() assignments: AssignmentType[];
  @Output() selectedAssignmentEmitter = new EventEmitter<AssignmentType>();

  emitSelectedAssignment(assignmentId: number) {
    if (!this.assignments) {
      return;
    }

    const selectedAssignment =
      this.assignments.find((assignment) => assignment.id === assignmentId) ?? this.assignments[0];
    this.selectedAssignmentEmitter.emit(selectedAssignment);
    this.onChange(selectedAssignment);
  }

  onChange: ControlValueAccessorFn = () => {};
  onTouch: ControlValueAccessorFn = () => {};

  writeValue(selectedAssigment: AssignmentType | number): void {
    if (typeof selectedAssigment === 'number') {
      this.selectedAssignmentId = selectedAssigment;
      return;
    }
    this.selectedAssignmentId = selectedAssigment?.id;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
