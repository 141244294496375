import { Injectable } from '@angular/core';
import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../state/appState';
import { Observable, map } from 'rxjs';
import { AppIntegration, AppModule } from '../state/types/collaboration-package.types';

type OnboardingExtraClientInfo = {
  email: string;
  cloudApiKey: string;
};

type OnboardingExtraFinancialInfo = {
  financialYear: {
    start: string;
    end: string;
  };
  accountingMethod: string;
  vatAccountingInterval: string;
};

type OnboardExistingClientBody = {
  client?: Pick<OnboardingExtraClientInfo, 'email'>;
  financialInformation: OnboardingExtraFinancialInfo;
};

type OnboardingResponse = {
  key: string;
};

type OnboardCompanyWithMiniCollaborationPackageBody = {
  cloudApiKey: string;
  name: string;
  socialSecurityNumber: string;
};

type OnboardCompanyWithMiniCollaborationPackageResponse = {
  integrations: AppIntegration[];
  modules: AppModule[];
  publicKey: string;
  subscriptionTypeId: number;
};

@Injectable({ providedIn: 'root' })
export class OnboardingService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'onboarding';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  getOnboardingKey(clientId: number, body: OnboardExistingClientBody): Observable<string> {
    return this.http
      .post<OnboardingResponse>(`${this.url}/${clientId}`, body, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(
        map((response: OnboardingResponse) => response.key),
        this.catchErrorAndShowMessage(),
      );
  }

  getOnboardingKeyForNonExistingClient(body: OnboardingExtraClientInfo): Observable<string> {
    return this.http
      .post<OnboardingResponse>(this.url, { client: body }, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(
        map((response: OnboardingResponse) => response.key),
        this.catchErrorAndShowMessage(),
      );
  }

  onboardCompanyWithMiniCollaborationPackage(
    body: OnboardCompanyWithMiniCollaborationPackageBody,
  ): Observable<OnboardCompanyWithMiniCollaborationPackageResponse> {
    return this.http
      .post<OnboardCompanyWithMiniCollaborationPackageResponse>(
        `${this.url}/company/with-non-email-user`,
        body,
        this.options.getAuthorizationHeaderWithEmptyBody(),
      )
      .pipe(this.catchErrorAndShowMessage());
  }
}
