import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { TeamsActions } from '../teams/teams.actions';
import { TeamActions } from './team.actions';

@Injectable()
export class TeamEffects {
  addTeamSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.addTeamSucceeded),
      map(() => TeamActions.hideDialog())
    )
  );

  updateTeamSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.updateTeamSucceeded),
      map(() => TeamActions.hideDialog())
    )
  );

  deleteTeamSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.deleteTeamSucceeded),
      map(() => TeamActions.hideDialog())
    )
  );

  constructor(private actions$: Actions) {}
}
