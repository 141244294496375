import { Span } from './span';
import { Activity } from './activity';
import { CompositeBase } from './composite.base';
export class TaxDeclarationComposite extends CompositeBase {
  client: string = '';
  clientId: number;
  corporateIdentity: string;
  customerNumber: string;
  responsible: string;
  mainSpan: Span = new Span();
  mainSpanOrigin: string;
  arbe: { activity: Activity; readyDate: string; task: { comment: '' } };
  beta: { activity: Activity; readyDate: string; task: { comment: '' } };
  betg: { activity: Activity; readyDate: string; task: { comment: '' } };
  lonn: { activity: Activity; readyDate: string; task: { comment: '' } };
  momd: { activity: Activity; readyDate: string; task: { comment: '' } };
  momu: { activity: Activity; readyDate: string; task: { comment: '' } };
  pers: { activity: Activity; readyDate: string; task: { comment: '' } };

  constructor() {
    super();
  }

  protected getCompositeColumnTypePropertyNames(): string[] {
    return ['arbe', 'beta', 'betg', 'lonn', 'momd', 'momu', 'pers'];
  }

  protected createDefaultCompositeObjectForProperty() {
    return {
      activity: new Activity(),
      readyDate: 'false',
      task: { comment: '' },
    };
  }

  static from(obj: any): TaxDeclarationComposite {
    const taxDeclaration = CompositeBase.fromObject(TaxDeclarationComposite, obj);
    if (!taxDeclaration.mainSpan) {
      taxDeclaration.mainSpan = new Span();
    }
    taxDeclaration.populateTaskUsers();
    return taxDeclaration;
  }
}
