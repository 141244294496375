import { MAX_CALENDAR_DATE, MIN_CALENDAR_DATE } from 'src/app/shared/misc/dates';
import { TodayDate } from 'src/app/shared/misc/today.date';
import { ValidationStatus } from './validationStatus';

export interface ValidationProps {
  min?: number;
  max?: number;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
}

export interface ValidationStrings {
  title: string;
  text: string;
}

export function getValidationStrings(
  status: ValidationStatus,
  {
    label,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    minDate = MIN_CALENDAR_DATE,
    maxDate = MAX_CALENDAR_DATE,
  }: ValidationProps = {}
): ValidationStrings {
  if (status === ValidationStatus.MissingValue) {
    return {
      title: getTitleWithLabel('Värde saknas', label),
      text: '',
    };
  }

  if (status === ValidationStatus.InvalidDateFormat) {
    return {
      title: getTitleWithLabel('Felaktigt datumformat', label),
      text: 'Datum måste vara giltiga och i formatet YYYY-MM-dd',
    };
  }

  if (status === ValidationStatus.InvalidNumber) {
    return { title: getTitleWithLabel('Felaktigt eller tomt värde', label), text: 'Värdet måste vara en siffra' };
  }

  if (status === ValidationStatus.InvalidDate) {
    return { title: getTitleWithLabel('Felaktigt datum', label), text: 'Datum måste vara giltiga' };
  }

  if (status === ValidationStatus.LessThanMin) {
    return {
      title: getTitleWithLabel('Felaktigt värde', label),
      text: `Värdet måste vara större eller lika med ${min}`,
    };
  }

  if (status === ValidationStatus.GreaterThanMax) {
    return {
      title: getTitleWithLabel('Felaktigt värde', label),
      text: `Värdet måste vara mindre eller lika med ${max}`,
    };
  }

  if (status === ValidationStatus.NegativeValue) {
    return { title: getTitleWithLabel('Felaktigt värde', label), text: 'Värdet måste vara större än 0' };
  }

  if (status === ValidationStatus.BeforeMinDate) {
    return {
      title: getTitleWithLabel('Felaktigt datum', label),
      text: `Datum måste vara giltiga och lika med eller efter ${TodayDate.getIsoFormattedDate(minDate)}`,
    };
  }

  if (status === ValidationStatus.AfterMaxDate) {
    return {
      title: getTitleWithLabel('Felaktigt datum', label),
      text: `Datum måste vara giltiga och lika med eller innan ${TodayDate.getIsoFormattedDate(maxDate)}`,
    };
  }

  return { title: ValidationStatus.Ok, text: '' };
}

function getTitleWithLabel(title: string, label?: string): string {
  if (!label) {
    return title;
  }

  return `${title} [${label}]`;
}
