<div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="flex flex-row">
      <div class="w-1/2">
        <div formGroupName="span">
          <label for="spnStart" class="text-thick mt-3 mb-1 w-full">Startdatum</label>
          <app-calendar
            inputId="spnStart"
            formControlName="start"
            appendTo="body"
            [invalid]="form.controls.span.controls.start.errors?.start?.message"
            [title]="
              form.controls.span.controls.start.errors?.start?.message ??
              'ex: Om tjänsten ska utföras från oktober månad ange 2021-10-01'
            "
          ></app-calendar>
          <label for="spnEnd" class="text-thick mt-3 mb-1 w-full">Slutdatum</label>
          <app-calendar
            inputId="spnEnd"
            formControlName="end"
            appendTo="body"
            [invalid]="form.controls.span.controls.end.errors?.end?.message"
            [title]="
              form.controls.span.controls.end.errors?.end?.message ??
              'Ange sista dag då tjänsten ska utföras, exempelvis 2021-12-31'
            "
          ></app-calendar>
          <button type="button" class="btn btn-primary ml-1" (click)="resetEndYear()" title="Töm slutdatum">
            <i class="far fa-trash-alt"></i>
          </button>
        </div>
        <div class="flex justify-between w-3/4">
          <div class="flex flex-col">
            <label for="startDiff" class="text-thick mt-3 mb-1">Visa</label>
            <p-inputNumber
              inputId="startDiff"
              formControlName="startDiff"
              [min]="1"
              [max]="350"
              [size]="4"
              locale="sv-SE"
              title="Ange antal kalenderdagar före deadline"
            ></p-inputNumber>
          </div>
          <div class="flex flex-col">
            <label for="reminderDiff" class="text-thick mt-3 mb-1">Påminn</label>
            <p-inputNumber
              inputId="reminderDiff"
              formControlName="reminderDiff"
              [min]="1"
              [max]="350"
              [size]="4"
              locale="sv-SE"
              title="Ange antal kalenderdagar före deadline"
            ></p-inputNumber>
          </div>
          <div class="flex flex-col">
            <label for="warningDiff" class="text-thick mt-3 mb-1">Varna</label>
            <p-inputNumber
              inputId="warningDiff"
              formControlName="warningDiff"
              [min]="1"
              [max]="350"
              [size]="4"
              locale="sv-SE"
              title="Ange antal kalenderdagar före deadline"
            ></p-inputNumber>
          </div>
        </div>
        <div>
          <label for="users" class="text-thick mt-3 mb-1">Utförande handläggare</label>
          <app-form-user-selector
            formControlName="users"
            inputId="users"
            [breakpoint]="12"
            [isInvalid]="form.controls.users.errors?.message"
            [isMultiSelect]="true"
            [title]="form.controls.users.errors?.message ?? ''"
          ></app-form-user-selector>
        </div>
        <div formGroupName="periodicity">
          <label for="periodName" class="text-thick mt-3 mb-1">Periodicitet</label>
          <p-dropdown
            appendTo="body"
            formControlName="name"
            inputId="periodName"
            styleClass="w-full"
            optionLabel="description"
            optionValue="name"
            [options]="config.data.task.selectablePeriodicities"
            [autoOptionFocus]="false"
            [autoDisplayFirst]="false"
          ></p-dropdown>
        </div>
        <div *ngIf="showSingleEventUI">
          <button type="button" class="btn btn-primary btn-sm mt-2.5" (click)="showCreateActivityDialog()">
            Skapa aktivitet
          </button>
        </div>
        <div *ngIf="!showSingleEventUI" formGroupName="deadlineRule">
          <label for="rule" class="text-thick mt-3 mb-1">Regel för deadline</label>
          <p-dropdown
            appendTo="body"
            formControlName="template"
            inputId="rule"
            styleClass="w-full"
            optionLabel="text"
            optionValue="code"
            [options]="rules$ | async"
            [autoOptionFocus]="false"
            [autoDisplayFirst]="false"
          ></p-dropdown>
          <label class="text-thick mt-3 mb-1">
            Regelvärde {{ form.value.deadlineRule.paramType ? '(' + form.value.deadlineRule.paramType + ')' : '' }}
          </label>
          <app-deadline-value
            formControlName="param"
            [type]="form.value.deadlineRule.paramType"
            [code]="form.value.deadlineRule.template"
            (valueValidated)="onDeadlineValidated($event)"
          ></app-deadline-value>
        </div>
      </div>
      <div class="w-1/2">
        <label for="comment" class="text-thick mt-3 mb-1">Kommentar</label>
        <textarea
          class="form-control"
          cols="50"
          formControlName="comment"
          id="comment"
          rows="3"
          maxlength="400"
          style="resize: none; height: 102px"
          title="Visas som information varje gång tjänsten ska utföras"
        ></textarea>
        <label for="selectableStates" class="text-thick mt-3 mb-1">Valbara status</label>
        <app-activity-states-multi-selector
          formControlName="selectableStates"
          inputId="selectableStates"
          [invalid]="form.controls.selectableStates.errors?.message"
          [title]="form.controls.selectableStates.errors?.message ?? ''"
        ></app-activity-states-multi-selector>
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <button type="submit" class="btn btn-success mr-2" [disabled]="form.invalid">Spara</button>
      <button type="button" class="btn btn-warning" (click)="close({ task: null })">Stäng</button>
    </div>
  </form>
</div>
