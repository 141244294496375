import { createReducer, on } from '@ngrx/store';
import { UserType } from '../types';
import { AuthActions } from './auth.actions';

export interface AuthState {
  user: UserType;
  error: any;
  authSpinner: boolean;
}

export const initialAuthState: AuthState = {
  user: undefined,
  error: undefined,
  authSpinner: false,
};

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.loginSpinnerStart, (state) => ({ ...state, authSpinner: true })),
  on(AuthActions.loginSpinnerStopp, (state) => ({ ...state, authSpinner: false })),
  on(AuthActions.loadCurrentUserSuccessful, (state, { user }) => ({ ...state, user, error: undefined })),
  on(AuthActions.loadCurrentUserFailed, (state, { error }) => ({ ...state, error, user: undefined })),
  on(AuthActions.resetAuthStateAndStorage, () => ({ ...initialAuthState }))
);
