import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BlCurrencyPipe } from '@app/shared/pipes/bl-currency.pipe';
import { Notification } from 'src/app/core/entity/advisory.notification';

@Component({
  selector: 'app-notification-payload',
  template: `
    <div class="text-lg">
      <ng-container [ngSwitch]="notification?.feature_number">
        <ng-container *ngSwitchCase="1">
          <dl class="flex flex-col">
            <div class="flex">
              <dt class="mr-2">Kontosaldo 2393:</dt>
              <dd>{{ notification?.payload.balance_2393 }}</dd>
            </div>
            <div class="flex">
              <dt class="mr-2">Kontosaldo 2893:</dt>
              <dd>{{ notification?.payload.balance_2893 }}</dd>
            </div>
          </dl>
        </ng-container>
        <ng-container *ngSwitchCase="12">
          <dl class="flex flex-col">
            <div class="flex">
              <dt class="mr-2">Anställd:</dt>
              <dd>{{ notification?.payload.name }}</dd>
            </div>
            <div class="flex">
              <dt class="mr-2">Födelsedatum:</dt>
              <dd>{{ notification?.payload.birth_date }}</dd>
            </div>
          </dl>
        </ng-container>
        <ng-container *ngSwitchCase="28">
          <dl class="flex flex-col">
            <div class="flex">
              <dt class="mr-2">Kontosaldo:</dt>
              <dd>{{ notification?.payload.balance | BLCurrency }}</dd>
            </div>
          </dl>
        </ng-container>
        <ng-container *ngSwitchCase="29">
          <dl class="flex flex-col">
            <div class="flex">
              <dt class="mr-2">Saldo:</dt>
              <dd>{{ notification?.payload.company_value | BLCurrency }}</dd>
            </div>
          </dl>
        </ng-container>
        <ng-container *ngSwitchCase="34">
          <dl class="flex flex-col">
            <div class="flex">
              <dt class="mr-2">Kontosaldo:</dt>
              <dd>{{ notification?.payload.balance | BLCurrency }}</dd>
            </div>
          </dl>
        </ng-container>
        <ng-container *ngSwitchCase="35">
          <dl class="flex flex-col">
            <div class="flex">
              <dt class="mr-2">Saldo på konto 1630:</dt>
              <dd>{{ notification?.payload.balance | BLCurrency }}</dd>
            </div>
          </dl>
        </ng-container>
        <ng-container *ngSwitchCase="104">
          <dl class="flex flex-col">
            <div class="flex">
              <dt class="mr-2">Fakturadatum:</dt>
              <dd>{{ notification?.payload.invoice_date }}</dd>
            </div>
            <div class="flex">
              <dt class="mr-2">Fakturabelopp:</dt>
              <dd>{{ notification?.payload.amount_local | BLCurrency }}</dd>
            </div>
            <div class="flex">
              <dt class="mr-2">FakturaID:</dt>
              <dd>{{ notification?.payload.invoice_id }}</dd>
            </div>
          </dl>
        </ng-container>
      </ng-container>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, BlCurrencyPipe],
})
export class NotificationPayloadComponent {
  @Input('notification') notification: Notification;
}
