import { Component } from '@angular/core';

@Component({
  selector: 'app-please-wait',
  styles: [
    `
      .pane {
        padding: 1em;
      }
      .centered {
        position: absolute;
        z-index: 10000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `,
  ],
  template: `<div class="centered p-5 bg-white border border-bl-grey-400 shadow-md text-center">
    <img src="assets/ajax-loader.gif" alt="Arbetar, var god vänta..." class="inline" /><br />Arbetar, var god vänta...
  </div>`,
  standalone: true,
})
export class PleaseWaitComponent {}
