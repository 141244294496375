import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/core/services/clients.service';
import { Client } from 'src/app/core/entity/client';
import { Observable, EMPTY, BehaviorSubject, combineLatest } from 'rxjs';
import { catchError, filter, first, map } from 'rxjs/operators';
import { AdvisoryNotification, UpdateReportWithCompanyName } from 'src/app/core/entity/advisory.notification';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { showClientDialog } from '@app/shared/misc/showClientDialog';
import { DialogService } from 'primeng/dynamicdialog';
import { openDialog } from '@app/shared/misc/openDialog';
import { AdvisoryToolFilter, AdvisoryToolService } from '../services/advisory-tool.service';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';

@Component({
  selector: 'app-advisory-tool',
  templateUrl: './advisory-tool.component.html',
  styleUrls: ['./advisory-tool.component.css'],
})
export class AdvisoryToolComponent implements OnInit {
  selectedClient$: Observable<Client>;
  displayClientCard: boolean;
  cols = [
    { field: 'customer_number', header: 'Kundnr.' },
    { field: 'corporate_identity', header: 'Orgnr.' },
    { field: 'client_name', header: 'Namn' },
    { field: 'date', header: 'Datum' },
    { field: 'title', header: 'Typ', status: true },
  ];

  notifications$: Observable<AdvisoryNotification[]>;
  filter$: Observable<AdvisoryToolFilter>;
  showNotificationsCount$: Observable<boolean>;
  getLastUpdatedClients$: Observable<UpdateReportWithCompanyName[]>;
  hideAdvisoryGuide: boolean;

  private updatedClientBS: BehaviorSubject<null | Client>;

  constructor(
    private advisoryToolService: AdvisoryToolService,
    private clientService: ClientService,
    private store: Store<AppState>,
    private dialogService: DialogService,
  ) {
    this.updatedClientBS = new BehaviorSubject<null | Client>(null);
  }

  ngOnInit() {
    this.notifications$ = combineLatest([this.updatedClientBS, this.notificationsWithFilter$]).pipe(
      map(([updatedClient, notifications]) =>
        this.advisoryToolService.updateClientInfoOnLoadedNotifications(updatedClient, notifications),
      ),
    );

    this.filter$ = this.advisoryToolService.filter$;
    this.showNotificationsCount$ = this.advisoryToolService.showNotificationCount$;
    this.getLastUpdatedClients$ = this.advisoryToolService.lastUpdatedClients$;
    this.hideAdvisoryGuide = localStorage.getItem('hideAdvisoryGuide')
      ? JSON.parse(localStorage.getItem('hideAdvisoryGuide'))
      : localStorage.setItem('hideAdvisoryGuide', 'false');
  }

  tableColumnClick(event: { type: string; notification: AdvisoryNotification }) {
    if (event.type === 'Namn' || event.type === 'Kundnr.') {
      showClientDialog({
        clientService: this.clientService,
        dialogService: this.dialogService,
        id: event.notification.client_id,
        store: this.store,
      })
        .pipe(first())
        .subscribe((result) => {
          if (!result?.client?.id || result?.client?.id === -1) {
            return;
          }

          this.updateClientInfo(result.client);
        });
      return;
    }

    openDialog(this.dialogService, NotificationModalComponent, { advisoryNotification: event.notification })
      .pipe(filter((result) => Boolean(result?.url)))
      .subscribe(({ url }) => {
        this.markAsCleared(url);
      });
  }

  onAdviceToggle(checked: boolean) {
    this.advisoryToolService.onAdviceToggle(checked);
  }

  onClearedToggle(checked: boolean) {
    this.advisoryToolService.onClearedToggle(checked);
  }

  showNotificationsCount(checked: boolean) {
    this.advisoryToolService.onShowNotificationsToggle(checked);
  }

  markAsCleared(url: string) {
    if (!url) {
      return;
    }
    this.advisoryToolService.toggleCleared(url);
  }

  onCompanyTrigger(companyList: UpdateReportWithCompanyName[]) {
    companyList.forEach((company) => {
      // eslint-disable-next-line no-console
      this.advisoryToolService.triggerSoftrobotUpdate(company.company_key).pipe(first()).subscribe(console.log);
    });
    this.store.dispatch(
      ToastActions.showInfoMessage({
        summary: `${companyList.length} företag uppdateras`,
        detail: 'Detta kan ta en liten stund, men du kan fortsätta jobba som vanligt i Byråstödet',
      }),
    );
  }

  hideOnboarding(event: boolean) {
    if (event) {
      this.hideAdvisoryGuide = true;
      localStorage.setItem('hideAdvisoryGuide', JSON.stringify(event));
    }
  }

  updateClientInfo(client: Client) {
    this.updatedClientBS.next({ ...client });
  }

  private notificationsWithFilter$ = this.advisoryToolService.notificationsWithFilter$.pipe(
    catchError(() => {
      this.store.dispatch(
        ToastActions.showErrorMessage({
          summary: 'Något gick fel',
          detail: 'Fel vid inläsande av data till tabellen i Rådgivarverktyget',
        }),
      );
      return EMPTY;
    }),
  );
}
