import { Directive, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppState } from '@app/core/state/appState';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { PrivilegeType } from '@app/core/state/types';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

@Directive({
  selector: '[appNotAdminPrivilege]',
  standalone: true,
})
export class NotAdminPrivilegeDirective implements OnChanges {
  private privilege: string;

  constructor(private view: ViewContainerRef, private template: TemplateRef<unknown>, private store: Store<AppState>) {
    this.store
      .select(AuthSelectors.selectAuthUser)
      .pipe(filter((user) => Boolean(user)))
      .subscribe((user) => {
        this.privilege = user.privilege ?? '';
        this.ngOnChanges();
      });
  }

  ngOnChanges(): void {
    if (this.privilege !== PrivilegeType.ADMIN) {
      this.view.clear();
      this.view.createEmbeddedView(this.template);
      return;
    }

    this.view.clear();
  }
}
