import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import {
  DialogData,
  DialogForm,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { InputTextModule } from 'primeng/inputtext';

interface Result extends DialogResult {
  email: string;
  password: string;
}

interface Form extends DialogForm<unknown> {
  email: FormControl<string>;
  password: FormControl<string>;
}

interface Data extends DialogData {
  email: string | null;
}

@Component({
  template: `
    <form [formGroup]="form" (ngSubmit)="onSubmit()" pFocusTrap>
      <p>
        Skriv in de integrationsuppgifter du fått från Due Compliance. Är du redan kund, men saknar dina uppgifter,
        kontakta Due Compliance på <a href="mailto:support@duecompliance.se">support&#64;duecompliance.se</a>.
      </p>
      <label for="email" class="mt-4 mb-0">API-mejladress</label>
      <input pInputText type="text" formControlName="email" class="w-full" />

      <label for="password" class="mt-6">API-lösenord</label>
      <input pInputText type="password" formControlName="password" class="w-full" />

      <div class="bg-bl-grey-100 rounded-lg mt-6 p-4">
        <p class="mb-0">Är du ännu inte kund hos Due Compliance?</p>
        <a href="https://www.bjornlunden.se/redovisningsbyr%c3%a5/program/due-compliance-kyc" target="_blank"
          >Läs mer och boka en demo</a
        >
      </div>

      <div class="flex flex-row w-full justify-end mt-8">
        <bl-frontend-button type="button" variant="secondary" (click)="close(null)" text="Avbryt"></bl-frontend-button>
        <bl-frontend-button
          type="submit"
          variant="primary"
          class="ml-2"
          [disabled]="form.invalid"
          text="Aktivera integration"></bl-frontend-button>
      </div>
    </form>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InputTextModule, ReactiveFormsModule, BlFrontendButtonComponent],
})
export class ActivateIntegrationDialogComponent extends FormDialogBaseComponent<Data, Result, Form> {
  protected override onInitialized = () => {
    this.form = this.builder.group({
      email: new FormControl(this.config.data.email, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });
  };

  onSubmit() {
    const { email, password } = this.form.value;
    this.close({ email, password });
  }
}
