import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FileVaultActions = createActionGroup({
  source: 'FileVault',
  events: {
    'Load All FileNames': emptyProps(),
    'Load All FileNames Succeeded': props<{ files: string[] }>(),
    'Load All FileNames Failed': props<{ error: any }>(),
    'Download File': props<{ file: string }>(),
    'Download File Succeeded': props<{ file: string; blob: any }>(),
    'Download File Failed': props<{ file: string; error: any }>(),
  },
});
