import { Injectable } from '@angular/core';
import { FilterService } from 'primeng/api';

@Injectable()
export class TableFilterFactory {
  constructor(private filterService: FilterService) {}

  registerFilter(filterName: string, filterFunction: (value: any, filter: any) => boolean) {
    const isFilterRegistered = !!(this.filterService.filters as Record<string, any>)[filterName];

    if (isFilterRegistered) {
      return;
    }

    this.filterService.register(filterName, filterFunction);
  }

  createCustomDropdownFilterFunction() {
    return (value: any, filter?: number) => {
      const isStringOrNumber = (item: any) => typeof item === 'string' || typeof item === 'number';
      const isArray = (item: any) => Array.isArray(item);

      if (!filter && filter !== 0) {
        return true;
      }
      if (!value) {
        return filter === 0;
      }

      if (isStringOrNumber(value)) {
        return value == filter;
      }
      if (isArray(value)) {
        return value.some((item: any) => (isStringOrNumber(item) ? item == filter : item.id == filter));
      }
      return value?.id === filter;
    };
  }

  createCustomMultiSelectFilterFunction() {
    return (value: any, filter: any[]) => {
      const isStringOrNumber = (item: any) => typeof item === 'string' || typeof item === 'number';
      const isArray = (item: any) => Array.isArray(item);

      if (!filter || filter?.length < 1) {
        return true;
      }
      // if ((!value || value?.length < 1) && filter.some(item => item < 0)) {
      //   return false;
      // }

      if (isArray(value)) {
        const values = value.map((item: any) => (isStringOrNumber(item) || !item ? item : item.id));
        return filter.every((item) => values.includes(item));
      }
      if (isStringOrNumber(value)) {
        return filter.includes(value);
      }
      // om man filtrerar på "saknar klientansvarig" så kan value vara null eller 0,
      // medan filter är satt till -1 (value 0/null på filter går inte, för det valet
      // markerade alla i filterlistan)
      if (!value) {
        return filter.includes(-1);
      }
      return filter.includes(value?.id); // går på "id" som prop om value skulle vara ett object
    };
  }
}
