export class Contact {
    id:number;
    clientId:number;
    label:string;
    phone:string;
    email:string;
    comment:string;

}

