import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { RoleLabel, User } from '../entity/user';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { toUserTypeTransformer } from '../state/transformers/transformers';
import { RoleLabelType, UserType } from '../state/types';
import { AppState } from '../state/appState';
import { UserSettingsStorage } from '../storage/user.settings.storage';
import { BLService } from './bl.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'user';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;
  private currentUserSubject = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
    this.useCache();
  }

  loadCurrentUserType(): Observable<UserType> {
    return this.http.get<UserType>(this.url + '/me', this.options.getAuthorizationHeaderWithEmptyBody()).pipe(
      this.catchErrorAndShowMessage(),
      tap((user: UserType) => {
        const uss = new UserSettingsStorage();
        // todo - should be removed and use "getCurrentUser" in places where we need the id and name
        uss.saveSetting(UserSettingsStorage.LOGGED_IN_USER_ID, JSON.stringify(user.id));
        uss.saveSetting(UserSettingsStorage.LOGGED_IN_USER_NAME, user.name);
      }),
      // Behövs tills vi har byggt bort "getCurrentUser()" funktionen
      tap((user: UserType) => this.currentUserSubject.next(user as unknown as User)),
    );
  }

  getCurrentUser(): Observable<User> {
    return this.currentUserSubject.asObservable().pipe(
      filter((user: User) => !!user),
      take(1),
    );
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.url, this.options.getAuthorizationHeaderWithEmptyBody()).pipe(
      map((result) =>
        result.map((user) => ({ ...user, roleLabel: (RoleLabel as Record<string, RoleLabel>)[user.role] })),
      ),
      tap((result) => this.updateCache(result)),
      this.catchErrorAndShowMessage(),
    );
  }

  getAllUserTypes(): Observable<UserType[]> {
    // very important to transform to usertype objects to separate objects in state (readonly) from objects in dialogs (mutable)
    // when all dialogs and views use state then we don't need transformers any more
    return this.getAllUsers().pipe(map((users) => users.map(toUserTypeTransformer.transform)));
  }

  getUsers(): Observable<User[]> {
    return this.getAllUsers().pipe(map((users) => users.filter((user) => user.active)));
  }

  getUser(id: number): Observable<User> {
    if (!this.hasCacheExpired() && this.cacheContainsUser(id)) {
      return this.getCacheObservable<User[]>().pipe(
        map((users: User[]) => {
          return users.find((user) => user.id === id);
        }),
      );
    }

    return this.http
      .get<User>(this.url + '/' + id, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  saveUserType(user: UserType, errorHandler: any = tap(() => {})): Observable<UserType> {
    this.clearCache();
    const body = JSON.stringify(user);
    return this.http.put<UserType>(this.url, body, this.options.getAuthorizationHeader()).pipe(
      map((storedUser) => ({
        ...storedUser,
        roleLabel: (RoleLabelType as Record<string, RoleLabelType>)[storedUser.role],
      })),
      errorHandler,
      this.catchErrorAndShowMessage(),
    );
  }

  saveUser(user: User, errorHandler: any = this.catchErrorAndShowMessage()): Observable<User> {
    this.clearCache();
    const body = JSON.stringify(user);
    return this.http.put<User>(this.url, body, this.options.getAuthorizationHeader()).pipe(
      map((storedUser) => ({ ...storedUser, roleLabel: (RoleLabel as Record<string, RoleLabel>)[storedUser.role] })),
      errorHandler,
    );
  }

  logout(): void {
    this.clearCache();
    this.currentUserSubject.next(null);
  }

  private cacheContainsUser(id: number) {
    return this.getCacheValue<User[]>().some((user) => user.id === id);
  }
}
