import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Message, PrimeNGConfig } from 'primeng/api';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { UserType } from './core/state/types';
import { AppState } from './core/state/appState';
import { AuthActions } from './core/state/auth/auth.actions';
import { AuthSelectors } from './core/state/auth/auth.selectors';
import { ToastActions } from './core/state/toast/toast.actions';
import { showTodoDialog } from './shared/misc/showTodoDialog';
import { NotesService } from './core/services/notes.service';
import { openDialog } from './shared/misc/openDialog';
import { FirmSettingsDialog } from './firm-settings/firm.settings.dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  loggedInUser$: Observable<UserType>;

  constructor(
    private router: Router,
    private config: PrimeNGConfig,
    private store: Store<AppState>,
    private notesService: NotesService,
    private dialogService: DialogService,
  ) {
    this.loggedInUser$ = this.store.select(AuthSelectors.selectAuthUser);
  }

  ngOnInit(): void {
    // primeng translation - only calendar is translated for now
    this.config.setTranslation({
      dayNames: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
      dayNamesShort: ['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'],
      dayNamesMin: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
      monthNames: [
        'Januari',
        'Februari',
        'Mars',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'Augusti',
        'September',
        'Oktober',
        'November',
        'December',
      ],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
      today: 'Idag',
      clear: 'Rensa',
      dateFormat: 'yy-mm-dd',
      weekHeader: 'Wk',

      addRule: 'Lägg till regel',
      removeRule: 'Ta bort regel',
      apply: 'Filtrera',
      matchAll: 'Matcha alla',
      matchAny: 'Matcha någon',
      startsWith: 'Börjar med',
      contains: 'Innehåller',
      notContains: 'Innehåller inte',
      endsWith: 'Slutar med',
      equals: 'Lika med',
      notEquals: 'Inte lika med',
      lt: 'Mindre än',
      lte: 'Mindre än eller lika med',
      gt: 'Större än',
      gte: 'Större än eller lika med',
    });
  }

  public logout() {
    this.store.dispatch(ToastActions.clearsAllToastMessages({}));
    this.store.dispatch(AuthActions.resetAuthStateAndStorage());
    this.router.navigate(['login']);
  }

  showNewNoteDialog() {
    showTodoDialog({ notesService: this.notesService, dialogService: this.dialogService, id: -1 });
  }

  showSettingsDialog() {
    openDialog(this.dialogService, FirmSettingsDialog, {});
  }

  handleToastButtonClick(message: Message) {
    message.data?.button?.click?.();
    this.store.dispatch(ToastActions.clearsAllToastMessages({}));
  }
}
