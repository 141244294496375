import { NgClass, NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-table-filter-item',
  template: `
    <div class="flex flex-col ml-4" [ngClass]="containerClass">
      <span class="label-font" *ngIf="label">{{ label }}</span>
      <div><ng-content></ng-content></div>
    </div>
  `,
  styles: [
    `
      .label-font {
        font-size: 12px;
        font-weight: 600;
      }
    `,
  ],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class TableFilterItemComponent {
  @Input() label: string = '';
  @Input() containerClass: string;
  @Input() fill: boolean | '' = false;

  @HostBinding('class.flex-1') get fillWithSpace() {
    return this.fill !== false;
  }
}
