<app-table
  #table
  storageKey="accountingListTable"
  [columns]="columns"
  [data]="list$ | async"
  sortField="client"
  [rowsPerPage]="defaultNumberOfRows"
  exportFilename="Bokforing"
  (pageChange)="paginatorTriggered($event)"
>
  <app-table-filter>
    <app-team-multi-selector localStorageKey="accounting" (OnTeamsInit)="refreshList()"></app-team-multi-selector>
    <app-table-filter-item label="Klientansvarig"
      ><app-user-selector
        [(userId)]="urlFilter.selectedClientResponsibleUserId"
        styleClass="min-w-48"
      ></app-user-selector
    ></app-table-filter-item>
    <app-table-filter-item label="Utförande handläggare">
      <app-user-selector [(userId)]="urlFilter.selectedTaskUserId" styleClass="min-w-48"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item label="Urvalskriterier">
      <p-dropdown
        [options]="[
          { label: 'Momsinlämningsmånad', value: 1 },
          { label: 'Sista bokföringsmånad', value: 2 }
        ]"
        [(ngModel)]="urlFilter.searchCriteria"
        optionValue="value"
        styleClass="min-w-48"
        [autoDisplayFirst]="false"
      ></p-dropdown>
    </app-table-filter-item>
    <app-table-filter-item containerClass="ml-1-important">
      <app-month-selector [(month)]="urlFilter.selectedMonth"></app-month-selector>
    </app-table-filter-item>
    <app-table-filter-item containerClass="ml-1-important">
      <app-year-selector [(year)]="urlFilter.selectedYear"></app-year-selector>
    </app-table-filter-item>
    <app-table-filter-item label="Periodicitet">
      <p-dropdown
        [options]="[
          { label: '- Alla -', value: '- Alla -' },
          { label: 'Månad', value: 'Månad' },
          { label: 'Kvartal', value: 'Kvartal' },
          { label: 'År', value: 'År' }
        ]"
        [(ngModel)]="filter.periodicy"
        (ngModelChange)="filterList()"
        optionValue="value"
        styleClass="min-w-24"
      ></p-dropdown>
    </app-table-filter-item>
    <app-table-filter-item>
      <app-refresh-button [loading]="loading$ | async" (onClick)="refreshList()"></app-refresh-button>
    </app-table-filter-item>
    <app-table-filter-item>
      <p-checkbox
        [binary]="true"
        [(ngModel)]="filter.includeAllDoneClients"
        (ngModelChange)="filterList()"
        [label]="includeFinishedLabel$ | async"
      ></p-checkbox>
    </app-table-filter-item>
  </app-table-filter>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="client" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">{{ client.client }}</a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="corporateIdentity" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">
      {{ client.corporateIdentity }}
    </a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="customerNumber" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">
      {{ client.customerNumber }}
    </a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="accountingMaterial.when" let-data="rowData">
    <div
      class="cursor-pointer text-center truncate"
      (click)="onShowActivity(data.bokf)"
      [style.color]="data.materialStatusColor"
    >
      <app-status-icon
        [status]="data.accountingMaterial.alertLevel.name"
        [color]="data.materialStatusColor"
        [title]="data.accountingMaterial.alertLevel.description"
      ></app-status-icon>
      {{ data.accountingMaterial.when }}
    </div>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="dags.activity.alertLevel.description"
    let-activity="rowData.dags"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="bokf.activity.alertLevel.description"
    let-activity="rowData.bokf"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="kont.activity.alertLevel.description"
    let-activity="rowData.kont"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="avst.activity.alertLevel.description"
    let-activity="rowData.avst"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="rapp.activity.alertLevel.description"
    let-activity="rowData.rapp"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="program" let-data="rowData">
    <div class="text-center">
      <app-bla-icon-button [composite]="data"></app-bla-icon-button>
    </div>
  </ng-template>

  <ng-template tableTemplate="emptyMessage">{{ statusText$ | async }}</ng-template>
  <ng-template tableTemplate="summary" let-data>
    <div class="relative">
      Antal aktuella klienter: {{ data.length }}
      <div class="absolute export-button-wrapper">
        <p-button
          icon="pi pi-download"
          class="export-button"
          size="small"
          label="Exportera lista"
          (click)="table.exportCSV()"
          title="Ladda ner listan för import till Excel"
        ></p-button>
      </div>
    </div>
  </ng-template>

  <ng-template #defaultCompositeColumn let-activity>
    <div
      class="text-center cursor-pointer truncate"
      (click)="onShowActivity(activity)"
      [style.color]="activity.activity.stateColor"
    >
      <app-activity-comment-icon [title]="activity.activity.comment"></app-activity-comment-icon>
      <app-task-comment-icon [title]="activity.task.comment"></app-task-comment-icon>
      <app-status-icon
        [status]="activity.activity.alertLevel.name"
        [color]="activity.activity.stateColor"
        [title]="activity.activity.alertLevel.description"
      ></app-status-icon>
      {{ activity.activity.state.description }}
    </div>
  </ng-template>
</app-table>
