import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Client } from '@app/core/entity/client';
import { AllInclusiveService } from '@app/core/services/all-inclusive.service';
import { Store } from '@ngrx/store';
import { CompositeBase } from 'src/app/core/entity/composite.base';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { CompositeBaseType } from 'src/app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { ClientService } from '@app/core/services/clients.service';
import { ActivityComposite } from '@app/core/entity/activity.composite';
import { TaskService } from '@app/core/services/task.service';
import { getAgentUrl } from '@app/core/state/shared/shared';
import { EMPTY, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-bla-icon-button',
  template: `
    <div (click)="onClick()" class="cursor-pointer"><img [src]="blaIconUrl" class="inline" /> {{ title }}</div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlaIconButtonComponent {
  @Input() composite: CompositeBase | CompositeBaseType;
  @Input() activityComposite: ActivityComposite;
  @Input() client: Client;
  @Input() disabled = false;
  @Input() title = '';

  get isAllInclusiveStatus(): boolean {
    const allInclusiveCheck = this.hhl.isLoggedInWithAnyAllInclusiveService;
    return allInclusiveCheck;
  }

  get blaIconUrl() {
    return this.isAllInclusiveStatus ? '/assets/bla20.png' : '/assets/bla20_disabled.png';
  }

  constructor(
    private store: Store<AppState>,
    private hhl: AllInclusiveService,
    private clientService: ClientService,
    private taskService: TaskService,
  ) {}

  onClick(): void {
    if (!this.isAllInclusiveStatus) {
      this.showAccessDeniedInfo();
      return;
    }

    this.getAgentUrlObservable().subscribe((url) => window.location.assign(url));
  }

  private showAccessDeniedInfo() {
    this.store.dispatch(
      ToastActions.showWarnMessage({
        summary: 'Rättigheter saknas',
        detail: 'Din användare saknar rättighet att starta BL Administration från Byråstöd',
      }),
    );
  }

  private getAgentUrlObservable(): Observable<string> {
    if (this.client) {
      return this.getAgentUrlByClientId(this.client.id);
    }

    if (this.composite) {
      return this.getAgentUrlByComposite(this.composite);
    }

    if (this.activityComposite) {
      return this.getAgentUrlByActivity(this.activityComposite);
    }

    return EMPTY;
  }

  private getAgentUrlByComposite(composite: CompositeBase | CompositeBaseType): Observable<string> {
    if (this.isCompositeBase(composite)) {
      return of(composite.getAgentUrl(true));
    }
    return of(getAgentUrl(composite, true));
  }

  private getAgentUrlByClientId(clientId: number): Observable<string> {
    return this.clientService.getAgentUrl(clientId).pipe(
      map(({ agentCall }) => {
        let href = agentCall;
        const year = new Date().getUTCFullYear();
        const financialYear = `financialYear=${year}0101-${year}1231&`;

        href = href.replace('blinfoa://', `blinfoa://${financialYear}`);
        href = href.replace('&loginId=', '&task=&loginId=');
        const jwt = sessionStorage.getItem('jwt') || '';
        href += `&jwt=${jwt}`;

        return href;
      }),
    );
  }

  private getAgentUrlByActivity(activityComposite: ActivityComposite): Observable<string> {
    return this.taskService.getAgentUrl(activityComposite.task.id, activityComposite.activity.span.start).pipe(
      map((reply) => {
        let path = reply.agentCall;
        path += `&jwt=${sessionStorage.getItem('jwt') || ''}`;
        return path;
      }),
    );
  }

  private isCompositeBase(composite: CompositeBase | CompositeBaseType): composite is CompositeBase {
    return !!(<CompositeBase>composite).getAgentUrl;
  }
}
