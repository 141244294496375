<ng-container *ngIf="!hideAdvisoryGuide">
  <app-advisory-tool-onboarding (dontShow)="hideOnboarding($event)"></app-advisory-tool-onboarding>
  <div class="border-b my-16 border-bl-grey-600"></div>
</ng-container>
<!-- <main *ngIf="notifications$ | async as notifications" class="flex flex-col mx-8 my-4"> -->
<main class="flex flex-col mx-8 my-4">
  <section class="flex justify-between items-start">
    <div class="shadow-md rounded p-4">
      <app-advisory-toolbar
        [showNotificationsCount]="showNotificationsCount$ | async"
        [filter]="filter$ | async"
        (showCountInTab)="showNotificationsCount($event)"
        (adviceFilter)="onAdviceToggle($event)"
        (clearedFilter)="onClearedToggle($event)"
      >
      </app-advisory-toolbar>
    </div>
    <div class="w-1/3 shadow-md rounded p-4 z-10">
      <app-advisory-last-updated
        (triggerUpdate)="onCompanyTrigger($event)"
        [data]="getLastUpdatedClients$ | async"
      ></app-advisory-last-updated>
    </div>
  </section>

  <section *ngIf="notifications$ | async as notifications" class="-mt-12">
    <ng-container>
      <app-advisory-tool-table
        [columns]="cols"
        [tableData]="notifications"
        (tableColumnClick)="tableColumnClick($event)"
        (adviceFilter)="onAdviceToggle($any($event))"
        (clearedFilter)="onClearedToggle($any($event))"
      >
      </app-advisory-tool-table>
    </ng-container>
    <ng-container *ngIf="notifications.length === 0">
      <p class="!mt-32 text-2xl text-center font-bold">
        Det verkar inte finnas några notiser för dina klienter med aktiverat Rådgivarverktyg
      </p>
    </ng-container>
  </section>
</main>
