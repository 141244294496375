<main class="max-w-screen-xl">
  <app-partnerprogram-signup [firm]="firm"></app-partnerprogram-signup>
  <app-partnerprogram-fallenout-alert [firm]="firm" (openTab)="openTab.emit($event)"></app-partnerprogram-fallenout-alert>
  <div class="w-full mb-6 grid grid-cols-2 gap-4" *ngIf="isPartner">
    <div>
      <app-partnerprogram-profile-card [column]="false">
        <app-partnerprogram-cert-card [level]="level" [hasFallenOut]="hasFallenOut"></app-partnerprogram-cert-card>
        <app-partnerprogram-current-benefits
          [firm]="firm"
          [hasFallenOut]="hasFallenOut"></app-partnerprogram-current-benefits>
      </app-partnerprogram-profile-card>
    </div>
    <div>
      <app-partnerprogram-keycert-card [firm]="firm" [hasFallenOut]="hasFallenOut"></app-partnerprogram-keycert-card>
    </div>
  </div>
  <div class="w-full mb-6">
    <app-partnerprogram-progressbar
      [levels]="levels"
      [firm]="firm"
      [hasFallenOut]="hasFallenOut"></app-partnerprogram-progressbar>
  </div>
  <div class="w-full mb-14" *ngIf="isPartner">
    <app-partnerprogram-profile-card>
      <app-partnerprogram-chart [hasFallenOut]="hasFallenOut"></app-partnerprogram-chart>
    </app-partnerprogram-profile-card>
  </div>
  <div class="flex justify-between gap-4 mb-5 w-full">
    <div class="w-1/2">
      <app-partnerprogram-profile-card [header]="'Så här fungerar Partnerprogrammet'">
        <div>
          <p class="mt-5">
            Koppla
            <bl-frontend-tooltip [heading]="'BL Pro-paket'" [content]="blProTooltipContent">
              <strong>BL Pro-</strong>
            </bl-frontend-tooltip>
            och
            <bl-frontend-tooltip [heading]="'BL Essential-paket'" [content]="blEssentialsTooltipContent">
              <strong>BL Essential-paket</strong>
            </bl-frontend-tooltip>
            till dina klienter för att nå högre nivåer i Partnerprogrammet. Ju högre nivå du når desto fler och bättre
            blir dina förmåner. De finansiella förmånerna är återbäring baserad på kopplade BL Pro-paket och rabatt på
            böcker och kurser. Du får även förmåner som personliga sälj- och supportkontakter, affärsdrivande
            utbildningar och nätverk samt tillgång till ett smörgåsbord av färdiga marknadsföringsmaterial.
          </p>
        </div>
        <div class="space-x-4" footer>
          <a class="hover:underline cursor-pointer" (click)="openTab.emit(1)">Förmånsnivåer</a>
          <a href="https://www.bjornlunden.se/redovisningsbyr%C3%A5/partnerprogram/policy__8985" target="_blank">
            Villkor för Partnerprogrammet
          </a>
        </div>
      </app-partnerprogram-profile-card>
    </div>
    <div class="w-1/2">
      <app-partnerprogram-profile-card header="Vanliga frågor">
        <app-partnerprogram-faq [firm]="firm" [isPartner]="isPartner" (openTab)="openTab.emit($event)"></app-partnerprogram-faq>
      </app-partnerprogram-profile-card>
    </div>
  </div>
</main>
