import { NgModule } from '@angular/core';

import { InputNumberModule } from 'primeng/inputnumber';
import { FilterService, MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { InputMaskModule } from 'primeng/inputmask';
@NgModule({
  imports: [
    SharedModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ContextMenuModule,
    DialogModule,
    DragDropModule,
    DropdownModule,
    FileUploadModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MessagesModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    ScrollPanelModule,
    TooltipModule,
    TableModule,
    ToastModule,
    DividerModule,
    ConfirmDialogModule,
    InputMaskModule,
  ],
  exports: [
    SharedModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ContextMenuModule,
    DialogModule,
    DragDropModule,
    DropdownModule,
    FileUploadModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MessagesModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    ScrollPanelModule,
    TooltipModule,
    TableModule,
    ToastModule,
    DividerModule,
    ConfirmDialogModule,
    InputMaskModule,
  ],
  providers: [
    MessageService, // primeng - Toast
    FilterService,
    ConfirmationService,
  ],
})
export class PrimeNgModule {}
