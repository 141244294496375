import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ClientTypeType } from '../types';

export const ClientTypesActions = createActionGroup({
  source: 'ClientTypes',
  events: {
    'Load All Client Types': emptyProps(),

    'Load Client Types Succeeded': props<{ types: ClientTypeType[] }>(),

    'Load Client Types Failed': props<{ error: any }>(),
  },
});
