import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../state/appState';

@Injectable()
export class ImportService extends BLService {
  private options = new HttpAuthorizationHeader('application/text; charset=utf-8');
  private RESOURCE_ENDPOINT = 'client/';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  public sendFileContentToImport(
    body: string,
    importParameters: {
      includeEmptyOrgNumbers: string;
      overWriteExistingOrgNumbers: string;
      clientResponsible: string;
      templatesDate: string;
    },
  ): any {
    const { includeEmptyOrgNumbers, overWriteExistingOrgNumbers, clientResponsible, templatesDate } = importParameters;

    const tempUrl = `${this.url}import/${includeEmptyOrgNumbers}/${overWriteExistingOrgNumbers}/${clientResponsible}/${templatesDate}`;
    return this.http.post(tempUrl, body, this.options.getAuthorizationHeader()).pipe(this.catchErrorAndShowMessage());
  }

  public sendFileContentToDeferalImport(body: string, clearAll: boolean): any {
    const tempUrl = `${this.url}defer/${clearAll}`;
    return this.http
      .post<any>(tempUrl, body, this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }
}
