import { createSelector } from '@ngrx/store';
import { TeamsSelectors } from '../teams/teams.selectors';
import { LoadingStatus } from '../types';
import { AppState } from '../appState';

const list = (state: AppState) => state.list;

const getItems = <EntityType extends Object, FilterType extends Object, ParamsType extends Object>() =>
  createSelector(list, (state) => {
    return {
      items: state.items as EntityType[],
      filter: state.filter as FilterType,
      params: state.params as ParamsType,
    };
  });

const getFilters = <FilterType extends Object, ParamsType extends Object>() =>
  createSelector(list, (state) => {
    return {
      filter: state.filter as FilterType,
      params: state.params as ParamsType,
    };
  });

const getStatus = createSelector(list, (state) => state.status);
const getStatusText = createSelector(list, ({ status, items, error }) => {
  if (status === LoadingStatus.NotStarted && !items.length) {
    return 'Inga rader finns att visa';
  }

  if (status === LoadingStatus.Loading) {
    return 'Listan uppdateras...';
  }

  if (status === LoadingStatus.Failed) {
    return `Något gick fel ${error?.message}`;
  }

  return '';
});
const getError = createSelector(list, (state) => state.error);
const selectedTeams = createSelector(
  list,
  TeamsSelectors.selectTeamsInitialized,
  TeamsSelectors.selectTeams,
  (listState, initialized, teams) => ({
    ids: listState.selectedTeams ?? teams?.map((t) => t.id),
    initialized,
  }),
);
const isLoading = createSelector(getStatus, (status) => status === LoadingStatus.Loading);

export const ListSelectors = {
  getFilters,
  getItems,
  getStatus,
  getError,
  getStatusText,
  selectedTeams,
  isLoading,
};
