import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  template: `
    <div [hidden]="!active">
      <ng-content></ng-content>
    </div>
  `,
})
export class TabComponent {
  @Input('tabTitle') title: string;
  @Input('tabTooltip') tooltip: string;
  @Input()
  get active() {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
    this.activeChange.emit(value);
  }

  @Input() route: string = null;
  @Input() disabled = false;
  @Input() newFeature = false;

  @Output() activeChange: EventEmitter<boolean> = new EventEmitter();

  private _active = false;
}
