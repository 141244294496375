<div style="margin: auto; width: 35%">
  <p-dialog
    [visible]="true"
    [style]="{ width: '600px' }"
    [resizable]="false"
    [closable]="false"
    [modal]="true"
    [showHeader]="false"
    [draggable]="false"
  >
    <h3 class="text-center mt-12">Varmt välkommen till BL Byråstöd</h3>
    <div *ngIf="!(loggingIn$ | async); else loggingInTemplate">
      <p style="font-size: 120%; text-align: center">
        Logga in för att fortsätta!<br /><br /><i
          class="fas fa-sign-in-alt fa-5x faa-pulse animated"
          aria-hidden="true"
          (click)="login()"
          title="Logga in"
          style="padding-right: 20px; cursor: pointer; color: green"
        ></i>
      </p>
      <p *ngIf="!isChrome" style="margin-bottom: -20px" class="text-center font-bold italic">
        <a href="https://www.google.com/intl/sv/chrome/browser/desktop/index.html" target="_blank" style="color: black"
          >Vi rekommenderar Google Chrome för bästa upplevelse</a
        >
      </p>
    </div>
    <ng-template #loggingInTemplate>
      <div style="font-size: 120%; text-align: center" class="pt-12 pb-4">
        <div class="flex justify-center">
          <img src="./assets/ajax-loader.gif" alt="Laddar BL Byråstöd, var god vänta..." />
        </div>
        <br />Loggar in, vänligen vänta...
      </div>
    </ng-template>
    <br />
    <div class="text-center">
      <div class="flex justify-center">
        <img class="py-4" src="/assets/BL-Landscape.png" alt="Bjorn Lunden" />
      </div>
      e-post: info.se&#64;bjornlunden.com | tel: 0650-54 14 00
    </div>
  </p-dialog>
</div>
