import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-client-archive-client-dialog',
  template: `
    <p-dialog
      [header]="header"
      [modal]="true"
      [dismissableMask]="true"
      [visible]="visible"
      (visibleChange)="onVisibleChange($event)"
      showEffect="fade"
      [style]="{ width: '400px' }"
      [draggable]="false"
    >
      <p>
        Klienten har sparade aktiviteter och kan inte tas bort. Upplagda tjänster kan däremot sättas som avslutade och
        klienten kan arkiveras.
      </p>
      <p>
        Vill du sätta slutdatum på upplagda tjänster till ett datum i den senast sparade perioden och ta bort tjänster
        som inte använts alls?
      </p>
      <p-footer>
        <button type="button" class="btn btn-success" (click)="onConfirmClicked()">Ja</button>
        <button type="button" class="btn btn-warning" (click)="onCancelClicked()">Nej</button>
      </p-footer>
    </p-dialog>
  `,
})
export class ArchiveClientDialog {
  @Input() visible = false;
  @Input() name: string;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  public get header(): string {
    return `Arkivera klient: ${this.name}?`;
  }

  public onVisibleChange(visible: boolean) {
    this.visibleChange.emit(visible);
  }

  public onConfirmClicked() {
    this.onConfirm.emit();
  }

  public onCancelClicked() {
    this.onCancel.emit();
  }
}
