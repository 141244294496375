import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CLIENT_RESPONSIBLE } from '@app/shared/misc/constants';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Client } from 'src/app/core/entity/client';
import { ClientService } from 'src/app/core/services/clients.service';
import { UserType } from 'src/app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';
import { TableColumnWithFilter } from 'src/app/shared/components/table/interfaces/table-column';
import { StateFacade } from '../../../facades/state.facade';
import { BulkChangeComponentData } from '../../../interfaces/bulk-change.component';
import { BaseChangeComponent } from '../base-change.component';

@Component({
  template: `<div [formGroup]="formGroup">
    <p>Vad ska det ändras till?</p>
    <app-user-selector
      formControlName="toUserId"
      placeholder="Välj användare"
      [includeAllItem]="false"></app-user-selector>
  </div>`,
})
export class ChangeResponsibleUserComponent extends BaseChangeComponent {
  public filterAvailableUsers$: Observable<UserType[]>;
  public scrollHeight: string;

  constructor(stateFacade: StateFacade, private clientService: ClientService, private store: Store<AppState>) {
    super(stateFacade);
    this.filterAvailableUsers$ = this.store.select(UserSelectors.activeUsersWithMissingResponsible);
  }

  protected load(): Observable<BulkChangeComponentData> {
    this.formGroup.disable({ emitEvent: false });

    return this.clientService.getAllClients().pipe(
      map((clients: Client[]) => clients.filter((client) => !client.archived)),
      map((clients: Client[]) => ({ tableData: clients, dataToChange: this.formGroup.value })),
      finalize(() => {
        this.formGroup.enable({ emitEvent: false });
      }),
    );
  }

  protected createForm() {
    return new UntypedFormGroup({
      toUserId: new UntypedFormControl(null, Validators.required),
    });
  }

  protected getDataIdentityKey(): string {
    return 'id';
  }

  protected getTableColumns(): TableColumnWithFilter[] {
    return [
      { field: 'name', header: 'Klient', filter: { type: 'text' } },
      { field: 'corporateIdentity', header: 'Org.nr', filter: { type: 'text' } },
      { field: 'customerNumber', header: 'Kundnr', filter: { type: 'text' } },
      {
        field: 'type.description',
        header: 'Typ',
        filter: {
          type: 'multidropdown',
          data: this.clientService.getClientsTypes(),
          dataLabel: 'description',
          dataValue: 'description',
          dataSelectedItemsLabel: '{0} typer valda',
        },
      },
      {
        field: 'responsible',
        header: CLIENT_RESPONSIBLE,
        fieldValue: (responsible) => responsible?.name,
        filter: {
          type: 'multidropdown',
          data: this.filterAvailableUsers$,
          dataLabel: 'name',
          dataValue: 'id',
        },
      },
    ];
  }
}
