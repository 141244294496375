import { DialogService } from 'primeng/dynamicdialog';
import { first, mergeMap, Observable } from 'rxjs';
import { TodoDialog, TodoDialogResult } from '@app/todo/todo.dialog';
import { openDialog } from './openDialog';
import { NotesService } from '@app/core/services/notes.service';
import { initialTodoType } from '@app/core/state/types/todo.types';
import { SimpleClientType } from '@app/core/state/types';

export const showTodoDialog = ({
  notesService,
  dialogService,
  id,
  client,
}: {
  notesService: NotesService;
  dialogService: DialogService;
  id: number;
  client?: SimpleClientType;
}): Observable<TodoDialogResult> => {
  if (id === -1) {
    return openDialog(dialogService, TodoDialog, { todo: { ...initialTodoType, client: client ?? null } });
  }

  return notesService.getById(id).pipe(
    first(),
    mergeMap((todo) => openDialog(dialogService, TodoDialog, { todo })),
  );
};
