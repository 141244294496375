import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { Client } from 'src/app/core/entity/client';
import { Document } from 'src/app/core/entity/document';
import { DocumentService } from 'src/app/core/services/document.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { fileNameNormalizer } from '@app/shared/misc/fileNameNormalizer';
import { TableRowUnSelectEvent } from 'primeng/table';

@Component({
  selector: 'app-file-browser',
  templateUrl: `./file.browser.component.html`,
  providers: [DocumentService],
})
export class FileBrowserComponent {
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;
  @Input()
  set active(value: boolean) {
    if (value === false) {
      return;
    }

    this.loadFiles();
  }

  hidePleaseWait = true;
  displayDeleteFile = false;
  displayRenameDialog = false;
  loading = false;
  sendingFiles = false;
  directory = '';
  currentRealPath = '';
  currentVisualPath = '/';
  files: Document[] = [];
  selectedRow: Document;
  pendingDeletionDocument: Document = new Document();
  pendingRenameDocument: Document = new Document();
  refreshIcon = 'fa fas fa-sync';
  _client: Client;
  contextMenuItems: MenuItem[];
  columns = [{ field: 'name', header: 'Filnamn', sortable: true }, { field: 'file' }];
  private zip: JSZip;

  constructor(private documentService: DocumentService, private store: Store<AppState>) {
    this.contextMenuItems = [
      {
        label: 'Öppna',
        icon: 'fa-folder-open',
        command: () => this.contextMenuOpen(),
      },
      {
        label: 'Byt namn',
        icon: 'fa-pencil-square',
        command: () => this.changeName(this.selectedRow),
      },
      {
        label: 'Ta bort',
        icon: 'fa-trash',
        command: () => this.deleteFile(this.selectedRow),
      },
    ];
  }

  @Input()
  set client(client: Client) {
    this.sendingFiles = false;
    this.hidePleaseWait = true;
    if (client && client.id !== -1) {
      this._client = client;
    } else {
      this.currentRealPath = '';
      this.currentVisualPath = '';
      this.files = [];
      this._client = undefined;
    }
  }

  loadFiles() {
    this.reloadFilesAtPath('');
  }

  reloadFilesAtPath(path: string) {
    this.refreshIcon = 'fa fas fa-sync fa-spin';
    this.loading = true;
    this.documentService.getFilesAndFolders(this._client.id, path ? path + '|' : '').subscribe((docs) => {
      this.files = docs;
      this.currentRealPath = path ? (path[path.length - 1] === '|' ? path : path + '|') : '';
      this.currentVisualPath = path ? '/' + this.getPrettyPath(path) + '/' : '/';
      this.selectedRow = undefined;
      this.refreshIcon = 'fa fas fa-sync';
      this.loading = false;
    });
  }

  getPrettyPath(path: string) {
    return this.getPath(path, true);
  }

  getPath(path: string, pretty: boolean) {
    while (path.indexOf(pretty ? '|' : '/') !== -1) {
      path = path.replace(pretty ? '|' : '/', pretty ? '/' : '|');
    }
    return path[path.length - 1] !== (pretty ? '/' : '|') ? path : path.substring(0, path.length - 1);
  }

  drop(event: any) {
    this.refreshIcon = 'fa fas fa-sync fa-spin';
    this.hidePleaseWait = false;
    event.preventDefault();
    event.stopPropagation();

    if (this.sendingFiles) {
      this.store.dispatch(
        ToastActions.showWarnMessage({
          summary: 'Upptagen',
          detail: 'Sparar redan filer - var god försök igen senare',
        }),
      );
      return;
    }

    this.sendingFiles = true;
    this.zip = new JSZip();
    const items = Array.from<any>(event.dataTransfer.items);
    const entries = items.map((item) => item.webkitGetAsEntry());
    this.buildTree(entries, '')
      .then((tree) => {
        if (tree.files.length > 0) {
          this.parseFilesFromTree(tree.files, '');
        }
        if (tree.directories.length > 0) {
          this.parseDirectoriesFromTree(tree.directories, '');
        }
        let me = this;
        this.zip
          .generateAsync({ type: 'blob' })
          .then(function (content: any) {
            let file = new File([content], 'upload-pick-data.zip', { type: 'application/x-www-form-urlencoded' });
            me.fileSaver([file]);
          })
          .catch((error: any) => {
            this.handleError(error);
          });
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  private parseFilesFromTree(files: any, path: string) {
    files.forEach((file: any) => {
      if (file.name === '.DS_Store') {
        return;
      }
      const fullPath = fileNameNormalizer(fileNameNormalizer(path + '/' + file.name));
      this.zip.file(fullPath, file, { createFolders: true });
    });
  }

  private parseDirectoriesFromTree(directories: any, path: string) {
    directories.forEach((directory: any) => {
      if (directory.files.length > 0) {
        this.parseFilesFromTree(directory.files, path + '/' + directory.name);
      }
      const fullPath = fileNameNormalizer(fileNameNormalizer(path + '/' + directory.name));

      this.zip.folder(fullPath); //path + '/' + directory.name);
      this.parseDirectoriesFromTree(directory.directories, path + '/' + directory.name);
    });
  }

  private async buildTree(entries: any, name: string) {
    const tree: { name: string; files: any[]; directories: any[] } = { name, files: [], directories: [] };
    const promises: Promise<any>[] = [];
    entries.forEach((entry: any) => {
      if (entry.isFile) {
        const promise = this.parseFileEntry(entry)
          .then((file) => {
            tree.files.push(file);
          })
          .catch((error) => {
            this.handleError(error);
          });
        promises.push(promise);
      } else if (entry.isDirectory) {
        const promise = this.parseDirectoryEntry(entry)
          .then((directory) => {
            tree.directories.push(directory);
          })
          .catch((error) => {
            this.handleError(error);
          });
        promises.push(promise);
      }
    });

    await Promise.all(promises);
    return tree;
  }

  private parseFileEntry(fileEntry: any) {
    return new Promise<any>((resolve, reject) => {
      fileEntry.file(
        (file: any) => {
          resolve(file);
        },
        (error: any) => {
          reject(error);
        },
      );
    });
  }

  private parseDirectoryEntry(directoryEntry: any) {
    const directoryReader = directoryEntry.createReader();
    return new Promise<any>((resolve, reject) => {
      directoryReader.readEntries(
        (entries: any) => {
          resolve(this.buildTree(entries, directoryEntry.name));
        },
        (error: any) => {
          reject(error);
        },
      );
    });
  }

  dragEnter(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  dragOver(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.effectAllowed = 'copyMove';
    this.refreshIcon = 'fa fas fa-sync fa-spin';
  }

  dragLeave(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.refreshIcon = 'fa fas fa-sync';
    this.hidePleaseWait = true;
  }

  private fileSaver(files: File[]) {
    if (files.length === 0) {
      return;
    }

    if (files.length === 1) {
      const file = files.pop();
      if (file.size > 40000000) {
        this.refreshIcon = 'fa fas fa-sync';
        this.hidePleaseWait = true;
        this.sendingFiles = false;
        this.store.dispatch(
          ToastActions.showWarnMessage({
            summary: 'Stor fil',
            detail: 'Filen är för stor. Prova att komprimera denna innan sparande eller försök med en mindre fil.',
          }),
        );
        return;
      }

      this.documentService.saveFile(this._client.id, this.currentRealPath, file).subscribe(
        (res) => {
          if (res.body && files.length === 0) {
            this.refreshIcon = 'fa fas fa-sync';
            this.hidePleaseWait = true;
            this.reloadFilesAtPath(this.currentRealPath);
            this.store.dispatch(
              ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Filen/filerna har sparats i molnet' }),
            );
            this.sendingFiles = false;
          } else {
            this.fileSaver(files);
          }
        },
        (err: unknown) => {
          console.log(err);
          this.store.dispatch(
            ToastActions.showWarnMessage({
              summary: 'Filen kunde inte sparas',
              detail: 'Kanske är filen för stor eller så har ett annat fel uppståt.',
            }),
          );
          this.reloadFilesAtPath(this.currentRealPath);
          this.hidePleaseWait = true;
          this.sendingFiles = false;
        },
      );
    }
  }

  onRowUnselect(event: TableRowUnSelectEvent) {
    setTimeout(() => {
      this.selectedRow = event.data;
    }, 0);
  }

  onRowDblClick(selectedFile: any) {
    this.selectedRow = selectedFile;
    if (this.selectedRow.isFolder) {
      this.reloadFilesAtPath(this.currentRealPath + this.selectedRow.name);
    } else {
      this.documentService.getFile(this._client.id, this.currentRealPath + selectedFile.name).subscribe((file) => {
        FileSaver.saveAs(new Blob([file]), selectedFile.name);
      });
    }
  }

  upDir() {
    const upPath = this.currentRealPath.substring(
      0,
      this.currentRealPath.substring(0, this.currentRealPath.length - 1).lastIndexOf('|'),
    );
    this.reloadFilesAtPath(upPath);
  }

  createDir() {
    this.refreshIcon = 'fa fas fa-sync fa-spin';
    this.sendingFiles = true;
    this.documentService.createDirectory(this._client.id, this.currentRealPath, this.directory).subscribe(() => {
      this.reloadFilesAtPath(this.currentRealPath);
      this.store.dispatch(
        ToastActions.showInfoMessage({
          summary: 'Skapad',
          detail: 'Mappen ' + fileNameNormalizer(this.directory) + ' har skapats',
        }),
      );
      this.directory = undefined;
      this.sendingFiles = false;
    });
  }

  deleteFile(document: Document) {
    this.pendingDeletionDocument = document;
    this.displayDeleteFile = true;
  }

  afterDeleteDialog(proceedWithDelete: boolean) {
    this.displayDeleteFile = false;
    if (proceedWithDelete) {
      this.refreshIcon = 'fa fas fa-sync fa-spin';
      this.hidePleaseWait = false;
      this.sendingFiles = true;
      this.documentService
        .deleteFileOrFolder(
          this._client.id,
          this.getPath(
            this.pendingDeletionDocument.path.substring(1, this.pendingDeletionDocument.path.length),
            false,
          ) + (this.pendingDeletionDocument.isFolder ? '|' : ''),
        )
        .subscribe(() => {
          this.reloadFilesAtPath(this.currentRealPath);
          this.store.dispatch(
            ToastActions.showInfoMessage({
              summary: 'Borttaget',
              detail: 'Filen/mappen ' + this.pendingDeletionDocument.name + ' har tagits bort',
            }),
          );
          this.pendingDeletionDocument = new Document();
          this.sendingFiles = false;
          this.refreshIcon = 'fa fas fa-sync';
          this.hidePleaseWait = true;
        });
    } else {
      this.pendingDeletionDocument = new Document();
    }
  }

  private changeName(document: Document) {
    this.pendingRenameDocument = JSON.parse(JSON.stringify(document));
    this.displayRenameDialog = true;
  }

  afterRenameDialog(proceedWithRename: boolean) {
    this.displayRenameDialog = false;
    if (proceedWithRename) {
      this.sendingFiles = true;
      this.documentService
        .renameFileOrFolder(
          this._client.id,
          this.getPath(this.pendingRenameDocument.path.substring(1, this.pendingRenameDocument.path.length), false) +
            (this.pendingRenameDocument.isFolder ? '|' : ''),
          this.pendingRenameDocument.name,
        )
        .subscribe(() => {
          this.store.dispatch(
            ToastActions.showInfoMessage({
              summary: 'Namnbyte',
              detail: 'Nytt namn har sparats: ' + fileNameNormalizer(this.pendingRenameDocument.name),
            }),
          );
          this.reloadFilesAtPath(this.currentRealPath);
          this.pendingRenameDocument = new Document();
          this.sendingFiles = false;
        });
    } else {
      this.reloadFilesAtPath(this.currentRealPath);
      this.pendingRenameDocument = new Document();
    }
  }

  private handleError(error: any) {
    console.error('Fel vid uppladdning av fil', error);
    this.sendingFiles = false;
    this.hidePleaseWait = true;
    this.store.dispatch(
      ToastActions.showErrorMessage({
        summary: 'Ett fel har uppstått',
        detail: 'Tryck F5 för att ladda om applikationen. Om felet kvarstår, kontakta Support.',
      }),
    );
  }

  private contextMenuOpen() {
    this.onRowDblClick(this.selectedRow);
  }

  onUploadButtonClicked() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.click();
  }

  onUploadFile(event: any) {
    const files = Array.from(event.target.files);
    this.hidePleaseWait = false;
    this.sendingFiles = true;

    this.zip = new JSZip();
    this.parseFilesFromTree(files, '');

    this.zip
      .generateAsync({ type: 'blob' })
      .then((content: any) => {
        const file = new File([content], 'upload-pick-data.zip', { type: 'application/x-www-form-urlencoded' });
        this.fileSaver([file]);
      })
      .catch((error: any) => {
        this.handleError(error);
      });
  }
}
