import { createSelector } from '@ngrx/store';
import { clientTasksFeature } from './clientTasks.reducer';
import { LoadingStatus } from '../types';

const { selectTasks, selectStatus } = clientTasksFeature;

const loading = createSelector(selectStatus, (status) => status === LoadingStatus.Loading);

export const ClientTaskSelectors = {
  tasks: selectTasks,
  loading,
};
