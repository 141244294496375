import { Injectable } from '@angular/core';
import { PeriodService } from '@app/core/services/period.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { RuleActions } from './rule.actions';

@Injectable()
export class RuleEffects {
  loadRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.load),
      switchMap(({ period }) =>
        this.periodService.getAvailableRuleTypes(period).pipe(
          map((rules) => RuleActions.loadSucceeded({ rules })),
          catchError((error: unknown) => of(RuleActions.loadFailed({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private periodService: PeriodService) {}
}
