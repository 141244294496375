export enum ValidationStatus {
  Ok = 'Ok',
  MissingValue = 'MissingValue',
  NegativeValue = 'NegativeValue',
  LessThanMin = 'LessThanMin',
  GreaterThanMax = 'GreaterThanMax',
  InvalidDateFormat = 'InvalidDateFormat',
  InvalidNumber = 'InvalidNumber',
  InvalidDate = 'InvalidDate',
  BeforeMinDate = 'BeforeMinDate',
  AfterMaxDate = 'AfterMaxDate',
}
