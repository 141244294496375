import { createReducer, on } from '@ngrx/store';
import { ClientType } from '../types';
import { ClientActions } from './clients.actions';

interface States {
  loading: boolean | null;
  deleting: boolean | null;
  archiving: boolean | null;
  activating: boolean | null;
  deactivating: boolean | null;
  reactivating: boolean | null;
}

const initialStatesState: States = {
  loading: null,
  deleting: null,
  archiving: null,
  activating: null,
  deactivating: null,
  reactivating: null,
};

export interface ClientsState extends States {
  clients: ClientType[];
  error: unknown;
}

export const initialClientsState: ClientsState = {
  ...initialStatesState,
  clients: [],
  error: null,
};

const updateClient = (state: ClientsState, clientToUpdate: ClientType): ClientsState => {
  const clients = state.clients.map((client) => {
    if (client.id === clientToUpdate.id) {
      return clientToUpdate;
    }

    return client;
  });

  return { ...state, clients, ...initialStatesState };
};

const deleteClient = (state: ClientsState, clientToDelete: ClientType): ClientsState => {
  const clients = state.clients.filter((c) => c.id !== clientToDelete.id);

  return { ...state, clients, ...initialStatesState };
};

export const clientsReducer = createReducer(
  initialClientsState,
  on(ClientActions.loadAllClients, (state) => ({ ...state, error: null, loading: true })),
  on(ClientActions.loadAllClientsSucceeded, (state, { clients }) => ({
    ...state,
    clients,
    error: null,
    loading: false,
  })),
  on(ClientActions.loadAllClientsFailed, (state, { error }) => ({ ...state, error, loading: false })),
  on(ClientActions.updateCludApiKey, (state, { id, cloudApiKey }) => ({
    ...state,
    clients: state.clients.map((c) => (c.id === id ? { ...c, cloudApiKey } : c)),
  })),

  on(ClientActions.reactivateClient, (state) => ({ ...state, error: null, reactivating: true })),
  on(ClientActions.reactivateClientSucceeded, (state, { client }) => updateClient(state, client)),
  on(ClientActions.reactivateClientFailed, (state, { error }) => ({ ...state, error, reactivating: false })),

  on(ClientActions.archiveClient, (state) => ({ ...state, error: null, archiving: true })),
  on(ClientActions.archiveClientSucceeded, (state, { client }) => updateClient(state, client)),
  on(ClientActions.archiveClientFailed, (state, { error }) => ({ ...state, error, archiving: false })),

  on(ClientActions.archiveDeletableClient, (state) => ({ ...state, error: null, archiving: true })),
  on(ClientActions.archiveDeletableClientSucceeded, (state, { client }) => updateClient(state, client)),
  on(ClientActions.archiveDeletableClientFailed, (state, { error }) => ({ ...state, error, archiving: false })),

  on(ClientActions.activateAdvisoryReport, (state) => ({ ...state, error: null, activating: true })),
  on(ClientActions.activateAdvisoryReportSucceeded, (state, { client }) => updateClient(state, client)),
  on(ClientActions.activateAdvisoryReportFailed, (state, { error }) => ({ ...state, error, activating: false })),

  on(ClientActions.deactivateAdvisoryReport, (state) => ({ ...state, error: null, deactivating: true })),
  on(ClientActions.deactivateAdvisoryReportSucceeded, (state, { client }) => updateClient(state, client)),
  on(ClientActions.deactivateAdvisoryReportFailed, (state, { error }) => ({ ...state, error, deactivating: false })),

  on(ClientActions.deleteClient, (state) => ({ ...state, error: null, deleting: true })),
  on(ClientActions.deleteClientSucceeded, (state, { client }) => deleteClient(state, client)),
  on(ClientActions.deleteClientFailed, (state, { error }) => ({ ...state, error, deleting: false })),
);
