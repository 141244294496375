<app-tabs>
  <app-tab tabTitle="Ändra" (activeChange)="bulkChangeTabActive = $event">
    <app-bulk-change
      [type]="BulkTypeChange"
      actionSelectPlaceholder="Välj vad du vill ändra"
      [active]="tabActive && bulkChangeTabActive"
    >
      <p actionTitle>Vad vill du ändra?</p>
    </app-bulk-change>
  </app-tab>
  <app-tab tabTitle="Lägg till" (activeChange)="bulkAddTabActive = $event">
    <app-bulk-change
      [type]="BulkTypeAdd"
      actionSelectPlaceholder="Välj vad du vill lägga till"
      [active]="tabActive && bulkAddTabActive"
    >
      <p actionTitle>Vad vill du lägga till?</p>
    </app-bulk-change>
  </app-tab>
  <app-tab tabTitle="Ta bort" (activeChange)="bulkRemoveTabActive = $event">
    <app-bulk-change
      [type]="BulkTypeRemove"
      actionSelectPlaceholder="Välj vad du vill ta bort"
      [active]="tabActive && bulkRemoveTabActive"
    >
      <p actionTitle>Vad du vill ta bort?</p>
    </app-bulk-change>
  </app-tab>
</app-tabs>
