import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TableColumnWithFilter } from 'src/app/shared/components/table/interfaces/table-column';
import { StateFacade } from '../../facades/state.facade';
import { BulkChangeActionType } from '../../interfaces/bulk-change-action-type.enum';
import {
  BulkChangeComponentData,
  ExecuteChangeResponse,
  ExecuteChangeServerResponse,
  IBulkChangeComponent,
} from '../../interfaces/bulk-change.component';

@Component({ template: '---' })
export abstract class BaseChangeComponent implements IBulkChangeComponent, OnDestroy {
  @Input() actionType: BulkChangeActionType;

  public formGroup: UntypedFormGroup;
  protected subscriptions: Subscription = new Subscription();

  constructor(private stateFacade: StateFacade) {
    this.formGroup = this.createForm();

    this.subscriptions.add(
      this.formGroup.statusChanges.subscribe((status) => {
        // reset the value when status is changed == clear the table
        this.stateFacade.update({ componentValue: null, componentValid: status === 'VALID' });
      })
    );
  }

  protected abstract createForm(): UntypedFormGroup;
  protected abstract load(): Observable<BulkChangeComponentData>;
  protected abstract getTableColumns(): TableColumnWithFilter[];
  // property that should be picked in the object to send back as selected when updating rows
  protected abstract getDataIdentityKey(): string;

  /**
   * Override this function in the component if you want handle field validation in the component
   */
  protected onValidateFields(): boolean {
    return true;
  }

  /**
   * Override this function in the component if you want to change the table data in some way after
   * the change is made on the server.
   * Default this implementation updates/replaces the table data with the updated version of the row.
   */
  public afterExecuteChange(oldTableData?: any[], response?: ExecuteChangeServerResponse): ExecuteChangeResponse {
    const dataIdentityKey = this.getDataIdentityKey();
    const hasError = response?.data?.some((item) => item.success === false);
    const successValues = response?.data?.filter((item) => item.success === true).map((item) => item.value);
    const updatedData = oldTableData?.map((item) => {
      const index = successValues?.findIndex((item2) => item[dataIdentityKey] === item2[dataIdentityKey]);
      return index > -1 ? successValues[index] : item;
    });

    return { hasError, data: updatedData };
  }

  public loadData(): void {
    this.stateFacade.update({ loading: true });

    this.load()
      .pipe(take(1))
      .subscribe((data: BulkChangeComponentData) => {
        this.stateFacade.update({
          loading: false,
          componentValue: {
            ...data,
            actionType: this.actionType,
            tableColumns: this.getTableColumns(),
            dataIdentityKey: this.getDataIdentityKey(),
          },
        });
      });
  }

  public validateFields(): boolean {
    return this.onValidateFields();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
