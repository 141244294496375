import { createSelector } from '@ngrx/store';
import { FirmSelectors } from '../firm/firm.selectors';
import { AuthSelectors } from '../auth/auth.selectors';
import { ListSelectors } from '../list/list.selectors';

const usesTeams = (initialized: boolean, ids: number[]) => initialized && Boolean(ids?.length);

const firmAndUserSelector = createSelector(
  FirmSelectors.selectFirm,
  AuthSelectors.selectAuthUser,
  ListSelectors.selectedTeams,
  (firm, user, { ids, initialized }) => {
    if (usesTeams(initialized, ids)) {
      return { firm, user };
    }

    return null;
  },
);

export const MessagingSelectors = {
  firmAndUserSelector,
};
