/* eslint-disable max-lines-per-function */
import { IncomeStatementComposite } from '@app/core/entity/incomestatement.composite';
import { IncomeStatementCompositeType } from '../types/incomestatement.types';
import { CompositeType } from '../types';
import { toCompositeBasePropertyTransformer, Transformer } from './transformers';

export const toIncomeStatementCompositeTypeTransformer: Transformer<
  IncomeStatementComposite,
  IncomeStatementCompositeType
> = {
  transform: ({
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    eulo,
    eura,
    fora,
    konu,
    kuut,
    rest,
    sral,
    mainSpan,
    responsible,
    taskUsers,
  }) => ({
    type: CompositeType.incomeStatement,
    client,
    clientId,
    corporateIdentity,
    customerNumber,
    eulo: toCompositeBasePropertyTransformer.transform(eulo),
    eura: toCompositeBasePropertyTransformer.transform(eura),
    fora: toCompositeBasePropertyTransformer.transform(fora),
    konu: toCompositeBasePropertyTransformer.transform(konu),
    kuut: toCompositeBasePropertyTransformer.transform(kuut),
    rest: toCompositeBasePropertyTransformer.transform(rest),
    sral: toCompositeBasePropertyTransformer.transform(sral),
    mainSpan: mainSpan
      ? {
          end: mainSpan.end,
          sameDay: mainSpan.sameDay,
          spanLabel: mainSpan.spanLabel,
          spanType: mainSpan.spanType,
          start: mainSpan.start,
        }
      : {
          end: '',
          sameDay: false,
          spanLabel: '',
          spanType: '',
          start: '',
        },
    responsible,
    taskUsers,
  }),
};
