export const ENCODED_PIPE_CHARACTER = '%7C';

export const getClientDocumentsRoot = (baseUrl: string, clientId: number) => {
  if (!baseUrl || !clientId) {
    throw new Error();
  }

  return `${baseUrl}/${clientId}/files${ENCODED_PIPE_CHARACTER}`;
};

export const escapeHtmlChars = (text: string): string => {
  return text.replace(/\+/g, '%2b').replace(/\|/g, ENCODED_PIPE_CHARACTER);
};
