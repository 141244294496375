import { MainSpanType } from '../state/types';
import { Activity } from './activity';
import { CompositeBase } from './composite.base';

export class ClosingComposite extends CompositeBase {
  client = '';
  clientId: number;
  corporateIdentity: string;
  customerNumber: string;
  responsible: string;
  accountant: string;

  best: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
    shard: { info: string };
  };

  forb: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
    shard: { info: string };
  };

  boks: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
    shard: { info: string };
  };

  uppr: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
    shard: { info: string };
  };

  arsr: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
    shard: { info: string };
  };

  arss: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
    shard: { info: string };
  };

  uppa: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
    shard: { info: string };
  };

  skic: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
    shard: { info: string };
  };

  bokr: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
    shard: { info: string };
  };

  bfrb: {
    activity: Activity;
    readyDate: string;
    task: { comment: string };
    agentCall: string;
    shard: { info: string };
  };

  mainSpan: MainSpanType;

  // Implementation of abstract method
  protected getCompositeColumnTypePropertyNames(): string[] {
    return ['best', 'forb', 'boks', 'uppr', 'arsr', 'arss', 'uppa', 'skic', 'bokr', 'bfrb'];
  }

  // Implementation of abstract method
  protected createDefaultCompositeObjectForProperty() {
    return {
      activity: new Activity(),
      readyDate: 'false',
      task: { comment: '' },
      agentCall: '',
      shard: { info: '' },
    };
  }

  static from(obj: unknown): ClosingComposite {
    const composite = ClosingComposite.fromObject(ClosingComposite, obj);
    composite.populateTaskUsers();
    return composite;
  }
}
