import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ListActions } from '@app/core/state/list/list.actions';
import { TeamsSelectors } from '@app/core/state/teams/teams.selectors';
import { TeamType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { MemoizedSelector, Store } from '@ngrx/store';
import { Subject, takeUntil, filter } from 'rxjs';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';
import { TableFilterItemComponent } from '../table/components/table-filter-item.component';
import { MultiSelectDropDownIncludingAllComponent } from '../multiselect-dropdown/multiselect-dropdown-including-all.component';

@Component({
  selector: 'app-team-multi-selector',
  template: `
    <div [ngClass]="{ hidden: !visible }">
      <app-table-filter-item label="Team" [containerClass]="containerClass">
        <app-multiselect-dropdown-including-all
          [allValue]="allValue"
          [options]="teams"
          [selected]="selected"
          optionLabel="name"
          optionValue="id"
          (selectedChange)="onTeamChanged($event)"
          selectedItemLabel="team">
        </app-multiselect-dropdown-including-all>
      </app-table-filter-item>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, FormsModule, TableFilterItemComponent, MultiSelectDropDownIncludingAllComponent],
})
export class TeamMultiSelectorComponent implements OnInit, OnDestroy {
  @Input() localStorageKey: string;
  @Input() containerClass: string;
  @Input() dispatches = true;
  @Input() selector: MemoizedSelector<AppState, { teams: TeamType[]; ids: number[] }> =
    TeamsSelectors.selectTeamSelector;

  @Input() set selectedIds(ids: number[]) {
    if (!this.initialized) {
      return;
    }

    this.selected = ids.filter((id) => this.teams.find((t) => id === this.allValue || t.id === id));
  }

  @Output()
  public OnTeamsChanged: EventEmitter<number[]>;

  @Output()
  public OnTeamsInit: EventEmitter<number[]>;

  public visible = false;
  public includesRootTeam = false;
  public teams: TeamType[] = [];
  public selected: number[] = [];
  public prevSelected: number[] = [];
  public scrollHeight: string;
  public allValue: number;
  private onDestroy: Subject<boolean>;
  private initialized: true;
  private uss: UserSettingsStorage;
  private storedIds: number[] = [];

  constructor(private store: Store<AppState>) {
    this.onDestroy = new Subject();
    this.OnTeamsChanged = new EventEmitter<number[]>();
    this.OnTeamsInit = new EventEmitter<number[]>();
    this.uss = new UserSettingsStorage();
  }

  ngOnDestroy(): void {
    this.saveUserSettings();
    this.onDestroy.next(true);
    this.onDestroy.complete();
  }

  ngOnInit(): void {
    this.subscribeToTeams();
  }

  onTeamChanged(selectedTeams: number[]) {
    if (!this.initialized || this.areArraysEqual(this.prevSelected, selectedTeams)) {
      return;
    }

    this.dispatchSelectedTeams(selectedTeams);
    this.storedIds = selectedTeams;
    this.OnTeamsChanged.next(selectedTeams);

    if (!this.areSettingsStored()) {
      // to avoid strange bugs let's make sure we store the first teams we get in localstorage
      this.saveUserSettings();
    }
  }

  onTeamInit(selectedTeams: number[]) {
    this.dispatchSelectedTeams(selectedTeams);
    this.storedIds = selectedTeams;
    this.OnTeamsInit.next(selectedTeams);
  }

  private getSelected = (teams: TeamType[]) => {
    if (!this.areSettingsStored() || this.isEmpty(this.storedIds)) {
      return teams.map((t) => t.id);
    }

    return this.storedIds.filter((id) => teams.find((t) => id === this.allValue || t.id === id));
  };

  private areArraysEqual = (arr1: number[], arr2: number[]): boolean => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort((a, b) => a - b);
    const sortedArr2 = arr2.slice().sort((a, b) => a - b);

    for (let i = 0; i < sortedArr1.length; i += 1) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  };

  private loadUserSettings() {
    if (!this.localStorageKey) {
      return;
    }
    this.storedIds = JSON.parse(this.uss.loadSetting(this.getStorageKey(), '[]'));
  }

  private saveUserSettings() {
    if (!this.localStorageKey) {
      return;
    }
    this.uss.saveSetting(this.getStorageKey(), JSON.stringify(this.storedIds));
  }

  private getStorageKey = () => `${UserSettingsStorage.TEAM_SELECTOR_PREFIX}${this.localStorageKey}`;

  private dispatchSelectedTeams = (selectedTeams: number[]) => {
    if (this.dispatches) {
      this.store.dispatch(ListActions.teamsSelected({ selectedTeams }));
    }
  };

  private subscribeToTeams = () => {
    this.store
      .select(this.selector)
      .pipe(
        filter(({ teams }) => Boolean(teams)),
        takeUntil(this.onDestroy),
      )
      .subscribe(({ teams }) => {
        this.loadUserSettings();
        this.initialized = true;
        this.allValue = 0;
        this.scrollHeight = getScrollHeightString(teams.length);
        this.visible = teams?.length > 1;
        this.prevSelected = [...this.selected];
        this.selected = this.getSelected(teams);
        this.teams = teams;
        this.onTeamInit(this.selected);
      });
  };

  private areSettingsStored = () => {
    if (!this.localStorageKey) {
      return true;
    }

    return Boolean(this.uss.loadSetting(this.getStorageKey(), ''));
  };

  private isEmpty = (array: number[]) => array.length === 0;
}
