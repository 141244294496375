import { createSelector } from '@ngrx/store';
import { AssignmentType, TaskType } from '../types';
import { AppState } from '../appState';

const assignments = (state: AppState) => state.assignments;

const assignmentsWithChoose = createSelector(assignments, (state) => {
  const choose: AssignmentType = { ...initialAssignmentType, name: '- Välj -' };
  return [choose].concat(state.assignments);
});

const selectAssignments = createSelector(assignments, (state) => state.assignments);

export const initialAssignmentType: AssignmentType = {
  id: null,
  label: '',
  name: '',
  rootTemplates: [],
  task: {} as TaskType,
};

export const AssignmentSelectors = {
  assignmentsWithChoose,
  selectAssignments,
};
