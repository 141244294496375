<div class="shadow-md">
  <div class="p-8">
    <div class="flex justify-between">
      <h4 class="font-semibold">app.bjornlunden.se</h4>
      <div><button class="btn btn-warning" (click)="openIntegrationPage()">Öppna</button></div>
    </div>
    <p class="py-3">
      På app.bjornlunden.se och i Björn Lundén-appen finns våra webbtjänster kopplade till BL Administration. <br />
      Vi implementerar löpande nya funktioner som vi hoppas ska underlätta din och dina klienters vardag.
    </p>
    <div class="flex items-center text-bl-orange-500 mb-6">
      FUNKTIONER
      <div class="border-b-2 border-bl-grey-300 h-1 w-full ml-5"></div>
    </div>
    <div class="row">
      <div class="col-md-3 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Fakturering</div>
      <div class="col-md-3 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Tolkning</div>
      <div class="col-md-3 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Rådgivarverktyg</div>
      <div class="col-md-3 mb-2">
        <span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Meddelandefunktion
      </div>
      <div class="col-md-3 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Ekonomiöversikt</div>
      <div class="col-md-3 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Attest</div>
      <div class="col-md-3 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Bank & betalning</div>
      <div class="col-md-3 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Integrationer</div>
      <div class="col-md-3 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Kvittohantering</div>
      <div class="col-md-3 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>E-postportal</div>
    </div>
  </div>
</div>
<div class="row mt-8">
  <div class="col-md-6">
    <div class="p-8 shadow-md">
      <div class="flex justify-between">
        <h4 class="font-semibold">Integrationer</h4>
        <div><button class="btn btn-warning" (click)="openIntegrationPage()">Öppna</button></div>
      </div>
      <p class="py-3">
        Genom att koppla ditt BL Administration mot våra integratörer kan du effektivisera ditt arbete och få ut mer av
        ditt program.
      </p>
      <div class="flex items-center text-bl-orange-500 mb-6 text-nowrap">
        KOPPLING TILL
        <div class="border-b-2 border-bl-grey-300 h-1 w-full ml-5"></div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Zettle</div>
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Capcito</div>
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Handelbanken</div>
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Timekeeper</div>
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Nordea</div>
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Leeroy</div>
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>SEB</div>
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Med flera</div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="p-8 shadow-md">
      <div class="flex justify-between">
        <h4 class="font-semibold">BL Finans</h4>
        <div><button class="btn btn-warning" (click)="openBlFinancePage()">Öppna</button></div>
      </div>
      <p class="py-3">Förmånlig finansiering eller smart kapitalförvaltning? <br />- Vi har alternativen!</p>
      <div class="flex items-center text-bl-orange-500 mb-6 text-nowrap">
        LÖSNINGAR FRÅN
        <div class="border-b-2 border-bl-grey-300 h-1 w-full ml-5"></div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Capcito</div>
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Elsa Försäkring</div>
        <!-- <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Lysa</div> -->
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>Marginalen Bank</div>
        <div class="col-md-6 mb-2"><span class="fa fa-check-circle mr-3 text-bl-orange-500"></span>SEB</div>
        <!-- filling the height -->
        <div class="col-md-6 mb-2">&nbsp;</div>
        <div class="col-md-6 mb-2">&nbsp;</div>
      </div>
    </div>
  </div>
</div>
