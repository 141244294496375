import { Component, Input } from '@angular/core';
import { Client } from 'src/app/core/entity/client';
import { ClientService } from 'src/app/core/services/clients.service';
import { environment } from 'src/environments/environment';

type IntegrationResponse = { key: string };

@Component({
  selector: 'app-client-integrations',
  templateUrl: './client-integrations.component.html',
})
export class ClientIntegraionsComponent {
  @Input() client: Client;

  constructor(private clientService: ClientService) {}

  openBlFinancePage() {
    this.openIntegrationPage('blfinance');
  }

  openIntegrationPage(page = 'integration') {
    this.getIntegrationKey(this.client.id).subscribe((response: IntegrationResponse) =>
      this.openPage(page, response.key)
    );
  }

  private getIntegrationKey(clientId: number) {
    return this.clientService.getIntegrationKey(clientId);
  }

  private openPage(page: string, key: string) {
    window.open(`${environment.blappWeb}/${page}?key=${key}`, '_blank')?.focus();
  }
}
