import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { AdvisoryToolComponent } from './components/advisory-tool.component';
import { AdvisoryToolTableComponent } from './components/advisory-tool-table/advisory-tool-table.component';
import { BystSharedModule } from '../shared/byst-shared.module';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { NotificationPayloadComponent } from './components/notification-payload/notification-payload.component';
import { AdvisoryLastUpdatedComponent } from './components/advisory-last-updated/advisory-last-updated.component';
import { AdvisoryToolbarComponent } from './components/advisory-toolbar/advisory-toolbar.component';
import { DateConvertPipe } from './pipes/date-convert.pipe';
import { AdvisoryToolOnboardingComponent } from './components/advisory-tool-onboarding/advisory-tool-onboarding.component';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    BystSharedModule,
    AdvisoryToolbarComponent,
    AdvisoryLastUpdatedComponent,
    DateConvertPipe,
    NotificationModalComponent,
    NotificationPayloadComponent,
    AdvisoryToolTableComponent,
    AdvisoryToolOnboardingComponent,
  ],
  declarations: [AdvisoryToolComponent],
  exports: [AdvisoryToolComponent],
})
export class AdvisoryToolModule {}
