import { DialogData, DialogResult, FormDialogBaseComponent } from '../../form-dialog-base/form-dialog-base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { PleaseWaitComponent } from '../../please-wait/please-wait.component';

interface Result extends DialogResult {
  confirmed: boolean;
}

@Component({
  selector: 'app-corporate-identity-exists-dialog',
  template: `
    <div>
      <p>Detta person-/organisationsnummer finns redan upplagd i Byråstödet, vill du spara denna ändå?</p>
      <div class="flex justify-end mt-4 space-x-2">
        <bl-frontend-button [text]="'Spara'" (click)="close({ confirmed: true })" />
        <bl-frontend-button [text]="'Avbryt'" variant="secondary" (click)="close({ confirmed: false })" />
      </div>
    </div>
  `,
  standalone: true,
  providers: [DialogService],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BlFrontendButtonComponent, PleaseWaitComponent],
})
export class CorporateIdentityExistsDialog extends FormDialogBaseComponent<DialogData, Result> {
  protected static override getDialogConfig(): Omit<DynamicDialogConfig<DialogData>, 'data'> {
    return {
      header: 'Organisationsnumret finns redan',
      draggable: false,
      modal: true,
    };
  }
}
