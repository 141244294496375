import { Component } from '@angular/core';
import { FeaturesService } from '@app/core/services/features.service';
import { AppState } from '@app/core/state/appState';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { initialFeaturesState } from '@app/core/state/feature/feature.reducer';
import { FeatureSelectors } from '@app/core/state/feature/feature.selectors';
import { FeatureType, PrivilegeType } from '@app/core/state/types';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

@Component({
  templateUrl: './main-app.component.html',
  host: { class: 'flex flex-col h-full' },
})
export class MainAppComponent {
  private hasAdminPrivilege: boolean;
  private features: FeatureType;

  constructor(featuresService: FeaturesService, private store: Store<AppState>) {
    featuresService.init();
    this.hasAdminPrivilege = false;
    this.features = { ...initialFeaturesState };
    this.store
      .select(AuthSelectors.selectAuthUser)
      .pipe(filter((user) => Boolean(user)))
      .subscribe((user) => {
        this.hasAdminPrivilege = user.privilege === PrivilegeType.ADMIN;
      });

    this.store
      .select(FeatureSelectors.selectFeaturesState)
      .pipe(filter((features) => Boolean(features)))
      .subscribe((features) => {
        this.features = features;
      });
  }

  public isAdminAndFeatureIsActive = (feature: string): boolean => this.features[feature] && this.hasAdminPrivilege;
}
