export class FinancialYear {
  public clientId: number;
  public span: { start: string; end: string };
  public endDate: string;
  public euCommerce = false;
  public largeCompany = false;
  public electronicVat = false;
  public electronicCompilation = false;
  public electronicTax = false;
  public firmVatRespite = false;
  public firmTaxRespite = false;
  public id: number | null = null;

  constructor() {
    this.span = { start: '', end: '' };
  }
}
