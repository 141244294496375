import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TeamsSelectors } from '@app/core/state/teams/teams.selectors';
import { SimpleUserType, TeamType, TeamTypes } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserAvatarComponent } from '@app/shared/components/user-avatar/user-avatar.component';
import { BlFrontendBadgeComponent } from '@app/core/wrappers/bl-frontend-badge.component';

@Component({
  selector: 'app-teams-grid',
  templateUrl: './teams-grid.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UserAvatarComponent, BlFrontendBadgeComponent],
  styles: [
    `
      .min-h-220px {
        min-height: 220px;
      }
    `,
  ],
})
export class TeamsGridComponent implements OnInit {
  @Input() teamType: TeamTypes | TeamTypes[];
  @Output() teamClick: EventEmitter<TeamType> = new EventEmitter<TeamType>();
  @Output() newTeamClick: EventEmitter<void> = new EventEmitter<void>();

  teams$: Observable<TeamType[]>;
  users$: Observable<SimpleUserType[]>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.teams$ = this.store.select(TeamsSelectors.selectTeamsByType(this.teamType));
    this.users$ = this.store.select(UserSelectors.activeUsersWithoutAllAsSimpleUser);
  }

  getTeamUsers(team: TeamType): Observable<SimpleUserType[]> {
    const isUserInTeam = (user: SimpleUserType) => team.users.data.includes(user.id);
    return this.users$.pipe(map((users) => users.filter(isUserInTeam)));
  }

  isRootTeam(team: TeamType) {
    return team.typeId === TeamTypes.Root;
  }

  isClientTeam(team: TeamType) {
    return team.typeId === TeamTypes.Client;
  }
}
