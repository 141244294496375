import { Injectable } from '@angular/core';
import { TaskService } from '@app/core/services/task.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, switchMap } from 'rxjs';
import { ClientTasksActions } from './clientTasks.actions';

@Injectable()
export class ClientTasksEffects {
  loadClientTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientTasksActions.load),
      switchMap(({ clientId }) =>
        this.taskService.getTaskTypesByClientId(clientId).pipe(
          mergeMap((tasks) => [ClientTasksActions.loadSucceeded({ tasks })]),
          catchError((error: unknown) => of(ClientTasksActions.loadFailed({ error }))),
        ),
      ),
    ),
  );

  // eslint-disable-next-line no-useless-constructor
  constructor(private actions$: Actions, private taskService: TaskService) {}
}
