import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Permission, PermissionGroup, PermissionType } from 'src/app/core/entity/permission';

export type PermissionState = {
  active: boolean;
  group: PermissionGroup;
  users: number[];
};

export type PermissionRootState = {
  [key in PermissionType]: PermissionState;
};

const initState: PermissionRootState = {
  SHOW_TAX_ACCOUNT_BALANCE: { active: false, group: PermissionGroup.None, users: [] },
  VISIBLE_IN_NOTIFICATION: { active: false, group: PermissionGroup.None, users: [] },
};

@Injectable()
export class PermissionStateFacade {
  private permissionStateBS: BehaviorSubject<PermissionRootState> = new BehaviorSubject(initState);
  private previousPersistedStateBS: BehaviorSubject<PermissionRootState> = new BehaviorSubject(initState);

  public createRootStateObservable() {
    return this.permissionStateBS.asObservable();
  }

  public getStateObservable(stateRoot: PermissionType) {
    return this.permissionStateBS.pipe(map((state) => state[stateRoot]));
  }

  public withLatestFromState() {
    return <T extends PermissionType>(source: Observable<T>) => {
      return source.pipe(
        mergeMap((permissionType: T) =>
          this.getStateObservable(permissionType).pipe(
            first(),
            map((state) => [permissionType, state])
          )
        )
      );
    };
  }

  // operator function that filter out unchanged states
  public filterUnchanged() {
    return <T extends PermissionType>(source: Observable<T>) => {
      return source.pipe(
        withLatestFrom(this.previousPersistedStateBS, this.permissionStateBS),
        filter(([permissionType, prevRootState, currentRootState]: [T, PermissionRootState, PermissionRootState]) => {
          const prevState = prevRootState[permissionType];
          const currentState = currentRootState[permissionType];
          return JSON.stringify(prevState) !== JSON.stringify(currentState);
        }),
        map(
          ([permissionType, _prevRootState, _currentRootState]: [T, PermissionRootState, PermissionRootState]) =>
            permissionType
        )
      );
    };
  }

  public initState(permissions: Permission[]) {
    if (!permissions) {
      return;
    }

    // setup state for each permission type
    for (const permissionType of Object.values(PermissionType)) {
      const permissionsForType = permissions.filter((p) => p.permissionKey === permissionType);
      const permissionGroupForType = permissionsForType[0]?.permissionGroup || PermissionGroup.None;

      this.updateState(permissionType as PermissionType, {
        active: permissionGroupForType !== PermissionGroup.None,
        group: permissionGroupForType,
        users: permissionsForType.map((p) => p.userId).filter((userId) => !!userId), // remove null-values
      });

      // set the prev state to same as current state since it should be the init values of the state
      this.resetPreviousPersistedState();
    }
  }

  public updateState(stateRoot: PermissionType, newState: Partial<PermissionState>) {
    const currentState = this.permissionStateBS.getValue();

    this.permissionStateBS.next({
      ...currentState,
      [stateRoot]: { ...currentState[stateRoot], ...newState },
    });
  }

  public clearIfEmpty(stateRoot: PermissionType) {
    const currentState = this.permissionStateBS.getValue();
    const currentUsers = currentState[stateRoot].users;
    if (currentUsers.length > 0) {
      return;
    }
    this.updateState(stateRoot, {
      active: false,
      group: PermissionGroup.None,
      users: [],
    });
  }

  public rollbackToPreviousPersistedState() {
    this.permissionStateBS.next(this.previousPersistedStateBS.getValue());
  }

  // resets the previous state value to current state value
  public resetPreviousPersistedState() {
    this.previousPersistedStateBS.next(this.permissionStateBS.getValue());
  }
}
