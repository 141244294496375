import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TooltipProps } from '@bl/components';
import { BlFrontendBaseComponent } from './bl-frontend-base.component';

@Component({
  selector: 'bl-frontend-tooltip',
  template: '<span><ng-content/></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class BlFrontendTooltipComponent extends BlFrontendBaseComponent<TooltipProps> {
  @Input() content: TooltipProps['content'] = '';
  @Input() heading?: TooltipProps['heading'];
  @Input() placement?: TooltipProps['placement'];
  @Input() trigger?: TooltipProps['trigger'];

  protected override getComponentName() {
    return 'Tooltip';
  }

  protected override getProps() {
    return {
      content: this.content,
      heading: this.heading,
      placement: this.placement,
      trigger: this.trigger,
    };
  }
}
