import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BenefitsDto, PartnerFirm, LevelsDto, PropertiesDto, SaveFirmDto } from '../types/partner.types';
import { getCurrentIsoDateWithoutTime } from '@app/shared/misc/dates';

export const PartnerProgramActions = createActionGroup({
  source: 'PartnerProgram',
  events: {
    load: (startDate?: string) => ({ startDate: startDate ?? getCurrentIsoDateWithoutTime() }),
    'Load Succeeded': props<{ startDate: string; data: PartnerFirm }>(),
    'Load Failed': props<{ error: unknown }>(),
    save: props<{ data: SaveFirmDto }>(),
    'Save Succeeded': props<{ data: SaveFirmDto }>(),
    'Save Failed': props<{ error: unknown }>(),
    'Load Benefits': emptyProps(),
    'Load Benefits Succeeded': props<{ benefits: BenefitsDto }>(),
    'Load Benefits Failed': props<{ error: unknown }>(),
    'Load Properties': emptyProps(),
    'Load Properties Succeeded': props<{ properties: PropertiesDto }>(),
    'Load Properties Failed': props<{ error: unknown }>(),
    'Load Levels': emptyProps(),
    'Load Levels Succeeded': props<{ levels: LevelsDto }>(),
    'Load Levels Failed': props<{ error: unknown }>(),
  },
});
