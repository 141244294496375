import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TeamType, TeamTypes } from '../types';

export const TeamActions = createActionGroup({
  source: 'Team',
  events: {
    'Show Add Dialog': props<{ teamType: TeamTypes }>(),
    'Show Edit Dialog': props<{ team: TeamType }>(),
    'Hide Dialog': emptyProps(),
  },
});
