import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ListSelectors } from '@app/core/state/list/list.selectors';
import { LoadingStatus } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-table-refresh-button',
  template: `
    <a class="btn btn-primary" (click)="onRefresh()" title="Uppdatera listan"><i [class]="iconClass$ | async"></i></a>
  `,
})
export class TableRefreshButton implements OnInit {
  public iconClass$: Observable<string>;
  @Output() onClick: EventEmitter<void> = new EventEmitter();

  constructor(private store: Store<AppState>) {}

  public ngOnInit(): void {
    this.iconClass$ = this.store.select(ListSelectors.getStatus).pipe(
      map((status) => {
        if (status !== LoadingStatus.Loading) {
          return 'fa fas fa-sync';
        }

        return 'fa fas fa-sync fa-spin';
      }),
    );
  }

  public onRefresh() {
    this.onClick.emit();
  }
}
