<div class="flex">
  <div class="w-1/2" style="display: flex; justify-items: center">
    <button
      type="button"
      class="btn btn-primary"
      (click)="displayAssignmentAdder()"
      [disabled]="client.archived || client.id === -1"
    >
      Lägg till tjänster
    </button>
    <p-checkbox
      class="ml-4 items-end"
      [binary]="true"
      [ngModel]="includeTasksWithEndDate"
      (ngModelChange)="onCheckboxEndDateChange()"
      [label]="includeTasksWithEndDateLabel"
    ></p-checkbox>
  </div>
  <div class="flex w-1/2 justify-end">
    <button type="button" class="btn btn-primary mr-3" (click)="saveTaskData()">Skapa uppdragsavtal</button>
    <button type="button" class="btn btn-info" (click)="checkOrUncheckAll()">Markera / avmarkera alla</button>
  </div>
</div>
<br />

<!-- Den här har special header, så kan inte använda app-table (som den är byggd nu)-->
<p-table
  [value]="clientTasks"
  [columns]="columns"
  [scrollable]="true"
  scrollHeight="flex"
  [style]="{ 'max-height': '650px' }"
  [tableStyle]="{'table-layout':'fixed'}"
>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        <div class="my-3">Inga tjänster finns att visa</div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        [style]="getColumnSize(col)"
        class="font-normal text-center truncate"
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="!col.sortable"
      >
        <div class="white-space-normal flex h-full items-center">
          {{ col.header }}
          <p-sortIcon
            *ngIf="col.sortable"
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          >
          </p-sortIcon>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-task let-col="columns">
    <tr>
      <td [style]="getColumnSize(col[0])">
        <a (click)="showTask(task)" class="text-black cursor-pointer">{{ task.assignmentName }}</a>
      </td>
      <td [style]="getColumnSize(col[1])">
        <a (click)="showTask(task)" class="text-black cursor-pointer">{{ task.type }}</a>
      </td>
      <td [style]="getColumnSize(col[2])">{{ task?.span?.start }}</td>
      <td [style]="getColumnSize(col[3])">{{ task?.span?.end }}</td>
      <td [style]="getColumnSize(col[4])">{{ task?.periodicity?.description }}</td>
      <td [style]="getColumnSize(col[5])">{{ task?.deadlineRule?.description }}</td>
      <td [style]="getColumnSize(col[6])">{{ task?.usersAsString }}</td>
      <td [style]="getColumnSize(col[7])">
        <div class="flex justify-center">
          <input type="checkbox" [checked]="task.assignmentAgreement" (change)="onCheckboxChange(task)" />
        </div>
      </td>
      <td [style]="getColumnSize(col[8])">
        <div class="flex justify-center">
          <span
            *ngIf="!client.archived"
            (click)="showTaskActivities(task)"
            class="far fa-list-alt text-black cursor-pointer"
          >
          </span>
        </div>
      </td>
      <td [style]="getColumnSize(col[9])">
        <div class="flex justify-center">
          <span
            *ngIf="!client.archived"
            (click)="tryDeleteTask(task)"
            class="far fa-trash-alt text-black cursor-pointer"
          >
          </span>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="relative py-1">
      <div>Antal tjänster: {{ clientTasks.length }}</div>
    </div>
  </ng-template>
</p-table>

<p-dialog header="Avsluta tjänst?" [(visible)]="displayDeleteDialog" showEffect="fade" [draggable]="false">
  <p>
    Tjänsten har sparade aktiviteter och kan inte tas bort. Den kan däremot sättas som avslutad till ett datum i den
    senast sparade perioden. Tjänstens historik kommer då finns fortsatt tillgänglig. Vill du fortsätta?
  </p>
  <p-footer>
    <button type="button" class="btn btn-success" (click)="afterDeleteDialog(true)">Ja</button>
    <button type="button" class="btn btn-warning" (click)="afterDeleteDialog(false)">Nej</button>
  </p-footer>
</p-dialog>

<p-dialog
  header="Tjänsternas inställningar"
  [(visible)]="displayTaskAddedDialog"
  showEffect="fade"
  [style]="{ width: '400px' }"
  [resizable]="false"
  [draggable]="false"
>
  <p>För att kontrollera eller ändra tjänsternas inställningarna för:</p>
  <ul>
    <li>periodicitet</li>
    <li>utförande handläggare</li>
    <li>regel för deadline</li>
    <li>startdatum</li>
    <li>antal dagar för visa, påminn, varna</li>
  </ul>
  <p>
    så klicka på tjänsten i listan. Du kan även lägga till en kommentar som visas varje gång aktiviteten för denna
    tjänst visas.
  </p>
  <p-footer>
    <input
      type="checkbox"
      style="padding-bottom: 10px; margin-left: 15px; float: left"
      [(ngModel)]="dontShowTaskAddedDialogAgain"
    /><span style="float: left"> Visa inte igen</span>
    <button
      type="button"
      style="margin-left: 220px; clear: both; margin-bottom: 10px"
      class="btn btn-success btn-sm"
      (click)="closeTaskAddedDialog()"
    >
      Ok
    </button>
  </p-footer>
</p-dialog>

<app-client-activity-list-dialog
  [visible]="displayActivitiesDialog"
  (visibleChange)="onDisplayActivitiesDialogChange($event)"
  [task]="selectedActivityListTask"
></app-client-activity-list-dialog>
