import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ExecuteChangeServerResponse } from 'src/app/firm-settings/bulk-handling/interfaces/bulk-change.component';
import { environment } from 'src/environments/environment';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { AppState } from '../state/appState';
import { BLService } from './bl.service';

@Injectable()
export class BulkService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'bulkchange';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  change(data: any): Observable<ExecuteChangeServerResponse> {
    const options = this.options.getAuthorizationHeaderWithoutContentType();
    // const data = {
    //   actionType: 'CHANGE_RESPONSIBLE_USER',
    //   changes: [],
    //   rows: [],
    // };
    return this.http.put<ExecuteChangeServerResponse>(this.url, data, options).pipe(this.catchErrorAndShowMessage());
  }
}
