export interface AdvisoryReportProps {
  documentUrl: string;
  jwt: string;
  corporateIdentity: string;
  cloudApiKey: string;
  startDate: string;
  endDate: string;
  radius?: number;
  sni?: string;
}

export function constructUrlToAdvisoryReport({
  documentUrl,
  jwt,
  corporateIdentity,
  cloudApiKey,
  startDate,
  endDate,
  radius,
  sni,
}: AdvisoryReportProps): string {
  if (!documentUrl || !jwt || !corporateIdentity || !cloudApiKey || !startDate || !endDate) {
    throw new Error('Failed to construct advisory report url because mandatory parameters were missing.');
  }

  const baseUrl = `${documentUrl}/advisory-report/`;
  const url = new URL(baseUrl);
  url.searchParams.append('jwt', jwt);
  url.searchParams.append('startDate', startDate);
  url.searchParams.append('endDate', endDate);
  url.searchParams.append('pKey', cloudApiKey);
  url.searchParams.append('cin', corporateIdentity);

  if (radius) {
    url.searchParams.append('radius', radius.toString(10));
  }

  if (sni) {
    url.searchParams.append('sni', sni.replace(/\./g, ''));
  }

  const redirectUrl = url.toString();
  return redirectUrl;
}
