import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { Activity } from 'src/app/core/entity/activity';
import { ActivityComposite } from 'src/app/core/entity/activity.composite';
import { AlertLevelNames } from 'src/app/core/entity/alert.level';
import { Task } from 'src/app/core/entity/task';
import { TodayDate } from 'src/app/shared/misc/today.date';
import { ActivityService } from 'src/app/core/services/activity.service';
import { TaskService } from 'src/app/core/services/task.service';
import { TableColumn } from '../../../table/interfaces/table-column';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { showActivityDetailsDialog } from '@app/shared/misc/showActivityDetailsDialog';
import { DialogService } from 'primeng/dynamicdialog';
import { toActivityTransformer } from '@app/core/state/transformers/transformers';

interface ClientActivityListDialogFilter {
  fromDate: string;
  toDate: string;
  includeFinished: boolean;
}

@Component({
  selector: 'app-client-activity-list-dialog',
  templateUrl: './client-activity-list-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .font-size-12 {
        font-size: 12px;
      }
    `,
  ],
})
export class ClientActivityListDialogComponent implements OnChanges, OnDestroy {
  @Input() task: Task;
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public columns: TableColumn[] = [
    { header: '', field: 'statusIcon', width: '40px', sortable: false },
    { header: 'Aktivitet', field: 'task.type-activity.span.spanLabel' },
    { header: 'Deadline', field: 'activity.deadline', width: '120px' },
    { header: 'Status', field: 'activity.state.description', width: '120px' },
    { header: 'Ändringsdatum', field: 'activity.latestStateChangeEvent.eventDate', width: '120px' },
    { header: 'Ändrat av', field: 'activity.latestStateChangeEvent.changedBy', width: '120px' },
    { header: 'Kommentar', field: 'activity.comment', width: '120px' },
    { header: 'Ta bort', field: 'deleteAction', width: '70px' },
    { header: 'Program', field: 'programAction', width: '70px' },
  ];
  public displayDeleteActivityWarningDialog = false;
  public activityCompositeList$: Observable<ActivityComposite[]>;
  public filter$: Observable<any>;
  public loading$: Observable<{ value: boolean }>; // using object to be able to use "ngif .. as" in html-page
  public removingActivity$: Observable<{ value: boolean }>;

  private filterBS: BehaviorSubject<ClientActivityListDialogFilter>;
  private loadingSubject: BehaviorSubject<{ value: boolean }> = new BehaviorSubject<{ value: boolean }>({
    value: true,
  });
  private removingActivitySubject: BehaviorSubject<{ value: boolean }> = new BehaviorSubject<{ value: boolean }>({
    value: false,
  });
  private activityCompositeListSubject = new BehaviorSubject<ActivityComposite[]>([]);
  private pendingDeletionActivity: Activity;
  private currentSelectedTask: Task;
  private filterAndLoadSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private activityService: ActivityService,
    private taskService: TaskService,
    private store: Store<AppState>,
    private dialogService: DialogService,
  ) {
    this.filterBS = new BehaviorSubject<any>(null);
    this.filter$ = this.filterBS.asObservable();
    this.loading$ = this.loadingSubject.asObservable();
    this.removingActivity$ = this.removingActivitySubject.asObservable();
    this.activityCompositeList$ = this.activityCompositeListSubject.asObservable();
    this.createActivityCompositeListObservable();
  }

  get filterFields() {
    // creates a flat array with field names
    const fields = this.columns
      .filter((c) => !!c.field)
      .reduce((acc, column) => {
        return [...acc, ...column.field.split(',')];
      }, []);
    return fields;
  }

  ngOnDestroy() {
    if (this.filterAndLoadSubscription) {
      this.filterAndLoadSubscription.unsubscribe();
      this.filterAndLoadSubscription = undefined;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const task = changes && changes.task && changes.task.currentValue;
    if (!task) {
      return;
    }
    this.currentSelectedTask = task;

    // Init filter from task span which triggers init load of activities
    this.initFilter(task);
  }

  dismissDialog() {
    // this.visible = false;
    this.filterBS.next(null);
    this.activityCompositeListSubject.next([]);
    this.visibleChange.emit(false);
  }

  showTask(composite: ActivityComposite) {
    if (!composite?.activity?.taskId) {
      return;
    }

    showActivityDetailsDialog(this.activityService, this.dialogService, composite?.activity).subscribe((result) => {
      if (result?.activity?.state?.name) {
        this.activityService
          .saveActivity(toActivityTransformer.transform(result?.activity))
          .pipe(
            tap(() =>
              this.store.dispatch(
                ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Aktiviteten har sparats' }),
              ),
            ),
          )
          .subscribe(() => {
            // trigger reload of activites though apply the lastest filter again
            this.applyFilterToFilterStream({ ...this.filterBS.getValue() });
          });
      }
    });
  }

  showDeleteActivityWarning(activityComposite: ActivityComposite) {
    this.pendingDeletionActivity = activityComposite.activity;
    this.displayDeleteActivityWarningDialog = true;
  }

  confirmDeleteActivityDialog() {
    this.removingActivitySubject.next({ value: true });
    this.activityService.deleteActivity(this.pendingDeletionActivity).subscribe(
      () => {
        this.applyFilterToFilterStream({ ...this.filterBS.getValue() }); // use current filter to reload the list
        this.dismissDeleteActivityDialog();
      },
      () => this.dismissDeleteActivityDialog(),
    );
  }

  dismissDeleteActivityDialog() {
    this.removingActivitySubject.next({ value: false });
    this.displayDeleteActivityWarningDialog = false;
    this.pendingDeletionActivity = undefined;
    this.changeDetectorRef.markForCheck();
  }

  onFilterFromDateChanged(fromDate: string) {
    this.applyFilterToFilterStream({ ...this.filterBS.getValue(), fromDate });
  }
  onFilterToDateChanged(toDate: string) {
    this.applyFilterToFilterStream({ ...this.filterBS.getValue(), toDate });
  }
  onFilterIncludeFinishedChanged(includeFinished: boolean) {
    this.applyFilterToFilterStream({ ...this.filterBS.getValue(), includeFinished });
  }

  callAgent(activityComposite: ActivityComposite) {
    const agentCallerElement = <HTMLAnchorElement>document.getElementById('agentCaller');

    this.taskService
      .getAgentUrl(activityComposite.task.id, activityComposite.activity.span.start)
      .subscribe((reply) => {
        agentCallerElement.href = reply.agentCall;
        agentCallerElement.click();
        return false;
      });
  }

  private initFilter(task: Task) {
    const fromDate = task.span.start;
    const toDate = task.span.end || new TodayDate().getDefaultSearchToDate();
    const includeFinished = true;
    this.applyFilterToFilterStream({ fromDate, toDate, includeFinished });
  }

  private createActivityCompositeListObservable(): void {
    const filterToActivityComposites$ = this.filterBS.pipe(
      filter((filter) => !!filter),
      debounceTime(300),
      switchMap((filter: any) =>
        this.loadTaskActivites(this.currentSelectedTask, filter.fromDate, filter.toDate).pipe(
          map((activityComposites) => activityComposites.sort(this.sortActivityComposite)),
          map((activityComposites) => [filter, activityComposites]),
        ),
      ),
      map(([filter, activityComposites]: [any, ActivityComposite[]]) =>
        this.filterActivities(activityComposites, filter),
      ),
    );

    this.filterAndLoadSubscription = filterToActivityComposites$.subscribe(
      (activityComposites: ActivityComposite[]) => {
        this.activityCompositeListSubject.next(activityComposites);
      },
    );
  }

  private sortActivityComposite(a: ActivityComposite, b: ActivityComposite): number {
    if (!a && !b) {
      return 0;
    }

    if (!a && b) {
      return 1;
    }

    if (a && !b) {
      return -1;
    }

    return Date.parse(b.activity.deadline) - Date.parse(a.activity.deadline);
  }

  private filterActivities(activityComposites: ActivityComposite[], filter: ClientActivityListDialogFilter) {
    return activityComposites.filter((activityComposite) =>
      this.filterByFinishedActivitiesFlag(filter.includeFinished, activityComposite),
    );
  }

  private filterByFinishedActivitiesFlag(includeFinished: boolean, activityComposite: ActivityComposite) {
    if (includeFinished) {
      return true;
    }
    return activityComposite.activity.alertLevel.name != AlertLevelNames.COMPLETED;
  }

  private applyFilterToFilterStream(filter: ClientActivityListDialogFilter) {
    this.filterBS.next(filter);
  }

  private loadTaskActivites(task: Task, fromDate?: string, toDate?: string) {
    const todayDate = new TodayDate();
    const startDate = fromDate || task.span.start;
    const endDate = toDate || todayDate.getDefaultSearchToDate();
    const validStartDate = todayDate.isValidDateString(startDate);
    const validEndDate = todayDate.isValidDateString(endDate);

    if (!validStartDate || !validEndDate) {
      this.store.dispatch(
        ToastActions.showWarnMessage({
          summary: 'Felaktigt datumformat',
          detail: 'Datumen måste vara i formatet YYYY-MM-dd',
        }),
      );
      return EMPTY;
    }

    this.loadingSubject.next({ value: true });
    this.activityCompositeListSubject.next([]);

    return this.activityService.getActivites(startDate, endDate, task.id, true).pipe(
      map((activities: Activity[]) => this.processActivities(task, activities)),
      tap(() => this.loadingSubject.next({ value: false })),
    );
  }

  private processActivities(task: Task, activities: Activity[]) {
    const activityCompositeList = activities.map((activity: Activity) => {
      var ac = new ActivityComposite(task, activity);
      return ac;
    });

    return activityCompositeList;
  }
}
