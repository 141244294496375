import { Component, Input, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { Client } from '../../core/entity/client';
import { getDateValidator } from 'src/app/core/entity/validation';
import { ValidationStatus } from 'src/app/core/entity/validationStatus';
import { ImportService } from '../../core/services/import.service';
import { TableColumn } from '../../shared/components/table/interfaces/table-column';
import { TodayDate } from '../../shared/misc/today.date';
import { Store } from '@ngrx/store';
import { ToastWithoutSeverity } from 'src/app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';
import { map } from 'rxjs/operators';

declare var jschardet: any;

enum ImportType {
  CLIENT = 'client',
  DEFERAL = 'deferal',
}

@Component({
  selector: 'app-file-import',
  templateUrl: './file.imports.component.html',
  providers: [ImportService],
})
export class FileImportComponent implements OnInit {
  public availableUsersWithoutAll$: Observable<SelectItem[]>;
  public filePath = '';
  public ignoredImportsColumns: TableColumn[] = [
    { field: 'name', header: 'Namn', sortable: false },
    { field: 'corporateIdentity', header: 'Organisationsnummer', sortable: false },
    { field: 'address', header: 'Adress', sortable: false },
    { field: 'currentFinancialYear.span.start', header: 'Räkenskapsår start', sortable: false },
    { field: 'currentFinancialYear.span.end', header: 'Räkenskapsår slut', sortable: false },
  ];

  public importTypeSelectOptions = [
    { label: 'Klienter', value: ImportType.CLIENT },
    { label: 'Anstånd', value: ImportType.DEFERAL },
  ];

  public isVisible = false;

  hidePleaseWait = true;
  overWriteExistingOrgNumbers = false;
  clearPreviousDeferals = false;
  includeEmptyOrgNumbers = false;
  applyDefaulTemplates = false;
  applyClientResponsible = false;
  templateStartDate: string = new TodayDate().getTodayAsString();
  selectedUserId: number;
  selectedImportType: string = ImportType.CLIENT;
  ignoredImports: Client[] = [];

  get isClientImportSelected() {
    return this.selectedImportType === ImportType.CLIENT;
  }

  get isDeferalImportSelected() {
    return this.selectedImportType === ImportType.DEFERAL;
  }

  constructor(public importService: ImportService, private store: Store<AppState>) {}

  ngOnInit() {
    this.availableUsersWithoutAll$ = this.store.select(UserSelectors.activeUsersWithoutAll).pipe(
      map((users) => {
        if (users.length) {
          this.selectedUserId = users[0].id;
        }

        return users.map(({ name, initials, id }) => ({ label: `${name} (${initials})`, value: id }));
      }),
    );
  }

  @Input()
  set tabActive(active: boolean) {
    this.isVisible = active;
    this.hidePleaseWait = true;
    if (!this.isVisible) {
      this.filePath = '';
      this.selectedImportType = ImportType.CLIENT;
    }
  }

  changeListener(inputValue: any): void {
    if (inputValue && inputValue.files && inputValue.files.length > 0) {
      this.filePath = inputValue.files[0].name;
    } else {
      this.filePath = '';
    }
    this.ignoredImports = [];
  }

  startImport(inputValue: any): void {
    const { validate, min, max } = getDateValidator();
    const { status, title, text } = validate(this.templateStartDate, {
      min,
      max,
      label: 'Ange startdatum för tjänsterna',
    });
    const applyTemplates = this.applyDefaulTemplates;
    const hasValidationError = status !== ValidationStatus.Ok;

    if (this.isClientImportSelected && applyTemplates && hasValidationError) {
      this.store.dispatch(ToastActions.showWarnMessage({ summary: title, detail: text }));
      return;
    }

    this.readThis(inputValue);
  }

  readThis(inputValue: any): void {
    const myself = this;
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    const clientImportParameters = {
      includeEmptyOrgNumbers: this.includeEmptyOrgNumbers ? '1' : '0',
      overWriteExistingOrgNumbers: this.overWriteExistingOrgNumbers ? '1' : '0',
      clientResponsible: this.applyClientResponsible ? String(this.selectedUserId) : '0',
      templatesDate: this.applyDefaulTemplates ? this.templateStartDate : '0',
    };

    this.hidePleaseWait = false;

    myReader.onloadend = function () {
      const result = myself.isClientImportSelected
        ? jschardet.detect(myReader.result)
        : { encoding: 'UTF-8', confidence: 1.0 };

      if (!myself.isValidEncoding(result) || result.confidence <= 0.85) {
        myself.showErrorMessage({
          summary: 'Filformat',
          detail: 'Importfilen måste vara en txt-fil med UTF-8 encoding.',
        });
        myself.hidePleaseWait = true;
        return;
      }

      if (myself.isDeferalImportSelected) {
        myself.importDeferal(myReader.result as string, myself.clearPreviousDeferals);
        return;
      }

      myself.importClients(myReader.result as string, clientImportParameters);
    };

    myReader.readAsText(file);
  }

  private isValidEncoding(data: any) {
    const validEncodings = ['utf-8', 'UTF-8', 'windows-1252'];
    return data && validEncodings.includes(data.encoding);
  }

  private setIgnoredImports(event: any) {
    this.hidePleaseWait = true;

    try {
      if (Array.isArray(event)) {
        this.ignoredImports = event;
      } else {
        this.ignoredImports = JSON.parse(event._body);
      }
    } catch (err: unknown) {
      this.showErrorMessage({
        summary: 'Okänt fel inträffade',
        detail: 'Importen genomföras men svaret kunde inte behandlas.',
      });
    }

    const extraMessage =
      this.ignoredImports.length > 0
        ? this.ignoredImports.length + ' importerades inte'
        : ' samtliga klienter har importerats';
    this.showInfoMessage({ summary: 'Importen avslutad', detail: extraMessage });
  }

  private importClients(
    body: string,
    importParameters: {
      includeEmptyOrgNumbers: string;
      overWriteExistingOrgNumbers: string;
      clientResponsible: string;
      templatesDate: string;
    },
  ) {
    this.importService.sendFileContentToImport(body, importParameters).subscribe(
      (ignored: any[]) => {
        if (ignored?.length > 0) {
          this.setIgnoredImports(ignored);
        }
        this.showInfoMessage({ summary: 'Import avslutad', detail: 'Klienter har importerats' });
        this.hidePleaseWait = true;
      },
      () => this.showImportFailedMessage(),
    );
  }

  private importDeferal(body: string, clearPreviousDeferals: boolean) {
    this.importService.sendFileContentToDeferalImport(body, clearPreviousDeferals).subscribe(
      () => {
        this.showInfoMessage({ summary: 'Import avslutad', detail: 'Anstånd har importerats' });
        this.hidePleaseWait = true;
      },
      () => this.showImportFailedMessage(),
    );
  }

  private showInfoMessage(config: ToastWithoutSeverity) {
    this.store.dispatch(ToastActions.showInfoMessage(config));
  }

  private showErrorMessage(config: ToastWithoutSeverity) {
    this.store.dispatch(ToastActions.showErrorMessage(config));
  }

  private showImportFailedMessage() {
    this.showErrorMessage({
      summary: 'Import avbruten',
      detail: 'Importen kunde inte genomföras',
    });
    this.hidePleaseWait = true;
  }
}
