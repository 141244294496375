import { createFeature, createReducer, on } from '@ngrx/store';
import { LoadingStatus, TaskType } from '../types';
import { ClientTasksActions } from './clientTasks.actions';

export interface ClientTasksState {
  tasks: TaskType[];
  error: unknown;
  status: LoadingStatus;
}

const initialClientTaskState: ClientTasksState = {
  tasks: [],
  error: null,
  status: LoadingStatus.NotStarted,
};

export const clientTasksFeature = createFeature({
  name: 'clientTasks',
  reducer: createReducer(
    initialClientTaskState,
    on(ClientTasksActions.load, (state) => ({ ...state, status: LoadingStatus.Loading })),
    on(ClientTasksActions.loadSucceeded, (state, { tasks }) => ({
      ...state,
      tasks,
      error: null,
      status: LoadingStatus.NotStarted,
    })),
    on(ClientTasksActions.loadFailed, (state, { error }) => ({ ...state, error, status: LoadingStatus.Failed })),
    on(ClientTasksActions.cleanUp, () => initialClientTaskState),
  ),
});
