import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ClientService } from '../../services/clients.service';
import { ClientTypesActions } from './clientTypes.actions';

@Injectable()
export class ClientTypeEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientTypesActions.loadAllClientTypes),
      switchMap(() =>
        this.clientService.getClientTypes().pipe(
          map((types) => ClientTypesActions.loadClientTypesSucceeded({ types })),
          catchError((error: unknown) => of(ClientTypesActions.loadClientTypesFailed({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private clientService: ClientService) {}
}
