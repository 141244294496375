import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RadioButtonsProps, RadioButtonEvents } from '@bl/components';
import { BlFrontendBaseComponent } from './bl-frontend-base.component';

@Component({
  selector: 'bl-frontend-radiobuttons',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class BlFrontendRadiobuttonsComponent extends BlFrontendBaseComponent<RadioButtonsProps, RadioButtonEvents> {
  @Input() items: RadioButtonsProps['items'];
  @Input() name: RadioButtonsProps['name'] = '';
  @Input() brand: RadioButtonsProps['brand'] = 'firmsupport';
  @Input() direction: RadioButtonsProps['direction'] = 'column';
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  protected override getComponentName(): string {
    return 'RadioButtons';
  }

  protected override getProps(): RadioButtonsProps {
    return {
      name: this.name,
      brand: this.brand,
      items: this.items,
      direction: this.direction,
      onChange: (value: string) => this.onChange.emit(value),
    };
  }
}
