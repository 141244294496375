import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PartnerFirm, LevelDto } from '@app/core/state/types/partner.types';
import { PartnerProgramProgressbarLevelComponent } from './partnerprogram-progressbar-level.component';

@Component({
  selector: 'app-partnerprogram-progressbar',
  template: `
    <div class="w-full flex justify-start py-5" [ngClass]="{ 'opacity-60': hasFallenOut }">
      <div class="flex items-center" *ngFor="let level of visibleLevels; let levelIndex = index">
        <app-partnerprogram-progressbar-level
          [levels]="visibleLevels"
          [levelFrom]="level.value"
          [firm]="firm"
          [lastLevel]="levelIndex === visibleLevels.length - 1"
          [ngClass]="{ 'ml-5': levelIndex > 0 }"
          [hasFallenOut]="hasFallenOut">
        </app-partnerprogram-progressbar-level>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, PartnerProgramProgressbarLevelComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramProgressbarComponent {
  @Input() firm: PartnerFirm;
  @Input()
  set levels(levels: LevelDto[]) {
    this.visibleLevels = levels?.filter((level) => level.value > 0);
  }

  @Input() hasFallenOut: boolean;

  visibleLevels: LevelDto[] = [];
}
