<p>
  Nedan visas de företag som finns både i BL Byråstöd och i listan för publicerade Företag i molnet. Dock saknar de
  molndatabasnyckel i BL Byråstöd. (Molndatabasnyckel krävs för att kunna visa aviseringar om inkomna dokument m.m.
  direkt i BL Byråstöd under fliken Aviseringar m.m.)
</p>
<p class="font-bold mt-2 mb-6">Klicka på Lägg till om du önskar hämta in molndatabasnyckeln till BL Byråstöd.</p>
<p-table
  [columns]="columns"
  [value]="companies$ | async"
  [rows]="50"
  [rowsPerPageOptions]="[25, 50, 100, 250, 1000]"
  [paginator]="true"
  [(selection)]="selectedCompanies"
>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length + 1">
        <div class="my-3">{{ listMessage }}</div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3.4em">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th class="font-normal truncate" *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td>
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td>
        {{ rowData.client.corporateIdentity }}
      </td>
      <td>
        {{ rowData.client.name }}
      </td>
      <td>
        {{ rowData.client.customerNumber }}
      </td>
      <td>
        {{ rowData.client.responsibleUser }}
      </td>
      <td>
        {{ rowData.blaCompany.publicKey }}
      </td>
    </tr>
  </ng-template>
</p-table>
