import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AllInclusiveService } from '@app/core/services/all-inclusive.service';

@Directive({
  selector: '[appShowIfBlFirmService]',
  standalone: true,
})
export class ShowIfBlFirmServiceDirective implements OnInit {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<unknown>,
    private allInclusiveService: AllInclusiveService,
  ) {}

  ngOnInit(): void {
    this.view.clear();

    if (this.allInclusiveService.isLoggedInWithBLTAService) {
      this.view.createEmbeddedView(this.template);
    }
  }
}
