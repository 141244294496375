import { DialogData, FormDialogBaseComponent } from '../../form-dialog-base/form-dialog-base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { PleaseWaitComponent } from '../../please-wait/please-wait.component';

@Component({
  selector: 'app-change-responsible-warning-dialog',
  template: `
    <div>
      <div>
        <p>Du håller på att ändra klientansvarig.</p>
        <p>
          Om du spar din ändring kommer du inte kunna ändra klientansvarig igen. Det kan bara klientansvarig och
          byråadministratörer göra.
        </p>
        <div class="flex justify-end mt-4 space-x-2">
          <bl-frontend-button [text]="'Stäng'" variant="secondary" (click)="close({})" />
        </div>
      </div>
    </div>
  `,
  standalone: true,
  providers: [DialogService],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BlFrontendButtonComponent, PleaseWaitComponent],
})
export class ChangeResponsibleWarningDialog extends FormDialogBaseComponent {
  protected static override getDialogConfig(): Omit<DynamicDialogConfig<DialogData>, 'data'> {
    return {
      header: 'Ändra klientansvarig?',
      width: '450px',
      closable: false,
      closeOnEscape: false,
      draggable: false,
      modal: true,
    };
  }
}
