import { Component, OnDestroy, OnInit } from '@angular/core';
import { CLIENT_RESPONSIBLE } from '@app/shared/misc/constants';
import { ApplicationLinksService } from '@app/core/services/application-links.service';
import { Store } from '@ngrx/store';
import { Client } from '../core/entity/client';
import { ClientService } from '../core/services/clients.service';
import { ClientActions } from '../core/state/clients/clients.actions';
import { ClientType, TeamType } from '../core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSettingsStorage } from '../core/storage/user.settings.storage';
import { TableColumn } from '../shared/components/table/interfaces/table-column';
import {
  ApplicationLink,
  ApplicationLinksGroup,
  ApplicationType,
} from '@app/shared/misc/application-link-builders/types';
import { Observable, Subject, of } from 'rxjs';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { filter, first, map, mergeMap, takeUntil } from 'rxjs/operators';
import { ListActions } from '@app/core/state/list/list.actions';
import { ListSelectors } from '@app/core/state/list/list.selectors';
import { toClientTransformer, toClientTypeTransformer } from '@app/core/state/transformers/transformers';
import { Actions, ofType } from '@ngrx/effects';
import { showClientDialog } from '@app/shared/misc/showClientDialog';
import { DialogService } from 'primeng/dynamicdialog';
import { TeamsSelectors } from '@app/core/state/teams/teams.selectors';
import { openDialog } from '@app/shared/misc/openDialog';
import { MissingCloudApiKeyDialog } from '@app/shared/components/missing-cloud-api-key/missing-cloud-api-key.dialog';
import { ActivateSustainabilityReportingDialog } from '@app/shared/components/new-client/components/activate-sustainability-reporting-dialog/activate-sustainability-reporting-dialog';
import { DeactivateSustainabilityReportingDialog } from '@app/shared/components/new-client/components/deactivate-sustainability-reporting-dialog/deactivate-sustainability-reporting-dialog';
import { ArchiveOrDeleteClientDialogComponent } from './archive-or-delete-client-dialog/archive-or-delete-client.dialog';
import { AdvisoryToolActivateClientDialog } from '@app/advisory-tool/components/advisory-tool-activate-client/advisory-tool-activate-client.dialog';

@Component({
  selector: 'app-client-list',
  templateUrl: './client.list.component.html',
  providers: [DialogService],
})
export class ClientListComponent implements OnInit, OnDestroy {
  public clients$: Observable<ClientType[]>;

  public loading$: Observable<boolean>;
  public statusText$: Observable<string>;
  public includeArchivedLabel$: Observable<string>;
  public model: Client;
  public pendingDeletionClient?: Client;
  public displayArchiveClientDialog = false;
  public includeArchivedClients = false;
  public selectedClientResponsibleUserId: number | undefined;
  public displayCompareCloudCompaniesDialog = false;
  public defaultNumberOfRows = 50;
  public defaultColumns: TableColumn[] = [
    { field: 'customerNumber', header: 'Kundnr' },
    { field: 'corporateIdentity', header: 'Orgnr' },
    { field: 'name', header: 'Namn' },
    { field: 'phone', header: 'Telefon' },
    { field: 'email', header: 'E-post' },
    { field: 'city', header: 'Postort' },
    { field: 'advisoryTool', header: 'Rådgivarverktyg' },
    { field: 'responsibleUser', header: CLIENT_RESPONSIBLE },
    { field: 'cellphone', header: 'Mobil', visible: false },
    { field: 'address', header: 'Adress', visible: false },
    { field: 'zipCode', header: 'Postnr', visible: false },
    { field: 'residence', header: 'Säte', visible: false },
    { field: 'signatory', header: 'Firmatecknare', visible: false },
    { field: 'firmType', header: 'Företagstyp', visible: false },
    { field: 'latestFinancialYear', header: 'Räkenskapsår', visible: false },
    { field: 'country', header: 'Land', visible: false },
    { field: 'webpage', header: 'Hemsida', visible: false },
    { field: 'bank', header: 'Bank', visible: false },
    { field: 'businessArea', header: 'Bransch', visible: false },
    { field: 'registrationDate', header: 'Reg.datum', visible: false },
    { field: 'accountant', header: 'Revisor', visible: false },
    { field: 'cloudApiKey', header: 'Molndatabasnyckel', visible: false, width: '10%' },
    { field: 'external-app-bla', header: 'BL Adm', visible: false, sortable: false, width: '50px' },
    { field: 'external-app-bls-current', header: 'BL Skatt', visible: false, sortable: false, width: '50px' },
    { field: 'external-app-bls-prev', header: 'BL Skatt föregående', visible: false, sortable: false, width: '50px' },
    { field: 'external-app-blb-current', header: 'BL Bokslut', visible: false, sortable: false, width: '50px' },
    { field: 'external-app-blb-prev', header: 'BL Bokslut föregående', visible: false, sortable: false, width: '50px' },
    { field: 'action', header: '', width: '40px', sortable: false, selectable: false },
    { field: 'sustainabilityReporting', header: 'Hållbarhetsredovisning', visible: false },
  ];

  public columns: TableColumn[] = [...this.defaultColumns];
  public tabToOpen: string;
  private uss: UserSettingsStorage;
  private externalAppColumns: string[] = [];

  private onDestroySubject: Subject<boolean>;

  constructor(
    private clientService: ClientService,
    private store: Store<AppState>,
    private applicationLinksService: ApplicationLinksService,
    private actions$: Actions,
    private dialogService: DialogService,
  ) {
    this.externalAppColumns = this.columns.filter((c) => c.field.startsWith('external-app-')).map((c) => c.field);
    this.onDestroySubject = new Subject();
  }

  ngOnInit() {
    this.loadSettings();
    const groups = this.applicationLinksService.getApplicationLinksGroupsWithTasks();
    const linkMap = this.mapGroupsToRecord(groups);
    this.statusText$ = this.store.select(ListSelectors.getStatusText);
    this.includeArchivedLabel$ = this.store.select(ClientSelectors.includeArchivedLabelSelector);
    this.initClients(linkMap);
    this.loading$ = this.store.select(ListSelectors.isLoading);
    this.actions$
      .pipe(ofType(ClientActions.archiveClient, ClientActions.deleteClient), takeUntil(this.onDestroySubject))
      .subscribe(() => {
        this.resetVariables();
      });
    this.store
      .select(TeamsSelectors.selectTeamSelector)
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(({ ids }) => {
        const visible = ids?.length > 1;
        const teamsColumn: TableColumn = { field: 'teamsString', header: 'Team', visible };
        this.columns = [teamsColumn, ...this.defaultColumns];
      });
  }

  ngOnDestroy() {
    this.uss.saveSetting(UserSettingsStorage.CLIENT_SHOW_ARCHIVED, `${this.includeArchivedClients}`);
    this.uss.saveSetting(UserSettingsStorage.CLIENT_RESPONSIBLE_ID, `${this.selectedClientResponsibleUserId}`);
    this.onDestroySubject.next(true);
  }

  reloadClients() {
    this.store.dispatch(
      ClientActions.loadClientList({
        filter: {
          includeArchivedClients: this.includeArchivedClients,
          responsibleUserId: this.selectedClientResponsibleUserId,
        },
      }),
    );
  }

  refilterClients() {
    this.store.dispatch(
      ListActions.filterList({
        filter: {
          includeArchivedClients: this.includeArchivedClients,
          responsibleUserId: this.selectedClientResponsibleUserId,
        },
      }),
    );
  }

  onCellClick({ column, data }: { column: TableColumn; data: any }) {
    this.model = toClientTransformer.transform(data);

    if (column.field === 'advisoryTool') {
      this.handleActiveAdvisoryToolClick(toClientTypeTransformer.transform(this.model));
      return;
    }

    if (column.field === 'sustainabilityReporting') {
      this.handleSustainabilityReportingClick(toClientTypeTransformer.transform(this.model));
      return;
    }

    if (this.externalAppColumns.includes(column.field)) {
      this.externalAppClick({ column, data });
      return;
    }

    if (column.field === 'action') {
      return;
    }

    this.openClientCard();
  }

  showNewClient() {
    this.model = new Client('');
    showClientDialog({
      clientService: this.clientService,
      dialogService: this.dialogService,
      id: this.model.id,
      store: this.store,
    })
      .pipe(first())
      .subscribe((result) => {
        if (result?.client?.id && result?.client?.id !== -1) {
          this.reloadClients();
        }
      });
  }

  tryDeleteClient(clientToDelete: Client) {
    this.clientService
      .getClientById(clientToDelete.id)
      .pipe(
        map((client) => toClientTypeTransformer.transform(client)),
        mergeMap((client) => {
          const { deleteable } = client;
          if (!deleteable) {
            return of({ client });
          }

          return openDialog(this.dialogService, ArchiveOrDeleteClientDialogComponent, { client });
        }),
      )
      .subscribe((result) => {
        if (!result?.client) {
          return;
        }

        if (!result.client.deleteable) {
          this.pendingDeletionClient = toClientTransformer.transform(result.client);
          this.displayArchiveClientDialog = true;
        }
      });
  }

  reactivateClient(clientToReactivate: Client) {
    const client = toClientTypeTransformer.transform(clientToReactivate);
    client.archived = false;
    this.store.dispatch(ClientActions.reactivateClient({ client, predicate: this.getClientListPredicate(client.id) }));
  }

  onArchiveClient() {
    if (!this.pendingDeletionClient) {
      return;
    }

    const client = toClientTypeTransformer.transform(this.pendingDeletionClient);
    client.archived = true;
    this.store.dispatch(ClientActions.archiveClient({ client, predicate: this.getClientListPredicate(client.id) }));
  }

  onCancel() {
    this.resetVariables();
  }

  openClientCard(tabToOpen: string = null) {
    showClientDialog({
      clientService: this.clientService,
      dialogService: this.dialogService,
      id: this.model.id,
      store: this.store,
      tabToOpen,
    })
      .pipe(first())
      .subscribe((result) => {
        if (result?.client?.id && result?.client?.id !== -1) {
          this.reloadClients();
        }
      });
  }

  showCompareCloudCompaniesDialog() {
    this.displayCompareCloudCompaniesDialog = true;
  }

  private resetVariables() {
    this.displayArchiveClientDialog = false;
    this.pendingDeletionClient = null;
  }

  private loadSettings() {
    this.uss = new UserSettingsStorage();

    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.CLIENT_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.includeArchivedClients = this.uss.loadSettingAsBoolean(UserSettingsStorage.CLIENT_SHOW_ARCHIVED, true);
    this.selectedClientResponsibleUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.CLIENT_RESPONSIBLE_ID, 0);
  }

  private callExternalApp(client: Client, { type, year }: ApplicationLink) {
    const agentCallerElement = <HTMLAnchorElement>document.getElementById('externalAppCaller');

    this.clientService.getAgentUrl(client.id).subscribe(({ agentCall }) => {
      let href = agentCall;
      const financialYear = `financialYear=${year}0101-${year}1231&`;
      if (type === ApplicationType.BLSkatt) {
        href = href.replace('blinfoa://', `blinfos${year}://${financialYear}`);
        href = href.replace('&loginId=', '&task=UPPI&loginId=');
      }

      if (type === ApplicationType.BLBokslut) {
        href = href.replace('blinfoa://', `blinfob${year}://${financialYear}`);
        href = href.replace('&loginId=', '&task=ARSR&loginId=');
      }

      agentCallerElement.href = href;
      agentCallerElement.click();
      return false;
    });
  }

  private mapGroupsToRecord(groups: ApplicationLinksGroup[]): Record<string, ApplicationLink> {
    const blaGroup = groups.find((g) => g.type === ApplicationType.BL);
    const blsGroup = groups.find((g) => g.type === ApplicationType.BLSkatt);
    const blbGroup = groups.find((g) => g.type === ApplicationType.BLBokslut);

    return {
      'external-app-bla': blaGroup.links[0],
      'external-app-bls-current': blsGroup.links[0],
      'external-app-bls-prev': blsGroup.links[1],
      'external-app-blb-current': blbGroup.links[0],
      'external-app-blb-prev': blbGroup.links[1],
    };
  }

  private getClientListPredicate(clientId: number) {
    return (client: ClientType) => client.id === clientId;
  }

  private initClients(linkMap: Record<string, ApplicationLink>) {
    this.clients$ = this.store.select(ClientSelectors.allFilteredClients).pipe(
      map(({ filteredClients, rootTeam }) =>
        filteredClients.map((client) => {
          const latestFinancialYear = client.financialYears[client.financialYears.length - 1];
          return {
            ...client,
            responsibleUser: client.responsible?.name ?? '',
            latestFinancialYear:
              client.financialYears.length !== 0
                ? `${latestFinancialYear.span.start.replace(/-/g, '')}-${latestFinancialYear.span.end.replace(
                    /-/g,
                    '',
                  )}`
                : '',
            firmType: client.type.description ?? '',
            ...linkMap,
            teamsString: this.getTeamsString(client, rootTeam),
          };
        }),
      ),
    );
  }

  private getTeamsString = (client: ClientType, rootTeam?: TeamType) =>
    client.teams
      ?.filter((t) => t.id !== rootTeam?.id)
      .map((t) => t.name)
      .join(', ');

  private externalAppClick = ({ column, data }: { column: TableColumn; data: any }) => {
    if (column.field === 'external-app-bla') {
      return;
    }
    this.callExternalApp(data, data[column.field]);
  };

  private handleActiveAdvisoryToolClick = (client: ClientType) => {
    openDialog(this.dialogService, AdvisoryToolActivateClientDialog, { client }).subscribe((result) => {
      if (!result) {
        return;
      }

      if (result.openClientCard) {
        this.openClientCard('Molntjänster');
      }
    });
  };

  private handleSustainabilityReportingClick = (client: ClientType) => {
    if (!client.cloudApiKey) {
      openDialog(this.dialogService, MissingCloudApiKeyDialog, {
        client,
        featureToActivate: 'hållbarhetsredovisning',
      })
        .pipe(filter((result) => Boolean(result?.client)))
        .subscribe(() => this.openClientCard('Molntjänster'));
      return;
    }

    if (client.sustainabilityReporting) {
      openDialog(this.dialogService, DeactivateSustainabilityReportingDialog, { client })
        .pipe(
          filter((result) => Boolean(result?.client)),
          first(),
        )
        .subscribe(() => this.reloadClients());
      return;
    }

    openDialog(this.dialogService, ActivateSustainabilityReportingDialog, { client })
      .pipe(
        filter((result) => Boolean(result?.client)),
        first(),
      )
      .subscribe(() => this.reloadClients());
  };
}
