import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { TeamMultiSelectorComponent } from '@app/shared/components/team-multi-selector/team-multi-selector.component';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { AdvisoryNotification } from 'src/app/core/entity/advisory.notification';

@Component({
  selector: 'app-advisory-tool-table',
  templateUrl: './advisory-tool-table.component.html',
  styleUrls: ['./advisory-tool-table.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TableModule, TooltipModule, TeamMultiSelectorComponent],
})
export class AdvisoryToolTableComponent {
  @Input() tableData: AdvisoryNotification[];
  @Input() columns: {
    field: string;
    header: string;
    status?: boolean;
  }[];

  @Output() tableColumnClick = new EventEmitter<{ type: string; notification: AdvisoryNotification }>();

  onColumnClick(columnName: string, notification: AdvisoryNotification) {
    const clickedObject = {
      type: columnName,
      notification,
    };

    return this.tableColumnClick.emit(clickedObject);
  }
}
