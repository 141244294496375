import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { BlaCompany } from 'src/app/core/entity/bla-company';
import { Client } from 'src/app/core/entity/client';
import { CloudCompanyService } from 'src/app/core/services/cloud-company.service';

@Component({
  selector: 'app-connect-cloud-company-dialog',
  templateUrl: 'connect-cloud-company-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectCloudCompanyDialogComponent {
  public cloudCompanies$: Observable<{
    loading: boolean;
    error?: boolean;
    data: BlaCompany[] | undefined;
  }>;
  public cloudCompanyColumns = [
    { field: 'orgNumber', header: 'OrgNr', width: '135px' },
    { field: 'name', header: 'Namn' },
    { field: 'publicKey', header: 'Molndatabasnyckel' },
    { field: 'action', header: '', width: '120px' },
  ];

  private _visible = false;

  @Input() client: Client;
  @Input()
  get visible(): boolean {
    return this._visible;
  }
  set visible(value: boolean) {
    this._visible = value;
    this.visibleChange.emit(value);

    if (value) {
      this.initLoadOfCloudCompanies();
    }
  }

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() updated: EventEmitter<Client> = new EventEmitter();

  constructor(private cloudCompanyService: CloudCompanyService) {}

  public connectCloudCompany(cloudCompany: BlaCompany) {
    this.updated.emit({ ...this.client, cloudApiKey: cloudCompany.publicKey });
    this.closeDialog();
  }

  public closeDialog() {
    this.visible = false;
  }

  private initLoadOfCloudCompanies() {
    const filterMatchingCompaniesFn = this.findMatchingCompanies(this.client.corporateIdentity);
    const source = this.cloudCompanyService.getCloudCompanies(false, true).pipe(
      map(filterMatchingCompaniesFn),
      map((cloudCompanies: BlaCompany[]) => ({ loading: false, data: cloudCompanies })),
      catchError(() => of({ loading: false, error: true, data: undefined }))
    );

    this.cloudCompanies$ = source.pipe(startWith({ loading: true, data: undefined }));
  }

  private findMatchingCompanies(clientCorporateIdentity: string) {
    return (cloudCompanies: BlaCompany[]) =>
      (cloudCompanies || []).filter((cc) => !!cc.publicKey && cc.orgNumber === clientCorporateIdentity);
  }
}
