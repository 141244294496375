import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompositeListFilter, CompositeListParams, CompositeType } from '@app/core/state/types';
import { IncomeStatementCompositeType } from '@app/core/state/types/incomestatement.types';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';
import { CompositeListComponent } from '@app/shared/components/composite-list/composite-list.component';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { TodayDate } from '@app/shared/misc/today.date';

@Component({
  selector: 'app-incomestatement-list',
  templateUrl: './incomestatement.list.component.html',
})
export class IncomeStatementListComponent
  extends CompositeListComponent<IncomeStatementCompositeType, CompositeListFilter, CompositeListParams>
  implements OnInit, OnDestroy
{
  selectedYear: string;
  selectedClientResponsibleUserId: number;
  selectedTaskUserId: number;
  includeAllDoneClients: boolean;
  columns: TableColumn[] = [
    { field: 'client', header: 'Klient', width: '13%' },
    { field: 'corporateIdentity', header: 'Orgnr', width: '10%' },
    { field: 'customerNumber', header: 'Kundnr' },
    { field: 'responsible', header: 'Klientansv' },
    {
      field: 'konu.activity.state.description',
      header: 'KU (ospec)',
      fieldValue: (_fieldValue, rowData) => rowData?.konu?.activity?.state?.description,
    },
    {
      field: 'eulo.activity.state.description',
      header: 'KU Löner',
      fieldValue: (_fieldValue, rowData) => rowData?.eulo?.activity?.state?.description,
      visible: false,
    },
    {
      field: 'eura.activity.state.description',
      header: 'KU Ränta',
      fieldValue: (_fieldValue, rowData) => rowData?.eura?.activity?.state?.description,
    },
    {
      field: 'kuut.activity.state.description',
      header: 'KU Utdelning',
      fieldValue: (_fieldValue, rowData) => rowData?.kuut?.activity?.state?.description,
    },
    {
      field: 'fora.activity.state.description',
      header: 'Forarapportering',
      fieldValue: (_fieldValue, rowData) => rowData?.fora?.activity?.state?.description,
    },
    {
      field: 'rest.activity.state.description',
      header: 'Restaurangrapport',
      visible: false,
      fieldValue: (_fieldValue, rowData) => rowData?.rest?.activity?.state?.description,
    },
    {
      field: 'sral.activity.state.description',
      header: 'Statistiktrapport Alkohol',
      visible: false,
      fieldValue: (_fieldValue, rowData) => rowData?.sral?.activity?.state?.description,
    },
    { field: 'taskUsers', header: 'UH', width: '5%', visible: false },
    { field: 'program', header: 'Program', width: '70px', sortable: false, exportable: false },
  ];

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  loadUserSettings(): void {
    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.INCOME_STATEMENT_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.selectedYear = this.uss.loadSetting(
      UserSettingsStorage.INCOME_STATEMENT_YEAR,
      new TodayDate().getRelevantYearAsString(4),
    );
    this.selectedClientResponsibleUserId = this.uss.loadSettingAsNumber(
      UserSettingsStorage.INCOME_STATEMENT_CLIENT_RESPONSIBLE_ID,
      0,
    );
    this.selectedTaskUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.INCOME_STATEMENT_USER_ID, 0);
    this.includeAllDoneClients = this.uss.loadSettingAsBoolean(
      UserSettingsStorage.INCOME_STATEMENT_INCLUDE_ALL_DONE_CLIENTS,
      true,
    );
  }

  saveUserSettings(): void {
    this.uss.saveSetting(UserSettingsStorage.INCOME_STATEMENT_YEAR, this.selectedYear);
    this.uss.saveSetting(
      UserSettingsStorage.INCOME_STATEMENT_CLIENT_RESPONSIBLE_ID,
      `${this.selectedClientResponsibleUserId}`,
    );
    this.uss.saveSetting(UserSettingsStorage.INCOME_STATEMENT_USER_ID, `${this.selectedTaskUserId}`);
    this.uss.saveSetting(
      UserSettingsStorage.INCOME_STATEMENT_INCLUDE_ALL_DONE_CLIENTS,
      `${this.includeAllDoneClients}`,
    );
  }

  getFilterValue() {
    return { includeAllDoneClients: this.includeAllDoneClients };
  }

  getParamsValue() {
    return {
      responsibleId: this.selectedClientResponsibleUserId,
      executorId: this.selectedTaskUserId,
      year: this.selectedYear,
    };
  }

  getPaginatorTriggeredKey() {
    return UserSettingsStorage.INCOME_STATEMENT_NUMBER_OF_ROWS;
  }

  getCompositeType() {
    return CompositeType.incomeStatement;
  }
}
