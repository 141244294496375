import { Injectable } from '@angular/core';
import { ActivityService } from '@app/core/services/activity.service';
import { CustomListService } from '@app/core/services/customlist.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { BaseEffects } from '../core/base.effects';
import { ListActions } from '../list/list.actions';
import { ToastActions } from '../toast/toast.actions';
import { toActivityTransformer, toActivityTypeTransformer } from '../transformers/transformers';
import {
  ClosingListParams,
  CompositeBaseType,
  CompositeType,
  CompositeListParams,
  IncomeTaxDeclarationListParams,
  ListParams,
  SalaryListParams,
  TaxDeclarationListParams,
  AccountingListParams,
} from '../types';
import { AppState } from '../appState';
import { CompositeActions } from './composite.actions';

@Injectable()
export class CompositeEffects extends BaseEffects {
  loadComposites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.loadComposites),
      this.whenOnCompositeUrl(),
      switchMap(({ params, compositeType }) =>
        this.getComposites$(compositeType, params).pipe(
          first(),
          map((items) => ListActions.loadSucceeded({ items })),
          this.takeUntilNavigationStart(),
          catchError((error: unknown) => of(ListActions.loadFailed({ error }))),
        ),
      ),
    ),
  );

  loadComposite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.loadComposites),
      this.whenOnCompositeUrl(),
      map(({ filter, params }) => ListActions.load({ filter, params })),
    ),
  );

  updateActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompositeActions.updateActivity),
      this.whenOnCompositeUrl(),
      switchMap(({ activity, callback }) =>
        this.activityService.saveActivity(toActivityTransformer.transform(activity)).pipe(
          mergeMap((item) => [
            ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Aktiviteten har sparats' }),
            ListActions.updateItemInListSucceeded({ item: toActivityTypeTransformer.transform(item), callback }),
          ]),
          catchError((error: unknown) => of(ListActions.updateItemInListFailed({ error }))),
        ),
      ),
    ),
  );

  constructor(
    actions$: Actions,
    store: Store<AppState>,
    private customListService: CustomListService,
    private activityService: ActivityService,
  ) {
    super(actions$, store);
  }

  private getComposites$ = (type: CompositeType, params: ListParams): Observable<CompositeBaseType[]> => {
    if (type === CompositeType.accounting) {
      return this.customListService.getAccountingCompositeTypes(params as AccountingListParams);
    }

    if (type === CompositeType.closing) {
      return this.customListService.getClosingCompositeTypes(params as ClosingListParams);
    }

    if (type === CompositeType.incomeStatement) {
      return this.customListService.getIncomeStatementCompositeTypes(params as CompositeListParams);
    }

    if (type === CompositeType.incomeTaxDeclaration) {
      return this.customListService.getIncomeTaxdeclarationCompositeTypes(params as IncomeTaxDeclarationListParams);
    }

    if (type === CompositeType.salaries) {
      return this.customListService.getSalaryCompositeTypes(params as SalaryListParams);
    }

    if (type === CompositeType.taxDeclaration) {
      return this.customListService.getTaxdeclarationCompositeTypes(params as TaxDeclarationListParams);
    }

    return of([]);
  };
}
