import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { PleaseWaitComponent } from '@app/shared/components/please-wait/please-wait.component';
import { ofType } from '@ngrx/effects';
import { Action, ActionCreator } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { take } from 'rxjs';
import { FocusTrapModule } from 'primeng/focustrap';
import { AutoFocusModule } from 'primeng/autofocus';
import {
  DialogData,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';

interface Data extends DialogData {
  body: string;
  header: string;
  buttonText: string;
  confirmText: string;
  actionToDispatch: Action;
  actionOnSuccess: ActionCreator;
  actionOnFail: ActionCreator;
}
interface Form {
  confirm: FormControl<string>;
}

@Component({
  selector: 'app-firm-reset',
  template: `
    <app-please-wait [hidden]="hidePleaseWait"></app-please-wait>
    <div class="w-full" pFocusTrap>
      <div class="bg-bl-orange-100 border border-bl-orange-500 mb-2 p-4 text-center">
        <span>{{ config.data.header }}</span>
      </div>
      <p>{{ config.data.body }}</p>
      <form [formGroup]="form" class="flex flex-col py-2" (ngSubmit)="onButtonClick()" autocomplete="off">
        <p id="username-help">
          Var snäll och skriv <strong>{{ config.data.confirmText }}</strong> för att bekräfta och tryck på knappen.
        </p>
        <input
          pInputText
          id="username"
          aria-describedby="username-help"
          formControlName="confirm"
          [placeholder]="config.data.confirmText"
          pAutoFocus
          [autofocus]="true" />
        <p-button
          [label]="config.data.buttonText"
          styleClass="p-button-outlined p-button-danger w-full"
          [disabled]="form.invalid"
          class="mt-2"
          type="submit">
        </p-button>
      </form>
    </div>
  `,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutoFocusModule,
    FocusTrapModule,
    ButtonModule,
    InputTextModule,
    PleaseWaitComponent,
  ],
})
export class FirmResetDialog extends FormDialogBaseComponent<Data, DialogResult, Form> {
  hidePleaseWait: boolean;

  protected override onInitialized = () => {
    this.form = this.builder.group(
      { confirm: new FormControl('', Validators.required) },
      { validators: this.validateRequiredFields() },
    );

    this.hidePleaseWait = true;

    // eslint-disable-next-line rxjs/no-unbound-methods
    this.actions$.pipe(ofType(this.config.data.actionOnSuccess), take(1)).subscribe(() => {
      new UserSettingsStorage().clearSettings();
      this.hidePleaseWait = true;
      this.ref.close({});
    });

    // eslint-disable-next-line rxjs/no-unbound-methods
    this.actions$.pipe(ofType(this.config.data.actionOnFail), take(1)).subscribe(() => {
      this.hidePleaseWait = true;
      this.ref.close();
    });
  };

  protected static override getDialogConfig = (): Omit<DynamicDialogConfig<Data>, 'data'> => ({
    header: 'Är du helt säker?',
    closable: true,
    closeOnEscape: true,
    width: '500px',
    modal: true,
    resizable: false,
    draggable: false,
  });

  onButtonClick = () => {
    if (!this.isConfirmed(this.form, this.config.data.confirmText)) {
      return;
    }

    this.hidePleaseWait = false;
    this.store.dispatch(this.config.data.actionToDispatch);
  };

  private isConfirmed = (form: FormGroup<Form>, confirmText: string) => form.controls.confirm.value === confirmText;

  private validateRequiredFields(): ValidatorFn {
    return (form: FormGroup<Form>): ValidationErrors | null => {
      if (this.isConfirmed(form, this.config.data.confirmText)) {
        return null;
      }

      return {
        requiredFields: {
          message: 'Bekräfta texten',
        },
      };
    };
  }
}
