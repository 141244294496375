import { Pipe } from '@angular/core';
import { AmlStatus } from '@app/core/state/types/aml.types';
import { ComponentVariant } from '@bl/components';

@Pipe({
  name: 'amlStatusVariantType',
  standalone: true,
})
export class AmlStatusVariantTypePipe {
  transform(value: AmlStatus | null): ComponentVariant {
    switch (value) {
      case AmlStatus.WAITING_COLLEAGUE:
        return 'danger';
      case AmlStatus.WAITING_COLLEAGUE_APPROVAL:
        return 'danger';
      case AmlStatus.WAITING_CLIENT:
        return 'danger';
      case AmlStatus.DECLINED:
        return 'warning';
      default:
        return 'info';
    }
  }
}
