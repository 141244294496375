import { createActionGroup, props } from '@ngrx/store';
import { ClientType } from '../types';
import { CloudDbData } from '@app/core/entity/cloud-db-data';

export const ExportWizardActions = createActionGroup({
  source: 'Export Wizard',
  events: {
    'Get Default Values': props<{ client: ClientType }>(),
    'Get Default Values Succeeded': props<{ data: CloudDbData }>(),
    'Get Default Values Failed': props<{ error: unknown }>(),
  },
});
