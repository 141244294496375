import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ALL_WITH_HYPHENS } from '@app/shared/misc/constants';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  selector: 'app-multiselect-dropdown-including-all',
  template: `
    <p-multiSelect
      [options]="options"
      [ngModel]="selected"
      (ngModelChange)="selectedChanged($event)"
      (onPanelShow)="onPanelShow()"
      (onPanelHide)="onPanelHide()"
      [optionLabel]="optionLabel"
      [optionValue]="optionValue"
      [filter]="false"
      [showHeader]="false"
      [showToggleAll]="false"
      [selectedItemsLabel]="selectedItemsLabel"
      [maxSelectedLabels]="1"
      [scrollHeight]="scrollHeight"
      [autoOptionFocus]="false"
      [inputId]="inputId"
      [styleClass]="styleClass">
    </p-multiSelect>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, MultiSelectModule],
})
export class MultiSelectDropDownIncludingAllComponent implements OnChanges {
  public selectedItemsLabel = ALL_WITH_HYPHENS;
  public scrollHeight: string;
  private _selected: any[];
  private _options: any[];
  private intialized = false;

  @Input() selectedItemLabel = '';
  @Input() allValue: string | number;
  @Input() allOption: any;
  @Input() optionLabel = 'label';
  @Input() optionValue = 'value';
  @Input() inputId: string;
  @Input() styleClass = '';

  @Input()
  get options(): any[] {
    return this._options;
  }

  set options(options: any[]) {
    if (!options?.length) {
      return;
    }

    this._options = this.allOption
      ? options
      : [{ [this.optionLabel]: ALL_WITH_HYPHENS, [this.optionValue]: this.allValue }].concat(options);
    this.scrollHeight = getScrollHeightString(this._options.length);
  }

  @Input()
  get selected(): any[] {
    return this._selected;
  }

  set selected(selected: any[]) {
    this._selected = this.selectedIsAllButAllvalue(selected) ? [this.allValue, ...selected] : selected;
    this.refreshSetItemsLabel();
  }

  @Output() selectedChange: EventEmitter<any[]>;

  constructor() {
    this.selectedChange = new EventEmitter();
  }

  // ngOnChanges makes sure we wait for async options to be loaded
  ngOnChanges(_changes: SimpleChanges): void {
    if (this.intialized) {
      return;
    }

    if (!this._options?.length) {
      return;
    }

    if (this.allValue === undefined) {
      return;
    }

    this.intialized = true;
  }

  selectedChanged(data: unknown[]) {
    const prevIncludesAll = this.getPreviousIncludesAll();
    const nextIncludesAll = this.getNextIncludesAll(data);

    // all has been checked
    if (this.hasAllValueBeenChecked(prevIncludesAll, nextIncludesAll)) {
      this.selectAll();
      this.refreshSetItemsLabel();
      return;
    }

    // all has been unchecked
    if (this.hasAllValueBeenUnChecked(prevIncludesAll, nextIncludesAll)) {
      this._selected = [];
      this.refreshSetItemsLabel();
      return;
    }

    // whenever all is selected and we uncheck all value
    if (this.hasOneBeenUnchecked(data)) {
      this._selected = this.getSelectedWithoutAllValue(data);
      this.refreshSetItemsLabel();
      return;
    }

    // whenever we check the last one
    if (this.hasLastOneBeenChecked(data)) {
      this._selected = this.getSelectedWithAllValue(data);
      this.refreshSetItemsLabel();
      return;
    }

    this._selected = data;
    this.refreshSetItemsLabel();
  }

  onPanelShow() {}

  onPanelHide() {
    if (!this.selected || this.selected.length !== 0) {
      return;
    }
    this.selectAll();
  }

  private selectAll() {
    this._selected = this._options.map((o) => o[this.optionValue]);
    this.refreshSetItemsLabel();
  }

  private refreshSetItemsLabel() {
    this.selectedChange.emit(this.selected);

    if (!this._options?.length) {
      this.setItemsLabelForNoOptions();
      return;
    }

    this.setItemsLabel();
  }

  private setItemsLabelForNoOptions() {
    if (!this.allOption) {
      this.selectedItemsLabel = !this.selected?.length ? ALL_WITH_HYPHENS : `{0} ${this.selectedItemLabel} valda`;
      return;
    }
  }

  private setItemsLabel() {
    if (!this.allOption) {
      this.selectedItemsLabel =
        this.selected?.length >= this._options?.length || this.selected?.length === 0
          ? ALL_WITH_HYPHENS
          : `{0} ${this.selectedItemLabel} valda`;
      return;
    }

    this.setItemsLabelUsingAllOption();
  }

  private setItemsLabelUsingAllOption = () => {
    this.selectedItemsLabel =
      this.selected?.length >= this._options?.length || this.selected?.length === 0
        ? this.getAllOptionLabel()
        : `{0} ${this.selectedItemLabel} valda`;
  };

  private getPreviousIncludesAll = () =>
    this.allOption ? this.selected.includes(this.getAllOptionValue()) : this.selected.includes(this.allValue);

  private getNextIncludesAll = (data: unknown[]) =>
    this.allOption ? data.includes(this.getAllOptionValue()) : data.includes(this.allValue);

  private hasAllValueBeenChecked = (prevIncludesAll: boolean, nextIncludesAll: boolean) =>
    nextIncludesAll && !prevIncludesAll;

  private hasAllValueBeenUnChecked = (prevIncludesAll: boolean, nextIncludesAll: boolean) =>
    prevIncludesAll && !nextIncludesAll;

  private hasOneBeenUnchecked = (data: unknown[]) =>
    this.selected.length === this._options.length && data.length < this._options.length;

  private hasLastOneBeenChecked = (data: unknown[]) =>
    this.selected.length === this._options.length - 2 && data.length === this._options.length - 1;

  private getSelectedWithoutAllValue = (data: unknown[]) =>
    this.allOption ? data.filter((d) => d !== this.getAllOptionValue()) : data.filter((d) => d !== this.allValue);

  private getSelectedWithAllValue = (data: unknown[]) =>
    this.allOption ? [this.getAllOptionValue(), ...data] : [this.allValue, ...data];

  private selectedIsAllButAllvalue = (selected: unknown[]) =>
    selected?.length === this._options?.length - 1 && !selected?.includes(this.allValue);

  private getAllOptionLabel = () => {
    if (!this.allOption) {
      return ALL_WITH_HYPHENS;
    }

    return this.allOption[this.optionLabel] || this.allOption.name || ALL_WITH_HYPHENS;
  };

  private getAllOptionValue = () => {
    if (!this.allOption) {
      return null;
    }

    return this.allOption[this.optionValue] || this.allOption.id || null;
  };
}
