import { Task } from 'src/app/core/entity/task';
import { BaseApplicationLinksBuilder } from './BaseApplicationLinksBuilder';
import { ApplicationLink, ApplicationType } from './types';

export class YearlyApplicationLinksBuilder extends BaseApplicationLinksBuilder {
  constructor(title: string, private type: ApplicationType, private cutOffDate: Date) {
    super(title);
  }

  canBuild(type: ApplicationType): boolean {
    return this.type === type;
  }

  build(type: ApplicationType, tasks: Task[]): ApplicationLink[] {
    this.cantBuildGuard(type);

    const today = new Date();
    const nextYear = today.getUTCFullYear();
    const thisYear = nextYear - 1;
    const prevYear = thisYear - 1;
    const thisYearLink = this.createLink({
      type: this.type,
      title: `${this.title} ${thisYear}`,
      tasks,
      year: thisYear,
    });
    const thisPrevLink = this.createLink({
      type: this.type,
      title: `${this.title} ${prevYear}`,
      year: prevYear,
      tasks,
    });
    const nextYearLink = this.createLink({
      type: this.type,
      title: `${this.title} ${nextYear}`,
      year: nextYear,
      tasks,
    });

    if (today <= this.cutOffDate) {
      return [thisYearLink, thisPrevLink];
    }

    return [nextYearLink, thisYearLink];
  }
}
