import { createReducer, on } from '@ngrx/store';
import { ClientTypeType } from '../types';
import { ClientTypesActions } from './clientTypes.actions';

export interface ClientTypesState {
  types: ClientTypeType[];
  error: any;
}

export const initialClientTypesState: ClientTypesState = { types: [], error: undefined };

export const clientTypesReducer = createReducer(
  initialClientTypesState,
  on(ClientTypesActions.loadAllClientTypes, (state) => ({ ...state, error: undefined })),
  on(ClientTypesActions.loadClientTypesSucceeded, (state, { types }) => ({ ...state, types, error: undefined })),
  on(ClientTypesActions.loadClientTypesFailed, (state, { error }) => ({ ...state, error }))
);
