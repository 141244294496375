export const errors: Record<string, string> = {
  // Anti money laundering messages
  AML_COULD_NOT_BE_CREATED: 'PTL-kontroll kunde inte skapas',
  INVALID_AML_DATE: 'Datumet för PTL-kontrollen är ogiltigt',
  CLIENT_ID_DOES_NOT_EXIST: 'Angivet klient-id existerar inte',
  AML_NOT_FOUND: 'PTL-kontrollen kunde inte hittas',
  AML_ACCOUNT_NOT_FOUND: 'Inloggningsinformation saknas för att logga in på PTL tjänsten',
  CONNECTION_FAILED_TO_AML_SERVICE: 'Det gick inte att logga in på PTL tjänsten',
  RESPONSIBLE_MANAGER_DOES_NOT_EXIST: 'Klientanvarig finns inte upplagd i Due Compliance',
  RESPONSIBLE_REGISTRAR_DOES_NOT_EXIST: 'Du som användare finns inte upplagd i Due Compliance',
  AML_ID_IS_MISSING: 'PTL ID saknas',
  AML_UNAUTHORIZED:
    'Inloggning till PTL tjänsten misslyckades. Kontrollera att inloggningsuppgifterna är de som tillhör din API användare hos Due Compliance.',
  AML_ALREADY_ATTACHED: 'PTL-kontrollen är redan kopplad till en klient',
  AML_MISSING_CORPORATE_IDENTITY:
    'Koppling kan ej ske - Org.nr saknas på PTL-kontrollen. Logga in på Due Compliance och lägg till org.nr och försök igen.',
  CORPORATE_IDENTITY_NOT_MATCH:
    'Koppling kan ej ske - Org.nr stämmer ej överens mellan Klient och PTL-kontroll. Har du valt rätt klient?',
};
