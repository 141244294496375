<ng-container *ngIf="currentTeam$ | async as currentTeam">
  <p-dialog
    [header]="isRootTeam(currentTeam) ? rootTeamName : currentTeam.name"
    [modal]="true"
    [dismissableMask]="true"
    [visible]="visible$ | async"
    [style]="{ width: '900px' }"
    [draggable]="false"
    [closable]="false"
    showEffect="fade"
    (visibleChange)="onCancelClick()"
    (onHide)="onHideDialog()"
  >
    <app-tabs #tabs>
      <app-tab tabTitle="Översikt">
        <ng-container *ngTemplateOutlet="overview; context: { $implicit: currentTeam }"></ng-container>
      </app-tab>
      <app-tab tabTitle="Redigera medarbetare">
        <ng-container *ngTemplateOutlet="userList"></ng-container>
      </app-tab>
      <app-tab tabTitle="Redigera klienter" *ngIf="isClientBased(currentTeam)">
        <ng-container *ngTemplateOutlet="clientList"></ng-container>
      </app-tab>
      <app-tab tabTitle="Redigera tjänster" *ngIf="isTaskBased(currentTeam)">
        <ng-container *ngTemplateOutlet="taskList"></ng-container>
      </app-tab>
    </app-tabs>

    <p-footer class="flex justify-between">
      <button
        type="button"
        class="btn btn-danger"
        *ngIf="!currentTeam.readonly"
        (click)="onDeleteTeamClick(currentTeam)"
      >
        Ta bort teamet
      </button>

      <div class="w-full">
        <button type="button" class="btn btn-success" (click)="onConfirmClick()">Spara</button>
        <button type="button" class="btn btn-warning" (click)="onCancelClick()">Avbryt</button>
      </div>
    </p-footer>
  </p-dialog>

  <app-confirm-delete-team-dialog
    [(visible)]="showConfirmDeleteDialog"
    [team]="currentTeam"
    (confirm)="onConfirmDeleteCallback()"
  ></app-confirm-delete-team-dialog>
</ng-container>

<ng-template #overview let-team>
  <div [formGroup]="form" class="max-h-490px overflow-auto">
    <ng-container *ngIf="!isRootTeam(team)">
      <label class="block text-bl-grey" for="name">Namn</label>
      <input
        pInputText
        type="text"
        name="name"
        formControlName="name"
        class="w-full"
        placeholder="Fyll i namn på team"
      />
    </ng-container>

    <label class="block text-bl-grey mt-5">Medarbetare</label>
    <app-user-avatar formControlName="users" [maxVisible]="0"></app-user-avatar>

    <div *ngIf="isClientBased(team) || isRootTeam(team)">
      <app-team-badges [options]="clientsFormControlValue" optionLabel="name" label="Klienter"></app-team-badges>
    </div>
    <div *ngIf="isTaskBased(team)">
      <app-team-badges [options]="tasksFormControlValue" optionLabel="type" label="Tjänster"></app-team-badges>
    </div>
  </div>
</ng-template>

<ng-template #userList>
  <div [formGroup]="form">
    <app-team-user-list formControlName="users"></app-team-user-list>
  </div>
</ng-template>

<ng-template #clientList>
  <div [formGroup]="form">
    <app-team-client-list formControlName="clients"></app-team-client-list>
  </div>
</ng-template>

<ng-template #taskList>
  <div [formGroup]="form">
    <app-team-task-list formControlName="tasks"></app-team-task-list>
  </div>
</ng-template>
