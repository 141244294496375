import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { TeamActions } from '@app/core/state/team/team.actions';
import { TeamDialogType } from '@app/core/state/team/team.reducers';
import { TeamSelectors } from '@app/core/state/team/team.selectors';
import {
  ClientType,
  CreateTeamType,
  TaskType,
  TeamType,
  TeamTypes,
  UpdateTeamType,
  UserType,
} from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { TeamsActions } from '@app/core/state/teams/teams.actions';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { TaskService } from '@app/core/services/task.service';
import { combineLatest, Observable, of } from 'rxjs';

@Injectable()
export class TeamEditService {
  constructor(private store: Store<AppState>, private taskService: TaskService) {}

  getAddTeamDialogVisibility() {
    return this.store.select(TeamSelectors.selectDialogType).pipe(map((value) => value === TeamDialogType.Add));
  }

  getEditTeamDialogVisibility() {
    return this.store.select(TeamSelectors.selectDialogType).pipe(map((value) => value === TeamDialogType.Edit));
  }

  getSelectedTeamType() {
    return this.store.select(TeamSelectors.selectTeamType);
  }

  getSelectedTeam() {
    return this.store.select(TeamSelectors.selectTeam);
  }

  addTeam(team: CreateTeamType) {
    this.store.dispatch(TeamsActions.addTeam({ team }));
  }

  updateTeam(team: UpdateTeamType) {
    this.store.dispatch(TeamsActions.updateTeam({ team }));
  }

  deleteTeam(team: TeamType) {
    this.store.dispatch(TeamsActions.deleteTeam({ team }));
  }

  cancel() {
    this.store.dispatch(TeamActions.hideDialog());
  }

  isRootTeam(team: TeamType) {
    return team.typeId === TeamTypes.Root;
  }

  isClientBased(team: TeamType) {
    return team.typeId === TeamTypes.Client;
  }

  isTaskBased(team: TeamType) {
    return team.typeId === TeamTypes.Task;
  }

  combinedLatestTeamData(team: TeamType): Observable<[UserType[], ClientType[], TaskType[]]> {
    const users$ = this.mapTeamUserIdsToUserType(team.users.data);
    const clients$ =
      this.isClientBased(team) || this.isRootTeam(team)
        ? this.mapTeamClientIdsToClientType(team.clients.data)
        : of(null);
    const tasks$ = this.isTaskBased(team) ? this.mapTeamTaskIdsToTaskType(team.tasks.data) : of(null);

    return combineLatest([users$, clients$, tasks$]);
  }

  private mapTeamUserIdsToUserType(userIds: number[]) {
    return this.store
      .select(UserSelectors.activeUsersWithoutAll)
      .pipe(map((users) => users.filter((user) => userIds.includes(user.id))));
  }

  private mapTeamClientIdsToClientType(clientIds: number[]) {
    return this.store
      .select(ClientSelectors.allClients)
      .pipe(map((clients) => clients.filter((client) => clientIds.includes(client.id))));
  }

  private mapTeamTaskIdsToTaskType(taskIds: number[]) {
    return this.taskService
      .getActiveTaskTypeTemplates()
      .pipe(map((tasks) => tasks.filter((task) => taskIds.includes(task.id))));
  }
}
