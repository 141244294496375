import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ActivityStateType } from '../types';

export const ActivityActions = createActionGroup({
  source: 'Activity',
  events: {
    'Load All Activity States': emptyProps(),
    'Load All Activity States Succeeded': props<{ states: ActivityStateType[] }>(),
    'All Activity States Failed': props<{ error: any }>(),
    'Save Single Activity': props<{ taskId: number; date: string; comment: string }>(),
  },
});
