import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  IncomeTaxDeclarationCompositeType,
  CompositeListFilter,
  IncomeTaxDeclarationListParams,
  CompositeType,
} from '../core/state/types';
import { CompositeListComponent } from '@app/shared/components/composite-list/composite-list.component';
import { getAgentUrl } from '@app/core/state/shared/shared';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';
import { TodayDate } from '@app/shared/misc/today.date';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { ALL } from '@app/shared/misc/constants';
import { IncomeTaxDeclarationComposite } from '@app/core/entity/incometaxdeclaration.composite';

@Component({
  selector: 'app-ncometaxdeclaration-list',
  templateUrl: './incometaxdeclaration.list.component.html',
})
export class IncomeTaxDeclarationListComponent
  extends CompositeListComponent<IncomeTaxDeclarationCompositeType, CompositeListFilter, IncomeTaxDeclarationListParams>
  implements OnInit, OnDestroy
{
  selectedYear: string;
  selectedClientResponsibleUserId: number;
  selectedTaskUserId: number;
  selectedPeriods: string[];
  includeAllDoneClients: boolean;
  columns: TableColumn[] = [
    { field: 'client', header: 'Skattskyldig', width: '13%' },
    { field: 'corporateIdentity', header: 'Orgnr' },
    { field: 'customerNumber', header: 'Kundnr' },
    { field: 'responsible', header: 'Klientansv' },
    { field: 'declarationPeriodCode', header: 'Dekl.period' },
    { field: 'firmTaxRespite', header: 'Byråanstånd' },
    { field: 'electronicTax', header: 'Lämnas elektr.' },
    { field: 'deadline', header: 'Deadline' },
    {
      field: 'declarationMaterial.materialSortValue',
      header: 'Material (Ink.dekl)',
    },
    {
      field: 'uppi.activity.alertLevel.description',
      header: 'Upprätta inkomstdekl.',
      width: '13%',
      fieldValue: (_fieldValue, rowData) => rowData?.uppi?.activity?.state?.description,
      additionalFilterFields: ['uppi.activity.state.description'],
    },
    {
      field: 'uppb.activity.alertLevel.description',
      header: 'Upprätta bil. för deläg.',
      fieldValue: (_fieldValue, rowData) => rowData?.uppb?.activity?.state?.description,
      additionalFilterFields: ['uppb.activity.state.description'],
    },
    { field: 'mainSpan.end', header: 'Boksl.dat', width: '6%', visible: false },
    { field: 'taskUsers', header: 'UH', width: '5%', visible: false },
    { field: 'program', header: 'Program', width: '70px', sortable: false, exportable: false },
  ];

  loadUserSettings(): void {
    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.INCOME_TAX_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.selectedYear = this.uss.loadSetting(
      UserSettingsStorage.INCOME_TAX_YEAR,
      new TodayDate().getRelevantYearAsString(8),
    );
    this.selectedClientResponsibleUserId = this.uss.loadSettingAsNumber(
      UserSettingsStorage.INCOME_TAX_CLIENT_RESPONSIBLE_ID,
      0,
    );
    this.selectedTaskUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.INCOME_TAX_USER_ID, 0);
    this.includeAllDoneClients = this.uss.loadSettingAsBoolean(
      UserSettingsStorage.INCOME_TAX_INCLUDE_ALL_DONE_CLIENTS,
      true,
    );
    this.handleSelectedPeriodsUserSettings();
  }

  saveUserSettings(): void {
    this.uss.saveSetting(UserSettingsStorage.INCOME_TAX_YEAR, this.selectedYear);
    this.uss.saveSetting(
      UserSettingsStorage.INCOME_TAX_CLIENT_RESPONSIBLE_ID,
      `${this.selectedClientResponsibleUserId}`,
    );
    this.uss.saveSetting(UserSettingsStorage.INCOME_TAX_USER_ID, `${this.selectedTaskUserId}`);
    this.uss.saveSetting(UserSettingsStorage.INCOME_TAX_INCLUDE_ALL_DONE_CLIENTS, `${this.includeAllDoneClients}`);
    this.storeSelectedPeriodsInUserSettings();
  }

  getFilterValue() {
    return { includeAllDoneClients: this.includeAllDoneClients };
  }

  getParamsValue() {
    return {
      responsibleId: this.selectedClientResponsibleUserId,
      executorId: this.selectedTaskUserId,
      year: this.selectedYear,
      periods: this.selectedPeriods,
    };
  }

  getPaginatorTriggeredKey() {
    return UserSettingsStorage.INCOME_TAX_NUMBER_OF_ROWS;
  }

  getCompositeType() {
    return CompositeType.incomeTaxDeclaration;
  }

  private handleSelectedPeriodsUserSettings() {
    const newExists = this.uss.keyExists(UserSettingsStorage.INCOME_TAX_PERIODS);
    if (newExists) {
      this.selectedPeriods = JSON.parse(this.uss.loadSetting(UserSettingsStorage.INCOME_TAX_PERIODS, '[]'));
      this.removeOldSelectedPeriodsFromUserSettings();
      return;
    }
    const oldValue = this.uss.loadSetting(
      UserSettingsStorage.INCOME_TAX_PERIOD,
      this.getTaxPeriod(new TodayDate().getRelevantMonthAsNumber(245) + 1),
    );
    this.selectedPeriods = oldValue === ALL ? [] : [oldValue];
    this.storeSelectedPeriodsInUserSettings();
    this.removeOldSelectedPeriodsFromUserSettings();
  }

  private removeOldSelectedPeriodsFromUserSettings() {
    this.uss.removeSetting(UserSettingsStorage.INCOME_TAX_PERIOD);
  }

  private getTaxPeriod(month: number): string {
    if (month >= 1 && month <= 4) {
      return 'P1';
    }

    if (month >= 5 && month <= 6) {
      return 'P2';
    }

    if (month >= 7 && month <= 8) {
      return 'P3';
    }

    if (month >= 9 && month <= 12) {
      return 'P4';
    }

    throw new Error('Month should be between 1 and 12');
  }

  getAgentUrl(composite: IncomeTaxDeclarationCompositeType) {
    const path = getAgentUrl(composite);
    return this.domSanitizer.bypassSecurityTrustUrl(path);
  }

  showMtrlTask(composite: IncomeTaxDeclarationComposite) {
    if (!composite.uppi.activity.taskId) {
      return;
    }

    this.onShowActivity(composite.uppi);
  }

  private storeSelectedPeriodsInUserSettings() {
    this.uss.saveSetting(UserSettingsStorage.INCOME_TAX_PERIODS, JSON.stringify(this.selectedPeriods));
  }
}
