<p-dialog
  header="Hämta molndatabasnyckel"
  [(visible)]="visible"
  showEffect="fade"
  [style]="{ width: '900px' }"
  (onHide)="closeDialog()"
  [draggable]="false"
>
  <p>Här visas en lista på de molnföretag som matchar organisationsnumret i Byråstöd.</p>
  <p class="mb-6">Klicka på "Lägg till" på det företag som du vill koppla ihop med.</p>

  <ng-container *ngIf="cloudCompanies$ | async as ccc">
    <p-table [columns]="cloudCompanyColumns" [value]="ccc.data" [tableStyle]="{'table-layout':'fixed'}">
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length">
            <div class="my-3 whitespace-normal" *ngIf="!ccc.loading; else loadingCloudCompany">
              Inget företag med detta org.nr kan hittas i Företagslistan i molnet. <br />
              Kontrollera i BL Administration under Företagsuppgifter-Generella att Org.nr är korrekt inlagt.
            </div>
            <ng-template #loadingCloudCompany>Listan uppdateras, vänligen vänta...</ng-template>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="font-normal text-center" *ngFor="let col of columns" [style.width]="col.width || 'auto'">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td>{{ rowData.orgNumber }}</td>
          <td>{{ rowData.name }}</td>
          <td>{{ rowData.publicKey }}</td>
          <td class="text-center">
            <button class="btn btn-success" (click)="connectCloudCompany(rowData)">Lägg till</button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>

  <p-footer class="mt-6">
    <button class="btn btn-warning" (click)="closeDialog()">Stäng</button>
  </p-footer>
</p-dialog>
