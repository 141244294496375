import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap } from 'rxjs/operators';
import { ActivityActions } from '../activities/activities.actions';
import { AssignmentActions } from '../assignments/assignments.actions';
import { AuthActions } from '../auth/auth.actions';
import { ClientActions } from '../clients/clients.actions';
import { FileVaultActions } from '../filevault/filevault.actions';
import { ClientTypesActions } from '../clientTypes/clientTypes.actions';
import { FirmActions } from '../firm/firm.actions';
import { UserActions } from '../users/users.actions';
import { CoreActions } from './core.actions';
import { TaskGroupsActions } from '../task-groups/task-groups.actions';
import { TeamsActions } from '../teams/teams.actions';

@Injectable()
export class CoreEffects {
  initCoreEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccessful, AuthActions.reloadCurrentAuthUser),
      switchMap(() => [
        TeamsActions.loadAllTeams(),
        UserActions.loadAllUsers(),
        AssignmentActions.loadAllAssignments(),
        ClientActions.loadAllClients(),
        FirmActions.loadFirm(),
        ActivityActions.loadAllActivityStates(),
        FileVaultActions.loadAllFileNames(),
        ClientTypesActions.loadAllClientTypes(),
        TaskGroupsActions.load(),
      ]),
    ),
  );

  navigateStart$ = createEffect(() =>
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      map(({ url }: NavigationStart) => CoreActions.navigationStart({ url })),
    ),
  );

  constructor(private actions$: Actions, private router: Router) {}
}
