import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

import { Period } from '../entity/period';
import { Rule } from '../entity/rule';
import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { RuleType } from '../state/types';
import { AppState } from '../state/appState';
import { toRuleTypeTransformer } from '../state/transformers/transformers';

@Injectable()
export class PeriodService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'period';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  getPeriods(): Observable<Period[]> {
    return this.http
      .get<Period[]>(this.url, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getAvailableRules(period: Period): Observable<Rule[]> {
    return this.http
      .get<Rule[]>(this.url + '/template/' + period.name, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getAvailableRuleTypes(period: Period): Observable<RuleType[]> {
    return this.getAvailableRules(period).pipe(map((rules) => rules.map(toRuleTypeTransformer.transform)));
  }
}
