import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  TaxDeclarationCompositeType,
  CompositeListFilter,
  TaxDeclarationListParams,
  CompositeType,
} from '@app/core/state/types';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';
import { CompositeListComponent } from '@app/shared/components/composite-list/composite-list.component';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { TodayDate } from '@app/shared/misc/today.date';

@Component({
  selector: 'app-taxdeclaration-list',
  templateUrl: './taxdeclaration.list.component.html',
})
export class TaxDeclarationListComponent
  extends CompositeListComponent<TaxDeclarationCompositeType, CompositeListFilter, TaxDeclarationListParams>
  implements OnInit, OnDestroy
{
  selectedMonth: string;
  selectedYear: string;
  selectedClientResponsibleUserId: number;
  selectedTaskUserId: number;
  includeAllDoneClients: boolean;
  columns: TableColumn[] = [
    { field: 'client', header: 'Klient', width: '10%' },
    { field: 'corporateIdentity', header: 'Orgnr', width: '7%' },
    { field: 'customerNumber', header: 'Kundnr', width: '5%' },
    { field: 'responsible', header: 'Klientansv', width: '5%' },
    { field: 'mainSpan.spanType', header: 'Intervall', width: '5%' },
    { field: 'mainSpan.spanLabel', header: 'Momsdekl avs period', width: '9%' },
    {
      field: 'momu.activity.alertLevel.description',
      header: 'Underlag till klient',
      width: '8%',
      fieldValue: (_fieldValue, rowData) => rowData?.momu?.activity?.state?.description,
      additionalFilterFields: ['momu.activity.state.description'],
    },
    {
      field: 'momd.activity.alertLevel.description',
      header: 'Momsdekl',
      width: '8%',
      fieldValue: (_fieldValue, rowData) => rowData?.momd?.activity?.state?.description,
      additionalFilterFields: ['momd.activity.state.description'],
    },
    {
      field: 'betg.activity.alertLevel.description',
      header: 'Momsbet',
      width: '8%',
      fieldValue: (_fieldValue, rowData) => rowData?.betg?.activity?.state?.description,
      additionalFilterFields: ['betg.activity.state.description'],
    },
    {
      field: 'lonn.activity.alertLevel.description',
      header: 'Underlag till klient',
      width: '8%',
      fieldValue: (_fieldValue, rowData) => rowData?.lonn?.activity?.state?.description,
      additionalFilterFields: ['lonn.activity.state.description'],
    },
    {
      field: 'arbe.activity.alertLevel.description',
      header: 'Arbgivdekl',
      width: '8%',
      fieldValue: (_fieldValue, rowData) => rowData?.arbe?.activity?.state?.description,
      additionalFilterFields: ['arbe.activity.state.description'],
    },
    {
      field: 'beta.activity.alertLevel.description',
      header: 'Bet skatt och arbgivavg',
      width: '8%',
      fieldValue: (_fieldValue, rowData) => rowData?.beta?.activity?.state?.description,
      additionalFilterFields: ['beta.activity.state.description'],
    },
    {
      field: 'pers.activity.alertLevel.description',
      header: 'Periodisk sammanst',
      width: '8%',
      fieldValue: (_fieldValue, rowData) => rowData?.pers?.activity?.state?.description,
      additionalFilterFields: ['pers.activity.state.description'],
    },
    { field: 'taskUsers', header: 'UH', width: '5%', visible: false },
    { field: 'program', header: 'Program', width: '60px', sortable: false, exportable: false },
  ];

  loadUserSettings(): void {
    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.TAX_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.selectedMonth = this.uss.loadSetting(UserSettingsStorage.TAX_MONTH, new TodayDate().getCurrentMonthAsString());
    this.selectedYear = this.uss.loadSetting(UserSettingsStorage.TAX_YEAR, new TodayDate().getCurrentYearAsString());
    this.selectedClientResponsibleUserId = this.uss.loadSettingAsNumber(
      UserSettingsStorage.TAX_CLIENT_RESPONSIBLE_ID,
      0,
    );
    this.selectedTaskUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.TAX_USER_ID, 0);
    this.includeAllDoneClients = this.uss.loadSettingAsBoolean(UserSettingsStorage.TAX_INCLUDE_ALL_DONE_CLIENTS, true);
  }

  saveUserSettings(): void {
    this.uss.saveSetting(UserSettingsStorage.TAX_MONTH, this.selectedMonth);
    this.uss.saveSetting(UserSettingsStorage.TAX_YEAR, this.selectedYear);
    this.uss.saveSetting(UserSettingsStorage.TAX_CLIENT_RESPONSIBLE_ID, `${this.selectedClientResponsibleUserId}`);
    this.uss.saveSetting(UserSettingsStorage.TAX_USER_ID, `${this.selectedTaskUserId}`);
    this.uss.saveSetting(UserSettingsStorage.TAX_INCLUDE_ALL_DONE_CLIENTS, `${this.includeAllDoneClients}`);
  }

  getFilterValue() {
    return { includeAllDoneClients: this.includeAllDoneClients };
  }

  getParamsValue(): TaxDeclarationListParams {
    return {
      responsibleId: this.selectedClientResponsibleUserId,
      executorId: this.selectedTaskUserId,
      year: this.selectedYear,
      periodIndex: new TodayDate().getIndexOfMonth(this.selectedMonth),
    };
  }

  getPaginatorTriggeredKey(): string {
    return UserSettingsStorage.TAX_NUMBER_OF_ROWS;
  }

  getCompositeType() {
    return CompositeType.taxDeclaration;
  }
}
