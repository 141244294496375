import { Action, createActionGroup, props } from '@ngrx/store';
import { UpdateActivityCallback } from '../shared/shared';
import { ActivityType, CompositeType, ListFilter, ListParams } from '../types';

export type LoadCompositesActionType = Action & LoadCompositesPayload;

export interface LoadCompositesPayload {
  compositeType: CompositeType;
  filter: ListFilter;
  params: ListParams;
}

export interface UpdateActivityPayload {
  compositeType: CompositeType;
  activity: ActivityType;
  callback: UpdateActivityCallback<any>;
}

export const CompositeActions = createActionGroup({
  source: 'Composite',
  events: {
    'Load Composites': props<LoadCompositesPayload>(),
    'Update Activity': props<UpdateActivityPayload>(),
  },
});
