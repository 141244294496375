import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Jwt } from 'src/app/core/misc/jwt';
import { AuthActions } from '../state/auth/auth.actions';
import { AppState } from '../state/appState';
import { UserSettingsStorage } from '../storage/user.settings.storage';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  uss = new UserSettingsStorage();

  constructor(private router: Router, private store: Store<AppState>) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const jwt = this.uss.loadSetting('jwt');

    if (!jwt) {
      // missing token, go to login without error
      return this.router.parseUrl('/login');
    }

    if (!Jwt.isValidToken(jwt, true)) {
      this.uss.removeSetting('jwt');
      return this.router.parseUrl('/login?error=INVALID_TOKEN');
    }

    // trigger loading of current user from backend if token is valid and not loaded before
    this.store.dispatch(AuthActions.reloadCurrentAuthUserIfNotLoaded());

    return true;
  }

  canActivateChild(
    _childRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const jwt = this.uss.loadSetting('jwt');
    return !!jwt || this.router.parseUrl('/login?error=MISSING_TOKEN');
  }
}
