import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from 'src/app/core/entity/client';
import { CloudCompanyService } from 'src/app/core/services/cloud-company.service';
import { BlaCompany } from 'src/app/core/entity/bla-company';
import { ClientBlaCompanyComposite } from 'src/app/core/entity/client-blacompany-composite';

export abstract class BaseCloudImportExportComponent {
  bystClients: Client[];

  LIST_MESSAGES = {
    UPDATING: 'Listan uppdateras. Det kan ta några minuter så vänligen vänta...',
    EMPTY_CLIENTS: 'Inga klienter hittades för export till Företag i molnet',
    EMPTY_CLOUD_COMPANIES: 'Inga molnföretag hittades för import till Byråstöd',
  };

  get hideAddButton() {
    return false;
  }

  constructor(protected cloudCompanyService: CloudCompanyService) {}

  abstract loadList(): void;
  abstract execute(): void;
  protected abstract filterList(
    clients: Client[],
    blaCompanies: BlaCompany[]
  ): Client[] | BlaCompany[] | ClientBlaCompanyComposite[];

  init(clients: Client[]): BaseCloudImportExportComponent {
    this.bystClients = [...clients]; //clients.filter((client) => client.type.name !== 'PERSON' && !client.archived);
    return this;
  }

  protected getListData(
    clientFilterFn = (_client: Client) => true
  ): Observable<Client[] | BlaCompany[] | ClientBlaCompanyComposite[]> {
    return combineLatest([of(this.bystClients.filter(clientFilterFn)), this.getCloudCompaniesObservable()]).pipe(
      map(([clients, companies]: [Client[], BlaCompany[]]) => this.filterList(clients, companies))
    );
  }

  protected appendClients(clients: Client[]) {
    if (!Array.isArray(clients)) {
      return;
    }

    const newClients = clients.filter((client) => {
      return !this.bystClients.find((bystClient) => bystClient.corporateIdentity === client.corporateIdentity);
    });

    this.bystClients = [...this.bystClients, ...newClients];
  }

  protected updateClients(updatedClients: Client[]) {
    if (!Array.isArray(updatedClients)) {
      return;
    }

    const notUpdatedClients = this.bystClients.filter((client) => {
      return !updatedClients.find((c) => c.corporateIdentity === client.corporateIdentity);
    });

    this.bystClients = [...notUpdatedClients, ...updatedClients];
  }

  private getCloudCompaniesObservable(): Observable<BlaCompany[]> {
    return this.cloudCompanyService.getCloudCompanies();
  }
}
