import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[tableTemplate]',
})
export class TableTemplateDirective {
  @Input('tableTemplate') name: string;
  @Input('tableBodyCellField') bodyCellField: string;

  constructor(public readonly templateRef: TemplateRef<any>) {}
}
