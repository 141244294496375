import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { TeamType } from '@app/core/state/types';
import { DialogModule } from 'primeng/dialog';
import { Observable } from 'rxjs';
import { filter, tap, mergeMap, map } from 'rxjs/operators';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { FormControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserAvatarComponent } from '@app/shared/components/user-avatar/user-avatar.component';
import { TabsComponent } from '@app/shared/components/tabs/tabs.component';
import { ROOT_TEAM_NAME } from '@app/shared/misc/constants';
import { TeamClientListComponent } from '../team-client-list/team-client-list.component';
import { TeamTaskListComponent } from '../team-task-list/team-task-list.component';
import { TeamUserListComponent } from '../team-user-list/team-user-list.component';
import { ConfirmDeleteTeamDialog } from './confirm-delete-team-dialog.component';
import { TeamEditService } from '../../services/team-edit.service';

@Component({
  selector: 'app-edit-team-dialog',
  templateUrl: './edit-team-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [TeamEditService],
  styles: [
    `
      .max-h-490px {
        max-height: 490px;
      }
    `,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DialogModule,
    BystSharedModule,
    UserAvatarComponent,
    TeamUserListComponent,
    TeamClientListComponent,
    TeamTaskListComponent,
    ConfirmDeleteTeamDialog,
  ],
})
export class EditTeamDialogComponent {
  @ViewChild('tabs', { read: TabsComponent }) tabs: TabsComponent;

  visible$: Observable<boolean>;
  currentTeam$: Observable<TeamType>;
  form: UntypedFormGroup;
  showConfirmDeleteDialog = false;
  onConfirmDeleteCallback = () => {};

  get clientsFormControlValue() {
    return this.form?.get('clients')?.value;
  }

  get tasksFormControlValue() {
    return this.form?.get('tasks')?.value;
  }

  get rootTeamName(): string {
    return ROOT_TEAM_NAME;
  }

  constructor(private teamService: TeamEditService) {
    this.visible$ = this.teamService.getEditTeamDialogVisibility();
    this.currentTeam$ = this.teamService.getSelectedTeam().pipe(
      filter((team) => !!team),
      tap((team) => {
        this.form = this.createForm(team);
      }),
      mergeMap((team) => this.patchFormValues(team)),
    );
  }

  isRootTeam(team: TeamType) {
    return this.teamService.isRootTeam(team);
  }

  isClientBased(team: TeamType) {
    return this.teamService.isClientBased(team);
  }

  isTaskBased(team: TeamType) {
    return this.teamService.isTaskBased(team);
  }

  onHideDialog() {
    this.form.reset();
    this.tabs.selectFirst();
  }

  onConfirmClick() {
    const changedValues = this.findFormChanges(this.form);

    // nothing changed
    if (!changedValues) {
      this.onCancelClick();
      return;
    }
    this.teamService.updateTeam(changedValues);
  }

  onCancelClick() {
    this.teamService.cancel();
  }

  onDeleteTeamClick(team: TeamType) {
    this.showConfirmDeleteDialog = true;
    this.onConfirmDeleteCallback = () => {
      this.teamService.deleteTeam(team);
    };
  }

  private createForm(team: TeamType) {
    const clientsValidator = this.isClientBased(team) ? Validators.required : null;
    const tasksValidator = this.isTaskBased(team) ? Validators.required : null;

    return new UntypedFormGroup({
      id: new FormControl<number>(team.id, Validators.required),
      typeId: new FormControl<number>(team.typeId, Validators.required),
      name: new FormControl<string>(team.name, Validators.required),
      users: new UntypedFormControl(null, Validators.required),
      clients: new UntypedFormControl(null, clientsValidator),
      tasks: new UntypedFormControl(null, tasksValidator),
    });
  }

  private patchFormValues(team: TeamType): Observable<TeamType> {
    return this.teamService.combinedLatestTeamData(team).pipe(
      map(([users, clients, tasks]) => {
        this.form.patchValue({ users, clients, tasks });
      }),
      map(() => team),
    );
  }

  private findFormChanges(formGroup: UntypedFormGroup) {
    const mandatoryProps = ['id', 'typeId'];

    const changes = Object.entries(formGroup.controls)
      .filter(([prop, control]) => control.dirty || mandatoryProps.includes(prop))
      .reduce((acc: any, [prop, control]: [string, any]) => {
        if (Array.isArray(control.value)) {
          acc[prop] = control.value.map((item: any) => item.id);
          return acc;
        }
        acc[prop] = control.value;
        return acc;
      }, {});

    const onlyMandatoryFieldsInChanges = Object.keys(changes).every((key) => mandatoryProps.includes(key));

    if (onlyMandatoryFieldsInChanges) {
      return null;
    }
    return changes;
  }
}
