import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { Task } from '../entity/task';
import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { TaskType } from '../state/types';
import { toTaskTypeTransformer } from '../state/transformers/transformers';
import { AppState } from '../state/appState';

@Injectable({ providedIn: 'root' })
export class TaskService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'task';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  getAllTasks(): Observable<Task[]> {
    return this.http
      .get<Task[]>(this.url, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getAllTaskTypes(): Observable<TaskType[]> {
    return this.getAllTasks().pipe(map((tasks) => tasks.map(toTaskTypeTransformer.transform)));
  }

  getTask(id: number): Observable<Task> {
    return this.http
      .get<Task>(`${this.url}/${id}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getAgentUrl(id: number, date: string): Observable<{ agentCall: string }> {
    return this.http
      .get<{ agentCall: string }>(
        `${this.url}/agentCall/${id}/${date}`,
        this.options.getAuthorizationHeaderWithEmptyBody(),
      )
      .pipe(this.catchErrorAndShowMessage());
  }

  getTasksTemplates(taskGroupId = 0): Observable<Task[]> {
    const url = `${this.url}/templates/${taskGroupId}`;
    return this.http
      .get<Task[]>(url, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getTaskTemplateTypes(taskGroupId = 0): Observable<TaskType[]> {
    return this.getTasksTemplates(taskGroupId).pipe(map((tasks) => tasks.map(toTaskTypeTransformer.transform)));
  }

  getActiveTaskTypeTemplates(): Observable<TaskType[]> {
    return this.getTaskTemplateTypes().pipe(map((templates) => templates.filter((t) => !t.archived)));
  }

  getTasksByClientId(clientId: number): Observable<Task[]> {
    return this.http
      .get<Task[]>(`${this.url}/byClient/${clientId}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getTaskTypesByClientId(clientId: number): Observable<TaskType[]> {
    return this.getTasksByClientId(clientId).pipe(map((tasks) => tasks.map((t) => toTaskTypeTransformer.transform(t))));
  }

  getAllTasksByCode(taskCode: string): Observable<Task[]> {
    return this.http
      .get<Task[]>(`${this.url}/byCode/${taskCode}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  saveTaskTemplate(task: TaskType): Observable<TaskType> {
    const body = JSON.stringify(task);

    return this.http
      .put<TaskType>(`${this.url}/template`, body, this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }

  saveTask(task: Task): Observable<Task[]> {
    const body = JSON.stringify(task);
    return this.http
      .put<Task>(`${this.url}/${task.client.id}`, body, this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }

  saveTasks(tasks: Task[]): Observable<Task[]> {
    const body = JSON.stringify(tasks);
    return this.http
      .put<Task>(`${this.url}/${tasks[0].client.id}`, body, this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }

  deleteTask(id: number): Observable<Task[]> {
    return this.http
      .delete<Task[]>(`${this.url}/${id}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getAllDeletableTasks(): Observable<Task[]> {
    const url = `${this.url}/deletable`;
    return this.http
      .get<Task[]>(url, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }
}
