import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, throttleTime } from 'rxjs/operators';

export enum LoginStatus {
  Ok = 'Ok',
  UnAuthorized = 'UNAUTHORIZED',
  UnAuthorizedInactive = 'UNAUTHORIZED-INACTIVE',
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private throttleLogout = new Subject<LoginStatus>();

  constructor(private router: Router) {
    this.setupThrottledLogout();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401) {
          // Call logout, but throttled!
          if (response.error?.message?.toLowerCase() === 'inactive user') {
            this.throttleLogout.next(LoginStatus.UnAuthorizedInactive);
          } else {
            this.throttleLogout.next(LoginStatus.UnAuthorized);
          }
        }
        return throwError(response);
      })
    );
  }

  private setupThrottledLogout() {
    this.throttleLogout.pipe(throttleTime(5000)).subscribe((param) => {
      this.logout(param);
    });
  }

  private logout(error: string) {
    this.router.navigate(['login'], { queryParams: { error } });
  }
}
