import { Injectable } from '@angular/core';
import * as BlAccountAuth from '@blinfo/authjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BlAccountAuthResponse } from '../entity/bl-account-auth-response';

@Injectable({ providedIn: 'root' })
export class BlAccountAuthService {
  // eslint-disable-next-line no-undefined
  private authStateBS: BehaviorSubject<string | null | undefined> = new BehaviorSubject(undefined);

  get authState(): Observable<string | null | undefined> {
    return this.authStateBS.asObservable();
  }

  constructor() {
    const { clientId, redirectURI, scope, env = '' } = environment.blauth;
    const scopes = scope.split(' ');

    BlAccountAuth.init({ clientId, redirectURI, scopes, env });
    BlAccountAuth.getLoginStatus((response: BlAccountAuthResponse) => this.updateAuthState(response));
  }

  login() {
    BlAccountAuth.login((response: BlAccountAuthResponse) => this.updateAuthState(response));
  }

  logout() {
    BlAccountAuth.logout(() => this.updateAuthState(null));
  }

  private updateAuthState(state: BlAccountAuthResponse) {
    const token = state?.authToken ? state.authToken : null;
    this.authStateBS.next(token);
  }
}
