import { Component, Input } from '@angular/core';
import { BulkType } from '../interfaces/bulk-change-action-type.enum';

@Component({
  selector: 'app-bulk-main',
  templateUrl: './bulk-main.component.html',
})
export class BulkMainComponent {
  @Input() tabActive: boolean = false;

  public bulkChangeTabActive: boolean = false;
  public bulkAddTabActive: boolean = false;
  public bulkRemoveTabActive: boolean = false;

  public BulkTypeChange = BulkType.Change;
  public BulkTypeAdd = BulkType.Add;
  public BulkTypeRemove = BulkType.Remove;
}
