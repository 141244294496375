import { Activity } from '@app/core/entity/activity';
import { ActivityService } from '@app/core/services/activity.service';
import { DialogService } from 'primeng/dynamicdialog';
import { first, mergeMap } from 'rxjs';
import { ActivityDetailsDialog } from '../components/activity-details-dialog/activity.details.dialog';
import { openDialog } from './openDialog';

export const showActivityDetailsDialog = (
  activityService: ActivityService,
  dialogService: DialogService,
  activity: Activity
) =>
  activityService.getActivityComposite(activity).pipe(
    first(),
    mergeMap((activityComposite) => openDialog(dialogService, ActivityDetailsDialog, { activityComposite }))
  );
