export function fileNameNormalizer(name: string): string {
  if (!name) {
    return '';
  }

  const replacements = [
    { find: '#', replace: '' },
    { find: '€', replace: '' },
    { find: '$', replace: '' },
    { find: '%', replace: '' },
    { find: '&', replace: '' },
    { find: '?', replace: '' },
    { find: '=', replace: '' },
    { find: '{', replace: '' },
    { find: '}', replace: '' },
    { find: '[', replace: '' },
    { find: ']', replace: '' },
  ];

  let newName = name;

  for (const { find, replace } of replacements) {
    newName = replaceAll(newName, find, replace);
  }

  return newName;
}

function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
