import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { TodayDate } from 'src/app/shared/misc/today.date';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { MAX_CALENDAR_DATE, MIN_CALENDAR_DATE } from '../../misc/dates';

@Component({
  selector: 'app-calendar',
  template: `
    <p-calendar
      [ngModel]="primeNGDate"
      (ngModelChange)="primeNGDate = $event"
      [showIcon]="true"
      dateFormat="yy-mm-dd"
      [dataType]="dataType"
      [minDate]="minSelectableDate"
      [maxDate]="maxSelectableDate"
      [style]="style"
      [disabled]="disabled"
      [firstDayOfWeek]="1"
      styleClass="{{ styleClass }}"
      appendTo="{{ appendTo }}"
      inputId="{{ inputId }}"
      title="{{ title }}"
      (onFocus)="onDateTextfieldFocus()"
      (onBlur)="onDateTextfieldBlur()"
      (onClose)="onDateSelectorClose()"
      (onInput)="onInput($event)"
      [class]="invalid ? 'ng-invalid ng-dirty' : ''"></p-calendar>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CalendarComponent,
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, CalendarModule],
})
export class CalendarComponent implements ControlValueAccessor {
  private _primeNGDate: any; //Date | string;
  private _dataType: 'string' | 'date' = 'string';
  private _touched = false;
  private _hasTextfieldFocus = false;
  private _isDateSelectorOpen = false;
  private _validDateStringRegex = new RegExp(/^[0-9]{8}$/);
  private _disabled = false;

  @Input() minSelectableDate = MIN_CALENDAR_DATE;
  @Input() maxSelectableDate = MAX_CALENDAR_DATE;
  @Input() defaultDate: Date | string = 'today';
  @Input() styleClass: string;
  @Input() style: any;
  @Input() appendTo: string;
  @Input() inputId: string;
  @Input() title: string;
  @Input() invalid = false;
  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  @Input()
  get dataType() {
    return this._dataType;
  }

  set dataType(value: 'date' | 'string') {
    this._dataType = value;
  }

  @Input()
  get selectedDate() {
    return this._primeNGDate;
  }

  set selectedDate(value: any) {
    this._primeNGDate = value;
  }

  get primeNGDate() {
    return this._primeNGDate;
  }

  set primeNGDate(value: any) {
    this._primeNGDate = value;
    this.onChange(value);
    this.selectedDateChange.emit(value);
  }

  @Output() selectedDateChange: EventEmitter<any> = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  onChange: ControlValueAccessorFn = () => {};

  onTouched: ControlValueAccessorFn = () => {};

  writeValue(value: Date | string) {
    this.primeNGDate = value;
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(onChange: ControlValueAccessorFn) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: ControlValueAccessorFn) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this._touched) {
      this.onTouched();
      this._touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    this.changeDetectorRef.markForCheck();
  }

  onInput(e: { target?: { value: string } }) {
    const inputValue = e?.target?.value;

    if (this.isValidDateString(inputValue)) {
      this.primeNGDate = this.parseNumberStringToDate(inputValue);
    }
  }

  onDateTextfieldFocus() {
    this._hasTextfieldFocus = true;
    this._isDateSelectorOpen = true;
  }

  onDateTextfieldBlur() {
    this._hasTextfieldFocus = false;
    this.updateDateToDefaultValue();
  }

  onDateSelectorClose() {
    this._hasTextfieldFocus = false;
    this._isDateSelectorOpen = false;
    this.updateDateToDefaultValue();
  }

  private isValidDateString(value: string) {
    return this._validDateStringRegex.test(value);
  }

  private parseNumberStringToDate(value: string): null | string {
    const dateArray = [];
    // split the string in year, month, day
    dateArray.push(value.slice(0, 4));
    dateArray.push(value.slice(4, 6));
    dateArray.push(value.slice(6));
    let date = dateArray.join('-');
    // parse and "stringify" the date
    date = new Date(Date.parse(date)).toJSON();
    return date && date.slice(0, 10); // null or datepart of the string
  }

  private updateDateToDefaultValue() {
    if (this._hasTextfieldFocus || this._isDateSelectorOpen) {
      return; // if field still has focus or date selector is open, we have to skip this updating
    }

    if (!this.primeNGDate && this.defaultDate) {
      this.primeNGDate = this.getDefaultDate();
    }
  }

  public getDefaultDate() {
    if (this.defaultDate !== 'today') {
      return this.defaultDate;
    }

    if (this.dataType === 'string') {
      return new TodayDate().getTodayAsString();
    }
    return new Date();
  }
}
