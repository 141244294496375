import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { ClientType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

type TeamClientTableFilter = {
  showOnlySelectedRows: boolean;
  includeArchivedClients: boolean;
};

interface ClientTypeWithArchivedName extends ClientType {
  archivedName: string;
}

@Component({
  selector: 'app-team-client-list',
  templateUrl: './team-client-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BystSharedModule],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TeamClientListComponent), multi: true }],
})
export class TeamClientListComponent implements ControlValueAccessor {
  clients$: Observable<ClientTypeWithArchivedName[]>;
  columns: TableColumn[] = [
    { field: 'archivedName', header: 'Namn', width: '200px' },
    { field: 'type.description', header: 'Företagstyp', width: '100px' },
    { field: 'responsible.name', header: 'Klientansvarig', width: '100px' },
  ];

  selectedClients: ClientTypeWithArchivedName[];
  showOnlySelectedRows = false;
  includeArchivedClients = false;

  onChange: ControlValueAccessorFn = () => {};
  onTouched: ControlValueAccessorFn = () => {};

  private tableFilterBS = new BehaviorSubject<TeamClientTableFilter>({
    showOnlySelectedRows: false,
    includeArchivedClients: false,
  });

  get filterFields() {
    return this.columns.map((column) => column.field);
  }

  constructor(store: Store<AppState>, private changeDetectorRef: ChangeDetectorRef) {
    this.clients$ = store
      .select(ClientSelectors.allClients)
      .pipe(switchMap((clients) => this.getFilteredClientsObservable(clients)));
  }

  writeValue(clients: ClientType[]): void {
    this.selectedClients = this.mapToClientTypeWithArchivedName(clients);
    this.changeDetectorRef.markForCheck();
    // reset checkboxes and filter
    this.tableFilterBS.next({ showOnlySelectedRows: false, includeArchivedClients: false });
    this.showOnlySelectedRows = false;
    this.includeArchivedClients = false;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  getColumnWidth(column: TableColumn) {
    if (!column.width || column.width === 'auto') {
      return {};
    }

    return { maxWidth: column.width };
  }

  filterTableBaseOnShowOnlySelectedRows(showOnlySelectedRows: boolean) {
    this.refilterClients(this.updateTableFilter({ showOnlySelectedRows }));
  }

  filterTableBasedOnIncludeArchivedClients(includeArchivedClients: boolean) {
    this.refilterClients(this.updateTableFilter({ includeArchivedClients }));
  }

  refilterClients(filterValue?: TeamClientTableFilter) {
    const updatedFilterValue = filterValue ?? { ...this.tableFilterBS.value };
    this.tableFilterBS.next(updatedFilterValue);
  }

  private updateTableFilter(value: Partial<TeamClientTableFilter>) {
    return { ...this.tableFilterBS.value, ...value };
  }

  private getFilteredClientsObservable(clients: ClientType[]) {
    return this.tableFilterBS.pipe(map((filter) => this.applyFilterOnClients(clients, filter)));
  }

  private applyFilterOnClients(
    clients: ClientType[],
    { showOnlySelectedRows, includeArchivedClients }: TeamClientTableFilter,
  ) {
    const archivedClientsFilter = (client: ClientType) => includeArchivedClients || !client.archived;

    if (!showOnlySelectedRows) {
      return this.mapToClientTypeWithArchivedName(clients.filter(archivedClientsFilter));
    }

    const filteredClientIds = this.selectedClients.filter(archivedClientsFilter).map((client) => client.id);
    return this.mapToClientTypeWithArchivedName(clients.filter((client) => filteredClientIds.includes(client.id)));
  }

  private mapToClientTypeWithArchivedName = (clients: ClientType[]) => {
    if (!clients) {
      return null;
    }

    const clientsWithArchivedName = clients.map((client) => ({
      ...client,
      archivedName: `${client.archived ? 'Arkiverad -' : ''}${client.name}`,
    }));
    return clientsWithArchivedName;
  };
}
