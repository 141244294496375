import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-refresh-button',
  template: `
    <ng-container *ngIf="loading">
      <button class="btn btn-primary" title="Uppdatera listan" disabled="true">
        <i class="fa fas fa-sync fa-spin"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="!loading">
      <button class="btn btn-primary" (click)="onRefresh()" title="Uppdatera listan" [disabled]="disabled">
        <i class="fa fas fa-sync"></i>
      </button>
    </ng-container>
  `,
})
export class RefreshButton {
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Output() onClick: EventEmitter<void>;

  constructor() {
    this.loading = false;
    this.disabled = false;
    this.onClick = new EventEmitter();
  }

  onRefresh() {
    this.onClick.emit();
  }
}
