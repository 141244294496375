import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Client } from 'src/app/core/entity/client';
import { TaskService } from 'src/app/core/services/task.service';
import { ApplicationLinksGroup, ApplicationLink } from 'src/app/shared/misc/application-link-builders/types';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { ToastActions } from '@app/core/state/toast/toast.actions';
import { ApplicationLinksService } from '@app/core/services/application-links.service';

@Component({
  selector: 'app-application-links',
  templateUrl: './application-links.component.html',
  styles: [
    `
      .app-link {
        cursor: pointer;
      }
      .disabled-app-link,
      .disabled-app-link > img {
        filter: grayscale(100%) opacity(0.6);
        cursor: default;
      }
    `,
  ],
})
export class ApplicationLinksComponent {
  _active: boolean = false;
  applicationLinksGroups$: Observable<ApplicationLinksGroup[]>;

  @Input() client: Client = null;

  @Input()
  get active() {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value;
    if (value === true) {
      this.setupApplicationLinksGroups();
    }
  }

  constructor(
    private taskService: TaskService,
    private store: Store<AppState>,
    private applicationLinksService: ApplicationLinksService,
  ) {}

  openAgentApplication(link: ApplicationLink) {
    const { disabled, task, year } = link;
    if (disabled) {
      return this.showDisabledApplicationMessage();
    }

    const requestedDateForApplication = this.getRequestDateForAgent(link);

    if (!requestedDateForApplication) {
      return this.showMissingFinancialYearMessage(year);
    }

    this.taskService.getAgentUrl(task.id, requestedDateForApplication).subscribe((response) => {
      const elementId = 'cloudServiceAgentCaller';
      const agentCallerElement = <HTMLAnchorElement>document.getElementById(elementId);
      agentCallerElement.href = response.agentCall;
      agentCallerElement.click();
      return false;
    });
  }

  private setupApplicationLinksGroups() {
    this.applicationLinksGroups$ = this.taskService
      .getTasksByClientId(this.client.id)
      .pipe(map(this.applicationLinksService.getApplicationLinksGroups), startWith([]));
  }

  private getRequestDateForAgent({ year }: ApplicationLink): string {
    const financialyear = this.client.financialYears.find((f) => f.span?.end?.startsWith(String(year)));
    return financialyear ? financialyear.span.end : undefined;
  }

  private showMissingFinancialYearMessage(year: number) {
    this.store.dispatch(
      ToastActions.showWarnMessage({
        summary: 'Räkenskapsår saknas',
        detail: `Inget räkenskapsår för ${year} hittades i Byråstöd.`,
      }),
    );
  }

  private showDisabledApplicationMessage() {
    this.store.dispatch(
      ToastActions.showWarnMessage({ summary: 'Ingen tjänst', detail: 'Inget tjänst hittades för detta program.' }),
    );
  }
}
