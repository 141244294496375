import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClientTypesSelectors } from '@app/core/state/clientTypes/clientTypes.selectors';
import { getAgentUrl } from '@app/core/state/shared/shared';
import {
  ClosingCompositeType,
  CompositeListFilter,
  ClosingListParams,
  CompositeType,
  ClientTypeType,
} from '@app/core/state/types';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';
import { CompositeListComponent } from '@app/shared/components/composite-list/composite-list.component';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { TodayDate } from '@app/shared/misc/today.date';
import { BehaviorSubject, Observable, map } from 'rxjs';

interface DropdownItem<T> {
  label: string;
  value: T;
}

@Component({
  selector: 'app-closing-list',
  templateUrl: './closing.list.component.html',
})
export class ClosingListComponent
  extends CompositeListComponent<ClosingCompositeType, CompositeListFilter, ClosingListParams>
  implements OnInit, OnDestroy
{
  allValue = 'ALL';
  selectedYear: string;
  selectedClientResponsibleUserId: number;
  selectedTaskUserId: number;
  includeAllDoneClients: boolean;
  selectedTypes: string[];
  selectedMonths: number[];
  public columns: TableColumn[] = [
    { field: 'client', header: 'Klient', width: '10%' },
    { field: 'corporateIdentity', header: 'Orgnr', width: '6%' },
    { field: 'customerNumber', header: 'K.nr', width: '4%' },
    { field: 'responsible', header: 'KA', width: '4%' },
    { field: 'mainSpan.end', header: 'Boksl.dat', width: '6%' },
    { field: 'accountant', header: 'Revisor', width: '7%' },
    {
      field: 'best.activity.state.description',
      header: 'Best eng.besked',
      fieldValue: (_fieldValue, rowData) => rowData?.best?.activity?.state?.description,
    },
    {
      field: 'forb.activity.state.description',
      header: 'Förb. Boks.möte',
      fieldValue: (_fieldValue, rowData) => rowData?.forb?.activity?.state?.description,
    },
    {
      field: 'bfrb.activity.state.description',
      header: 'Förb. bokslutsarb.',
      visible: false,
      fieldValue: (_fieldValue, rowData) => rowData?.bfrb?.activity?.state?.description,
    },
    {
      field: 'boks.activity.state.description',
      header: 'Bokslut',
      fieldValue: (_fieldValue, rowData) => rowData?.boks?.activity?.state?.description,
    },
    {
      field: 'uppr.activity.state.description',
      header: 'Årsbokslut',
      fieldValue: (_fieldValue, rowData) => rowData?.uppr?.activity?.state?.description,
    },
    {
      field: 'arsr.activity.state.description',
      header: 'Årsredovisning',
      fieldValue: (_fieldValue, rowData) => rowData?.arsr?.activity?.state?.description,
    },
    {
      field: 'arss.activity.state.description',
      header: 'Årsred. u.revis',
      fieldValue: (_fieldValue, rowData) => rowData?.arss?.activity?.state?.description,
    },
    {
      field: 'uppa.activity.state.description',
      header: 'Årsred. m.revis.',
      fieldValue: (_fieldValue, rowData) => rowData?.uppa?.activity?.state?.description,
    },
    {
      field: 'skic.activity.state.description',
      header: 'Skicka in årsred',
      fieldValue: (_fieldValue, rowData) => rowData?.skic?.activity?.state?.description,
    },
    {
      field: 'bokr.activity.state.description',
      header: 'Bokslutsrapport',
      fieldValue: (_fieldValue, rowData) => rowData?.bokr?.activity?.state?.description,
    },
    { field: 'taskUsers', header: 'UH', width: '5%', visible: false },
    { field: 'program', header: 'Program', width: '70px', sortable: false, exportable: false },
  ];

  clientTypesSub: BehaviorSubject<ClientTypeType[]>;
  clientTypes$: Observable<ClientTypeType[]>;
  months: DropdownItem<number>[];

  override ngOnInit(): void {
    super.ngOnInit();
    this.clientTypesSub = new BehaviorSubject([]);
    this.initTypes();
    this.initDates();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  loadUserSettings(): void {
    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.CLOSING_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.selectedYear = this.uss.loadSetting(
      UserSettingsStorage.CLOSING_YEAR,
      new TodayDate().getRelevantYearAsString(6),
    );
    this.selectedClientResponsibleUserId = this.uss.loadSettingAsNumber(
      UserSettingsStorage.CLOSING_CLIENT_RESPONSIBLE_ID,
      0,
    );
    this.selectedTaskUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.CLOSING_USER_ID, 0);
    this.includeAllDoneClients = this.uss.loadSettingAsBoolean(
      UserSettingsStorage.CLOSING_INCLUDE_ALL_DONE_CLIENTS,
      true,
    );
    this.selectedTypes = JSON.parse(this.uss.loadSetting(UserSettingsStorage.CLOSING_TYPES, '[]'));
    this.selectedMonths = JSON.parse(
      this.uss.loadSetting(UserSettingsStorage.CLOSING_MONTHS, '[0,1,2,3,4,5,6,7,8,9,10,11,12]'),
    );
  }

  saveUserSettings(): void {
    this.uss.saveSetting(UserSettingsStorage.CLOSING_YEAR, this.selectedYear);
    this.uss.saveSetting(UserSettingsStorage.CLOSING_CLIENT_RESPONSIBLE_ID, `${this.selectedClientResponsibleUserId}`);
    this.uss.saveSetting(UserSettingsStorage.CLOSING_USER_ID, `${this.selectedTaskUserId}`);
    this.uss.saveSetting(UserSettingsStorage.CLOSING_INCLUDE_ALL_DONE_CLIENTS, `${this.includeAllDoneClients}`);
    this.uss.saveSetting(UserSettingsStorage.CLOSING_TYPES, JSON.stringify(this.selectedTypes));
    this.uss.saveSetting(UserSettingsStorage.CLOSING_MONTHS, JSON.stringify(this.selectedMonths));
  }

  getFilterValue() {
    return { includeAllDoneClients: this.includeAllDoneClients };
  }

  getParamsValue() {
    return {
      responsibleId: this.selectedClientResponsibleUserId,
      executorId: this.selectedTaskUserId,
      year: this.selectedYear,
      months: this.selectedMonths,
      clientTypes: this.selectedTypes,
    };
  }

  getPaginatorTriggeredKey() {
    return UserSettingsStorage.CLOSING_NUMBER_OF_ROWS;
  }

  getCompositeType() {
    return CompositeType.closing;
  }

  isUndefined(value: any): boolean {
    // eslint-disable-next-line no-undefined
    return value === undefined;
  }

  getAgentUrl(composite: ClosingCompositeType) {
    const url = getAgentUrl(composite);
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  private initTypes() {
    this.store
      .select(ClientTypesSelectors.all)
      .pipe(
        map((types) => types.filter((t) => t.name !== 'PERSON')),
        this.takeUntilDestroyed(),
      )
      .subscribe((types) => {
        if (!this.areSelectedTypesStored()) {
          this.selectedTypes = [this.allValue].concat(types.map((t) => t.name));
        }
        this.clientTypesSub.next(types);
      });

    this.clientTypes$ = this.clientTypesSub.asObservable();
  }

  private initDates() {
    const todayDate = new TodayDate();
    this.months = todayDate.MONTHS.map(this.toMonthDropdownItem);
  }

  private toMonthDropdownItem(value: string, index: number): DropdownItem<number> {
    return { label: value, value: index + 1 };
  }

  private areSelectedTypesStored = () => Boolean(this.uss.loadSetting(UserSettingsStorage.CLOSING_TYPES, null));
}
