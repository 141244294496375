import { AssignmentType, NameDescriptionType, SimpleClientType, SimpleUserType } from '../types';

export interface TodoType {
  id: number;
  entryDate: string;
  deadline: string;
  text: string;
  tmpText: string;
  users: SimpleUserType[];
  creator: SimpleUserType;
  client: SimpleClientType;
  clientName: string; // kolla om den behövs/används
  clientOrgNbr: string; // kolla om den behövs/används
  clientCustNbr: string; // kolla om den behövs/används
  clientRespName: string; // kolla om den behövs/används
  state: NameDescriptionType;
  statename: string; // kolla om den behövs/används
  assignment: AssignmentType;
  assignmentname: string; // kolla om den behövs/används
  scope: string;
  usernames: string;
  selectableStates: NameDescriptionType[];
  selectableScopes: string[];
  type: NameDescriptionType;
  selectableTypes: NameDescriptionType[];
}

export const TodoSelectableStates: NameDescriptionType[] = [
  { description: 'Ej påbörjad', name: 'NST' },
  { description: 'Pågående', name: 'IPG' },
  { description: 'Klar', name: 'DON' },
];

export const TodoSelectableTypes: NameDescriptionType[] = [
  { description: 'Notering', name: 'NOT' },
  { description: 'Besök', name: 'BES' },
  { description: 'E-post', name: 'EPO' },
  { description: 'Telefon', name: 'TEL' },
  { description: 'Övrigt', name: 'OVR' },
];

export const initialTodoType: TodoType = {
  id: null,
  entryDate: null,
  deadline: null,
  text: '',
  tmpText: '',
  users: [],
  creator: null,
  client: null,
  clientName: '',
  clientOrgNbr: '',
  clientCustNbr: '',
  clientRespName: '',
  state: null,
  statename: '',
  assignment: null,
  assignmentname: '',
  scope: null,
  usernames: '',
  selectableStates: TodoSelectableStates,
  selectableScopes: [],
  type: { description: '', name: '' },
  selectableTypes: TodoSelectableTypes,
};
