<table class="w-full">
  <tr>
    <td></td>
    <td></td>
    <td></td>
  </tr>
  <tr>
    <td class="table-label" colspan="2">Bransch</td>
    <td class="table-label">Registreringsdatum</td>
  </tr>
  <tr>
    <td class="editor-cell" colspan="2">
      <input
        type="text"
        class="form-control"
        (change)="emitBusinessDetails()"
        [(ngModel)]="clientBusinessDetails.businessArea"
        maxlength="50"
      />
    </td>
    <td class="editor-cell">
      <app-calendar
        [(selectedDate)]="clientBusinessDetails.registrationDate"
        [minSelectableDate]="minSelectableDate"
        [defaultDate]="null"
        (selectedDateChange)="emitBusinessDetails()"
        styleClass="w-full"
      ></app-calendar>
    </td>
  </tr>
  <tr>
    <td colspan="3" class="table-label">Beskrivning av verksamheten</td>
  </tr>
  <tr>
    <td colspan="3" class="editor-cell">
      <textarea
        rows="5"
        cols="260"
        (change)="emitBusinessDetails()"
        class="w-100 form-control"
        [(ngModel)]="clientBusinessDetails.description"
        maxlength="300"
      ></textarea>
    </td>
  </tr>
  <tr>
    <td colspan="3" class="table-label">Speciella regelverk att ta hänsyn till</td>
  </tr>
  <tr>
    <td colspan="3" class="editor-cell">
      <textarea
        rows="5"
        cols="130"
        (change)="emitBusinessDetails()"
        class="w-100 form-control"
        [(ngModel)]="clientBusinessDetails.specialRules"
        maxlength="300"
      ></textarea>
    </td>
  </tr>
  <tr>
    <td colspan="3" class="table-label">Övrig viktig information om klienten</td>
  </tr>
  <tr>
    <td colspan="3" class="editor-cell">
      <textarea
        rows="5"
        cols="130"
        (change)="emitBusinessDetails()"
        class="w-100 form-control"
        [(ngModel)]="clientBusinessDetails.otherInfo"
        maxlength="300"
      ></textarea>
    </td>
  </tr>
  <tr>
    <td colspan="2" class="table-label">Bank</td>
    <td class="table-label">Revisor</td>
  </tr>
  <tr>
    <td class="editor-cell" colspan="2">
      <input
        type="text"
        class="form-control"
        (change)="emitBusinessDetails()"
        [(ngModel)]="clientBusinessDetails.bank"
        maxlength="40"
      />
    </td>
    <td class="editor-cell">
      <input
        type="text"
        class="form-control"
        (change)="emitBusinessDetails()"
        [(ngModel)]="clientBusinessDetails.accountant"
        maxlength="40"
      />
    </td>
  </tr>
  <tr></tr>
</table>

<div style="height: 15px"></div>
<div class="flex justify-end mt-1">
  <button
    type="button"
    class="btn btn-success mr-3"
    (click)="close(1)"
    [disabled]="clientBusinessDetails.archived || clientBusinessDetails.id === -1"
  >
    Spara
  </button>
  <button type="button" class="btn btn-warning" (click)="close(-1)">Stäng</button>
</div>
