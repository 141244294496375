export const showArchiveWarningIfRecentlyUsed = (lastUsed: string): boolean => {
  if (!lastUsed) {
    return false;
  }
  const lastUsedDate = new Date(lastUsed);
  const currentDate = new Date();
  const diffInDays = Math.floor((currentDate.getTime() - lastUsedDate.getTime()) / (1000 * 60 * 60 * 24));
  return diffInDays < 90;
};

export const archiveWarningMessage = (lastUsed: string): string =>
  `<div class="mt-3"><span class="font-bold">Observera!</span> Någon har loggat in i denna företagsdatabas så sent som [${lastUsed}]. <br>
  Om en annan byrå, eller klienten själv, jobbar i denna företagsdatabas så ska du <span class="font-bold">INTE</span> arkivera den.</div>`;
