import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorContext } from 'centrifuge';
import { MessagingMessage } from '../types';

export const MessagingActions = createActionGroup({
  source: 'Messaging',
  events: {
    connecting: emptyProps(),
    connected: emptyProps(),
    disconnected: emptyProps(),
    subscribing: emptyProps(),
    subscribed: emptyProps(),
    unsubscribed: emptyProps(),
    error: props<{ ctx: ErrorContext }>(),
    publish: props<{ message: MessagingMessage }>(),
  },
});
