import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CLIENT_RESPONSIBLE } from '@app/shared/misc/constants';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Client } from 'src/app/core/entity/client';
import { Task } from 'src/app/core/entity/task';
import { getDateValidator } from 'src/app/core/entity/validation';
import { ValidationStatus } from 'src/app/core/entity/validationStatus';
import { ClientService } from 'src/app/core/services/clients.service';
import { TaskService } from 'src/app/core/services/task.service';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { UserType } from 'src/app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';
import { TableColumnWithFilter } from 'src/app/shared/components/table/interfaces/table-column';
import { TodayDate } from 'src/app/shared/misc/today.date';
import { StateFacade } from '../../../facades/state.facade';
import { BulkChangeComponentData } from '../../../interfaces/bulk-change.component';
import { BaseChangeComponent } from '../base-change.component';

@Component({
  template: `<div [formGroup]="formGroup">
    <p>Vad ska det ändras till?</p>
    <app-calendar formControlName="toStartdate"></app-calendar>
  </div>`,
})
export class ChangeStartdateComponent extends BaseChangeComponent {
  availableUsers$: Observable<UserType[]>;
  filterAvailableUsers$: Observable<UserType[]>;

  constructor(
    stateFacade: StateFacade,
    private clientService: ClientService,
    private taskService: TaskService,
    private store: Store<AppState>,
  ) {
    super(stateFacade);
    this.availableUsers$ = this.store.select(UserSelectors.activeUsersWithNoResponsible);
    this.filterAvailableUsers$ = this.store.select(UserSelectors.activeUsersWithMissingResponsible);

    this.setDefaultStartdate();
  }

  protected load(): Observable<BulkChangeComponentData> {
    this.formGroup.disable({ emitEvent: false });

    return forkJoin([this.taskService.getAllTasks(), this.clientService.getAllArchivedClients()]).pipe(
      map(([tasks, archivedClients]: [Task[], Client[]]) => {
        const archivedClientIds = archivedClients.map((client) => client.id);
        const hasArchivedClient = (task: Task) => archivedClientIds.includes(task.client.id);
        const isEnded = (task: Task) => !!task.span.end;

        // excludes tasks with archived clients and assignment with end date
        return tasks.filter((task: Task) => !hasArchivedClient(task) && !isEnded(task));
      }),
      map((tasks: Task[]) => this.sortTasks(tasks)),
      map((tasks: Task[]) => ({ tableData: tasks, dataToChange: this.formGroup.value })),
      finalize(() => {
        this.formGroup.enable({ emitEvent: false });
      }),
    );
  }

  protected createForm() {
    return new UntypedFormGroup({
      toStartdate: new UntypedFormControl(null, Validators.required),
    });
  }

  protected getDataIdentityKey(): string {
    return 'id';
  }

  protected getTableColumns(): TableColumnWithFilter[] {
    return [
      { field: 'client.name', header: 'Klient', filter: { type: 'text' } },
      { field: 'client.corporateIdentity', header: 'Org.nr', filter: { type: 'text' } },
      {
        field: 'client.responsible',
        header: CLIENT_RESPONSIBLE,
        fieldValue: (responsible) => responsible?.name,
        filter: {
          type: 'multidropdown',
          data: this.availableUsers$,
          dataLabel: 'name',
          dataValue: 'id',
        },
      },
      { field: 'assignmentName', header: 'Uppdrag', filter: { type: 'text' } },
      { field: 'type', header: 'Tjänst', filter: { type: 'text' } },
      {
        field: 'users',
        header: 'Handläggare',
        filter: {
          type: 'multidropdownwithmultirules',
          data: this.availableUsers$,
          dataLabel: 'name',
          dataValue: 'id',
        },
        fieldValue: (users) => users?.map((user: UserType) => user.initials).join(', '),
      },
      { field: 'span.start', header: 'Startdatum', filter: { type: 'text' } },
      { field: 'periodicity.description', header: 'Periodicitet', filter: { type: 'text' } },
      {
        field: 'client.currentFinancialYear',
        header: 'Räkenskapsår',
        filter: { type: 'text' },
        fieldValue: (financialYear) =>
          financialYear ? `${financialYear.span?.start} - ${financialYear.span?.end}` : '',
      },
    ];
  }

  protected override onValidateFields(): boolean {
    const { validate, min, max } = getDateValidator();
    const date = this.formGroup.get('toStartdate').value;
    const { status, title, text } = validate(date, { min, max, label: 'Vad ska det ändras till' });
    const hasValidationErrors = status !== ValidationStatus.Ok;

    if (hasValidationErrors) {
      this.store.dispatch(ToastActions.showWarnMessage({ summary: title, detail: text }));
      return false;
    }

    return true;
  }

  private setDefaultStartdate() {
    const today = TodayDate.getIsoFormattedDate(new Date());
    this.formGroup.setValue({ toStartdate: today });
  }

  private sortTasks(tasks: Task[]): Task[] {
    return tasks.sort((t1, t2) => {
      if (t1.client.name != t2.client.name) {
        return t1.client.name < t2.client.name ? -1 : 1;
      }
      if (t1.assignmentName != t2.assignmentName) {
        return t1.assignmentName < t2.assignmentName ? -1 : 1;
      }
      return t1.type < t2.type ? -1 : 1;
    });
  }
}
