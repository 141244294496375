import { Injectable } from '@angular/core';
import { Observable, catchError, of, throwError } from 'rxjs';

import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BLService } from './bl.service';
import { BenefitsDto, PartnerFirm, LevelsDto, PropertiesDto, SaveFirmDto } from '../state/types/partner.types';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { AppState } from '../state/appState';
import { getCurrentIsoDateWithoutTime } from '@app/shared/misc/dates';

@Injectable({ providedIn: 'root' })
export class PartnerProgramService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'partnerprogram';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(store: Store<AppState>, private http: HttpClient) {
    super(store);
  }

  getFirm(language = 'sv', startDate = getCurrentIsoDateWithoutTime()): Observable<PartnerFirm> {
    return this.http
      .get<PartnerFirm>(
        `${this.url}/firm?language=${language}&startDate=${startDate}`,
        this.options.getAuthorizationHeaderWithEmptyBody(),
      )
      .pipe(
        catchError((err: any) => {
          if (err.status === 404 && err.error.message === 'PARTNER_NO_PARTNER_DATA') {
            return of(null);
          }

          return throwError(() => err);
        }),
        this.catchErrorAndShowMessage(),
      );
  }

  getBenefits(language = 'sv'): Observable<BenefitsDto> {
    return this.http
      .get<BenefitsDto>(`${this.url}/benefits?language=${language}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getProperties(language = 'sv'): Observable<PropertiesDto> {
    return this.http
      .get<PropertiesDto>(
        `${this.url}/properties?language=${language}`,
        this.options.getAuthorizationHeaderWithEmptyBody(),
      )
      .pipe(this.catchErrorAndShowMessage());
  }

  getLevels(language = 'sv'): Observable<LevelsDto> {
    return this.http
      .get<LevelsDto>(`${this.url}/levels?language=${language}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  updateFirm(data: SaveFirmDto): Observable<SaveFirmDto> {
    return this.http
      .post<SaveFirmDto>(`${this.url}/firm`, data, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }
}
