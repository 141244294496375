import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'app-multiselect-dropdown',
  template: `
    <p-multiSelect
      [options]="options"
      [(ngModel)]="model"
      [filter]="false"
      [showToggleAll]="false"
      [showHeader]="false"
      [disabled]="disabled"
      (onPanelHide)="onHide.emit()"
      [selectedItemsLabel]="selectedItemsLabel || '{0} personer valda'"
      [maxSelectedLabels]="maxSelectedLabels"
      placeholder="{{ placeholder }}"
      optionLabel="{{ optionLabel }}"
      appendTo="{{ appendTo }}"
      styleClass="app-multiselect-dropdown {{ styleClass }}"
      [scrollHeight]="scrollHeight"
      [autoOptionFocus]="false">
    </p-multiSelect>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiselectComponent implements OnChanges {
  @ViewChild(MultiSelect) multiSelect: MultiSelect;
  public scrollHeight: string;

  @Input() breakpoint: number = 16;
  @Input()
  get options(): any[] {
    return this._options;
  }
  set options(options: any[]) {
    this._options = options;
    this.scrollHeight = getScrollHeightString(this._options.length, this.breakpoint);
  }

  @Input() optionLabel: string = 'label';
  @Input() optionValue: string | undefined;
  @Input() styleClass: string = '';
  @Input() appendTo: string | undefined;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = value;
    if (value === true) {
      this.closeOverlay();
    }
  }

  @Input() selectedItemsLabel: string = '{0} personer valda';
  @Input() maxSelectedLabels: number = 0;
  @Input() placeholder: string;

  @Input()
  get selected() {
    return this._selected;
  }
  set selected(value) {
    // Note: Om man skickar in [0] så blir alla markerad.
    // Funkar just nu bara när man har satt optionValue till ett färlt med siffror
    const isAllUsersSelected = value?.length > 0 && value[0] === 0;
    if (!isAllUsersSelected) {
      this._selected = value;
      return;
    }
    this._selected = this.getValuesForSelected();
  }

  @Output() selectedChange: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();

  private _disabled: boolean = false;
  private _model: any[];
  private _selected: any[];
  private _options: any[];

  get model() {
    return this._model;
  }
  set model(value) {
    this._model = value;
    this.emitChangedValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.selected || changes?.options) {
      this.updateModelValue(this.selected);
    }
  }

  public show() {
    const element = this.multiSelect.el.nativeElement.querySelector('.app-multiselect-dropdown');
    if (element) {
      element.click(); // workaround to open the multiselect overlaylist
    }
  }

  private closeOverlay() {
    if (this.multiSelect) {
      this.multiSelect.hide();
    }
  }

  private updateModelValue(selectedValue: any[]) {
    if (!this.optionValue) {
      this._model = selectedValue;
      return;
    }

    this._model = this.findOptionObjectByValueKey(selectedValue);
  }

  private findOptionObjectByValueKey(selectedValues: any[]) {
    if (!this.options || !selectedValues) {
      return undefined;
    }
    return this.options.filter((o) => selectedValues.includes(o[this.optionValue]));
  }

  private emitChangedValue() {
    let emitValue: any[] = this.model;
    if (this.optionValue) {
      emitValue = this.model.map((item) => item[this.optionValue]);
    }

    this.selectedChange.emit(this.model.length === this.options.length ? [0] : emitValue);
  }

  private getValuesForSelected() {
    if (this.optionValue) {
      return this.options.map((item) => item[this.optionValue]);
    }
    return [...this.options];
  }
}
