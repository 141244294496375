import { Store } from '@ngrx/store';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { filter, first, take, tap } from 'rxjs/operators';
import { ListSelectors } from '../state/list/list.selectors';
import { AppState } from '../state/appState';

// eslint-disable-next-line no-undefined
const isNullish = (value: unknown) => value === null || value === undefined;

export const takeFirstTruthy = <T>(onlyNullish = false): MonoTypeOperatorFunction<T> =>
  pipe(
    filter((value: T) => (onlyNullish ? !isNullish(value) : !!value)),
    take(1),
  );

export const logToConsole = <T>(message: string, logData = false): MonoTypeOperatorFunction<T> =>
  pipe(
    // eslint-disable-next-line no-console
    tap((data: T) => (logData ? console.log(message, data) : console.log(message))),
  );

export const withTeamIds = (store: Store<AppState>) =>
  store.select(ListSelectors.selectedTeams).pipe(filter(({ initialized, ids }) => initialized && Boolean(ids?.length)));

export const withFirstTeamIds = (store: Store<AppState>) => withTeamIds(store).pipe(first());
