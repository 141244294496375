import { Task } from 'src/app/core/entity/task';
import { BaseApplicationLinksBuilder } from './BaseApplicationLinksBuilder';
import { ApplicationLink, ApplicationType } from './types';

export class BlaApplicationLinksBuilder extends BaseApplicationLinksBuilder {
  constructor(title: string) {
    super(title);
  }

  canBuild(type: ApplicationType): boolean {
    return type === ApplicationType.BL;
  }

  build(type: ApplicationType, tasks: Task[]): ApplicationLink[] {
    this.cantBuildGuard(type);
    const title = this.title;
    const year = new Date().getUTCFullYear(); // can be any date

    return [this.createLink({ type, title, tasks, year })];
  }
}
