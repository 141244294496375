import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'BLCurrency', standalone: true })
export class BlCurrencyPipe extends DecimalPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  override transform(value: any, arg?: any): any {
    if (this.isNumber(value)) {
      // If argument false is passed, value without decimals is returned
      if (arg !== false) {
        return super.transform(value, '1.2-2', 'sv');
      }

      return super.transform(value, '1.0-0', 'sv');
    }

    if (typeof value === 'string') {
      const num = +value;
      if (arg !== false) {
        return super.transform(num, '1.2-2', 'sv');
      }

      return super.transform(num, '1.0-0', 'sv');
    }

    return '';
  }

  private isNumber = (value: unknown) => typeof value === 'number' && !Number.isNaN(value);
}
