import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { withFirstTeamIds } from '@app/core/misc/rxjs-operators';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { MemoizedSelector, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { UserType } from 'src/app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';

@Component({
  selector: 'app-user-selector',
  template: `
    <p-dropdown
      [options]="availableUsers$ | async"
      [ngModel]="userId"
      (ngModelChange)="onUserIdChange($event)"
      optionValue="id"
      optionLabel="name"
      [styleClass]="styleClass"
      [scrollHeight]="scrollHeight"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false">
    </p-dropdown>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UserSelector,
    },
  ],
})
export class UserSelector implements OnDestroy, ControlValueAccessor {
  public availableUsers$: Observable<UserType[]>;
  public scrollHeight: string;
  @Input() styleClass: string;
  @Input() placeholder: string;
  @Input()
  set includeAllItem(includeAllItem: boolean) {
    this.refresh(includeAllItem);
  }

  @Input() userId: number;
  @Input() disabled = false;
  @Output() userIdChange = new EventEmitter();

  private onDestroy: Subject<void>;
  private usersBS: BehaviorSubject<UserType[]>;

  constructor(private store: Store<AppState>) {
    this.onDestroy = new Subject();
    this.usersBS = new BehaviorSubject([]);
    this.refresh(true);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  onChange: ControlValueAccessorFn = () => {};
  onTouched: ControlValueAccessorFn = () => {};

  writeValue(userId: number): void {
    this.userId = userId;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onUserIdChange = (userId: number) => {
    this.onChange(userId);
    this.userIdChange.emit(userId);
  };

  private refresh(includeAllItem: boolean) {
    const selector = includeAllItem ? UserSelectors.activeUsersWithAll : UserSelectors.activeUsersWithoutAll;
    withFirstTeamIds(this.store)
      .pipe(
        mergeMap(({ ids }) => this.getFilteredObservable(selector, includeAllItem, ids)),
        takeUntil(this.onDestroy),
      )
      .subscribe((users) => {
        this.usersBS.next(users);
        this.scrollHeight = getScrollHeightString(users.length);
      });
    this.availableUsers$ = this.usersBS.asObservable();
  }

  private isAllUser(includeAllItem: boolean, user: UserType): boolean {
    return includeAllItem && user.id === 0;
  }

  private isIncludedInTeam(user: UserType, ids: number[]): boolean {
    return Boolean(user.teams?.find((id) => ids?.includes(id)));
  }

  private getFilteredObservable = (
    selector: MemoizedSelector<AppState, UserType[]>,
    includeAllItem: boolean,
    ids: number[],
  ) =>
    this.store.select(selector).pipe(
      map((users) => {
        const filtered = users.filter((u) => this.isAllUser(includeAllItem, u) || this.isIncludedInTeam(u, ids));
        return filtered?.length ? filtered : [];
      }),
    );
}
