import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { BadgeProps } from '@bl/components';
import { BlFrontendBaseComponent } from './bl-frontend-base.component';

@Component({
  selector: 'bl-frontend-badge',
  template: '<div></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class BlFrontendBadgeComponent extends BlFrontendBaseComponent<BadgeProps> {
  @Input() text: BadgeProps['text'];
  @Input() variant?: BadgeProps['variant'] = 'neutral';
  @Input() icon?: BadgeProps['icon'];
  @Input() iconVariant?: BadgeProps['iconVariant'];
  @Input() iconSize?: BadgeProps['iconSize'];
  @Input() iconAnimation?: BadgeProps['iconAnimation'];

  constructor(protected override ref: ElementRef) {
    super(ref);
  }

  protected override getComponentName(): string {
    return 'Badge';
  }

  protected override getProps(): BadgeProps {
    return {
      text: this.text,
      variant: this.variant,
      icon: this.icon,
      iconAnimation: this.iconAnimation,
      iconSize: this.iconSize,
      iconVariant: this.iconVariant,
    };
  }

  protected override onInitialized = () => this.ref.nativeElement.classList.add('flex', 'overflow-hidden');
}
