import { Client } from '@app/core/entity/client';
import { ClientService } from '@app/core/services/clients.service';
import { DialogService } from 'primeng/dynamicdialog';
import { filter, first, mergeMap, Observable } from 'rxjs';
import {
  NewClientDialog,
  NewClientDialogConfig,
  NewClientDialogResult,
} from '../components/new-client/new.client.dialog';
import { FirmType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/effects';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';

const showNewClientDialog = ({
  dialogService,
  client,
  firm,
  tabToOpen,
}: {
  dialogService: DialogService;
  client: Client;
  firm: FirmType;
  tabToOpen: string;
}): Observable<NewClientDialogResult> => {
  const data: NewClientDialogConfig = { client, firm, tabToOpen };
  const ref = dialogService.open(NewClientDialog, {
    data,
    header: (client?.archived ? 'Arkiverad - ' : '') + (client?.name || 'Ny klient'),
    width: '1255px',
    height: '840px',
    modal: true,
    styleClass: 'new-client-dialog',
  });

  return ref.onClose.pipe(first());
};

export const showClientDialog = ({
  clientService,
  dialogService,
  id,
  store,
  tabToOpen = '',
}: {
  clientService: ClientService;
  dialogService: DialogService;
  id: number;
  store: Store<AppState>;
  tabToOpen?: string;
}): Observable<NewClientDialogResult> => {
  if (id === -1) {
    const client = new Client('');
    return store.select(FirmSelectors.selectFirm).pipe(
      filter((firm) => Boolean(firm)),
      first(),
      mergeMap((firm) => showNewClientDialog({ dialogService, client, firm, tabToOpen })),
    );
  }

  return clientService.getClientById(id).pipe(
    first(),
    concatLatestFrom(() => store.select(FirmSelectors.selectFirm).pipe(filter((firm) => Boolean(firm)))),
    mergeMap(([client, firm]) => showNewClientDialog({ dialogService, client, firm, tabToOpen })),
  );
};
