import { map, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, zip } from 'rxjs';

import { Firm } from '../entity/firm';
import { FirmSettings } from '../entity/firm.settings';
import { BLService } from './bl.service';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { ActivateSustainabilityReporting, FirmType } from '../state/types';
import { FirmSelectors } from '../state/firm/firm.selectors';
import { toFirmTransformer } from '../state/transformers/transformers';
import { AppState } from '../state/appState';

@Injectable({
  providedIn: 'root',
})
export class FirmService extends BLService {
  public firmAssignmentData$: Observable<FirmSettings>;
  public assignmentAgreementFirmData$: Observable<[Firm, FirmSettings]>;
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'firm';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;
  private firmAssignmentDataBS = new BehaviorSubject(new FirmSettings());

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
    this.firmAssignmentData$ = this.firmAssignmentDataBS.asObservable();
    this.assignmentAgreementFirmData$ = zip(
      this.store.select(FirmSelectors.selectFirm).pipe(map(toFirmTransformer.transform)),
      this.firmAssignmentData$,
    );
  }

  loadFirmSettings(): Observable<FirmSettings> {
    return zip(this.getFirmSettings(), this.getMaxLengthForAssignmentValues()).pipe(
      map(([firmSettings, maxLengthRes]) => ({ ...firmSettings, maxLength: maxLengthRes.maxLength })),
    );
  }

  loadFirmAssignmentData() {
    return this.loadFirmSettings().subscribe((res) => {
      this.firmAssignmentDataBS.next(res);
    });
  }

  loadFirmType(): Observable<FirmType> {
    return this.http.get<FirmType>(this.url, this.options.getAuthorizationHeaderWithEmptyBody());
  }

  setFirmAssignmentData(val: FirmSettings): void {
    this.firmAssignmentDataBS.next(val);
  }

  updateFirmType(firm: FirmType): Observable<FirmType> {
    return this.http.put<FirmType>(this.url, firm, this.options.getAuthorizationHeader());
  }

  updateFirm(firmData: Firm): Observable<Firm> {
    return this.http.put<Firm>(this.url, firmData, this.options.getAuthorizationHeader());
  }

  updateFirmSettings(firmSettings: FirmSettings): Observable<FirmSettings> {
    const url = `${this.url}/settings`;
    return this.http.put<FirmSettings>(url, firmSettings, this.options.getAuthorizationHeader());
  }

  updateFirmBlAgentStatus(state: string) {
    const url = `${this.url}/settings/use_old_bl_agent/${state}`;
    return this.http.put(url, '', this.options.getAuthorizationHeaderWithEmptyBody());
  }

  resetFirmSettings(): Observable<FirmSettings> {
    const url = `${this.url}/settings`;
    return this.http.delete<FirmSettings>(url, this.options.getAuthorizationHeaderWithEmptyBody());
  }

  resetFirmTasks(firmId: number): Observable<{}> {
    const url = `${this.url}/${firmId}/tasks`;
    return this.http
      .delete(url, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  resetFirm(firmId: number): Observable<{}> {
    const url = `${this.url}/${firmId}`;
    return this.http
      .delete(url, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  getKeyFromBackend(body: any): Observable<any> {
    const url = `${environment.serverUrl}informationlogistics/values`;
    return this.http
      .post(url, body, this.options.getAuthorizationHeaderWithResponseType('text'))
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  activateSustainabilityReporting(firmId: number, body: ActivateSustainabilityReporting): Observable<void> {
    const url = `${this.url}/${firmId}/sustainabilityreporting`;
    return this.http.post<void>(url, body, this.options.getAuthorizationHeaderWithResponseType('text'));
  }

  private getFirmSettings(): Observable<FirmSettings> {
    const url = `${this.url}/settings`;
    return this.http.get<FirmSettings>(url, this.options.getAuthorizationHeaderWithEmptyBody());
  }

  private getMaxLengthForAssignmentValues(): Observable<{ maxLength: number }> {
    const url = `${this.url}/settings/maxLength`;
    return this.http.get<{ maxLength: number }>(url, this.options.getAuthorizationHeaderWithEmptyBody());
  }
}
