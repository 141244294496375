<app-please-wait [hidden]="hidePleaseWait"></app-please-wait>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="flex w-full">
    <div class="flex flex-col mr-1 w-full">
      <label for="type">Typ</label>
      <p-dropdown
        id="type"
        [options]="types"
        formControlName="type"
        optionLabel="description"
        appendTo="body"
        styleClass="w-full"></p-dropdown>
    </div>
    <div class="flex flex-col mr-1 w-full">
      <label for="assignment">Uppdrag</label>
      <p-dropdown
        id="assignment"
        [options]="assignments$ | async"
        formControlName="assignment"
        optionLabel="name"
        dataKey="id"
        appendTo="body"
        styleClass="w-full"></p-dropdown>
    </div>
    <div class="flex flex-col w-full">
      <label for="entryDate">Noteringsdatum</label>
      <app-calendar
        id="entryDate"
        formControlName="entryDate"
        [selectedDate]="form.controls.entryDate.value"
        [invalid]="form.controls.entryDate.enabled && !form.controls.entryDate.valid"
        [title]="form.controls.entryDate.errors?.entryDate.message"
        appendTo="body"
        styleClass="w-full"></app-calendar>
    </div>
  </div>
  <div class="flex flex-col w-2/3 mt-3">
    <label for="client">Klient</label>
    <p-dropdown
      id="client"
      [options]="clients$ | async"
      formControlName="client"
      optionLabel="name"
      dataKey="id"
      [filter]="true"
      filterBy="name"
      placeholder="- Välj -"
      appendTo="body"
      styleClass="w-full"
      [class]="form.errors?.requiredFields ? 'ng-invalid ng-dirty' : ''"
      [title]="form.errors?.requiredFields?.message"></p-dropdown>
  </div>
  <div class="flex flex-col w-full mt-3">
    <label for="text">Noteringstext</label>
    <textarea id="text" formControlName="text" pInputTextarea style="resize: none" rows="3" cols="35"></textarea>
  </div>
  <div class="flex mt-5">
    <p-checkbox
      [binary]="true"
      formControlName="todoChecked"
      label="Lägg upp en Att Göra-post">
    </p-checkbox>
  </div>
  <div class="flex w-full mt-4">
    <div class="flex flex-col mr-1 w-full">
      <label for="users">Utförande handläggare</label>
      <app-user-multi-selector
        id="users"
        scrollHeight="240px"
        [selector]="selector"
        formControlName="userIds"
        [class]="form.controls.todoChecked.value && form.errors?.requiredFields ? 'ng-invalid ng-dirty' : ''"
        [title]="form.errors?.requiredFields?.message"></app-user-multi-selector>
    </div>
    <div class="flex flex-col mr-1 w-full">
      <label for="deadline">Deadline</label>
      <app-calendar
        id="deadline"
        formControlName="deadline"
        [selectedDate]="form.controls.deadline.value"
        [invalid]="form.controls.deadline.enabled && !form.controls.deadline.valid"
        [title]="form.controls.deadline.errors?.deadline.message"
        appendTo="body"
        styleClass="w-full"></app-calendar>
    </div>
    <div class="flex flex-col w-full">
      <label for="state">Status</label>
      <p-dropdown
        id="state"
        [options]="states"
        formControlName="state"
        optionLabel="description"
        styleClass="w-full"
        appendTo="body"></p-dropdown>
    </div>
  </div>
  <div class="flex w-full justify-end mt-10">
    <button
      type="submit"
      class="btn btn-success"
      style="display: inline"
      [disabled]="!form.valid"
      [title]="formErrors">
      Spara
    </button>
    <button type="button" class="btn btn-warning ml-3" (click)="close({ todo: null })">Stäng</button>
  </div>
</form>