import { createReducer, on } from '@ngrx/store';
import { UserType } from '../types';
import { UserActions } from './users.actions';

export interface UsersState {
  users: UserType[];
  error: any;
}

export const initialUsersState: UsersState = {
  users: [],
  error: undefined,
};

export const usersReducer = createReducer(
  initialUsersState,
  on(UserActions.loadAllUsersSucceeded, (state, { users }) => ({ ...state, users, error: undefined })),
  on(UserActions.loadAllUsersFailed, (state, { error }) => ({ ...state, error })),

  on(UserActions.saveUserSucceeded, (state) => ({ ...state, error: undefined })),
  on(UserActions.saveUserFailed, (state, { error }) => ({ ...state, users: [...state.users], error }))
);
