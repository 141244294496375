import { createSelector } from '@ngrx/store';
import { AppState } from '../appState';

export enum NavigationPages {
  clients = '/clients',
  todo = '/todo',
  collaborationPackages = '/collaborationpackages',
}

const navigation = (state: AppState) => state.navigation;

const currentUrl = createSelector(navigation, (state) => state.url);

export const NavigationSelectors = {
  currentUrl,
};
