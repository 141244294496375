import { Actions, concatLatestFrom, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { NavigationPages, NavigationSelectors } from '../navigation/navigation.selectors';
import { AppState } from '../appState';
import { CoreActions } from './core.actions';
import { LoadCompositesActionType, UpdateActivityPayload } from '../composite/composite.actions';

export class BaseEffects {
  protected takeUntilNavigationStart = <T>(): MonoTypeOperatorFunction<T> => {
    return takeUntil(this.actions$.pipe(ofType(CoreActions.navigationStart)));
  };

  protected whenCurrentUrl = <T>(navigation: NavigationPages): MonoTypeOperatorFunction<T> => {
    return pipe(
      concatLatestFrom(() => this.store.select(NavigationSelectors.currentUrl)),
      filter(([_, currentUrl]) => currentUrl === navigation),
      map(([data, _]) => data),
    );
  };

  protected whenOnCompositeUrl = <
    T extends LoadCompositesActionType | UpdateActivityPayload,
  >(): MonoTypeOperatorFunction<T> => {
    return pipe(
      concatLatestFrom(() => this.store.select(NavigationSelectors.currentUrl)),
      filter(([{ compositeType }, currentUrl]) => `/${compositeType}` === currentUrl),
      map(([action, _]) => action),
    );
  };

  constructor(protected actions$: Actions, protected store: Store<AppState>) {}
}
