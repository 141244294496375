import { createReducer, on } from '@ngrx/store';
import { ActivityStateType } from '../types';
import { ActivityActions } from './activities.actions';

export interface ActivityState {
  activityStates: ActivityStateType[];
  activityStatesError: any;
}

export const initialActivityState: ActivityState = { activityStates: [], activityStatesError: undefined };

export const activitiesReducer = createReducer(
  initialActivityState,
  on(ActivityActions.loadAllActivityStates, (_state) => initialActivityState),
  on(ActivityActions.loadAllActivityStatesSucceeded, (_state, { states }) => ({
    ...initialActivityState,
    activityStates: states,
  })),
  on(ActivityActions.allActivityStatesFailed, (_state, { error }) => ({
    ...initialActivityState,
    activityStatesError: error,
  }))
);
