import { createSelector } from '@ngrx/store';
import { ListSelectors } from '../list/list.selectors';
import { isAllCompositeColumnTypeObjectsDone } from '../shared/shared';
import { CompositeBaseType, CompositeListFilter, CompositeListParams } from '../types';

const allFilteredComposites = <
  Type extends CompositeBaseType,
  Filter extends CompositeListFilter,
  Params extends CompositeListParams
>() =>
  createSelector(ListSelectors.getItems<Type, Filter, Params>(), ({ items, filter: { includeAllDoneClients } }) => {
    const composites = items.filter((composite) => {
      return includeAllDoneClients || !isAllCompositeColumnTypeObjectsDone(composite);
    });
    const count = items.filter((composite) => isAllCompositeColumnTypeObjectsDone(composite)).length;
    return { composites, count };
  });

export const CompositeSelectors = {
  allFilteredComposites,
};
