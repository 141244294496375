import { Injectable } from '@angular/core';

export interface FeatureObject {
  title: string;
  type: number;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  constructor() {}
  featureObject: Record<string, { title: string; type: number; description: string }> = {
    // ?
    // Type 1 = Tips
    // Type 2 = Varning
    1: {
      title: 'Förbjudet lån',
      type: 2,
      description: `Saldot på ägarnas avräkningskonto är negativt.
      Det kan innebära att det finns ett förbjudet lån. Kontrollera bokföringen på avräkningskontot.`,
    },
    2: {
      title: 'Ingen pensionskostnad',
      type: 2,
      description: `Det finns lönekostnader bokförda i företaget, men inga pensionskostnader.
      Bör företaget se till att de anställda har en pensionslösning?`,
    },
    6: {
      title: 'Sena kundfakturor',
      type: 2,
      description: `Det finns kundfakturor som har förfallit till betalning. Kontrollera kundreskontran`,
    },
    12: {
      title: 'Minnesgåva till anställd',
      type: 1,
      description: `Det finns anställda som fyller 50 eller 60 år och därmed finns det möjlighet att ge dessa en minnesgåva.`,
    },
    13: {
      title: 'Löneuttag huvudregeln K10',
      type: 1,
      description: `Kontrollera löneuttaget för delägarna så att de uppfyller lönekravet(om möjligt).`,
    },
    28: {
      title: 'Negativ kassa',
      type: 2,
      description: `Saldot på konto 1910-1919 har ett negativt saldo. Kontrollera bokföringen.`,
    },
    29: {
      title: 'Kontrollbalansläge?',
      type: 2,
      description: `Enligt bokföringen är det totala egna kapitalet mindre än halva aktiekapitalet.
       Kontrollera om kunden är i kontrollbalansläge. Summan av konto 2081-2099 samt årets resultat och 79,4% av obeskattade reserver är mindre än hälften av aktiekapitalet bokfört på konto 2081.`,
    },
    33: {
      title: 'Varningslistad leverantör',
      type: 2,
      description: `Företaget har fått en faktura från ett företag som är med på Svensk Handels varningslista. Företaget heter: `,
    },
    34: {
      title: 'OBS-konto',
      type: 2,
      description: `Det finns transaktioner bokförda på observationskonto 2999. Kontrollera bokföringen.`,
    },

    35: {
      title: 'Underskott på skattekonto',
      type: 2,
      description: `Det bokförda saldot på skattekontot är negativt. Kontrollera om det behöver åtgärdas`,
    },

    36: {
      title: 'Kassalikviditet',
      type: 2,
      description: `Företaget har en låg kassalikviditet (lägre än 100%) och kan därmed få problem att betala sina kortfristiga skulder.`,
    },
    104: {
      title: 'Avvikande leverantörsfakt.',
      type: 2,
      description: `En leverantörsfaktura som företaget fått avviker betydligt från tidigare fakturor.`,
    },
  };

  getFeaturesOfTypeWarning() {
    // returns all the features of type 2
    const keys = Object.keys(this.featureObject);
    return keys.filter((key) => this.featureObject[key].type === 2);
  }

  getFeatureOfTypeAdvice() {
    // returns all the features of type 1
    const keys = Object.keys(this.featureObject);
    return keys.filter((key) => this.featureObject[key].type === 1);
  }

  getCorrectFeatureInfo(featureNumber: number): FeatureObject {
    return this.featureObject[featureNumber];
  }
}
