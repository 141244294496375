<div pFocusTrap>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <p class="mb-6"><span class="font-bold">Företag:</span> {{ form.controls.name.value }}</p>
    <p class="font-bold">Redovisningsmetod</p>
    <div class="mb-6 flex">
      <div *ngFor="let method of accountingMethods" class="flex mr-6">
        <div class="mr-2">
          <p-radioButton
            [inputId]="method.key"
            [value]="method.key"
            name="accountingMethod"
            formControlName="accountingMethod"
            [class]="form.controls.accountingMethod.invalid ? 'ng-invalid ng-dirty' : ''"
          ></p-radioButton>
        </div>
        <div>
          <label [for]="method.key">
            {{ method.name }}
          </label>
        </div>
      </div>
    </div>

    <div class="mb-6">
      <p class="font-bold">Momsredovisning</p>
      <div class="flex">
        <div *ngFor="let method of vatAccountingIntervals" class="flex mr-6">
          <div class="mr-2">
            <p-radioButton
              [inputId]="method.key"
              [value]="method.key"
              name="vatAccountingInterval"
              formControlName="vatAccountingInterval"
              [class]="form.controls.vatAccountingInterval.invalid ? 'ng-invalid ng-dirty' : ''"
            ></p-radioButton>
          </div>
          <div>
            <label [for]="method.key">
              {{ method.name }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div formGroupName="financialYear">
      <p class="font-bold">Räkenskapsår</p>
      <div class="w-full grid grid-cols-2 gap-1">
        <div class="flex items-center">
          <span class="mr-1">Från:</span>
          <app-calendar
            formControlName="start"
            appendTo="body"
            [defaultDate]="null"
            [invalid]="form.controls.financialYear.controls.start.invalid">
          </app-calendar>
        </div>
        <div class="flex items-center">
          <span class="mr-1">Till:</span>
          <app-calendar
            formControlName="end"
            appendTo="body"
            [defaultDate]="null"
            [invalid]="form.controls.financialYear.controls.end.invalid">
          </app-calendar>
        </div>
      </div>      
    </div>

    <div class="flex justify-end mt-4">
      <button type="submit" class="btn btn-success mr-1" [disabled]="!isFormValid" [title]="submitTitle">Fortsätt</button>
      <button type="button" class="btn btn-warning" (click)="close(null)">Avbryt</button>
    </div>
  </form>
</div>
