<p-dialog
  [header]="dialogHeaderText$ | async"
  [modal]="true"
  [dismissableMask]="true"
  [visible]="visible$ | async"
  [style]="{ width: '730px' }"
  [draggable]="false"
  [closable]="false"
  showEffect="fade"
  (visibleChange)="onCancelClick()"
  (onHide)="onHideDialog()">
  <ng-container [ngTemplateOutlet]="stepTemplate$ | async"></ng-container>

  <p-footer>
    <ng-container [ngTemplateOutlet]="footerStepTemplate$ | async"></ng-container>
  </p-footer>
</p-dialog>

<ng-template #step1>
  <div [formGroup]="form">
    <label class="block text-bl-grey" for="name">Namn</label>
    <input pInputText type="text" name="name" formControlName="name" class="w-full" placeholder="Fyll i namn på team" />

    <label class="block text-bl-grey mt-5" for="name"
      >Välj medarbetare
      <i
        class="fal fa-question-circle fa-lg text-bl-blue-light-500 px-2 cursor-help"
        pTooltip="Administratörer visas inte i listan, de har alltid rättighet till teamet"></i>
    </label>
    <app-user-multi-selector
      formControlName="users"
      [selector]="usersWithoutAdmin"
      emptyMessage="Inga byråmedarbetare hittades"></app-user-multi-selector>
  </div>
</ng-template>

<ng-template #step2Clients>
  <div [formGroup]="form">
    <app-team-client-list formControlName="clients"></app-team-client-list>
  </div>
</ng-template>

<ng-template #step2Tasks>
  <div [formGroup]="form">
    <app-team-task-list formControlName="tasks"></app-team-task-list>
  </div>
</ng-template>

<ng-template #footerStep1>
  <button type="button" class="btn btn-warning" (click)="onCancelClick()">Avbryt</button>
  <button type="button" class="btn btn-success" (click)="onNextStepClick()" [disabled]="form.invalid">Fortsätt</button>
</ng-template>

<ng-template #footerStep2>
  <button type="button" class="btn btn-warning" (click)="onPreviousStepClick()">Tillbaka</button>
  <button type="button" class="btn btn-success" (click)="onConfirmClick()" [disabled]="form.invalid">
    Lägg till team
  </button>
</ng-template>
