import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, mergeMap, switchMap } from 'rxjs';
import { CreditInformationService } from '@app/core/services/credit-information.service';
import { CreditInformationActions } from './credit-information.actions';
import { ToastActions } from '../toast/toast.actions';
import { CoreActions } from '../actions';

@Injectable()
export class CreditInformationEffects {
  loadCreditInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreditInformationActions.load),
      switchMap(({ corporateIdentity }) =>
        this.creditInformationService.getCreditInformation(corporateIdentity).pipe(
          mergeMap((creditInformation) => [
            CreditInformationActions.loadSucceeded({ creditInformation }),
            !creditInformation
              ? ToastActions.showWarnMessage({
                  summary: 'Information',
                  detail: `Ingen information hittades på organisationsnr: ${corporateIdentity}`,
                })
              : CoreActions.noAction(),
          ]),
          catchError((error: unknown) =>
            from([
              CreditInformationActions.loadFailed({ error }),
              ToastActions.showErrorMessage({
                summary: 'Något gick fel!',
                detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
                error: `Det gick inte hämta information för orgnr:${corporateIdentity} Error: ${error}`,
              }),
            ]),
          ),
        ),
      ),
    ),
  );

  // eslint-disable-next-line no-useless-constructor
  constructor(private actions$: Actions, private creditInformationService: CreditInformationService) {}
}
