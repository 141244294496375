import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PartnerFirm } from '@app/core/state/types/partner.types';

@Component({
  selector: 'app-partnerprogram-profile-card',
  template: `
    <div
      class="flex bg-white p-6 border border-bl-grey-200 rounded-lg shadow-sm relative"
      [ngClass]="{ 'flex-row': !column, 'flex-col': column, 'h-full': fullHeight }">
      <div *ngIf="header">
        <p class="text-lg font-semibold text-bl-blue-light-800 truncate mb-0 pr-2">{{ header }}</p>
      </div>
      <div
        class="flex"
        [ngClass]="{
          'flex-row': !column,
          'items-center': !column,
          'flex-col': column,
        }">
        <ng-content></ng-content>
      </div>
      <div class="absolute bottom-0 left-0 mb-6 ml-6">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerProgramProfileCardComponent {
  @Input() level: number;
  @Input() data: PartnerFirm[];
  @Input() header: string;
  @Input() column = true;
  @Input() fullHeight = true;
}
