<form [formGroup]="form" (ngSubmit)="onSubmit()" pFocusTrap>
  <div class="flex w-full py-2 flex-wrap">
    <div class="flex flex-col mr-4">
      <label for="startDiff" title="Antal kalenderdagar innan deadline">Visa (dagar)</label>
      <p-inputNumber formControlName="startDiff" inputId="startDiff" [min]="1" [max]="350" pAutoFocus [autofocus]="true" [size]="5">
      </p-inputNumber>
    </div>
    <div class="flex flex-col mr-5">
      <label for="reminderDiff" title="Antal kalenderdagar innan deadline">Påminn (dagar)</label>
      <p-inputNumber formControlName="reminderDiff" inputId="reminderDiff" [min]="1" [max]="350" [size]="5">
      </p-inputNumber>
    </div>
    <div class="flex flex-col mr-6">
      <label for="warningDiff" title="Antal kalenderdagar innan deadline">Varna (dagar)</label>
      <p-inputNumber formControlName="warningDiff" inputId="warningDiff" [min]="1" [max]="350" [size]="5">
      </p-inputNumber>
    </div>
    <div class="flex flex-col flex-1">
      <label for="preferredTemplate">Vald för import/standard</label>
      <p-checkbox [formControl]="form.controls.preferredTemplate" [binary]="true" inputId="preferredTemplate" [title]="form.controls.preferredTemplate.disabled ? 'Går inte att redigera import för tjänst i en mallgrupp':''"></p-checkbox>
    </div>
  </div>
  <div class="flex w-full py-2">
    <div class="flex flex-col w-1/4">
      <label for="periodicity">Periodicitet</label>
      <p-dropdown
        formControlName="periodicity"
        inputId="periodicity"
        [autoDisplayFirst]="false"
        [options]="periodicities"
        optionLabel="description"
        dataKey="name"
        styleClass="w-5/6"
        appendTo="body"
        [autoOptionFocus]="false"
        [autoDisplayFirst]="false"
      ></p-dropdown>
    </div>
  </div>
  <div class="flex w-full py-2 flex-wrap" formGroupName="deadlineRule">
    <div class="flex flex-col w-2/4">
      <label for="deadlineTemplate">Regel för deadline</label>
      <p-dropdown
        formControlName="template"
        inputId="deadlineTemplate"
        [autoDisplayFirst]="false"
        [options]="rules$ | async"
        optionLabel="text"
        optionValue="code"
        styleClass="w-5/6"
        appendTo="body"
        [scrollHeight]="scrollHeight"
        [autoOptionFocus]="false"
        [autoDisplayFirst]="false"
      ></p-dropdown>
    </div>
    <div class="flex flex-col w-1/4">
      <label for="deadlineParam">
        Regelvärde
        {{
          form.controls.deadlineRule.controls.template.value === 'SDL'
            ? ''
            : form.controls.deadlineRule.controls.paramType.value
        }}
      </label>
      <div class="w-5/6">
        <app-deadline-value
          [type]="form.controls.deadlineRule.controls.paramType.value"
          [code]="form.controls.deadlineRule.controls.template.value"
          [value]="form.controls.deadlineRule.controls.param.value"
          inputId="deadlineParam"
          (valueChange)="onDeadlineValueChanged($event)"
          (valueValidated)="onDeadlineValidated($event)"
        ></app-deadline-value>
      </div>
    </div>
  </div>
  <div class="flex w-full py-2 flex-wrap">
    <div class="flex flex-col w-2/4">
      <label for="responsibleUsers" title="Dessa medarbetare är normalt ansvariga för mallens uppdrag och tjänst">
        Utförande handläggare
      </label>
      <div class="w-5/6">
        <app-user-multi-selector
          [selector]="responsibleUserSelector"
          formControlName="userIds"
          inputId="responsibleUsers"
          [invalid]="form.errors?.users?.message"
          [title]="form.errors?.users?.message || ''"
        ></app-user-multi-selector>
      </div>
    </div>
    <div class="flex flex-col w-2/4">
      <label for="states" title="Mallen kan ha följande statusar">Valbara status</label>
      <div class="w-5/6">
        <app-activity-states-multi-selector
          formControlName="selectableStates"
          inputId="states"
          [invalid]="form.errors?.selectableStates?.message"
          [title]="form.errors?.selectableStates?.message || ''"
        ></app-activity-states-multi-selector>
      </div>
    </div>
  </div>
  <div class="flex w-full pt-6 justify-between">
    <div>
      <button type="button" class="btn btn-primary" (click)="onArchivedClicked()">
        <ng-container *ngIf="form.controls.archived.value">
          <i class="fa fa-recycle mr-2"></i>
          <span>Återaktivera</span>
        </ng-container>
        <ng-container *ngIf="!form.controls.archived.value">
          <i class="fa-trash-alt far mr-2"></i>
          <span>Inaktivera</span>
        </ng-container>
      </button>
    </div>
    <div>
      <button type="submit" class="btn btn-success mr-1.5" [disabled]="form.invalid">Spara</button>
      <button type="button" class="btn btn-warning" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</form>
