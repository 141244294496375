import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ClientActions } from '@app/core/state/clients/clients.actions';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { ClientType } from '@app/core/state/types';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import {
  DialogData,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { skipWhile } from 'rxjs';

interface Data extends DialogData {
  client: ClientType;
}

interface Result extends DialogResult {
  client: ClientType | null;
}

@Component({
  selector: 'app-archive-or-delete-client-dialog',
  template: `
    <div class="flex flex-row justify-between mt-6">
      <div>
        <bl-frontend-button text="Arkivera" brand="firmsupport" (click)="onArchive()"></bl-frontend-button>
      </div>
      <div>
        <bl-frontend-button
          class="mr-2"
          text="Avbryt"
          variant="secondary"
          (click)="close({ client: null })"></bl-frontend-button>
        <bl-frontend-button
          text="Ta bort permanent"
          variant="primary-negative"
          (click)="onDelete()"></bl-frontend-button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, BlFrontendButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveOrDeleteClientDialogComponent extends FormDialogBaseComponent<Data, Result> {
  constructor(protected override ref: DynamicDialogRef, protected override config: DynamicDialogConfig<Data>) {
    super(ref, config);
    this.config.header = `Vad vill du göra med ${this.config.data.client.name}?`;

    this.store
      .select(ClientSelectors.clientStateArchiving)
      .pipe(
        skipWhile((value) => !value),
        skipWhile((value) => value),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        const { client } = this.config.data;
        this.close({ client: { ...client, archived: true } });
      });

    this.store
      .select(ClientSelectors.clientStateDeleting)
      .pipe(
        skipWhile((value) => !value),
        skipWhile((value) => value),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        const { client } = this.config.data;
        this.close({ client: { ...client } });
      });
  }

  onDelete() {
    const { client } = this.config.data;
    this.store.dispatch(
      ClientActions.deleteClient({ client: { ...client }, predicate: (item) => item.id !== client.id }),
    );
  }

  onArchive() {
    const { client } = this.config.data;
    this.store.dispatch(
      ClientActions.archiveDeletableClient({
        client: { ...client, archived: true },
        predicate: (item) => item.id === client.id,
      }),
    );
  }

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<Data>, 'data'> {
    return {
      modal: true,
      dismissableMask: true,
      draggable: false,
      width: '800px',
    };
  }
}
