export class Span {
  public spanType = '';
  public spanLabel = '';
  public sameDay?: boolean;
  public end = '';
  public start = '';

  constructor(spanType?: string, spanLabel?: string, start?: string, end?: string) {
    this.spanType = spanType ? spanType : '';
    this.spanLabel = spanLabel ? spanLabel : '';
    this.start = start ? start : '';
    this.end = end ? end : '';
  }
}
