import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TodayDate } from '@app/shared/misc/today.date';
import { SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-month-selector',
  template: `
    <p-dropdown
      [options]="months$ | async"
      [ngModel]="month"
      (ngModelChange)="onChange($event)"
      optionValue="value"
      [styleClass]="styleClass"
      scrollHeight="auto"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false">
    </p-dropdown>
  `,
})
export class MonthsSelector {
  public months$: Observable<SelectItem<string>[]>;

  @Input() month: string;
  @Input() styleClass = 'min-w-24';
  @Output() monthChange: EventEmitter<string>;

  constructor() {
    this.months$ = of(new TodayDate().MONTHS.map((m) => ({ label: m, value: m })));
    this.monthChange = new EventEmitter();
  }

  onChange(month: string) {
    this.monthChange.emit(month);
  }
}
