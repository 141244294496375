import { createFeature, createReducer, on } from '@ngrx/store';
import { BenefitsDto, PartnerFirm, LevelsDto, PropertiesDto } from '../types/partner.types';
import { PartnerProgramActions } from './partnerprogram.actions';

export interface PartnerProgramState {
  firm: PartnerFirm | null;
  benefits: BenefitsDto | null;
  properties: PropertiesDto | null;
  levels: LevelsDto | null;
  error: unknown;
}

export const initialPartnerProgramState: PartnerProgramState = {
  firm: null,
  benefits: null,
  properties: null,
  levels: null,
  error: null,
};

export const partnerProgramFeature = createFeature({
  name: 'partnerProgram',
  reducer: createReducer(
    initialPartnerProgramState,
    on(PartnerProgramActions.loadSucceeded, (state, { data }) => ({
      ...state,
      error: null,
      // firm: { ...data, level: { ...data.level, value: 0 } }, // fallen out
      // firm: { ...data, level: { ...data.level, value: 1 }, units: 35 }, // level 1
      // firm: { ...data, level: { ...data.level, value: 2 }, units: 75 }, // level 2
      // firm: { ...data, level: { ...data.level, value: 3 }, units: 125 }, // level 3
      // firm: { ...data, level: { ...data.level, value: 4 }, units: 175 }, // level 4
      // firm: null // not partner
      firm: data,
    })),
    on(PartnerProgramActions.loadFailed, (state, { error }) => ({ ...state, error, data: {} })),
    on(PartnerProgramActions.loadBenefitsSucceeded, (state, { benefits }) => ({ ...state, error: null, benefits })),
    on(PartnerProgramActions.loadBenefitsFailed, (state, { error }) => ({ ...state, error, benefits: null })),
    on(PartnerProgramActions.loadPropertiesSucceeded, (state, { properties }) => ({
      ...state,
      error: null,
      properties,
    })),
    on(PartnerProgramActions.loadPropertiesFailed, (state, { error }) => ({ ...state, error, properties: null })),
    on(PartnerProgramActions.loadLevelsSucceeded, (state, { levels }) => ({ ...state, error: null, levels })),
    on(PartnerProgramActions.loadLevelsFailed, (state, { error }) => ({ ...state, error, levels: null })),
  ),
});
