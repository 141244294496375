import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TodoType } from '../types/todo.types';

export const TodoActions = createActionGroup({
  source: 'Todo',
  events: {
    'Save Todo': props<{ todo: TodoType }>(),
    'Save Todo Succeeded': props<{ todo: TodoType }>(),
    'Save Todo Failed': props<{ error: unknown }>(),
    'Increase Todo Count': emptyProps(),
  },
});
