import { RuleCode } from './ruleCode';
import { ValidationType } from './validationType';

export const deadlineTypes: Record<RuleCode, ValidationType> = {
  DAF: ValidationType.INTEGER,
  DAM: ValidationType.INTEGER,
  DAQ: ValidationType.INTEGER,
  DBA: ValidationType.INTEGER,
  DBM: ValidationType.INTEGER,
  DBQ: ValidationType.INTEGER,
  MAF: ValidationType.INTEGER,
  FDL: ValidationType.DATE,
  FDS: ValidationType.DATE,
  FDT: ValidationType.DATE,
  FIA: ValidationType.DATE,
  SDL: ValidationType.NONE, // Single Event
  SFF: ValidationType.UNSIGNED_INTEGER,
  SFS: ValidationType.UNSIGNED_INTEGER,
  SMA: ValidationType.UNSIGNED_INTEGER,
  SMF: ValidationType.UNSIGNED_INTEGER,
  SMS: ValidationType.UNSIGNED_INTEGER,
  SQF: ValidationType.UNSIGNED_INTEGER,
  SQS: ValidationType.UNSIGNED_INTEGER,
};
