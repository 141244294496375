import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientActions } from '@app/core/state/clients/clients.actions';
import { ClientType } from '@app/core/state/types';
import {
  DialogData,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { PleaseWaitComponent } from '@app/shared/components/please-wait/please-wait.component';
import { ofType } from '@ngrx/effects';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

interface Data extends DialogData {
  client: ClientType;
}

interface Result extends DialogResult {
  client: ClientType;
}

@Component({
  selector: 'app-activate-sustainability-reporting-dialog',
  template: `
    <div>
      <app-please-wait [hidden]="!deactivating"></app-please-wait>
      <p>Vill du avsluta hållbarhetsredovisning för {{ config.data.client.name }}?</p>
      <p>Allt du sparat hos Wellfish på denna klient kommer att raderas!</p>
      <div class="flex justify-end mt-4">
        <button type="button" (click)="onClose()" class="btn">Avbryt</button>
        <button type="submit" (click)="onDeactivate()" class="btn btn-primary">Avaktivera</button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PleaseWaitComponent],
})
export class DeactivateSustainabilityReportingDialog extends FormDialogBaseComponent<Data, Result> {
  deactivating = false;

  protected override onInitialized = () => {
    this.actions$
      .pipe(
        ofType(
          ClientActions.deactivateSustainabilityReportingSucceeded,
          ClientActions.deactivateSustainabilityReportingFailed,
        ),
        this.takeUntilDestroyed(),
      )
      .subscribe((action) => {
        this.deactivating = false;
        if (action.type === ClientActions.deactivateSustainabilityReportingFailed().type) {
          return;
        }

        this.close({ client: { ...this.config.data.client, sustainabilityReporting: false } });
      });
  };

  public onClose() {
    this.close({ client: this.config.data.client });
  }

  public onDeactivate() {
    this.deactivating = true;
    this.store.dispatch(ClientActions.deactivateSustainabilityReporting({ clientId: this.config.data.client.id }));
  }

  protected static override getDialogConfig(): Omit<DynamicDialogConfig<DialogData>, 'data'> {
    return {
      header: 'Avaktivering av hållbarhetsredovisning',
      modal: true,
      resizable: false,
      draggable: false,
      closable: false,
      closeOnEscape: false,
      width: '400px',
    };
  }
}
