<p>
  Nedan visas de företag som finns i Byråstöd (ej privatpersoner - enl grunduppgifterna) och som inte hittas bland
  molndatabaserna kopplat till ditt kundnummer (baserat på organisationsnummer).
</p>
<div class="font-bold mt-2 mb-6">
  Klicka på "Lägg till" på det företag som du önskar skapa en NY Molndatabas för i BL Administration.
</div>
<p-table
  [columns]="columns"
  [value]="clients$ | async"
  [rows]="50"
  [rowsPerPageOptions]="[25, 50, 100, 250, 1000]"
  [paginator]="true"
>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        <div class="my-3">{{ listMessage }}</div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        class="font-normal truncate"
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="col.sortDisabled"
      >
        {{ col.header }}
        <p-sortIcon
          *ngIf="!col.sortDisabled"
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        <ng-container
          *ngTemplateOutlet="
            col.field === 'action' ? colActionButton : colText;
            context: { rowData: rowData, col: col }
          "
        >
        </ng-container>

        <ng-template #colText let-rowData="rowData" let-col="col">
          {{ rowData[col.field] }}
        </ng-template>

        <ng-template #colActionButton let-rowData="rowData">
          <div class="text-center">
            <p-progressSpinner
              [style]="{ width: '20px', height: '20px' }"
              strokeWidth="6"
              *ngIf="rowData === selectedClientToExport && disableAddButtons"
            ></p-progressSpinner>

            <button
              *ngIf="!disableAddButtons || (rowData !== selectedClientToExport && disableAddButtons)"
              type="button"
              class="btn btn-info btn-small"
              [disabled]="disableAddButtons"
              (click)="onAddButton(rowData)"
            >
              Lägg till
            </button>
          </div>
        </ng-template>
      </td>
    </tr>
  </ng-template>
</p-table>
