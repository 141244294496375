import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BlFrontendBadgeComponent } from '@app/core/wrappers/bl-frontend-badge.component';

@Component({
  selector: 'app-team-badges',
  template: `
    <label class="block text-bl-grey mt-5">{{ label }}</label>
    <div [ngClass]="{ 'overflow-hidden': !isExpanded }">
      <bl-frontend-badge
        *ngFor="let option of visibleOptions"
        [text]="option[optionLabel]"
        variant="info"
        class="inline-flex p-0.5">
      </bl-frontend-badge>
    </div>
    <div *ngIf="showExpandElement" class="cursor-pointer" (click)="isExpanded = true">+ Visa alla</div>
  `,
  standalone: true,
  imports: [NgClass, NgFor, NgIf, BlFrontendBadgeComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamBadgesComponent {
  @Input() label: string;

  /**
   * Classname for rounded corners https://v2.tailwindcss.com/docs/border-radius#rounded-corners
   */
  @Input() roundedClass: string;

  /**
   * Name of the label field of an option.
   */
  @Input() optionLabel: string;
  @Input()
  set options(newOptions: Record<string, any>[]) {
    this.allOptions = newOptions ?? [];
    this.isExpanded = false;
  }

  public isExpanded: boolean;
  private allOptions: Record<string, any>[];

  get visibleOptions() {
    return this.isExpanded ? this.allOptions : this.allOptions?.slice(0, 10);
  }

  get showExpandElement() {
    return !this.isExpanded && this.allOptions.length > 10;
  }

  constructor() {
    this.allOptions = [];
    this.isExpanded = false;
  }
}
