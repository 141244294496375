<div class="flex items-end mb-4">
  <div class="mr-2">
    <app-team-multi-selector localStorageKey="advisory-tool"></app-team-multi-selector>
  </div>
  <div class="w-96">
    <input
      (input)="advisoryTable.filterGlobal($any($event.target).value, 'contains')"
      class="bl-input"
      placeholder="Sök"
      type="text"
    />
  </div>
</div>

<p-table
  #advisoryTable
  [columns]="columns"
  sortField="date"
  [sortOrder]="-1"
  [value]="tableData"
  [paginator]="true"
  [rows]="50"
  [rowsPerPageOptions]="[25, 50, 100, 250, 1000]"
  [rowHover]="true"
  styleClass="advisory-table"
>
  <ng-template *ngIf="tableData.length > 1" pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field">
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-client let-columns="columns">
    <tr
      [pTooltip]="client.notification.cleared_at ? 'Markerad som åtgärdad' : ''"
      [showDelay]="1000"
      [hideDelay]="200"
      [ngClass]="client.notification.cleared_at ? 'opacity-50' : ''"
    >
      <ng-container *ngFor="let col of columns; let i = index">
        <td (click)="onColumnClick(col.header, client)">
          <ng-container *ngIf="col.status">
            <td
              class="rounded px-2 py-1 truncate inline text-normal"
              [ngClass]="
                client.type === 2 ? 'bg-bl-red-200 text-bl-red-800' : 'bg-bl-blue-light-200 text-bl-blue-light-800'
              "
            >
              {{ client[col.field] }}
            </td>
          </ng-container>
          <ng-container *ngIf="!col.status">{{ client[col.field] }}</ng-container>
        </td>
      </ng-container>
    </tr>
  </ng-template>
</p-table>
