import {
  CLIENT_TYPE_LIMITED_COMPANY,
  CLIENT_TYPE_NON_PROFIT_ASSOCIATION,
  CLIENT_TYPE_PARTNERSHIP,
  CLIENT_TYPE_SOLE_PROPRIETORSHIP,
} from '@app/shared/misc/constants';
import { ClientType, NameDescriptionType } from '../types';
import { CreditInformationType } from '../types/credit-information.types';
import { Transformer } from './transformers';

const getAccountant = (info: CreditInformationType): string => {
  if (!Array.isArray(info.DIRECTORS)) {
    return '';
  }

  const accountant = info.DIRECTORS.find((d) => d.FUNCTION && d.FUNCTION.toLowerCase() === 'revisor');
  if (!accountant) {
    return '';
  }

  return accountant.NAME || '';
};

const typeMap: Record<string, string> = {
  'sole trader': CLIENT_TYPE_SOLE_PROPRIETORSHIP,
  'economic association': CLIENT_TYPE_LIMITED_COMPANY,
  'trading partnership': CLIENT_TYPE_PARTNERSHIP,
  'limited liability company': CLIENT_TYPE_LIMITED_COMPANY,
  foundation: CLIENT_TYPE_NON_PROFIT_ASSOCIATION,
  'tenant-owner association': CLIENT_TYPE_LIMITED_COMPANY,
  'community association': CLIENT_TYPE_NON_PROFIT_ASSOCIATION,
  'non-profit association': CLIENT_TYPE_NON_PROFIT_ASSOCIATION,
};

const getType = (info: CreditInformationType): NameDescriptionType => {
  const type = info.GETDATA_RESPONSE.COMPANY_TYPE_TEXT.toLowerCase();
  return { name: typeMap[type], description: '' };
};

const formatDate = (date: string): string =>
  // format date which is in format "19901020" so we slice it up and add "-" between year, month, day
  [date.slice(0, 4), date.slice(4, 6), date.slice(6)].join('-');

const getRegistrationDate = (info: CreditInformationType): string => {
  const incorpDate = String(info.GETDATA_RESPONSE.INCORPORATION_DATE);

  // check if the date exists and is "valid" length
  if (incorpDate.length !== 8) {
    return '';
  }

  // incorpDate is in format "19901020" so we slice it up and add "-" between year, month, day
  return formatDate(incorpDate);
};

export const getAccountPeriod = (info: CreditInformationType): { start: string; end: string } => {
  const accountPeriod: string = info.GETDATA_RESPONSE.ACCOUNT_PERIOD;
  const accountPeriodArray = accountPeriod.split('-');

  if (!Array.isArray(accountPeriodArray) || accountPeriodArray.length !== 2) {
    return null;
  }

  const [start, end] = accountPeriodArray;
  return { start: formatDate(start), end: formatDate(end) };
};

const decorateWithSignatory = (info: CreditInformationType, client: Partial<ClientType>): Partial<ClientType> => {
  const signatory = client.type.name === CLIENT_TYPE_SOLE_PROPRIETORSHIP ? info.GETDATA_RESPONSE.NAME : null;
  if (!signatory) {
    return client;
  }

  return { ...client, signatory };
};

export const toClientTypeFromCreditInformationTransformer: Transformer<CreditInformationType, Partial<ClientType>> = {
  transform: (creditInformation) => {
    const { GETDATA_RESPONSE, COMPANY_REGNAME } = creditInformation;

    if (!GETDATA_RESPONSE) {
      return null;
    }

    const type = getType(creditInformation);
    const companyName = COMPANY_REGNAME?.length ? COMPANY_REGNAME[0].NAME : GETDATA_RESPONSE.NAME;

    const client: Partial<ClientType> = {
      // grunduppgifter
      name: type.name === CLIENT_TYPE_SOLE_PROPRIETORSHIP ? companyName : GETDATA_RESPONSE.NAME,
      address: GETDATA_RESPONSE.ADDRESS,
      zipCode: String(GETDATA_RESPONSE.ZIPCODE ?? ''),
      city: GETDATA_RESPONSE.TOWN,
      phone: GETDATA_RESPONSE.TELEPHONE,
      email: GETDATA_RESPONSE.EMAIL_ADRESS,
      webpage: GETDATA_RESPONSE.WWW_ADRESS,
      residence: GETDATA_RESPONSE.COMMUNITY,
      type,

      // verksamhetsuppgifter
      businessArea: `${GETDATA_RESPONSE.BRANSCH}, ${GETDATA_RESPONSE.BRANSCH_TEXT}`,
      description: GETDATA_RESPONSE.ACTIVITY_TEXT,
      registrationDate: getRegistrationDate(creditInformation),
      accountant: getAccountant(creditInformation),
    };

    return decorateWithSignatory(creditInformation, client);
  },
};
