import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { TaskGroupsActions } from './task-groups.actions';
import { TaskGroupService } from '@app/core/services/task-group.service';

@Injectable()
export class TaskGroupsEffects {
  loadTaskGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskGroupsActions.load),
      switchMap(() =>
        this.taskGroupService.getTaskGroups().pipe(
          map((taskGroups) => TaskGroupsActions.loadSucceeded({ taskGroups })),
          catchError((error: unknown) => of(TaskGroupsActions.loadFailed({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private taskGroupService: TaskGroupService) {}
}
