import { Injectable } from '@angular/core';
import { BLService } from './bl.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../state/appState';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { environment } from 'src/environments/environment';
import { Observable, tap } from 'rxjs';
import { AmlEntity, CreateAmlEntry } from '@core/state/types/aml.types';
import { FirmActions } from '../state/firm/firm.actions';

@Injectable({ providedIn: 'root' })
export class AmlService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'aml';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  getAmlApiEmail(): Observable<{ email: string | null }> {
    return this.http
      .get<{ email: string }>(`${this.url}/integration`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  activateIntegration(data: { email: string; password: string }): Observable<void> {
    return this.http.post<void>(`${this.url}/integration`, data, this.options.getAuthorizationHeader()).pipe(
      tap(({ email }) => this.store.dispatch(FirmActions.verifyAmlConnectionSucceeded({ email }))),
      this.catchErrorAndShowMessage(),
    );
  }

  updateActiveIntegrationCredentials(data: { email: string; password: string }): Observable<void> {
    return this.http.put<void>(`${this.url}/integration`, data, this.options.getAuthorizationHeader()).pipe(
      tap(({ email }) => this.store.dispatch(FirmActions.verifyAmlConnectionSucceeded({ email }))),
      this.catchErrorAndShowMessage(),
    );
  }

  deactivateIntegration(): Observable<void> {
    return this.http.delete<void>(`${this.url}/integration`, this.options.getAuthorizationHeader()).pipe(
      tap(() => this.store.dispatch(FirmActions.verifyAmlConnectionSucceeded({ email: null }))),
      this.catchErrorAndShowMessage(),
    );
  }

  createAmlForClient(clientId: number): Observable<AmlEntity> {
    return this.http
      .post<AmlEntity>(`${this.url}/${clientId}`, null, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  createManualAmlForClient(clientId: number, data: CreateAmlEntry): Observable<AmlEntity> {
    return this.http
      .post<AmlEntity>(`${this.url}/${clientId}/manual`, data, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  attachExistingAmlForClient(clientId: number, amlId: string): Observable<AmlEntity> {
    return this.http
      .post<AmlEntity>(`${this.url}/${clientId}/attach`, { amlId }, this.options.getAuthorizationHeader())
      .pipe(this.catchErrorAndShowMessage());
  }

  loadAmlDataForClient(clientId: number): Observable<AmlEntity[]> {
    return this.http
      .get<AmlEntity[]>(`${this.url}/${clientId}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }

  deleteAmlEntity(id: number): Observable<void> {
    return this.http
      .delete<void>(`${this.url}/${id}`, this.options.getAuthorizationHeaderWithEmptyBody())
      .pipe(this.catchErrorAndShowMessage());
  }
}
