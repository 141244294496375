import { AlertLevelNames } from '../entity/alert.level';

export class StatusColor {
  public static getColorByAlertName(alertLevelName: string): string {
    switch (alertLevelName) {
      case AlertLevelNames.WARNING:
        return 'orangered';
      case AlertLevelNames.REMINDER:
        return 'orange';
      case AlertLevelNames.OVERDUE:
        return 'red';
      case AlertLevelNames.COMPLETED:
        return 'green';
      case AlertLevelNames.STARTED:
        return 'blue';
      case AlertLevelNames.INACTIVE:
        return 'gray';
    }
    return 'black';
  }
}
