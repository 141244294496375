import { Type } from '@app/core/entity/type';
import { CLIENT_TYPE_LIMITED_COMPANY } from '@app/shared/misc/constants';

const includeNotification = (type: Type, feature: number): boolean => {
  if (isFeatureTwentyNine(feature) && !isLimitedCompany(type)) {
    return false;
  }

  return true;
};

const isLimitedCompany = (type: Type): boolean => {
  return type?.name === CLIENT_TYPE_LIMITED_COMPANY;
};

const isFeatureTwentyNine = (feature: number): boolean => {
  return feature === 29;
};

export const AdvisoryToolFilters = {
  includeNotification,
};
