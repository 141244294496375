import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@app/core/state/appState';
import { PartnerProgramActions } from '@app/core/state/partnerprogram/partnerprogram.actions';
import { PartnerProgramSelectors } from '@app/core/state/partnerprogram/partnerprogram.selectors';
import { BenefitsDto, LevelDto, PartnerFirm } from '@app/core/state/types/partner.types';
import { Store } from '@ngrx/store';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  templateUrl: './partnerprogram.component.html',
})
export class PartnerProgramComponent implements OnInit, OnDestroy {
  level: LevelDto | null = null;
  firm: PartnerFirm | null = null;
  levels: LevelDto[] = [];
  benefits: BenefitsDto | null = null;
  activeIndex = 0;
  private onDestroySub: Subject<void>;

  constructor(private store: Store<AppState>) {
    this.onDestroySub = new Subject();
    this.store
      .select(PartnerProgramSelectors.firm)
      .pipe(
        filter((data) => Boolean(data)),
        takeUntil(this.onDestroySub),
      )
      .subscribe((firm) => {
        this.level = firm.level;
        this.firm = firm;
      });

    this.store
      .select(PartnerProgramSelectors.benefits)
      .pipe(takeUntil(this.onDestroySub))
      .subscribe((data) => {
        this.benefits = data;
      });
    this.store
      .select(PartnerProgramSelectors.levels)
      .pipe(
        filter((l) => Boolean(l)),
        takeUntil(this.onDestroySub),
      )
      .subscribe((data) => {
        this.levels = data?.levels ?? [];
      });
  }

  ngOnInit(): void {
    this.store.dispatch(PartnerProgramActions.load());
    this.store.dispatch(PartnerProgramActions.loadBenefits());
    this.store.dispatch(PartnerProgramActions.loadProperties());
    this.store.dispatch(PartnerProgramActions.loadLevels());
  }

  ngOnDestroy(): void {
    this.onDestroySub.next();
    this.onDestroySub.complete();
  }
}
