import { ApplicationLinksGroup, ApplicationType } from '@app/shared/misc/application-link-builders/types';
import { createSelector } from '@ngrx/store';
import { LoadingStatus } from '../types';
import { AppState } from '../appState';

const filevault = (state: AppState) => state.fileVault;

const isAppFile = (year: number, type: ApplicationType) => (file: string) => {
  const application = type === ApplicationType.BLSkatt ? 'bls_' : type === ApplicationType.BLBokslut ? 'blb_' : '';
  const separator = type === ApplicationType.BL ? '.' : '_';
  const regex = `^${application}${year}(${separator}\\d\\d?${separator}\\d+)?\\.exe$`;
  const match = file.match(new RegExp(regex, 'gi'));
  if (!match) {
    return false;
  }

  return true;
};

const isLoading = createSelector(filevault, ({ status }) => status === LoadingStatus.Loading);
const downloadStatus = createSelector(filevault, ({ downloadStatus }) => downloadStatus);

const getApplicationLinksWithDownloads = (groups: ApplicationLinksGroup[]) =>
  createSelector(filevault, ({ files, error }) => {
    if (error) {
      return groups;
    }

    for (const group of groups) {
      for (const link of group.links) {
        const { year, type } = link;
        const filteredFiles = files.filter(isAppFile(year, type)).sort();
        if (!filteredFiles.length) {
          continue;
        }

        const fileName = filteredFiles.slice(-1);
        link.download = fileName[0];
      }
    }

    return groups;
  });

export const FileVaultSelectors = {
  getApplicationLinksWithDownloads,
  isLoading,
  downloadStatus,
};
