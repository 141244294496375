import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AssignmentType } from '../types';

export const AssignmentActions = createActionGroup({
  source: 'Assignment',
  events: {
    'Load All Assignments': emptyProps(),
    'Load Assignments Succeeded': props<{ assignments: AssignmentType[] }>(),
    'Load Assignments Failed': props<{ error: any }>(),
  },
});
