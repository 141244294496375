import { Firm } from './firm';

export class AssignmentAgreement {
  firm: Firm;
  client: {
    name: string;
    corporateIdentity: string;
    address: string;
    zipCode: string;
    city: string;
    phone: string;
    email: string;
    clientContact: string;
  };

  tasks: Array<{
    assignmentName: string;
    type: string;
    periodicity: {
      description: string;
      name: string;
    };
  }>;

  applying_conditions: string;
  notice_period: string;
  material_delivery: {
    material_delivery1: string;
    material_delivery2: string;
    material_delivery3: string;
    material_delivery4: string;
  };

  payment: {
    payment_text1: string;
    payment_text2: string;
    payment_text3: string;
    payment_text4: string;
  };

  payment_condition: {
    payment_condition_text1: string;
    payment_condition_text2: string;
    payment_condition_text3: string;
    payment_condition_text4: string;
  };

  other_condition_text: string;
  other_terms_text: string;
  attachments: {
    attachment_text1: string;
    attachment_text2: string;
    attachment_text3: string;
  };

  signatures: {
    city: string;
    date: string;
    client_contact: any;
    firm_contact: any;
  };
}
