import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, audit, filter, first, interval, map, mergeMap, scan, switchMap } from 'rxjs';
import { MessagingService } from '@app/core/services/messaging.service';
import { Store } from '@ngrx/store';
import { isAdmin } from '@app/core/misc/isAdmin';
import { UserService } from '@app/core/services/user.service';
import { logToConsole } from '@app/core/misc/rxjs-operators';
import { MessagingMessage } from '../types';
import { MessagingActions } from './messaging.actions';
import { MessageIds, NewClientOnboardedMessage } from './messaging.ids';
import { MessagingSelectors } from './messaging.selectors';
import { ToastActions } from '../toast/toast.actions';
import { AppState } from '../appState';
import { Action } from '@ngrx/store/src/models';
import { showTodoDialog } from '@app/shared/misc/showTodoDialog';
import { DialogService } from 'primeng/dynamicdialog';
import { NotesService } from '@app/core/services/notes.service';
import { TodoActions } from '../todo/todo.actions';
import { BLAPP_PROVIDER_KEY } from './messaging.constants';
import { ClientSelectors } from '../clients/clients.selectors';
import { ClientActions } from '../clients/clients.actions';
import { ListActions } from '../list/list.actions';

const sampleInterval = 1 * 60 * 1000;

@Injectable()
export class MessagingEffects {
  processMessages$ = createEffect(() => this.messagingService.messages$.pipe(mergeMap((m) => this.handleMessage(m))));

  connecting$ = createEffect(
    () => this.actions$.pipe(ofType(MessagingActions.connecting), logToConsole('Messaging: Connecting...')),
    { dispatch: false },
  );

  connected$ = createEffect(
    () => this.actions$.pipe(ofType(MessagingActions.connected), logToConsole('Messaging: Connected!')),
    { dispatch: false },
  );

  disconnected$ = createEffect(
    () => this.actions$.pipe(ofType(MessagingActions.disconnected), logToConsole('Messaging: Disconnected!')),
    { dispatch: false },
  );

  subscribing$ = createEffect(
    () => this.actions$.pipe(ofType(MessagingActions.subscribing), logToConsole('Messaging: Subscribing...')),
    { dispatch: false },
  );

  subscribed$ = createEffect(
    () => this.actions$.pipe(ofType(MessagingActions.subscribed), logToConsole('Messaging: Subscribed!')),
    { dispatch: false },
  );

  unsubscribed$ = createEffect(
    () => this.actions$.pipe(ofType(MessagingActions.unsubscribed), logToConsole('Messaging: Unsubscribed!')),
    { dispatch: false },
  );

  errors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.error),
      scan((acc, ctx) => ({ count: acc.count + 1, ctx }), { count: 0, ctx: {} }),
      audit((data) => (data.count === 1 ? interval(0) : interval(sampleInterval))),
      map((data) => data.ctx),
      logToConsole('Messaging: Error', true),
      map(() =>
        ToastActions.showWarnMessage({
          detail: 'Lyckades inte skapa kontakt med meddelandetjänsten',
          summary: 'Meddelandetjänst',
        }),
      ),
    ),
  );

  private handleMessage = (message: MessagingMessage) => {
    if (!this.handlers[message.data.messageId]) {
      return EMPTY;
    }

    return this.handlers[message.data.messageId](message);
  };

  private handleUserWithoutAssignedTeamMessage = (message: MessagingMessage) =>
    this.store.select(MessagingSelectors.firmAndUserSelector).pipe(
      first(),
      filter((props) => Boolean(props)),
      filter(({ user }) => user.id !== message.data.userId && isAdmin(user)),
      switchMap(() => this.userService.getUser(message.data.userId)),
      map((user) =>
        ToastActions.showWarnMessage({
          summary: 'En medarbetare som inte har tilldelats ett team loggade precis in',
          detail: `${user.initials} - ${user.name}`,
          sticky: true,
          life: 60 * 60 * 24 * 1000,
        }),
      ),
    );

  private handleUserReceivedTodoMessage = (message: MessagingMessage<{ authorId: number; noteId: number }>) =>
    this.store.select(MessagingSelectors.firmAndUserSelector).pipe(
      first(),
      filter((props) => Boolean(props)),
      filter(({ user }) => user.id === message.data.userId),
      switchMap(() => this.userService.getUser(message.data.props.authorId)),
      mergeMap((user) => [
        TodoActions.increaseTodoCount(),
        ToastActions.showInfoMessage({
          summary: 'Du har fått en ny Att-Göra-post.',
          detail: `Den är upplagd av ${user.name}.`,
          sticky: true,
          life: 60 * 60 * 24 * 1000,
          button: {
            text: 'Öppna Att-Göra-post',
            click: () =>
              showTodoDialog({
                dialogService: this.dialogService,
                notesService: this.notesService,
                id: message.data.props.noteId,
              }).subscribe(),
          },
        }),
      ]),
    );

  private handleUserReceivedUpdatedTodoMessage = (message: MessagingMessage<{ authorId: number; noteId: number }>) =>
    this.store.select(MessagingSelectors.firmAndUserSelector).pipe(
      first(),
      filter((props) => Boolean(props)),
      filter(({ user }) => user.id === message.data.userId),
      switchMap(() => this.userService.getUser(message.data.props.authorId)),
      map((user) =>
        ToastActions.showInfoMessage({
          summary: 'En av dina Att göra-poster har ändrats',
          detail: `${user.name} har ändrat något i en av dina Att göra-poster`,
          sticky: true,
          life: 60 * 60 * 24 * 1000,
          button: {
            text: 'Öppna Att-Göra-post',
            click: () =>
              showTodoDialog({
                dialogService: this.dialogService,
                notesService: this.notesService,
                id: message.data.props.noteId,
              }).subscribe(),
          },
        }),
      ),
    );

  private handleClientOnboardedMessage = (message: MessagingMessage<NewClientOnboardedMessage>) => {
    if (message.data.props.providerKey !== BLAPP_PROVIDER_KEY) {
      return EMPTY;
    }

    return this.store.select(ClientSelectors.allClients).pipe(
      first(),
      mergeMap((all) => {
        const client = all.find((c) => c.id === message.data.props.clientId);
        if (!client || client.archived) {
          return EMPTY;
        }

        const cloudApiKey = message.data.props.publicKey;
        const item = { ...client, cloudApiKey };
        const actions = [
          ToastActions.showInfoMessage({
            summary: `Ett företag har kopplats med paketering`,
            detail: `${client.name} är nu ett företag med paketering`,
          }),
          ClientActions.updateCludApiKey({ id: client.id, cloudApiKey }),
        ];
        const allActions = window.location.pathname.includes('clients')
          ? [
              ...actions,
              ListActions.updateItemInList({ item }),
              ListActions.updateItemInListSucceeded({ item, predicate: (i) => i.id === item.id }),
            ]
          : actions;
        return allActions;
      }),
    );
  };

  private handlers: Record<string, (message: MessagingMessage) => Observable<Action>> = {
    [MessageIds.USER_WITHOUT_TEAM_LOGGED_IN]: this.handleUserWithoutAssignedTeamMessage,
    [MessageIds.USER_RECEIVED_TODO]: this.handleUserReceivedTodoMessage,
    [MessageIds.USER_TODO_UPDATED_BY_OTHER_USER]: this.handleUserReceivedUpdatedTodoMessage,
    [MessageIds.NEW_CLIENT_ONBOARDED]: this.handleClientOnboardedMessage,
  };

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private messagingService: MessagingService,
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private dialogService: DialogService,
    private notesService: NotesService,
  ) {}
}
