import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppState } from '@app/core/state/appState';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { RoleType } from '@app/core/state/types';
import { BlFrontendBadgeComponent } from '@app/core/wrappers/bl-frontend-badge.component';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { openDialog, openDialogWithConfig } from '@app/shared/misc/openDialog';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, combineLatest, filter, map, mergeMap, take } from 'rxjs';
import { ActivateIntegrationDialogComponent } from './activate-integration-dialog.component';
import { AmlService } from '@app/core/services/aml.service';
import { ToastActions } from '@app/core/state/toast/toast.actions';
import { DeactivateIntegrationDialogComponent } from './deactivate-integration-dialog.component';

type StateType = { activeIntegration: boolean; isAdminUser: boolean };

@Component({
  selector: 'app-due-compliance-activation-box',
  templateUrl: './due-compliance-activation-box.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    BlFrontendButtonComponent,
    BlFrontendBadgeComponent,
    TooltipModule,
    ActivateIntegrationDialogComponent,
  ],
})
export class DueComplianceActivationBoxComponent {
  state$: Observable<StateType>;

  constructor(private store: Store<AppState>, private dialogService: DialogService, private amlService: AmlService) {
    this.state$ = this.createStateObservable();
  }

  showActivateIntegrationDialog() {
    this.openActivateIntegrationDialog('Aktivera integration mot Due PTL')
      .pipe(mergeMap((response) => this.amlService.activateIntegration(response)))
      .subscribe({
        next: () => this.showActivationSuccessMessage(),
      });
  }

  showSettingsIntegrationDialog() {
    this.store
      .select(FirmSelectors.selectAmlApiEmail)
      .pipe(
        take(1),
        mergeMap((email) => this.openActivateIntegrationDialog('Uppdatera integration mot Due PTL', email)),
        mergeMap((response) => this.amlService.updateActiveIntegrationCredentials(response)),
      )
      .subscribe({
        next: () => this.showUpdatedActivationSuccessMessage(),
      });
  }

  private openActivateIntegrationDialog(header: string, email: string = null) {
    const config = {
      header,
      data: { email },
      width: '430px',
      height: 'auto',
      modal: true,
    };

    return openDialogWithConfig(this.dialogService, ActivateIntegrationDialogComponent, config).pipe(
      filter((response) => !!response),
    );
  }

  showDeactivateIntegrationDialog() {
    openDialog(this.dialogService, DeactivateIntegrationDialogComponent, null)
      .pipe(
        filter((response) => !!response),
        mergeMap(() => this.amlService.deactivateIntegration()),
      )
      .subscribe({
        next: () => this.showDeactivationSuccessMessage(),
      });
  }

  private createStateObservable(): Observable<StateType> {
    const hasActiveIntegration$ = this.store.select(FirmSelectors.selectHasActiveAmlConnection);

    const isAdminUser$ = this.store
      .select(AuthSelectors.selectAuthUser)
      .pipe(map((user) => user?.role === RoleType.FIRM_ADMIN));

    return combineLatest([hasActiveIntegration$, isAdminUser$]).pipe(
      map(([activeIntegration, isAdminUser]) => ({ activeIntegration, isAdminUser })),
    );
  }

  private showActivationSuccessMessage() {
    this.store.dispatch(ToastActions.showInfoMessage({ detail: '', summary: 'Aktiveringen lyckades.' }));
  }

  private showUpdatedActivationSuccessMessage() {
    this.store.dispatch(
      ToastActions.showInfoMessage({ detail: '', summary: 'Uppdatering av integrationen lyckades.' }),
    );
  }

  private showDeactivationSuccessMessage() {
    this.store.dispatch(
      ToastActions.showInfoMessage({
        detail: 'Kontakta Due Compliance på info@duecompliance.se om du önskar avsluta avtalet hos dem.',
        summary: 'Avaktiveringen lyckades!',
      }),
    );
  }
}
