import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { DialogModule } from 'primeng/dialog';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap, shareReplay, take, withLatestFrom } from 'rxjs/operators';
import { SimpleClientType, TaskType, TeamTypes } from '@app/core/state/types';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { TeamEditService } from '../../services/team-edit.service';
import { TeamClientListComponent } from '../team-client-list/team-client-list.component';
import { TeamTaskListComponent } from '../team-task-list/team-task-list.component';

@Component({
  selector: 'app-add-team-dialog',
  templateUrl: './add-team-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    BystSharedModule,
    TeamClientListComponent,
    TeamTaskListComponent,
  ],
  providers: [TeamEditService],
})
export class AddTeamDialogComponent {
  @ViewChild('step1') step1Template: TemplateRef<any>;
  @ViewChild('step2Clients') step2ClientsTemplate: TemplateRef<any>;
  @ViewChild('step2Tasks') step2TasksTemplate: TemplateRef<any>;
  @ViewChild('footerStep1', { static: true }) footerStep1Template: TemplateRef<any>;
  @ViewChild('footerStep2', { static: true }) footerStep2Template: TemplateRef<any>;

  visible$: Observable<boolean>;
  dialogHeaderText$: Observable<string>;
  form: FormGroup;
  stepTemplate$: Observable<TemplateRef<any>>;
  footerStepTemplate$: Observable<TemplateRef<any>>;

  usersWithoutAdmin = UserSelectors.activeUsersWithoutAllAndAdmin;

  private stepBS: BehaviorSubject<number>;

  constructor(private teamService: TeamEditService) {
    this.visible$ = this.teamService.getAddTeamDialogVisibility();
    this.stepBS = new BehaviorSubject<number>(1);
    this.form = this.createForm(this.stepBS.asObservable());

    const currenctStepAndTeamType$ = this.getCombinedStepAndTeamTypeObservable();
    this.dialogHeaderText$ = this.getDialogHeaderTextFromStep(currenctStepAndTeamType$);
    this.stepTemplate$ = this.getStepTemplate(currenctStepAndTeamType$);
    this.footerStepTemplate$ = this.getFooterStepTemplate(currenctStepAndTeamType$);
  }

  onNextStepClick() {
    this.stepBS.next(this.stepBS.value + 1);
  }

  onPreviousStepClick() {
    this.stepBS.next(this.stepBS.value - 1);
  }

  onConfirmClick() {
    const { clients, tasks, ...rest } = this.form.value;
    const clientIds = clients && clients.map((client: SimpleClientType) => client.id);
    const taskIds = tasks && tasks.map((task: TaskType) => task.id);

    this.teamService.addTeam({ ...rest, clients: clientIds, tasks: taskIds });
  }

  onCancelClick() {
    this.teamService.cancel();
  }

  onHideDialog() {
    this.form.reset();
    this.stepBS.next(1);
  }

  private getCombinedStepAndTeamTypeObservable(): Observable<{ step: number; teamType: TeamTypes }> {
    const selectedTeamType$ = this.teamService
      .getSelectedTeamType()
      .pipe(tap((teamType: TeamTypes) => this.updateTeamTypeOnForm(teamType)));

    const step$ = this.stepBS.asObservable();

    return combineLatest([selectedTeamType$, step$]).pipe(
      map(([teamType, step]) => ({ teamType, step })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private getDialogHeaderTextFromStep(
    combinedStepAndTeamType$: Observable<{ teamType: TeamTypes; step: number }>,
  ): Observable<string> {
    return combinedStepAndTeamType$.pipe(
      map(({ teamType, step }) => {
        if (step === 2 && teamType === TeamTypes.Client) return 'Välj klienter';
        if (step === 2 && teamType === TeamTypes.Task) return 'Välj tjänster';
        return 'Lägg till nytt team';
      }),
    );
  }

  private getStepTemplate(combinedStepAndTeamType$: Observable<{ teamType: TeamTypes; step: number }>) {
    return combinedStepAndTeamType$.pipe(map(({ teamType, step }) => this.getStepTemplateForTeamType(teamType, step)));
  }

  private updateTeamTypeOnForm(teamType: TeamTypes) {
    this.form.patchValue({ typeId: teamType }, { emitEvent: false });
  }

  private getStepTemplateForTeamType(teamType: TeamTypes, step: number) {
    if (step === 2 && teamType === TeamTypes.Client) return this.step2ClientsTemplate;
    if (step === 2 && teamType === TeamTypes.Task) return this.step2TasksTemplate;
    return this.step1Template;
  }

  private getFooterStepTemplate(combinedStepAndTeamType$: Observable<{ teamType: TeamTypes; step: number }>) {
    const getTemplate = (step: number) => (step === 1 ? this.footerStep1Template : this.footerStep2Template);
    return combinedStepAndTeamType$.pipe(map(({ step }) => getTemplate(step)));
  }

  private createForm(step$: Observable<number>) {
    const asyncRequiredIfStep2 =
      (teamType: TeamTypes) =>
      (formControl: AbstractControl): Observable<ValidationErrors> =>
        step$.pipe(
          withLatestFrom(this.teamService.getSelectedTeamType()),
          map(([step, selectedTeamType]) => {
            if (step === 2 && selectedTeamType === teamType) {
              return formControl?.value?.length > 0 ? null : { required: true };
            }
            return null;
          }),
          take(1),
        );

    return new UntypedFormGroup({
      typeId: new UntypedFormControl(null),
      name: new UntypedFormControl(null, Validators.required),
      users: new UntypedFormControl(null),
      clients: new UntypedFormControl(null, null, asyncRequiredIfStep2(TeamTypes.Client)),
      tasks: new UntypedFormControl(null, null, asyncRequiredIfStep2(TeamTypes.Task)),
    });
  }
}
