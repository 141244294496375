import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { FormDialogBaseComponent } from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  template: `
    <p>
      Är du säker att du vill ta bort inloggningsuppgifterna så att kopplingen mot Due PTL inte längre fungerar. Redan
      skapade kontroller ligger kvar i Byråstödet men går ej att öppna från Byråstöd.
    </p>
    <p>
      Kontakta Due Compliance på <a href="mailto:info@duecompliance.se">info&#64;duecompliance.se</a> om du önskar
      avsluta avtalet hos dem.
    </p>

    <div class="flex flex-row w-full justify-end mt-8">
      <bl-frontend-button type="button" variant="secondary" (click)="close(null)" text="Avbryt"></bl-frontend-button>
      <bl-frontend-button
        (onClick)="close(true)"
        variant="primary-negative"
        class="ml-2"
        text="Ja, avsluta integration"></bl-frontend-button>
    </div>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BlFrontendButtonComponent],
})
export class DeactivateIntegrationDialogComponent extends FormDialogBaseComponent<null, boolean> {
  protected static override getDialogConfig(): Omit<DynamicDialogConfig, 'data'> {
    return {
      header: 'Bekräfta borttag av koppling mot Due PTL',
      width: '530px',
      height: 'auto',
      modal: true,
    };
  }
}
