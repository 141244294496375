<div class="mb-2">
  <button type="button" class="btn btn-primary" (click)="showNewTodo()">Skapa ny Notering/Att göra-post</button>
</div>

<app-table
  [columns]="columns"
  [data]="clientTodos"
  [paginator]="false"
  scrollHeight="550px"
  [columnSelector]="false"
  [useFixedStyle]="true"
>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="default" let-content let-todo="rowData">
    <a (click)="showExistingTodo(todo)" class="text-black cursor-pointer">{{ content }}</a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="status" let-composite="rowData">
    <p-dropdown
      [options]="composite.selectableStates"
      [disabled]="isDisabledSelector(composite)"
      [ngModel]="composite.state"
      (ngModelChange)="changeStateAndSave($event, composite)"
      optionLabel="description"
      appendTo="body"
      [title]="isDisabledSelector(composite) ? 'Notis' : 'Välj status'"
      styleClass="w-full table-inline-dropdown"
      placeholder="- Välj -"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false">
    </p-dropdown>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="action" let-todo="rowData">
    <span *ngIf="!_client.archived" (click)="tryDeleteTodo(todo)" class="far fa-trash-alt cursor-pointer text-center">
    </span>
  </ng-template>

  <ng-template tableTemplate="emptyMessage"> Inga notiser / Att Göra finns att visa </ng-template>
  <ng-template tableTemplate="summary" let-data> Antal noteringar / Att Göra: {{ data.length }} </ng-template>
</app-table>

<p-dialog
  header="Ta bort Notering/Att Göra-post?"
  [(visible)]="_displayDeleteDialog"
  showEffect="fade"
  [closeOnEscape]="false"
  [closable]="false"
  [style]="{ width: '400px' }"
  [draggable]="false"
>
  <p>Posten kommer att tas bort permanent.</p>
  <p>Vill du fortsätta?</p>
  <p-footer>
    <button type="button" class="btn btn-success" (click)="afterDeleteDialog(true)">Ja</button>
    <button type="button" class="btn btn-warning" (click)="afterDeleteDialog(false)">Nej</button>
  </p-footer>
</p-dialog>
