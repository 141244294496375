import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';

@Component({
  selector: 'app-advisory-tool-onboarding',
  templateUrl: './advisory-tool-onboarding.component.html',
  styleUrls: ['./advisory-tool-onboarding.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BlFrontendButtonComponent],
})
export class AdvisoryToolOnboardingComponent {
  @Output() dontShow = new EventEmitter<boolean>();

  hideOnboardingGuide() {
    this.dontShow.emit(true);
  }
}
