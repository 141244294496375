import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { validateDateFn } from '@app/core/entity/validation';
import { FinancialYearType } from '@app/core/state/types';
import {
  DialogForm,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';

interface EditFinancialYearDialogForm extends DialogForm<any> {
  clientId: FormControl<number>;
  electronicCompilation: FormControl<boolean>;
  electronicTax: FormControl<boolean>;
  electronicVat: FormControl<boolean>;
  endDate: FormControl<string>;
  euCommerce: FormControl<boolean>;
  firmVatRespite: FormControl<boolean>;
  firmTaxRespite: FormControl<boolean>;
  id: FormControl<number>;
  largeCompany: FormControl<boolean>;
  span: FormGroup<SpanType>;
}

interface SpanType {
  spanType: FormControl<string>;
  spanLabel: FormControl<string>;
  end: FormControl<string>;
  start: FormControl<string>;
}

@Component({
  selector: 'app-edit-financialyear-dialog',
  templateUrl: './edit-financialyear-dialog.component.html',
})
export class EditFinancialYearDialogComponent extends FormDialogBaseComponent<
  FinancialYearType,
  FinancialYearType,
  EditFinancialYearDialogForm
> {
  protected override onInitialized = () => {
    const {
      clientId,
      electronicCompilation,
      electronicTax,
      electronicVat,
      endDate,
      euCommerce,
      firmVatRespite,
      firmTaxRespite,
      id,
      largeCompany,
      span,
    } = this.config.data;

    this.form = this.builder.group({
      clientId: new FormControl(clientId),
      electronicCompilation: new FormControl(electronicCompilation),
      electronicTax: new FormControl(electronicTax),
      electronicVat: new FormControl(electronicVat),
      endDate: new FormControl(endDate),
      euCommerce: new FormControl(euCommerce),
      firmVatRespite: new FormControl(firmVatRespite),
      firmTaxRespite: new FormControl(firmTaxRespite),
      id: new FormControl(id),
      largeCompany: new FormControl(largeCompany),
      span: new FormGroup({
        spanType: new FormControl(span.spanType),
        spanLabel: new FormControl(span.spanLabel),
        end: new FormControl(span.end, [validateDateFn({ key: 'end' })]),

        start: new FormControl(span.start, [validateDateFn({ key: 'end' })]),
      }),
    });
  };

  onSubmit() {
    const result: FinancialYearType = {
      clientId: this.form.value.clientId,
      electronicCompilation: this.form.value.electronicCompilation,
      electronicTax: this.form.value.electronicTax,
      electronicVat: this.form.value.electronicVat,
      endDate: this.form.value.endDate,
      euCommerce: this.form.value.euCommerce,
      firmVatRespite: this.form.value.firmVatRespite,
      firmTaxRespite: this.form.value.firmTaxRespite,
      largeCompany: this.form.value.largeCompany,
      id: this.form.value.id,
      span: this.form.value.span,
    };
    this.close(result);
  }
}
