import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from 'src/app/core/entity/client';
import { CloudDbData } from 'src/app/core/entity/cloud-db-data';
import { Task } from 'src/app/core/entity/task';
import { TaskService } from 'src/app/core/services/task.service';

@Injectable({ providedIn: 'root' })
export class WizardDefaultValueService {
  constructor(private taskService: TaskService) {}

  gatherDefaultValuesForWizardDialog(selectedClient: Client): Observable<CloudDbData> {
    const latestFinancialYear = selectedClient.financialYears.reduce(
      (acc, current) => {
        if (acc.end < current.span.end) {
          acc.start = current.span.start;
          acc.end = current.span.end;
        }
        return acc;
      },
      { start: '', end: '' }
    );

    return this.taskService.getTasksByClientId(selectedClient.id).pipe(
      map((tasks) => this.getDefaultVatAccountingIntervalValue(tasks)),
      map((defaultVatAccountingValue) => ({
        clientId: selectedClient.id,
        name: selectedClient.name,
        financialYear: latestFinancialYear,
        accountingMethod: '',
        vatAccountingInterval: defaultVatAccountingValue,
      }))
    );
  }

  private getDefaultVatAccountingIntervalValue(tasks: Task[]): string {
    const searchList = ['MOMD', 'BETG', 'MOMU'];
    const search = (array: Task[]) => {
      return (code: string) => array.find((item) => item.code === code);
    };
    const findTask = search(tasks.filter((task) => searchList.includes(task.code)));

    // find the periodicity name in the order of search list
    const bystPeriodicityName = searchList.reduce((accPeriodicityName, currentCode) => {
      const task = findTask(currentCode);
      if (task && task.periodicity && !accPeriodicityName) {
        return task.periodicity.name;
      }
      return accPeriodicityName;
    }, '');

    return this.castBystPeriodicityNameToCloudName((bystPeriodicityName || '').toUpperCase());
  }

  private castBystPeriodicityNameToCloudName(bystPeriodicityName: string): string {
    switch (bystPeriodicityName) {
      case 'STRICT_ANNUAL':
      case 'ANNUAL':
        return 'year';
      case 'QUARTER':
        return 'quarter';
      case 'MONTH':
        return 'month';
      default:
        return '';
    }
  }
}
