import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import {
  DialogData,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { TooltipModule } from 'primeng/tooltip';
import { AdvisoryNotification } from 'src/app/core/entity/advisory.notification';
import { DateConvertPipe } from '@app/advisory-tool/pipes/date-convert.pipe';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NotificationPayloadComponent } from '../notification-payload/notification-payload.component';

interface Data extends DialogData {
  advisoryNotification?: AdvisoryNotification;
}

interface Result extends DialogResult {
  url?: string;
}

@Component({
  selector: 'app-notification-modal',
  template: `
    <main class="font-sans relative mt-4">
      <div class="flex flex-row justify-between items-center border-b border-bl-blue-100">
        <p class="!text-xl !font-normal !text-bl-blue-500">
          {{ config.data?.advisoryNotification?.title }}
        </p>
      </div>
      <section class="text-bl-blue-500 mt-4">
        <p class="text-lg">
          {{ config.data?.advisoryNotification?.description }}
        </p>
        <app-notification-payload
          [notification]="config.data?.advisoryNotification.notification"></app-notification-payload>
        <p class="mt-12 text-lg italic" *ngIf="config.data?.advisoryNotification.notification.cleared_at">
          Åtgärdad av: {{ config.data?.advisoryNotification?.notification.cleared_by }}
          {{ config.data?.advisoryNotification?.notification.cleared_at | dateConvert }}
        </p>
      </section>
      <div class="flex justify-end">
        <button
          [pTooltip]="
            config.data?.advisoryNotification?.notification.cleared_at
              ? 'Tryck för att markera notisen som ej åtgärdad'
              : 'Tryck för att markera notisen som åtgärdad'
          "
          tooltipPosition="bottom"
          class="btn btn-success mr-1"
          (click)="markAsCleared()">
          <span *ngIf="!config.data?.advisoryNotification?.notification.cleared_at">Åtgärda</span>
          <span *ngIf="config.data?.advisoryNotification?.notification.cleared_at">Återställ</span>
        </button>
        <button class="btn btn-warning" (click)="onClose()">Avbryt</button>
      </div>
    </main>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, TooltipModule, NotificationPayloadComponent, DateConvertPipe],
})
export class NotificationModalComponent extends FormDialogBaseComponent<Data, Result> {
  protected static override getDialogConfig(): Omit<DynamicDialogConfig<Data>, 'data'> {
    return {
      showHeader: false,
      modal: true,
      closable: true,
      closeOnEscape: true,
      width: '50%',
      draggable: false,
      resizable: false,
      position: 'center',
    };
  }

  onClose() {
    this.close({});
  }

  markAsCleared() {
    this.close({ url: this.config.data?.advisoryNotification?.notification.url });
  }
}
