import { Injectable } from '@angular/core';
import { TeamService } from '@app/core/services/team.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AuthActions } from '../auth/auth.actions';
import { BaseEffects } from '../core/base.effects';
import { ToastActions } from '../toast/toast.actions';
import { TeamType } from '../types';
import { AppState } from '../appState';
import { UserActions } from '../users/users.actions';
import { TeamsActions } from './teams.actions';

@Injectable()
export class TeamsEffects extends BaseEffects {
  loadTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.loadAllTeams),
      switchMap(() =>
        this.teamService.getTeams().pipe(
          map((teams) => TeamsActions.loadTeamsSucceeded({ teams })),
          this.takeUntilNavigationStart(),
          catchError((error: unknown) => of(TeamsActions.loadTeamsFailed({ error }))),
        ),
      ),
    ),
  );

  addTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.addTeam),
      switchMap(({ team }) =>
        this.teamService.addTeam(team).pipe(
          mergeMap((addedTeam: TeamType) => [
            TeamsActions.addTeamSucceeded({ team: addedTeam }),
            AuthActions.reloadCurrentAuthUser(),
            UserActions.loadAllUsers(),
          ]),
          catchError((error: unknown) => of(TeamsActions.addTeamFailed({ error }))),
        ),
      ),
    ),
  );

  addTeamSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.addTeamSucceeded),
      map(({ team }) =>
        ToastActions.showInfoMessage({
          summary: 'Skapad',
          detail: `Teamet ${team.name} har skapats`,
        }),
      ),
    ),
  );

  updateTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.updateTeam),
      switchMap(({ team }) =>
        this.teamService.updateTeam(team).pipe(
          mergeMap((updatedTeam: TeamType) => [
            TeamsActions.updateTeamSucceeded({ team: updatedTeam }),
            UserActions.loadAllUsers(),
          ]),
          catchError((error: unknown) => of(TeamsActions.updateTeamFailed({ error }))),
        ),
      ),
    ),
  );

  updateTeamSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.updateTeamSucceeded),
      map(({ team }) =>
        ToastActions.showInfoMessage({
          summary: 'Uppdaterad',
          detail: `Teamet ${team.name} är uppdaterad`,
        }),
      ),
    ),
  );

  deleteTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.deleteTeam),
      switchMap(({ team }) =>
        this.teamService.deleteTeam(team).pipe(
          mergeMap(() => [
            TeamsActions.deleteTeamSucceeded({ team }),
            AuthActions.reloadCurrentAuthUser(),
            UserActions.loadAllUsers(),
          ]),
          catchError((error: unknown) => of(TeamsActions.deleteTeamFailed({ error }))),
        ),
      ),
    ),
  );

  deleteTeamSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeamsActions.deleteTeamSucceeded),
      map(({ team }) =>
        ToastActions.showInfoMessage({
          summary: 'Borttagen',
          detail: `Teamet ${team.name} är borttagen`,
        }),
      ),
    ),
  );

  constructor(actions$: Actions, store: Store<AppState>, private teamService: TeamService) {
    super(actions$, store);
  }
}
