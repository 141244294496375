import { Injectable } from '@angular/core';
import { ActivityService } from '@app/core/services/activity.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ActivityActions } from './activities.actions';
import { ToastActions } from '../toast/toast.actions';

@Injectable()
export class ActivityEffects {
  loadActivityStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.loadAllActivityStates),
      switchMap(() =>
        this.activityService.getActivityStatesTypes().pipe(
          map((states) => ActivityActions.loadAllActivityStatesSucceeded({ states })),
          catchError((error: unknown) => of(ActivityActions.allActivityStatesFailed({ error }))),
        ),
      ),
    ),
  );

  saveSingleActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.saveSingleActivity),
      switchMap(({ taskId, comment, date }) =>
        this.activityService
          .saveSingleActivity(date, taskId, comment)
          .pipe(map(() => ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Aktiviteten är sparad' }))),
      ),
    ),
  );

  constructor(private actions$: Actions, private activityService: ActivityService) {}
}
