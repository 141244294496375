const formatIdentity = (identity: string): string => {
  const removeDashAndSpace = (value: string) => value.replace(/[\s-]/g, '');
  const sliceToLastMax10Char = (value: string) => value.slice(-10);

  if (!identity) return '';
  return sliceToLastMax10Char(removeDashAndSpace(identity));
};

const sortByValueLength = (value1: string, value2: string) => {
  if (value1.length > value2.length) {
    return { shortValue: value2, longValue: value1 };
  }
  return { shortValue: value1, longValue: value2 };
};

const sliceToCharLengthFromStart = (numberOfChars: number, value: string) => value.slice(0, numberOfChars);
const sliceToCharLengthFromEnd = (numberOfLastChars: number, value: string) => value.slice(-numberOfLastChars);

const isMatchOnShortestValue = (value1: string, value2: string) => {
  const { shortValue, longValue } = sortByValueLength(value1, value2);
  const numberOfChars = shortValue.length;
  const identityFromStart = sliceToCharLengthFromStart(numberOfChars, longValue);
  const identityFromEnd = sliceToCharLengthFromEnd(numberOfChars, longValue);
  return identityFromStart === shortValue || identityFromEnd === shortValue;
};

/**
 *
 * @param identity1 First compare value
 * @param identity2 Second compare value
 * @param minMatchLength If it isn't a direct match, the length has to be larger than this to continue the matching. Default to 8
 * @returns Boolean
 */
export const isIdentityMatch = (identity1: string, identity2: string, minMatchLength: number = 8) => {
  const isLengthEqualGreaterThanMinMatchLength = (value: string) => value.length >= minMatchLength;

  const formattedIdentity1 = formatIdentity(identity1);
  const formattedIdentity2 = formatIdentity(identity2);

  if (!formattedIdentity1 || !formattedIdentity2) return false;
  if (formattedIdentity1 === formattedIdentity2) return true;

  // do not match if any of the values are less then X chars
  if (
    !isLengthEqualGreaterThanMinMatchLength(formattedIdentity1) ||
    !isLengthEqualGreaterThanMinMatchLength(formattedIdentity2)
  ) {
    return false;
  }

  // if different length, match on shortest value
  if (formattedIdentity1.length !== formattedIdentity2.length) {
    return isMatchOnShortestValue(formattedIdentity1, formattedIdentity2);
  }

  return false;
};
