import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CreateTaskGroupCommand, TaskGroupType, UpdateTaskGroupCommand } from '../types';

export const TaskGroupActions = createActionGroup({
  source: 'TaskGroup',
  events: {
    Create: props<{ cmd: CreateTaskGroupCommand }>(),
    'Create TaskGroup Succeeded': props<{ taskGroup: TaskGroupType }>(),
    Update: props<{ id: number; cmd: UpdateTaskGroupCommand }>(),
    'Update TaskGroup Succeeded': props<{ taskGroup: TaskGroupType }>(),
    Delete: props<{ id: number }>(),
    'Delete TaskGroup Succeeded': emptyProps(),
  },
});
