<div [formGroup]="formGroup" class="flex">
  <div class="mr-2">
    <p>Vilket uppdrag?</p>
    <p-dropdown
      [options]="assignments$ | async"
      placeholder="Välj uppdrag"
      optionLabel="name"
      optionValue="id"
      formControlName="assignmentId"
      [style]="{ width: '135px' }"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false"
    ></p-dropdown>
  </div>
  <div class="mr-2">
    <p>Vilken tjänst?</p>
    <p-dropdown
      [options]="availableTaskTemplates$ | async"
      placeholder="Välj tjänst"
      optionLabel="type"
      formControlName="task"
      [style]="{ width: '135px' }"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false"
    ></p-dropdown>
  </div>
  <div class="mr-2">
    <p>Periodicitet</p>
    <p-dropdown
      [autoDisplayFirst]="false"
      [options]="availablePeriodicities$ | async"
      optionLabel="description"
      formControlName="periodicity"
      placeholder="Välj periodicitet"
      [style]="{ width: '135px' }"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false"
    ></p-dropdown>
  </div>
  <div class="mr-2">
    <p>Regel för deadline</p>
    <p-dropdown
      [autoDisplayFirst]="false"
      [options]="availableRulesForSelectedPeriodicity$ | async"
      optionLabel="text"
      formControlName="rule"
      [style]="{ width: '135px' }"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false"
    ></p-dropdown>
  </div>
  <ng-container *ngIf="selectedRuleDeadline$ | async as deadlineInfo">
    <div *ngIf="deadlineInfo.visible" class="mr-2">
      <p>
        Värde <span class="text-xs">({{ deadlineInfo.paramType }})</span>
      </p>
      <app-deadline-value
        [type]="deadlineInfo.paramType"
        [value]="formGroup.value.deadlineParam"
        (valueChange)="onDeadlineValueChanged($event)"
        [code]="deadlineInfo.code"
        (valueValidated)="onDeadlineValidated($event)"
        [style]="{ width: '130px' }"
      ></app-deadline-value>
    </div>
  </ng-container>
  <div class="mr-2">
    <p>Startdatum</p>
    <app-calendar formControlName="startDate" [style]="{ width: '130px' }"></app-calendar>
  </div>
  <div>
    <p>Utförande handläggare</p>
    <app-user-multi-selector
      formControlName="userIds"
      placeholder="Välj användare"
      [selector]="availableUsersSelector"
      [breakpoint]="12"
    ></app-user-multi-selector>
  </div>
</div>
