import { TaskTemplateActions } from '@app/core/state/task-templates/task-templates.actions';
import { TaskType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { FirmTaskTemplateAskArchiveDialog } from './firm-task-template-ask-archive.dialog';

export const askArchiveTaskTemplate = (
  store: Store<AppState>,
  dialogService: DialogService,
  updatedTemplate: TaskType,
  onClose?: (template: TaskType) => void,
) => {
  if (updatedTemplate.archived) {
    const template: TaskType = { ...updatedTemplate, archived: false };
    store.dispatch(TaskTemplateActions.activate({ template }));
    onClose?.(template);
    return;
  }

  const ref = dialogService.open(FirmTaskTemplateAskArchiveDialog, {
    header: 'Vill du inaktivera denna tjänst?',
    draggable: false,
    resizable: false,
  });

  ref.onClose.subscribe((yesOrNo: boolean) => {
    if (!yesOrNo) {
      return;
    }

    const template: TaskType = { ...updatedTemplate, archived: true };
    store.dispatch(TaskTemplateActions.archive({ template }));
    onClose?.(template);
  });
};
