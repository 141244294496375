import { MAX_CALENDAR_DATE, MIN_CALENDAR_DATE } from './dates';

export function getMinMaxFromPattern(pattern: string): { min: number; max: number } {
  if (!pattern) {
    return { min: Number.NEGATIVE_INFINITY, max: Number.POSITIVE_INFINITY };
  }

  // number matcher
  const numRegEx = new RegExp(/^(\d+)-(\d+)$/g);
  const numMatches = numRegEx.exec(pattern.trim());
  if (numMatches?.length === 3) {
    const [_, min, max] = numMatches;
    const minAsNumber = Number(min);
    const maxAsNumber = Number(max);
    return { min: minAsNumber, max: maxAsNumber };
  }

  // date range matcher
  const dateRegEx = new RegExp(/^(\d{4}-\d{1,2}-\d{1,2})-(\d{4}-\d{1,2}-\d{1,2})$/g);
  const dateMatches = dateRegEx.exec(pattern.trim());
  if (dateMatches?.length === 3) {
    const [_, min, max] = dateMatches;
    const minAsNumber = new Date(min).getTime();
    const maxAsNumber = new Date(max).getTime();
    return { min: minAsNumber, max: maxAsNumber };
  }

  // presuming date min and max
  return { min: MIN_CALENDAR_DATE.getTime(), max: MAX_CALENDAR_DATE.getTime() };
}
