/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Todo } from '@app/core/entity/todo';
import { TodoType } from '../types/todo.types';
import { Assignment } from '@app/core/entity/assignment';
import { AssignmentType } from '../types';
import {
  Transformer,
  fromClientToSimpleClientTypeTransformer,
  fromUserToSimpleUserTypeTransformer,
  toNameDescriptionTransformer,
  toNameDescriptionTypeTransformer,
  toSimpleClientTransformer,
  toSimpleUserTransformer,
  toTaskTransformer,
  toTaskTypeTransformer,
} from './transformers';

export const toAssignmentTransformer: Transformer<AssignmentType, Assignment> = {
  transform: ({ id, label, name, rootTemplates, task }) => ({
    id,
    label,
    name,
    rootTemplates: rootTemplates?.map(toTaskTransformer.transform),
    task: task ? toTaskTransformer.transform(task) : null,
  }),
};

export const toTodoTransformer: Transformer<TodoType, Todo> = {
  transform: ({
    assignment,
    assignmentname,
    client,
    clientCustNbr,
    clientName,
    clientOrgNbr,
    clientRespName,
    creator,
    deadline,
    entryDate,
    id,
    scope,
    selectableScopes,
    selectableStates,
    selectableTypes,
    state,
    statename,
    text,
    tmpText,
    type,
    usernames,
    users,
  }) => ({
    assignment: assignment ? toAssignmentTransformer.transform(assignment) : null,
    assignmentname,
    client: client ? toSimpleClientTransformer.transform(client) : null,
    clientCustNbr,
    clientName,
    clientOrgNbr,
    clientRespName,
    creator: creator ? toSimpleUserTransformer.transform(creator) : null,
    deadline: deadline ?? null,
    entryDate: entryDate ?? null,
    id,
    scope,
    selectableScopes: selectableScopes?.slice(),
    selectableStates: selectableStates?.map(toNameDescriptionTransformer.transform),
    selectableTypes: selectableTypes?.map(toNameDescriptionTransformer.transform),
    state: state ? toNameDescriptionTransformer.transform(state) : null,
    statename,
    text,
    tmpText,
    type: toNameDescriptionTransformer.transform(type),
    usernames,
    users: users?.map(toSimpleUserTransformer.transform),
  }),
};

export const toAssignmentTypeTransformer: Transformer<Assignment, AssignmentType> = {
  transform: ({ id, label, name, rootTemplates, task }) => ({
    id,
    label,
    name,
    rootTemplates: rootTemplates?.map(toTaskTypeTransformer.transform),
    task: task ? toTaskTypeTransformer.transform(task) : null,
  }),
};

export const toTodoTypeTransformer: Transformer<Todo, TodoType> = {
  transform: ({
    assignment,
    assignmentname,
    client,
    clientCustNbr,
    clientName,
    clientOrgNbr,
    clientRespName,
    creator,
    deadline,
    entryDate,
    id,
    scope,
    selectableScopes,
    selectableStates,
    selectableTypes,
    state,
    statename,
    text,
    tmpText,
    type,
    usernames,
    users,
  }) => ({
    assignment: assignment ? toAssignmentTypeTransformer.transform(assignment) : null,
    assignmentname,
    client: client ? fromClientToSimpleClientTypeTransformer.transform(client) : null,
    clientCustNbr,
    clientName,
    clientOrgNbr,
    clientRespName,
    creator: creator ? fromUserToSimpleUserTypeTransformer.transform(creator) : null,
    deadline: deadline ?? null,
    entryDate: entryDate ?? null,
    id,
    scope,
    selectableScopes: selectableScopes?.slice(),
    selectableStates: selectableStates?.map(toNameDescriptionTypeTransformer.transform),
    selectableTypes: selectableTypes?.map(toNameDescriptionTypeTransformer.transform),
    state: state ? toNameDescriptionTypeTransformer.transform(state) : null,
    statename,
    text,
    tmpText,
    type: toNameDescriptionTypeTransformer.transform(type),
    usernames,
    users: users?.map(fromUserToSimpleUserTypeTransformer.transform),
  }),
};
