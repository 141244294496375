/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { EMPTY, OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastActions } from '../state/toast/toast.actions';
import { ToastMessageType } from '../state/types';
import serviceErrorMessages from './service-errors-messages';
import { chainHandler, createChainHandler } from './chain-handler';

const DEFAULT_ERROR_SUMMARY = 'Ett fel har uppstått';
const DEFAULT_ERROR_DETAIL = 'Tryck F5 för att ladda om applikationen. Om felet kvarstår, kontakta Support.';

export interface ServiceException {
  className: string;
  exception: string;
  lineNumber: number;
  message: string;
  methodName: string;
}

export type ErrorMessageType = Pick<ToastMessageType, 'summary' | 'detail'>;
type AfterMessageProcessorCallback = (message: ErrorMessageType) => ErrorMessageType;

type CatchErrorConfig = {
  store: Store;
  afterErrorMessageProcessor?: AfterMessageProcessorCallback;
};

class IgnorableError extends Error {}

class ToastError extends Error {
  constructor(public toastMessage: ErrorMessageType) {
    super();
  }
}

// ------
// Error handlers

const defaultErrorHandler = createChainHandler(
  (): ToastError => new ToastError({ summary: DEFAULT_ERROR_SUMMARY, detail: DEFAULT_ERROR_DETAIL }),
);

const handleUnauthorizedError = createChainHandler((error: unknown): IgnorableError | null => {
  if (error instanceof HttpErrorResponse && error.status === 401) {
    return new IgnorableError();
  }
  return null;
});

// Error handler for mapping HTTP error message codes to error messages
const handleHttpErrorsWithErrorCode = createChainHandler((error: unknown): ToastError | null => {
  if (!(error instanceof HttpErrorResponse)) {
    return null;
  }

  const serviceException = error.error as ServiceException;
  const detail = serviceErrorMessages[serviceException?.message];
  if (detail) {
    return new ToastError({ summary: DEFAULT_ERROR_SUMMARY, detail });
  }
  return null;
});

// Error handler for mapping HTTP error messages to error messages
const handleHttpErrorsWithErrorMessage = createChainHandler((error: unknown): ToastError | null => {
  if (!(error instanceof HttpErrorResponse)) {
    return null;
  }

  const serviceException = error.error as ServiceException;
  if (!serviceException?.message) {
    return null;
  }
  return new ToastError({
    summary: `${DEFAULT_ERROR_SUMMARY} - ${serviceException.message}`,
    detail: DEFAULT_ERROR_DETAIL,
  });
});

// Not sure if this is needed, if it's ever used, but it's here for now.
const handleErrorWithJsonBody = createChainHandler((error: unknown): ToastError | null => {
  // eslint-disable-next-line no-underscore-dangle
  if (!(error as any)?._body) {
    return null;
  }

  try {
    // eslint-disable-next-line no-underscore-dangle
    const body = JSON.parse((error as any)._body);
    return new ToastError({
      summary: `${DEFAULT_ERROR_SUMMARY} - ${body?.message ?? ''}`,
      detail: DEFAULT_ERROR_DETAIL,
    });
  } catch (e) {
    return null;
  }
});

// Error handlers sorted by priority
const errorHandlers = [
  handleUnauthorizedError,
  handleHttpErrorsWithErrorCode,
  handleHttpErrorsWithErrorMessage,
  handleErrorWithJsonBody,
  defaultErrorHandler,
];
const errorChainHandler = chainHandler(errorHandlers);

export const catchErrorAndShowMessage = ({
  store,
  afterErrorMessageProcessor = (message) => message,
}: CatchErrorConfig): OperatorFunction<any, any> =>
  catchError((error: unknown) => {
    const result: Error | null = errorChainHandler.handle(error);

    if (result instanceof IgnorableError) {
      return EMPTY;
    }

    if (result instanceof ToastError) {
      store.dispatch(ToastActions.showErrorMessage(afterErrorMessageProcessor(result.toastMessage)));
    }

    return throwError(() => error);
  });
