<app-table
  #table
  storageKey="incometaxdeclarationListTable"
  [columns]="columns"
  [data]="list$ | async"
  [rowsPerPage]="defaultNumberOfRows"
  sortField="client"
  exportFilename="inkomstdeklaration"
  (pageChange)="paginatorTriggered($event)"
>
  <app-table-filter>
    <app-team-multi-selector localStorageKey="incometaxdeclaration" (OnTeamsInit)="refreshList()"></app-team-multi-selector>
    <app-table-filter-item label="Klientansvarig">
      <app-user-selector [(userId)]="selectedClientResponsibleUserId" styleClass="min-w-48"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item label="Utförande handläggare">
      <app-user-selector [(userId)]="selectedTaskUserId" styleClass="min-w-48"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item label="Deklarationsperiod">
      <div class="flex">
        <app-multiselect-dropdown-including-all
          allValue="ALL"
          [options]="[
            { label: 'P1', value: 'P1' },
            { label: 'P2', value: 'P2' },
            { label: 'P3', value: 'P3' },
            { label: 'P4', value: 'P4' },
            { label: 'P4INK1', value: 'P4INK1' }
          ]"
          [(selected)]="selectedPeriods"
          optionLabel="label"
          optionValue="value"
          selectedItemLabel="perioder"
        ></app-multiselect-dropdown-including-all>
        <app-year-selector [(year)]="selectedYear" styleClass="min-w-24 ml-1"></app-year-selector>
      </div>
    </app-table-filter-item>
    <app-table-filter-item>
      <app-refresh-button [loading]="loading$ | async" (onClick)="refreshList()"></app-refresh-button>
    </app-table-filter-item>
    <app-table-filter-item>
      <p-checkbox
        [binary]="true"
        [(ngModel)]="includeAllDoneClients"
        (ngModelChange)="filterList()"
        [label]="includeFinishedLabel$ | async"
      ></p-checkbox>
    </app-table-filter-item>
  </app-table-filter>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="client" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">{{ client.client }}</a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="corporateIdentity" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">
      {{ client.corporateIdentity }}
    </a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="customerNumber" let-client="rowData">
    <a (click)="onShowExistingClient(client.clientId)" class="cursor-pointer text-black">
      {{ client.customerNumber }}
    </a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="firmTaxRespite" let-firmTaxRespite>
    <div class="text-center">
      <i [ngClass]="{ 'fa fa-times': firmTaxRespite }" [style.color]="'gray'" aria-hidden="true"></i>
    </div>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="electronicTax" let-electronicTax>
    <div class="text-center">
      <i [ngClass]="{ 'fa fa-times': electronicTax }" [style.color]="'gray'" aria-hidden="true"></i>
    </div>
  </ng-template>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="declarationMaterial.materialSortValue" let-data="rowData">
    <div
      class="cursor-pointer text-center truncate"
      (click)="showMtrlTask(data)"
      [style.color]="data.materialStatusColor"
    >
      <app-status-icon
        [status]="data.declarationMaterial.materialReceivedAlertLevel.name"
        [color]="data.materialStatusColor"
        [title]="data.declarationMaterial.materialReceivedAlertLevel.description"
      ></app-status-icon>
      {{ data.declarationMaterial.materialReceivedAt }}
    </div>
  </ng-template>

  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="uppi.activity.alertLevel.description"
    let-activity="rowData.uppi"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template
    tableTemplate="bodyCell"
    tableBodyCellField="uppb.activity.alertLevel.description"
    let-activity="rowData.uppb"
  >
    <ng-container *ngTemplateOutlet="defaultCompositeColumn; context: { $implicit: activity }"></ng-container>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="program" let-data="rowData">
    <div class="text-center">
      <a [href]="getAgentUrl(data)"><img src="../../assets/bls20.png" title="Starta programmet" /></a>
    </div>
  </ng-template>

  <ng-template tableTemplate="emptyMessage">{{ statusText$ | async }}</ng-template>

  <ng-template tableTemplate="summary" let-data>
    <div class="relative">
      Antal aktuella klienter: {{ data.length }}
      <div class="absolute export-button-wrapper">
        <p-button
          icon="pi pi-download"
          class="export-button"
          size="small"
          label="Exportera lista"
          (click)="table.exportCSV()"
          title="Ladda ner listan för import till Excel"
        ></p-button>
      </div>
    </div>
  </ng-template>

  <ng-template #defaultCompositeColumn let-activity>
    <div
      class="text-center cursor-pointer truncate"
      (click)="onShowActivity(activity)"
      [style.color]="activity.activity.stateColor"
    >
      <app-activity-comment-icon [title]="activity.activity.comment"></app-activity-comment-icon>
      <app-task-comment-icon [title]="activity.task.comment"></app-task-comment-icon>
      <app-status-icon
        [status]="activity.activity.alertLevel.name"
        [color]="activity.activity.stateColor"
        [title]="activity.activity.alertLevel.description"
      ></app-status-icon>
      {{ activity.activity.state.description }}
    </div>
  </ng-template>
</app-table>
