import { Injectable } from '@angular/core';
import { AdvisoryToolService } from '@app/advisory-tool/services/advisory-tool.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ClientService } from '../../services/clients.service';
import { BaseEffects } from '../core/base.effects';
import { ListActions } from '../list/list.actions';
import { NavigationPages } from '../navigation/navigation.selectors';
import { ToastActions } from '../toast/toast.actions';
import { AppState } from '../appState';
import { ClientActions } from './clients.actions';
import { TeamsActions } from '../teams/teams.actions';

@Injectable()
export class ClientEffects extends BaseEffects {
  loadClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadAllClients),
      switchMap(() =>
        this.clientService.getAllClientTypes().pipe(
          map((clients) => ClientActions.loadAllClientsSucceeded({ clients })),
          this.takeUntilNavigationStart(),
          catchError((error: unknown) => of(ClientActions.loadAllClientsFailed({ error }))),
        ),
      ),
    ),
  );

  loadClientsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadClientList),
      this.whenCurrentUrl(NavigationPages.clients),
      filter(({ filter }) => Boolean(filter)),
      map(({ filter }) => ListActions.load({ filter })),
    ),
  );

  loadClientsLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadClientList),
      this.whenCurrentUrl(NavigationPages.clients),
      switchMap(() =>
        this.clientService.getAllFilteredClientTypes().pipe(
          map((clients) => ClientActions.loadClientsListSucceeded({ clients })),
          this.takeUntilNavigationStart(),
          catchError((error: unknown) => of(ClientActions.loadClientsListFailed({ error }))),
        ),
      ),
    ),
  );

  loadClientsListSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadClientsListSucceeded),
      this.whenCurrentUrl(NavigationPages.clients),
      map(({ clients }) => ListActions.loadSucceeded({ items: clients })),
    ),
  );

  loadClientsListFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.loadClientsListFailed),
      this.whenCurrentUrl(NavigationPages.clients),
      map(({ error }) => ListActions.loadFailed({ error })),
    ),
  );

  reactivateClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.reactivateClient),
      switchMap(({ client, predicate }) =>
        this.clientService.upsertClientType(client).pipe(
          mergeMap(() => [
            ClientActions.reactivateClientSucceeded({ client }),
            ListActions.updateItemInListSucceeded({ item: client, predicate }),
            TeamsActions.loadAllTeams(),
          ]),
          catchError((error: unknown) =>
            from([ClientActions.reactivateClientFailed({ error }), ListActions.updateItemInListFailed({ error })]),
          ),
        ),
      ),
    ),
  );

  reactivateSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.reactivateClientSucceeded),
      map(({ client }) =>
        ToastActions.showInfoMessage({
          summary: 'Återaktiverad',
          detail: `Klienten ${client.name} är återaktiverad`,
        }),
      ),
    ),
  );

  archiveClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.archiveClient),
      switchMap(({ client, predicate }) =>
        this.clientService.deleteClient(client.id).pipe(
          mergeMap(() => [
            ClientActions.archiveClientSucceeded({ client }),
            ListActions.updateItemInListSucceeded({ item: client, predicate }),
            TeamsActions.loadAllTeams(),
          ]),
          catchError((error: unknown) =>
            from([ClientActions.archiveClientFailed({ error }), ListActions.updateItemInListFailed({ error })]),
          ),
        ),
      ),
    ),
  );

  archiveDeletableClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.archiveDeletableClient),
      switchMap(({ client, predicate }) =>
        this.clientService.archiveDeletableClient(client.id).pipe(
          mergeMap(() => [
            ClientActions.archiveDeletableClientSucceeded({ client }),
            ListActions.updateItemInListSucceeded({ item: client, predicate }),
            TeamsActions.loadAllTeams(),
          ]),
          catchError((error: unknown) =>
            from([
              ClientActions.archiveDeletableClientFailed({ error }),
              ListActions.updateItemInListFailed({ error }),
            ]),
          ),
        ),
      ),
    ),
  );

  archiveClientSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.archiveClientSucceeded, ClientActions.archiveDeletableClientSucceeded),
      map(({ client }) =>
        ToastActions.showInfoMessage({
          summary: 'Arkiverad',
          detail: `Klienten ${client.name} är arkiverad`,
        }),
      ),
    ),
  );

  deleteClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deleteClient),
      switchMap(({ client, predicate }) =>
        this.clientService.deleteClient(client.id).pipe(
          mergeMap(() => [
            ClientActions.deleteClientSucceeded({ client }),
            ListActions.deleteItemInListSucceeded({ item: client, predicate }),
            TeamsActions.loadAllTeams(),
          ]),
          catchError((error: unknown) =>
            from([ClientActions.deleteClientFailed({ error }), ListActions.deleteItemInListFailed({ error })]),
          ),
        ),
      ),
    ),
  );

  deleteClientSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deleteClientSucceeded),
      map(({ client }) =>
        ToastActions.showInfoMessage({
          summary: 'Borttagen',
          detail: `Klienten ${client.name} är borttagen`,
        }),
      ),
    ),
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.activateAdvisoryReport, ClientActions.deactivateAdvisoryReport, ClientActions.archiveClient),
      map(({ client }) => ListActions.updateItemInList({ item: client })),
    ),
  );

  deleteStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deleteClient),
      map(({ client }) => ListActions.deleteItemInList({ item: client })),
    ),
  );

  activateAdvisoryReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.activateAdvisoryReport),
      switchMap(({ client, predicate }) =>
        this.clientService.upsertClientType(client).pipe(
          switchMap((client) =>
            this.advisoryToolService
              .triggerSoftrobotUpdate(client.cloudApiKey)
              .pipe(
                mergeMap(() => [
                  ClientActions.activateAdvisoryReportSucceeded({ client }),
                  ListActions.updateItemInListSucceeded({ item: client, predicate }),
                ]),
              ),
          ),
          catchError((error: unknown) =>
            from([
              ClientActions.activateAdvisoryReportFailed({ error }),
              ListActions.updateItemInListFailed({ error }),
            ]),
          ),
        ),
      ),
    ),
  );

  deactivateAdvisoryReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deactivateAdvisoryReport),
      switchMap(({ client, predicate }) =>
        this.clientService.upsertClientType(client).pipe(
          tap(() => this.advisoryToolService.reTriggerNotifications()),
          mergeMap(() => [
            ClientActions.deactivateAdvisoryReportSucceeded({ client }),
            ListActions.updateItemInListSucceeded({ item: client, predicate }),
          ]),
          catchError((error: unknown) =>
            from([
              ClientActions.deactivateAdvisoryReportFailed({ error }),
              ListActions.updateItemInListFailed({ error }),
            ]),
          ),
        ),
      ),
    ),
  );

  activateAdvisoryReportSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.activateAdvisoryReportSucceeded),
      map(({ client }) =>
        ToastActions.showInfoMessage({
          summary: `Rådgivarverktyget aktiverat`,
          detail: `Rådgivarverktyget har nu aktiverats för ${client.name} och notiser har börjat hämtas. Inom kort dyker de upp
          i fliken för Rådgivarverktyget`,
        }),
      ),
    ),
  );

  deactivateAdvisoryReportSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deactivateAdvisoryReportSucceeded),
      map(({ client }) =>
        ToastActions.showInfoMessage({
          summary: `Rådgivarverktyget avaktiverat`,
          detail: `Rådgivarverktyget har nu avaktiverats för ${client.name}`,
        }),
      ),
    ),
  );

  activateAdvisoryReportFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.activateAdvisoryReportFailed),
      tap(console.error),
      map(() =>
        ToastActions.showWarnMessage({ summary: 'Något fick fel vid aktivering av Rådgivarverktyget', detail: '' }),
      ),
    ),
  );

  deactivateAdvisoryReportFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deactivateAdvisoryReportFailed),
      tap(console.error),
      map(() =>
        ToastActions.showWarnMessage({ summary: 'Något fick fel vid avaktivering av Rådgivarverktyget', detail: '' }),
      ),
    ),
  );

  activateSustainabilityReporting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.activateSustainabilityReporting),
      exhaustMap(({ clientId }) =>
        this.clientService.activateSustainabilityReporting(clientId).pipe(
          mergeMap(() => [
            ClientActions.activateSustainabilityReportingSucceeded(),
            ToastActions.showInfoMessage({
              summary: 'Hållbarhetsredovisningen har aktiveras för klienten',
              detail: '',
            }),
          ]),
          catchError((error: unknown) =>
            from([
              ToastActions.showErrorMessage({
                summary: 'Något gick fel!',
                detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
                error,
                sticky: true,
              }),
              ClientActions.activateSustainabilityReportingFailed(),
            ]),
          ),
        ),
      ),
    ),
  );

  deactivateSustainabilityReporting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientActions.deactivateSustainabilityReporting),
      exhaustMap(({ clientId }) =>
        this.clientService.deactivateSustainabilityReporting(clientId).pipe(
          mergeMap(() => [
            ClientActions.deactivateSustainabilityReportingSucceeded(),
            ToastActions.showInfoMessage({
              summary: 'Hållbarhetsredovisningen har avaktiveras för klienten',
              detail: '',
            }),
          ]),
          catchError((error: unknown) =>
            from([
              ToastActions.showErrorMessage({
                summary: 'Något gick fel!',
                detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
                error,
                sticky: true,
              }),
              ClientActions.deactivateSustainabilityReportingFailed(),
            ]),
          ),
        ),
      ),
    ),
  );

  constructor(
    actions$: Actions,
    store: Store<AppState>,
    private clientService: ClientService,
    private advisoryToolService: AdvisoryToolService,
  ) {
    super(actions$, store);
  }
}
