import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { AppState } from '../state/appState';
import { BLService } from './bl.service';
import { CreditInformationType } from '../state/types/credit-information.types';

@Injectable({
  providedIn: 'root',
})
export class CreditInformationService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'informationlogistics/info/';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  getCreditInformation(corporateIdentity: string): Observable<CreditInformationType> {
    return this.http
      .get<CreditInformationType & { error: string }>(
        this.url + corporateIdentity,
        this.options.getAuthorizationHeaderWithEmptyBody(),
      )
      .pipe(
        map((response) => {
          if (!response) {
            return null;
          }

          if (!Object.keys(response).length) {
            return null;
          }

          const { error, ...creditInformation } = response;
          if (error) {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal, no-throw-literal
            throw { error: { message: error } };
          }

          return creditInformation;
        }),
        this.catchErrorAndShowMessage(),
      );
  }
}
