import { createSelector } from '@ngrx/store';
import { ListSelectors } from '../list/list.selectors';
import { TodoListFilter, TodoListParams } from '../types';
import { TodoType } from '../types/todo.types';

const isDeadlineGreaterThenFromDate = (deadlineDt: string, fromDt: string) => {
  const deadlineDate = new Date(deadlineDt);
  const fromDate = new Date(fromDt);
  return deadlineDate > fromDate;
};

const isTodoDone = (todo: TodoType) => todo.state.name === 'DON';

const isAllUncompletedAndClientsDone = (filter: TodoListFilter) =>
  filter.includeAllUncompleted && filter.includeAllDoneClients;

const isNotAllUncompletedAndClientsDone = (filter: TodoListFilter) =>
  !filter.includeAllUncompleted && filter.includeAllDoneClients;

const isAllUncompletedAndNotClientsDone = (filter: TodoListFilter) =>
  filter.includeAllUncompleted && !filter.includeAllDoneClients;

const includeTodo = (todo: TodoType, filter: TodoListFilter, params: TodoListParams) => {
  if (isAllUncompletedAndClientsDone(filter)) {
    return true;
  }

  if (isNotAllUncompletedAndClientsDone(filter)) {
    return isDeadlineGreaterThenFromDate(todo.deadline, params.fromDate);
  }

  if (isAllUncompletedAndNotClientsDone(filter)) {
    return !isTodoDone(todo);
  }

  return isDeadlineGreaterThenFromDate(todo.deadline, params.fromDate) && !isTodoDone(todo);
};

const allTodos = ListSelectors.getItems<TodoType, TodoListFilter, TodoListParams>();

const allFilteredTodos = createSelector(allTodos, ({ items, filter, params }) => {
  const filteredTodos = items.filter((todo) => includeTodo(todo, filter, params));
  const filteredCompositesCount = items.filter(isTodoDone).length;
  return { filteredTodos, filteredCompositesCount };
});

export const TodosSelectors = {
  allFilteredTodos,
};
