import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleUserType } from '@app/core/state/types';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-admin-avatar',
  template: `
    <div class="flex flex-wrap">
      <span
        class="inline-flex h-8 w-8 mr-4 mb-4 items-center justify-center rounded-full cursor-default bg-bl-blue-light-800 ring-2 ring-bl-blue-light-600 shadow-lg"
        pTooltip="{{ user.name }}"
        tooltipPosition="top"
        *ngFor="let user of users"
      >
        <span class="text-sm font-medium leading-none text-white">{{ user.initials }}</span>
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TooltipModule],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AdminAvatarComponent), multi: true }],
})
export class AdminAvatarComponent implements ControlValueAccessor {
  @Input() users: SimpleUserType[];

  constructor() {}

  writeValue(obj: any): void {
    this.users = obj;
  }

  registerOnChange(): void {}
  registerOnTouched(): void {}
}
