import { JwtHelperService } from '@auth0/angular-jwt';

export class Jwt {
  static isValidToken(jwt: string, skipCheckExpiredDate = false) {
    try {
      const tokenPayload = this.decodeToken(jwt);

      if (skipCheckExpiredDate) {
        return this.isValidPayload(tokenPayload);
      }
      return this.isValidPayload(tokenPayload) && this.isValidExpiredAtDate(tokenPayload.exp);
    } catch (err) {
      return false;
    }
  }

  static isValidPayload(payload: any) {
    return payload && payload.dataOwner && payload.username && payload.userId && payload.service;
  }

  static decodeToken(jwt: string) {
    const jwtHelper = new JwtHelperService();
    return jwtHelper.decodeToken(jwt);
  }

  private static isValidExpiredAtDate(expTime: number | undefined) {
    if (!expTime) {
      return false;
    }
    const jwtExpireAt = new Date(1000 * expTime);
    return jwtExpireAt.getTime() > Date.now();
  }
}
