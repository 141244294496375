import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TaskService } from '../core/services/task.service';
import { ActivityService } from '../core/services/activity.service';
import { ClientService } from '../core/services/clients.service';
import { UserService } from '../core/services/user.service';
import { Activity } from '../core/entity/activity';
import { Task } from '../core/entity/task';
import { ActivityComposite } from '../core/entity/activity.composite';
import { Client } from '../core/entity/client';
import { User } from '../core/entity/user';
import { TodayDate } from '../shared/misc/today.date';
import { UserSettingsStorage } from '../core/storage/user.settings.storage';
import { TableColumn } from '../shared/components/table/interfaces/table-column';
import { AlertLevelNames } from '../core/entity/alert.level';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { ToastActions } from '../core/state/toast/toast.actions';
import { getIncludeFinishedLabel } from '@app/shared/misc/getIncludeFinishedLabel';
import { CLIENT_RESPONSIBLE, INCLUDE_FINISHED_LABEL } from '@app/shared/misc/constants';
import { catchError, first, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { showClientDialog } from '@app/shared/misc/showClientDialog';
import { DialogService } from 'primeng/dynamicdialog';
import { showActivityDetailsDialog } from '@app/shared/misc/showActivityDetailsDialog';
import { toActivityTransformer } from '@app/core/state/transformers/transformers';

@Component({
  selector: 'app-activites-list',
  templateUrl: './activities.list.component.html',
  styles: [
    `
      .maxWidth150 {
        max-width: 150px;
      }
    `,
  ],
})
export class ActivitiesListComponent implements OnInit, OnDestroy {
  originalActivities: ActivityComposite[] = [];
  currentActivityComposits: ActivityComposite[] = [];
  filteredActivityComposits: ActivityComposite[] = [];
  fromDate: string = new TodayDate().getDefaultSearchFromDate();
  toDate: string = new TodayDate().getDefaultSearchToDate();
  defaultIfEmptyFromDate = new TodayDate().getDefaultSearchFromDate();
  defaultIfEmptyToDate = new TodayDate().getDefaultSearchToDate();
  selectedActivityComposite: ActivityComposite;
  isUpdatingList = false;
  listMessage = 'Listan uppdateras...';
  selectedTaskUserId = 0;
  selectedClientResponsibleUserId = 0;
  defaultNumberOfRows = 50;
  searchAllUncompleted = false;
  includeFinished = true;

  columns: TableColumn[] = [
    {
      field: 'activity.alertLevel.description',
      header: '',
      sortable: false,
      width: '40px',
      selectorLabel: 'Statusikon',
      fieldValue: (_fieldValue, rowData) => rowData?.activity?.alert_level?.description,
    },
    { field: 'task.assignment_name', header: 'Uppdrag', width: '12%' },
    { field: 'task.type', header: 'Aktivitet', width: '24%' },
    { field: 'task.customer_number', header: 'Kundnummer', width: '13%' },
    { field: 'task.client.name', header: 'Klient', width: '21%' },
    { field: 'task.responsible_name', header: CLIENT_RESPONSIBLE, width: '12%' },
    { field: 'task.usersAsString', header: 'Handläggare', width: '18%' },
    { field: 'activity.deadline', header: 'Deadline', width: '100px' },
    {
      field: 'status',
      header: 'Status',
      width: '170px',
      fieldValue: (_fieldValue, rowData) => rowData?.activity?.state?.description,
      additionalFilterFields: ['activity.state.description'],
    },
    {
      field: 'comments',
      header: '',
      sortable: false,
      width: '70px',
      selectorLabel: 'Kommentar',
      fieldValue: (_fieldValue, rowData) => this.getComment(rowData),
      additionalFilterFields: ['activity.comment', 'task.comment'],
    },
    { field: 'program', header: 'Program', sortable: false, width: '70px', exportable: false },
  ];

  includeFinishedLabel = INCLUDE_FINISHED_LABEL;
  includeArchivedClientsBS: BehaviorSubject<boolean>;
  private uss = new UserSettingsStorage();
  private onDestroySubject: Subject<boolean>;

  constructor(
    private taskService: TaskService,
    private activityService: ActivityService,
    private clientService: ClientService,
    private userService: UserService,
    private domSanitizer: DomSanitizer,
    private store: Store<AppState>,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
  ) {
    this.onDestroySubject = new Subject();
    this.includeArchivedClientsBS = new BehaviorSubject(false);
  }

  ngOnInit() {
    this.loadUserSettings();
    this.userService.getCurrentUser().subscribe((currentUser: User) => {
      this.selectedTaskUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.ACTIVITIES_USER_ID, currentUser.id);
    });
    this.store
      .select(FirmSelectors.selectFirm)
      .pipe(
        map((firm) => firm?.properties?.include_activities_for_archived_clients === 'true'),
        takeUntil(this.onDestroySubject),
      )
      .subscribe(this.includeArchivedClientsBS);
  }

  ngOnDestroy() {
    this.saveUserSettings();
    this.onDestroySubject.next(true);
  }

  private loadUserSettings() {
    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.ACTIVITIES_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.fromDate = this.uss.loadSetting(
      UserSettingsStorage.ACTIVITIES_START_DATE,
      new TodayDate().getDefaultSearchFromDate(),
    );
    this.toDate = this.uss.loadSetting(
      UserSettingsStorage.ACTIVITIES_END_DATE,
      new TodayDate().getDefaultSearchToDate(),
    );
    this.includeFinished = this.uss.loadSettingAsBoolean(UserSettingsStorage.ACTIVITIES_SHOW_FINISHED, true);
    this.searchAllUncompleted = this.uss.loadSettingAsBoolean(UserSettingsStorage.ACTIVITIES_SEARCH_UNCOMPLETED, true);
    this.selectedClientResponsibleUserId = this.uss.loadSettingAsNumber(
      UserSettingsStorage.ACTIVITIES_CLIENT_RESPONSIBLE_ID,
      0,
    );
  }

  private saveUserSettings() {
    try {
      this.uss.saveSetting(UserSettingsStorage.ACTIVITIES_START_DATE, this.fromDate);
      this.uss.saveSetting(UserSettingsStorage.ACTIVITIES_END_DATE, this.toDate);
      this.uss.saveSetting(UserSettingsStorage.ACTIVITIES_SHOW_FINISHED, this.includeFinished ? 'true' : 'false');
      this.uss.saveSetting(
        UserSettingsStorage.ACTIVITIES_SEARCH_UNCOMPLETED,
        this.searchAllUncompleted ? 'true' : 'false',
      );
      this.uss.saveSetting(UserSettingsStorage.ACTIVITIES_USER_ID, `${this.selectedTaskUserId}`);
      this.uss.saveSetting(
        UserSettingsStorage.ACTIVITIES_CLIENT_RESPONSIBLE_ID,
        `${this.selectedClientResponsibleUserId}`,
      );
    } catch (e) {
      console.log((<Error>e).message);
    }
  }

  private setAllActivities(activities: Activity[]) {
    this.originalActivities = activities.map((activity) => {
      this.setClientOnTask(activity);
      this.setUsersAsString(activity);
      activity.alertLevel = activity.alert_level;

      var ac = new ActivityComposite(activity.task, activity);
      ac.updateStatusColor();
      ac.task.selectableStates = ac.task.selectable_states;
      return ac;
    });

    this.filterActivityList();
  }

  refilterActivities() {
    this.filterActivityList();
  }

  private filterActivityList() {
    this.currentActivityComposits = this.originalActivities.filter((activityComposite) =>
      this.filteredActivity(activityComposite.activity),
    );
    this.includeFinishedLabel = getIncludeFinishedLabel({
      label: INCLUDE_FINISHED_LABEL,
      completeList: this.originalActivities,
      predicate: (item) => item.activity.alertLevel.name === AlertLevelNames.COMPLETED,
    });
  }

  private filteredActivity(activity: Activity) {
    return this.includeFinished ? true : activity.alertLevel.name != AlertLevelNames.COMPLETED;
  }

  refreshList() {
    const isAllTaskUser = this.selectedTaskUserId === 0;
    const isAllClientUser = this.selectedClientResponsibleUserId === 0;
    if (!isAllTaskUser || !isAllClientUser || !this.includeArchivedClientsBS.value) {
      this.initEverything();
      return;
    }

    this.confirmationService.confirm({
      accept: () => this.initEverything(),
      message:
        'Du har valt att visa aktiviteter för alla klientansvariga, alla utförande handläggare och inkludera arkiverade klienter i resultatet. Detta kan ta lång tid. Vill du fortsätta?',
    });
  }

  initEverything() {
    this.isUpdatingList = true;
    this.listMessage = 'Listan uppdateras...';
    this.activityService
      .getActivitesByUser({
        fromDate: this.fromDate,
        toDate: this.toDate,
        taskUserId: this.selectedTaskUserId,
        clientUserId: this.selectedClientResponsibleUserId,
        includeArchivedClients: this.includeArchivedClientsBS.value,
      })
      .pipe(
        first(),
        catchError(() => of([])), // catchError is partially handled by activityService so let's return an empty array
      )
      .subscribe((activities) => {
        this.setAllActivities(activities);
        this.isUpdatingList = false;
        this.listMessage = 'Inga aktiviteter finns att visa';
      });
  }

  private setClientOnTask(activity: Activity) {
    activity.task.client = new Client(activity.task.client_name);
    activity.task.client.id = activity.task.client_id;
  }

  private setUsersAsString(activity: Activity) {
    const usersAsString = activity.users.map((user) => user.name).join(', ');
    activity.task.usersAsString = usersAsString;
  }

  showTask(event: ActivityComposite) {
    if (!event?.activity?.taskId) {
      return;
    }

    showActivityDetailsDialog(this.activityService, this.dialogService, event?.activity).subscribe((result) => {
      if (result?.activity?.state?.name) {
        result.activity.unsavedId = event.activity.unsavedId;
        this.processDetails(toActivityTransformer.transform(result.activity));
      }
    });
  }

  processDetails(activity: Activity) {
    if (!activity || !activity.state || !activity.state.name) {
      return;
    }

    this.activityService.saveActivity(activity).subscribe((persistedActivity) => {
      this.store.dispatch(ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Aktiviteten har sparats' }));
      const indexOfSelectedActivity = this.originalActivities.findIndex((ac) => {
        if (ac.activity.id) {
          // if activity is persisted
          return ac.activity.id === activity.id;
        }
        return ac.activity.unsavedId === activity.unsavedId; // ... otherwise use the temporary id
      });
      this.replace(indexOfSelectedActivity, persistedActivity);
    });
  }

  replace(index: number, activity: Activity) {
    this.taskService.getTask(activity.taskId).subscribe((task: Task) => {
      const ac = new ActivityComposite(task, activity);
      ac.updateStatusColor();

      this.originalActivities[index].activity = activity;
      this.originalActivities[index].statusColor = ac.statusColor;
      const activityUsers = JSON.parse(JSON.stringify(ac.activity.users));
      const taskUsers = JSON.parse(JSON.stringify(ac.task.users));
      this.originalActivities[index].activity.users = activityUsers.concat(taskUsers);
      this.originalActivities[index].activity.task = task;
      this.setUsersAsString(this.originalActivities[index].activity);
    });
  }

  showExistingClient(id: number) {
    showClientDialog({ clientService: this.clientService, dialogService: this.dialogService, id, store: this.store })
      .pipe(first())
      .subscribe((result) => {
        if (result?.client?.id && result?.client?.id !== -1) {
          this.initEverything();
        }
      });
  }

  getStateOptions(composite: ActivityComposite) {
    if (!composite.task) {
      return [];
    }

    return composite.task.selectableStates.map((state) => ({ label: state.description, value: state.name }));
  }

  changeActivityState(selectedStateName: string, composite: ActivityComposite) {
    const selectedState = composite.task.selectableStates.find((state) => state.name === selectedStateName);
    if (!selectedState) {
      return;
    }
    composite.activity.state = selectedState;
    composite.activity.task = undefined;
    this.processDetails(composite.activity);
  }

  getSelectedState(composite: ActivityComposite) {
    return composite.activity.state.name;
  }

  setSelectedListLength(list: any) {
    this.filteredActivityComposits = list;
  }

  persistSelectedLength(listLength: number) {
    this.uss.saveSetting(UserSettingsStorage.ACTIVITIES_SELECTED_LENGTH, `${listLength}`);
  }

  paginatorTriggered(_index: number, nbrOfRows: number) {
    this.uss.saveSetting(UserSettingsStorage.ACTIVITIES_NUMBER_OF_ROWS, `${nbrOfRows}`);
  }

  callAgent(composite: ActivityComposite) {
    const agentCallerElement = <HTMLAnchorElement>document.getElementById('agentCaller');

    this.taskService.getAgentUrl(composite.task.id, composite.activity.span.start).subscribe((reply) => {
      let path = reply.agentCall;

      agentCallerElement.href = path;
      agentCallerElement.click();
      return false;
    });
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  hasAccessToOpenProgram(agentName: string) {
    return agentName !== 'BL';
  }

  private getComment = (rowData: ActivityComposite) => {
    const activityComment = rowData?.activity?.comment;
    const taskComment = rowData?.task?.comment;

    if (activityComment && taskComment) {
      return `${activityComment} | ${taskComment}`;
    }

    return activityComment || taskComment || '';
  };
}
