import { Span } from './span';
import { Activity } from './activity';
import { CompositeBase } from './composite.base';
import { StatusColor } from '../misc/status-color';

export class IncomeTaxDeclarationComposite extends CompositeBase {
  client: string;
  clientId: number;
  corporateIdentity: string;
  customerNumber: string;
  responsible: string;
  deadline: string;
  mainSpan: Span = new Span();
  firmTaxRespite: boolean;
  electronicTax: boolean;
  declarationPeriodCode: string;
  materialStatusColor: string;
  declarationMaterial: {
    materialReceivedAlertLevel: { description: string; name: string };
    materialReceivedAt: string;
    materialSortValue: string;
  };
  uppi: {
    activity: Activity;
    readyDate: string;
    task: { comment: '' };
    agentCall: string;
  };
  uppb: { activity: Activity; readyDate: string; task: { comment: '' }; agentCall: string };

  constructor() {
    super();
  }

  protected getCompositeColumnTypePropertyNames(): string[] {
    return ['uppi', 'uppb'];
  }

  protected createDefaultCompositeObjectForProperty() {
    return {
      activity: new Activity(),
      readyDate: 'false',
      task: { comment: '' },
      agentCall: '',
    };
  }

  public static from(obj: any): IncomeTaxDeclarationComposite {
    const incomeTaxDeclaration = CompositeBase.fromObject(IncomeTaxDeclarationComposite, obj);

    if (!incomeTaxDeclaration.mainSpan) {
      incomeTaxDeclaration.mainSpan = new Span();
    }

    if (!incomeTaxDeclaration.declarationMaterial) {
      incomeTaxDeclaration.declarationMaterial = {
        materialReceivedAt: '',
        materialSortValue: '',
        materialReceivedAlertLevel: { description: '', name: '' },
      };
    }

    incomeTaxDeclaration.updateMaterialStatusColor();
    incomeTaxDeclaration.updateMaterialSortValue();
    incomeTaxDeclaration.populateTaskUsers();

    return incomeTaxDeclaration;
  }

  public updateMaterialStatusColor() {
    const alertLevelName = this.declarationMaterial.materialReceivedAlertLevel.name;
    this.materialStatusColor = StatusColor.getColorByAlertName(alertLevelName);
  }

  public updateMaterialSortValue() {
    const materialReceivedAt = this.declarationMaterial.materialReceivedAt || '';
    const materialStatusName = this.declarationMaterial.materialReceivedAlertLevel.name || '';

    this.declarationMaterial.materialSortValue = `${materialReceivedAt}${materialStatusName}`;
  }
}
