import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, filter } from 'rxjs/operators';
import { Permission, PermissionGroup, PermissionType } from 'src/app/core/entity/permission';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { UserType } from 'src/app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';
import { MultiselectWithAllDropdownComponent } from '../../../multiselect-dropdown/multiselect-with-all-dropdown.component';
import { PermissionRootState, PermissionState, PermissionStateFacade } from './permission-state.facade';

@Component({
  selector: 'app-permission-selector',
  templateUrl: './permission-selector.component.html',
  providers: [PermissionStateFacade],
})
export class PermissionSelectorComponent implements OnDestroy {
  @ViewChild('visibleInNotificationMultiselect') visibleInNotificationMultiselect: MultiselectWithAllDropdownComponent;
  @ViewChild('showTaxAccountMultiselect') showTaxAccountMultiselect: MultiselectWithAllDropdownComponent;

  @Input()
  get clientId() {
    return this._clientId;
  }
  set clientId(clientId: number | undefined) {
    this._clientId = clientId;
    if (!clientId || clientId < 1) {
      this.clearPermissionState();
      return;
    }
    this.loadAndUpdatePermissionState();
  }

  @Input() disabled: boolean = true;
  @Input() disabledTooltip: string | undefined;
  @Output() change: EventEmitter<Permission[]> = new EventEmitter<Permission[]>();

  get VisibleInNotification() {
    return PermissionType.VisibleInNotification;
  }
  get ShowTaxAccountBalance() {
    return PermissionType.ShowTaxAccountBalance;
  }

  availableUsers$: Observable<UserType[]>;
  permissionState$: Observable<PermissionRootState>;
  permissionGroupOptions = [
    { name: 'Alla', value: PermissionGroup.All },
    { name: 'Byråadmin', value: PermissionGroup.OnlyFirmAdmin },
    { name: 'Byråadmin och klientansvarig', value: PermissionGroup.OnlyResponsibleUserAndFirmAdmin },
    { name: 'Valda användare', value: PermissionGroup.OnlySelectedUsers },
  ];
  private onSaveSubject: BehaviorSubject<PermissionType> = new BehaviorSubject(null);
  private subscriptions: Subscription = new Subscription();
  private _clientId: number | undefined;

  constructor(
    private permissionState: PermissionStateFacade,
    private permissionService: PermissionService,
    private store: Store<AppState>,
  ) {
    this.permissionState$ = this.permissionState.createRootStateObservable();
    this.availableUsers$ = this.store.select(UserSelectors.activeUsersWithoutAll);
    this.setupSaveSubscription();
  }

  private clearPermissionState() {
    this.permissionState.initState([]);
  }

  private loadAndUpdatePermissionState() {
    this.permissionService.loadPermissions(this.clientId).subscribe((permissions: Permission[]) => {
      this.permissionState.initState(permissions);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  showVisibleInNotificationUsersDropdown() {
    setTimeout(() => {
      this.visibleInNotificationMultiselect.show();
    }, 20); // wait for the angular change detection to show the dropdown
  }

  showTaxAccountBalanceUsersDropdown() {
    setTimeout(() => {
      this.showTaxAccountMultiselect.show();
    }, 20); // wait for the angular change detection to show the dropdown
  }

  onMultiselectHide(permissionType: PermissionType) {
    this.permissionState.clearIfEmpty(permissionType);
    this.onSaveSubject.next(permissionType);
  }

  updateNotificationPermissionState(prop: string, value: any, persistChanges = true) {
    this.updateState(PermissionType.VisibleInNotification, prop, value);
    if (prop === 'group' && value === PermissionGroup.OnlySelectedUsers) {
      this.showVisibleInNotificationUsersDropdown();
      return;
    }
    if (persistChanges) {
      this.onSaveSubject.next(PermissionType.VisibleInNotification);
    }
  }

  updateTaxAccountPermissionState(prop: any, value: any, persistChanges = true) {
    this.updateState(PermissionType.ShowTaxAccountBalance, prop, value);
    if (prop === 'group' && value === PermissionGroup.OnlySelectedUsers) {
      this.showTaxAccountBalanceUsersDropdown();
      return;
    }
    if (persistChanges) {
      this.onSaveSubject.next(PermissionType.ShowTaxAccountBalance);
    }
  }

  private updateState(permissionType: PermissionType, prop: string, value: any) {
    let updatedState: Partial<PermissionState> = { [prop]: value };

    if (prop === 'active') {
      updatedState = {
        active: value,
        group: value ? PermissionGroup.OnlyResponsibleUserAndFirmAdmin : PermissionGroup.None,
        users: [],
      };
    }
    if (prop === 'group') {
      updatedState.users = [];
    }

    this.permissionState.updateState(permissionType, updatedState);
  }

  private setupSaveSubscription() {
    this.subscriptions.add(
      this.onSaveSubject
        .pipe(
          filter((permissionType) => !!permissionType),
          this.permissionState.filterUnchanged(),
          this.permissionState.withLatestFromState(),
          concatMap(([permissionType, state]: [PermissionType, PermissionState]) =>
            this.savePermissions(permissionType, state),
          ),
          catchError((err: unknown) => {
            console.error('Fel vid sparande av rättigheter!', 'klientid: ' + this._clientId, err);
            return throwError(err);
          }),
        )
        .subscribe((response) => {
          if (!response) {
            this.permissionState.rollbackToPreviousPersistedState();
            return;
          }

          this.permissionState.initState(response);
          this.store.dispatch(
            ToastActions.showInfoMessage({ summary: 'Sparat', detail: 'Rättigheterna har uppdaterats.' }),
          );
          this.change.emit(response);
        }),
    );
  }

  private savePermissions(permissionType: PermissionType, state: PermissionState) {
    return this.permissionService
      .updatePermission(this.clientId, permissionType, state.group, state.users)
      .pipe(catchError(() => of(null)));
  }
}
