import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Client } from 'src/app/core/entity/client';
import { getDateValidator } from 'src/app/core/entity/validation';
import { ValidationStatus } from 'src/app/core/entity/validationStatus';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { AppState } from '@app/core/state/appState';
import { DirtyItem } from 'src/app/shared/misc/dirty.item';

@Component({
  selector: 'app-client-business-detail',
  templateUrl: './client.business.detail.component.html',
  styles: [
    `
      table > tr > td {
        padding: 2px;
      }
      table > tr > td.table-label {
        padding: 12px 2px 0 2px;
        font-weight: 600;
        font-size: 85%;
      }
    `,
  ],
})
export class ClientBusinessDetailComponent {
  minSelectableDate = new Date(1897, 0, 1);
  private _clientBusinessDetails: Client;
  @Output() detailsEmitter = new EventEmitter<Client>();
  @Output() dirtyEmitter = new EventEmitter<DirtyItem>();

  @Input()
  set client(client: Client) {
    this._clientBusinessDetails = client;
  }

  constructor(private store: Store<AppState>) {}

  get clientBusinessDetails() {
    return this._clientBusinessDetails;
  }

  emitBusinessDetails() {
    this.detailsEmitter.emit(this.clientBusinessDetails);
    this.sendDirty();
  }

  close(saveFlag: number) {
    const { validate, min, max } = getDateValidator({ minDate: this.minSelectableDate });
    const { status, title, text } = validate(this.clientBusinessDetails.registrationDate, {
      min,
      max,
      label: 'Registreringsdatum',
    });

    if (this.clientBusinessDetails.registrationDate && status !== ValidationStatus.Ok && saveFlag !== -1) {
      this.store.dispatch(ToastActions.showWarnMessage({ summary: title, detail: text }));
      return;
    }

    this.clientBusinessDetails.saveFlag = saveFlag;
    this.detailsEmitter.emit(this.clientBusinessDetails);
  }

  sendDirty() {
    this.dirtyEmitter.emit(new DirtyItem('BusinessDetails', true));
  }
}
