import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-firm-users-confirm-dialog',
  template: `<p-dialog
    showEffect="fade"
    [(visible)]="isVisible"
    [positionTop]="80"
    header="Inaktivera medarbetare"
    [draggable]="false"
    [blockScroll]="true"
    [modal]="true"
    [resizable]="false"
    (onHide)="onHide()"
    [style]="{ width: '50vw' }"
    appendTo="body"
  >
    <p>
      Medarbetaren kommer bli inaktiverad i Byråstödet och kommer inte kunna använda tjänsten. För att du inte ska
      fortsätta debiteras för licensen så måste du även avsluta den. Glöm inte att anmäla licensförändringen till
      info.se&#64;bjornlunden.com med vår ändringsblankett som du hittar
      <a href="https://download.blinfo.se/v2/public/Andring-av-anvandaruppgifter-i-molnet.pdf" target="_blank">
        här &rsaquo;&rsaquo;
      </a>
    </p>
    <p>Vill du fortsätta?</p>
    <p-footer>
      <button type="button" class="btn btn-success" (click)="onClick(true)">Ja</button>
      <button type="button" class="btn btn-warning" (click)="onClick(false)">Nej</button>
    </p-footer>
  </p-dialog>`,
})
export class FirmUsersConfirmDialogComponent {
  public isVisible: boolean;

  constructor() {
    this.isVisible = false;
    this.onClose = new EventEmitter<boolean>();
    this.visibleChange = new EventEmitter<boolean>();
  }

  @Input()
  set visible(newVisible: boolean) {
    this.isVisible = newVisible;
  }

  @Output() onClose: EventEmitter<boolean>;
  @Output() visibleChange: EventEmitter<boolean>;

  onClick(active: boolean) {
    this.onClose.emit(active);
    this.onHide();
  }

  onHide() {
    this.isVisible = false;
    this.visibleChange.emit(this.isVisible);
  }
}
