import { Component, Input, EventEmitter, Output } from '@angular/core';
import { getYears } from '@app/shared/misc/dates';
import { SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';

const YEARS_START = 2015;
const YEARS_FUTURE = 3;

@Component({
  selector: 'app-year-selector',
  template: `
    <p-dropdown
      [options]="years$ | async"
      [ngModel]="year"
      (ngModelChange)="onChange($event)"
      optionValue="value"
      [styleClass]="styleClass"
      [scrollHeight]="itemCount > 15 ? '200px' : 'auto'"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false">
    </p-dropdown>
  `,
})
export class YearSelector {
  public years$: Observable<SelectItem<string>[]>;
  public itemCount: number;

  @Input() year: string;
  @Input() styleClass = 'min-w-24';
  @Output() yearChange: EventEmitter<string>;

  constructor() {
    const years = getYears(YEARS_START, YEARS_FUTURE).map((year) => ({ label: String(year), value: String(year) }));
    this.years$ = of(years);
    this.itemCount = years.length;
    this.yearChange = new EventEmitter();
  }

  onChange(month: string) {
    this.yearChange.emit(month);
  }
}
