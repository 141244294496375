import { Component, OnInit, OnDestroy } from '@angular/core';
import { Client } from '../core/entity/client';
import { TodayDate } from '../shared/misc/today.date';
import { ClientService } from '../core/services/clients.service';
import { UserSettingsStorage } from '../core/storage/user.settings.storage';
import { TableColumn } from '../shared/components/table/interfaces/table-column';
import { Store } from '@ngrx/store';
import { TodoType } from '../core/state/types/todo.types';
import { AppState } from '@app/core/state/appState';
import { ToastActions } from '../core/state/toast/toast.actions';
import { CLIENT_RESPONSIBLE, INCLUDE_FINISHED_LABEL, INCLUDE_UNFINISHED_LABEL } from '@app/shared/misc/constants';
import { filter, first, map, take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ListSelectors } from '@app/core/state/list/list.selectors';
import { TodosSelectors } from '@app/core/state/todos/todos.selectors';
import { TodosActions } from '@app/core/state/todos/todos.actions';
import { ListActions } from '@app/core/state/list/list.actions';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { takeFirstTruthy } from '@app/core/misc/rxjs-operators';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { showClientDialog } from '@app/shared/misc/showClientDialog';
import { DialogService } from 'primeng/dynamicdialog';
import { showTodoDialog } from '@app/shared/misc/showTodoDialog';
import { NotesService } from '@app/core/services/notes.service';
import { toTodoTransformer } from '@app/core/state/transformers/todo.transformers';

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo.list.component.html',
})
export class TodoListComponent implements OnInit, OnDestroy {
  public loading$: Observable<boolean>;
  public statusText$: Observable<string>;
  public includeFinishedLabel$: Observable<string>;
  public list$: Observable<TodoType[]>;

  public fromDate: string = new TodayDate().getDefaultSearchFromDate();
  public toDate: string = new TodayDate().getDefaultSearchToDate();

  public columns: TableColumn[] = [
    { field: 'client.name', header: 'Klient', width: '11%' },
    { field: 'client.corporateIdentity', header: 'Orgnr', width: '8%' },
    { field: 'client.customerNumber', header: 'Kundnr', width: '7%' },
    { field: 'client.responsible.name', header: CLIENT_RESPONSIBLE, width: '9%' },
    { field: 'type.description', header: 'Typ', width: '5%' },
    { field: 'assignment.name', header: 'Uppdrag', width: '7%' },
    { field: 'deadline', header: 'Deadline', width: '100px' },
    { field: 'text', header: 'Noteringstext' },
    { field: 'creator.initials', header: 'Skapad av', width: '6%' },
    { field: 'usernames', header: 'Utförande handläggare', width: '13%' },
    {
      field: 'status',
      header: 'Status',
      width: '170px',
      sortable: false,
      fieldValue: (_fieldValue, rowData) => rowData?.state?.description || '',
    },
    { field: 'action', header: '', width: '40px', sortable: false, selectable: false, exportable: false },
  ];

  public selectedUserId: number = null;
  public includeAllUncompleted = true;
  public includeFinished = true;
  public defaultNumberOfRows = 50;
  public displayDeleteDialog = false;
  public includeUnFinishedLabel: string = INCLUDE_UNFINISHED_LABEL;

  public afterDeleteDialogCallback: (cancel?: boolean) => void;
  public additionalFilterFields: string[] | null = null;

  private uss = new UserSettingsStorage();
  private onDestroySubject: Subject<boolean>;

  constructor(
    private clientService: ClientService,
    private notesService: NotesService,
    private store: Store<AppState>,
    private dialogService: DialogService,
  ) {
    this.statusText$ = this.store.select(ListSelectors.getStatusText);
    this.loading$ = this.store.select(ListSelectors.isLoading);
    this.includeFinishedLabel$ = this.store
      .select(TodosSelectors.allFilteredTodos)
      .pipe(map(({ filteredCompositesCount }) => `${INCLUDE_FINISHED_LABEL} (${filteredCompositesCount} st)`));

    this.list$ = this.store.select(TodosSelectors.allFilteredTodos).pipe(map(({ filteredTodos }) => filteredTodos));
    this.onDestroySubject = new Subject();
    this.additionalFilterFields = ['state.description'];
  }

  get defaultFromSearchDate() {
    return new TodayDate().getDefaultSearchFromDate();
  }

  get defaultToSearchDate() {
    return new TodayDate().getDefaultSearchToDate();
  }

  ngOnInit() {
    this.loadUserSettings();
    this.initListLoading();
  }

  ngOnDestroy() {
    this.saveUserSettings();
    this.onDestroySubject.next(true);
  }

  private loadUserSettings() {
    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.TOD_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.fromDate = this.uss.loadSetting(
      UserSettingsStorage.TOD_START_DATE,
      new TodayDate().getDefaultSearchFromDate(),
    );
    this.toDate = this.uss.loadSetting(UserSettingsStorage.TOD_END_DATE, new TodayDate().getDefaultSearchToDate());
    this.includeFinished = this.uss.loadSettingAsBoolean(UserSettingsStorage.TOD_SHOW_FINISHED, true);
    this.includeAllUncompleted = this.uss.loadSettingAsBoolean(UserSettingsStorage.TOD_INCLUDE_UNCOMPLETED, true);
    this.selectedUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.TOD_USER_ID, null);
  }

  private saveUserSettings() {
    try {
      this.uss.saveSetting(UserSettingsStorage.TOD_START_DATE, this.fromDate);
      this.uss.saveSetting(UserSettingsStorage.TOD_END_DATE, this.toDate);
      this.uss.saveSetting(UserSettingsStorage.TOD_USER_ID, `${this.selectedUserId}`);
      this.uss.saveSetting(UserSettingsStorage.TOD_SHOW_FINISHED, `${this.includeFinished}`);
      this.uss.saveSetting(UserSettingsStorage.TOD_INCLUDE_UNCOMPLETED, `${this.includeAllUncompleted}`);
    } catch (e: unknown) {
      // eslint-disable-next-line no-console
      console.error((e as Error).message);
    }
  }

  private initListLoading() {
    this.store
      .select(AuthSelectors.selectAuthUser)
      .pipe(takeFirstTruthy())
      .subscribe((currentUser) => {
        this.selectedUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.TOD_USER_ID, currentUser.id);
      });
  }

  loadList() {
    const todosFilter = {
      includeAllDoneClients: this.includeFinished,
      includeAllUncompleted: this.includeAllUncompleted,
    };
    const params = {
      fromDate: this.fromDate,
      toDate: this.toDate,
      executorId: this.selectedUserId,
    };

    this.store.dispatch(TodosActions.load({ filter: todosFilter, params }));
  }

  filterTodos() {
    const todosFilter = {
      includeAllDoneClients: this.includeFinished,
      includeAllUncompleted: this.includeAllUncompleted,
    };
    this.store.dispatch(ListActions.filterList({ filter: todosFilter }));
  }

  showExistingClient(event: { client: Client }) {
    showClientDialog({
      clientService: this.clientService,
      dialogService: this.dialogService,
      id: event.client.id,
      store: this.store,
    })
      .pipe(first())
      .subscribe((result) => {
        if (result?.client?.id && result?.client?.id !== -1) {
          this.loadList();
        }
      });
  }

  onStateChanged(stateName: string, todoToUpdate: TodoType) {
    const predicate = (todo: TodoType) => todo.id === todoToUpdate.id;
    this.store.dispatch(TodosActions.updateState({ state: stateName, todo: todoToUpdate, predicate }));
  }

  paginatorTriggered({ rows }: { rows: number }) {
    this.uss.saveSetting(UserSettingsStorage.TOD_NUMBER_OF_ROWS, String(rows));
  }

  showDeleteWarningDialog(todoToDelete: TodoType) {
    this.displayDeleteDialog = true;

    this.afterDeleteDialogCallback = (cancel?: boolean) => {
      this.displayDeleteDialog = false;
      if (!cancel) {
        this.tryToDeleteTodo(todoToDelete);
      }
    };
  }

  showExistingTodo(selectedTodoRow: TodoType) {
    const todo = toTodoTransformer.transform(selectedTodoRow);
    showTodoDialog({ dialogService: this.dialogService, notesService: this.notesService, id: todo.id })
      .pipe(filter((result) => Boolean(result?.todo)))
      .subscribe();
  }

  private tryToDeleteTodo(todoToDelete: TodoType) {
    if (!todoToDelete.client) {
      this.dispatchDeleteAction(todoToDelete);
      return;
    }

    this.store
      .select(ClientSelectors.getClientById(todoToDelete.client.id))
      .pipe(take(1))
      .subscribe((client) => {
        if (!client.archived) {
          this.dispatchDeleteAction(todoToDelete);
          return;
        }

        this.store.dispatch(
          ToastActions.showWarnMessage({
            summary: 'Arkiverad',
            detail: 'Noteringen / Att Göra-punkten tillhör en arkiverad klient och kan inte tas bort',
          }),
        );
      });
  }

  private dispatchDeleteAction(todoToDelete: TodoType) {
    const predicate = (todo: TodoType) => todo.id !== todoToDelete.id;
    this.store.dispatch(TodosActions.todoDelete({ todo: todoToDelete, predicate }));
  }
}
