<app-table
  #table
  storageKey="todoListTable"
  [columns]="columns"
  [data]="list$ | async"
  [rowsPerPage]="defaultNumberOfRows"
  sortField="client.name"
  exportFilename="notiser"
  [additionalFilterFields]="additionalFilterFields"
>
  <app-table-filter>
    <app-team-multi-selector localStorageKey="todos" (OnTeamsInit)="loadList()"></app-team-multi-selector>
    <app-table-filter-item label="Period för Att Göra ska visas">
      <div class="flex">
        <app-calendar
          [(selectedDate)]="fromDate"
          [style]="{ 'max-width': '150px' }"
          [defaultDate]="defaultFromSearchDate"
        ></app-calendar>
        <app-calendar
          [(selectedDate)]="toDate"
          [style]="{ 'max-width': '150px' }"
          styleClass="ml-2"
          [defaultDate]="defaultToSearchDate"
        ></app-calendar>
      </div>
    </app-table-filter-item>
    <app-table-filter-item label="Utförande handläggare">
      <app-user-selector [(userId)]="selectedUserId" styleClass="min-w-48"></app-user-selector>
    </app-table-filter-item>
    <app-table-filter-item>
      <app-refresh-button [loading]="loading$ | async" (onClick)="loadList()"></app-refresh-button>
    </app-table-filter-item>
    <app-table-filter-item>
      <p-checkbox
        [binary]="true"
        [(ngModel)]="includeAllUncompleted"
        (ngModelChange)="filterTodos()"
        [label]="includeUnFinishedLabel"
      ></p-checkbox>
    </app-table-filter-item>
    <app-table-filter-item>
      <p-checkbox
        [binary]="true"
        [(ngModel)]="includeFinished"
        (ngModelChange)="filterTodos()"
        [label]="includeFinishedLabel$ | async"
      ></p-checkbox>
    </app-table-filter-item>
  </app-table-filter>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="default" let-content let-rowData="rowData">
    <a (click)="showExistingTodo(rowData)" class="text-black cursor-pointer">{{ content }}</a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="client.name" let-rowData="rowData">
    <a (click)="showExistingClient(rowData)" class="text-black cursor-pointer">{{ rowData.client?.name }}</a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="client.corporateIdentity" let-rowData="rowData">
    <a (click)="showExistingClient(rowData)" class="text-black cursor-pointer">{{
      rowData.client?.corporateIdentity
    }}</a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="client.customerNumber" let-rowData="rowData">
    <a (click)="showExistingClient(rowData)" class="text-black cursor-pointer">{{ rowData.client?.customerNumber }}</a>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="client.responsible.name" let-content>
    {{ content }}
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="status" let-rowData="rowData">
    <p-dropdown
      [options]="rowData.selectableStates"
      [ngModel]="rowData.state"
      (onChange)="onStateChanged($event?.value?.name, rowData)"
      optionLabel="description"
      appendTo="body"
      styleClass="w-full table-inline-dropdown"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false">
    </p-dropdown>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="action" let-rowData="rowData">
    <span (click)="showDeleteWarningDialog(rowData)" class="far fa-trash-alt text-black cursor-pointer"> </span>
  </ng-template>

  <ng-template tableTemplate="emptyMessage">{{ statusText$ | async }}</ng-template>

  <ng-template tableTemplate="summary" let-data>
    <div class="relative">
      Antal noteringar / Att Göra: {{ data.length }}
      <div class="absolute export-button-wrapper">
        <p-button
          icon="pi pi-download"
          class="export-button"
          size="small"
          label="Exportera lista"
          (click)="table.exportCSV()"
          title="Ladda ner listan för import till Excel"
        ></p-button>
      </div>
    </div>
  </ng-template>
</app-table>

<p-dialog
  header="Ta bort Notering/Att Göra-post?"
  [(visible)]="displayDeleteDialog"
  showEffect="fade"
  [closeOnEscape]="false"
  [closable]="false"
  [draggable]="false"
  [style]="{ width: '400px' }"
>
  <p>Posten kommer att tas bort permanent.</p>
  <p>Vill du fortsätta?</p>
  <p-footer>
    <button type="button" class="btn btn-success" (click)="afterDeleteDialogCallback()">Ja</button>
    <button type="button" class="btn btn-warning" (click)="afterDeleteDialogCallback(true)">Nej</button>
  </p-footer>
</p-dialog>
