import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { withFirstTeamIds } from '../misc/rxjs-operators';
import { AppState } from '../state/appState';
import { BLService } from './bl.service';
import {
  NotificationResultDto,
  NotificationResultDtoCompany,
  NotificationResultDtoSkv,
  NotificationDataType,
  NotificationResult,
} from './notification.service.types';

const STATUSTEXT_FOR_MISSING_PERMISSION = 'Rättighet saknas';
const STATUSTEXT_FOR_SERVER_ERROR = 'Det gick inte att hämta uppgifterna';
const STATUSTEXT_FOR_MISSING_PROXY = 'Ombudsbehörighet saknas';

interface StatusHandler {
  canHandle: (data?: NotificationResultDtoSkv) => boolean;
  handle: (data?: NotificationResultDtoSkv) => { TaxAccount: string } | null;
}

interface NotificationCache {
  responsibleUserId: number;
  data: NotificationResult[];
  timestamp: number;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'cloudnotification';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;
  private notificationCacheSubject: BehaviorSubject<NotificationCache> = new BehaviorSubject<NotificationCache>(null);
  private handlers: StatusHandler[];

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);

    this.handlers = [
      { canHandle: (data) => !data, handle: () => null },
      { canHandle: (data) => 'saldo' in data, handle: (data) => ({ TaxAccount: `${data.saldo.saldoSkatteverket}` }) },
      {
        canHandle: (data) => data?.status?.includes(STATUSTEXT_FOR_MISSING_PERMISSION),
        handle: () => ({ TaxAccount: 'MISSING_PERMISSION' }),
      },
      {
        canHandle: (data) => data?.status?.includes(STATUSTEXT_FOR_SERVER_ERROR),
        handle: () => ({ TaxAccount: 'SERVER_ERROR' }),
      },
      {
        canHandle: (data) => data?.status?.includes(STATUSTEXT_FOR_MISSING_PROXY),
        handle: () => ({ TaxAccount: 'MISSING_PROXY' }),
      },
    ];
  }

  loadTodoInformationForResponsibleUser(
    responsibleUserId: number,
    startDate: string,
    endDate: string,
    force: boolean = false,
  ): Observable<NotificationResult[]> {
    if (force) {
      this.notificationCacheSubject.next(null);
    }

    if (typeof responsibleUserId === 'string') {
      responsibleUserId = parseInt(responsibleUserId, 10);
    }

    if (this.hasCacheValue(responsibleUserId)) {
      return this.notificationCacheSubject.asObservable().pipe(
        take(1),
        map((cache) => cache.data),
      );
    }

    return withFirstTeamIds(this.store).pipe(
      switchMap(({ ids }) =>
        this.http
          .get<any>(
            `${this.url}/${responsibleUserId}/${startDate}/${endDate}`,
            this.options.getAuthorizationHeaderWithEmptyBody(ids),
          )
          .pipe(
            map((result: NotificationResultDto[]) => {
              return (result || []).map((item) => {
                if (!item || !item.data) {
                  return item;
                }

                const { company, skv, ...rest } = item.data;
                const data: NotificationDataType = {
                  ...rest,
                  ...this.mapCompanyTodoInformation(company),
                  ...this.mapSkvInformation(skv),
                };
                return { ...item, data } as NotificationResult;
              });
            }),
            tap((result) =>
              this.notificationCacheSubject.next({ responsibleUserId, data: result, timestamp: Date.now() }),
            ),
            this.catchErrorAndShowMessage(),
          ),
      ),
    );
  }

  getLastUpdatedDate() {
    const cacheValue = this.notificationCacheSubject.getValue();
    return cacheValue ? new Date(cacheValue.timestamp) : null;
  }

  private hasCacheValue(responsibleUserId: number) {
    const cacheValue: NotificationCache = this.notificationCacheSubject.getValue();
    if (!cacheValue) {
      return false;
    }
    const hasDataValue = Array.isArray(cacheValue.data) && cacheValue.data.length > 0;
    return cacheValue.responsibleUserId === responsibleUserId && hasDataValue;
  }

  private mapCompanyTodoInformation(company?: NotificationResultDtoCompany[]): Record<string, number> {
    if (!company) {
      return {};
    }

    return company.reduce((acc, todo) => {
      acc[this.toPascalCase(todo.type)] = todo.number;
      return acc;
    }, {} as Record<string, number>);
  }

  private mapSkvInformation(data?: NotificationResultDtoSkv) {
    const handler = this.handlers.find((h) => h.canHandle(data));
    if (!handler) {
      return null;
    }

    return handler.handle(data);
  }

  private toPascalCase(value: string): string {
    const capitalize = (str: string) =>
      ` ${str}`.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_match, chr) => chr.toUpperCase());
    return value.split('_').map(capitalize).join('');
  }
}
