import { Component } from '@angular/core';

@Component({
  selector: 'app-table-filter',
  template: `
    <div class="flex items-end my-4">
      <ng-content select="app-task-groups-selector"></ng-content>
      <ng-content select="app-team-multi-selector"></ng-content>
      <ng-content select="app-table-filter-item"></ng-content>
    </div>
  `,
})
export class TableFilterComponent {}
