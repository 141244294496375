import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertLevelNames } from '@app/core/entity/alert.level';

@Component({
  selector: 'app-status-icon',
  template: ` <i [style.color]="color" [class]="statusIcon" [title]="title" aria-hidden="true"></i> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIconComponent {
  @Input() status: string;
  @Input() color: string;
  @Input() title: string;
  @Input() emptyBlack: boolean | '' = false;

  get statusIcon() {
    switch (this.status) {
      case AlertLevelNames.WARNING:
        return 'fas fa-triangle fa-sm';
      case AlertLevelNames.REMINDER:
        return 'fas fa-square fa-sm';
      case AlertLevelNames.OVERDUE:
        return 'fas fa-exclamation-triangle fa-sm';
      case AlertLevelNames.COMPLETED:
        return 'fas fa-check fa-sm';
      case AlertLevelNames.STARTED:
        return 'fas fa-circle fa-sm';
      case AlertLevelNames.INACTIVE:
        return 'fas fa-circle fa-sm';
    }
    return this.emptyBlack !== false ? '' : 'fa fa-minus fa-sm';
  }
}
