import { createSelector } from '@ngrx/store';
import { ActivityStateGroup, ActivityStateGroupType, ActivityStateType } from '../types';
import { AppState } from '../appState';

const actitivies = (state: AppState) => state.activities;

const startNames = ['WFM', 'NST'];
const inProgressNames = [
  'MRC',
  'IPG',
  'CSG',
  'SBC',
  'COM',
  'MBC',
  'FTR',
  'DND',
  'SRU',
  'NAP',
  'OND',
  'DFC',
  'CND',
  'MRT',
  'DTA',
  'AND',
];
const doneNames = [
  'ABA',
  'DON',
  'VTR',
  'PYD',
  'STC',
  'ORD',
  'CDL',
  'HTC',
  'DLD',
  'CRD',
  'RCD',
  'MRD',
  'FTD',
  'NCT',
  'ABC',
];

export const isInStartGroup = ({ name }: ActivityStateType): boolean => startNames.includes(name);
export const isInInProgressGroup = ({ name }: ActivityStateType): boolean => inProgressNames.includes(name);
export const isInDoneGroup = ({ name }: ActivityStateType): boolean => doneNames.includes(name);

export const getStartGroup = (states: ActivityStateType[]): ActivityStateGroupType => ({
  description: ActivityStateGroup.Start,
  name: ActivityStateGroup.Start,
  items: states.filter((s) => isInStartGroup(s)),
});

export const getInProgressGroup = (states: ActivityStateType[]): ActivityStateGroupType => ({
  description: ActivityStateGroup.InProgress,
  name: ActivityStateGroup.InProgress,
  items: states.filter((s) => isInInProgressGroup(s)),
});

export const getDoneGroup = (states: ActivityStateType[]): ActivityStateGroupType => ({
  description: ActivityStateGroup.Done,
  name: ActivityStateGroup.Done,
  items: states.filter((s) => isInDoneGroup(s)),
});

export const activityStates = createSelector(actitivies, (state) => {
  const startGroup = getStartGroup(state.activityStates);
  const inProgressGroup = getInProgressGroup(state.activityStates);
  const doneGroup = getDoneGroup(state.activityStates);

  return [startGroup, inProgressGroup, doneGroup];
});

export const ActivitySelectors = {
  activityStates,
};
