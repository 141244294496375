import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';

@Component({
  selector: 'app-advisory-toolbar',
  template: `
    <main class="flex flex-col">
      <p class="!text-base !text-bl-blue-600 !m-0 !mb-4">Tabellinställningar:</p>
      <span id="showNotificationsCount" class="flex pb-1">
        <p-inputSwitch
          class="px-2"
          aria-labelledby="showNotificationsCount"
          [ngModel]="showNotificationsCount"
          (onChange)="onShowNotificationsToggle($event.checked)"></p-inputSwitch>
        Visa notissymbol i menyraden
      </span>
      <span id="advice" class="flex pb-1">
        <p-inputSwitch
          class="px-2"
          aria-labelledby="advice"
          [ngModel]="filter.advice"
          (onChange)="onAdvicesToggle($event.checked)"></p-inputSwitch>
        Visa tips
      </span>
      <span id="cleared" class="flex pb-1">
        <p-inputSwitch
          class="px-2"
          aria-labelledby="cleared"
          [ngModel]="filter.cleared"
          (onChange)="onClearedToggle($event.checked)"></p-inputSwitch>
        Visa även åtgärdade
      </span>
    </main>
  `,
  standalone: true,
  imports: [CommonModule, FormsModule, InputSwitchModule],
})
export class AdvisoryToolbarComponent {
  @Input() showNotificationsCount: boolean;
  @Input() filter: {
    advice: boolean;
    cleared: boolean;
    warning: boolean;
  };

  @Output() showCountInTab = new EventEmitter<boolean>();
  @Output() adviceFilter = new EventEmitter<boolean>();
  @Output() clearedFilter = new EventEmitter<boolean>();

  onShowNotificationsToggle(checked: boolean): void {
    this.showCountInTab.emit(checked);
  }

  onAdvicesToggle(checked: boolean): void {
    this.adviceFilter.emit(checked);
  }

  onClearedToggle(checked: boolean): void {
    this.clearedFilter.emit(checked);
  }
}
