import { StatusColor } from '../misc/status-color';
import { Activity } from './activity';
import { CompositeBase } from './composite.base';

export class SalaryComposite extends CompositeBase {
  client: string = '';
  clientId: number;
  corporateIdentity: string;
  customerNumber: string;
  responsible: string;
  materialStatusColor: string;
  paymentDay: string;
  paymentMaterial: {
    alertLevel: { name: string; description: string };
    when: string;
  };

  lonb: { activity: Activity; readyDate: string; task: { comment: string }; agentCall: string };
  lone: { activity: Activity; readyDate: string; task: { comment: string }; agentCall: string };
  lonu: { activity: Activity; readyDate: string; task: { comment: string }; agentCall: string };
  form: { activity: Activity; readyDate: string; task: { comment: string }; agentCall: string };
  ovri: { activity: Activity; readyDate: string; task: { comment: string }; agentCall: string };
  stat: { activity: Activity; readyDate: string; task: { comment: string }; agentCall: string };

  constructor() {
    super();
  }

  protected getCompositeColumnTypePropertyNames(): string[] {
    return ['lonb', 'lone', 'lonu', 'ovri', 'stat', 'form'];
  }

  protected createDefaultCompositeObjectForProperty() {
    return {
      activity: new Activity(),
      readyDate: 'false',
      task: { comment: '' },
    };
  }

  static from(obj: any): SalaryComposite {
    const salaryComposite = CompositeBase.fromObject(SalaryComposite, obj);

    if (!salaryComposite.paymentMaterial) {
      salaryComposite.paymentMaterial = {
        alertLevel: { name: '', description: '' },
        when: '',
      };
    }

    salaryComposite.updateMaterialStatusColor();
    salaryComposite.populateTaskUsers();

    return salaryComposite;
  }

  public updateMaterialStatusColor() {
    const alertLevelName = this.paymentMaterial.alertLevel.name;
    this.materialStatusColor = StatusColor.getColorByAlertName(alertLevelName);
  }
}
