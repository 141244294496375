<p-table
  [columns]="columns"
  [value]="clients$ | async"
  [(selection)]="selectedClients"
  (selectionChange)="refilterClients(); onChange($event); onTouched()"
  [scrollable]="true"
  [sortField]="columns[0].field"
  [globalFilterFields]="filterFields"
  scrollHeight="346px"
  dataKey="id"
  #table>
  <ng-template pTemplate="caption">
    <div class="flex">
      <span class="p-input-icon-left mr-5">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="table.filterGlobal($any($event.target).value, 'contains')"
          placeholder="Sök" />
      </span>
      <p-checkbox
        [binary]="true"
        [(ngModel)]="showOnlySelectedRows"
        (ngModelChange)="filterTableBaseOnShowOnlySelectedRows($any($event))"
        label="Visa endast valda rader"></p-checkbox>
      <p-checkbox
        [binary]="true"
        [(ngModel)]="includeArchivedClients"
        (ngModelChange)="filterTableBasedOnIncludeArchivedClients($any($event))"
        label="Visa även arkiverade klienter"
        class="ml-5"></p-checkbox>
    </div>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length + 1">
        <div class="my-3">Inga träffar</div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3.4em">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th
        class="font-normal truncate"
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        [style]="getColumnWidth(col)">
        {{ col.header }}
        <p-sortIcon
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-client let-columns="columns">
    <tr>
      <td>
        <p-tableCheckbox [value]="client"></p-tableCheckbox>
      </td>
      <td [style]="getColumnWidth(columns[0])" class="truncate">
        {{ client.archivedName }}
      </td>
      <td [style]="getColumnWidth(columns[1])" class="truncate">
        {{ client.type.description }}
      </td>
      <td [style]="getColumnWidth(columns[2])" class="truncate">
        {{ client.responsible?.name }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="relative">Antal valda klienter: {{ selectedClients?.length }}</div>
  </ng-template>
</p-table>
