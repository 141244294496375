import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TaskGroupType } from '../types';

export const TaskGroupsActions = createActionGroup({
  source: 'TaskGroups',
  events: {
    Load: emptyProps(),
    'Load Succeeded': props<{ taskGroups: TaskGroupType[] }>(),
    'Load Failed': props<{ error: any }>(),
  },
});
