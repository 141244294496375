import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import {
  DataType,
  FormDialogBaseComponent,
  ResultType,
} from '../components/form-dialog-base/form-dialog-base.component';
import { Type } from '@angular/core';
import { Observable, first } from 'rxjs';

/**
 *
 * @param dialogService DialogService used to open a dialog containing the `componentType`
 * @param componentType The component type to expose as a dialog
 * @param config This is the data that is passed to the dialog as defined by the `componentType` and the config needed to display the dialog
 * @returns Observable with the return type defined as defined by the `componentType`
 */
export const openDialogWithConfig = <T extends FormDialogBaseComponent<any, any, any>>(
  dialogService: DialogService,
  componentType: Type<T>,
  config: DynamicDialogConfig<DataType<T>>,
): Observable<ResultType<T>> => dialogService.open(componentType, config).onClose.pipe(first());

/**
 *
 * @param dialogService DialogService used to open a dialog containing the `componentType`
 * @param componentType The component type to expose as a dialog
 * @param data This is the data that is passed to the dialog as defined by the `componentType`
 * @returns Observable with the return type defined as defined by the `componentType`
 */
export const openDialog = <T extends FormDialogBaseComponent<any, any, any>>(
  dialogService: DialogService,
  componentType: Type<T>,
  data: DataType<T>,
): Observable<ResultType<T>> => {
  // Uses overriden static function on Dialog to extract DynamicDialogConfig
  const config: DynamicDialogConfig<DataType<T>> = {
    ...(componentType as any)?.getDialogConfig?.(),
    data,
  };

  return openDialogWithConfig(dialogService, componentType, config);
};
