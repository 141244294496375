import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@app/core/state/appState';
import { ListSelectors } from '@app/core/state/list/list.selectors';
import {
  AppModule,
  AppModuleType,
  AppSubscription,
  CollaborationPackageActivityFilter,
  CollaborationPackageFilter,
  CollaborationPackageItem,
  CollaborationPackageStatusFilter,
} from '@app/core/state/types/collaboration-package.types';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { openDialog } from '@app/shared/misc/openDialog';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, combineLatest, first, map } from 'rxjs';
import { ActionSelectorDialogComponent } from './components/action-selector-dialog/action-selector-dialog.component';
import { SubscriptionTypeIconComponent } from './components/subscripton-type-icon/subscription-type-icon.component';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { CollaborationPackageSelectors } from '@app/core/state/collaboration-packages/collaboration-packages.selectors';
import { CollaborationPackageActions } from '@app/core/state/collaboration-packages/collaboration-packages.actions';
import { showClientDialog } from '@app/shared/misc/showClientDialog';
import { ClientService } from '@app/core/services/clients.service';
import { BlFrontendAlertComponent } from '@app/core/wrappers/bl-frontend-alert.component';
import { Link } from '@bl/components';
import { ListActions } from '@app/core/state/list/list.actions';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';
import { TooltipOptions } from 'primeng/api';
import { getLocalIsoDateWithTime } from '@app/shared/misc/dates';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { FirmSettingsDialog } from '@app/firm-settings/firm.settings.dialog';
import {
  getAdminMessageAboutHiddenDatabases,
  getUserMessageAboutHiddenDatabases,
  getMainInfoAlertContent,
} from './collaboration-texts-util';

type TableCloudDbItem = CollaborationPackageItem & {
  status: string;
  financialYearText: string;
  paying: string;
  subscriptionTypeId: number | null;
  subscriptionBundleName: string | null;
  addonModulesName: string | null;
};

@Component({
  templateUrl: './collaboration-packages.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    BystSharedModule,
    BlFrontendButtonComponent,
    BlFrontendAlertComponent,
    SubscriptionTypeIconComponent,
    FormsModule,
  ],
  styles: [
    `
      .max-55em {
        max-width: 55em;
      }
    `,
  ],
})
export class CollaborationPackagesComponent implements OnInit, OnDestroy {
  filter: CollaborationPackageFilter = { status: 'allExceptArchived', activity: 'all' };
  defaultColumns: TableColumn[] = [
    { field: 'action', header: 'Hantera paket', selectable: false, sortable: false, width: '130px' },
    { field: 'subscriptionTypeId', header: 'Samarbetspaket', width: '100px' },
    { field: 'subscriptionBundleName', header: 'Paket', width: '130px' },
    { field: 'addonModulesName', header: 'Tilläggstjänster', width: '170px' },
    { field: 'name', header: 'Namn' },
    { field: 'corporateIdentity', header: 'Orgnr', width: '150px' },
    { field: 'responsibleUser.name', header: 'Klientansvarig' },
    {
      field: 'financialYearText',
      header: 'Räkenskapsår',
      visible: false,
    },
    { field: 'systemNote', header: 'Systemnot' },
    { field: 'lastLogin', header: 'Senaste inlogg i BLA', visible: true },
    { field: 'email', header: 'E-post', visible: false },
    { field: 'status', header: 'Status' },
    { field: 'paying', header: 'Betalande', width: '100px' },
    { field: 'cloudApiKey', header: 'Molndatabasnyckel' },
  ];

  columns: TableColumn[] = [...this.defaultColumns];
  defaultNumberOfRows = 50;
  selectedClientResponsibleUserId: number | undefined = 0;
  defaultColumnTooltipOptions: TooltipOptions = { tooltipPosition: 'top', tooltipStyleClass: 'tooltip-extended-width' };

  list$: Observable<TableCloudDbItem[]>;
  loading$: Observable<boolean>;
  statusText$: Observable<string>;
  hiddenDatabaseMessage$: Observable<string>;

  private isBundle = (module: AppModule) => module.type?.toUpperCase() === AppModuleType.BUNDLE;
  private findBundleName = (subscription: AppSubscription | null) =>
    subscription?.modules?.find(this.isBundle)?.name ?? '';

  get alertContent(): Link[] {
    return getMainInfoAlertContent();
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private store: Store<AppState>,
    private dialogService: DialogService,
    private clientService: ClientService,
  ) {}

  ngOnInit(): void {
    this.loadSettings();
    this.loading$ = this.store.select(ListSelectors.isLoading);
    this.statusText$ = this.store.select(ListSelectors.getStatusText);
    this.list$ = this.getListObservable();
    this.hiddenDatabaseMessage$ = this.getHiddenDatabaseMessageAsObservable();
    this.reloadList();
  }

  ngOnDestroy(): void {
    this.saveSettings();
  }

  openActionSelectionDialog(data: CollaborationPackageItem) {
    const { clientId, cloudApiKey } = data;

    this.store.dispatch(CollaborationPackageActions.clearState());
    this.store.dispatch(CollaborationPackageActions.loadEmailForClient({ clientId, cloudApiKey }));
    this.store.dispatch(CollaborationPackageActions.loadClient({ clientId }));

    openDialog(this.dialogService, ActionSelectorDialogComponent, data);
  }

  reloadList() {
    this.store.dispatch(
      CollaborationPackageActions.load({
        filter: { ...this.filter },
        params: { responsibleId: this.selectedClientResponsibleUserId },
      }),
    );
  }

  refilterCollaborationPackageList() {
    this.store.dispatch(
      ListActions.filterList({
        filter: { ...this.filter },
      }),
    );
  }

  onShowExistingClient(clientId: number) {
    showClientDialog({
      clientService: this.clientService,
      dialogService: this.dialogService,
      id: clientId,
      store: this.store,
    })
      .pipe(first())
      .subscribe();
  }

  onInfoLinkClickedOpenFirmDataTabInDialog(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const target = event.target as HTMLElement;
    if (target.tagName !== 'A') {
      return;
    }
    openDialog(this.dialogService, FirmSettingsDialog, { tabToOpen: 'Byråuppgifter' });
  }

  private loadSettings() {
    const uss = new UserSettingsStorage();

    this.defaultNumberOfRows = uss.loadSettingAsNumber(
      UserSettingsStorage.COLLABORATION_PACKAGES_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.filter = {
      status: uss.loadSetting(
        UserSettingsStorage.COLLABORATION_PACKAGES_STATUS_FILTER,
        'allExceptArchived',
      ) as CollaborationPackageStatusFilter,
      activity: uss.loadSetting(
        UserSettingsStorage.COLLABORATION_PACKAGES_ARCHIVED_FILTER,
        'all',
      ) as CollaborationPackageActivityFilter,
    };
    this.selectedClientResponsibleUserId = uss.loadSettingAsNumber(
      UserSettingsStorage.COLLABORATION_PACKAGES_RESPONSIBLE_ID,
      0,
    );
  }

  private saveSettings() {
    const uss = new UserSettingsStorage();

    uss.saveSetting(UserSettingsStorage.COLLABORATION_PACKAGES_NUMBER_OF_ROWS, `${this.defaultNumberOfRows}`);
    uss.saveSetting(UserSettingsStorage.COLLABORATION_PACKAGES_STATUS_FILTER, this.filter.status);
    uss.saveSetting(UserSettingsStorage.COLLABORATION_PACKAGES_ARCHIVED_FILTER, this.filter.activity);
    uss.saveSetting(
      UserSettingsStorage.COLLABORATION_PACKAGES_RESPONSIBLE_ID,
      `${this.selectedClientResponsibleUserId}`,
    );
  }

  private getListObservable(): Observable<TableCloudDbItem[]> {
    return this.store.select(CollaborationPackageSelectors.selectFilteredItems).pipe(
      map((items) =>
        items.map((item) => ({
          ...item,
          status: this.createStatusText(item),
          financialYearText: this.convertFinancialYearToString(item),
          paying: this.createPayingText(item),
          subscriptionTypeId: item.subscription?.subscriptionTypeId,
          subscriptionBundleName: this.findBundleName(item.subscription),
          addonModulesName: this.createAddonModulesText(item.subscription),
          lastLogin: item.lastLogin ? getLocalIsoDateWithTime(item.lastLogin) : '',
        })),
      ),
    );
  }

  private getHiddenDatabaseMessageAsObservable(): Observable<string> {
    const hasHiddenDatabases$ = this.store.select(CollaborationPackageSelectors.selectHasHiddenDatabases);

    const messageByRole$ = this.store
      .select(AuthSelectors.selectIsFirmUser)
      .pipe(
        map((isFirmUser) =>
          isFirmUser ? getUserMessageAboutHiddenDatabases() : getAdminMessageAboutHiddenDatabases(),
        ),
      );

    const shouldShowMessage$ = this.store.select(FirmSelectors.selectFirm).pipe(
      map((firm) => firm?.properties?.show_hidden_databases_for_all_users ?? 'false'),
      map((setting) => setting === 'true'),
      map((showHiddenDatabaseSetting) => !showHiddenDatabaseSetting),
    );

    return combineLatest([hasHiddenDatabases$, shouldShowMessage$, messageByRole$]).pipe(
      map(([hasHiddenDatabases, showMessage, message]) => (hasHiddenDatabases && showMessage ? message : '')),
    );
  }

  private createStatusText(item: CollaborationPackageItem): string {
    const hiddenText = item.hidden ? 'Dold' : '';
    const firmSupportText = item.existsInFirmSupport ? 'Upplagd i Byråstöd' : 'Ej upplagd i Byråstöd';
    const archivedInFirmSupportText = item.archivedInFirmSupport ? 'Arkiverad i Byråstöd' : '';
    const archivedText = item.archived ? 'Arkiverad företagsdatabas' : '';

    return [hiddenText, firmSupportText, archivedText, archivedInFirmSupportText].filter(Boolean).join(', ');
  }

  private convertFinancialYearToString(item: CollaborationPackageItem): string {
    if (!item.financialYear) {
      return '';
    }
    const { start, end } = item.financialYear.span;
    return `${start} - ${end}`;
  }

  private createPayingText(item: CollaborationPackageItem): string {
    return item.payingByFirm ? 'Byrån' : 'Annan part';
  }

  private createAddonModulesText(subscription: AppSubscription | null): string {
    if (!subscription) {
      return '';
    }

    const addonModules = subscription.modules?.filter((module) => !this.isBundle(module)) ?? [];
    const integrations = subscription.integrations ?? [];
    const addonModuleNames = addonModules.map((module) => module.name);
    const integrationNames = integrations.map((integration) => integration.name);

    return this.distinctArray(addonModuleNames.concat(integrationNames))
      .sort((a, b) => a.localeCompare(b))
      .join(', ');
  }

  private distinctArray(array: string[]): string[] {
    return Array.from(new Set(array));
  }
}
