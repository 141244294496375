import { Component } from '@angular/core';
import { BlAccountAuthService } from '../core/services/bl-account-auth.service';

@Component({
  template: 'Du loggas in, vänligen vänta...',
})
export class SilentAuthComponent {
  // This is a landing page for apigateway after success login.
  // The page will only be loaded and then closed inside the popup.
  // The real app is loaded in the main browser.
  // This page MUST have the BlAccountAuthService injected to init the listening of JWT param so the popup can be closed.
  constructor(_blAccoutAuthService: BlAccountAuthService) {}
}
