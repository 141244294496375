import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { NotesService } from '../../services/notes.service';
import { ToastActions } from '../toast/toast.actions';
import { TodoActions } from './todo.actions';

@Injectable()
export class TodoEffects {
  loadClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TodoActions.saveTodo),
      switchMap(({ todo }) =>
        this.notesService.addTodoType(todo).pipe(
          mergeMap((newTodo) =>
            from([
              TodoActions.saveTodoSucceeded({ todo: newTodo }),
              ToastActions.showInfoMessage({ summary: 'Sparad', detail: 'Notering/Att Göra är sparad' }),
            ]),
          ),
          catchError((error: unknown) =>
            from([
              TodoActions.saveTodoFailed({ error }),
              ToastActions.showErrorMessage({
                summary: 'Fel vid sparande av Notering/Att Göra',
                detail: '',
                error,
              }),
            ]),
          ),
        ),
      ),
    ),
  );

  increaseTodoCount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TodoActions.increaseTodoCount),
        switchMap(() => {
          this.notesService.increaseTodosCount();
          return [];
        }),
      ),
    { dispatch: false },
  );

  // eslint-disable-next-line no-useless-constructor
  constructor(private actions$: Actions, private notesService: NotesService) {}
}
