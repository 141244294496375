import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BlAccountServiceTypes } from '../entity/blaccount-service-types';
import { ToastActions } from '../state/toast/toast.actions';
import { AppState } from '../state/appState';
import { UserSettingsStorage } from '../storage/user.settings.storage';

@Injectable({ providedIn: 'root' })
export class AllInclusiveService {
  private loggedInService: string;

  constructor(private store: Store<AppState>) {
    this.reloadLoggedInService();
  }

  get isLoggedInWithBLTAService() {
    return this.loggedInService === BlAccountServiceTypes.BL_T_A;
  }

  get isLoggedInWithAnyAllInclusiveService() {
    return (
      this.loggedInService === BlAccountServiceTypes.BL_ALL_INCLUSIVE ||
      this.loggedInService === BlAccountServiceTypes.BL_T_A
    );
  }

  reloadLoggedInService() {
    this.loggedInService = new UserSettingsStorage().loadSetting(UserSettingsStorage.LOGGED_IN_SERVICE, '');
  }

  showMissingAllInclusiveServiceWarning() {
    if (this.isLoggedInWithAnyAllInclusiveService) {
      return;
    }

    this.store.dispatch(
      ToastActions.showWarnMessage({
        summary: 'Rättigheter saknas',
        detail: 'Din användare saknar rättighet att starta BL Administration från Byråstöd',
      }),
    );
  }
}
