import { UserType } from 'src/app/core/state/types';

export interface FilterConfig {
  includeInactive?: boolean;
  searchString?: string | null;
}

export function filterType(
  users: UserType[],
  { includeInactive = false, searchString = '' }: FilterConfig = {}
): UserType[] {
  return users.filter((user) => {
    if (!user) {
      return false;
    }

    const activeFilter = includeInactive ? true : user.active;
    const searchFilter = searchString
      ? user.name?.toLocaleLowerCase().includes(searchString.toLowerCase()) ||
        user.email?.toLocaleLowerCase().includes(searchString.toLowerCase()) ||
        user.initials?.toLocaleLowerCase().includes(searchString.toLowerCase()) ||
        user.roleLabel?.toLocaleLowerCase().includes(searchString.toLowerCase())
      : true;

    return activeFilter && searchFilter;
  });
}
