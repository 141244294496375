import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { CreateTeamType, ResponseTeamType, TeamType, UpdateTeamType } from '../state/types';
import { AppState } from '../state/appState';
import { BLService } from './bl.service';

@Injectable({ providedIn: 'root' })
export class TeamService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'team';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  getTeams(): Observable<TeamType[]> {
    const headers = this.options.getAuthorizationHeaderWithEmptyBody();
    return this.http.get<TeamType[]>(this.url, headers).pipe(this.catchErrorAndShowMessage());
  }

  addTeam(team: CreateTeamType): Observable<TeamType> {
    const headers = this.options.getAuthorizationHeader();
    return this.http
      .post<ResponseTeamType>(this.url, team, headers)
      .pipe(map(this.transformToTeamType), this.catchErrorAndShowMessage());
  }

  updateTeam(team: UpdateTeamType): Observable<TeamType> {
    const headers = this.options.getAuthorizationHeader();
    return this.http
      .patch<ResponseTeamType>(`${this.url}/${team.id}`, team, headers)
      .pipe(map(this.transformToTeamType), this.catchErrorAndShowMessage());
  }

  deleteTeam(team: TeamType): Observable<void> {
    const headers = this.options.getAuthorizationHeaderWithEmptyBody();
    return this.http.delete<void>(`${this.url}/${team.id}`, headers).pipe(this.catchErrorAndShowMessage());
  }

  private transformToTeamType(team: ResponseTeamType): TeamType {
    const mapToIds = (array: { id: number }[]) => array.map((item) => item.id);
    const { users, clients, tasks, ...rest } = team;

    const userIds = mapToIds(users?.data || []);
    const clientIds = mapToIds(clients?.data || []);
    const taskIds = mapToIds(tasks?.data || []);

    return {
      ...rest,
      users: { ...users, data: userIds },
      clients: { ...clients, data: clientIds },
      tasks: { ...tasks, data: taskIds },
    };
  }
}
