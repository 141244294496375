import { Type } from './type';
import { Contact } from './contact';
import { User } from './user';
import { FinancialYear } from './financialyear';
import { Permission } from './permission';

export class Client {
  public id = -1;
  public customerNumber: number;
  public corporateIdentity: string;
  public type: Type = new Type();
  public description: string;
  public email: string;
  public financialYears: FinancialYear[];
  public advisoryTool: boolean;
  public alertIdentity: string;
  public cloudApiKey: string;
  public businessArea: string;
  public specialRules: string;
  public otherInfo: string;
  public accountant: string;
  public bank: string;
  public registrationDate: string;
  public contacts: Contact[];
  public saveFlag = 0;
  public deleteable = false;
  public archived = false;
  public userId: number;
  public userAsString: string;
  public responsible: User;
  public address: string;
  public zipCode: string;
  public city: string;
  public country: string;
  public residence: string;
  public phone: string;
  public cellphone: string;
  public signatory: string;
  public webpage: string;
  public permissions: Permission[];
  public foreignCorporateIdentity: boolean;
  public teams: { id: number; name: string }[];
  public sustainabilityReporting: boolean;

  constructor(public name: string) {}
}
