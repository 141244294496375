import { createReducer, on } from '@ngrx/store';
import { CoreActions } from '../core/core.actions';

export interface NavigationState {
  url: string;
}

export const initialNaviationState: NavigationState = { url: '' };

export const navigationReducer = createReducer(
  initialNaviationState,
  on(CoreActions.navigationStart, (_, { url }) => ({ url }))
);
