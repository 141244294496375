import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountingComposite } from '../entity/accounting.composite';
import { ClosingComposite } from '../entity/closing.composite';
import { IncomeStatementComposite } from '../entity/incomestatement.composite';
import { IncomeTaxDeclarationComposite } from '../entity/incometaxdeclaration.composite';
import { SalaryComposite } from '../entity/salary.composite';
import { TaxDeclarationComposite } from '../entity/taxdeclaration.composite';
import { HttpAuthorizationHeader } from '../misc/httpauthorizationheader';
import { withFirstTeamIds } from '../misc/rxjs-operators';
import {
  toAccountingCompositeTypeTransformer,
  toClosingCompositeTypeTransformer,
  toIncomeTaxDeclarationCompositeTypeTransformer,
  toSalaryCompositeTypeTransformer,
  toTaxDeclarationCompositeTypeTransformer,
} from '../state/transformers/transformers';
import {
  ClosingCompositeType,
  ClosingListParams,
  CompositeListParams,
  IncomeTaxDeclarationCompositeType,
  IncomeTaxDeclarationListParams,
  SalaryCompositeType,
  SalaryListParams,
  TaxDeclarationCompositeType,
  TaxDeclarationListParams,
  AccountingListParams,
  AccountingCompositeType,
} from '../state/types';
import { AppState } from '../state/appState';
import { BLService } from './bl.service';
import { IncomeStatementCompositeType } from '../state/types/incomestatement.types';
import { toIncomeStatementCompositeTypeTransformer } from '../state/transformers/incomestatement.transformers';

@Injectable({ providedIn: 'root' })
export class CustomListService extends BLService {
  private options = new HttpAuthorizationHeader('application/json');
  private RESOURCE_ENDPOINT = 'customlist';
  private url = environment.serverUrl + this.RESOURCE_ENDPOINT;

  constructor(private http: HttpClient, store: Store<AppState>) {
    super(store);
  }

  getAccountingComposites({
    responsibleId,
    executorId,
    searchCriteria,
    year,
    periodIndex,
  }: AccountingListParams): Observable<AccountingComposite[]> {
    const url = `${this.url}/accounting/${responsibleId}/${executorId}/${searchCriteria}/${year}/${periodIndex}`;
    return withFirstTeamIds(this.store).pipe(
      switchMap(({ ids }) =>
        this.http.get<AccountingComposite[]>(url, this.options.getAuthorizationHeaderWithEmptyBody(ids)).pipe(
          map((result) => result.map((accountingComposite) => AccountingComposite.from(accountingComposite))),
          this.catchErrorAndShowMessage(),
        ),
      ),
    );
  }

  getAccountingCompositeTypes(params: AccountingListParams): Observable<AccountingCompositeType[]> {
    return this.getAccountingComposites(params).pipe(
      map((items) => items.map(toAccountingCompositeTypeTransformer.transform)),
    );
  }

  getSalaryComposites({
    responsibleId,
    executorId,
    year,
    periodIndex,
  }: SalaryListParams): Observable<SalaryComposite[]> {
    const url = `${this.url}/salary/${responsibleId}/${executorId}/${year}/${periodIndex}`;
    return withFirstTeamIds(this.store).pipe(
      switchMap(({ ids }) =>
        this.http.get<SalaryComposite[]>(url, this.options.getAuthorizationHeaderWithEmptyBody(ids)).pipe(
          map((result) => result.map((salaryComposite) => SalaryComposite.from(salaryComposite))),
          this.catchErrorAndShowMessage(),
        ),
      ),
    );
  }

  getSalaryCompositeTypes(params: SalaryListParams): Observable<SalaryCompositeType[]> {
    return this.getSalaryComposites(params).pipe(map((items) => items.map(toSalaryCompositeTypeTransformer.transform)));
  }

  getTaxdeclarationComposites({
    responsibleId,
    executorId,
    year,
    periodIndex,
  }: TaxDeclarationListParams): Observable<TaxDeclarationComposite[]> {
    const url = `${this.url}/taxDeclaration/${responsibleId}/${executorId}/${year}/${periodIndex}`;
    return withFirstTeamIds(this.store).pipe(
      switchMap(({ ids }) =>
        this.http.get<TaxDeclarationComposite[]>(url, this.options.getAuthorizationHeaderWithEmptyBody(ids)).pipe(
          map((result) => result.map((taxDeclaration) => TaxDeclarationComposite.from(taxDeclaration))),
          this.catchErrorAndShowMessage(),
        ),
      ),
    );
  }

  getTaxdeclarationCompositeTypes(params: TaxDeclarationListParams): Observable<TaxDeclarationCompositeType[]> {
    return this.getTaxdeclarationComposites(params).pipe(
      map((items) => items.map(toTaxDeclarationCompositeTypeTransformer.transform)),
    );
  }

  getIncomeTaxdeclarationComposites(
    config: IncomeTaxDeclarationListParams,
  ): Observable<IncomeTaxDeclarationComposite[]> {
    const url = `${this.url}/incomeTaxDeclaration`;
    return withFirstTeamIds(this.store).pipe(
      switchMap(({ ids }) =>
        this.http
          .post<IncomeTaxDeclarationComposite[]>(url, config, this.options.getAuthorizationHeaderWithEmptyBody(ids))
          .pipe(
            map((result) =>
              result.map((incomeTaxDeclaration) => IncomeTaxDeclarationComposite.from(incomeTaxDeclaration)),
            ),
            this.catchErrorAndShowMessage(),
          ),
      ),
    );
  }

  getIncomeTaxdeclarationCompositeTypes(
    params: IncomeTaxDeclarationListParams,
  ): Observable<IncomeTaxDeclarationCompositeType[]> {
    return this.getIncomeTaxdeclarationComposites(params).pipe(
      map((items) => items.map(toIncomeTaxDeclarationCompositeTypeTransformer.transform)),
    );
  }

  getIncomeStatementComposites({
    responsibleId,
    executorId,
    year,
  }: CompositeListParams): Observable<IncomeStatementComposite[]> {
    const url = `${this.url}/incomeStatement/${responsibleId}/${executorId}/${year}`;
    return withFirstTeamIds(this.store).pipe(
      switchMap(({ ids }) =>
        this.http.get<IncomeStatementComposite[]>(url, this.options.getAuthorizationHeaderWithEmptyBody(ids)).pipe(
          map((result) =>
            result.map((incomeStatementComposite) => IncomeStatementComposite.from(incomeStatementComposite)),
          ),
          this.catchErrorAndShowMessage(),
        ),
      ),
    );
  }

  getIncomeStatementCompositeTypes(config: CompositeListParams): Observable<IncomeStatementCompositeType[]> {
    return this.getIncomeStatementComposites(config).pipe(
      map((items) => items.map(toIncomeStatementCompositeTypeTransformer.transform)),
    );
  }

  getClosingComposites(config: ClosingListParams): Observable<ClosingComposite[]> {
    return withFirstTeamIds(this.store).pipe(
      switchMap(({ ids }) =>
        this.http
          .post<ClosingComposite[]>(
            `${this.url}/closing`,
            config,
            this.options.getAuthorizationHeaderWithEmptyBody(ids),
          )
          .pipe(
            map((list) => list.map((item) => ClosingComposite.from(item))), // creates ClosingComposite objects from the response json
            this.catchErrorAndShowMessage(),
          ),
      ),
    );
  }

  getClosingCompositeTypes(config: ClosingListParams): Observable<ClosingCompositeType[]> {
    return this.getClosingComposites(config).pipe(
      map((items) => items.map(toClosingCompositeTypeTransformer.transform)),
    );
  }
}
