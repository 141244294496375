import { createFeature, createReducer, on } from '@ngrx/store';
import { RuleType } from '../types';
import { RuleActions } from './rule.actions';

export interface RuleState {
  rules: RuleType[];
  error: any;
}

export const initialRuleState: RuleState = {
  rules: [],
  error: undefined,
};

export const ruleFeature = createFeature({
  name: 'rules',
  reducer: createReducer(
    initialRuleState,
    on(RuleActions.loadSucceeded, (state, { rules }) => ({ ...state, error: undefined, rules })),
    on(RuleActions.loadFailed, (state, { error }) => ({ ...state, error, rules: [] }))
  ),
});
