import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MemoizedSelector, Store } from '@ngrx/store';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { filter, finalize, map, switchMap } from 'rxjs/operators';
import { Assignment } from 'src/app/core/entity/assignment';
import { Client } from 'src/app/core/entity/client';
import { deadlineTypes } from 'src/app/core/entity/deadlineTypes';
import { Period } from 'src/app/core/entity/period';
import { Rule } from 'src/app/core/entity/rule';
import { Task } from 'src/app/core/entity/task';
import { getDateValidator, ValidatorResult } from 'src/app/core/entity/validation';
import { ValidationStatus } from 'src/app/core/entity/validationStatus';
import { ValidationType } from 'src/app/core/entity/validationType';
import { AssignmentService } from 'src/app/core/services/assignment.service';
import { ClientService } from 'src/app/core/services/clients.service';
import { PeriodService } from 'src/app/core/services/period.service';
import { TaskService } from 'src/app/core/services/task.service';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { UserType } from 'src/app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { TableColumnWithFilter } from 'src/app/shared/components/table/interfaces/table-column';
import { TodayDate } from 'src/app/shared/misc/today.date';
import { StateFacade } from '../../../facades/state.facade';
import {
  BulkChangeComponentData,
  ExecuteChangeResponse,
  ExecuteChangeServerResponse,
} from '../../../interfaces/bulk-change.component';
import { BaseChangeComponent } from '../base-change.component';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';
import { RuleCode } from '@app/core/entity/ruleCode';
import { CLIENT_RESPONSIBLE } from '@app/shared/misc/constants';

@Component({
  templateUrl: './add-task.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTaskComponent extends BaseChangeComponent {
  assignments$: Observable<Assignment[]>;
  selectedTaskTemplate$: Observable<Task>;
  selectedRuleDeadline$: Observable<Rule & { type: 'text' | 'date'; visible: boolean }>;
  availableTaskTemplates$: Observable<Task[]>;
  availablePeriodicities$: Observable<Period[]>;
  availableRulesForSelectedPeriodicity$: Observable<Rule[]>;
  filterAvailableUsers$: Observable<UserType[]>;
  availableUsersSelector: MemoizedSelector<AppState, UserType[]>;

  private availablePeriodicitiesBS = new BehaviorSubject<Period[] | null>(null);
  private availableRulesBS = new BehaviorSubject<Rule[] | null>(null);
  private selectedRuleDeadlineBS = new BehaviorSubject<(Rule & { type: 'text' | 'date'; visible: boolean }) | null>(
    null,
  );
  private deadlineValidationResult?: ValidatorResult;

  constructor(
    stateFacade: StateFacade,
    private clientService: ClientService,
    private assignmentService: AssignmentService,
    private taskService: TaskService,
    private periodService: PeriodService,
    private store: Store<AppState>,
  ) {
    super(stateFacade);

    this.initTemplateObservables();

    this.subscriptions.add(this.onAssignmentChange());
    this.subscriptions.add(this.onTaskChange());
    this.subscriptions.add(this.onPeriodicityChange());
    this.subscriptions.add(this.onRuleChange());
    this.availableUsersSelector = UserSelectors.activeUsersWithClientResponsible;
  }

  /**
   * Overridden base class function to remove rows instead of updating rows that has been changed
   */
  public override afterExecuteChange(
    oldTableData: any[],
    response: ExecuteChangeServerResponse,
  ): ExecuteChangeResponse {
    const dataIdentityKey = this.getDataIdentityKey();
    const hasError = response?.data?.some((item) => item.success === false);
    const updatedClientIds: number[] = response.data
      ?.filter((item) => item.success)
      .map((item) => (item.value as Task)?.client?.id);

    const updatedClientList = oldTableData.filter(
      (client: Client) => !updatedClientIds.includes((client as Record<string, any>)[dataIdentityKey]),
    );

    return { hasError, data: updatedClientList };
  }

  public onDeadlineValidated(result: ValidatorResult) {
    this.deadlineValidationResult = result;
  }

  public onDeadlineValueChanged(newValue: any) {
    this.formGroup.patchValue({ deadlineParam: newValue });
  }

  protected load(): Observable<BulkChangeComponentData> {
    const taskCode = this.formGroup.value.task.code;
    const dataToChange = this.formatFormValueToResponseValue(this.formGroup.value);
    const selectedUserIds = this.formGroup.value.userIds as number[];
    const isOnlyResponsibleUserSelected = selectedUserIds.every((id) => id === -1);

    this.disableFormControl();

    const clientIdsWithActiveTasksAndCode$ = this.taskService
      .getAllTasksByCode(taskCode)
      .pipe(map((activeTasks: Task[]) => activeTasks.map((task) => task.client.id)));

    return forkJoin([clientIdsWithActiveTasksAndCode$, this.clientService.getAllClients()]).pipe(
      map(([clientIdsWithSelectedTaskCode, allClients]: [number[], Client[]]) => {
        return allClients
          .filter((client: Client) => !client.archived && !clientIdsWithSelectedTaskCode.includes(client.id))
          .filter((client: Client) => (isOnlyResponsibleUserSelected ? !!client.responsible : true));
      }),
      map((clientsWithoutSelectedTask: Client[]) => ({ tableData: clientsWithoutSelectedTask, dataToChange })),
      finalize(() => this.enableFormControl()),
    );
  }

  protected createForm() {
    const todayDate = TodayDate.getIsoFormattedDate(new Date());

    return new UntypedFormGroup({
      assignmentId: new UntypedFormControl(null, Validators.required),
      task: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
      periodicity: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
      rule: new UntypedFormControl({ value: null, disabled: true }, Validators.required),
      deadlineParam: new UntypedFormControl(null),
      startDate: new UntypedFormControl(todayDate, Validators.required),
      userIds: new UntypedFormControl(null, Validators.required),
    });
  }

  protected getDataIdentityKey(): string {
    return 'id';
  }

  protected getTableColumns(): TableColumnWithFilter[] {
    return [
      { field: 'name', header: 'Klient', filter: { type: 'text' } },
      { field: 'corporateIdentity', header: 'Org.nr', filter: { type: 'text' } },
      { field: 'customerNumber', header: 'Kundnr', filter: { type: 'text' } },
      {
        field: 'type.description',
        header: 'Typ',
        filter: {
          type: 'multidropdown',
          data: this.clientService.getClientsTypes(),
          dataLabel: 'description',
          dataValue: 'description',
          dataSelectedItemsLabel: '{0} typer valda',
        },
      },
      {
        field: 'responsible',
        header: CLIENT_RESPONSIBLE,
        fieldValue: (responsible) => responsible?.name,
        filter: {
          type: 'multidropdown',
          data: this.filterAvailableUsers$,
          dataLabel: 'name',
          dataValue: 'id',
        },
      },
    ];
  }

  protected override onValidateFields(): boolean {
    if (!this.validateStartDate()) {
      return false;
    }

    if (!this.validateDeadlineParam()) {
      return false;
    }

    return true;
  }

  private validateStartDate() {
    const { validate, min, max } = getDateValidator();
    const startDate = this.formGroup.get('startDate').value;
    const { status, title, text } = validate(startDate, { min, max, label: 'Startdatum' });
    const hasValidationErrors = startDate && status !== ValidationStatus.Ok;

    if (hasValidationErrors) {
      this.store.dispatch(ToastActions.showWarnMessage({ summary: title, detail: text }));
      return false;
    }

    return true;
  }

  private validateDeadlineParam() {
    if (!this.deadlineValidationResult) {
      return true;
    }

    const { status, title, text } = this.deadlineValidationResult;

    if (status !== ValidationStatus.Ok) {
      this.store.dispatch(ToastActions.showWarnMessage({ summary: title, detail: text }));
      return false;
    }

    return true;
  }

  private initTemplateObservables() {
    this.assignments$ = this.assignmentService.getAssignments();
    this.filterAvailableUsers$ = this.store.select(UserSelectors.activeUsersWithMissingResponsible);
    this.availablePeriodicities$ = this.availablePeriodicitiesBS.asObservable();
    this.availableRulesForSelectedPeriodicity$ = this.availableRulesBS.asObservable();
    this.selectedRuleDeadline$ = this.selectedRuleDeadlineBS.asObservable();
    this.availableTaskTemplates$ = this.formGroup
      .get('assignmentId')
      .valueChanges.pipe(
        switchMap((assignmentId: number) =>
          this.taskService
            .getTasksTemplates()
            .pipe(map((templates) => templates.filter((t) => t.assignmentId === assignmentId && !t.archived))),
        ),
      );
  }

  private onAssignmentChange(): Subscription {
    return this.formGroup.get('assignmentId').valueChanges.subscribe((assignmentId) => {
      this.formGroup.patchValue({ task: null, periodicity: null, rule: null, deadlineParam: null });

      if (!assignmentId) {
        this.disableFormControl('task');
        return;
      }
      this.enableFormControl('task');
    });
  }

  private onTaskChange(): Subscription {
    return this.formGroup.get('task').valueChanges.subscribe((task: Task) => {
      if (!task) {
        this.disableFormControl('periodicity');
        this.disableFormControl('rule');
        this.availablePeriodicitiesBS.next(null);
        return;
      }

      this.enableFormControl('periodicity');
      this.enableFormControl('rule');
      this.availablePeriodicitiesBS.next(task.selectablePeriodicities);

      const selectedPeriodicity = task.selectablePeriodicities.find((p) => p.name === task.periodicity.name);
      this.formGroup.patchValue({ periodicity: selectedPeriodicity });
    });
  }

  private onPeriodicityChange(): Subscription {
    return this.formGroup
      .get('periodicity')
      .valueChanges.pipe(
        filter((p) => !!p),
        switchMap((periodicity: Period) => this.periodService.getAvailableRules(periodicity)),
      )
      .subscribe((rules: Rule[]) => {
        this.availableRulesBS.next(rules);

        const selectedTask = this.formGroup.value.task;
        const defaultRule = rules.find((r) => r.code === selectedTask.deadlineRule.template);
        this.formGroup.patchValue({ rule: defaultRule || rules[0] });
      });
  }

  private onRuleChange(): Subscription {
    return this.formGroup.get('rule').valueChanges.subscribe((rule: Rule) => {
      if (!rule) {
        this.selectedRuleDeadlineBS.next(null);
        return;
      }

      const validationType = deadlineTypes[rule.code as RuleCode] ?? ValidationType.NONE;
      const showDeadlineRuleParam = validationType !== ValidationType.NONE;
      const type = rule.paramType?.toUpperCase().startsWith('YYYY') ? 'date' : 'text';
      const selectedTask = this.formGroup.value.task;
      const defaultDeadlineRule = selectedTask.deadlineRule;
      let defaultDeadlineParam = defaultDeadlineRule.param;

      // rule has changed and not the default
      if (defaultDeadlineRule.template !== rule.code) {
        defaultDeadlineParam = null;
      }

      if (!showDeadlineRuleParam) {
        this.deadlineValidationResult = undefined;
      }

      this.selectedRuleDeadlineBS.next({ ...rule, type, visible: showDeadlineRuleParam });
      this.formGroup.patchValue({ deadlineParam: defaultDeadlineParam });
    });
  }

  private formatFormValueToResponseValue(formValue: any) {
    const { task, rule, periodicity, deadlineParam, startDate, userIds } = formValue;
    const updatedTask = {
      ...task,
      periodicity,
      deadlineRule: { description: rule.text, template: rule.code, param: deadlineParam },
      span: { ...task.span, start: startDate },
    };
    return { task: updatedTask, userIds };
  }

  private enableFormControl(formControlName: string = null) {
    if (!formControlName) {
      this.formGroup.enable({ emitEvent: false });
      return;
    }
    this.formGroup.get(formControlName).enable({ emitEvent: false });
  }
  private disableFormControl(formControlName: string = null) {
    if (!formControlName) {
      this.formGroup.disable({ emitEvent: false });
      return;
    }

    this.formGroup.get(formControlName).disable({ emitEvent: false });
  }
}
