import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { isAdmin } from '@app/core/misc/isAdmin';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { UserType } from '@app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-team-user-list',
  templateUrl: './team-user-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BystSharedModule],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TeamUserListComponent), multi: true }],
})
export class TeamUserListComponent implements ControlValueAccessor {
  users$: Observable<UserType[]>;
  columns: TableColumn[] = [
    { field: 'name', header: 'Namn' },
    { field: 'roleLabel', header: 'Roll' },
  ];

  selectedUsers: UserType[];
  showOnlySelectedRows = false;

  onChange: ControlValueAccessorFn = () => {};
  onTouched: ControlValueAccessorFn = () => {};

  private showOnlySelectedRowsBS = new BehaviorSubject<boolean>(false);

  get filterFields() {
    return this.columns.map((column) => column.field);
  }

  constructor(store: Store<AppState>, private changeDetectorRef: ChangeDetectorRef) {
    this.users$ = store.select(UserSelectors.activeUsersWithoutAll).pipe(
      switchMap((users) =>
        this.showOnlySelectedRowsBS.pipe(
          map((showOnlySelectedRows) => {
            if (!showOnlySelectedRows) return users;
            const filteredUserIds = this.selectedUsers.map((user) => user.id);
            return users.filter((user) => filteredUserIds.includes(user.id));
          }),
        ),
      ),
    );
  }

  writeValue(obj: any): void {
    this.selectedUsers = obj;
    this.changeDetectorRef.markForCheck();
    // reset checkbox and filter
    this.showOnlySelectedRowsBS.next(false);
    this.showOnlySelectedRows = false;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  refilterUsers(event?: boolean) {
    const showOnlySelectedRows = typeof event === 'boolean' ? event : this.showOnlySelectedRowsBS.value;
    this.showOnlySelectedRowsBS.next(showOnlySelectedRows);
  }

  isAdmin(user: UserType) {
    return isAdmin(user);
  }

  getRoleLabel(user: UserType) {
    return isAdmin(user) ? 'Administratör' : 'Medarbetare';
  }
}
