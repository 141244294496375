import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { FirmService } from '../../services/firm.service';
import { ToastActions } from '../toast/toast.actions';
import { FirmActions } from './firm.actions';
import { AuthActions } from '../auth/auth.actions';
import { AmlService } from '@app/core/services/aml.service';

@Injectable()
export class FirmEffects {
  loadFirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmActions.loadFirm),
      exhaustMap(() =>
        this.firmService.loadFirmType().pipe(
          // eslint-disable-next-line max-nested-callbacks
          map((firm) => FirmActions.loadFirmSucceeded({ firm })),
          catchError((error: unknown) => of(FirmActions.loadFirmFailed({ error }))),
        ),
      ),
    ),
  );

  saveFirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmActions.saveFirm),
      exhaustMap(({ firm }) =>
        this.firmService.updateFirmType(firm).pipe(
          map((updatedFirm) => FirmActions.saveFirmSucceeded({ firm: updatedFirm })),
          catchError((error: unknown) => of(FirmActions.saveFirmFailed({ error }))),
        ),
      ),
    ),
  );

  saveFirmSuccessfulMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmActions.saveFirmSucceeded),
      map(() => ToastActions.showInfoMessage({ summary: 'Byråuppgifterna har sparats', detail: '' })),
    ),
  );

  saveFirmFailedMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmActions.saveFirmFailed),
      map(() =>
        ToastActions.showErrorMessage({
          summary: 'Något gick fel!',
          detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
        }),
      ),
    ),
  );

  resetFirmAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmActions.resetFirmAll),
      mergeMap(({ firm }) =>
        this.firmService.resetFirm(firm.id).pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap(() => [FirmActions.resetFirmSucceeded(), AuthActions.resetAuthStateAndStorage()]),
          // eslint-disable-next-line max-nested-callbacks
          catchError(() => of(FirmActions.resetFirmFailed())),
        ),
      ),
    ),
  );

  resetFirmTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmActions.resetFirmTasks),
      mergeMap(({ firm }) =>
        this.firmService.resetFirmTasks(firm.id).pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap(() => [FirmActions.resetFirmSucceeded(), AuthActions.resetAuthStateAndStorage()]),
          // eslint-disable-next-line max-nested-callbacks
          catchError(() => of(FirmActions.resetFirmFailed())),
        ),
      ),
    ),
  );

  activateSustainabilityReporting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmActions.activateSustainabilityReporting),
      exhaustMap(({ firmId, data }) =>
        this.firmService.activateSustainabilityReporting(firmId, data).pipe(
          mergeMap(() => [
            FirmActions.loadFirm(),
            FirmActions.activateSustainabilityReportingSucceeded(),
            ToastActions.showInfoMessage({ summary: 'Hållbarhetsredovisningen har aktiveras för byrån', detail: '' }),
          ]),
          catchError((error: unknown) =>
            from([
              ToastActions.showErrorMessage({
                summary: 'Något gick fel!',
                detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
                error,
                sticky: true,
              }),
              FirmActions.activateSustainabilityReportingFailed(),
            ]),
          ),
        ),
      ),
    ),
  );

  verifyAmlConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmActions.loadFirmSucceeded),
      exhaustMap(() =>
        this.amlService.getAmlApiEmail().pipe(
          map(({ email }) => FirmActions.verifyAmlConnectionSucceeded({ email })),
          catchError(() => of(FirmActions.verifyAmlConnectionFailed())),
        ),
      ),
    ),
  );

  // eslint-disable-next-line no-useless-constructor
  loadFirmSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmActions.loadFirmSettings),
      mergeMap(() =>
        this.firmService.loadFirmSettings().pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((settings) => [FirmActions.loadFirmSettingsSucceeded({ settings })]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) =>
            of(
              FirmActions.loadFirmSettingsFailed({ error }),
              ToastActions.showErrorMessage({
                summary: 'Något gick fel!',
                detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateFirmSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmActions.saveFirmSettings),
      mergeMap(({ settings }) =>
        this.firmService.updateFirmSettings(settings).pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap(() => [
            FirmActions.saveFirmSettingsSucceeded({ settings }),
            ToastActions.showInfoMessage({ summary: 'Uppgifterna till uppdragsavtalet har sparats', detail: '' }),
          ]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) =>
            of(
              FirmActions.saveFirmSettingsFailed({ error }),
              ToastActions.showErrorMessage({
                summary: 'Något gick fel!',
                detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  resetFirmSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FirmActions.resetFirmSettings),
      mergeMap(() =>
        this.firmService.resetFirmSettings().pipe(
          // eslint-disable-next-line max-nested-callbacks
          mergeMap((settings) => [
            FirmActions.resetFirmSettingsSucceeded({ settings }),
            ToastActions.showInfoMessage({ summary: 'Texterna är nu återställda.', detail: '' }),
          ]),
          // eslint-disable-next-line max-nested-callbacks
          catchError((error: unknown) =>
            of(
              FirmActions.resetFirmSettingsFailed({ error }),
              ToastActions.showErrorMessage({
                summary: 'Något gick fel!',
                detail: 'Vänligen försök igen. Om problemet kvarstår, kontakta supporten.',
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  // eslint-disable-next-line no-useless-constructor
  constructor(private actions$: Actions, private firmService: FirmService, private amlService: AmlService) {}
}
