import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { UserType } from 'src/app/core/state/types';
import { createPopup } from '@typeform/embed';
import { environment } from 'src/environments/environment';
import { EmbedPopup } from '@typeform/embed/types/base';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { concatLatestFrom } from '@ngrx/effects';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { Subject, filter, takeUntil } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { openDialog } from '@app/shared/misc/openDialog';
import { DueComplianceTeaserDialogComponent } from '../due-compliance-teaser-dialog/due-compliance-teaser.dialog';
import { FirmSettingsDialog } from '@app/firm-settings/firm.settings.dialog';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input()
  get loggedInUser() {
    return this.currentLoggedInUser;
  }

  set loggedInUser(loggedInUser: UserType | undefined) {
    this.currentLoggedInUser = loggedInUser;
    this.profileItems = this.createProfileItems(loggedInUser);
  }

  @Output() newNote = new EventEmitter<boolean>();
  @Output() settings = new EventEmitter<boolean>();
  @Output() logout = new EventEmitter<boolean>();

  helpItems: MenuItem[];
  profileItems: MenuItem[];
  private currentLoggedInUser: UserType | undefined;
  private popup: EmbedPopup;
  private onDestroySub: Subject<void>;

  // eslint-disable-next-line no-useless-constructor
  constructor(private router: Router, private store: Store<AppState>, private dialogService: DialogService) {}

  ngOnInit(): void {
    this.onDestroySub = new Subject();
    this.store
      .select(FirmSelectors.selectFirm)
      .pipe(
        concatLatestFrom(() => this.store.select(AuthSelectors.selectAuthAsSimpleUser)),
        filter(([firm, user]) => Boolean(firm) && Boolean(user)),
        takeUntil(this.onDestroySub),
      )
      .subscribe(([firm, user]) => {
        this.popup = createPopup(environment.forms.giveFeedback, {
          size: 50,
          hideFooter: true,
          hidden: {
            firmId: String(firm.id),
            userId: String(user.id),
            formType: 'popup',
          },
        });
      });

    this.helpItems = this.getHelpMenuItems();
  }

  ngOnDestroy(): void {
    this.onDestroySub.next();
    this.onDestroySub.complete();
  }

  logoutClicked() {
    this.logout.emit(true);
  }

  get loggedInUserFirm() {
    return this.loggedInUser.firm.name;
  }

  navigateToPartnerProgram() {
    this.router.navigate(['partnerprogram']);
  }

  onDueComplianceClick() {
    openDialog(this.dialogService, DueComplianceTeaserDialogComponent, {});
  }

  private createProfileItems(user: UserType | undefined): MenuItem[] {
    if (!user) {
      return [];
    }

    return [
      {
        label: 'Logga ut',
        command: () => {
          this.logoutClicked();
        },
      },
    ];
  }

  private startChat = (): void => {
    if (!window.$kundo_chat) {
      return;
    }

    if (this.loggedInUser) {
      window.$kundo_chat.user = {
        name: `${this.loggedInUser.name} - ${this.loggedInUser.firm?.name} - ${this.loggedInUser.firm?.corporateIdentity}`,
        email: `${this.loggedInUser.email}`,
      };
    }

    window.$kundo_chat.start_chat();
  };

  private getHelpMenuItems(): MenuItem[] {
    return [
      { label: 'Frågor & Svar', url: 'https://byrastodsforum.blinfo.se/org/bl-byrastod/' },
      { label: 'Manual', url: 'https://support.bjornlunden.se/category/bl-byrastod' },
      { label: 'Filmade guider ', url: 'https://www.youtube.com/playlist?list=PL009yjRM5nBhrjxSv7xa3fvehLRNf0kZH' },
      {
        label: 'Chatta med supporten',
        command: () => this.startChat(),
      },
      { label: 'Fjärrsupport', url: 'https://download.blinfo.se/v2/public/fjarrsupport.exe' },
      { label: 'Facebook-användargrupp', url: 'https://www.facebook.com/groups/bjornlunden/' },
      {
        separator: true,
      },
      {
        label: 'Ladda ner program',
        command: () => openDialog(this.dialogService, FirmSettingsDialog, { tabToOpen: 'Systemuppgifter' }),
      },
      {
        separator: true,
      },
      {
        label: 'Ge feedback',
        command: () => {
          this.popup.open();
        },
      },
    ];
  }
}
