import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OperatorFunction } from 'rxjs';
import { ActivateSustainabilityReporting, FirmType } from '../types';
import { FirmSettings } from '@app/core/entity/firm.settings';

export const FirmActions = createActionGroup({
  source: 'Firm',
  events: {
    'Load Firm': emptyProps(),
    'Load Firm Succeeded': props<{ firm: FirmType }>(),
    'Load Firm Failed': props<{ error: unknown }>(),
    'Save Firm': props<{ firm: FirmType; errorHandler?: OperatorFunction<unknown, unknown> }>(),
    'Save Firm Succeeded': props<{ firm: FirmType }>(),
    'Save Firm Failed': props<{ error: unknown }>(),
    'Reset Firm Tasks': props<{ firm: FirmType }>(),
    'Reset Firm All': props<{ firm: FirmType }>(),
    'Reset Firm Succeeded': emptyProps(),
    'Reset Firm Failed': emptyProps(),
    'Activate Sustainability Reporting': props<{ firmId: number; data: ActivateSustainabilityReporting }>(),
    'Activate Sustainability Reporting Succeeded': emptyProps(),
    'Activate Sustainability Reporting Failed': emptyProps(),
    'Verify Aml Connection Succeeded': props<{ email: string }>(),
    'Verify Aml Connection Failed': emptyProps(),
    'Load Firm Settings': emptyProps(),
    'Load Firm Settings Succeeded': props<{ settings: FirmSettings }>(),
    'Load Firm Settings Failed': props<{ error: unknown }>(),
    'Save Firm Settings': props<{ settings: FirmSettings }>(),
    'Save Firm Settings Succeeded': props<{ settings: FirmSettings }>(),
    'Save Firm Settings Failed': props<{ error: unknown }>(),
    'Reset Firm Settings': emptyProps(),
    'Reset Firm Settings Succeeded': props<{ settings: FirmSettings }>(),
    'Reset Firm Settings Failed': props<{ error: unknown }>(),
  },
});
