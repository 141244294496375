import { createSelector } from '@ngrx/store';
import { toSimpleUserTypeTransformer } from '../transformers/transformers';
import { TeamType, TeamTypes } from '../types';
import { AppState } from '../appState';
import { UserSelectors } from '../users/users.selectors';
import { AuthSelectors } from '../auth/auth.selectors';

const teamsState = (state: AppState) => state.teams;

const selectTeams = createSelector(teamsState, (state) => state.teams);
const selectTeamsInitialized = createSelector(teamsState, (state) => state.intialized);

const selectTeamsByType = (teamType: TeamTypes | TeamTypes[]) => {
  const teamTypes = Array.isArray(teamType) ? teamType : [teamType];
  return createSelector(selectTeams, (teams) => teams.filter((team) => teamTypes.includes(team.typeId)));
};

const selectRootTeam = createSelector(selectTeams, (teams) => teams?.find((t) => t.typeId === TeamTypes.Root));

const selectRootUsersAsSimpleUser = createSelector(selectRootTeam, UserSelectors.allUsers, (rootTeam, users) =>
  users.filter((u) => rootTeam.users.data.includes(u.id)).map(toSimpleUserTypeTransformer.transform),
);

const areBothFalsy = (a: TeamType, b: TeamType): boolean => !a && !b;
const isLeftFalsy = (a: TeamType, b: TeamType): boolean => !a && Boolean(b);
const isRightFalsy = (a: TeamType, b: TeamType): boolean => Boolean(a) && !b;

const sortByTypeId = (a: TeamType, b: TeamType): number => {
  if (areBothFalsy(a, b)) {
    return 0;
  }

  if (isLeftFalsy(a, b)) {
    return -1;
  }

  if (isRightFalsy(a, b)) {
    return 1;
  }

  return a.typeId - b.typeId;
};

const selectTeamSelector = createSelector(selectTeams, AuthSelectors.selectTeams, (allTeams, myTeamIds) => {
  if (!allTeams) {
    return { teams: null, ids: null };
  }

  if (!allTeams.length) {
    return { teams: [], ids: [] };
  }

  const teams = allTeams.filter((t) => myTeamIds.includes(t.id)).sort(sortByTypeId);
  return { teams, ids: teams.map((t) => t.id) };
});

const selectNewClientTeamSelector = createSelector(
  selectTeamsByType(TeamTypes.Client),
  selectTeamSelector,
  selectRootTeam,
  (clientTeams, teamSelector, rootTeam) => {
    if (!teamSelector.teams) {
      return { teams: null, ids: null };
    }

    if (!teamSelector.teams.length) {
      return { teams: [], ids: [] };
    }

    const clientTeamIds = clientTeams.map((t) => t.id);
    const teams = teamSelector.teams.filter((t) => t.id === rootTeam.id || clientTeamIds.includes(t.id));
    return { teams, ids: teams.map((t) => t.id) };
  },
);

export const TeamsSelectors = {
  selectTeams,
  selectTeamsByType,
  selectTeamsInitialized,
  selectRootTeam,
  selectRootUsersAsSimpleUser,
  selectTeamSelector,
  selectNewClientTeamSelector,
};
