<app-table [columns]="columns" [data]="filteredUsers$ | async" [columnSelector]="false" [searchField]="false">
  <app-table-filter *ngIf="filter$ | async as filter">
    <app-table-filter-item>
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          type="text"
          pInputText
          placeholder="Sök..."
          [ngModel]="filter.searchString"
          (ngModelChange)="search($event)"
        />
      </span>
    </app-table-filter-item>
    <app-table-filter-item>
      <p-checkbox
        [binary]="true"
        [ngModel]="!filter.includeInactive"
        (ngModelChange)="toggleIncludeInactiveUsers()"
        label="Visa endast aktiva medarbetare"
      ></p-checkbox>
    </app-table-filter-item>
  </app-table-filter>

  <ng-template tableTemplate="bodyCell" tableBodyCellField="initials" let-user="rowData" let-i="rowIndex">
    <p class="my-1" *ngIf="!hasRightsToChangeInitials(user); else editInitialsTemplate">{{ user.initials }}</p>
    <ng-template #editInitialsTemplate>
      <input
        type="text"
        class="form-control input-sm"
        [ngModel]="user.initials"
        (change)="updateInitials(user, $event)"
      />
    </ng-template>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="roleLabel" let-user="rowData">
    <p class="my-1" *ngIf="!hasRightsToChange(); else editRoleTemplate">{{ user.roleLabel }}</p>
    <ng-template #editRoleTemplate>
      <app-auth-role-selector [user]="user" (roleChange)="updateRole($event)"></app-auth-role-selector>
    </ng-template>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="active" let-user="rowData" let-i="rowIndex">
    <div class="w-full text-center">
      <i *ngIf="user.active" (click)="(undefined)" class="fa fa-check" style="color: green"> </i>
      <i *ngIf="!user.active" (click)="(undefined)" class="fa fa-times" style="color: red"> </i>
    </div>
  </ng-template>
  <ng-template tableTemplate="bodyCell" tableBodyCellField="action" let-user="rowData">
    <div class="w-full text-center cursor-pointer">
      <i
        pTooltip="Inaktivera medarbetare"
        tooltipPosition="left"
        *ngIf="user.active"
        (click)="deactivateUser(user)"
        class="far fa-trash-alt"
      >
      </i>
      <i
        pTooltip="Aktivera medarbetare"
        tooltipPosition="left"
        *ngIf="!user.active"
        (click)="activateUser(user)"
        class="fa fa-recycle"
      >
      </i>
    </div>
  </ng-template>
</app-table>

<app-firm-users-confirm-dialog [(visible)]="showConfirmDialog" (onClose)="onConfirmDialogClosed($event)">
</app-firm-users-confirm-dialog>
