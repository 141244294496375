<div>
  <p class="mb-6">Vad vill du göra med företaget {{ companyName }}?</p>
  <bl-frontend-radiobuttons
    name="action"
    [items]="radioButtonsItems"
    (onChange)="actionChanged($event)"></bl-frontend-radiobuttons>

  <!-- onboarding part -->
  <div class="flex flex-col mt-4" *ngIf="isOnboardSelected">
    <label class="font-bold block" for="email">Epostadress till företaget *</label>
    <input
      pInputText
      name="email"
      type="email"
      placeholder="Epostadress"
      [ngModel]="email | async"
      (ngModelChange)="email.next($event)" />
  </div>

  <!-- onboard as mini part -->
  <div class="flex flex-col mt-4" *ngIf="isOnboardAsMiniSelected">
    <label class="font-bold block" for="email">Ditt personnummer *</label>
    <input
      pInputText
      name="socialSecurityNumber"
      placeholder="Personnummer"
      [ngModel]="socialSecurityNumber | async"
      (ngModelChange)="socialSecurityNumber.next($event)" />

    <p class="mt-4">
      Du som byråmedarbetare blir automatiskt Admin i detta företag i webb och app-tjänsten app.bjornlunden.se. Klienten
      kan <em>inte</em> använda webb och app-tjänsten eftersom E-postadress saknas.
    </p>
  </div>

  <!-- change packages part -->
  <bl-frontend-alert
    *ngIf="isChangePackageSelected"
    class="block mt-4"
    severity="info"
    [header]="'Ändringar i paket och användare görs i BL App'"
    [listItems]="changePackageInfoItems"
    [useUnsafeHtml]="true"
    [dismissable]="false"></bl-frontend-alert>

  <!-- archive company part -->
  <div class="flex flex-col mt-4" *ngIf="isArchivedSelected">
    <label class="font-bold block" for="years">Antal år som du vill ha företaget arkiverat *</label>
    <p>Du kan välja att arkivera mellan 1-10 år</p>
    <div class="flex items-center">
      <input
        title="Antal år som du vill ha företaget arkiverat"
        pInputText
        class="w-20"
        name="years"
        type="number"
        [ngModel]="years | async"
        (ngModelChange)="years.next($event)"
        min="1"
        max="10" />
    </div>
    <div class="mt-4" *ngIf="existsInFirmSupport">
      <label class="font-bold block">Vill du även arkivera eller ta bort klienten i Byråstöd?</label>
      <bl-frontend-radiobuttons
        name="clientAction"
        [items]="clientActionRadioButtonsItems"
        (onChange)="clientActionChanged($event)"></bl-frontend-radiobuttons>
    </div>
    <div class="mt-4">
      <label class="font-bold block">Bekräfta arkiveringen *</label>
      <p-checkbox
        inputId="confirmArchive"
        [binary]="true"
        label="Jag förstår och bekräftar arkiveringen"
        [(ngModel)]="confirmArchive"></p-checkbox>
    </div>

    <div class="mt-4" *ngIf="showArchiveWarningIfRecentlyUsed">
      <bl-frontend-alert severity="warning" [content]="archiveWarningText" [useUnsafeHtml]="true"></bl-frontend-alert>
    </div>
  </div>

  <!-- delete company part -->
  <div class="flex flex-col mt-4" *ngIf="isDeleteSelected">
    <div *ngIf="existsInFirmSupport">
      <label class="font-bold block">Vill du även arkivera eller ta bort klienten i Byråstöd?</label>
      <bl-frontend-radiobuttons
        name="clientAction"
        [items]="clientActionRadioButtonsItems"
        (onChange)="clientActionChanged($event)"></bl-frontend-radiobuttons>
    </div>
    <div class="mt-4">
      <label class="font-bold block">Bekräfta borttagning *</label>
      <p-checkbox
        inputId="confirmDelete"
        [binary]="true"
        label="Jag förstår och bekräftar borttagningen"
        [(ngModel)]="confirmDelete"></p-checkbox>
    </div>
  </div>
  <div class="flex justify-end mt-6">
    <bl-frontend-button class="mr-2" text="Avbryt" variant="secondary" (click)="close(null)"></bl-frontend-button>
    <bl-frontend-button
      [text]="primaryButtonText"
      variant="primary"
      [disabled]="!isValid"
      (click)="next()"></bl-frontend-button>
  </div>
</div>
