import { CloudDbData } from '@app/core/entity/cloud-db-data';
import { createFeature, createReducer, on } from '@ngrx/store';
import { ExportWizardActions } from './export-wizard.actions';

export interface ExportWizardState {
  data: CloudDbData | null;
  cloudApiKey: string | null;
  error: unknown;
}

export const initialExportWizardState: ExportWizardState = {
  data: null,
  cloudApiKey: null,
  error: null,
};

export const exportWizardFeature = createFeature({
  name: 'exportWizard',
  reducer: createReducer(
    initialExportWizardState,
    on(ExportWizardActions.getDefaultValuesSucceeded, (state, { data }) => ({ ...state, error: null, data })),
    on(ExportWizardActions.getDefaultValuesFailed, (state, { error }) => ({ ...state, error, data: null })),
  ),
});
