import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init } from '@sentry/angular-ivy';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

const initSmartlook = (): void => {
  if (!window.smartlook) {
    (function createSmartlookStuff(d) {
      // eslint-disable-next-line no-multi-assign
      const o: SmartlookFunction = (window.smartlook = function smartlookMainFunction(...args) {
        o.api.push(args);
      });
      const h = d.getElementsByTagName('head')[0];
      const c = d.createElement('script');
      o.api = [];
      c.async = true;
      c.type = 'text/javascript';
      c.charset = 'utf-8';
      c.src = 'https://web-sdk.smartlook.com/recorder.js';
      h.appendChild(c);
    })(document);
  }
  window.smartlook('init', '030379ef26b193fe2e8eef476a9511357dab88de', { region: 'eu' });
};

if (environment.production) {
  init({
    dsn: 'https://3e0b5d2db4fa4eb38bdf541be6c7fa01@o356905.ingest.sentry.io/5785908',
    environment: environment.name,
    release: environment.version,
  });

  initSmartlook();
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));

// declaring $kundo_chat on window so we can use it in navbar.component without transpilation errors
declare global {
  // special type for smartlook function which is a function with additional api property
  interface SmartlookFunction {
    (value1: string, value2: string, value3: unknown): void;
    api?: unknown[];
  }

  interface Window {
    $kundo_chat: {
      user: {
        name: string;
        email: string;
      };
      start_chat: () => void;
    };
    smartlook: SmartlookFunction;
  }
}
