import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ClientType } from '../types';
import {
  ClientActionType,
  CollaborationPackageFilter,
  CollaborationPackageParams,
  CollaborationPackageResponse,
} from '../types/collaboration-package.types';

export const CollaborationPackageActions = createActionGroup({
  source: 'CollaborationPackage',
  events: {
    Load: props<{ filter: CollaborationPackageFilter; params: CollaborationPackageParams }>(),
    'Clear State': emptyProps(),
    'Set Meta': props<{ meta: CollaborationPackageResponse['meta'] }>(),
    'Load Email For Client': props<{ clientId?: number; cloudApiKey?: string }>(),
    'Load Email For Client Succeeded': props<{ email: string | null }>(),
    'Load Email For Client Failed': props<{ error: unknown }>(),
    'Load Client': props<{ clientId: number }>(),
    'Load Client Succeeded': props<{ client: ClientType | null }>(),
    'Load Client Failed': props<{ error: unknown }>(),
    'Onboard Existing Client': props<{ clientId: number; email: string }>(),
    'Onboard Existing Client Succeeded': props<{ key: string }>(),
    'Onboard Existing Client Failed': props<{ error: unknown }>(),
    'Onboard Non Existing Client': props<{ cloudApiKey: string; email: string }>(),
    'Onboard Non Existing Client Succeeded': props<{ key: string }>(),
    'Onboard Non Existing Client Failed': props<{ error: unknown }>(),

    'Onboard With Mini Collaboration Package': props<{
      cloudApiKey: string;
      name: string;
      socialSecurityNumber: string;
    }>(),
    'Onboard With Mini Collaboration Package Succeeded': props<{ name: string }>(),
    'Onboard With Mini Collaboration Package Failed': props<{ error: unknown }>(),

    'Change Package On New Subscription Type': props<{
      cloudApiKey: string;
    }>(),

    'Archive Cloud Database': props<{
      cloudApiKey: string;
      years: number;
      name: string;
      clientAction: ClientActionType;
    }>(),
    'Archive Cloud Database Succeeded': props<{ cloudApiKey: string; name: string; clientAction: ClientActionType }>(),
    'Archive Cloud Database Failed': props<{ error: unknown }>(),

    'Delete Cloud Database': props<{
      cloudApiKey: string;
      name: string;
      clientAction: ClientActionType;
    }>(),
    'Delete Cloud Database Succeeded': props<{ cloudApiKey: string; name: string; clientAction: ClientActionType }>(),
    'Delete Cloud Database Failed': props<{ error: unknown }>(),
  },
});
