import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TableFilterItemComponent } from './components/table-filter-item.component';
import { TableFilterComponent } from './components/table-filter.component';
import { TableRefreshButton } from './components/table-refresh-button.component';
import { TableTemplateDirective } from './directives/table-template.directive';
import { TableComponent } from './table.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    InputTextModule,
    CheckboxModule,
    TableFilterItemComponent,
    CheckboxModule,
  ],
  declarations: [TableComponent, TableFilterComponent, TableTemplateDirective, TableRefreshButton],
  exports: [TableComponent, TableFilterComponent, TableFilterItemComponent, TableTemplateDirective, TableRefreshButton],
})
export class BystTableModule {}
