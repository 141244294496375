export const hashCode = function (text: string) {
  let hash = 0;
  // if (this.length == 0) {
  //     return hash;
  // }
  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};
