import { NgModule } from '@angular/core';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { TabViewModule } from 'primeng/tabview';
import { CommonModule } from '@angular/common';
import { PartnerProgramComponent } from './partnerprogram.component';
import { PartnerProgramOverviewComponent } from './partnerprogram-overview/partnerprogram-overview.component';
import { PartnerProgramBenefitsComponent } from './partnerprogram-benefits/partnerprogram-benefits.component';
import { PartnerProgramProfileComponent } from './partnerprogram-profile/partnerprogram-profile.component';
import { PartnerProgramResourcesComponent } from './partnerprogram-resources/partnerprogram-resources.component';

@NgModule({
  declarations: [PartnerProgramComponent],
  imports: [
    BystSharedModule,
    CommonModule,
    TabViewModule,
    PartnerProgramOverviewComponent,
    PartnerProgramBenefitsComponent,
    PartnerProgramProfileComponent,
    PartnerProgramResourcesComponent,
  ],
  exports: [PartnerProgramComponent],
})
export class PartnerProgramModule {}
