import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, exhaustMap, catchError, mergeMap } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { AuthActions } from '../auth/auth.actions';
import { AuthSelectors } from '../auth/auth.selectors';
import { TeamsActions } from '../teams/teams.actions';
import { ToastActions } from '../toast/toast.actions';
import { UserType } from '../types';
import { AppState } from '../appState';
import { UserActions } from './users.actions';

@Injectable()
export class UserEffects {
  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadAllUsers),
      exhaustMap(() =>
        this.userService.getAllUserTypes().pipe(
          map(this.sortUsers),
          map((users) => UserActions.loadAllUsersSucceeded({ users })),
          catchError((error: unknown) => of(UserActions.loadAllUsersFailed({ error }))),
        ),
      ),
    ),
  );

  saveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUser),
      exhaustMap(({ user, errorHandler }) =>
        this.userService.saveUserType(user, errorHandler).pipe(
          mergeMap((storedUser) => [UserActions.saveUserSucceeded({ user: storedUser }), TeamsActions.loadAllTeams()]),
          catchError((error: unknown) => of(UserActions.saveUserFailed({ error }))),
        ),
      ),
    ),
  );

  saveUserSuccessfulMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserSucceeded),
      map(({ user }) => ToastActions.showInfoMessage({ summary: 'Sparat', detail: user.name + ' är nu uppdaterad' })),
    ),
  );

  saveUserSuccessfulReloadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.saveUserSucceeded),
      concatLatestFrom(() => this.store.select(AuthSelectors.selectAuthUser)),
      map(([{ user }, authUser]) =>
        user.id === authUser.id ? AuthActions.reloadCurrentAuthUser() : UserActions.loadAllUsers(),
      ),
    ),
  );

  constructor(private actions$: Actions, private store: Store<AppState>, private userService: UserService) {}

  private sortUsers(users: UserType[]) {
    return users.sort((userA, userB) => {
      if (userA.name < userB.name) return -1;
      if (userA.name > userB.name) return 1;
      return 0;
    });
  }
}
