import { StatusColor } from '../misc/status-color';
import { Activity } from './activity';
import { Task } from './task';

export class ActivityComposite {
  task: Task;
  activity: Activity;
  statusColor: string;

  constructor(task: Task, activity: Activity) {
    this.task = task;
    this.activity = activity;
    this.activity.taskId = task && task.id ? task.id : this.activity.taskId;
  }

  updateStatusColor() {
    var statusText = this.activity.alert_level ? this.activity.alert_level.name : this.activity.alertLevel.name;
    this.statusColor = StatusColor.getColorByAlertName(statusText);
  }
}
